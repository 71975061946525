import './styles.css'

import React, { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

export type Variant =
  | 'heading-large'
  | 'heading-medium'
  | 'heading-small'
  | 'label-extralarge'
  | 'label-large'
  | 'label-medium'
  | 'label-small'
  | 'label-extrasmall'
  | 'paragraph-large'
  | 'paragraph-medium'
  | 'paragraph-small'
  | 'paragraph-extrasmall'

// If we ever introduce left and right spacers we
// can extend this out to include 4 spacers
type SpacerOption = 'top' | 'bottom'
export type Spacers = [SpacerOption?, SpacerOption?]

const components: { [k in Variant]: string } = {
  'heading-large': 'h1',
  'heading-medium': 'h2',
  'heading-small': 'h3',
  'label-extralarge': 'h4',
  'label-large': 'h4',
  'label-medium': 'h5',
  'label-small': 'h6',
  'label-extrasmall': 'h6',
  'paragraph-large': 'p',
  'paragraph-medium': 'p',
  'paragraph-small': 'p',
  'paragraph-extrasmall': 'p',
}

const textStyle: { [k in Variant]: string } = {
  'heading-large': twMerge(
    'text-[3.5rem] leading-[4rem] font-[800] text-black'
  ),
  'heading-medium': twMerge(
    'text-[2.5rem] leading-[3rem] font-[800] text-black'
  ),
  'heading-small': twMerge(
    'text-[1.875rem] leading-[2.5rem] font-[800] text-black'
  ),
  'label-extralarge': twMerge(
    'text-[1.5rem] leading-[1.75rem] font-[800] text-black'
  ),
  'label-large': twMerge(
    'text-[1.125rem] leading-[1.75rem] font-[800] text-black'
  ),
  'label-medium': twMerge(
    'text-[0.9375rem] leading-[1.5rem] font-[800] text-black'
  ),
  'label-small': twMerge(
    'text-[0.875rem] leading-[1.25rem] font-[800] text-black'
  ),
  'label-extrasmall': twMerge(
    'text-[0.75rem] leading-[1rem] font-[800] text-black'
  ),
  'paragraph-large': twMerge(
    'text-[1.125rem] leading-[1.75rem] font-[500] text-gray-600'
  ),
  'paragraph-medium': twMerge(
    'text-[0.9375rem] leading-[1.5rem] font-[500] text-gray-600'
  ),
  'paragraph-small': twMerge(
    'text-[0.875rem] leading-[1.25rem] font-[500] text-gray-600'
  ),
  'paragraph-extrasmall': twMerge(
    'text-[0.75rem] leading-[1rem] font-[500] text-gray-600'
  ),
}

const topSpacer: { [k in Variant]: string } = {
  'heading-large': 'pt-[2rem]',
  'heading-medium': 'pt-[1.5rem]',
  'heading-small': 'pt-[0.75rem]',
  'label-extralarge': 'pb-[0.5rem]',
  'label-large': 'pt-[0.5rem]',
  'label-medium': 'pt-[0.5rem]',
  'label-small': 'pt-[0.5rem]',
  'label-extrasmall': 'pt-[0.5rem]',
  'paragraph-large': 'pt-[1rem]',
  'paragraph-medium': 'pt-[0.75rem]',
  'paragraph-small': 'pt-[0.5rem]',
  'paragraph-extrasmall': 'pt-[0.5rem]',
}

const bottomSpacer: { [k in Variant]: string } = {
  'heading-large': 'pb-[2rem]',
  'heading-medium': 'pb-[1rem]',
  'heading-small': 'pb-[0.75rem]',
  'label-extralarge': 'pb-[0.5rem]',
  'label-large': 'pb-[0.5rem]',
  'label-medium': 'pb-[0.5rem]',
  'label-small': 'pb-[0.5rem]',
  'label-extrasmall': 'pb-[0.5rem]',
  'paragraph-large': 'pb-[0.5rem]',
  'paragraph-medium': 'pb-[1.5rem]',
  'paragraph-small': 'pb-[1rem]',
  'paragraph-extrasmall': 'pb-[1rem]',
}

export type Props = {
  variant: Variant
  spacers?: Spacers
  children: React.ReactNode
  tooltip?: string
  onClick?: (e?: MouseEvent<HTMLElement>) => void
  className?: string
  testId?: string
}

export const Typography = (props: Props) => {
  // Create a component map
  const component = components[props.variant]

  // Return the appropriate heading level with the class string applied
  return React.createElement(
    component,
    {
      className: twMerge(
        textStyle[props.variant],
        Array.isArray(props.spacers) &&
          props.spacers.includes('top') &&
          topSpacer[props.variant],
        Array.isArray(props.spacers) &&
          props.spacers.includes('bottom') &&
          bottomSpacer[props.variant],
        props.className
      ),
      onClick: props.onClick,
      title: props.tooltip ?? undefined,
      'data-testid': props.testId,
    },
    props.children
  )
}
