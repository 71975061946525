import { t } from 'i18next'
import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { ActionResponse } from '@/types/actions'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  onInvite?: () => void
  onCancel?: () => void
}
export const ForgottenPasswordModal = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'information',
      title: t('reset_password'),
      description: t('reset_password_success'),
    })
    props.onClose()
  }

  const onClose = () => {
    props.onCancel && props.onCancel()
    props.onClose()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[504px]'}
    >
      <Form
        buttons={[
          <Button.Basic
            key={`form_btn_forgottenpassword`}
            hierarchy={'secondary'}
            label={t('reset_password')}
            state={
              _submitting === INTENTS.RECOVER_PASSWORD ? 'loading' : 'default'
            }
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.RECOVER_PASSWORD,
            }}
          />,
          <Button.Basic
            key={`form_btn_close`}
            label={t('cancel')}
            onClick={onClose}
            hierarchy="tertiary"
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={'flex p-6 flex-col items-start gap-0 w-full max-w-[40rem]'}
      >
        <div className={'flex flex-col pb-6'}>
          <Paragraph
            title={t('recover_password')}
            description={ReactHtmlParser(t('recover_password_desc'))}
            spacerOverrides={{
              description: ['top', 'bottom'],
              title: ['bottom'],
            }}
          />
          <Input.Email
            id="forgotten_password_email"
            name="forgotten_password_email"
            autoComplete={'email'}
            label={t('email_address')}
            placeholder={t('email_address_placeholder')}
            dynamicInput
          />
        </div>
      </Form>
    </Modal>
  )
}
