import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { dateToString } from '@/helpers/dateToString'
import { IAsset, IAssetStatusName } from '@/types/asset'

import Progress from '../progress'

interface Props {
  asset: IAsset
}

interface TimelineItem {
  label: string
  timestamp: string
  icon: string
  className?: string
}

const extractTimeline = (asset: IAsset) => {
  const timelineItems: TimelineItem[] = []

  // Add in some default timeline items
  timelineItems.push({
    label: t('asset_added'),
    timestamp: asset.created_at,
    icon: 'plus',
  })
  const sortedStatuses = [...asset.statuses].sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  )
  sortedStatuses.forEach((status, i) => {
    const prevStatus = sortedStatuses[i - 1]
    let label = `${t('status_change')}: ${capitalizeFirstLetter(status.name)}`
    if (
      prevStatus &&
      prevStatus.name === status.name &&
      prevStatus.happened_at !== status.happened_at
    ) {
      label = `${capitalizeFirstLetter(status.name)} date change: ${dateToString(status?.happened_at ?? '')}`
    }

    timelineItems.push({
      label,
      timestamp: status.created_at,
      icon: getStatusChangeIcon(status.name),
      className:
        status.name === 'SEIZED'
          ? 'bg-red-500'
          : status.name === 'RETURNED'
            ? 'bg-green-500'
            : '',
    })
  })

  return timelineItems
}

const formatTooltipDate = (date: string) => {
  return `${dateToString(date)} ${moment(new Date(date)).format('HH:mm:ss')}`
}

const getStatusChangeIcon = (status: IAssetStatusName) => {
  switch (status) {
    case 'WITHOUT_OPERATION':
      return 'plus'
    case 'PENDING':
      return 'clock'
    case 'READY':
      return 'check'
    case 'SEIZED':
      return 'hand'
    case 'ADOPTED':
      return 'plus'
    case 'AWARDED':
      return 'award'
    case 'FORFEITED':
      return 'exclamation'
    case 'TRANSFERRED':
      return 'arrow-right'
    case 'RETURNED':
      return 'arrow-right-arrow-left'
    case 'LIQUIDATED':
      return 'faucet-drip'
    case 'DELETED':
      return 'trash'
    case 'CLOSED':
      return 'close'
  }
}

export const AssetTimeline = (props: Props) => {
  const [_timelineItems, setTimelineItems] = useState<TimelineItem[]>(
    extractTimeline(props.asset)
  )

  useEffect(() => {
    setTimelineItems(extractTimeline(props.asset))
  }, [props.asset])

  // Return nothing if we have no items available
  if (!_timelineItems || _timelineItems.length <= 0) {
    return null
  }

  return (
    <div className={'flex flex-col items-start self-stretch'}>
      {_timelineItems.map((t, i) => (
        <Progress.Step
          key={`step_${i}`}
          title={t.label}
          description={dateToString(t.timestamp)}
          descriptionTooltip={formatTooltipDate(t.timestamp)}
          iconName={t.icon}
          className={t.className}
        />
      ))}
    </div>
  )
}
