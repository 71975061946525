import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import AssetIcon from '@/components/table/rows/assetIcon.tsx'
import { BannerManager } from '@/contexts/banner'
import { useModal } from '@/contexts/interface'
import { useWorkspace } from '@/contexts/workspace'
import { getAssetTypeDescriptionForColumn } from '@/helpers/assetTypeDescription.ts'
import { useOrgWorkspaces } from '@/hooks/queries/useOrgWorkspaces'
import { ActionResponse } from '@/types/actions'
import { IAsset } from '@/types/asset'
import { IWorkspace } from '@/types/workspace'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  asset: IAsset
  onTransfer?: (toWorkspace: IWorkspace) => void
  onCancel?: () => void
}

export const TransferAssetModal = (props: Props) => {
  const { selectedWorkspace } = useWorkspace()
  const { orgWorkspaces } = useOrgWorkspaces()
  const [_selectableWorkspaces, setSelectableWorkspace] =
    useState<IWorkspace[]>()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const { setModal } = useModal()

  useEffect(() => {
    setSelectableWorkspace(
      orgWorkspaces.filter((w) => w.id !== selectedWorkspace?.id)
    )
  }, [orgWorkspaces])

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    setModal({
      id: 'TRANSFER_ASSET_REQUESTED',
    })
  }

  const onCancel = () => {
    props.onCancel && props.onCancel()
    props.onClose()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[504px]'}
    >
      <Form
        buttons={[
          <Button.Basic
            key={'btn_transfer'}
            hierarchy={'secondary'}
            size={'medium'}
            label={t('transfer')}
            state={
              _submitting === INTENTS.TRANSFER_ASSET ? 'loading' : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.TRANSFER_ASSET,
            }}
          />,
          <Button.Basic
            key={'btn_cancel'}
            hierarchy={'tertiary'}
            size={'medium'}
            label={t('cancel')}
            onClick={onCancel}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={'flex p-6 flex-col items-start gap-6 w-full'}
      >
        <input type={'hidden'} name={'asset_id'} value={props.asset.id} />
        <input
          type={'hidden'}
          name={'from_workspace'}
          value={selectedWorkspace?.id}
        />

        <ListItem
          leading={
            <Icon
              name={'buildings'}
              family={'sharp'}
              variant={'solid'}
              size={'medium'}
            />
          }
          title={selectedWorkspace?.name}
        />
        <div className={'flex items-center gap-8 self-stretch'}>
          <AssetIcon asset={props.asset} />
          <Paragraph
            title={
              props.asset?.external_ref ??
              getAssetTypeDescriptionForColumn(props.asset)
            }
            subTitle={props.asset.platform_id ?? '-'}
            spacerOverrides={{
              title: [],
              subTitle: [],
            }}
          />
        </div>
        <Input.Dropdown
          name={'to_workspace'}
          label={t('transfer_to_workspace')}
          defaultValue={
            _selectableWorkspaces && _selectableWorkspaces.length > 0
              ? _selectableWorkspaces[0].id
              : ''
          }
          icon={
            <Icon
              name={'sitemap'}
              family={'sharp'}
              variant={'solid'}
              size={'medium'}
            />
          }
          items={
            _selectableWorkspaces?.map((w) => (
              <ListItem
                key={w.id}
                value={w.id}
                title={w.name}
                style={'bold'}
                leading={
                  <Icon name={'sitemap'} family={'sharp'} variant={'solid'} />
                }
                className={'pl-2 pr-2'}
              />
            )) ?? []
          }
          className={'max-w-none'}
        />
      </Form>
    </Modal>
  )
}
