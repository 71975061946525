import { useQuery } from '@tanstack/react-query'

import { getOperations } from '@/api/broker/v2/operations'

export const key = 'operations'
export const useOperations = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key],
    queryFn: () => getOperations(),
  })

  return { operations: data?.data, isLoading, refetchOperations: refetch }
}
