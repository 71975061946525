import { useQuery } from '@tanstack/react-query'

import { getOrgUsers } from '@/api'

export const key = 'org-users'
export const useOrgUsers = (orgId?: string) => {
  const { isLoading, data } = useQuery({
    queryKey: [key, orgId],
    queryFn: () => getOrgUsers(orgId ?? ''),
    enabled: !!orgId,
  })

  return { users: data?.data, isLoading }
}
