import { t } from 'i18next'
import moment from 'moment'
import { MouseEvent, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Chip } from '@/components/chip'
import { Divider } from '@/components/divider'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { Typography } from '@/components/typography'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { AssetCategory } from '@/types/asset.ts'
import { IOperation } from '@/types/operations'
import { IReportTemplate } from '@/types/reporting'

export interface Props {
  template: IReportTemplate
  operations: IOperation[]
  showDatePickers?: boolean
  onClose: () => void
}
interface IAssetOption {
  label: string
  key: AssetCategory
  checked: boolean
}
export const ReportTemplate: React.FC<Props> = (props) => {
  const [_isEditing, setIsEditing] = useState<boolean>(false)
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_statuses] = useState<string[]>(props.template.include_statuses ?? [])
  const [_selectedCase, setSelectedCase] = useState<'ALL' | IOperation>('ALL')
  const [_assets, setAssets] = useState<IAssetOption[]>([
    ...((props.template.include_digital === true
      ? [
          {
            label: t('digital_assets'),
            key: 'DIGITAL',
            checked: true,
          },
        ]
      : []) as IAssetOption[]),
    ...((props.template.include_physical === true
      ? [
          { label: t('fiat_cash'), key: 'FIAT_CASH', checked: true },
          {
            label: t('high_value_property'),
            key: 'HIGH_VALUE_PERSONAL_PROPERTY',
            checked: true,
          },
          { label: t('real_estate'), key: 'REAL_ESTATE', checked: true },
          { label: t('vehicles'), key: 'VEHICLE', checked: true },
        ]
      : []) as IAssetOption[]),
  ])

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      text: actionRes.message ?? 'Failed to generate report',
      type: 'critical',
    })
  }

  // On form submit successfully
  const onSuccess = () => {
    ToastManager.showToast({
      type: 'information',
      text: t('we_have_emailed_you_a_link_to_the_report'),
    })
    setSubmitting(null)
    setIsEditing(false)
  }

  const toggleEditMode = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsEditing(!_isEditing)
  }

  const toggleAssetChecked = (key: string) => {
    setAssets([
      ..._assets.map((a) =>
        a.key === key ? { ...a, checked: !a.checked } : a
      ),
    ])
  }

  return (
    <div
      className={twMerge(
        'flex flex-col w-full max-w-[42rem]',
        'rounded-md border-[0.0625rem] border-solid border-[#212427] bg-white',
        _isEditing ? 'bg-[#F5F5F6] border-[#F5F5F6]' : ''
      )}
    >
      <Form<string, INTENTS>
        type={'other'}
        buttons={
          _isEditing
            ? [
                <Button.Basic
                  key={'btn_generate_report'}
                  hierarchy={'primary'}
                  size={'medium'}
                  state={
                    _submitting === INTENTS.GENERATE_REPORT
                      ? 'loading'
                      : _submitting !== null
                        ? 'disabled'
                        : 'default'
                  }
                  width={'intrinsic'}
                  label={t('generate_report')}
                  icon={{
                    name: 'arrow-down-to-bracket',
                  }}
                  withAttributes={{
                    name: 'intent',
                    type: 'submit',
                    value: INTENTS.GENERATE_REPORT,
                  }}
                  onClick={props.onClose}
                />,
                <Button.Basic
                  key={'btn_cancel'}
                  hierarchy={'tertiary'}
                  size={'medium'}
                  state={_submitting !== null ? 'disabled' : 'default'}
                  width={'intrinsic'}
                  label={t('cancel')}
                  onClick={toggleEditMode}
                  withAttributes={{
                    type: 'button',
                  }}
                />,
              ]
            : [
                <Button.Basic
                  key={'btn_generate_report_action'}
                  hierarchy={'tertiary'}
                  size={'medium'}
                  state={'default'}
                  width={'intrinsic'}
                  label={t('generate_report')}
                  icon={{
                    name: 'edit',
                  }}
                  onClick={toggleEditMode}
                  className={'pl-0 pr-0'}
                />,
              ]
        }
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        testId={'report_template_form'}
        className={'gap-0'}
        buttonClassName={twMerge(
          'px-[24px] py-[8px]',
          _isEditing && 'pb-[24px]'
        )}
        containerClassName={'gap-0'}
      >
        <input
          type="hidden"
          name={'categories'}
          value={JSON.stringify(
            _assets.filter((a) => a.checked === true).map((a) => a.key)
          )}
        />
        <input
          type="hidden"
          name={'template_id'}
          value={props.template.template_id}
        />

        <div className={'flex flex-col p-[24px] pt-[16px] gap-[16px]'}>
          <Paragraph
            subTitle={props.template.name}
            description={props.template.description}
            spacerOverrides={{
              description: [],
            }}
          />

          {props.showDatePickers === true && _isEditing && (
            <div
              className={'flex flex-col gap-2 self-stretch max-w-[23.4375rem]'}
            >
              <Typography variant={'label-small'}>{t('date_range')}</Typography>
              <div
                className={
                  'flex flex-row w-full gap-2 self-stretch items-center'
                }
                data-testid="report-datapicker"
              >
                <Input.Date
                  id={'date_from'}
                  name={'date_from'}
                  defaultValue={moment(new Date())
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD')}
                  className={'bg-white'}
                />
                <Typography variant={'paragraph-medium'}>{t('to')}</Typography>
                <Input.Date
                  id={'date_to'}
                  name={'date_to'}
                  defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                  className={'bg-white'}
                />
              </div>
            </div>
          )}

          {_isEditing && (
            <div
              className={'flex flex-col gap-2 self-stretch max-w-[23.4375rem]'}
            >
              <Typography variant={'label-small'}>
                {t('select_a_case')}
              </Typography>
              <div className={'w-full'} data-testid="select-case-dropdown">
                <Input.Dropdown
                  name={`case`}
                  placeholder={'Select a case'}
                  size={'medium'}
                  state={'default'}
                  value={
                    _selectedCase === 'ALL'
                      ? 'all'
                      : _selectedCase
                        ? _selectedCase.id
                        : ''
                  }
                  text={
                    _selectedCase === 'ALL'
                      ? t('all')
                      : _selectedCase
                        ? _selectedCase.name
                        : ''
                  }
                  items={[
                    {
                      title: t('all'),
                      value: 'ALL',
                      onClick: () => setSelectedCase('ALL'),
                    },
                    ...(props.operations.map((o) => {
                      return {
                        title: o.name,
                        value: o.id,
                        onClick: () => setSelectedCase(o),
                      }
                    }) ?? []),
                  ].map((v, i) => (
                    <ListItem
                      key={`item_${i}`}
                      title={v.title}
                      value={v.value}
                      style={'normal'}
                      className={'pl-4 pr-4'}
                      onClick={v.onClick}
                    />
                  ))}
                />
              </div>
            </div>
          )}

          {!!_selectedCase &&
            _selectedCase !== 'ALL' &&
            (_selectedCase.defendants ?? []).length > 0 &&
            _isEditing && (
              <div
                className={
                  'flex flex-col gap-2 self-stretch max-w-[23.4375rem]'
                }
              >
                <Typography variant={'label-small'}>
                  {t('select_a_party')}
                </Typography>
                <div className={'w-full'} data-testid="select-party-dropdown">
                  <Input.Dropdown
                    name={`party`}
                    placeholder={'Select a party'}
                    size={'medium'}
                    state={_selectedCase ? 'default' : 'disabled'}
                    items={[
                      {
                        name: 'all',
                        id: 'ALL',
                      },
                      ...(_selectedCase.defendants ?? []),
                    ].map((v, i) => (
                      <ListItem
                        key={`item_${i}`}
                        title={v.name}
                        value={v.id}
                        style={'normal'}
                        className={'pl-4 pr-4'}
                      />
                    ))}
                  />
                </div>
              </div>
            )}

          {_assets.length > 0 && (
            <div className={'flex flex-col gap-2 self-stretch'}>
              <Typography variant={'label-small'}>{t('asset')}</Typography>
              <div
                className={'flex flex-row w-full gap-2 self-stretch flex-wrap'}
              >
                {_assets.map((a, i) =>
                  _isEditing ? (
                    <Chip
                      key={`asset_${i}_check`}
                      size={'medium'}
                      label={a.label}
                      colour={'white'}
                      leading={
                        <Icon
                          name={a.checked ? 'square-check' : 'square'}
                          size={'small'}
                          variant={a.checked ? 'solid' : 'regular'}
                        />
                      }
                      onClick={() => toggleAssetChecked(a.key)}
                    />
                  ) : (
                    <Chip
                      key={`asset_${i}`}
                      size={'medium'}
                      label={a.label}
                      colour={'blue'}
                    />
                  )
                )}
              </div>
            </div>
          )}

          {!_isEditing && _statuses.length > 0 && (
            <div className={'flex flex-col gap-2 self-stretch'}>
              <Typography variant={'label-small'}>{t('status')}</Typography>
              <div
                className={'flex flex-row w-full gap-2 self-stretch flex-wrap'}
              >
                {_statuses.map((s, i) => (
                  <Chip
                    key={`status_${i}`}
                    size={'medium'}
                    label={s}
                    colour={'green'}
                    leading={
                      <Icon
                        name={'circle-check'}
                        size={'small'}
                        variant={'solid'}
                      />
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <Divider />
      </Form>
    </div>
  )
}
