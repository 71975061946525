import { ChangeEvent, ReactElement, useRef } from 'react'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import Input from '@/components/input'
import { Props as InputHintProps } from '@/components/input/components/hint.input'
import { Props as InputLabelProps } from '@/components/input/components/label.input'

import { RowTypes } from '../../components/list.dropdown'
import { Props as ViewProps } from '../../components/view.dropdown'

interface Props<T> extends ViewProps<T> {
  value?: string
  text?: string
  label?: string | ReactElement<InputLabelProps>
  hint?: string | ReactElement<InputHintProps>
  onInputChanged?: (value: string) => void
}
const SuggestionView = <T,>(props: Props<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)

  // Override the item click event so we don't
  // fire it when the input is clicked on
  const onItemClicked = (item: RowTypes<T>) => {
    if ('value' in item) {
      props.onItemClicked?.(item)
    }
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onInputChanged && props.onInputChanged(e.target.value)
  }

  return (
    <DropdownView
      {...props}
      id={'SUGGESTION_INPUT'}
      heightClassName={'max-h-[50dvh] tablet:h-auto'}
      onItemClicked={onItemClicked}
      fixedHeader={
        <div className={'p-6 pt-[1.75rem] w-full tablet:hidden'}>
          <Input.Text
            setRef={inputRef}
            key={`suggest_input_dd`}
            className={'flex max-w-none'}
            hint={props.hint}
            label={props.label}
            value={props.value ?? ''}
            text={props.text ?? ''}
            onChange={onInputChange}
          />
        </div>
      }
    />
  )
}
export default SuggestionView
