import { createContext } from 'react'

import { Props as DropdownProps } from '@/components/dropdown/components/context.dropdown'

import { DrawerProps } from './drawer.types'
import { ModalProps } from './modal.types'

interface IContext {
  currentDropdownId: string | null
  setCurrentDropdownId: (id: string | null) => void
  setDropdown: <T>(props: DropdownProps<T>) => string
  updateDropdownController: <T>(
    id: string,
    controller: DropdownProps<T>['controller']
  ) => void
  setDrawer: (props: DrawerProps) => void
  setModal: (props: ModalProps) => void
}
export const InterfaceContext = createContext<IContext>({
  currentDropdownId: null,
  setCurrentDropdownId: () => {},
  setDropdown: () => '',
  updateDropdownController: () => {},
  setDrawer: () => {},
  setModal: () => {},
})
InterfaceContext.displayName = 'InterfaceContext'
