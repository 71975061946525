import { t } from 'i18next'

import { resetPassword } from '@/api'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'

export const ResetPassword = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  // Grab data from the form
  const token = formData.get('token')?.toString()
  const newPassword = formData.get('new_password')?.toString()?.trim()

  // Validate the form data
  if (IsNot(token, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('missing_session_token'),
      },
    ])
  } else if (IsNot(newPassword, REGEX.IS_PASSWORD)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'new_password',
        error:
          'Invalid password, please check all password requirements are met.',
      },
    ])
  }

  // Update our operation
  const res = await resetPassword(newPassword, token)

  // Check the share worked
  if (res.error) {
    throw new Error(ExtractErrorFrom(res) ?? t('something_went_wrong'))
  }

  return {
    error: false,
    message: t('password_reset_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
