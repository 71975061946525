import { t } from 'i18next'

import { updateEscrow } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryEscrowKey } from '@/hooks/queries/useEscrow'
import { ActionResponse } from '@/types/actions'
import { IEscrow } from '@/types/escrows'

export const EditEscrowApprovers = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const escrow = formData.get('escrow')?.toString()
  const party_a_require_approval = formData
    .get('party_a_require_approval')
    ?.toString()
  const party_a_approver_count = formData
    .get('party_a_approver_count')
    ?.toString()
  const party_b_approver_count = formData
    .get('party_b_approver_count')
    ?.toString()
  const party_b_require_approval = formData
    .get('party_b_require_approval')
    ?.toString()

  // Validate the fields
  if (IsNot(escrow, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(party_a_require_approval, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_a_require_approval',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(party_b_require_approval, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_b_require_approval',
        error: t('try_again_later'),
      },
    ])
  }

  // Check if we're requiring approvals for each org
  const partyARequiresApproval = party_a_require_approval === 'true'
  const partyBRequiresApproval = party_b_require_approval === 'true'

  // Confirm that we have a valid count if
  if (partyARequiresApproval && Number(party_a_approver_count) <= 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_a_approver_count',
        error: 'Party A approver count must be greater than 0',
      },
    ])
  } else if (partyBRequiresApproval && Number(party_b_approver_count) <= 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'party_b_approver_count',
        error: 'Party B approver count must be greater than 0',
      },
    ])
  }

  // Attempt to parse the original escrow object
  const parsedEscrow = JSON.parse(escrow) as IEscrow

  // Update the escrow object
  const res = await updateEscrow({
    escrow: parsedEscrow,
    required_organization_approvals: [
      {
        id: parsedEscrow.organizations[0].id,
        number_of_approvals: Number(party_a_approver_count ?? 0),
        require_approval: partyARequiresApproval,
      },
      {
        id: parsedEscrow.organizations[1].id,
        number_of_approvals: Number(party_b_approver_count ?? 0),
        require_approval: partyBRequiresApproval,
      },
    ],
  })

  // Check it was created ok
  if (res.error || !res.data) {
    throw new Error(res.message)
  }

  // Invalidate the escrow cache
  await queryClient.invalidateQueries({
    queryKey: [QueryEscrowKey, parsedEscrow.id],
  })

  return {
    error: false,
    message: t('escrow_updated_successfully'),
    completion: 'COMPLETE',
    data: res.data.id,
  }
}
