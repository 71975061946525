import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const useId = () => {
  const [_ids, setIds] = useState<{ [id: string]: string }>({})

  const getId = (id: string) => {
    // Check if we already have this id added
    if (id in _ids) {
      return _ids[id]
    }

    // Otherwise we'll create a new Id
    const newId = uuidv4()
    setIds({
      ..._ids,
      [id]: newId,
    })
    return newId
  }

  return { id: getId }
}
