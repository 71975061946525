import { IWorkspace } from '@/types/workspace'

interface IWorkspaceManager {
  // Current states
  workspaces: IWorkspace[]
  selectedWorkspace: IWorkspace | null
  lastUsedWorkspace: IWorkspace | null

  // Triggering a refetch of workspaces
  setUpdateWorkspacesFunction: (fn: () => Promise<IWorkspace[]>) => void
  updateWorkspaces: () => Promise<IWorkspace[]>

  // Setting a new workspace
  setSelectWorkspaceFunction: (
    fn: (workspace: IWorkspace | string | null) => void
  ) => void
  selectWorkspace: (workspace: string | IWorkspace | null) => void
}

export const WorkspaceManager: IWorkspaceManager = {
  workspaces: [],
  selectedWorkspace: null,
  lastUsedWorkspace: null,

  setUpdateWorkspacesFunction(fn) {
    this.updateWorkspaces = fn
  },
  async updateWorkspaces() {
    if (this.updateWorkspaces) {
      return await this.updateWorkspaces()
    }
    return []
  },

  setSelectWorkspaceFunction(fn) {
    this.selectWorkspace = fn
  },
  selectWorkspace(workspace: IWorkspace | string | null) {
    if (this.selectWorkspace) {
      this.selectWorkspace(workspace)
    }
  },
}
