import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import {
  IAddressAsset,
  IContractBalance,
  INetwork,
  INFT,
  ITokenBalance,
} from '@/types/evm'

export interface IGetAddressAssetsResponse {
  address: string
  assets: IAddressAsset[]
  totalNetWorth: number
  networks: string[]
}
export interface IGetAddressTokensResponse {
  address: string
  nativeTokenTicker: string
  nativeTokenBalance: number
  tokenBalances: {
    tokenBalances: ITokenBalance[]
  }
}
export interface IValidateAddressResponse {
  isValid: boolean
  addressType: string
  isZeroAddress: boolean
  isContract: boolean
  supportedNetworks?: INetwork[] | null
}
export interface IGetAddressBalanceResponse {
  validationResponse: IValidateAddressResponse
  nativeTokenBalance: number
  contractBalances?: IContractBalance[]
}
export interface IGetAddressNFTsResponse {
  ownedNfts: INFT[]
  totalCount: number
  blochHash: string
}
export interface GetTokenInfoResponse {
  name: string
  ticker: string
}
export const getAllNetworks = async (): Promise<BaseResponse<INetwork[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<BaseResponse<INetwork[]>>(`/evm/networks`)
    .then((res) => res.data)
}

export const getAddressNFTs = async (
  address: string
): Promise<BaseResponse<IGetAddressNFTsResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<BaseResponse<IGetAddressNFTsResponse>>(`/evm/${address}/networks`)
    .then((res) => res.data)
}

export const validateAddress = async (
  address: string
): Promise<BaseResponse<IValidateAddressResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<
      BaseResponse<IValidateAddressResponse>
    >(`/${address.startsWith('0x') ? 'evm' : 'blockchain'}/${address}/validation`)
    .then((res) => res.data)
}

export const getAddressTokens = async (
  address: string,
  network: string
): Promise<BaseResponse<IGetAddressTokensResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<
      BaseResponse<IGetAddressTokensResponse>
    >(`/${address.startsWith('0x') ? 'evm' : 'blockchain'}/${address}/tokens?network=${network}`)
    .then((res) => res.data)
}

export const getAddressAssets = async (
  address: string
): Promise<BaseResponse<IGetAddressAssetsResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<
      BaseResponse<IGetAddressAssetsResponse>
    >(`/${address.startsWith('0x') ? 'evm' : 'blockchain'}/${address}/assets`)
    .then((res) => res.data)
}

export const getAddressAssetsForNetwork = async (
  address: string,
  network: string
): Promise<BaseResponse<IGetAddressAssetsResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<
      BaseResponse<IGetAddressAssetsResponse>
    >(`/${address.startsWith('0x') ? 'evm' : 'blockchain'}/${address}/assets?network=${network}`)
    .then((res) => res.data)
}

export const getTokenInfo = async (
  address: string
): Promise<BaseResponse<GetTokenInfoResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<BaseResponse<GetTokenInfoResponse>>(`/evm/${address}/token/info`)
    .then((res) => res.data)
}

export const getAddressBalances = async (
  address: string,
  ticker?: string,
  network?: string
): Promise<BaseResponse<IGetAddressBalanceResponse>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  const args = [`address=${address}`]
  if (ticker) args.push(`ticker=${ticker}`)
  if (network) args.push(`network=${network}`)
  return await client
    .get<
      BaseResponse<IGetAddressBalanceResponse>
    >(`/${address.startsWith('0x') ? 'evm' : 'blockchain'}/address-balances?${args.join('&')}`)
    .then((res) => res.data)
}

// export const getTokensFromWalletAddress = async (
//   address: string,
//   network: string
// ): Promise<BaseResponse<ITokenType>> => {
//   const client = await getClient((await getConfig()).BROKER_API_URL)
//   return await client
//     .get(`/evm/${address}/tokens?network=${network}`)
//     .then((res) => res.data)
// }
