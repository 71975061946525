import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { matchPath } from 'react-router-dom'

import { refreshSession } from '@/api'
import Button from '@/components/button'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import Page from '@/components/page'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { AuthManager } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { FormErrorsManager } from '@/contexts/formErrors'
import { useWorkspace } from '@/contexts/workspace'
import { IWorkspace } from '@/types/workspace'

import { Template } from '../_components/template'

export const Workspace = () => {
  const { lastUsedWorkspace, workspaces, setWorkspace } = useWorkspace()
  const [_selectingWorkspace, setSelectingWorkspace] = useState<boolean>(false)
  const [_chosenWorkspace, setChosenWorkspace] = useState<IWorkspace | null>(
    null
  )
  const params = new URLSearchParams(location.search)
  const param_workspace_id = params.get('workspace_id') ?? ''
  const param_from = params.get('from')

  useEffect(() => {
    // Delay the action as workspaces are not completely loaded
    // at this point so we may get a few empty arrays through
    // before we get our list of workspaces
    const timer = setTimeout(() => {
      if (workspaces.length <= 0) {
        BannerManager.showBanner({
          variant: 'page',
          type: 'information',
          title: t('no_workspaces_available'),
          description: t('no_workspaces_available_description'),
        })
      } else {
        // Hide any banners as its possible we displayed a 'you have no workspaces'
        // banner prior to the point of us fetching all workspaces
        BannerManager.hideBanners('page')

        // Check to see if the workspace context has already picked out a workspace - this
        // will likely be the last workspace that was used so lets pre-select that
        setChosenWorkspace(
          workspaces.find((w) => w.id === param_workspace_id) ??
            lastUsedWorkspace ??
            workspaces[0]
        )
      }
    }, 1000)

    // Clear the timeout
    return () => clearTimeout(timer)
  }, [workspaces])

  const continueToWorkspace = async () => {
    if (!_chosenWorkspace) {
      FormErrorsManager.addErrors([
        {
          fieldName: 'chosen_workspace',
          error: t('please_choose_a_workspace_before_contuining'),
        },
      ])
    } else {
      // Set the selected workspace
      setSelectingWorkspace(true)
      setWorkspace(_chosenWorkspace)

      // Trigger a session refresh
      const res = await refreshSession()

      // Check the session refreshed OK
      if (res.error) {
        BannerManager.showBanner({
          variant: 'page',
          type: 'error',
          title: 'Failed to refresh session using the new workspace selection.',
        })
      } else {
        // Navigate somewhere
        if (param_from && !matchPath(ROUTES.AUTH.WORKSPACE, param_from)) {
          window.location.replace(param_from)
        } else {
          window.location.replace('/')
        }
        // navigate(param_from ? param_from : ROUTES.ASSETS.INDEX)
      }
    }
  }

  const logout = () => {
    AuthManager.logout()
  }

  return (
    <Template>
      <div
        className={
          'flex w-full max-w-[23.4375rem] flex-col items-start gap-[24px] p-0'
        }
      >
        <Page.Navigation className={'p-0 tablet:p-0'} backOnClick={logout} />
        <Typography variant={'heading-small'} spacers={[]}>
          {t('select_your_workspace')}
        </Typography>
        <Input.Dropdown
          name={'chosen_workspace'}
          testId={'workspace_dropdown'}
          text={
            workspaces.length <= 0 ? t('no_workspaces') : _chosenWorkspace?.name
          }
          value={_chosenWorkspace?.id}
          state={
            workspaces.length <= 0 ||
            _chosenWorkspace === null ||
            _selectingWorkspace
              ? 'readonly'
              : 'default'
          }
          icon={<Icon name={'sitemap'} variant={'solid'} family={'sharp'} />}
          items={workspaces.map((workspace, index) => (
            <ListItem
              key={`workspace_${index}`}
              title={workspace.name}
              value={workspace.id}
              style={'normal'}
              onClick={() => {
                setChosenWorkspace(workspace)
              }}
              className={'pl-4 pr-4'}
            />
          ))}
        />
        <Button.Basic
          hierarchy={'primary'}
          testId={'btn_continue_to_workspace'}
          width={'flex'}
          state={
            _chosenWorkspace === null || _selectingWorkspace
              ? 'loading'
              : workspaces.length <= 0
                ? 'disabled'
                : 'default'
          }
          label={t('continue')}
          onClick={continueToWorkspace}
        />
      </div>
    </Template>
  )
}
