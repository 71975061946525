export function getEnumValueFromString<T>(
  enumObj: T,
  value: string
): T[keyof T] | undefined {
  const enumValues = Object.values(enumObj as object) as unknown as string[]
  if (enumValues.includes(value)) {
    return value as unknown as T[keyof T]
  }
  return undefined
}

export function isEnumValue<T>(enumObj: T, value: string | undefined): boolean {
  if (value === undefined) {
    return false
  }
  return Object.values(enumObj as object).includes(value as T[keyof T])
}
