import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import { Button } from '@/components/button'
import Chip from '@/components/chip'
import Dropdown from '@/components/dropdown'
import Cell from '@/components/table/components/cell.table'
import Row from '@/components/table/components/row.table'
import { BaseTable } from '@/components/table/variants/base.table'
import { useDrawer, useDropdown } from '@/contexts/interface'
import { IUser } from '@/types/user'

import { BaseTableProps } from '../types'

type SortBy = 'az' | 'recently_added'
type FilterBy = 'all' | 'active' | 'disabled'
type GroupBy = 'all' | 'team' | 'guests'

export interface Props extends BaseTableProps {
  team: IUser[]
}

export const TeamTable: React.FC<Props> = (props) => {
  const defaultSortBy: SortBy = 'recently_added'
  const defaultFilterBy: FilterBy = 'all'
  const defaultGroupBy: GroupBy = 'all'
  const { t } = useTranslation()
  const { setDropdown } = useDropdown()
  const { setDrawer } = useDrawer()
  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const [_filterBy, setFilterBy] = useState<FilterBy>(defaultFilterBy)
  const [_groupBy] = useState<GroupBy>(defaultGroupBy)
  const [_team, setTeam] = useState<IUser[]>([])

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setTeam([...sort(filter(group(props.team)))])
  }, [props.team])

  // Monitor for sortBy changes + update
  useEffect(() => {
    setTeam([...sort(_team)])
  }, [_sortBy])

  // Monitor for groupBy changes + update
  useEffect(() => {
    setTeam([...sort(filter(group(props.team)))])
  }, [_groupBy, _filterBy])

  // Update our visibility and sort
  const onSortChanged = (id: SortBy) => setSortBy(id)
  const onFilterChanged = (id: FilterBy) => setFilterBy(id)

  // Sorts the provided data
  const sort = (team: IUser[]): IUser[] => {
    switch (_sortBy) {
      case 'az':
        return team.sort((a, b) => a.name.localeCompare(b.name))
      case 'recently_added':
        return team.sort(
          (a, b) =>
            new Date(b.created_at ?? 0).getTime() -
            new Date(a.created_at ?? 0).getTime()
        )
      default:
        return team
    }
  }

  // Filter the provided data
  const filter = (team: IUser[]): IUser[] => {
    return team.filter((t) =>
      _filterBy === 'active'
        ? !t.disabled
        : _filterBy === 'disabled'
          ? t.disabled
          : true
    )
  }

  // Group the provided data
  const group = (team: IUser[]): IUser[] => {
    // TODO
    return team.filter((t) => t)
  }

  // eslint-disable-next-line react/display-name
  const renderRows = (person: IUser, index: number) => {
    return (
      <Row
        key={person.email + index}
        onClick={() => {
          setDrawer({
            id: 'EDIT_SUPER_ADMIN_USER',
            // userId: person.id,
            user: person,
          })
        }}
      >
        <Cell.Icon>
          <Avatar type={'monogram'} value={person.name} />
        </Cell.Icon>
        <Cell.Text>{person.name}</Cell.Text>
        <Cell.Text>{person.email}</Cell.Text>
        <Cell.Text>{person.phone || '-'}</Cell.Text>
        <Cell.Text>{person.role}</Cell.Text>
        <Cell.Chip>
          <Chip.Basic
            size={'medium'}
            label={person.disabled ? t('disabled') : t('active')}
            colour={person.disabled ? 'red' : 'green'}
          />
        </Cell.Chip>
        <Cell.Button>
          {person.two_factor_enabled && (
            <Button
              layout={'icon'}
              variant={'shape'}
              size={'small'}
              shape={'square'}
              hierarchy={'tertiary'}
              icon={{
                name: 'lock',
              }}
              onClick={() => {
                console.log('share')
              }}
            />
          )}
        </Cell.Button>
        <Cell.Button>
          <Button
            id={`team_table_extra_${index}`}
            layout={'icon'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'ellipsis',
            }}
            onClick={() => {
              setDropdown({
                target: `team_table_extra_${index}`,
                controller: (
                  <Dropdown.Controllers.TeamActions member={person} />
                ),
                maxWidthPx: 270,
              })
            }}
          />
        </Cell.Button>
      </Row>
    )
  }

  return (
    <BaseTable<IUser, SortBy, FilterBy, GroupBy>
      testId={props.testId}
      filter={{
        options: [
          {
            id: 'all',
            label: t('all'),
            value: 'all',
          },
          {
            id: 'active',
            label: t('active'),
            value: 'active',
          },
          {
            id: 'disabled',
            label: t('disabled'),
            value: 'disabled',
          },
        ],
        onFilterChanged: onFilterChanged,
        defaultSelectedId: defaultFilterBy,
      }}
      sort={{
        options: [
          {
            id: 'az',
            value: 'az',
            label: t('a-z'),
            icon: 'user-circle',
          },
          {
            id: 'recently_added',
            value: 'recently_added',
            label: t('recently_added'),
            icon: 'calendar',
            iconVariant: 'regular',
          },
        ],
        onSortByChanged: onSortChanged,
        defaultSelectedId: defaultSortBy,
      }}
      headers={[
        {
          label: t('username'),
          colSpan: 2,
        },
        {
          label: t('email'),
        },
        {
          label: t('mobile'),
        },
        {
          label: t('role'),
        },
        {
          label: t('status'),
          alignment: 'center',
        },
        {
          label: t('2fa'),
          alignment: 'center',
        },
        {
          label: '',
        },
      ]}
      data={_team}
      onRenderRow={renderRows}
    />
  )
}
