// AssetDetailsGroup.tsx

import { t } from 'i18next'

import { AssetAddressSelector } from '@/components/asset/asset-address-selector'
import { AssetCalculator } from '@/components/asset/asset-calculator'
import { AssetSerialNumberSelector } from '@/components/asset/asset-serial-number-selector'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { Currency } from '@/constants/currencies.ts'
import { AssetRules, RuleMode } from '@/helpers/assetRules.ts'
import {
  AssetCategory,
  AssetTangibleType,
  IAssetCost,
  IAssetValuation,
} from '@/types/asset'

export interface AssetDetailsValues {
  make?: string
  model?: string
  serialNumber?: string
  country?: string
  address?: string
  costs?: IAssetCost[]
  valuations?: IAssetValuation[]
}

export interface AssetDetailsGroupProps {
  assetCategory: AssetCategory | undefined
  assetTangibleType: AssetTangibleType | undefined
  assetFiatCurrency: Currency | undefined
  values?: AssetDetailsValues
}

export const AssetFormDetailsGroup = ({
  assetCategory,
  assetTangibleType,
  assetFiatCurrency,
  values,
}: AssetDetailsGroupProps) => {
  const assetRules = new AssetRules(
    RuleMode.EDIT,
    assetCategory,
    assetTangibleType,
    assetFiatCurrency
  )

  const group2ShowManufacturer = assetRules.showManufacturer()
  const group2ShowModel = assetRules.showModel()
  const group2ShowCountryAndAddress = assetRules.showCountryAndAddress()
  const group2ShowSerial = assetRules.showSerial() && assetCategory
  const group2ShowValuationAndCost = assetRules.showValuationAndCost()
  const group2ShowGroup =
    group2ShowManufacturer ||
    group2ShowModel ||
    group2ShowCountryAndAddress ||
    group2ShowSerial ||
    group2ShowValuationAndCost

  if (!group2ShowGroup) {
    return null
  }

  return (
    <Group label={t('asset_details')}>
      {group2ShowManufacturer && (
        <Input.Text
          name={'asset_manufacturer'}
          testId={'asset_manufacturer'}
          value={values?.make}
          placeholder={t('select_manufacturer')}
          label={t('manufacturer')}
        />
      )}

      {group2ShowModel && (
        <Input.Text
          name={'asset_model'}
          testId={'asset_vehicle_model'}
          value={values?.model}
          placeholder={t('select_model')}
          label={t('model')}
        />
      )}

      {group2ShowCountryAndAddress && (
        <AssetAddressSelector
          selectedCountry={values?.country}
          selectedAddress={values?.address}
        />
      )}

      {group2ShowSerial && (
        <AssetSerialNumberSelector
          value={values?.serialNumber}
          category={assetCategory}
        />
      )}

      {group2ShowValuationAndCost && (
        <div className={'flex flex-col w-full gap-2'}>
          {assetTangibleType && (
            <Input.Component.Label text={t('valuation_and_cost')} />
          )}
          <AssetCalculator
            costs={values?.costs}
            valuations={values?.valuations}
            valuationCurrency={assetFiatCurrency}
          />
        </div>
      )}
    </Group>
  )
}
