import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  Avatar,
  IconProps,
  MonogramProps,
  Shape,
  Size,
  UserProps,
} from './single.avatar'

export type Type = 'monogram' | 'icon' | 'user'

export interface Props {
  size?: Size
  outline?: boolean
  shape?: Shape
  maxLength?: number
  avatars: (MonogramProps | IconProps | UserProps)[]
}

export const AvatarGroup = (props: Props) => {
  const [_maxLength] = useState<number>(
    props.maxLength !== undefined ? props.maxLength : 5
  )

  const margin: { [k in Size]: string } = {
    xxlarge: 'ml-[-2.1rem]',
    xlarge: 'ml-[-1.6rem]',
    large: 'ml-[-1.1rem]',
    medium: 'ml-[-0.85rem]',
    small: 'ml-[-0.6rem]',
    xsmall: 'ml-[-0.48rem]',
  }

  return (
    <div className={twMerge('flex flex-row')}>
      {props.avatars.slice(0, _maxLength).map((a, i) => (
        <div
          key={`avatar_${i}`}
          className={i > 0 ? margin[props.size ?? 'medium'] : ''}
        >
          {a.type === 'icon' ? (
            <Avatar
              size={props.size}
              outline={props.outline}
              shape={props.shape}
              type={a.type}
              name={a.name}
              family={a.family}
              iconVariant={a.iconVariant}
            />
          ) : a.type === 'user' ? (
            <Avatar
              size={props.size}
              outline={props.outline}
              shape={props.shape}
              type={a.type}
              image={a.image}
              fallbackImage={a.fallbackImage}
              onError={a.onError}
            />
          ) : (
            <Avatar
              size={props.size}
              outline={props.outline}
              shape={props.shape}
              type={a.type}
              value={a.value}
            />
          )}
        </div>
      ))}
      {props.avatars.length > _maxLength && (
        <div key={`avatar_overflow`} className={margin[props.size ?? 'medium']}>
          <Avatar
            size={props.size}
            outline={props.outline}
            shape={props.shape}
            type={'text'}
            value={`+${props.avatars.length - _maxLength}`}
            className={'bg-white'}
          />
        </div>
      )}
    </div>
  )
}
