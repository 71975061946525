import { IEscrowFile } from '@/types/escrows'
import { IFile } from '@/types/file.ts'

export const getFileIcon = (file: File | IFile | IEscrowFile) => {
  switch (getFileExtension(file).toUpperCase()) {
    case 'PNG':
    case 'JPG':
    case 'GIF':
      return 'file-image'
    case 'PDF':
      return 'file-pdf'
    case 'DOC':
    case 'TXT':
      return 'file-word'
    case 'XLSX':
    case 'ODS':
    case 'XLS':
    case 'CSV':
      return 'file-spreadsheet'
  }

  if (file.type === 'PDF') {
    return 'file-pdf'
  } else if (file.type === 'IMG') {
    return 'file-image'
  } else {
    return 'file-word'
  }
}

export const getFileExtension = (file: File | IFile | IEscrowFile): string => {
  const parts = file.name.split('.')
  return parts.length > 1 ? (parts.pop() ?? '') : ''
}
