import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IOperation } from '@/types/operations'

import CaseActionsView from './views/case-actions.view'

interface Props extends ControllerProps<unknown> {
  case: IOperation
}
const CaseActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      testId={'dropdown_case'}
      defaultViewId={'CASE_ACTIONS'}
      closeOnItemClick={true}
    >
      <CaseActionsView case={props.case} />
    </DropdownController>
  )
}
export default CaseActionsController
