import { AvatarGroup, Props as AvatarGroupProps } from './variants/group.avatar'
import {
  Avatar as SingleAvatar,
  Props as AvatarProps,
} from './variants/single.avatar'

type VariantGroupProps = {
  variant: 'group'
} & AvatarGroupProps

type VariantAvatarProps = {
  variant?: 'avatar'
} & AvatarProps

export type Props = VariantGroupProps | VariantAvatarProps
export const Avatar = (props: Props) => {
  switch (props.variant) {
    case 'group':
      return <AvatarGroup {...props} />

    default:
      return <SingleAvatar {...props} />
  }
}
