import { twMerge } from 'tailwind-merge'

// import { HelpsettingImage } from '@/assets/images'
import {
  Typography,
  Variant as TypographyVariant,
} from '@/components/typography'

import { Icon, IconProps as IconCompProps } from '../../icon'
import { Image } from '../../image'

export type Size =
  | 'xxlarge'
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'xsmall'
export type Type = 'monogram' | 'text' | 'icon' | 'user'
export type Shape = 'round' | 'square'

interface BaseProps {
  type: Type
  id?: string
  size?: Size
  shape?: Shape
  outline?: boolean
  className?: string
  onClick?: () => void
}

export interface MonogramProps {
  type: 'monogram'
  value: string
}

export interface TextProps {
  type: 'text'
  value: string
}

export interface IconProps {
  type: 'icon'
  name: IconCompProps['name']
  family?: IconCompProps['family']
  iconVariant?: IconCompProps['variant']
  iconClassName?: string
}

export interface UserProps {
  type: 'user'
  image: string
  fallbackImage?: string
  onError?: () => void
}

export type Props = (MonogramProps | TextProps | IconProps | UserProps) &
  BaseProps
export const Avatar = (props: Props) => {
  const defaultSize: Size = 'medium'
  // Set some defaults
  props = {
    size: defaultSize,
    shape: 'round',
    outline: false,
    ...props,
  }

  const dimensions: { [k in Size]: string } = {
    xxlarge: 'w-[9.25rem] h-[9.25rem] border-[0.25rem]',
    xlarge: 'w-[5rem] h-[5rem] border-[0.1875rem]',
    large: 'w-[3.5rem] h-[3.5rem] border-[0.125rem]',
    medium: 'w-[2.5rem] h-[2.5rem] border-[0.125rem]',
    small: 'w-[2rem] h-[2rem] border-[0.09375rem]',
    xsmall: 'w-[1.5rem] h-[1.5rem] border-[1px]',
  }

  const textSize: { [k in Size]: TypographyVariant } = {
    xxlarge: 'heading-medium',
    xlarge: 'heading-small',
    large: 'label-large',
    medium: 'label-medium',
    small: 'label-small',
    xsmall: 'label-extrasmall',
  }

  const borderRadius: { [k in Size]: string } = {
    xxlarge: 'rounded-[0.75rem]',
    xlarge: 'rounded-[0.75rem]',
    large: 'rounded-[0.625rem]',
    medium: 'rounded-[0.5rem]',
    small: 'rounded-[6px]',
    xsmall: 'rounded-[6px]',
  }

  const getMonogramLetters = (value: string) => {
    const formatted = value.replace(/\W+/g, ' ').trim()
    if (props.size === 'xsmall') {
      // For xsmall we'll only return the first letter
      return `${formatted.substring(0, 1)}`
    } else if (value.includes(' ')) {
      // If spaces are found then we'll pick the leading letters
      const split = formatted.split(' ')
      if (split.length >= 2) {
        return `${split[0].substring(0, 1).toUpperCase()}${split[1]
          .substring(0, 1)
          .toUpperCase()}`
      }
    }

    // Otherwise we'll fall back to throwing back the first letters
    return `${formatted.substring(0, 2)}`.toUpperCase()
  }

  const getRandomBackgroundColour = (seedStr: string) => {
    let seed = 0
    // const monogram = getMonogramLetters(seedStr)
    for (let i = 0; i < seedStr.length; i++) {
      const val = seedStr.charCodeAt(i)
      seed = seed * i + (isNaN(val) ? 0 : val)
    }

    // Generate random RGB values based on the seed
    const r = Math.floor(Math.abs(Math.sin(seed++) * 250))
    const g = Math.floor(Math.abs(Math.sin(seed++) * 130))
    const b = Math.floor(Math.abs(Math.sin(seed++) * 130))

    // Convert RGB values to hex
    const rgb = (r << 16) + (g << 8) + b
    const hex = '#' + (0x1000000 + rgb).toString(16).slice(1)

    return hex
  }

  return (
    <div
      id={props.id}
      className={twMerge(
        'flex items-center justify-center bg-gray-100 overflow-hidden',
        'border-solid border-transparent',
        dimensions[props.size ?? defaultSize],
        props.shape === 'round'
          ? 'rounded-full'
          : borderRadius[props.size ?? defaultSize],
        props.outline ? 'border-white' : 'border-0',
        props.onClick && 'cursor-pointer',
        props.className
      )}
      {...(props.onClick && {
        onClick: props.onClick,
      })}
      style={
        props.type === 'monogram'
          ? {
              backgroundColor: getRandomBackgroundColour(props.value),
            }
          : {}
      }
    >
      {props.type === 'monogram' ? (
        <Typography
          variant={textSize[props.size ?? defaultSize]}
          className={'text-white select-none'}
        >
          {getMonogramLetters(props.value)}
        </Typography>
      ) : props.type === 'text' ? (
        <Typography
          variant={textSize[props.size ?? defaultSize]}
          className={'text-black select-none'}
        >
          {props.value}
        </Typography>
      ) : props.type === 'icon' ? (
        <Icon
          name={props.name}
          family={props.family}
          variant={props.iconVariant}
          size={props.size ?? defaultSize}
          className={twMerge('text-white', props.iconClassName)}
        />
      ) : (
        <Image
          src={props.image}
          fallbackSrc={props.fallbackImage}
          alt={`avatar image`}
          className={'w-[110%] h-[110%] object-cover'}
        />
      )}
    </div>
  )
}
