import { createContext } from 'react'

import {
  __PageBannerProps,
  __SiteBannerProps,
  __WelcomeBannerProps,
} from './types'

type IBannerContext = {
  currentPageBanner: __PageBannerProps | null
  currentSiteBanner: __SiteBannerProps | null
  currentWelcomeBanner: __WelcomeBannerProps | null
}

export const BannerContext = createContext<IBannerContext>({
  currentPageBanner: null,
  currentSiteBanner: null,
  currentWelcomeBanner: null,
})
BannerContext.displayName = 'BannerContext'
