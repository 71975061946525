export default [
  '#1A4876',
  '#8E4585',
  '#828E84',
  '#3D9979',
  '#4CB7A5',
  '#6F9940',
  '#1F75FE',
  '#9D81BA',
  '#17857B',
  '#1DACD6',
  '#00C5CD',
  '#00B9FB',
  '#95918C',
  '#CDA4DE',
  '#FFAACC',
  '#E6A8D7',
  '#FF1DCE',
  '#CA3767',
  '#FF7518',
  '#9B7653',
  '#FDD5B1',
  '#FDD9B5',
  '#DD4492',
]
