import '../animations.css'

import { useEffect, useState } from 'react'

import { useBanner } from '@/contexts/banner'

import DefaultSiteBanner from '../components/default.site.banner'
import SalesSiteBanner from '../components/sales.site.banner'

export const SiteBanner = () => {
  const { currentSiteBanner: _banner } = useBanner()
  const [_hide, setHide] = useState<boolean>(false)

  useEffect(() => {
    if (_banner) {
      let autoCloseTimer: NodeJS.Timeout

      // Ensure the hide is not true
      setHide(false)

      // Check if we want to auto close the banner
      if (_banner.duration && _banner.duration > 0) {
        autoCloseTimer = setTimeout(() => {
          // Instruct the component to hide
          setHide(true)

          // Wait a delay and then hide
          setTimeout(() => {
            _banner.close?.()
          }, 1)
        }, _banner.duration)
      }

      return () => clearTimeout(autoCloseTimer)
    }

    return () => {}
  }, [_banner])

  if (!_banner) return null
  if (_banner.type === 'sales') {
    return (
      <SalesSiteBanner
        {..._banner}
        className={_hide ? 'animate-fade-out' : ''}
      />
    )
  } else if (_banner.type === 'error') {
    return (
      <DefaultSiteBanner
        {..._banner}
        className={_hide ? 'animate-fade-out' : ''}
      />
    )
  } else if (_banner.type === 'information') {
    return (
      <DefaultSiteBanner
        {..._banner}
        className={_hide ? 'animate-fade-out' : ''}
      />
    )
  } else {
    return null
  }
}
