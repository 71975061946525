import { BaseResponse } from '@/types/api'
import { HealthState, IHealth } from '@/types/health'

import { getClient, getConfig } from '../common'

export const getHealthOverview = async (): Promise<HealthState> => {
  // Make a call to get the health status of the services we're bothered
  // about.
  const res = await Promise.allSettled([broker(), amrs(), evm()])

  // Find all services that we either failed to get a response from
  // or we've received a response where 'error' is true.
  const downServices = res.filter(
    (s) => s.status === 'rejected' || s.value.error
  )

  // Return the state - this is an overview so the 3 states should
  // be good enough.
  return downServices.length === res.length
    ? 'ALL_DOWN'
    : downServices.length > 0
      ? 'SOME_SERVICES_DOWN'
      : 'ALL_GOOD'
}

export const broker = async (): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<BaseResponse<string>>(`/health/broker`)
    .then((response) => {
      return response.data
    })
}

export const amrs = async (): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<BaseResponse<string>>(`/health/amrs`)
    .then((response) => {
      return response.data
    })
}

export const evm = async (): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL)
  return await client
    .get<BaseResponse<string>>(`/health/evm`)
    .then((response) => {
      return response.data
    })
}

export const getHealthChain = async (): Promise<
  BaseResponse<IHealth[] | null>
> => {
  const client = await getClient((await getConfig()).CHAIN_API_URL)
  return await client
    .get<BaseResponse<IHealth[] | null>>(`/health/chain/detailed`)
    .then((response) => {
      return response.data
    })
}
