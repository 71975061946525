import { useCallback, useEffect } from 'react'

import { ROUTES } from '@/constants/routes'
import { STORAGE_KEYS } from '@/helpers/localStorage.ts'
import useCookies from '@/hooks/useCookies'
import useHubspot from '@/hooks/useHubspot'
import useStorage from '@/hooks/useStorage'
import { IPolicyKey } from '@/types/policies'
import { IUser } from '@/types/user'

import { queryClient } from '..'
import { AuthContext } from './context'
import { AuthManager } from './manager'
type AuthProviderProps = {
  children: JSX.Element[] | JSX.Element
}

export const AuthProvider: React.FC<AuthProviderProps> = (
  props
): JSX.Element => {
  const [user, setUser] = useStorage('@auth', null) as [
    IUser | null,
    React.Dispatch<React.SetStateAction<IUser | null>>,
  ]
  const [policies, setPolicies] = useStorage(
    '@policies',
    [] as IPolicyKey[]
  ) as [IPolicyKey[], React.Dispatch<React.SetStateAction<IPolicyKey[]>>]

  const { remove } = useCookies()
  const { clear: clearHubspot } = useHubspot()

  const logout = useCallback(() => {
    // Make sure the token is removed from the cookies
    remove('token')

    // Clear the user object from state
    setUser(null)

    // Clear the react query cache
    queryClient.clear()

    // Clear any hubspot conversations
    clearHubspot()

    // Make sure we clear any session expiration storage data
    localStorage.removeItem(STORAGE_KEYS.SESSION_EXPIRATION_KEY)

    // Redirect to the auth screen
    window.location.replace(window.location.origin + ROUTES.AUTH.INDEX)
  }, [setUser, remove])

  useEffect(() => {
    AuthManager.setUserFunction(setUser)
    AuthManager.setPoliciesFunction(setPolicies)
    AuthManager.logoutFunction(logout)
  }, [setUser, setPolicies, logout])

  useEffect(() => {
    if (user && !AuthManager.user) AuthManager.user = user
  }, [user])

  useEffect(() => {
    if (user && !AuthManager.user) AuthManager.policies = policies
  }, [policies])

  const hasPolicy = (
    policyKeys: IPolicyKey | IPolicyKey[],
    requireAll?: boolean
  ) => {
    if (user && policies.length > 0) {
      return (Array.isArray(policyKeys) ? policyKeys : [policyKeys])[
        requireAll ? 'every' : 'some'
      ]((k) => {
        return policies.find((p) => p === k)
      })
    }
    return false
  }

  return (
    <AuthContext.Provider value={{ user, policies, hasPolicy }} {...props}>
      {props.children}
    </AuthContext.Provider>
  )
}
