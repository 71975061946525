import { t } from 'i18next'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'

export interface Props {
  backLabel?: string
  backOnClick?: () => void
  backUrl?: string
  className?: string
}
export const PageNavigation = (props: Props) => {
  return (
    <div
      className={twMerge(
        'flex px-4 pb-0 flex-col items-start self-stretch',
        'tablet:pt-4',
        props.className
      )}
    >
      <Button.Back
        label={props.backLabel ?? t('back')}
        onClick={props.backOnClick}
        url={props.backUrl}
      />
    </div>
  )
}
