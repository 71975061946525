import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const AssetSkeleton: React.FC = (): JSX.Element => (
  <div className="w-full flex justify-center pt-20">
    <div className="max-w-[850px] flex flex-col flex-grow tablet:p-0 p-5">
      <Skeleton className="h-4 mb-12" width="370px" />

      <Skeleton className="h-10" width="70px" />

      <div className="flex justify-between items-center my-3">
        <Skeleton className="h-4" width="130px" />
        <Skeleton className="h-8" width="160px" />
      </div>

      <Skeleton className="h-4 my-6" width="180px" />

      <div className="flex gap-4 my-3 px-2 items-end">
        <Skeleton width="222px" height="22px" />
        <Skeleton width="180px" height="16px" />
      </div>

      <div className="flex justify-between my-6">
        <div className="flex flex-col gap-3">
          <Skeleton className="h-4" width="50px" />
          <Skeleton className="h-4" width="60px" />
        </div>
        <div className="flex flex-col gap-3">
          <Skeleton className="h-4" width="60px" />
          <Skeleton className="h-4" width="40px" />
        </div>
        <div className="flex flex-col gap-3">
          <Skeleton className="h-4" width="70px" />
          <Skeleton className="h-4" width="90px" />
        </div>
      </div>

      <Skeleton className="h-[1px] my-5" />

      <Skeleton className="h-6 mb-5" width="100px" />
      <Skeleton className="h-4" width="300px" />

      <Skeleton className="h-10 mt-10" width="100px" />
    </div>
  </div>
)
export default AssetSkeleton
