import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Divider } from '@/components/divider'
import { Spacers, Typography } from '@/components/typography'

export interface Props {
  title?: ReactNode
  subTitle?: string
  description?: ReactNode
  dividerAbove?: boolean
  dividerBelow?: boolean
  alignment?: 'left' | 'center' | 'right'
  tooltips?: {
    title?: string
    subTitle?: string
    description?: string
  }
  spacerOverrides?: {
    title?: Spacers
    subTitle?: Spacers
    description?: Spacers
  }
  classOverrides?: {
    base?: string
    title?: string
    subTitle?: string
    description?: string
  }
}

export const ParagraphBasic = (props: Props) => {
  return (
    <span
      className={twMerge(
        'flex flex-col justify-center items-center max-w-[50rem]',
        props.classOverrides && props.classOverrides.base
      )}
    >
      {props.dividerAbove === true && (
        <span className={'flex flex-col py-2 self-stretch'}>
          <Divider size="small" orientation="horizontal" />
        </span>
      )}
      <span
        className={twMerge(
          'flex flex-col justify-center items-start self-stretch',
          props.alignment === 'center' && 'items-center text-center',
          props.alignment === 'right' && 'items-end text-right'
        )}
      >
        {props.title && (
          <Typography
            variant={'heading-small'}
            tooltip={props.tooltips?.title ?? undefined}
            spacers={props.spacerOverrides?.title ?? ['bottom', 'top']}
            className={props.classOverrides && props.classOverrides.title}
          >
            {props.title}
          </Typography>
        )}
        {props.subTitle && (
          <Typography
            variant={'label-large'}
            tooltip={props.tooltips?.subTitle ?? undefined}
            spacers={props.spacerOverrides?.subTitle ?? ['bottom', 'top']}
            className={props.classOverrides && props.classOverrides.subTitle}
          >
            {props.subTitle}
          </Typography>
        )}
        {props.description && (
          <Typography
            variant={'paragraph-medium'}
            tooltip={props.tooltips?.description ?? undefined}
            spacers={props.spacerOverrides?.description ?? ['bottom']}
            className={twMerge(
              props.classOverrides && props.classOverrides.description
            )}
          >
            {props.description}
          </Typography>
        )}
      </span>
      {props.dividerBelow === true && (
        <Divider size="small" orientation="horizontal" />
      )}
    </span>
  )
}
