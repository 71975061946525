import { t } from 'i18next'

import { isEnumValue } from '@/helpers/enums.ts'
import { isDefined } from '@/helpers/isDefined.ts'
import {
  AssetCategory,
  AssetGroupedCategory,
  AssetTangibleCategory,
  AssetTangibleEnum,
  AssetTangibleType,
  DigitalWalletSource,
  IDigitalAsset,
  IPhysicalAsset,
  TangibleTypeCash,
  TangibleTypeCollectibles,
  TangibleTypeElectronics,
  TangibleTypeJewellery,
  TangibleTypeRealEstate,
  TangibleTypeVehicle,
} from '@/types/asset.ts'
import { ILocation } from '@/types/common.ts'
import { IEscrowAsset } from '@/types/escrows'

export const getGroupedCategoryConfig = (category: AssetGroupedCategory) => {
  switch (category) {
    case AssetGroupedCategory.DIGITAL:
      return {
        icon: 'usb-drive',
        title: t('digital_asset'),
        description: t('digital_item_description'),
      }
    case AssetGroupedCategory.TANGIBLE:
      return {
        icon: 'car',
        title: t('tangible_asset'),
        description: t('physical_item_description'),
      }
  }
}

export const composeAssetTypeDescription = (terms: (string | undefined)[]) => {
  return terms
    .filter((it) => it !== undefined)
    .filter((it) => (it ?? '').trim().length > 0)
    .join(' > ')
}

export const getAssetLocationDescription = (location?: ILocation) => {
  if (!isDefined(location)) {
    return ''
  }
  let country = location.country ?? ''
  if ((location.address ?? '').includes(country)) {
    country = ''
  }
  return [location.address, country]
    .filter((it) => it !== undefined)
    .filter((it) => (it ?? '').trim().length > 0)
    .join(', ')
}

const walletSourceConfigMap = (
  ws: DigitalWalletSource
): ConfigWithDescription => {
  switch (ws) {
    case DigitalWalletSource.EXISTING:
      return {
        icon: 'rectangle-barcode',
        title: t('existing_address'),
        description: t('existing_address_description'),
      }
    case DigitalWalletSource.GENERATED:
      return {
        icon: 'grid-2-plus',
        title: t('generate_an_address'),
        description: t('generate_address_description'),
      }
    case DigitalWalletSource.CUSTOM_TRACKER:
      return {
        icon: 'coin',
        title: t('custom_tracker'),
        description: t('custom_tracker_description'),
      }
  }
}

export const getWalletSourceConfig = (
  ws: DigitalWalletSource | undefined
): ConfigWithDescription | undefined => {
  if (ws === undefined) {
    return undefined
  }
  const result = walletSourceConfigMap(ws)
  if (result) {
    return result
  }
  console.error('Unknown DigitalWalletSource ' + ws)
  return undefined
}

export const getTangibleSubcategoryTitle = (
  subcategoryAndTypeEnum: [AssetTangibleCategory, AssetTangibleEnum]
) => {
  const [category, type] = subcategoryAndTypeEnum
  if (category === AssetCategory.FIAT_CASH) {
    return t('financial')
  } else if (
    category === AssetCategory.VEHICLE &&
    type === TangibleTypeVehicle
  ) {
    return t('vehicles')
  } else if (
    category === AssetCategory.REAL_ESTATE &&
    type === TangibleTypeRealEstate
  ) {
    return t('real_estate')
  } else if (
    category === AssetCategory.PERSONAL_PROPERTY &&
    type === TangibleTypeJewellery
  ) {
    return t('jewellery')
  } else if (
    category === AssetCategory.PERSONAL_PROPERTY &&
    type === TangibleTypeElectronics
  ) {
    return t('electronics')
  } else if (
    category === AssetCategory.PERSONAL_PROPERTY &&
    type === TangibleTypeCollectibles
  ) {
    return t('collectibles')
  } else {
    console.error(`Unknown TangibleSubcategoryTitle: ${category}, ${type}`)
    return undefined
  }
}

interface ConfigWithDescription {
  icon: string
  title: string
  description: string
}

interface TangibleTypeConfig {
  icon: string
  title: string
  selectedTitle?: string
}

const getSelectableTitleForOther = (
  subcategoryAndTypeEnum: [AssetTangibleCategory, AssetTangibleEnum]
) => {
  const categoryTitle = getTangibleSubcategoryTitle(subcategoryAndTypeEnum)
  return categoryTitle + ', ' + t('other')
}

export const getTangibleTypeConfig = (
  _subcategory: AssetTangibleCategory,
  _type: AssetTangibleType
): TangibleTypeConfig | undefined => {
  switch (_subcategory) {
    case AssetCategory.FIAT_CASH:
      switch (_type) {
        case TangibleTypeCash.CASH:
          return {
            icon: 'money-bill',
            title: t('cash'),
          }
        default:
          return undefined
      }
    case AssetCategory.VEHICLE:
      switch (_type) {
        case TangibleTypeVehicle.CAR:
          return {
            icon: 'car',
            title: t('car'),
          }
        case TangibleTypeVehicle.MOTORCYCLE:
          return {
            icon: 'motorcycle',
            title: t('motorcycle'),
          }
        case TangibleTypeVehicle.SCOOTER:
          return {
            icon: 'moped',
            title: t('scooter'),
          }
        case TangibleTypeVehicle.VAN:
          return {
            icon: 'truck',
            title: t('van'),
          }
        case TangibleTypeVehicle.TRUCK:
          return {
            icon: 'truck-moving',
            title: t('truck'),
          }
        case TangibleTypeVehicle.SUV:
          return {
            icon: 'car-side',
            title: t('suv'),
          }
        case TangibleTypeVehicle.SEDAN:
          return {
            icon: 'car',
            title: t('sedan'),
          }
        case TangibleTypeVehicle.BUS:
          return {
            icon: 'bus',
            title: t('bus'),
          }
        case TangibleTypeVehicle.CARAVAN:
          return {
            icon: 'caravan',
            title: t('caravan'),
          }
        case TangibleTypeVehicle.BIKE:
          return {
            icon: 'bicycle',
            title: t('bike'),
          }
        case TangibleTypeVehicle.AIRPLANE:
          return {
            icon: 'plane',
            title: t('airplane'),
          }
        case TangibleTypeVehicle.HELICOPTER:
          return {
            icon: 'helicopter',
            title: t('helicopter'),
          }
        case TangibleTypeVehicle.BOAT:
          return {
            icon: 'ship',
            title: t('boat'),
          }
        case TangibleTypeVehicle.YACHT:
          return {
            icon: 'sailboat',
            title: t('yacht'),
          }
        case TangibleTypeVehicle.OTHER:
          return {
            icon: 'steering-wheel',
            title: t('other'),
            selectedTitle: getSelectableTitleForOther([
              AssetCategory.VEHICLE,
              TangibleTypeVehicle,
            ]),
          }
        default:
          return undefined
      }
    case AssetCategory.PERSONAL_PROPERTY:
      switch (_type) {
        /*
        //////////////////    Jewellery   ////////////////////////////
        */
        case TangibleTypeJewellery.WATCH:
          return {
            icon: 'watch',
            title: t('watch'),
          }
        case TangibleTypeJewellery.RING:
          return {
            icon: 'ring-diamond',
            title: t('ring'),
          }
        case TangibleTypeJewellery.NECKLACE:
          return {
            icon: 'circle-notch',
            title: t('necklace'),
          }
        case TangibleTypeJewellery.BRACELET:
          return {
            icon: 'ring',
            title: t('bracelet'),
          }
        case TangibleTypeJewellery.GEM:
          return {
            icon: 'gem',
            title: t('gem'),
          }
        case TangibleTypeJewellery.JEWELLERY_OTHER:
          return {
            icon: 'rings-wedding',
            title: t('other'),
            selectedTitle: getSelectableTitleForOther([
              AssetCategory.PERSONAL_PROPERTY,
              TangibleTypeJewellery,
            ]),
          }
        /*
        //////////////////    Electronics   ////////////////////////////
        */
        case TangibleTypeElectronics.DESKTOP_COMPUTER:
          return {
            icon: 'desktop',
            title: t('desktop_computer'),
          }
        case TangibleTypeElectronics.LAPTOP_COMPUTER:
          return {
            icon: 'laptop',
            title: t('laptop_computer'),
          }
        case TangibleTypeElectronics.TABLET:
          return {
            icon: 'tablet',
            title: t('tablet'),
          }
        case TangibleTypeElectronics.MOBILE_PHONE:
          return {
            icon: 'mobile',
            title: t('mobile_phone'),
          }
        case TangibleTypeElectronics.HARD_DISK_DRIVE:
          return {
            icon: 'hdd',
            title: t('hard_disk_drive'),
          }
        case TangibleTypeElectronics.USB_DRIVE:
          return {
            icon: 'usb-drive',
            title: t('usb_drive'),
          }
        case TangibleTypeElectronics.ELECTRONICS_OTHER:
          return {
            icon: 'microchip',
            title: t('other'),
            selectedTitle: getSelectableTitleForOther([
              AssetCategory.PERSONAL_PROPERTY,
              TangibleTypeElectronics,
            ]),
          }
        /*
        //////////////////    Collectibles   ////////////////////////////
        */
        case TangibleTypeCollectibles.PAINTING:
          return {
            icon: 'image',
            title: t('painting'),
          }
        case TangibleTypeCollectibles.SCULPTURE:
          return {
            icon: 'head-side',
            title: t('sculpture'),
          }
        case TangibleTypeCollectibles.ANTIQUES:
          return {
            icon: 'scroll-old',
            title: t('antiques'),
          }
        case TangibleTypeCollectibles.ALCOHOL:
          return {
            icon: 'wine-bottle',
            title: t('alcohol'),
          }
        case TangibleTypeCollectibles.OTHER:
          return {
            icon: 'coins',
            title: t('other'),
            selectedTitle: getSelectableTitleForOther([
              AssetCategory.PERSONAL_PROPERTY,
              TangibleTypeCollectibles,
            ]),
          }
        default:
          return undefined
      }
    case AssetCategory.REAL_ESTATE:
      switch (_type) {
        case TangibleTypeRealEstate.HOUSE:
          return {
            icon: 'house-chimney-window',
            title: t('house'),
          }
        case TangibleTypeRealEstate.LAND:
          return {
            icon: 'map-location-dot',
            title: t('land'),
          }
        case TangibleTypeRealEstate.VILLA:
          return {
            icon: 'house-turret',
            title: t('villa'),
          }
        case TangibleTypeRealEstate.APARTMENT_FLAT:
          return {
            icon: 'buildings',
            title: t('apartment_flat'),
          }
        case TangibleTypeRealEstate.SHED:
          return {
            icon: 'warehouse-full',
            title: t('shed'),
          }
        case TangibleTypeRealEstate.FARM:
          return {
            icon: 'farm',
            title: t('farm'),
          }
        case TangibleTypeRealEstate.OTHER:
          return {
            icon: 'house',
            title: t('other'),
            selectedTitle: getSelectableTitleForOther([
              AssetCategory.REAL_ESTATE,
              TangibleTypeRealEstate,
            ]),
          }
        default:
          return undefined
      }
  }
}

export const getTangibleTypeEnum = (
  asset: IPhysicalAsset
): AssetTangibleEnum => {
  switch (asset.category) {
    case AssetCategory.PERSONAL_PROPERTY:
      if (isEnumValue(TangibleTypeElectronics, asset.type)) {
        return TangibleTypeElectronics
      } else if (isEnumValue(TangibleTypeJewellery, asset.type)) {
        return TangibleTypeJewellery
      } else if (isEnumValue(TangibleTypeCollectibles, asset.type)) {
        return TangibleTypeCollectibles
      }
      break
    case AssetCategory.REAL_ESTATE:
      return TangibleTypeRealEstate
    case AssetCategory.VEHICLE:
      return TangibleTypeVehicle
    case AssetCategory.FIAT_CASH:
      return TangibleTypeCash
  }
  throw new Error('unexpected')
}

/**
 * Returns back the url for the ticker icon - if no value is provided
 * the default will be returned.
 *
 * @param {IDigitalAsset | string} tickerOrAsset - Either a ticker string value or a Digital Asset object
 * @returns {string} - The url of the ticker icon
 */
export const getCryptoImageUrl = (
  tickerOrAsset?: IDigitalAsset | IEscrowAsset | string
) =>
  `https://ar-icon-service.s3.eu-west-2.amazonaws.com/128/${(!tickerOrAsset
    ? 'default'
    : typeof tickerOrAsset === 'string'
      ? tickerOrAsset
      : (tickerOrAsset.ticker ?? 'default')
  )
    .toLowerCase()
    .replace('test', '')
    .replace('$', '')}.svg`

/**
 * Returns back the url for the currency icon - if no value is provided
 * the default will be returned.
 *
 * @param {string} currency - The string currency value
 * @returns {string} - The url of the currency icon
 */
export const getCurrencyImageUrl = (currency?: string) =>
  `https://ar-icon-service.s3.eu-west-2.amazonaws.com/128/${(
    currency ?? 'usd'
  ).toLowerCase()}.svg`
