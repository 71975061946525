import { getIamUser } from '@/api'
import { IPolicyKey } from '@/types/policies'
import { IUser } from '@/types/user'

interface IAuthManager {
  user: IUser | null
  policies: IPolicyKey[]
  setUserHook: React.Dispatch<React.SetStateAction<IUser | null>> | null
  setPoliciesHook: React.Dispatch<React.SetStateAction<IPolicyKey[]>> | null
  logoutHook: (() => void) | null

  setUserFunction: (
    fn: React.Dispatch<React.SetStateAction<IUser | null>>
  ) => void
  setPoliciesFunction: (
    fn: React.Dispatch<React.SetStateAction<IPolicyKey[]>>
  ) => void
  logoutFunction: (fn: () => void) => void

  setUser: (user: IUser | null) => Promise<void>
  setPolicies: (policies: IPolicyKey[]) => void
  logout: () => void
}

export const AuthManager: IAuthManager = {
  user: null,
  policies: [],
  setUserHook: null,
  setPoliciesHook: null,
  logoutHook: null,

  setUserFunction(fn) {
    this.setUserHook = fn
  },

  setPoliciesFunction(fn) {
    this.setPoliciesHook = fn
  },

  logoutFunction(fn) {
    this.logout = fn
  },

  async setUser(user: IUser | null) {
    if (this.setUserHook) {
      this.setUserHook(user)
      this.user = user

      // Get the users policies if we have a completely
      // authenticated user
      if (user?.id) {
        const iam = await getIamUser(user.id)
        this.setPolicies(iam.data?.session.policies ?? [])
      } else {
        this.setPolicies([])
      }
    }
  },

  setPolicies(policies: IPolicyKey[]) {
    if (this.setPoliciesHook) {
      this.setPoliciesHook(policies)
      this.policies = policies
    }
  },

  logout() {
    if (this.logout) {
      this.logout()
    }
  },
}
