import { t } from 'i18next'

import { addCustodian } from '@/api/org'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'

export const CreateCustodian = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('custodian_name')?.toString()

  // const notes = formData.get('case_notes')?.toString() // << Not currently implemented in BE

  // Check basic details
  if (
    !name ||
    IsNot(name?.charAt(0), REGEX.IS_STRING) ||
    IsNot(name, REGEX.IS_CASE_NAME)
  ) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'custodian_name',
        error: t('invalid_custodian_name_provided'),
      },
    ])
  }

  try {
    const data = { name: name }
    // Run through each asset
    await addCustodian(data)
    // await
  } catch (e) {
    // At this point we should throw an error but not anything that could
    // cause a re-create as the operation has been created - we can use a banner
    // instead
    console.error(e)
    return {
      error: false,
      message: `${t('successfully_added_custodian')} '${name}' ${t('but_failed_to_add_assets')}`,
      completion: 'COMPLETE_WITH_ERROR',
      data: null, // New operation Id
    }
  }

  return {
    error: false,
    message: `${t('new_custodian')} '${name}' ${t('added_successfully')}`,
    completion: 'COMPLETE',
    data: null, // New operation Id
  }
}
