import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import { Avatar } from '@/components/avatar'
import Banner from '@/components/banner'
import Button from '@/components/button'
import File from '@/components/file'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon'
import { hasOrgAlreadyApprovedFee, userCanApproveFees } from '@/helpers/escrow'
import { formatNumber } from '@/helpers/formatValue'
import { ActionResponse } from '@/types/actions'
import { IEscrow, IEscrowAsset, IEscrowFee } from '@/types/escrows'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  escrow: IEscrow
  fee?: IEscrowFee
  mode: 'setup' | 'approval' | 'readonly'
}
export const EscrowFeeModal = (props: Props) => {
  const { user } = useAuth()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_feeAsset, setFeeAsset] = useState<IEscrowAsset | null>(
    props.escrow.digital_assets.length
      ? (props.escrow.digital_assets.find(
          (a) => a.id === props.fee?.charge.asset_id
        ) ?? null)
      : null
  )

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'success',
      title: actionRes.message ?? t('escrow_updated_successfully'),
    })
    props.onClose()
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[720px]'}
    >
      <Form
        buttons={
          props.mode === 'setup'
            ? [
                ...(props.fee
                  ? [
                      <Button.Basic
                        key={`form_btn_edit_escrow_fee`}
                        hierarchy={'primary'}
                        label={t('save')}
                        state={
                          props.escrow.digital_assets.length <= 0
                            ? 'disabled'
                            : _submitting === INTENTS.ESCROW_EDIT_FEE
                              ? 'loading'
                              : 'default'
                        }
                        withAttributes={{
                          name: 'intent',
                          type: 'submit',
                          value: INTENTS.ESCROW_EDIT_FEE,
                        }}
                      />,
                    ]
                  : [
                      <Button.Basic
                        key={`form_btn_edit_escrow_fee`}
                        hierarchy={'primary'}
                        label={t('save')}
                        state={
                          props.escrow.digital_assets.length <= 0
                            ? 'disabled'
                            : _submitting === INTENTS.ESCROW_CREATE_FEE
                              ? 'loading'
                              : 'default'
                        }
                        withAttributes={{
                          name: 'intent',
                          type: 'submit',
                          value: INTENTS.ESCROW_CREATE_FEE,
                        }}
                      />,
                    ]),
                <Button.Basic
                  key={`form_btn_close`}
                  label={t('cancel')}
                  onClick={onClose}
                  hierarchy="tertiary"
                />,
              ]
            : props.mode === 'approval' &&
                userCanApproveFees(user, props.fee).length > 0
              ? [
                  <Button.Basic
                    key={`form_btn_approve_escrow_fee`}
                    hierarchy={'primary'}
                    label={t('approve')}
                    state={
                      _submitting === INTENTS.ESCROW_PROGRESS_FEE
                        ? 'loading'
                        : 'default'
                    }
                    icon={{
                      name: 'circle-check',
                    }}
                    withAttributes={{
                      name: 'intent',
                      type: 'submit',
                      value: INTENTS.ESCROW_PROGRESS_FEE,
                    }}
                    className={'pl-[8px]'}
                  />,
                  <Button.Basic
                    key={`form_btn_close`}
                    label={t('reject')}
                    onClick={onClose}
                    hierarchy="destructive"
                    state={
                      _submitting === INTENTS.ESCROW_REJECT_FEE
                        ? 'loading'
                        : 'default'
                    }
                    icon={{
                      name: 'circle-xmark',
                    }}
                    withAttributes={{
                      name: 'intent',
                      type: 'submit',
                      value: INTENTS.ESCROW_REJECT_FEE,
                    }}
                    className={'pl-[8px]'}
                  />,
                  <Button.Basic
                    key={`form_btn_close`}
                    label={t('cancel')}
                    onClick={onClose}
                    hierarchy="tertiary"
                  />,
                ]
              : [
                  <Button.Basic
                    key={`form_btn_close`}
                    label={t('cancel')}
                    onClick={onClose}
                    hierarchy="tertiary"
                  />,
                ]
        }
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={
          'flex p-[32px] flex-col items-start gap-0 w-full max-w-[720px]'
        }
      >
        <input
          type={'hidden'}
          name={'escrow'}
          value={JSON.stringify(props.escrow)}
        />
        <input type={'hidden'} name={'fee_id'} value={props.fee?.id} />
        <div className={'flex flex-col pb-6 gap-[24px]'}>
          <Paragraph
            title={
              props.mode === 'setup' && props.fee
                ? t('edit_escrow_fee')
                : props.mode === 'setup'
                  ? t('create_escrow_fee')
                  : props.mode === 'approval'
                    ? t('approve_escrow_fee')
                    : t('escrow_fee')
            }
            description={
              props.mode === 'setup' ? t('create_escrow_fee_description') : ''
            }
            spacerOverrides={{
              description: [],
              title: ['bottom'],
            }}
          />
          <Group label={t('details')} className={'flex-col gap-[24px]'}>
            <Paragraph
              title={props.escrow.name}
              spacerOverrides={{
                title: [],
              }}
            />
            <Input.IDNumber
              id={props.escrow.platform_id ?? ''}
              hideLabel={true}
            />
          </Group>
          {props.escrow.digital_assets.length <= 0 ? (
            <Group>
              <NoData
                iconName="circle-exclamation"
                description="No assets have been added to this Escrow yet."
              />
            </Group>
          ) : (
            <>
              <Group
                label={t(props.mode === 'setup' ? 'fee_setup' : 'fee_details')}
                className={'gap-[8px]'}
              >
                <Paragraph
                  description={
                    props.mode === 'setup'
                      ? 'The fee must be taken from one of the assets held in the Escrow. Please select the asset and the value of that asset to take as a fee.'
                      : ''
                  }
                />
                <div className={'flex flex-col gap-[16px]'}>
                  {['approval', 'readonly'].includes(props.mode) ? (
                    <div className={''}>
                      <Input.Text
                        name="fee_value"
                        placeholder="0.0"
                        state={'readonly'}
                        value={`${formatNumber(props.fee?.charge.amount ?? 0, {
                          notation: 'standard',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 18,
                        })} ${_feeAsset?.ticker}`}
                      />
                    </div>
                  ) : (
                    <div
                      className={'flex flex-row gap-[16px] max-w-[23.4375rem]'}
                    >
                      <Input.Number
                        name="fee_value"
                        placeholder="0.0"
                        className={'text-right'}
                        value={
                          props.fee?.charge.amount
                            ? formatNumber(props.fee?.charge.amount, {
                                notation: 'standard',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 18,
                              })
                            : undefined
                        }
                      />
                      <Input.Dropdown
                        name="fee_asset"
                        items={props.escrow.digital_assets.map((a) => (
                          <ListItem
                            key={a.id}
                            title={a.ticker}
                            style={'bold'}
                            leading={
                              <Avatar
                                type={'user'}
                                size={'xsmall'}
                                image={getCryptoImageUrl(a)}
                                fallbackImage={getCryptoImageUrl()}
                              />
                            }
                            value={a.id}
                            className="pl-2 pr-2"
                          />
                        ))}
                        onItemSelectionChange={(ticker: string | null) => {
                          const _asset =
                            props.escrow.digital_assets.find(
                              (a) => a.ticker === ticker
                            ) ?? null
                          setFeeAsset(_asset)
                        }}
                        defaultValue={_feeAsset?.id}
                        className={'max-w-[11rem]'}
                      />
                    </div>
                  )}
                  {_feeAsset && (
                    <div className={'flex flex-row gap-[8px] items-center'}>
                      <Avatar
                        type={'user'}
                        size={'xsmall'}
                        image={getCryptoImageUrl(_feeAsset)}
                        fallbackImage={getCryptoImageUrl()}
                      />
                      <Input.Component.Hint
                        style={'hint'}
                        label={t('balance_in_escrow', {
                          BALANCE: formatNumber(_feeAsset.balance ?? 0, {
                            notation: 'standard',
                            maximumFractionDigits: 18,
                          }),
                          TICKER: _feeAsset.ticker,
                        })}
                      />
                    </div>
                  )}
                </div>
              </Group>

              {props.mode === 'setup' && (
                <File.Upload
                  id="invoice_file"
                  name="invoice_file"
                  label={t('invoice')}
                  innerText={t('attach_invoice')}
                  maxFilesAllowed={1}
                  allowedFileTypes={['DOC', 'PDF', 'TXT']}
                  showFileList={true}
                  files={props.fee?.files ?? []}
                />
              )}
            </>
          )}
          {props.mode === 'approval' &&
            hasOrgAlreadyApprovedFee(
              user?.organization_id ?? '',
              props.fee
            ) && (
              <Banner.StaticPage
                type={'success'}
                title={t('fee_already_approved_title')}
                description={t('fee_already_approved_description', {
                  APPROVALS_GIVEN: props.fee?.approvals?.length ?? 0,
                  TOTAL_APPROVALS: props.escrow.organizations.length,
                })}
              />
            )}
        </div>
      </Form>
    </Modal>
  )
}
