import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { getSharedAssets } from '@/api'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IAsset } from '@/types/asset'

export interface LoaderData {
  asset: IAsset
  sessionToken: string
}
export const loader: LoaderFunction = async ({
  request,
}): Promise<LoaderData> => {
  try {
    const token = new URL(request.url).searchParams.get('token')
    if (!token) {
      return ErrorHandler(404, t('missing_params'))
    }

    // Make the call to grab the asset based on the token we have in the url
    const res = await getSharedAssets(token)

    // Check we got the asset ok
    if (res.error || !res.data || res.data.length <= 0) {
      return ErrorHandler(404, ExtractErrorFrom(res) ?? t('not_found'))
    }

    return { asset: res.data[0], sessionToken: token }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error) ?? t('failed_to_load_asset')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
