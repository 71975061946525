import { t } from 'i18next'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { useDrawer } from '@/contexts/interface'
import { IUser } from '@/types/user'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  member: IUser
}
const TeamActionsView = (props: Props) => {
  const { setDrawer } = useDrawer()

  const onViewDetails = () => {
    setDrawer({
      id: 'EDIT_TEAM_USER',
      userId: props.member.id,
    })
  }

  return (
    <DropdownView
      {...props}
      id={'TEAM_ACTIONS'}
      items={[
        {
          title: t('view_details'),
          icon: 'eye',
          onClick: onViewDetails,
        },
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default TeamActionsView
