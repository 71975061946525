import { ListItemBasic, Props as BasicProps } from './variants/basic.listitem'
import {
  ListItemLabel,
  Props as ListItemLabelProps,
} from './variants/label.listitem'

type LabelListItemProps = {
  variant: 'label'
} & ListItemLabelProps

type BasicCardProps<T> = {
  variant?: 'basic'
} & BasicProps<T>

export type Props<T> = BasicCardProps<T> | LabelListItemProps

export const ListItem = <T,>(props: Props<T>) => {
  switch (props.variant) {
    case 'label':
      return <ListItemLabel {...props} />

    default:
      return <ListItemBasic {...props} />
  }
}
