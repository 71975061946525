import { t } from 'i18next'

import { getAllGroups, updateGroup } from '@/api'
import { IGroup } from '@/types/group.ts'

export const getAccountOwnerGroupOrError: () => Promise<IGroup> = async () => {
  // Finally we need to either add the user into the owner group IF they are an owner
  // or if they're not then we need to make sure they're not in the owner group - all other
  // groups are handled in the BE
  const groups = await getAllGroups()

  // Check that went ok
  if (groups.error || !groups.data || groups.data.length <= 0) {
    console.error('failed to get all groups')
    throw new Error(t('something_went_wrong'))
  }

  // Otherwise we have our groups so we can now find and update the owner group
  const ownerGroup = groups.data.find((g) => g.name.toLowerCase() === 'owner')

  // Check we found the group
  if (!ownerGroup) {
    console.error('owner group does not exist')
    throw new Error(t('something_went_wrong'))
  }

  return ownerGroup
}

export const updateOwnerGroupToAddUserOrError = async (
  ownerGroup: IGroup,
  newOwnerUserId: string
) => {
  const groupRes = await updateGroup(ownerGroup.id, {
    name: ownerGroup.name,
    role_ids: ownerGroup.role_ids ?? [],
    user_ids: [...new Set([...(ownerGroup.user_ids ?? []), newOwnerUserId])],
  })

  // Check the group was updated ok
  if (groupRes.error) {
    console.error('failed to update group')
    throw new Error(t('something_went_wrong'))
  }
}
export const updateOwnerGroupToRemoveUserOrError = async (
  ownerGroup: IGroup,
  userId: string
) => {
  const groupRes = await updateGroup(ownerGroup.id, {
    name: ownerGroup.name,
    role_ids: ownerGroup.role_ids ?? [],
    user_ids: [...(ownerGroup.user_ids?.filter((id) => id !== userId) ?? [])],
  })

  // Check the group was updated ok
  if (groupRes.error) {
    console.error('failed to update group')
    throw new Error(t('something_went_wrong'))
  }
}
