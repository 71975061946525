import { createContext } from 'react'

import { FormError } from './types'

interface IContext {
  errors: FormError[]
  clearError: (fieldName: string) => void
}

export const FormErrorsContext = createContext<IContext>({
  errors: [],
  clearError: () => {},
})
FormErrorsContext.displayName = 'FormErrorsContext'
