import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { downloadFile } from '@/api/files'
import Button from '@/components/button'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { getFileIcon } from '@/helpers/getFileIcon.ts'
import { FileType, IFile } from '@/types/file'

interface Props {
  files?: (File | IFile)[]
  types?: 'ANY' | FileType[]
  onDelete?: (file: IFile | File) => void
}

export const FileList = (props: Props) => {
  const [_downloadingFileIds, setDownloadingFileIds] = useState<string[]>([])

  const fileDownload = async (file: IFile) => {
    if (!!file.id && !_downloadingFileIds.includes(file.id)) {
      try {
        // Push this file id into the collection of files being processed
        setDownloadingFileIds([..._downloadingFileIds, file.id])

        // Download the file
        await downloadFile(file)
      } catch (e) {
        console.error(e)
      } finally {
        setDownloadingFileIds(_downloadingFileIds.filter((i) => i !== file.id))
      }
    }
  }

  const onActionClick = (file: IFile | File) => {
    if (props.onDelete) {
      props.onDelete(file)
    } else if ('id' in file) {
      fileDownload(file)
    }
  }

  return (
    <div
      className={'flex flex-col items-start content-start gap-y-0 self-stretch'}
    >
      {(props.files ?? [])
        .filter((f) =>
          // If its not an array or 'ALL' then return all, otherwise only
          // return files that we want to display
          !('created_at' in f) || !Array.isArray(props.types)
            ? true
            : props.types.includes(f.type)
        )
        .map((file, index) => (
          <ListItem
            key={`file_${index}`}
            variant="basic"
            title={file.name}
            leading={
              <Icon name={getFileIcon(file)} variant="solid" size="medium" />
            }
            {...((props.onDelete || 'created_at' in file) && {
              trailing: (
                <Button.Shape
                  layout={'icon'}
                  icon={{
                    name: props.onDelete ? 'close' : 'arrow-down-to-bracket',
                  }}
                  shape={'square'}
                  size={'medium'}
                  state={
                    'id' in file &&
                    _downloadingFileIds.includes(file?.id as string)
                      ? 'loading'
                      : 'default'
                  }
                  hierarchy={'tertiary'}
                  onClick={() => {
                    onActionClick(file)
                  }}
                />
              ),
            })}
            className={twMerge('w-full max-w-[375px]')}
          />
        ))}
    </div>
  )
}
