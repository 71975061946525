import { CustomCellRendererProps } from 'ag-grid-react'

import Button from '@/components/button'

interface Props extends CustomCellRendererProps {
  onClick: (id: string) => void
}

export const IdentifierRenderer: React.FC<Props> = ({
  value,
  data,
  onClick,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className="truncate">{value}</div>
      <Button.Shape
        hierarchy="tertiary"
        layout="icon"
        size="xsmall"
        icon={{
          name: 'arrow-up-right-from-square',
        }}
        onClick={() => onClick(data.id)}
      />
    </div>
  )
}
