import { t } from 'i18next'
import { useState } from 'react'
import { useRevalidator } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { INTENTS } from '@/actions/intents'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Dropdown from '@/components/dropdown'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { useDropdown } from '@/contexts/interface'
import { ToastManager } from '@/contexts/toast'
import { TimeAgo } from '@/helpers/timeAgo'
import { ActionResponse } from '@/types/actions'
import { IAssetNote } from '@/types/asset'

interface Props {
  assetId: string
  note?: IAssetNote
  onCompleteEdit?: () => void
  className?: string
  readonly?: boolean
}

export const AssetNote = (props: Props) => {
  const { user, hasPolicy } = useAuth()
  const { setDropdown } = useDropdown()
  const { revalidate } = useRevalidator()
  const [_isEditing, setIsEditing] = useState<boolean>(!props.note ?? false)
  const [_isMine] = useState<boolean>(
    (user?.id ?? '') === (props.note?.user?.id ?? '')
  )
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
  }

  // On form submit successfully
  const onSuccess = () => {
    // First trigger a revalidate
    revalidate()

    // Then show the success
    if (_submitting === INTENTS.EDIT_ASSET_NOTE) {
      ToastManager.showToast({
        type: 'success',
        text: t('note_updated_successfully'),
      })
    } else if (_submitting === INTENTS.CREATE_ASSET_NOTE) {
      ToastManager.showToast({
        type: 'success',
        text: t('note_added_successfully'),
      })
    }

    // Then clear the submission
    clearSubmitting()

    // End the edit
    cancelEdit()
  }

  const editNote = () => {
    setIsEditing(true)
  }

  const deleteNote = () => {
    const btn = document.getElementById(`btn_delete_${props.note?.id ?? 'new'}`)
    if (btn) {
      btn.click()
    }
  }

  const cancelEdit = () => {
    props.onCompleteEdit && props.onCompleteEdit()
    setIsEditing(false)
  }

  const showActions = () => {
    setDropdown({
      target: `btn_note_actions_${props.note?.id ?? 'new'}`,
      maxWidthPx: 200,
      controller: (
        <Dropdown.Controllers.BasicList
          closeOnItemClick={true}
          items={[
            <ListItem
              key={'btn_edit'}
              title={t('edit')}
              leading={
                <Icon name={'edit'} family={'sharp'} variant={'solid'} />
              }
              onClick={editNote}
              className={'pl-2 pr-2'}
            />,
            ...(hasPolicy('CUSTODY.DELETE_NOTE')
              ? [
                  <ListItem
                    key={'btn_delete'}
                    title={t('delete')}
                    leading={
                      <Icon
                        name={'trash-alt'}
                        family={'sharp'}
                        variant={'solid'}
                      />
                    }
                    onClick={deleteNote}
                    className={'pl-2 pr-2'}
                  />,
                ]
              : []),
          ]}
        />
      ),
    })
  }

  return (
    <div
      className={twMerge(
        'group/assetnote',
        'flex flex-col items-start w-full',
        props.className
      )}
    >
      <div
        className={twMerge(
          'flex flex-col items-start w-full',
          'rounded-[6px] gap-2',
          'bg-white',
          _isEditing && 'border-[1px] border-black p-[12px] mt-6'
        )}
      >
        <div className={'flex flex-row w-full items-center gap-[8px]'}>
          <Avatar
            type={'monogram'}
            value={props.note?.user?.name ?? user?.name ?? ''}
            size={'xsmall'}
          />
          <div className={'flex flex-1 flex-row items-center'}>
            <Typography variant={'label-small'}>
              {props.note?.user?.name ?? user?.name ?? ''}
            </Typography>

            {props.note && (
              <>
                <Typography variant={'label-medium'}>{'・'}</Typography>
                <Typography variant={'paragraph-small'}>
                  {TimeAgo(
                    props.note?.created_at ?? '0',
                    new Date().toISOString()
                  )}
                </Typography>
              </>
            )}
          </div>
          {hasPolicy('CUSTODY.MANAGE_NOTE') &&
            _isMine &&
            !_isEditing &&
            !props.readonly && (
              <div className={'flex items-end'}>
                <div id={`btn_note_actions_${props.note?.id ?? 'new'}`}>
                  <Icon
                    name={'ellipsis'}
                    size={'small'}
                    variant={'solid'}
                    family={'sharp'}
                    onClick={showActions}
                  />
                </div>
              </div>
            )}
        </div>
        {_isEditing ? (
          <Form
            buttons={[
              <Button.Basic
                key={'btn_save_note'}
                hierarchy={'primary'}
                size={'small'}
                state={
                  _submitting === INTENTS.CREATE_ASSET_NOTE ||
                  _submitting === INTENTS.EDIT_ASSET_NOTE
                    ? 'loading'
                    : _submitting !== null
                      ? 'disabled'
                      : 'default'
                }
                label={t('save')}
                withAttributes={{
                  type: 'submit',
                  name: 'intent',
                  value: props.note
                    ? INTENTS.EDIT_ASSET_NOTE
                    : INTENTS.CREATE_ASSET_NOTE,
                }}
              />,
              <Button.Basic
                key={'btn_cancel_note'}
                hierarchy={'tertiary'}
                state={_submitting !== null ? 'disabled' : 'default'}
                size={'small'}
                label={t('cancel')}
                onClick={cancelEdit}
              />,
            ]}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onException={onException}
            onFailed={clearSubmitting}
            onUnknownResponse={clearSubmitting}
            buttonClassName={'justify-end p-0 pt-[8px]'}
            className={'p-0 gap-0'}
          >
            <input
              type={'hidden'}
              name={'note_obj'}
              value={props.note ? JSON.stringify(props.note) : undefined}
            />
            <input type={'hidden'} name={'asset_id'} value={props.assetId} />
            <Input.TextArea
              name={'note_text'}
              placeholder={t('add_a_note')}
              className={'border-0 p-0 w-full max-w-none py-2'}
              defaultValue={props.note?.content ?? ''}
              textAreaClassName="px-8"
            />
          </Form>
        ) : (
          <Typography variant={'paragraph-medium'}>
            {props.note?.content ?? ''}
          </Typography>
        )}
        {hasPolicy('CUSTODY.DELETE_NOTE') && (
          <Form
            buttons={[
              <Button.Basic
                id={`btn_delete_${props.note?.id ?? 'new'}`}
                key={'btn_delete_note'}
                hierarchy={'tertiary'}
                size={'small'}
                state={
                  _submitting === INTENTS.DELETE_ASSET_NOTE
                    ? 'loading'
                    : _submitting !== null
                      ? 'disabled'
                      : 'default'
                }
                label={t('delete')}
                icon={{
                  name: 'trash-alt',
                }}
                className={'hidden'}
                withAttributes={{
                  type: 'submit',
                  name: 'intent',
                  value: INTENTS.DELETE_ASSET_NOTE,
                }}
              />,
            ]}
            buttonClassName="p-0"
            className={'hidden'}
          >
            <input type={'hidden'} name={'note_id'} value={props.note?.id} />
            <input type={'hidden'} name={'asset_id'} value={props.assetId} />
          </Form>
        )}
      </div>
    </div>
  )
}
