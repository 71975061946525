import { t } from 'i18next'

import Button from '@/components/button'
import { Paragraph } from '@/components/paragraph'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  qr: string
  onContinue: () => void
}
export const TwoFactorSetupModal = (props: Props) => {
  const onContinue = () => {
    props.onClose()
    props.onContinue()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[400px]'}
    >
      <div className="flex p-6 flex-col items-center w-full max-w-[25rem] tablet:min-w-[21rem]">
        <Paragraph
          title={t('two_step_verification')}
          description={t('use_google_authenticator')}
          spacerOverrides={{ description: ['top', 'bottom'] }}
        />

        <div className="flex justify-center">
          <img
            key="qr"
            src={props.qr}
            alt="qr"
            className="max-w-[19rem] max-h-[19rem]"
          />
        </div>
        <Button.Dock>
          <Button.Basic
            key={'2step_frm_btn_continue'}
            label={t('continue')}
            width={'flex'}
            className={'max-w-none'}
            onClick={onContinue}
          />
        </Button.Dock>
      </div>
    </Modal>
  )
}
