import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

export interface Props<T> {
  id: string
  groupId: string
  value: T
  checked?: boolean
  defaultChecked?: boolean
  size?: 'large' | 'medium'
  state?: 'default' | 'disabled'
  onCheckChanged?: (id: string, groupId: string, value: T) => void
}

export const Radio = <T extends string>(props: Props<T>) => {
  const radioRef = useRef<HTMLInputElement>(null)

  // Set some default properties
  props = {
    defaultChecked: false,
    size: 'medium',
    state: 'default',
    ...props,
    checked: props.defaultChecked === true || props.checked === true,
  }

  // Monitor the checked prop - this doesn't directly go into the
  // input to avoid incorrect states - instead we'll use the ref
  // to update the checked state.
  useEffect(() => {
    if (radioRef.current) {
      radioRef.current.checked = props.checked === true
    }
  }, [props.checked])

  // Monitor when the check state changes so we can throw the
  // onCheckChanged event.
  const onChange = async () => {
    if (props.onCheckChanged) {
      props.onCheckChanged(props.id, props.groupId, props.value)
    }
  }

  return (
    <div className={'flex w-12 h-12 justify-center items-center'}>
      <label
        htmlFor={props.id}
        className={twMerge(
          'flex flex-1 relative justify-center items-center w-full h-full cursor-pointer',
          props.state === 'disabled' ? 'cursor-default' : ''
        )}
      >
        <input
          ref={radioRef}
          id={props.id}
          aria-describedby={props.id && `${props.id}-description`}
          radioGroup={props.groupId}
          name={props.groupId}
          value={props.value}
          type="radio"
          disabled={props.state === 'disabled'}
          onChange={onChange}
          // defaultChecked={props.defaultChecked === true}
          className={twMerge(
            'appearance-none bg-white m-0 h-[1.25rem] w-[1.25rem] ring-0',
            'transition-[border-color] duration-300 cursor-pointer',
            'outline-none shadow-none outline-0 shadow-0 ring-0',
            'rounded-full',
            'border-solid border-[#45484B] border-[0.125rem]',
            'focus:ring-0 focus:border-[#45484B]',
            'hover:border-[#212427]',
            'checked:border-[0.4rem] checked:border-[#212427]',
            'focus:checked:border-[#212427] hover:checked:border-[#212427]',
            'disabled:border-[#E1E1E2] disabled:cursor-default hover:disabled:border-[#E1E1E2] '
          )}
        />
      </label>
    </div>
  )
}
