import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { getFileMetadata } from '@/api/files'
import { Divider } from '@/components/divider'
import { Group } from '@/components/group'
import { useFormErrors } from '@/contexts/formErrors/useFormErrors'
import { IEscrowFile } from '@/types/escrows'
// import { useFileMetadata } from '@/hooks/queries/useFileMetadata'
import { IFile } from '@/types/file'

// import { Avatar } from '../../avatar'
import Button from '../../button'
import Input from '../../input'
import { Typography } from '../../typography'
import { FileGallery } from '../gallery'
import { FileList } from '../list'

/*

  Existing files come in as IFiles
  New files added are added as Files

  Gallery can accept both IFiles or Files
  List can accept both IFiles or Files

  When we submit all files we want to include must be in File format
  and be inside of the fileStorageInput otherwise they will be ignored.


*/
export interface Props {
  label?: string
  files?: (IFile | File | IEscrowFile)[]
  onChange?: (newFilesState: File[]) => void
  id: string
  name: string
  innerText: string
  containerClassName?: string
  hint?: string
  allowedFileTypes?: AllowedFileType[]
  maxFilesAllowed?: number
  previewShape?: 'round' | 'square'
  showPreview?: boolean // This preview sits next to the uploader
  showGallery?: boolean // This gallery shows all images above the uploader
  showFileList?: boolean // This list shows all files under the uploader
  showQuantitySelected?: boolean
  state?: 'disabled' | 'default'
  testId?: string
}

type AllowedFileType =
  | 'ALL_IMAGES'
  | 'ALL_AUDIO'
  | 'ALL_VIDEO'
  | 'PDF'
  | 'DOC'
  | 'TXT'
  | 'JPG'
  | 'PNG'
  | 'GIF'
  | 'XLS'
  | 'CSV'

export const allAllowedFileTypes: AllowedFileType[] = [
  'ALL_IMAGES',
  'ALL_AUDIO',
  'ALL_VIDEO',
  'PDF',
  'DOC',
  'TXT',
  'JPG',
  'PNG',
  'GIF',
  'XLS',
  'CSV',
]

export const FileUpload = (props: Props) => {
  props = {
    maxFilesAllowed: 1,
    ...props,
  }

  // const { metadata } = useFileMetadata()
  const fileStorageInput = useRef<HTMLInputElement>(null)
  const fileSelector = useRef<HTMLInputElement>(null)
  const [_isDragging, setIsDragging] = useState(false)
  const [_selectedFiles, _setSelectedFiles] = useState<File[]>([])
  const selectedFilesRef = useRef<File[]>(_selectedFiles)
  // const [_fileContent, setFileContent] = useState<{
  //   [filename: string]: string
  // }>({})
  const [_error, setError] = useState<string | null>(null)
  const { errors, clearError } = useFormErrors()

  // Override the setSelectedFiles so we can set the ref at the same time
  // we can then use the ref as the latest source of truth to avoid an outdated
  // state check - this should work in quick succession
  const setSelectedFiles = (files: File[]) => {
    selectedFilesRef.current = files
    _setSelectedFiles(files)
    props.onChange?.(files)
  }

  const AllowedFileTypes: { [key in AllowedFileType]: string } = {
    ALL_IMAGES: 'image/*',
    ALL_AUDIO: 'audio/*',
    ALL_VIDEO: 'video/*',
    PDF: '.pdf',
    DOC: '.doc,.docx',
    TXT: '.txt',
    JPG: '.jpg,.jpeg',
    PNG: '.png',
    GIF: '.gif',
    XLS: '.xls,.xlsx,.ods',
    CSV: '.csv',
  }

  /*
    When we get our IFile's through the props we need to convert them into normal
    File types as this is what we need to pass back to the Action.
  */
  useEffect(() => {
    const asyncFunc = async () => {
      if (Array.isArray(props.files)) {
        const addFiles: File[] = []

        // Grab the metadata for the files that don't currently have metadata
        const metadata = await getFileMetadata(
          props.files
            .filter((f) => !(f as IFile).metadata)
            .map((f) => (f as IFile).key)
        )

        // Run through each file and create a File object using the file data
        for (const f of props.files) {
          if (f instanceof File) {
            addFiles.push(f)
            continue
          }

          const mdata =
            f.metadata ?? metadata.find((m) => m.metadata.key === f.key)

          if (!mdata) {
            // We havent got metadata - we can't really do anything here
            // we tried!
            continue
          }

          const fetchRes = await fetch(mdata.url)
          const blob = await fetchRes.blob()

          // Create File object from Blob
          const file = new File([blob], mdata.metadata.title, {
            type: mdata.metadata.mimetype, //blob.type,
          })

          // Import the files
          addFiles.push(file)
        }

        // Import the original files
        importMultipleFiles(addFiles)
      }
    }
    asyncFunc()
  }, [props.files])

  useEffect(() => {
    const foundError = errors.find((e) => e.fieldName === props.name)
    if (foundError) {
      setError(foundError.error)
    } else {
      setError('')
    }
  }, [errors])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    clearError('document_new_files')
    if (files?.length) {
      importMultipleFiles(files)
    }
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)
    importMultipleFiles(event.dataTransfer.files)
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)
  }

  const importMultipleFiles = (files: FileList | File[]) => {
    let recordCount = selectedFilesRef.current.length
    for (const file of files) {
      if ((props.maxFilesAllowed ?? 1) === 1) {
        // If we land here then we can overwrite the existing image
        importFile(file)
      } else if (recordCount < (props.maxFilesAllowed ?? 1)) {
        // Otherwise we can only import if we're below the allowed amount
        importFile(file)
      } else {
        setError(t('max_files_reached'))
        break
      }

      // Increment the record counter
      recordCount += 1
    }
  }

  // Handles the import of a file - returns a boolean
  // flag as to whether a second import can be attempted
  const importFile = (newFile: File) => {
    // Reset the error
    setError('')

    // Check if we've reached our file upload limit
    if (selectedFilesRef.current.length >= (props.maxFilesAllowed ?? 1)) {
      setError(t('max_files_reached'))
      return
    }

    // Check we have a storage input available
    if (!fileStorageInput.current) {
      console.error('filestorageinput not available')
      return
    }

    // Check if we already have this file in the collection
    // We can't tell 100% but we can do a good comparison
    if (
      selectedFilesRef.current.find((f) => {
        const this_filename = f.name.split('.')[0]
        const that_filename = newFile.name.split('.')[0]
        return this_filename === that_filename && f.type === newFile.type
      })
    ) {
      return
    }

    // Push the new object into a loading state in the
    // selected files collection
    setSelectedFiles(
      (props.maxFilesAllowed ?? 1) === 1
        ? [newFile]
        : [...selectedFilesRef.current, newFile]
    )

    const fileBuffer = new DataTransfer()
    for (const f of selectedFilesRef.current) {
      fileBuffer.items.add(f)
    }
    if (fileStorageInput.current) {
      fileStorageInput.current.files = fileBuffer.files
    }
  }

  const removeFile = (rFile: IFile | File) => {
    if (
      props.state !== 'disabled' &&
      fileStorageInput.current &&
      fileStorageInput.current.files
    ) {
      const fileBuffer = new DataTransfer()

      // Remove the file from our new selection
      const newCollection = selectedFilesRef.current.filter(
        (f) => f.name !== rFile.name
      )

      // Run through our new collection and rebuild the storage buffer
      for (const f of newCollection) {
        fileBuffer.items.add(f)
      }

      // Update the storage files
      fileStorageInput.current.files = fileBuffer.files

      // Clear any selected files from the selector input
      resetSelectorInput()

      // Clear any errors
      setError(null)

      // Update the selected files collection
      setSelectedFiles([...newCollection])
    }
  }

  const resetSelectorInput = () => {
    if (fileSelector.current) {
      fileSelector.current.files = new DataTransfer().files
    }
  }

  return (
    <Group
      label={props.label ?? t('files')}
      testId={props.testId}
      className={_isDragging ? 'bg-gray-300' : 'bg-white'}
    >
      <div className={twMerge('flex flex-col gap-2 w-full')}>
        <input
          ref={fileStorageInput}
          id={props.id}
          name={props.name}
          type="file"
          className={'hidden'}
        />
        {props.state !== 'disabled' && (
          <div className="flex flex-1 flex-col-reverse gap-6 tablet:flex-row">
            <div className={twMerge('flex flex-1 flex-col gap-2')}>
              {props.showQuantitySelected && (
                <div
                  className={twMerge(
                    'flex w-full justify-end self-stretch flex-row gap-4'
                  )}
                >
                  {props.showQuantitySelected && props.maxFilesAllowed && (
                    <Input.Component.Label
                      text={`${_selectedFiles.length}/${
                        props.maxFilesAllowed ?? 1
                      }`}
                      size={'medium'}
                      classOverrides={{
                        base: 'w-auto',
                      }}
                    />
                  )}
                </div>
              )}

              {props.showGallery && (
                <FileGallery
                  files={_selectedFiles}
                  onRemoveClick={removeFile}
                  showThumbnails={true}
                />
              )}

              <div
                className={twMerge(
                  'flex flex-col justify-between items-center self-stretch gap-[24px]'
                )}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
              >
                <Typography variant="label-medium">
                  {props.innerText}
                </Typography>
                <div className={'flex flex-col items-center gap-1'}>
                  <label htmlFor={props.id}>
                    <Button.Basic
                      hierarchy={'secondary'}
                      size={'small'}
                      label={t('attach_file')}
                      onClick={() => {
                        fileSelector.current?.click()
                      }}
                      withAttributes={{
                        type: 'button',
                      }}
                    />

                    <input
                      ref={fileSelector}
                      type="file"
                      data-testid="file-upload"
                      className={'hidden'}
                      accept={
                        !props.allowedFileTypes
                          ? undefined
                          : props.allowedFileTypes
                              .map((t) => AllowedFileTypes[t])
                              .join(',')
                      }
                      onChange={onInputChange}
                      multiple={(props.maxFilesAllowed ?? 1) > 1}
                    />
                  </label>
                  <Typography
                    variant="paragraph-extrasmall"
                    className="text-gray-400"
                  >
                    {t('or_drag_and_drop')}
                  </Typography>
                </div>
              </div>
              {_error ? (
                <Input.Component.Hint label={_error} style={'negative'} />
              ) : props.hint ? (
                <Input.Component.Hint label={props.hint} style={'hint'} />
              ) : null}
            </div>
          </div>
        )}

        {props.showFileList === true && _selectedFiles.length > 0 && (
          <div className={'flex flex-col pt-[24px] gap-[24px]'}>
            <Divider />
            <FileList
              files={_selectedFiles}
              types={'ANY'}
              onDelete={removeFile}
            />
          </div>
        )}
      </div>
    </Group>
  )
}
