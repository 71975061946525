import { LogoIcon } from './icon'
import { LogoWord } from './word'
import { LogoWordMark } from './word-mark'
import { LogoWordMarkPill } from './word-mark-pill'

export default {
  Icon: LogoIcon,
  Word: LogoWord,
  WordMark: LogoWordMark,
  WordMarkPill: LogoWordMarkPill,
}
