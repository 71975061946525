import { t } from 'i18next'

import { updateOrgUser } from '@/api'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

export const EditSuperAdminUser = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('name')?.toString()
  const email = formData.get('email')?.toString()
  const phone = formData.get('phone')?.toString()
  const twoFactorEnabled = formData.get('two_factor_enabled')?.toString()

  const _user = JSON.parse(
    (formData.get('user_obj') as string) || '{}'
  ) as IUser

  // Check basic details
  if (IsNot(name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_name_provided'),
      },
    ])
  } else if (IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('invalid_email_provided'),
      },
    ])
  }
  const user = {
    ..._user,
    name,
    email,
    phone,
    two_factor_enabled: twoFactorEnabled === 'true',
  }

  try {
    await updateOrgUser(user)
  } catch (error) {
    const message = ExtractErrorFrom(error) ?? t('failed_to_load_organization')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }

  return {
    error: false,
    message: t('user_updated_successfully'),
    completion: 'COMPLETE',
    data: null,
  }
}
