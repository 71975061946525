import ReactHtmlParser from 'react-html-parser'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { Paragraph } from '@/components/paragraph'
import { Typography } from '@/components/typography'
import { __SiteBannerProps } from '@/contexts/banner/types'
import { useWindowSize } from '@/hooks/useWindowSize'

const SalesSiteBanner = (props: __SiteBannerProps) => {
  const { isMobile } = useWindowSize()
  return (
    <div className={twMerge('animate-fade-in', props.className)}>
      <div
        className={twMerge(
          'flex flex-row w-full justify-center',
          'bg-black',
          props.onClick && 'cursor-pointer'
        )}
        onClick={props.onClick}
      >
        <div
          className={twMerge(
            'flex flex-1 pl-4 pr-2 overflow-hidden items-center',
            props.description && 'pl-6 py-4',
            'tablet:justify-center'
          )}
        >
          {props.description ? (
            <Paragraph
              subTitle={props.title}
              description={ReactHtmlParser(props.description)}
              spacerOverrides={{
                subTitle: [],
                description: [],
              }}
              alignment={isMobile ? 'left' : 'center'}
              classOverrides={{
                subTitle: 'text-white',
                description: 'text-white',
              }}
            />
          ) : (
            <Typography
              variant={'label-medium'}
              className={twMerge(
                'w-full whitespace-nowrap overflow-hidden text-ellipsis',
                'text-white',
                'tablet:text-center'
              )}
            >
              {props.title}
            </Typography>
          )}
        </div>
        <div className={twMerge('p-1', props.description && 'py-2')}>
          <Button.Shape
            layout={'icon'}
            hierarchy={'primary'}
            icon={{ name: 'close', family: 'sharp', variant: 'solid' }}
            onClick={props.close}
          />
        </div>
      </div>
    </div>
  )
}
export default SalesSiteBanner
