// import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { ReactElement, useState } from 'react'

import { ButtonShape } from '@/components/button/variants/shape.button'
import { IconProps } from '@/components/icon'
import { Props as InputHintProps } from '@/components/input/components/hint.input'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame, Props as InputProps } from '../components/frame.input'
import { InputHint } from '../components/hint.input'
import { InputLabel } from '../components/label.input'

export type Props = {
  state?: 'default' | 'disabled'
  label?: string
  hint?: string | ReactElement<InputHintProps>
  icon?: ReactElement<IconProps>
  testId?: string
} & InputProps
export const PasswordInput = (props: Props) => {
  const [_showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!_showPassword)
  }

  return (
    <InputField
      testId={props.testId}
      className={props.className}
      label={
        props.label ? (
          <InputLabel state={props.state} text={props.label} />
        ) : undefined
      }
      hint={
        typeof props.hint === 'string' ? (
          <InputHint state={props.state} label={props.hint} style={'hint'} />
        ) : props.hint !== undefined ? (
          props.hint
        ) : undefined
      }
      element={
        <InputElement
          variant={'default'}
          icon={props.icon}
          state={props.state ?? 'default'}
          trailing={{
            button: (
              <ButtonShape
                hierarchy={'tertiary'}
                layout={'icon'}
                icon={{ name: _showPassword ? 'eye' : 'eye-slash' }}
                onClick={togglePasswordVisibility}
                withAttributes={{
                  type: 'button',
                }}
              />
            ),
          }}
          input={
            <InputFrame
              placeholder={props.placeholder}
              {...props}
              name={props.name}
              type={_showPassword ? 'text' : 'password'}
            />
          }
        />
      }
    />
  )
}
