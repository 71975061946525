import {
  ChangeEvent,
  JSX,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { Typography } from '@/components/typography'

export type Props = {
  /*
    The text value is not the same as value unless text is
    not supplied. This provides us with functionality to display
    1 value but have the input 'value' be a different value.
  */
  text?: string
  placeholder?: string

  setRef?: RefObject<HTMLInputElement>
  variant?: 'default' | 'dropdown' | 'id-number' | 'pin'
  size?: 'medium' | 'large'
  state?: 'default' | 'disabled'
  autoComplete?:
    | 'on'
    | 'off'
    | 'username'
    | 'current-password'
    | 'new-password'
    | 'email'
    | 'tel'
    | 'name'
    | 'organization-title'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'country-prefix'
    | 'postal-code'
    | 'cc-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string
  icon?: ReactNode
  dynamicInput?: boolean
} & Omit<JSX.IntrinsicElements['input'], 'size'>

export const InputFrame = (props: Props) => {
  const [_value, setValue] = useState<JSX.IntrinsicElements['input']['value']>(
    props.defaultValue ?? props.value ?? ''
  )
  const [_text, setText] = useState<string | undefined>(
    props.text ?? _value?.toString()
  )

  const [_isTextControlled, setIsTextControlled] = useState<boolean>(
    props.text !== undefined
  )

  // Listen for incoming value changes
  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value ?? '')

      // If we have no text value then we'll set that to be the
      // same as our 'value' value
      setText((currVal) => {
        return _isTextControlled ? currVal : (props.value?.toString() ?? '')
      })
    }
  }, [props.value])

  // List for incoming text (display) changes
  useEffect(() => {
    if (props.text !== undefined) {
      // If the user has set a value on the text property then
      // its a controlled property so lets mark it as such
      if (!_isTextControlled) {
        setIsTextControlled(true)
      }

      // Update the text value
      setText(props.text ?? '')

      // If we have no 'value' value then we'll set that to be
      // the same as our text value
      setValue((currVal) => {
        return !currVal ? props.text : currVal
      })
    }
  }, [props.text])

  // On input text change
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // When a user is typing into the input this should change
    // both the 'value' and the text as they become the same value
    setValue(e.target.value)
    setText(e.target.value)

    // Throw out the change event if we have a callback prop
    if (props.onChange) {
      props.onChange(e)
    }
  }

  const inputStyles = twMerge(
    'flex flex-1 w-full items-center m-0 p-0 overflow-hidden',
    'outline-0 border-0 focus:outline-0 focus:border-0 focus:ring-0 focus:shadow-none',
    'bg-transparent text-[#212427]',
    'text-[0.9375rem] pointer-events-none',
    props.size === 'large'
      ? 'h-7 text-[1.125rem] leading-[1.75rem]'
      : 'h-6 text-[0.9375rem] leading-[1.5rem]',
    props.variant === 'dropdown' || props.variant === 'pin'
      ? 'font-[800]'
      : 'font-[500]',
    props.state === 'disabled' || props.variant === 'dropdown'
      ? 'pointer-events-none'
      : 'pointer-events-auto',
    props.variant === 'dropdown' && 'select-none',
    'placeholder:text-[#909293] placeholder:font-normal',
    props.className
  )

  const frameInput = (
    <input
      type={props.type ?? 'text'}
      data-testid={'input_frame'}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete ?? 'off'}
      {...((props.autoComplete ?? 'off') === 'off' && {
        ['autoComplete']: 'off',
        ['data-1p-ignore']: 'true',
        ['data-lpignore']: 'true',
        ['data-bwignore']: 'true',
        ['data-form-type']: 'other',
      })}
      autoCorrect={props.autoCorrect ?? 'off'}
      {...Object.fromEntries(
        Object.entries(props).filter(
          ([key]) =>
            ![
              'setRef',
              'trailingIcon',
              'elementClassName',
              'defaultValue',
              'onClick',
              'onComplete',
              'hideClearButton',
              'testId',
            ].includes(key)
        )
      )}
      ref={props.setRef}
      name={props.name || 'input_display'}
      onChange={onChange}
      className={inputStyles}
      value={_text}
      size={undefined}
      onWheel={(e: unknown) =>
        props.type === 'number'
          ? (
              e as {
                target: HTMLInputElement
              }
            ).target.blur()
          : undefined
      }
    />
  )

  return (
    <>
      <input
        type="hidden"
        name={props.name}
        value={_value}
        onChange={() => {
          // We need this to avoid console errors
        }}
      />
      {props.state === 'disabled' ||
      props.variant === 'id-number' ||
      props.variant === 'dropdown' ? (
        <Typography
          variant={'paragraph-medium'}
          className={twMerge(inputStyles, 'inline-block text-ellipsis')}
        >
          {_text || (
            <>
              {props.placeholder ? (
                <span className="text-sm text-gray-300 font-normal">
                  {props.placeholder}
                </span>
              ) : null}
            </>
          )}
        </Typography>
      ) : props.dynamicInput ? (
        <label
          className={twMerge('input-sizer', props.icon ? 'hasIcon' : undefined)}
          data-value={_value}
        >
          {frameInput}
        </label>
      ) : (
        frameInput
      )}
    </>
  )
}
