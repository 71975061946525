import { t } from 'i18next'
import { twMerge } from 'tailwind-merge'

import Card from '@/components/card'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  invitedMembersCount: number
  invitedGuestsCount: number
  workspaceName: string
}

export const ShareAssetRequestedModal = (props: Props) => {
  return (
    <Modal visible={props.visible} onClose={props.onClose}>
      <div
        className={twMerge(
          'flex p-8 tablet:p-8 flex-col w-full',
          'items-center justify-center relative',
          'bg-left-center'
        )}
      >
        <div className={'z-[1] flex flex-1 w-full h-full bg-white rounded-sm'}>
          <Card.PageState
            type={'success'}
            title={t('share_asset_link_sent')}
            description={t('share_asset_description')}
            button={{ label: t('done'), onClick: props.onClose }}
            className={'max-w-none'}
          />
        </div>
      </div>
    </Modal>
  )
}
