import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const EscrowSkeleton: React.FC = (): JSX.Element => (
  <div className="w-full flex flex-col justify-center">
    <div className={'w-full flex flex-col max-w-[1440px]'}>
      <div className={'p-8 pt-[28px]'}>
        <Skeleton className={'h-[50px] mb-[1rem]'} width="300px" />
        <Skeleton className={'h-[50px] mb-[1rem]'} width="500px" />
        <Skeleton className={'h-[50px] mb-[2rem]'} width="200px" />
        <div className={'flex justify-between'}>
          <Skeleton className={'h-[50px] mb-[2rem]'} width="200px" />
          <Skeleton className={'h-[50px] mb-[2rem]'} width="200px" />
        </div>

        <Skeleton className={'h-[70px] mb-[0.5rem]'} width="100%" />
        <Skeleton className={'h-[70px] mb-[0.5rem]'} width="100%" />
        <Skeleton className={'h-[70px] mb-[0.5rem]'} width="100%" />
        <Skeleton className={'h-[70px] mb-[0.5rem]'} width="100%" />
        <Skeleton className={'h-[70px] mb-[0.5rem]'} width="100%" />
        <Skeleton className={'h-[70px] mb-[0.5rem]'} width="100%" />
      </div>
    </div>
  </div>
)
export default EscrowSkeleton
