import { t } from 'i18next'

import { deleteDefendant } from '@/api/broker/v2/defendants'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryDefendantsKey } from '@/hooks/queries/useDefendants'
import { ActionResponse } from '@/types/actions'
import { IDefendant } from '@/types/defendants'

export const DeleteDefendant = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const originalDefendant = formData.get('original_defendant')?.toString()

  // Validate the form data
  if (IsNot(originalDefendant, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_subject_object'),
      },
    ])
  }

  // Attempt to parse the original defenant object
  const parsedDefendant = JSON.parse(originalDefendant) as IDefendant

  // Make the call to delete the subject
  const res = await deleteDefendant(parsedDefendant.id)

  // Check if the call failed
  if (res.error) {
    throw new Error(res.message)
  }

  // Invalidate the cache
  await queryClient.invalidateQueries({ queryKey: [QueryDefendantsKey] })

  return {
    error: false,
    message: `${t('subject')} '${parsedDefendant.name}' ${t('deleted_successfully')}`,
    completion: 'COMPLETE',
    data: res.data,
  }
}
