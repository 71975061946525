import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { getStorage, updateStorage } from '@/api/broker/v2/storage'
import { useAuth } from '@/contexts/auth'
import { useWorkspace } from '@/contexts/workspace'

export const useInitialState = () => {
  const { user } = useAuth()
  const { selectedWorkspace } = useWorkspace()
  const key = selectedWorkspace ? `reports-${selectedWorkspace.id}` : null
  const { data } = useQuery({
    queryKey: ['storage', user!.id, key],
    queryFn: () =>
      getStorage(user!.id, key!)
        .then((response) => response.data?.Value)
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            return {}
          }
          throw error
        }),
    enabled: !!user && !!key,
  })
  return data
}

export const useUpdateInitialState = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { selectedWorkspace } = useWorkspace()
  const key = selectedWorkspace ? `reports-${selectedWorkspace.id}` : null
  const { mutate } = useMutation({
    mutationFn:
      user?.id && key
        ? (value: object) => updateStorage(user?.id, key, value)
        : undefined,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['storage', user?.id, key] })
    },
  })
  return mutate
}
