/*
 This file makes sure that we use the same rules that decide what is required when
 editing, when we display an asset and decide which fields to display
 */
import { t } from 'i18next'

import { Currency } from '@/constants/currencies.ts'
import { isDefined } from '@/helpers/isDefined.ts'
import {
  AssetCategory,
  AssetTangibleType,
  DigitalWalletSource,
  IAsset,
} from '@/types/asset.ts'

export enum RuleMode {
  EDIT,
  DISPLAY,
}

const serialRequiredCategories = [
  AssetCategory.VEHICLE,
  AssetCategory.REAL_ESTATE,
  AssetCategory.PERSONAL_PROPERTY,
]

export class AssetRules {
  private mode: RuleMode
  private category?: AssetCategory
  private tangibleType?: AssetTangibleType
  private currency?: Currency

  constructor(
    mode: RuleMode,
    category?: AssetCategory,
    tangibleType?: AssetTangibleType,
    currency?: Currency
  ) {
    this.mode = mode
    this.category = category
    this.tangibleType = tangibleType
    this.currency = currency
  }

  canAddPhotos(): boolean {
    return this.isTangible() && this.isTypeDefinedWhenEditingOrTrue()
  }

  showManufacturer(): boolean {
    return (
      this.category === AssetCategory.VEHICLE &&
      this.isTypeDefinedWhenEditingOrTrue()
    )
  }

  showModel(): boolean {
    return (
      this.category === AssetCategory.VEHICLE &&
      this.isTypeDefinedWhenEditingOrTrue()
    )
  }

  showCountryAndAddress(): boolean {
    return (
      this.category === AssetCategory.REAL_ESTATE &&
      this.isTypeDefinedWhenEditingOrTrue()
    )
  }

  showSerial(): boolean {
    return (
      isDefined(this.category) &&
      serialRequiredCategories.includes(this.category) &&
      this.isTypeDefinedWhenEditingOrTrue()
    )
  }

  showValuationAndCost(): boolean {
    const hasCurrencyInEditing =
      this.mode === RuleMode.EDIT ? isDefined(this.currency) : true
    return (
      (this.category === AssetCategory.FIAT_CASH && hasCurrencyInEditing) ||
      (this.isTangible() && this.isTypeDefinedWhenEditingOrTrue())
    )
  }

  private isTypeDefinedWhenEditingOrTrue(): boolean {
    if (this.mode === RuleMode.EDIT) {
      return isDefined(this.tangibleType)
    }
    return true
  }

  private isTangible(): boolean {
    return (
      this.category == AssetCategory.REAL_ESTATE ||
      this.category === AssetCategory.PERSONAL_PROPERTY ||
      this.category === AssetCategory.VEHICLE ||
      this.category === AssetCategory.FIAT_CASH
    )
  }
}

export const assetRulesCanEditDefendant = (asset: IAsset): boolean => {
  return (
    asset.category !== AssetCategory.DIGITAL ||
    asset.wallet?.source !== DigitalWalletSource.GENERATED
  )
}

export const isSerialRequired = (category: AssetCategory): boolean => {
  return serialRequiredCategories.includes(category)
}

export const getSerialMissingErrorMsg = (category: AssetCategory): string => {
  switch (category) {
    case AssetCategory.VEHICLE:
      return t('vin_should_be_filled')
    case AssetCategory.REAL_ESTATE:
      return t('plot_number_must_be_filled')
    case AssetCategory.PERSONAL_PROPERTY:
    default:
      return t('serial_number_must_be_filled')
  }
}
