import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { Paragraph } from '@/components/paragraph'
import { useBanner } from '@/contexts/banner'

export const WelcomeBanner = () => {
  const { currentWelcomeBanner: _banner } = useBanner()

  useEffect(() => {
    if (_banner) {
      let autoCloseTimer: NodeJS.Timeout

      // Check if we want to auto close the banner
      if (_banner.duration && _banner.duration > 0) {
        autoCloseTimer = setTimeout(() => {
          _banner.close?.()
        }, _banner.duration)
      }

      return () => clearTimeout(autoCloseTimer)
    }

    return () => {}
  }, [_banner])

  if (!_banner) return null
  return (
    <div
      className={twMerge(
        'flex flex-col-reverse w-full self-stretch relative animate-fade-in',
        'overflow-hidden',
        'tablet:flex-row'
      )}
    >
      <div
        className={twMerge(
          'w-full max-w-full px-6 py-6 bg-black',
          'items-start',
          'tablet:max-w-[540px] tablet:mt-0 tablet:gap-8 tablet:p-8',
          'desktop:max-w-[min(65vw,800px)] desktop:p-16'
        )}
      >
        <div className={'w-full max-w-[680px]'}>
          <div className={'hidden desktop:flex'}>
            <Paragraph
              variant={'heading'}
              title={_banner.items[0].title}
              description={_banner.items[0].description}
              classOverrides={{
                title: 'text-white',
                description: 'text-gray-100',
              }}
            />
          </div>
          <div className={'desktop:hidden'}>
            <Paragraph
              title={_banner.items[0].title}
              description={_banner.items[0].description}
              classOverrides={{
                title: 'text-white',
                description: 'text-gray-100',
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          'flex flex-1 relative min-h-[148px]',
          'bg-blue-100 bg-no-repeat bg-cover'
        )}
        style={{
          backgroundImage: `url(${_banner.items[0].image})`,
        }}
      >
        <div className={'absolute inset-0 bg-[#21242759]'} />
      </div>
    </div>
  )
}
