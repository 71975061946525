import { t } from 'i18next'

import { createNewUser, getAllRoles } from '@/api'
import { addWorkspaceMember } from '@/api/workspaces/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import {
  getAccountOwnerGroupOrError,
  updateOwnerGroupToAddUserOrError,
} from '@/helpers/accountOwnerGroupHelper.ts'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { IsNot } from '@/helpers/test'
import { key as QueryGroupsKey } from '@/hooks/queries/useGroups'
import { key as QueryOrgUsersKey } from '@/hooks/queries/useOrgUsers'
import { key as QueryWorkspaceMembersKey } from '@/hooks/queries/useWorkspaceMembers'
import { ActionResponse } from '@/types/actions'
import { IUser, IUserBase } from '@/types/user'

export const CreateUser = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('name')?.toString()?.trim() ?? ''
  const email = formData.get('email')?.toString()
  const workspaceId = formData.get('workspace_id')?.toString()
  const twoFactorEnabled = formData.get('two_factor_enabled')?.toString()
  const status = formData.get('status')?.toString()
  const roleId = formData.get('role_id') as string
  const isOwner: boolean = (formData.get('is_owner') as string) === 'true'
  const language = formData.get('language') as string

  // Check basic details
  if (IsNot(name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_name_provided'),
      },
    ])
  } else if (IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('invalid_email_provided'),
      },
    ])
  } else if (IsNot(roleId, REGEX.IS_STRING_WITH_NUMBERS) && !isOwner) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_role_selection'),
      },
    ])
  } else if (IsNot(workspaceId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('missing_workspace_id'),
      },
    ])
  }

  // Create a user object
  const newUser: IUserBase = {
    name,
    email,
    two_factor_enabled: twoFactorEnabled === 'true',
    disabled: status === 'false',
    role: 'user',
    language: language ?? 'en',
  }

  // Attempt the create the new user
  const createRes = await createNewUser([newUser])

  // Invalidate group cache
  await queryClient.invalidateQueries({
    queryKey: [QueryGroupsKey],
  })

  // Check for errors
  let error: string | null = null
  if (createRes.error) {
    error = createRes.message
  } else if (createRes.data?.errors && createRes.data.errors.length > 0) {
    error = createRes.data.errors[0]
  }

  // Check if we found an error
  if (error !== null) {
    // Check if we can understand the error
    if (error.toLowerCase().includes('email')) {
      return FormErrorsManager.addErrors([
        {
          fieldName: 'email',
          error: capitalizeFirstLetter(error),
        },
      ])
    } else {
      // Throw a generic exception
      throw new Error(error)
    }
  }

  // Check to make sure we can find the user
  // in the returned collection
  const foundUser = createRes.data?.users.find(
    (e: IUser) => e.email.toLowerCase() === newUser.email.toLowerCase()
  ) as IUser

  // If we havent found the user then exit out
  if (!foundUser) {
    throw new Error(t('something_went_wrong'))
  }

  // Invalidate user cache
  await queryClient.invalidateQueries({
    queryKey: [QueryOrgUsersKey, foundUser.organization_id],
  })

  // User is created - we now need to grab some roles
  const roles = await getAllRoles()

  // Check we found the roles ok
  if (roles.error || !roles.data || roles.data.length <= 0) {
    console.error('failed to get roles')
    throw new Error(t('something_went_wrong'))
  }

  // Find some roles
  // The 'fallbackRoleId' is the role with the lowest quantity of policies
  // we do this for safety; we can always increase the role.
  const requestedRoleId = roles.data.find((r) => r.id === roleId)?.id
  const fallbackRoleId = roles.data.sort(
    (a, b) => (a.policy_ids ?? []).length - (b.policy_ids ?? []).length
  )[0].id

  if (!isOwner) {
    // We need to (at least for now) assign a user to a workspace
    // from here - I imagine this job will get moved over to the BE eventually
    const workspaceRes = await addWorkspaceMember(
      workspaceId,
      foundUser.id,
      requestedRoleId ?? fallbackRoleId
    )

    if (workspaceRes.error) {
      throw new Error(workspaceRes.message ?? t('something_went_wrong'))
    }
  }

  if (isOwner) {
    const ownerGroup = await getAccountOwnerGroupOrError()
    await updateOwnerGroupToAddUserOrError(ownerGroup, foundUser.id)

    await queryClient.invalidateQueries({
      queryKey: [QueryGroupsKey],
    })
  }

  // Invalidate cache
  await queryClient.invalidateQueries({
    queryKey: [QueryWorkspaceMembersKey, workspaceId],
  })

  return {
    error: false,
    message: t('new_user_added_successfully'),
    completion: 'COMPLETE',
    data: foundUser.id,
  }
}
