import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Banner from '@/components/banner'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { Stepper } from '@/components/stepper'
import { Switch } from '@/components/switch'
import { BannerManager } from '@/contexts/banner'
import { ActionResponse } from '@/types/actions'
import { IEscrow } from '@/types/escrows'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  escrow: IEscrow
}
export const EscrowApproversModal = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_partyAApproverCount, setPartyAApproverCount] = useState<number>(
    props.escrow.organizations[0].number_of_approvals ?? 0
  )
  const [_partyBApproverCount, setPartyBApproverCount] = useState<number>(
    props.escrow.organizations[1].number_of_approvals ?? 0
  )
  const [_partyARequireApproval, setPartyARequireApproval] = useState<boolean>(
    props.escrow.organizations[0].require_approval === true
  )
  const [_partyBRequireApproval, setPartyBRequireApproval] = useState<boolean>(
    props.escrow.organizations[1].require_approval === true
  )

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'success',
      title: actionRes.message ?? t('escrow_updated_successfully'),
    })
    props.onClose()
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[640px]'}
    >
      <Form
        buttons={[
          <Button.Basic
            key={`form_btn_edit_approvers`}
            hierarchy={'primary'}
            label={t('add_approver')}
            state={
              _submitting === INTENTS.ESCROW_EDIT_APPROVERS
                ? 'loading'
                : 'default'
            }
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.ESCROW_EDIT_APPROVERS,
            }}
          />,
          <Button.Basic
            key={`form_btn_close`}
            label={t('cancel')}
            onClick={onClose}
            hierarchy="tertiary"
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={
          'flex p-[32px] flex-col items-start gap-0 w-full max-w-[40rem]'
        }
      >
        <input
          name={'escrow'}
          type={'hidden'}
          value={JSON.stringify(props.escrow)}
        />
        <div className={'flex flex-col pb-6 gap-[24px]'}>
          <Paragraph
            title={t('select_approver')}
            description={t('select_approver_description')}
            spacerOverrides={{
              description: [],
              title: ['bottom'],
            }}
          />
          <Group label={t('choose_approvers')} className={'gap-[24px]'}>
            <div className={'flex flex-col gap-[16px]'}>
              <Input.Text
                name="party_a_name"
                state={'readonly'}
                label={t('party_a')}
                icon={
                  <Icon name={'buildings'} family={'sharp'} variant={'solid'} />
                }
                value={props.escrow.organizations[0].name ?? '-'}
              />
              <div className={'flex flex-col gap-[8px]'}>
                <Input.Component.Label text={t('approver')} />
                <div className={'flex flex-row gap-[24px] items-center'}>
                  <Switch
                    name={'party_a_require_approval'}
                    enabled={_partyARequireApproval}
                    onChanged={(newState) => setPartyARequireApproval(newState)}
                    testId="party_a_approval_switch"
                  />
                  {_partyARequireApproval && (
                    <Stepper
                      name={'party_a_approver_count'}
                      maxValue={100}
                      minValue={1}
                      size={'medium'}
                      value={_partyAApproverCount}
                      onValueChanged={(_name: string, value: number) => {
                        setPartyAApproverCount(value)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={'flex flex-col gap-[16px]'}>
              <Input.Text
                name="party_b_name"
                state={'readonly'}
                label={t('party_b')}
                icon={
                  <Icon name={'buildings'} family={'sharp'} variant={'solid'} />
                }
                value={props.escrow.organizations[1].name ?? '-'}
              />
              <div className={'flex flex-col gap-[8px]'}>
                <Input.Component.Label text={t('approver')} />
                <div className={'flex flex-row gap-[24px] items-center'}>
                  <Switch
                    name={'party_b_require_approval'}
                    enabled={_partyBRequireApproval}
                    onChanged={(newState) => setPartyBRequireApproval(newState)}
                    testId="party_b_approval_switch"
                  />
                  {_partyBRequireApproval && (
                    <Stepper
                      name={'party_b_approver_count'}
                      maxValue={100}
                      minValue={1}
                      size={'medium'}
                      value={_partyBApproverCount}
                      onValueChanged={(_name: string, value: number) => {
                        setPartyBApproverCount(value)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Group>

          {_partyARequireApproval && _partyBRequireApproval ? (
            <Banner.StaticPage
              type={'information'}
              title={t('joint_instruction')}
              description={t('joint_instruction_description')}
            />
          ) : _partyARequireApproval || _partyBRequireApproval ? (
            <Banner.StaticPage
              type={'information'}
              title={t('unilateral_instruction')}
              description={t('unilateral_instruction_description')}
            />
          ) : null}
        </div>
      </Form>
    </Modal>
  )
}
