import { t } from 'i18next'
import moment from 'moment/moment'

export const dateToString = (date: string): string => {
  const dateMoment = moment(date)
  const isToday = dateMoment.isSame(moment(), 'day')
  if (isToday) {
    return t('date_today')
  }
  const isYesterday = dateMoment.isSame(moment().subtract(1, 'days'), 'day')
  if (isYesterday) {
    return t('date_yesterday')
  }
  return dateMoment.format('DD MMM yyyy')
}
