import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { ActionResponse } from '@/types/actions'
import { IEscrow, IEscrowAsset } from '@/types/escrows'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  asset: IEscrowAsset
  escrow: IEscrow
}
export const AddBlockchainAddressModal = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'success',
      title: t('escrow_updated_successfully'),
    })
    props.onClose()
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[640px]'}
    >
      <Form
        buttons={[
          <Button.Basic
            key={`form_btn_add_address`}
            hierarchy={'primary'}
            label={t('add_address')}
            state={
              _submitting === INTENTS.ESCROW_ADD_BLOCKCHAIN_ADDRESS
                ? 'loading'
                : 'default'
            }
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.ESCROW_ADD_BLOCKCHAIN_ADDRESS,
            }}
          />,
          <Button.Basic
            key={`form_btn_close`}
            label={t('cancel')}
            onClick={onClose}
            hierarchy="tertiary"
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={
          'flex p-[32px] flex-col items-start gap-0 w-full max-w-[40rem]'
        }
      >
        <input
          name={'escrow'}
          type={'hidden'}
          value={JSON.stringify(props.escrow)}
        />
        <input
          name={'escrow_assets'}
          type={'hidden'}
          value={JSON.stringify(props.escrow.digital_assets)}
        />
        <input name={'asset_id'} type={'hidden'} value={props.asset.id} />
        <div className={'flex flex-col pb-6'}>
          <Paragraph
            title={t('add_blockchain_address')}
            description={t('add_blockchain_address_description')}
            spacerOverrides={{
              description: ['top', 'bottom'],
              title: ['bottom'],
            }}
          />
          <Input.Text
            id="destination_address"
            name="destination_address"
            autoComplete={'off'}
            label={t('t_blockchain_address', { ticker: props.asset.ticker })}
            icon={<Icon name={'globe'} />}
            className={'max-w-none'}
          />
        </div>
      </Form>
    </Modal>
  )
}
