import ReactHtmlParser from 'react-html-parser'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { Icon } from '@/components/icon'
import { Paragraph } from '@/components/paragraph'
import { Typography } from '@/components/typography'
import { __SiteBannerProps } from '@/contexts/banner/types'

const DefaultSiteBanner = (props: __SiteBannerProps) => {
  return (
    <div className={twMerge('animate-fade-in', props.className)}>
      <div
        className={twMerge(
          'flex flex-col w-full justify-center',
          props.description && 'pt-3',
          props.onClick && 'cursor-pointer',
          props.type === 'error' ? 'bg-yellow-500' : 'bg-blue-100',
          'tablet:flex-row'
        )}
        onClick={props.onClick}
      >
        <div
          className={twMerge(
            'flex flex-1 flex-row min-h-[3rem] items-center',
            'p-1 gap-[0.125rem]',
            props.description && 'pl-4 flex-none tablet:items-start'
          )}
        >
          <div className={'px-1'}>
            <Icon
              size={'large'}
              family={'sharp'}
              variant={'solid'}
              name={
                props.type === 'error' ? 'triangle-exclamation' : 'circle-info'
              }
              className={'text-black'}
            />
          </div>
          <div className={'flex flex-1 overflow-hidden'}>
            {!props.description && (
              <Typography
                variant={'label-medium'}
                className={twMerge(
                  'w-full whitespace-nowrap overflow-hidden text-ellipsis'
                )}
              >
                {props.title}
              </Typography>
            )}
          </div>
          <Button.Shape
            layout={'icon'}
            hierarchy={'tertiary'}
            icon={{ name: 'close', family: 'sharp', variant: 'solid' }}
            onClick={props.close}
            className="tablet:hidden"
          />
        </div>

        {props.description && (
          <div className={'flex flex-1 px-6 tablet:pl-2'}>
            <Paragraph
              subTitle={props.title}
              description={ReactHtmlParser(props.description)}
              spacerOverrides={{
                subTitle: ['top'],
              }}
            />
          </div>
        )}
        <div className={twMerge('p-1 hidden tablet:flex')}>
          <Button.Shape
            layout={'icon'}
            hierarchy={'tertiary'}
            icon={{ name: 'close', family: 'sharp', variant: 'solid' }}
            onClick={props.close}
          />
        </div>
      </div>
    </div>
  )
}
export default DefaultSiteBanner
