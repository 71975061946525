import { IPrice } from '@/types/common'

/**
 * * Format currency
 */
export const formatValue = (
  price: number | string,
  currency?: string,
  options?: Intl.NumberFormatOptions
): string => {
  return Intl.NumberFormat('en', {
    currency: currency,
    style: currency ? 'currency' : 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(Number(price || 0))
}

/**
 *
 * Formats the asset price specifically
 */
export const formatAssetPrice = (assetPrice?: IPrice): string => {
  if (!assetPrice || assetPrice.is_untradeable === true) {
    return '-'
  } else {
    return formatValue(assetPrice.value ?? 0, assetPrice.currency, {
      notation: 'standard',
      compactDisplay: 'long',
    })
  }
}

/**
 * * Format number
 */
export const formatNumber = (
  price: number | undefined | string,
  options?: Intl.NumberFormatOptions
): string =>
  Intl.NumberFormat('en', {
    compactDisplay: 'short',
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  }).format(Number(price || 0))

/*
 * Format Byte
 */
export const formatBytes = (bytes?: number): string => {
  const kilobyte = 1024
  const megabyte = kilobyte * 1024
  const gigabyte = megabyte * 1024
  const terabyte = gigabyte * 1024

  if (bytes === undefined) {
    return '-'
  } else if (bytes < kilobyte) {
    return bytes + 'b'
  } else if (bytes < megabyte) {
    return (bytes / kilobyte).toFixed(2) + 'Kb'
  } else if (bytes < gigabyte) {
    return (bytes / megabyte).toFixed(2) + 'Mb'
  } else if (bytes < terabyte) {
    return (bytes / gigabyte).toFixed(2) + 'Gb'
  } else {
    return (bytes / terabyte).toFixed(2) + 'Tb'
  }
}
