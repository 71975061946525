import { t } from 'i18next'

import { updateEscrow } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryEscrowKey } from '@/hooks/queries/useEscrow'
import { ActionResponse } from '@/types/actions'
import { IEscrow, IEscrowAsset } from '@/types/escrows'

export const AddEscrowDestinationAddress = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const escrow = formData.get('escrow')?.toString()
  const escrow_assets = formData.get('escrow_assets')?.toString()
  const new_asset_id = formData.get('asset_id')?.toString()
  const new_destination_address = formData
    .get('destination_address')
    ?.toString()

  // Validate the fields
  if (IsNot(escrow, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(escrow_assets, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(new_asset_id, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(new_destination_address, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow_name',
        error: t('try_again_later'),
      },
    ])
  }

  // Attempt to parse the original escrow object
  const parsedEscrow = JSON.parse(escrow) as IEscrow

  // Attempt to parse the escrow assets object
  const parsedAssets = JSON.parse(escrow_assets) as IEscrowAsset[]

  // Update the escrow object
  const res = await updateEscrow({
    escrow: parsedEscrow,
    destination_addresses: [
      ...parsedAssets
        .filter((a) => a.destination_wallet?.address !== undefined)
        .map((a) => {
          return {
            address: a.destination_wallet!.address,
            asset_id: a.id,
          }
        }),
      {
        address: new_destination_address,
        asset_id: new_asset_id,
      },
    ],
  })

  // Check it was created ok
  if (res.error || !res.data) {
    throw new Error(res.message)
  }

  // Invalidate the escrow cache
  await queryClient.invalidateQueries({
    queryKey: [QueryEscrowKey, parsedEscrow.id],
  })

  return {
    error: false,
    message: t('escrow_updated_successfully'),
    completion: 'COMPLETE',
    data: res.data.id,
  }
}
