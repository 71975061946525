import { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import { twMerge } from 'tailwind-merge'

import { formatValue } from '@/helpers/formatValue'

import { Paragraph } from '../../paragraph'
import { Typography } from '../../typography'
import Colors from '../constants/colors'
import { BaseProps, canHide, GraphData } from '../types'
import { LegendKey } from './legendKey'

interface Props extends BaseProps {
  title?: string
  width: number
  height: number
}

export const DonutChart = (props: Props) => {
  const [_data, setData] = useState<GraphData[]>(
    props.data
      .map((d, i) => {
        return { ...d, htmlColor: d.htmlColor ?? Colors[i] }
      })
      .sort((a, b) => b.value - a.value)
  )
  const [_totalValue, setTotalValue] = useState<number>(
    props.data.reduce((prev, curr) => prev + curr.value, 0)
  )

  useEffect(() => {
    setData([...props.data]?.sort((a, b) => b.value - a.value))
    setTotalValue(
      [...props.data]
        ?.sort((a, b) => b.value - a.value)
        .reduce((prev, curr) => prev + curr.value, 0)
    )
  }, [props.data])

  return (
    <div
      className={twMerge(
        'flex w-full gap-[32px]',
        'flex-col tablet:flex-row',
        props.className
      )}
    >
      <div
        className={twMerge(
          'flex items-center justify-center',
          'relative overflow-hidden'
        )}
      >
        <Chart
          chartType="PieChart"
          data={[['Key', 'Value'], ..._data.map((d) => [d.label, d.value])]}
          options={{
            chartArea: {
              width: '100%',
              height: '100%',
            },
            width: props.width,
            height: props.height,
            pieHole: 0.79,
            pieSliceText: 'none',
            enableInteractivity: false,
            sliceVisibilityThreshold: 0,
            legend: 'none',
            is3D: false,
            colors: _data.map((d) => d.htmlColor ?? ''),
          }}
        />
        {_totalValue <= 0 && (
          <div
            className={twMerge(
              'absolute rounded-full h-[250px] w-[250px]',
              'border-solid border-[#F5F5F6] border-[28px]'
            )}
          />
        )}
        <Typography
          variant={'label-large'}
          spacers={[]}
          className={'absolute text-xl'}
        >
          {formatValue(Math.floor(_totalValue), props.currency, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).toLowerCase()}
        </Typography>
      </div>
      {props.showLegend && (
        <div
          className={
            'flex flex-1 flex-col justify-center items-start gap-[24px]'
          }
        >
          {props.title && <Paragraph subTitle={props.title} />}
          <div className={'flex flex-row flex-wrap gap-[24px]'}>
            {_data
              .filter((d) => !canHide(d))
              .sort((a, b) => b.value - a.value)
              .map((d, index) => (
                <LegendKey
                  key={index}
                  label={d.label}
                  currency={props.currency}
                  htmlColor={d.htmlColor}
                  value={d.value}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
