import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { ActionResponse } from '@/types/actions'
import { IOperation, IOperationStatus } from '@/types/operations'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  case: IOperation
  onUpdated?: () => void
}
export const EditCaseDrawer = (props: Props) => {
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    if (_submitting === INTENTS.ARCHIVE_CASE) {
      const goToUrl = `/cases?filter=archived`
      ToastManager.showToast({
        type: 'success',
        text: `Case archived successfully`,
      })
      navigate(goToUrl)
      props.onClose()
    } else if (_submitting === INTENTS.EDIT_CASE) {
      props.onUpdated && props.onUpdated()
      ToastManager.showToast({
        type: 'success',
        text: `Case updated successfully`,
      })
      props.onClose()
    } else {
      clearSubmitting()
    }
  }

  return (
    <Drawer
      title={t('edit_case')}
      description={t('new_case_drawer_subtitle')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        buttons={[
          <Button.Basic
            key={'btn_edit_case'}
            testId={'btn_edit_case'}
            label={t('save_changes')}
            state={
              _submitting === INTENTS.EDIT_CASE
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.EDIT_CASE,
            }}
          />,
          <Button.Basic
            key={'btn_archive_case'}
            testId={'btn_archive_case'}
            hierarchy={'tertiary'}
            label={t('archive_case')}
            state={
              _submitting === INTENTS.ARCHIVE_CASE
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.ARCHIVE_CASE,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <input
          type={'hidden'}
          name={'case_original'}
          value={JSON.stringify(props.case)}
        />
        <input type={'hidden'} name={'intent'} value={_submitting ?? ''} />

        <Group label={t('case_details')}>
          <Input.Text
            id="case_name"
            testId="txt_case_name"
            name="case_name"
            value={props.case.name}
            autoComplete={'off'}
            state={props.case.status === 'ARCHIVED' ? 'readonly' : 'default'}
            label={t('case_name')}
            hint={t('case_name_bottom_text')}
            placeholder={t('case_name_placeholder')}
          />
          <Input.Dropdown
            name="case_status"
            items={(['ACTIVE', 'ARCHIVED'] as IOperationStatus[]).map((s) => (
              <ListItem key={s} title={capitalizeFirstLetter(s)} value={s} />
            ))}
            defaultValue={props.case.status}
            state={'readonly'}
            label={t('status')}
            className={'max-w-[9.25rem]'}
          />
        </Group>
      </Form>
    </Drawer>
  )
}
