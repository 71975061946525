import { t } from 'i18next'

import Card from '@/components/card'
import { useOperations } from '@/hooks/queries/useOperations'
import { useReportTemplates } from '@/hooks/queries/useReportTemplates'

import { Drawer } from '../..'
import { BaseProps } from '../types'
import { ReportTemplate } from './components/reportTemplate'

export interface Props extends BaseProps {}
export const ReportingDrawer = (props: Props) => {
  const { operations } = useOperations()
  const { templates } = useReportTemplates()

  return (
    <Drawer
      title={t('report_templates')}
      description={t('report_templates_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <div
        className={'flex w-full max-w-[45rem] flex-col gap-6'}
        data-testid="templates-list"
      >
        {!templates || templates.length <= 0 ? (
          <Card.PageState
            type={'information'}
            title={t('no_templates')}
            description={t('no_templates_description')}
          />
        ) : (
          templates
            .filter(
              (t) =>
                !['employee_audit', 'valuation_report'].includes(t.template_id)
            )
            .map((t) => (
              <ReportTemplate
                key={t.template_id}
                template={t}
                operations={operations ?? []}
                showDatePickers={['year', 'month'].some((val) =>
                  t.name.toLowerCase().includes(val)
                )}
                onClose={props.onClose}
              />
            ))
        )}
      </div>
    </Drawer>
  )
}
