import { t } from 'i18next'
import Cookies from 'js-cookie'

import { getIamUser, getQRCode, getUser, login as _login } from '@/api/auth'
import { REGEX } from '@/constants/regex'
import { AuthManager } from '@/contexts/auth'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { WorkspaceManager } from '@/contexts/workspace'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { Is } from '@/helpers/test'
import i18n from '@/locale'
import { ActionResponse } from '@/types/actions'
import { IAuthenticationResponse } from '@/types/session'
import { IUser } from '@/types/user'

export const Authenticate = async (
  formData: FormData
): Promise<ActionResponse<IAuthenticationResponse> | Response> => {
  const email = formData.get('email')?.toString()?.trim()
  const password = formData.get('password')?.toString()?.trim()

  // Validate the fields
  const isEmailValid = Is(email, REGEX.IS_EMAIL)
  const isPasswordValid = Is(password, REGEX.IS_ANY)

  // Throw any errors for the invalid fields
  if (!isEmailValid || !isPasswordValid) {
    return FormErrorsManager.addErrors([
      ...(!isEmailValid
        ? [
            {
              fieldName: 'email',
              error: t('invalid_email_address_provided'),
            },
          ]
        : []),
      ...(!isPasswordValid
        ? [
            {
              fieldName: 'password',
              error: t('invalid_password_provided'),
            },
          ]
        : []),
    ])
  }

  // Clear any current session the user has selected - when logging in theyll
  // need to re-select a workspace - but we'll default pick their last chosen
  // to make life that little bit more pleasant
  WorkspaceManager.selectWorkspace(null)

  // Attempt a login
  const res = await _login(email, password)

  // Check the response
  if (res.error || !res.data) {
    throw new Error(ExtractErrorFrom(res) ?? t('something_went_wrong'))
  }

  // Create a user object
  const user: IUser = {
    id: '',
    name: 'name' in res.data ? res.data.name : '',
    email: '',
    token: 'token' in res.data ? res.data.token : '',
    role: res.data.role,
    user_id: res.data.user_id,
    organization_id: res.data.organization_id ?? '',
    created_at: new Date().toISOString(),
  }

  // If we DONT have a session token then we've received a login success
  // model which represents successful creds exchange
  if (!('session_token' in res.data)) {
    let qr: string = ''

    // Check if the user has 2 factor on
    if (!res.data.two_factor_enabled) {
      qr = await getQRCode(res.data.token ?? '')
    }

    // Set the user into the local session
    await AuthManager.setUser(user)

    // Throw back the next step process
    return {
      completion: 'COMPLETE',
      data: {
        two_step_verification: true,
        user: user,
        qr: qr,
      },
    }
  }

  // Otherwise we must have a session token to use - set a cookie
  Cookies.set('token', res.data.session_token, {
    expires: new Date(res.data.expiry),
  })

  // Wait until we know we can read the cookie
  let cookieSession = Cookies.get('token')
  const startStamp = new Date().getTime()
  while (cookieSession === undefined) {
    cookieSession = Cookies.get('token')

    // We don't want to endlessly loop so we'll only loop
    // for X seconds before we break out
    if (
      cookieSession === undefined &&
      new Date().getTime() - 5000 > startStamp
    ) {
      break
    }
  }

  // Make a call to grab the user details
  const userRes = await getUser()

  if (userRes.error || !userRes.data) {
    throw new Error(ExtractErrorFrom(userRes) ?? t('something_went_wrong'))
  }

  // Otherwise we should be good - start by creating the user
  // object
  const authUser = {
    ...userRes.data,
  }

  // We need to get the users policies using the iam endpoint
  const iam = await getIamUser(authUser.id)

  // Check we got the policies ok
  if (iam.error || !iam.data) {
    throw new Error(iam.message ?? t('something_went_wrong'))
  }

  // Set the user + policies in the state manager
  await Promise.all([
    AuthManager.setUser(authUser),
    AuthManager.setPolicies(iam.data.session.policies ?? []),
  ])

  // Set the users language
  i18n.changeLanguage(authUser.language?.toLowerCase() ?? 'en')

  return {
    error: false,
    message: t('authenticated_successfully'),
    completion: 'COMPLETE',
    data: {
      two_step_verification: false,
      user: authUser,
      qr: '',
    },
  }
}
