import { CustomCellRendererProps } from 'ag-grid-react'

import AssetIcon from '@/components/table/rows/assetIcon'

export const AssetTypeRenderer: React.FC<CustomCellRendererProps> = ({
  value,
  data,
}) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex-shrink-0">
        <AssetIcon size="small" asset={data.asset} />
      </div>
      <div className="truncate">{value}</div>
    </div>
  )
}
