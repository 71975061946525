import ReactHtmlParser from 'react-html-parser'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { Paragraph } from '@/components/paragraph'
import { PageBannerType } from '@/contexts/banner/types'

interface Props {
  type: PageBannerType
  title: string
  description?: string
  className?: string
}
const StaticPageBanner = (props: Props) => {
  return (
    <div>
      <div
        className={twMerge(
          'flex flex-col w-full justify-center',
          'rounded-[12px]',
          props.description
            ? 'pt-[8px] pr-[8px] pb-[16px]'
            : 'py-[4px] pr-[4px]',
          props.type === 'error'
            ? 'bg-yellow-500'
            : props.type === 'success'
              ? 'bg-green-100'
              : 'bg-blue-100',
          'tablet:flex-row'
        )}
      >
        <div
          className={twMerge(
            'flex flex-1 flex-row min-h-[3rem] items-center',
            'overflow-hidden',
            'gap-[0.125rem] justify-between',
            props.description && 'flex-none tablet:items-start'
          )}
        >
          <div
            className={twMerge(
              'px-[16px]',
              props.description && 'tablet:pt-[6px]'
            )}
          >
            <Icon
              size={'medium'}
              family={'sharp'}
              variant={'solid'}
              name={
                props.type === 'error'
                  ? 'triangle-exclamation'
                  : props.type === 'success'
                    ? 'circle-check'
                    : 'circle-info'
              }
              className={'text-black'}
            />
          </div>
          {!props.description && (
            <div className={'flex flex-1 overflow-hidden'}>
              <Paragraph
                subTitle={props.title}
                spacerOverrides={{
                  subTitle: ['top', 'bottom'],
                }}
              />
            </div>
          )}
        </div>

        {props.description && (
          <div className={'flex flex-1 overflow-hidden px-6 tablet:px-0'}>
            <Paragraph
              subTitle={props.title}
              description={ReactHtmlParser(props.description)}
              spacerOverrides={{
                subTitle: [],
                description: [],
              }}
              classOverrides={{
                subTitle: 'tablet:pt-[8px]',
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default StaticPageBanner
