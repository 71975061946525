import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { INTENTS } from '@/actions/intents'
import Banner from '@/components/banner'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import Logo from '@/components/logo'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { useModal } from '@/contexts/interface'
import { ActionResponse } from '@/types/actions'
import { IAuthenticationResponse } from '@/types/session'

export const Auth: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const intl = useIntl()
  const { setModal } = useModal()
  const { state } = useNavigation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const workspace = params.get('workspace_id') ?? ''

  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  useEffect(() => {
    // We must clear the banners otherwise they'll persist through
    // to the next screen - this is because the usual 'clear' functionality
    // only works from the root nav components whilst authed.
    BannerManager.hideBanners('all')
  }, [state])

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => {
    BannerManager.hideBanners('all')
    setSubmitting(intent)
  }

  // On form exception
  const onException = (actionRes: ActionResponse<IAuthenticationResponse>) => {
    clearSubmitting()
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: 'Account authentication error',
      description: actionRes.message ?? t('something_went_wrong'),
    })
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<IAuthenticationResponse>) => {
    if (actionRes.data?.two_step_verification && actionRes.data.qr) {
      // User needs to setup the QR for 2 factor authentication
      const _token = actionRes.data.user.token
      setModal({
        id: 'TWO_FACTOR_SETUP',
        qr: actionRes.data.qr,
        onContinue: () => {
          navigate(`${ROUTES.AUTH.TWO_FACTOR}?${params.toString()}`, {
            state: {
              token: _token,
            },
          })
        },
      })
    } else if (actionRes.data?.two_step_verification) {
      // User has 2 factor setup already so we need to move them to
      // the 2 factor auth screen
      navigate(`${ROUTES.AUTH.TWO_FACTOR}?${params.toString()}`, {
        state: {
          token: actionRes.data.user.token,
        },
      })
    } else if (actionRes.data?.user.role === 'super') {
      // User is a super admin so if 2step isn't required then we
      // need to jump past the workspace selection as we don't need
      // that for super users - they be 2 cool for that
      navigate(ROUTES.ADMIN.ORGANIZATION.INDEX)
    } else {
      // Navigate to the workspace selector
      navigate(`${ROUTES.AUTH.WORKSPACE}?${params.toString()}`)
    }
    clearSubmitting()
  }

  const onForgottenPassword = () => {
    setModal({
      id: 'FORGOTTEN_PASSWORD',
    })
  }

  return (
    <>
      <div
        className={twMerge(
          'flex min-w-[100vw] min-h-[100dvh] justify-end bg-green',
          'bg-[url("/auth-image.jpg")] bg-cover bg-left-center'
        )}
      >
        <div
          className={
            'absolute inset-0 h-full w-full bg-gradient-to-r from-[#21242759] to-[#212427FF]'
          }
        ></div>

        <div
          className={twMerge(
            'flex flex-col w-full min-h-[100dvh] z-10',
            'bg-white',
            'desktop:max-w-[37.5rem]'
          )}
        >
          <div
            className={twMerge(
              'flex flex-col p-6 justify-center items-start self-stretch',
              'tablet:p12',
              'desktop:absolute desktop:top-6 desktop:left-6'
            )}
          >
            <div className={'hidden desktop:flex'}>
              <Logo.WordMark style={'COLOR'} color={'WHITE'} heightRem={3.5} />
            </div>
            <div className={'flex desktop:hidden'}>
              <Logo.WordMark style={'COLOR'} color={'BLACK'} heightRem={3.5} />
            </div>
          </div>
          <Banner.Page />
          <div
            className={twMerge(
              'flex flex-1 p-6 pt-0 flex-col items-center',
              'items-center justify-center'
            )}
          >
            <div
              className={twMerge(
                'flex flex-1 flex-col justify-center gap-6 w-full',
                'max-w-[23.4375rem]'
              )}
            >
              <Typography variant="heading-small" className={'self-stretch'}>
                <FormattedMessage
                  defaultMessage="Welcome back"
                  description="Welcome back message on the login form"
                  id="F9UZ29"
                />
              </Typography>

              <Form
                type={'login'}
                buttons={[
                  <Button.Basic
                    key={`form_btn_authenticate`}
                    label={intl.formatMessage({
                      defaultMessage: 'Sign in',
                      id: 'SQJto2',
                    })}
                    testId="login-submit"
                    state={
                      _submitting === INTENTS.AUTHENTICATE
                        ? 'loading'
                        : 'default'
                    }
                    trailingIcon={{
                      name: 'arrow-right',
                    }}
                    width={'flex'}
                    withAttributes={{
                      name: 'intent',
                      type: 'submit',
                      value: INTENTS.AUTHENTICATE,
                    }}
                  />,
                ]}
                className={'p-0 gap-2'}
                hideErrorSummary={true}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                onException={onException}
                onFailed={clearSubmitting}
                onUnknownResponse={clearSubmitting}
              >
                <input type="hidden" name="workspace_id" value={workspace} />

                <div className={'flex flex-col gap-6'}>
                  <Input.Email
                    id="email"
                    name="email"
                    data-testid="login-email"
                    label={t('email_address')}
                    placeholder={t('email_address_placeholder')}
                    autoComplete="email"
                    icon={
                      <Icon
                        name={'envelope'}
                        family={'sharp'}
                        variant={'solid'}
                      />
                    }
                  />

                  <Input.Password
                    id="password"
                    name="password"
                    label={t('password')}
                    data-testid="login-password"
                    autoComplete="current-password"
                    hint={' '} /* spacing */
                    icon={
                      <Icon name={'lock'} family={'sharp'} variant={'solid'} />
                    }
                  />
                </div>
              </Form>
              <Typography variant={'label-small'} className={'text-gray-500'}>
                <a onClick={onForgottenPassword}>
                  {t('forgotten_your_password') + '?'}
                </a>
              </Typography>
            </div>
          </div>
          <footer
            className={twMerge(
              'flex flex-row p-6 content-end items-start gap-[1.5rem] self-stretch',
              'tablet:p-12'
            )}
          >
            {/* text-gray-500 */}
            <Typography variant={'label-small'} className={'text-gray-500'}>
              <a
                href="https://www.assetreality.com/privacy-policy"
                target="blank"
              >
                {t('privacy_policy')}
              </a>
            </Typography>
            <Typography variant={'label-small'} className={'text-gray-500'}>
              <a
                href="https://www.assetreality.com/cookie-policy"
                target="blank"
              >
                {t('cookie_policy')}
              </a>
            </Typography>
          </footer>
        </div>
      </div>
    </>
  )
}
