import { Avatar } from '@/components/avatar'
import { Icon } from '@/components/icon'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon.ts'
import { getAssetTypeDescription } from '@/helpers/assetTypeDescription.ts'
import { AssetCategory, IAsset } from '@/types/asset.ts'

import { Size as AvatarSize } from '../../avatar/variants/single.avatar'
import { Size as IconSize } from '../../icon'

const SIZE_MAP: Record<Size, { avatar: AvatarSize; icon: IconSize }> = {
  small: { avatar: 'xsmall', icon: 'medium' },
  medium: { avatar: 'medium', icon: 'large' },
}

type Size = 'small' | 'medium'
interface Props {
  asset: IAsset
  size?: Size
}

const AssetIcon = ({ asset, size = 'medium' }: Props) => {
  const isTangibleAsset = [
    AssetCategory.REAL_ESTATE,
    AssetCategory.VEHICLE,
    AssetCategory.PERSONAL_PROPERTY,
  ].includes(asset.category)

  return (
    <>
      {asset.category === AssetCategory.DIGITAL && (
        <Avatar
          type={'user'}
          size={SIZE_MAP[size].avatar}
          image={getCryptoImageUrl(asset)}
          fallbackImage={getCryptoImageUrl()}
        />
      )}

      {asset.category === AssetCategory.FIAT_CASH && (
        <Icon
          name={'money-bill'}
          size={SIZE_MAP[size].icon}
          variant="solid"
          className={'min-w-0'}
        />
      )}
      {isTangibleAsset && (
        <Icon
          name={getAssetTypeDescription(asset).icon}
          size={SIZE_MAP[size].icon}
          variant="solid"
          className={'min-w-0'}
        />
      )}
    </>
  )
}

export default AssetIcon
