import { AssetDetail } from '@/components/asset/detail/components/asset-detail.tsx'
import { useAuth } from '@/contexts/auth'
import { IAsset } from '@/types/asset'

interface Props {
  asset: IAsset
  readOnly?: boolean
  sessionToken?: string
}

export const AssetDetails = (props: Props) => {
  const { policies } = useAuth()

  const canEditAsset =
    policies?.includes('CUSTODY.MANAGE_DIGITAL_ASSET') &&
    policies?.includes('CUSTODY.MANAGE_PHYSICAL_ASSET')
  const _readOnly = props.readOnly === true || !canEditAsset

  return (
    <div className={'flex w-full justify-center'}>
      <div
        className={'flex flex-col w-full p-6'}
        data-testid="asset-detail-wrapper"
      >
        <AssetDetail
          asset={props.asset}
          readOnly={_readOnly}
          sessionToken={props.sessionToken}
          showTags={false}
        />
      </div>
    </div>
  )
}
