import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { downloadFile, getFileMetadata } from '@/api/files'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { useDropdown } from '@/contexts/interface'
import { isDefined } from '@/helpers/isDefined.ts'
import { IFile } from '@/types/file'

import FileRow from '../rows/file'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

type SortBy = 'az' | 'latest' | 'size'

export interface Props extends BaseTableProps {
  files: IFile[]
  onAddNew?: () => void
  onDeleteFile?: (file: IFile) => Promise<void>
}

export const FilesTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = 'az'
  const { t } = useTranslation()
  const [_files, setFiles] = useState<IFile[]>(props.files)
  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const [_downloadingFileIds, setDownloadingFileIds] = useState<string[]>([])
  const [_deletingFileIds, setDeletingFileIds] = useState<string[]>([])
  const { setDropdown } = useDropdown()
  const { hasPolicy } = useAuth()
  const showDeleteBtns = isDefined(props.onDeleteFile)

  useEffect(() => {
    setFiles([...sort(props.files)])
  }, [props.files])

  useEffect(() => {
    setFiles([...sort(_files)])
  }, [_sortBy])

  const sort = (files: IFile[]): IFile[] => {
    switch (_sortBy) {
      case 'az':
        return files.sort((a, b) => a.name.localeCompare(b.name))
      case 'latest':
        return files.sort(
          (a, b) =>
            new Date(b.created_at ?? 0).getTime() -
            new Date(a.created_at ?? 0).getTime()
        )
      case 'size':
        return files.sort(
          (a, b) =>
            (a.metadata?.metadata.size ?? 0) - (b.metadata?.metadata.size ?? 0)
        )
      default:
        return files
    }
  }

  const onSortChanged = (id: SortBy) => setSortBy(id)

  const onDownloadFile = async (file: IFile) => {
    if (!_downloadingFileIds.includes(file?.id as string)) {
      try {
        // Set the downloading flag
        setDownloadingFileIds([..._downloadingFileIds, file?.id as string])

        // Fetch the file metadata if not available
        if (!file.metadata || !file.metadata.url) {
          const metadata = await getFileMetadata(file.key)
          file.metadata = metadata[0]
        }

        // Download the file
        setTimeout(async () => {
          await downloadFile(file)
        }, 200)
      } catch (e) {
        console.error(e)
      } finally {
        setDownloadingFileIds(_downloadingFileIds.filter((i) => i !== file.id))
      }
    }
  }

  const showDeletionConfirmation = (file: IFile, targetId: string) => {
    setDropdown({
      target: targetId,
      maxWidthPx: 300,
      controller: (
        <Dropdown.Controllers.BasicList
          closeOnItemClick={true}
          items={[
            <Typography
              variant={'label-medium'}
              className={'text-gray-600 p-4'}
              key={'header_delete_file_confirmation'}
            >
              {t('confirm_file_deletion')}
            </Typography>,
            <ListItem
              key={'btn_add_asset_new'}
              testId={'btn_delete_file_confirmaton'}
              title={file.name}
              leading={
                <Icon name={'trash'} family={'sharp'} variant={'solid'} />
              }
              onClick={() => {
                onDeleteFile(file)
              }}
              className={'pl-2 pr-2'}
            />,
          ]}
        />
      ),
    })
  }

  const onDeleteFile = async (file: IFile) => {
    if (props.onDeleteFile) {
      if (!_deletingFileIds.includes(file?.id as string)) {
        try {
          setDeletingFileIds([..._deletingFileIds, file?.id as string])
          await props.onDeleteFile(file)
          setFiles(_files.filter((f) => f.id !== file.id))
        } catch (error) {
          console.error('Error deleting file:', error)
        } finally {
          setDeletingFileIds(_deletingFileIds.filter((i) => i !== file.id))
        }
      }
    }
  }

  const candDeleteFiles = showDeleteBtns && hasPolicy('CUSTODY.DELETE_DOCUMENT')
  const renderRow = (row: IFile, index: number) => (
    <FileRow
      key={`file_${row.id}`}
      file={row}
      index={index}
      onDownloadFile={onDownloadFile}
      onDeleteFile={candDeleteFiles ? showDeletionConfirmation : undefined}
      isDeletingFile={_deletingFileIds.includes(row?.id as string)}
      isDownloadingFile={_downloadingFileIds.includes(row?.id as string)}
    />
  )

  return (
    <>
      <BaseTable<IFile, SortBy, null, null>
        testId={props.testId}
        showLeftController={props.showLeftController}
        showFilters={props.showFilters}
        showSort={props.showSort}
        leftController={
          props.onAddNew
            ? {
                buttons: [
                  {
                    icon: {
                      name: 'plus',
                    },
                    size: 'small',
                    label: t('upload_documents'),
                    onClick: props.onAddNew,
                  },
                ],
              }
            : undefined
        }
        sort={{
          options: [
            {
              id: 'az',
              value: 'az',
              label: t('a-z'),
              icon: 'user-circle',
            },
            {
              id: 'latest',
              value: 'latest',
              label: t('latest'),
              icon: 'calendar',
              iconVariant: 'regular',
            },
          ],
          onSortByChanged: onSortChanged,
          defaultSelectedId: defaultSortBy,
        }}
        headers={[
          {
            label: t('file_name'),
            colSpan: 2,
          },
          {
            label: t('size'),
            alignment: 'center',
          },
          {
            label: t('date'),
            alignment: 'center',
          },
          {
            label: t('uploaded_by'),
            alignment: 'center',
          },
        ]}
        data={_files}
        onRenderRow={renderRow}
      />
    </>
  )
}
