import { useQuery } from '@tanstack/react-query'

import { getAllOrgs } from '@/api/org/v1/organization'

export const useOrgs = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['orgs'],
    queryFn: () => getAllOrgs(),
  })

  return { orgs: data?.data ?? [], isLoading }
}
