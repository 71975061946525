import './agreement.css'

import { useState } from 'react'

import Button from '@/components/button'
import { Checkbox } from '@/components/checkbox'
import Logo from '@/components/logo'
import { AuthManager } from '@/contexts/auth'

import { useUpdateAgreed } from './use-agreed'

export const VERSION = 1

export const Agreement: React.FC = () => {
  const [agree, setAgree] = useState(false)
  const updateAgreed = useUpdateAgreed()

  return (
    <div className="h-dvh flex">
      <div className='bg-[url("/auth-image.jpg")] flex-grow bg-cover p-12 hidden md:block'>
        <Logo.WordMark
          style="COLOR"
          color="WHITE"
          heightRem={3.5}
          className="justify-start"
        />
      </div>
      <div className="md:w-[600px] flex flex-col min-w-0">
        <div className="overflow-auto p-6 md:p-8">
          <Button.Back className="mb-6" onClick={AuthManager.logout} />
          <Logo.WordMark
            style="COLOR"
            color="BLACK"
            heightRem={3.5}
            className="justify-start mb-6 md:hidden"
          />
          <div className="agreement">
            <h1>User License Agreement (ULA)</h1>
            <p>
              Please read this User License Agreement (&quot;Agreement&quot;)
              carefully before accessing or using Asset Reality’s product,
              including its platform, mobile application, and related services
              (&quot;Product&quot;). By clicking &quot;I Agree&quot; or using
              the Product, you acknowledge that you have read, understood, and
              agree to be bound by the terms of this Agreement.
            </p>
            <p>
              If you do not agree to the terms of this Agreement, you must not
              access or use the Product.
            </p>
            <h2>License Grant</h2>
            <p>
              Asset Reality grants you a limited, nonexclusive, nontransferable,
              and revocable license to access and use the Product for your
              personal or business use, subject to the terms of this Agreement.
            </p>
            <h2>User Obligations</h2>
            <p>You agree to:</p>
            <ul>
              <li>
                Use the Product only for lawful purposes and in accordance with
                the Agreement.
              </li>
              <li>
                Not use the Product in any manner that could disable,
                overburden, or impair the Product or interfere with any other
                party&apos;s use of the Product.
              </li>
            </ul>
            <h2>Prohibited Uses</h2>
            <p>You agree not to:</p>
            <ul>
              <li>
                Modify, distribute, or reproduce the Product without explicit
                written permission from Asset Reality.
              </li>
              <li>
                Decompile, reverse engineer, or attempt to derive the source
                code of the Product.
              </li>
              <li>
                access all or any part of the Product to build a product or
                service which competes with the Product.
              </li>
              <li>
                Use the Product for any illegal, fraudulent, or harmful
                activity.
              </li>
            </ul>
            <h2>Privacy</h2>
            <p>
              By using the Product, you acknowledge and agree to Asset
              Reality&apos;s Privacy Policy, which describes how your personal
              data is collected, used, and shared.
            </p>
            <a
              href="https://www.assetreality.com/policies/privacy-policy"
              target="blank"
            >
              [Link to Privacy Policy]
            </a>
            <h2>Intellectual Property</h2>
            <ul>
              <li>
                You acknowledge that all intellectual property rights in the
                Product anywhere in the world belong to us or our licensors,
                that rights in the Product are licensed (not sold) to you, and
                that you have no rights in, or to, the Product other than the
                right to use them in accordance with the terms of this Licence.
              </li>
              <li>
                You acknowledge that you have no right to have access to any
                Product in source code form.
              </li>
              <li>
                You agree not to use any intellectual property without Asset
                Reality&apos;s prior written consent.
              </li>
            </ul>
            <h2>Termination</h2>
            <p>
              Asset Reality reserves the right to terminate or suspend your
              access to the Product at any time for any reason, including a
              violation of this Agreement.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
              To the fullest extent permitted by law, Asset Reality shall not be
              liable for any direct, indirect, incidental, or consequential
              damages arising from your use or inability to use the Product.
            </p>
            <h2>Governing Law</h2>
            <p>
              This Agreement shall be governed by and construed in accordance
              with English law. You agree that the courts of England and Wales
              will have exclusive jurisdiction, except that if you are a
              resident of Northern Ireland, you may also bring proceedings in
              Northern Ireland, and if you are a resident of Scotland, you may
              also bring proceedings in Scotland. If you are a business, you
              agree to be governed by English law and to the exclusive
              jurisdiction of the courts of England and Wales.
            </p>
            <h2>Changes to the Agreement</h2>
            <p>
              Asset Reality may modify this Agreement from time to time. Any
              changes will be effective when posted, and continued use of the
              Product constitutes your acceptance of those changes.
            </p>
            <h2>Acknowledgement and Acceptance</h2>
            <p>
              By clicking &quot;I Agree,&quot; you acknowledge that you have
              read, understood, and agree to the terms of this Agreement.
            </p>
          </div>
        </div>
        <div className="border-t border-[#2124271A] p-6 md:p-8 flex justify-center">
          <div className="w-[375px] flex flex-col">
            <div className="bg-gray-100 rounded-md px-4 py-2 mb-6 flex items-center justify-between">
              I Agree
              <Checkbox
                id="agree"
                testId="agree"
                onCheckChanged={async (_, state) => {
                  setAgree(state === 'checked' ? true : false)
                  return state
                }}
              />
            </div>
            <Button.Basic
              label="Continue"
              className="self-stretch"
              state={agree ? 'default' : 'disabled'}
              trailingIcon={{
                name: 'arrow-right',
              }}
              testId="continue"
              onClick={() => updateAgreed(VERSION)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
