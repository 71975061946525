import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '../button'
import { Size as TabSize, Type as TabType } from '../button/variants/tab.button'
import { IconProps as IconCompProps } from '../icon'

interface BaseTabProps<IdType> {
  id: IdType
  testId?: string
}
interface BaseProps<IdType> {
  testId?: string
  type: TabType
  size: TabSize
  selectedId?: IdType
  fill?: boolean
  responsive?: boolean
  className?: string
  onSelectionChanged: (id: IdType) => void
}
interface ButtonProps<IdType> extends BaseProps<IdType> {
  type: 'button'
  values: ({
    text: string
    icon?: IconCompProps
  } & BaseTabProps<IdType>)[]
}
interface TextProps<IdType> extends BaseProps<IdType> {
  type: 'text'
  values: ({
    text: string
    icon?: IconCompProps
  } & BaseTabProps<IdType>)[]
}
interface IconProps<IdType> extends BaseProps<IdType> {
  type: 'icon'
  values: ({
    icon: IconCompProps
  } & BaseTabProps<IdType>)[]
}
export type Props<IdType> =
  | IconProps<IdType>
  | ButtonProps<IdType>
  | TextProps<IdType>
export const Tabs = <IdType = string,>(props: Props<IdType>) => {
  const [_selectedId, setSelectedId] = useState<IdType | null>(
    props.selectedId !== undefined
      ? props.selectedId
      : props.values.length > 0
        ? props.values[0].id
        : null
  )

  const onSelectionChanged = (id: IdType) => {
    setSelectedId(id)
    props.onSelectionChanged(id)
  }

  useEffect(() => {
    if (props.selectedId) {
      setSelectedId(props.selectedId)
    }
  }, [props.selectedId])

  return (
    <div
      className={twMerge(
        'flex flex-row relative items-start',
        props.type !== 'text' && 'gap-[0.125rem]',
        props.fill === true && 'w-full',
        props.responsive !== false && 'w-full tablet:w-auto',
        props.className
      )}
      data-testid={props.testId ?? 'tab-component'}
    >
      {props.type === 'icon'
        ? props.values.map((v, i) => (
            <Button.Tab
              key={`tab_${i}`}
              testId={v.testId}
              type={'icon'}
              icon={v.icon}
              onClick={() => {
                onSelectionChanged(v.id)
              }}
              active={_selectedId === v.id}
              className={twMerge(
                'z-[1]',
                (props.responsive !== false || props.fill === true) && 'flex-1'
              )}
            />
          ))
        : props.values.map((v, i) => (
            <Button.Tab
              key={`tab_${i}`}
              testId={v.testId}
              type={props.type}
              icon={v.icon}
              text={v.text}
              onClick={() => {
                onSelectionChanged(v.id)
              }}
              active={_selectedId === v.id}
              className={twMerge(
                'z-[1]',
                (props.responsive !== false || props.fill === true) && 'flex-1'
              )}
            />
          ))}
      {props.type !== 'text' && (
        <div
          className={
            'absolute w-full h-full rounded-md border-solid border-[1px] border-[#E1E1E2]'
          }
        />
      )}
    </div>
  )
}
