import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IAsset } from '@/types/asset'

import AssetActionsView from './views/asset-actions.view'

interface Props extends ControllerProps<unknown> {
  asset: IAsset
  onRemoveFromCase?: (a: IAsset) => void
}

const AssetActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'ASSET_ACTIONS'}
      closeOnItemClick={true}
    >
      <AssetActionsView
        asset={props.asset}
        onRemoveFromCase={props.onRemoveFromCase}
      />
    </DropdownController>
  )
}
export default AssetActionsController
