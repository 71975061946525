import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const AssetsSkeleton: React.FC = (): JSX.Element => (
  <>
    <Skeleton className="h-10 mb-4" width="100px" />
    <Skeleton className="h-4" width="74%" />
    <Skeleton className="h-4 mb-[60px]" width="60%" />

    <div className="flex flex-row items-center gap-5 mb-[60px]">
      <Skeleton className="h-12" width="170px" />
      <Skeleton className="h-12" width="170px" />
    </div>

    <div className="flex flex-row items-center justify-between mb-[40px]">
      <div className="flex flex-row items-center">
        <Skeleton className="h-9" width="85px" />
        <Skeleton className="h-9" width="85px" />
      </div>

      <div className="flex flex-row items-center gap-2">
        <Skeleton className="h-9" width="130px" />
        <Skeleton className="h-9" width="130px" />
      </div>
    </div>

    <div className="flex flex-row items-center justify-between p-4">
      <Skeleton className="h-4" width="85px" />
      <Skeleton className="h-4" width="45px" />
      <Skeleton className="h-4" width="65px" />
      <Skeleton className="h-4" width="55px" />
      <Skeleton className="h-4" width="55px" />
    </div>

    <Skeleton className="h-10" count={5} />
  </>
)
export default AssetsSkeleton
