import { t } from 'i18next'

import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { IAssetStatusName } from '@/types/asset'

interface Props {
  status?: IAssetStatusName
  onChangeStatus?: (status: string | null) => void
}
export const AssetStatusSelector = (props: Props) => {
  return (
    <Input.Dropdown<IAssetStatusName>
      name={'asset_status'}
      onItemSelectionChange={props.onChangeStatus}
      testId={'asset_status'}
      listGap={'none'}
      defaultValue={props.status ?? 'PENDING'}
      items={(
        [
          'PENDING',
          'READY',
          'SEIZED',
          'ADOPTED',
          'AWARDED',
          'FORFEITED',
          'TRANSFERRED',
          'RETURNED',
          'LIQUIDATED',
          'DELETED',
          'CLOSED',
        ] satisfies IAssetStatusName[]
      ).map((status, i) => (
        <ListItem
          key={`item_${i}`}
          title={capitalizeFirstLetter(t(status.toLowerCase()))}
          value={status}
          className={'pl-2 pr-2'}
        />
      ))}
      className={'max-w-[10rem]'}
    />
  )
}
