import { t } from 'i18next'

import { progressEscrowFee } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryEscrowKey } from '@/hooks/queries/useEscrow'
import { ActionResponse } from '@/types/actions'
import { IEscrow } from '@/types/escrows'

export const ProgressEscrowFee = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const escrow = formData.get('escrow')?.toString()
  const fee_id = formData.get('fee_id')?.toString()

  console.log(escrow, fee_id)
  // Validate the fields
  if (IsNot(escrow, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(fee_id, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'fee_id',
        error: t('try_again_later'),
      },
    ])
  }

  // Attempt to parse the original escrow object
  const parsedEscrow = JSON.parse(escrow) as IEscrow

  // Update the escrow object
  const res = await progressEscrowFee(parsedEscrow.id, fee_id)

  // Check it was created ok
  if (res.error || !res.data) {
    throw new Error(res.message)
  }

  // Invalidate the escrow cache
  await queryClient.invalidateQueries({
    queryKey: [QueryEscrowKey, parsedEscrow.id],
  })

  return {
    error: false,
    message: t('escrow_updated_successfully'),
    completion: 'COMPLETE',
    data: parsedEscrow.id,
  }
}
