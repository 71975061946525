const EIGHT_HOURS = 8 * 60 * 60 * 1000
export const TOKEN_EXPIRATION_TIME = new Date(
  new Date().getTime() + EIGHT_HOURS
)

export const MAX_ALLOWED_OWNERS = 4

export enum USER_ROLES {
  SUPER = 'super',
  ADMIN = 'admin',
  VIEWER = 'viewer',
  APPROVER = 'approver',
  INITIATOR = 'initiator',
  SIGNER = 'signer',
  EDITOR = 'editor',
  GUEST = 'guest',
}

export const DEFAULT_USER_ROLE = USER_ROLES.VIEWER
