import { HubSpotFlow } from '@/types/hubspot'

export default function useHubspot() {
  // This will auto display a particular conversation flow
  const openConversation = (flow: HubSpotFlow) => {
    try {
      // Listen for certain events to remove/close the widget and remove
      // any associated params
      window.HubSpotConversations.on('widgetClosed', () => {
        closeConversation()
      })
      window.HubSpotConversations.on('conversationClosed', () => {
        closeConversation()
      })

      // Add in the query param so hubsput knows which conversation
      // to open up
      window.history.pushState({}, flow, `?chat=${flow}`)

      // Trigger the open and make sure we run the refresh so it checks
      // we've got the correct conversation.
      window.HubSpotConversations.widget.open()
      window.HubSpotConversations.widget.refresh({
        openToNewThread: true,
      })
    } catch (e) {
      console.error('Failed to start Hubspot conversation')
    }
  }

  const closeConversation = () => {
    try {
      window.HubSpotConversations.widget.remove()
      window.history.pushState({}, '', `?`)
    } catch (e) {
      // Do nothing
    }
  }

  const clear = () => {
    try {
      closeConversation()
      window.HubSpotConversations.clear()
    } catch (e) {
      // Do nothing
    }
  }

  return { openConversation, closeConversation, clear }
}
