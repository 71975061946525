import { ColDef } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { downloadFile, getFileMetadata } from '@/api/files'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import { Typography } from '@/components/typography'
import { formatBytes } from '@/helpers/formatValue'
import { getFileIcon } from '@/helpers/getFileIcon'
import { useOrgUsers } from '@/hooks/queries/useOrgUsers'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { IEscrowFile } from '@/types/escrows'
import { IFile } from '@/types/file'

import Table from '..'
import styles from '../aggrid.module.css'

export interface Props {
  data: (IFile | IEscrowFile)[]
  organizations?: { id: string; name: string }[]
  testId?: string
}

export const FilesTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { members } = useWorkspaceMembers()
  const { users: orgUsers } = useOrgUsers()
  const [_downloadingFileIds, setDownloadingFileIds] = useState<string[]>([])
  const [_data, setData] = useState<(IFile | IEscrowFile)[]>(props.data)

  useEffect(() => {
    const func = async () => {
      if (props.data.length > 0) {
        const _newData = [...props.data]

        // Find all files that we need to get metadata for - we'll exclude any
        // files that already has a metadata object
        const fileKeys = props.data.filter((f) => !f.metadata).map((f) => f.key)

        // Check we have any files to get metadata for
        if (fileKeys.length > 0) {
          // Get the metadata for those keys
          const metadataRes = await getFileMetadata(fileKeys)

          // Create a lookup
          const metadataLookup = Object.fromEntries(
            metadataRes.map((mdata) => [mdata.metadata.key, mdata])
          )

          // Run through the files and assign the metadata where we have it
          for (const file of _newData) {
            if (metadataLookup[file.key]) {
              file.metadata = metadataLookup[file.key]
            }
          }
        }

        // Set the new data
        setData(_newData)
      } else {
        setData([])
      }
    }
    func()
  }, [props.data])

  const onDownloadFile = async (file?: IFile | IEscrowFile) => {
    if (file && !_downloadingFileIds.includes(file.key)) {
      try {
        // Set the downloading flag
        setDownloadingFileIds([..._downloadingFileIds, file.key])

        // Fetch the file metadata if not available
        if (!file.metadata || !file.metadata.url) {
          const metadata = await getFileMetadata(file.key)
          file.metadata = metadata[0]
        }

        // Download the file
        setTimeout(async () => {
          await downloadFile(file)
        }, 200)
      } catch (e) {
        console.error(e)
      } finally {
        setDownloadingFileIds(_downloadingFileIds.filter((i) => i !== file.key))
      }
    }
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IFile | IEscrowFile>[]>(
    () => [
      {
        field: 'name',
        headerName: t('filename'),
        cellRenderer: (props: CustomCellRendererProps<IFile | IEscrowFile>) => {
          return (
            <div className={'flex flex-row gap-[16px]'}>
              <Avatar
                type={'icon'}
                shape={'square'}
                size={'medium'}
                name={props.data ? getFileIcon(props.data) : ''}
                family={'sharp'}
                iconVariant={'solid'}
                iconClassName="text-black"
              />
              <div className={'flex flex-col gap-[4px] justify-center'}>
                <Typography variant={'label-small'}>{props.value}</Typography>
              </div>
            </div>
          )
        },
        cellRendererParams: {
          type: 'header',
        },
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'created_at',
        headerName: t('created'),
        cellRenderer: Table.AgGrid.Cell,
        cellRendererParams: {
          type: 'date-short',
        },
        width: 180,
        minWidth: 180,
      },
      {
        field: 'metadata.metadata.size',
        headerName: t('size'),
        cellRenderer: (
          cellProps: CustomCellRendererProps<IFile | IEscrowFile>
        ) => {
          return Table.AgGrid.Cell({
            ...cellProps,
            value: formatBytes(cellProps.data?.metadata?.metadata.size),
            type: 'text',
          })
        },
        width: 150,
        minWidth: 150,
      },
      {
        field: 'created_by',
        headerName: t('uploaded_by'),
        cellRenderer: (
          cellProps: CustomCellRendererProps<IFile | IEscrowFile>
        ) => {
          let isOrg: boolean = false

          // First attempt to find the created by user id in the workspace
          // members...
          let createdBy = members?.find(
            (u) =>
              u.user_id ===
              (typeof cellProps.data?.created_by === 'string'
                ? cellProps.data?.created_by
                : cellProps.data?.created_by?.user_id)
          )?.user?.name

          // Secondly lets take a gander at the org users...
          if (!createdBy && orgUsers) {
            createdBy = orgUsers.find(
              (u) =>
                u.user_id ===
                (typeof cellProps.data?.created_by === 'string'
                  ? cellProps.data?.created_by
                  : cellProps.data?.created_by?.user_id)
            )?.name
          }

          // If we cant find the user in the current workspace or current org
          // its possible they belong to a different organisation - so lets
          // check...
          // (Note that we need a collection or orgs to be provided)
          if (!createdBy && props.organizations) {
            // Attempt to find the org based on the id
            const org = props.organizations.find(
              (o) =>
                o.id ===
                (typeof cellProps.data?.created_by === 'string'
                  ? cellProps.data?.created_by
                  : cellProps.data?.created_by?.organization_id)
            )

            // If we've found an org lets grab the name
            if (org) {
              createdBy = org.name
              isOrg = true
            }
          }

          // Display the createdBy name if we have it - otherwise we'll display a "-"
          if (createdBy) {
            return (
              <div className={'flex flex-row gap-[16px]'}>
                {isOrg ? (
                  <Avatar
                    type={'icon'}
                    shape={'square'}
                    size={'small'}
                    name={'buildings'}
                    iconVariant={'solid'}
                    family={'sharp'}
                    iconClassName={'text-black'}
                  />
                ) : (
                  <Avatar
                    type={'monogram'}
                    shape={'round'}
                    size={'small'}
                    value={createdBy}
                  />
                )}
                <div className={'flex flex-col gap-[4px] justify-center'}>
                  <Typography variant={'paragraph-small'}>
                    {createdBy}
                  </Typography>
                </div>
              </div>
            )
          } else {
            return Table.AgGrid.Cell({
              ...cellProps,
              value: '-',
              type: 'text',
            })
          }
        },
        flex: 1,
        width: 180,
        minWidth: 180,
      },
      {
        headerName: t('file'),
        cellRenderer: (
          cellProps: CustomCellRendererProps<IFile | IEscrowFile>
        ) => {
          if (cellProps.data === undefined) {
            return Table.AgGrid.Cell({ ...cellProps, value: '-', type: 'text' })
          } else {
            return (
              <Button.Shape
                layout={'icon'}
                hierarchy={'tertiary'}
                shape={'square'}
                size={'small'}
                icon={{ name: 'arrow-down-to-bracket' }}
                onClick={(e) => {
                  e.preventDefault()
                  onDownloadFile(cellProps.data)
                }}
              />
            )
          }
        },
        width: 100,
        minWidth: 100,
      },
    ],
    [t]
  )

  return (
    <div
      className={`ag-theme-quartz ${styles.grid} ${styles.no_cell_selection} ${styles.no_row_selection}`}
      style={{ position: 'relative' }}
      data-testid={props.testId}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          domLayout: 'autoHeight',
          rowHeight: 72,
        }}
        rowData={_data}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
