import { useQuery } from '@tanstack/react-query'

import { getDigitalAssets, IAssetOperationState } from '@/api'

export const key = 'assets-digital'
export const useDigitalAssets = (withOperationState?: IAssetOperationState) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key, withOperationState],
    queryFn: () => getDigitalAssets(withOperationState),
  })

  return { assets: data?.data, isLoading, refetchDigitalAssets: refetch }
}
