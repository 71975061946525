import { t } from 'i18next'

import {
  composeAssetTypeDescription,
  getTangibleSubcategoryTitle,
  getTangibleTypeConfig,
  getTangibleTypeEnum,
  getWalletSourceConfig,
} from '@/helpers/assetTitleAndIcon.ts'
import { isDefined } from '@/helpers/isDefined.ts'
import {
  AssetCategory,
  AssetTangibleType,
  DigitalAssetType,
  DigitalWalletSource,
  IAsset,
  IDigitalAsset,
  IFiatCashAsset,
  IHighValuePersonalPropertyAsset,
  IRealEstateAsset,
  IVehicleAsset,
  TangibleTypeCash,
} from '@/types/asset.ts'

interface AssetTypeInfo {
  icon: string
  title: string
}

export const getAssetTypeDescription = (asset: IAsset): AssetTypeInfo => {
  switch (asset.category) {
    case AssetCategory.DIGITAL:
      return getDigitalTypeDescription(asset)
    case AssetCategory.FIAT_CASH:
    case AssetCategory.PERSONAL_PROPERTY:
    case AssetCategory.REAL_ESTATE:
    case AssetCategory.VEHICLE:
      return getTangibleTypeDescription(asset)
  }
}

const getDigitalTypeDescription = (_asset: IDigitalAsset) => {
  let walletSource: DigitalWalletSource | undefined = _asset.wallet?.source
  if (!isDefined(walletSource) && _asset.is_self_managed) {
    walletSource = DigitalWalletSource.CUSTOM_TRACKER
  }
  const walletSourceConfig = getWalletSourceConfig(walletSource)
  const title = composeAssetTypeDescription([
    t('digital'),
    walletSourceConfig?.title,
    _asset.type === DigitalAssetType.NATIVE
      ? t('asset_type_native')
      : _asset?.type,
  ])
  return {
    icon: walletSourceConfig?.icon ?? 'coin',
    title,
  }
}

export const getTangibleTypeDescription = (
  _asset:
    | IHighValuePersonalPropertyAsset
    | IRealEstateAsset
    | IVehicleAsset
    | IFiatCashAsset
) => {
  const tangibleTypeEnum = getTangibleTypeEnum(_asset)
  const subcategoryTitle = getTangibleSubcategoryTitle([
    _asset.category,
    tangibleTypeEnum,
  ])
  const isCash = _asset.category === AssetCategory.FIAT_CASH
  const typeConfig = getTangibleTypeConfig(
    _asset.category,
    isCash ? TangibleTypeCash.CASH : (_asset.type as AssetTangibleType)
  )

  const cashCurrency = isCash ? _asset.currency?.toUpperCase() : ''
  return {
    icon: typeConfig?.icon ?? '',
    title: composeAssetTypeDescription([
      subcategoryTitle,
      typeConfig?.title,
      cashCurrency,
    ]),
  }
}

export const getAssetTypeDescriptionForColumn = (asset: IAsset): string => {
  switch (asset.category) {
    case AssetCategory.FIAT_CASH:
      return composeAssetTypeDescription([
        t('cash'),
        asset.currency?.toUpperCase(),
      ])
    case AssetCategory.DIGITAL: {
      return composeAssetTypeDescription([
        t('digital'),
        asset.ticker.toUpperCase().replace('$', ''),
      ])
    }
    case AssetCategory.PERSONAL_PROPERTY:
    case AssetCategory.REAL_ESTATE:
    case AssetCategory.VEHICLE: {
      const typeConfig = getTangibleTypeConfig(
        asset.category,
        asset.type as AssetTangibleType
      )
      const result = typeConfig?.selectedTitle ?? typeConfig?.title
      if (isDefined(result)) {
        return result
      }
      const tangibleTypeEnum = getTangibleTypeEnum(asset)
      const subcategoryTitle = getTangibleSubcategoryTitle([
        asset.category,
        tangibleTypeEnum,
      ])
      return subcategoryTitle ?? t('tangible')
    }
  }
}
