import { t } from 'i18next'
import Cookies from 'js-cookie'
import { useEffect, useRef, useState } from 'react'
import { matchPath, Outlet, useLocation, useNavigation } from 'react-router-dom'

import { refreshSession } from '@/api'
import Card from '@/components/card'
import { CardBadge } from '@/components/card/components/badge.card'
import { ROUTES } from '@/constants/routes'
import ContextsProviders, { queryClient } from '@/contexts'
import { AuthManager } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { FormErrorsManager } from '@/contexts/formErrors'
import { WorkspaceManager } from '@/contexts/workspace'
import { STORAGE_KEYS } from '@/helpers/localStorage.ts'
import useHubspot from '@/hooks/useHubspot'
import { VERSION } from '@/screens/agreement/agreement'
import { useAgreed } from '@/screens/agreement/use-agreed'
import { IPolicyKey } from '@/types/policies'
import { IUser } from '@/types/user'
import { IWorkspace } from '@/types/workspace'

import { HasAccess } from './access'
import GuardContent from './guardContent'

// import { useTheme } from '@/contexts/theme'
// import { ThemeManager } from '@/contexts/theme/manager'
interface Props {
  children: JSX.Element
  access: 'AUTHENTICATED_ONLY' | 'UNAUTHENTICATED_ONLY' | 'BOTH'
  showNavigation?: boolean
}

type AccessState = 'LOADING' | 'HAS_ACCESS' | 'NO_ACCESS'
type AuthenticationState = 'AUTHENTICATED' | 'NOT_AUTHENTICATED'
const Guard = (props: Props) => {
  const { pathname: rrdPathname } = useLocation()
  const navigation = useNavigation()
  const { clear: clearHubspot } = useHubspot()
  const _lastRefreshedTimestamp = useRef<number>(0)
  const _lastAuthCheck = useRef<number>(0)
  const [_authenticationState, setAuthenticationState] =
    useState<AuthenticationState>('NOT_AUTHENTICATED')
  const [_accessState, setAccessState] = useState<AccessState>('LOADING')
  const [tokenExpiration, setTokenExpiration] = useState<number | null>(null)
  const tokenTimeout = useRef<NodeJS.Timeout | null>(null)
  const agreed = useAgreed()

  // Some time consts
  const ONE_SECOND = 1000
  const ONE_MINUTE = ONE_SECOND * 60

  useEffect(() => {
    // Visibility change handler function
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkSessionExpiration()
      }
    }

    // Set a listener for when the browsers visibility changes - we need to do this
    // so we can handle when the browser returns after the computer has been asleep
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      // Clean up the visibility change listner
      document.removeEventListener('visibilitychange', handleVisibilityChange)

      // Clear up any existing timeouts
      if (tokenTimeout.current) {
        clearTimeout(tokenTimeout.current)
      }
    }
  }, [])

  // Set up the timeout for token expiration - this will handle auto signing the user
  // out when the token has expired
  useEffect(() => {
    if (tokenExpiration) {
      checkSessionExpiration()
    }
  }, [tokenExpiration])

  // Listening to the navigation event gives us actions faster however
  // they don't throw when the user actions a browser refresh/navigation
  // so we can only use this event for when the user is running react
  // internal navigations.
  useEffect(() => {
    if (navigation.state === 'loading') {
      runActions(navigation.location.pathname)
    }
  }, [navigation])

  // Because of the above issue; we also need to listen to the pathname
  // changing so we can catch the moments when the user refreshes etc.
  useEffect(() => {
    if (
      new Date().getTime() - new Date(_lastAuthCheck.current).getTime() >
      ONE_SECOND
    ) {
      runActions(rrdPathname)
    }
  }, [rrdPathname])

  useEffect(() => {
    if (agreed === null || (typeof agreed === 'number' && agreed !== VERSION)) {
      window.location.replace(ROUTES.AGREEMENT)
    }
  }, [agreed])

  const checkSessionExpiration = () => {
    const sessionExpiration = localStorage.getItem(
      STORAGE_KEYS.SESSION_EXPIRATION_KEY
    )
    if (sessionExpiration) {
      const currentTime = new Date().getTime()
      const expiryTime = parseInt(sessionExpiration, 10)
      if (currentTime >= expiryTime) {
        signOut(navigation.location?.pathname || window.location.pathname)
      } else {
        // If no timer is running but there is time remaining on our
        // session expiration; set up the timeout
        setTokenExpiration(expiryTime)
        if (tokenTimeout.current) {
          clearTimeout(tokenTimeout.current)
        }
        tokenTimeout.current = setTimeout(() => {
          signOut(navigation.location?.pathname || window.location.pathname)
        }, expiryTime - currentTime)
      }
    }
  }

  // Run the following actions when navigation is changed
  const runActions = async (pathName: string) => {
    // Set the last auth check timestamp
    _lastAuthCheck.current = new Date().getTime()

    // Make sure the hubspot widget is loaded
    try {
      if (!window.HubSpotConversations.widget.status().loaded) {
        window.HubSpotConversations.widget.load({
          widgetOpen: false,
        })
      }
    } catch (e) {
      // Sometimes .widget can be undefined - this avoids a crash
      console.error('failed to launch hubspot.', e)
    }

    // Clear down any banners + UI elements
    clearBannersAndUI()

    // Run the auth check to ensure we are allowed here
    await authCheck(pathName)
  }

  const clearBannersAndUI = () => {
    try {
      // Hide any banners
      BannerManager.hideBanners('welcome')
      BannerManager.hideBanners('page')

      // Clear any form errors
      FormErrorsManager.clearErrors()

      // Make sure any hubspot chats are closed
      window.HubSpotConversations.widget.remove()
    } catch (e) {
      /* empty */
    }
  }

  const authCheck = async (pathname: string) => {
    const cookies = Cookies.get()
    const token = cookies['token']
    let user: IUser | null = null
    let policies: IPolicyKey[] = []
    let workspace: IWorkspace | null = null
    let isEscrowOnlyUser: boolean = false

    // Set that we're loading - depending on how fast the
    // function is this state will most likely get overwritten before
    // the render meaning you'll never really see the loader
    setAccessState('LOADING')

    // Search for the session token - this will determine if the user
    // is authenticated or not
    if (token) {
      // Update the state that we are authenticated
      setAuthenticationState('AUTHENTICATED')

      // Landing here means a session token has been found.
      // The token stored in the cookie should expire when the session expires as we
      // set this against the cookie when we create the session. Based on this we can
      // assume the session is still active so we can simply run a refresh to keep
      // the session alive.
      //
      // So that we're not constantly refreshing the session if the user quickly
      // navigates around the site we can implement some logic where we only refresh
      // every X seconds/minutes
      if (
        new Date().getTime() -
          new Date(_lastRefreshedTimestamp.current).getTime() >
        30 * ONE_MINUTE
      ) {
        try {
          // First update our last refreshed timestamp
          _lastRefreshedTimestamp.current = new Date().getTime()

          // Refresh our session - sign out if any issues are found
          const res = await refreshSession()

          // Check we have some OK response back
          if (!res.data) throw new Error('no data')

          // Replace the current session token
          Cookies.remove('token')
          Cookies.set('token', res.data.token, {
            expires: new Date(res.data.expiry),
          })

          // Set the token expiration time
          const sessionExpiration = new Date(res.data.expiry).getTime()
          localStorage.setItem(
            STORAGE_KEYS.SESSION_EXPIRATION_KEY,
            sessionExpiration.toString()
          )
          setTokenExpiration(sessionExpiration)

          // Update our session policies
          if (res.data.policies) {
            AuthManager.setPolicies(res.data.policies)
          }
        } catch (error) {
          // If we land in here then something went wrong with the session
          // refresh - either a BE issue or something incorrect with their
          // session. In either case we'll redirect them back to the auth
          // screen and clear their current session.
          return signOut(pathname)
        }
      }

      // Attempt to grab some detail that could be delayed due to race conditions
      const res = await Promise.all([getUser(), getPolicies()])

      // Extract the values out to make life easier
      user = res[0]
      policies = res[1] ?? []

      // If we can't find the user object here then we should sign the user out
      // as we have a token so somethings gone wrong.
      if (!user) {
        console.error('unable to find user object - signing out')
        return signOut(pathname)
      }

      // Work out if this user is an escrow only user - this is a user who has been assigned
      // to an escrow but doesn't belong to any org or workspace
      isEscrowOnlyUser =
        !user?.organization_id && (user?.escrow_ids ?? []).length > 0

      // If the user is not a super user and doesn't currently have a workspace
      // selected then we need to get them to select a workspace now
      if (user.role !== 'super' && !isEscrowOnlyUser) {
        // Attempt to find the selected workspace
        workspace = await getWorkspace()

        // Check if we found a selected workspace
        if (!workspace) {
          // We don't have a workspace chosen and we must have one selected so
          // lets redirect the user to the workspace selection screen
          // now
          if (!matchPath(ROUTES.AUTH.WORKSPACE, pathname)) {
            return window.location.replace(ROUTES.AUTH.WORKSPACE)
          } else {
            return setAccessState('HAS_ACCESS')
          }
        }
      }
    } else {
      // Update the state to reflect that we're not authenticated
      setAuthenticationState('NOT_AUTHENTICATED')

      // If the current page only allows authenticated access then we'll
      // need to sign them out and redirect to login screen
      if (props.access === 'AUTHENTICATED_ONLY') {
        return signOut(pathname)
      }
    }

    // If we land down here we can either be authenticated or not authenticated
    // but we need to check if the user is allowed to be here
    const hasAccess = HasAccess(pathname, policies, user)

    // Check if our access check has resulted in the user not being allowed
    // here - if thats the case we'll need to redirect them unless they are
    // already on the screen we'd be redirecting them too.
    // There are also a few other cases we need to re-route the user for for
    // example; being on the workspace selector with a workspace already selected
    if (
      hasAccess === 'NO_ACCESS' ||
      (workspace && matchPath(ROUTES.AUTH.WORKSPACE, pathname)) ||
      (user && matchPath(ROUTES.AUTH.INDEX, pathname)) ||
      (user && matchPath(ROUTES.AUTH.TWO_FACTOR, pathname)) ||
      (user && matchPath(ROUTES.AUTH.RESET_PASSWORD, pathname))
    ) {
      console.log('no access', hasAccess, pathname, policies, user, workspace)
      let goTo = ''

      // User is not allowed access to the current screen so we need to
      // figure out what type of user they are and then redirect appropriately
      if (!token || !user) {
        // User isn't logged in
        return signOut(pathname)
      } else if (user?.role === 'super') {
        // Super user - redirect to the admin org dashboard
        goTo = ROUTES.ADMIN.ORGANIZATION.INDEX
      } else if (isEscrowOnlyUser) {
        // Escrow only user - redirect to the escrow screen
        goTo = ROUTES.ESCROW.INDEX
      } else {
        // Normal user - redirect to the asset screen
        goTo = ROUTES.ASSETS.INDEX
      }

      // First lets check if we're already on the page that we don't
      // have access to - if we are then sign them out
      if (matchPath(goTo, pathname)) {
        // This is a special case and one that most people should never land in - if they do
        // land here then the user must be missing some basic policies in order to allow them
        // access to the page
        return setAccessState('NO_ACCESS')
      } else {
        // Otherwise redirect to a screen they should be on.
        return window.location.replace(goTo)
      }
    }

    // If we land here then we've either landed on a page that the guard doesnt
    // know about so its likely a page that doesn't exist in which case we can allow
    // access and the react router dom should show a 404 - OR its a page that the user
    // can access so we'll allow access anyway
    return setAccessState('HAS_ACCESS')
  }

  // Sometimes we can get ourselves into a race condition where we're
  // attempting to set a user and then instantly read it - because we use
  // state to hold alot of these values we wouldn't get the value until the
  // next render - so we can assist with this here where we'll recurse until
  // we know we havent got.
  const getUser = async (attempts?: number): Promise<IUser | null> => {
    // Check if we've got the user
    if (AuthManager.user !== null) {
      return AuthManager.user
    }

    // Check if we've exceeded the amount of attempts
    if ((attempts ?? 0) > 20) {
      return null
    }

    // No user - lets wait and retry
    await new Promise((r) => setTimeout(r, 100))

    // Retry
    return getUser((attempts ?? 0) + 1)
  }

  const getPolicies = async (
    attempts?: number
  ): Promise<IPolicyKey[] | null> => {
    // Check if we've got the user
    if (AuthManager.policies && AuthManager.policies.length > 0) {
      return AuthManager.policies
    }

    // Check if we've exceeded the amount of attempts
    if ((attempts ?? 0) > 20) {
      return null
    }

    // No user - lets wait and retry
    await new Promise((r) => setTimeout(r, 100))

    // Retry
    return getPolicies((attempts ?? 0) + 1)
  }

  const getWorkspace = async (
    attempts?: number
  ): Promise<IWorkspace | null> => {
    // Check if we've got the user
    if (WorkspaceManager.selectedWorkspace !== null) {
      return WorkspaceManager.selectedWorkspace
    }

    // Check if we've exceeded the amount of attempts
    if ((attempts ?? 0) > 20) {
      return null
    }

    // No user - lets wait and retry
    await new Promise((r) => setTimeout(r, 100))

    // Retry
    return getWorkspace((attempts ?? 0) + 1)
  }

  // Function to sign the user out - clear session and move
  // them to the auth screen
  const signOut = (currentPathname: string) => {
    // Make sure the token is removed from the cookies
    Cookies.remove('token')

    // Clear the user object from state
    AuthManager.setUser(null)

    // Clear the react query cache
    queryClient.clear()

    // Clear any hubspot conversations
    clearHubspot()

    // Clear the expiration date of the token so we don't accidentally
    // trigger another signout after signing out!
    setTokenExpiration(null)
    localStorage.removeItem(STORAGE_KEYS.SESSION_EXPIRATION_KEY)

    // Check the current url state (we don't want to recursively redirect)
    if (
      !matchPath(ROUTES.AUTH.INDEX, currentPathname) &&
      !matchPath(ROUTES.AUTH.RESET_PASSWORD, currentPathname)
    ) {
      // User is not on an /auth or /auth/xyz or /reset-password screens so we'll grab the
      // current path and push it into the url so we can redirect back
      const params = new URLSearchParams()
      params.set('from', currentPathname)
      return window.location.replace(
        ROUTES.AUTH.INDEX + '?' + params.toString()
      )
    } else if (currentPathname.includes(ROUTES.AUTH.WORKSPACE)) {
      // If we're on the workspace selection screen the user will technically be in
      // an auth screen but we don't want to leave them there as they're not logged in
      return window.location.replace(ROUTES.AUTH.INDEX)
    } else {
      // User must be on an auth or /auth/xyz screen so we can leave them here
      // no worries :)
      return false
    }
  }

  return (
    <>
      <ContextsProviders type={'NAVIGATION_PROVIDERS'}>
        {_accessState === 'LOADING' ? (
          <div className={'flex w-screen h-screen items-center justify-center'}>
            <CardBadge type={'loading'} size={'large'} />
          </div>
        ) : (
          <GuardContent
            authenticated={_authenticationState === 'AUTHENTICATED'}
            showNavigation={props.showNavigation === true}
          >
            {_accessState === 'NO_ACCESS' ? (
              <Card.PageState
                type={'declined'}
                title={t('404')}
                description={t('restricted_access')}
              />
            ) : (
              (props.children ?? <Outlet />)
            )}
          </GuardContent>
        )}
      </ContextsProviders>
    </>
  )
}

export default Guard
