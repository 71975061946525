import '../animations.css'

import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'
import { useToast } from '@/contexts/toast'

export const ToastBanner: React.FC = () => {
  const { currentToast } = useToast()
  const [_transitionClass, setTransitionClass] = useState<string>('alert-enter')
  const [_timeRemaining, setTimeRemaining] = useState<number>(0)

  useEffect(() => {
    if (currentToast) {
      // Display the alert
      setTransitionClass('alert-active')

      // Set the initial time remaining - we'll use seconds and
      // update the value every 1000ms which should be close enough
      setTimeRemaining(currentToast.duration ?? 3000)

      // Start a timer running so we can hide the alert once
      // the duration has passed
      const timer = setInterval(() => {
        setTimeRemaining((currentTime) => {
          const timeRemaining = Math.max(0, currentTime - 1000)

          // Check if we should exit the timeout
          if (timeRemaining <= 0) {
            // Timers finished so lets clear the timeout and
            // transition the toast back out of the screen
            clearTimeout(timer)
            setTransitionClass('alert-exit')
          }

          return timeRemaining
        })
      }, 1000)

      // Clear the timeout
      return () => clearTimeout(timer)
    }

    return () => {}
  }, [currentToast])

  const calculateCountdownString = (): string => {
    const msRemaining = _timeRemaining

    // Work out what is remaining
    const days = Math.floor(msRemaining / (1000 * 60 * 60 * 24))
    const hours = Math.floor((msRemaining / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((msRemaining / 1000 / 60) % 60)
    const seconds = Math.floor((msRemaining / 1000) % 60)

    return `${days}d ${hours}hr ${minutes}m ${seconds}s`
  }

  if (!currentToast) return null
  return (
    <div
      className={twMerge(
        'flex items-center gap-1 h-[3.25rem] w-auto max-w-[min(90vw,40rem)] shadow-[0_0.5rem_1rem_0.0625rem_rgba(40,38,35,0.15)] rounded-full pl-[0.375rem] pr-6 absolute z-[500]',
        'overflow-hidden',
        currentToast.showCountdown && 'gap-2',
        currentToast.type === 'success'
          ? 'bg-green-500'
          : currentToast.type === 'critical'
            ? 'bg-yellow-500'
            : 'bg-black',
        _transitionClass
      )}
      data-testid={'banner-toast'}
    >
      <div
        className={twMerge(
          'flex w-10 h-10 min-w-10 min-h-10 items-center justify-center rounded-full bg-transparent',
          currentToast.showCountdown && 'bg-black',
          currentToast.showCountdown &&
            currentToast.type === 'information' &&
            'bg-white'
        )}
      >
        <Icon
          name={
            currentToast.showCountdown
              ? 'clock'
              : currentToast.type === 'success'
                ? 'circle-check'
                : 'circle-info'
          }
          size={'medium'}
          family={'sharp'}
          variant={'solid'}
          className={twMerge(
            (!currentToast.showCountdown && currentToast.type === 'critical') ||
              (currentToast.showCountdown &&
                currentToast.type === 'information')
              ? 'text-black'
              : 'text-white'
          )}
        />
      </div>
      {currentToast.showCountdown && (
        <Typography
          variant="paragraph-small"
          className={twMerge(
            'font-bold text-nowrap',
            'select-none',
            currentToast.type === 'critical' ? 'text-black' : 'text-white'
          )}
        >
          {calculateCountdownString()}
        </Typography>
      )}
      <Typography
        variant="paragraph-medium"
        className={twMerge(
          'select-none text-nowrap overflow-hidden text-ellipsis',
          currentToast.type === 'critical' ? 'text-black' : 'text-white'
        )}
        testId="toast-text"
      >
        {currentToast.text}
      </Typography>
    </div>
  )
}
