import { t } from 'i18next'
import { QRCodeCanvas } from 'qrcode.react'

import Button from '@/components/button'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { copyToClipboard } from '@/helpers/copyToClipboard'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  address: string
}
export const QRWalletAddressModal = (props: Props) => {
  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[380px]'}
      closeOnBackgroundClick={true}
    >
      <div className="flex p-[24px] flex-col items-center w-full gap-[16px]">
        <div className={'flex flex-row w-full gap-[16px]'}>
          <Paragraph
            subTitle={t('blockchain_wallet_address')}
            spacerOverrides={{ subTitle: [] }}
            classOverrides={{
              base: 'flex-1',
            }}
          />
          <Button.Shape
            hierarchy={'tertiary'}
            size={'medium'}
            layout={'icon'}
            icon={{ name: 'xmark', family: 'sharp', variant: 'solid' }}
            onClick={props.onClose}
          />
        </div>

        <div className="flex justify-center">
          <QRCodeCanvas
            value={props.address}
            size={664}
            style={{
              width: '332px',
              height: 'auto',
              maxWidth: '100%',
            }}
          />
        </div>

        <div className={'flex w-full'}>
          <Input.Text
            state={'readonly'}
            value={props.address}
            trailingIcon={
              <Icon
                name={'copy'}
                family={'sharp'}
                variant={'solid'}
                onClick={() =>
                  copyToClipboard(props.address, t('blockchain_wallet_address'))
                }
              />
            }
          />
          {/* <Button.Basic
            hierarchy={'tertiary'}
            size={'medium'}
            width={'flex'}
            trailingIcon={{ name: 'copy', family: 'sharp', variant: 'solid' }}
            label={props.address}
          /> */}
        </div>
      </div>
    </Modal>
  )
}
