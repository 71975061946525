import { t } from 'i18next'

import { ToastManager } from '@/contexts/toast'

export const copyToClipboard = (text: string, nameOfValue?: string) => {
  navigator.clipboard.writeText(text)
  ToastManager.showToast({
    type: 'information',
    text: t('value_copied_to_clipboard', {
      value: nameOfValue ?? t('value'),
    }),
  })
}
