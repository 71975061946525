import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export interface LoaderData {
  assetId: string
}
export const loader: LoaderFunction = async ({
  params,
}): Promise<LoaderData> => {
  try {
    if (!params.asset_id) {
      return ErrorHandler(404, t('missing_params'))
    }

    return { assetId: params.asset_id }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error) ?? t('failed_to_load_asset')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
