import { JSX, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  align?: 'left' | 'right' | 'center'
  slim?: boolean
  children: ReactNode
} & JSX.IntrinsicElements['td']

const classAlignment = (alignment: Props['align']) => {
  switch (alignment) {
    case 'left':
      return 'text-left'
    case 'right':
      return 'text-right'
    case 'center':
      return 'text-center'
    default:
      return 'text-left'
  }
}

const getPadding = (slim: Props['slim']) => {
  if (slim === true) {
    return 'px-4 py-1'
  }
  return 'px-4 py-4'
}

const TextCell = (props: Props) => {
  return (
    <td
      {...props}
      className={twMerge(
        'whitespace-nowrap max-w-[14rem] text-gray-600 text-ellipsis overflow-hidden',
        classAlignment(props.align ?? 'left'),
        getPadding(props.slim ?? false),
        props.className
      )}
    >
      {props.children}
    </td>
  )
}

const NumberCell = (props: Props) => {
  return (
    <td
      {...props}
      className={twMerge(
        'whitespace-nowrap max-w-[14rem] text-gray-600 text-ellipsis overflow-hidden',
        classAlignment(props.align ?? 'center'),
        getPadding(props.slim ?? false),
        props.className
      )}
    >
      {props.children}
    </td>
  )
}

const ChipCell = (props: Props) => {
  return (
    <td
      {...props}
      className={twMerge(
        'whitespace-nowrap w-1 max-w-fit',
        classAlignment(props.align ?? 'center'),
        getPadding(props.slim ?? false),
        'px-8',
        props.className
      )}
    >
      {props.children}
    </td>
  )
}

const ButtonCell = (props: Props) => {
  return (
    <td
      {...props}
      className={twMerge(
        'whitespace-nowrap w-1 max-w-fit',
        classAlignment(props.align ?? 'center'),
        getPadding(props.slim ?? false),
        props.className
      )}
    >
      <span className={'ml-auto mr-auto inline-block'}>{props.children}</span>
    </td>
  )
}

const CheckboxCell = (props: Props) => {
  return (
    <td
      {...props}
      className={twMerge(
        'whitespace-nowrap w-1 text-gray-600',
        classAlignment(props.align ?? 'center'),
        getPadding(props.slim ?? false),
        'px-0',
        props.className
      )}
    >
      {props.children}
    </td>
  )
}

const IconCell = (props: Props) => {
  return (
    <td
      {...props}
      className={twMerge(
        'whitespace-nowrap w-1 text-gray-600',
        classAlignment(props.align ?? 'center'),
        getPadding(props.slim ?? false),
        props.className
      )}
    >
      {props.children}
    </td>
  )
}

const ImageCell = (props: { fill?: 'no_fill' | 'fill' } & Props) => {
  return (
    <td {...props} className={twMerge('whitespace-nowrap p-0 w-1')}>
      <div
        className={twMerge(
          'flex w-[4rem] h-[4rem] min-w-[4rem] min-h-[4rem] max-w-[4rem] max-h-[4rem]',
          'rounded-md overflow-hidden',
          'object-cover items-center justify-center',
          props.fill === 'no_fill' && 'bg-transparent',
          props.className
        )}
      >
        {props.children}
      </div>
    </td>
  )
}

export default {
  Text: TextCell,
  Number: NumberCell,
  Chip: ChipCell,
  Button: ButtonCell,
  Checkbox: CheckboxCell,
  Icon: IconCell,
  Image: ImageCell,
}
