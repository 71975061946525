export const API_ERRORS = {
  LOGIN: {
    WRONG_CREDENTIALS: 'invalid email and password combination',
    USER_DISABLED: 'this user is disabled',
  },
  TWO_STEP_VERIFICATION: {
    WRONG_CODE: '2FA code is invalid',
  },
  RECOVER_PASSWORD: {
    ACCOUNT_NOT_FOUND: 'failed to find account',
  },
  UNSUFFICIENT_POLICIES: 'You do not have sufficient policies',
}
