import { useLoaderData, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { AssetDetails } from '@/components/asset/detail'
import Banner from '@/components/banner'
import { Divider } from '@/components/divider'
import Logo from '@/components/logo'

import { LoaderData } from './loader'

export const Shares: React.FC = (): JSX.Element => {
  const { asset, sessionToken } = useLoaderData() as LoaderData
  const navigate = useNavigate()
  return (
    <div className="flex flex-col h-[100dvh] min-h-[100dvh] max-h-[100dvh] w-[100vw] max-w-[100vw] overflow-hidden">
      <Banner.Site />
      <div
        className={twMerge(
          'flex w-full px-[32px] flex-col items-center',
          'bg-white'
        )}
      >
        <div
          className={twMerge(
            'flex flex-row h-[4.5rem] justify-between items-center self-stretch'
          )}
        >
          <Logo.WordMark
            style={'COLOR'}
            heightRem={2.75}
            color={'BLACK'}
            onClick={() => navigate('/')}
          />
        </div>
        <Divider />
      </div>
      <main className="overflow-y-auto min-h-[calc(100dvh-5rem)] pb-6 tablet:pb-8 desktop:pb-16">
        <Banner.Toast />
        <Banner.Welcome />
        <Banner.Page />
        <div className={twMerge('flex flex-col items-center')}>
          <div className={'w-full max-w-[720px]'}>
            <AssetDetails
              asset={asset}
              readOnly={true}
              sessionToken={sessionToken}
            />
          </div>
        </div>
      </main>
    </div>
  )
}
