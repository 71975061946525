import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/button'
import Chip from '@/components/chip'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { ROUTES } from '@/constants/routes'
import { useDropdown } from '@/contexts/interface'
import { dateToString } from '@/helpers/dateToString.ts'
import { formatValue } from '@/helpers/formatValue'
import { useOrg } from '@/hooks/queries/useOrg'
import { IOperation, IOperationStatus } from '@/types/operations'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

export type SortBy = 'az' | 'latest' | 'value'
export type GroupBy = 'all' | 'active' | 'archived'

export interface Props extends BaseTableProps {
  cases: IOperation[]
  defaultGroupBy?: GroupBy
  defaultSortBy?: SortBy
  displayArchivedByDefault?: boolean
}

export const CasesTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = 'latest'
  const defaultGroupBy: GroupBy =
    props.displayArchivedByDefault === true ? 'all' : 'active'
  const { t } = useTranslation()
  const { org } = useOrg()
  const { setDropdown } = useDropdown()
  const navigate = useNavigate()
  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const [_groupBy, setGroupBy] = useState<GroupBy>(defaultGroupBy)
  const [_cases, setCases] = useState<IOperation[]>([])

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setCases([...sort(filter(group(props.cases)))])
  }, [props.cases])

  useEffect(() => {
    if (props.defaultGroupBy) {
      setGroupBy(props.defaultGroupBy)
    }
  }, [props.defaultGroupBy])

  // Monitor for sortBy changes + update
  useEffect(() => {
    setCases([...sort(_cases)])
  }, [_sortBy])

  // Monitor for groupBy changes + update
  useEffect(() => {
    setCases([...sort(filter(group(props.cases)))])
  }, [_groupBy])

  // Update our visibility and sort
  const onGroupChanged = (id: GroupBy) => setGroupBy(id)
  const onSortChanged = (id: SortBy) => setSortBy(id)

  // Sorts the provided data
  const sort = (cases: IOperation[]): IOperation[] => {
    switch (_sortBy) {
      case 'latest':
        return cases.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      case 'az':
        return cases.sort((a, b) => a.name.localeCompare(b.name))
      case 'value':
        return cases.sort(
          (a, b) =>
            b.total_price_of_digital_assets.value +
            b.total_price_of_physical_assets.value -
            (a.total_price_of_digital_assets.value +
              a.total_price_of_physical_assets.value)
        )
      default:
        return cases
    }
  }

  // Group the provided data
  const group = (cases: IOperation[]): IOperation[] => {
    const groupStatuses = {
      active: ['PENDING', 'ACTIVE'],
      archived: ['ARCHIVED'],
      all: ['PENDING', 'ACTIVE', 'ARCHIVED'],
    } as { [k in GroupBy]: Array<IOperationStatus> }
    return cases.filter((c) => groupStatuses[_groupBy].includes(c.status))
  }

  const filter = (cases: IOperation[]): IOperation[] => {
    return cases
  }

  const renderRow = (row: IOperation, index: number) => {
    // Handle navigation
    const handleNavigateToDetails = () => {
      navigate(
        ROUTES.CASES.DETAIL.replace(':case_id', row.id).replace(
          ':case_id',
          row.id
        )
      )
    }

    // const handleShareCase = () =>
    //   setUrlParams({
    //     'share-case-modal': row?.id,
    //   })

    return (
      <Row
        key={`${row.id}_${index}`}
        onClick={row.status === 'ACTIVE' ? handleNavigateToDetails : undefined}
      >
        <Cell.Icon>
          <Icon name="folders" variant={'solid'} family={'sharp'} size={'md'} />
        </Cell.Icon>
        <Cell.Text title={row.name} className={'max-w-[20rem]'}>
          {row.name}
        </Cell.Text>
        <Cell.Number>{(row.defendants ?? []).length}</Cell.Number>
        <Cell.Number>
          {row.number_of_digital_assets + row.number_of_physical_assets}
        </Cell.Number>
        <Cell.Number align="right">
          {formatValue(
            row.total_price_of_digital_assets.value +
              row.total_price_of_physical_assets.value,
            row.total_price_of_digital_assets.currency ??
              org?.preferred_currency
          )}
        </Cell.Number>
        <Cell.Text align={'center'}>{dateToString(row.created_at)}</Cell.Text>
        <Cell.Chip>
          <Chip.Basic
            size={'medium'}
            label={t(row.status.toLowerCase())}
            colour={
              row.status === 'ACTIVE'
                ? 'green'
                : row.status === 'ARCHIVED'
                  ? 'red'
                  : row.status === 'PENDING'
                    ? 'yellow'
                    : 'white'
            }
          />
        </Cell.Chip>
        <Cell.Button>
          <Button
            id={`btn_case_ellip_drop_${row.id}`}
            testId={'btn_operation_ellipsis'}
            layout={'icon'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'ellipsis',
            }}
            onClick={() => {
              setDropdown({
                target: `btn_case_ellip_drop_${row.id}`,
                controller: <Dropdown.Controllers.CaseActions case={row} />,
                maxWidthPx: 270,
              })
            }}
          />
        </Cell.Button>
      </Row>
    )
  }

  return (
    <BaseTable<IOperation, SortBy, GroupBy, null>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      filter={{
        options: [
          {
            id: 'all',
            label: t('all'),
            value: 'all',
          },
          {
            id: 'active',
            label: t('active'),
            value: 'active',
          },
          {
            id: 'archived',
            label: t('archived'),
            value: 'archived',
          },
        ],
        onFilterChanged: onGroupChanged,
        defaultSelectedId: _groupBy,
      }}
      sort={{
        options: [
          {
            id: 'az',
            value: 'az',
            label: t('a-z'),
            icon: 'user-circle',
          },
          {
            id: 'latest',
            value: 'latest',
            label: t('latest'),
            icon: 'calendar',
            iconVariant: 'regular',
          },
          {
            id: 'value',
            value: 'value',
            label: t('value'),
            icon: 'coins',
            iconVariant: 'regular',
          },
        ],
        onSortByChanged: onSortChanged,
        defaultSelectedId: defaultSortBy,
      }}
      headers={[
        {
          label: t('case_name'),
          colSpan: 2,
        },
        {
          label: t('subjects'),
          alignment: 'center',
        },
        {
          label: t('assets'),
          alignment: 'center',
        },
        {
          label: t('value'),
          alignment: 'right',
        },
        {
          label: t('created'),
          alignment: 'center',
        },
        {
          label: t('status'),
          alignment: 'center',
        },
        // {
        //   label: '',
        // },
        {
          label: '',
        },
      ]}
      data={_cases}
      onRenderRow={renderRow}
    />
  )
}
