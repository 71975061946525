import { useQuery } from '@tanstack/react-query'

import { getFileMetadata } from '@/api/files'

export const key = 'file-metadata'
export const useFileMetadata = (fileKey?: string, sessionToken?: string) => {
  const { isLoading, data } = !fileKey
    ? { isLoading: false, data: undefined }
    : useQuery({
        queryKey: [key, fileKey, sessionToken],
        queryFn: () => getFileMetadata(fileKey, sessionToken),
        enabled: true,
        staleTime: Infinity,
      })
  return { metadata: data?.[0], isLoading }
}
