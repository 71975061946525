import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { Image } from '@/components/image'
import { useFileMetadata } from '@/hooks/queries/useFileMetadata'
import { IFile } from '@/types/file'

interface Props {
  file?: File | IFile
  size: 'large' | 'small'
  onClick: (file: File | IFile) => void
  isPreviewing?: boolean
  onRemoveClick?: (file: File | IFile) => void

  // Used for sharing/transfering
  sessionToken?: string
}
export const GalleryThumbnail = (props: Props) => {
  const { metadata } = useFileMetadata(
    props.file && 'id' in props.file ? props.file.key : undefined,
    props.sessionToken
  )
  const [_content, setContent] = useState<string>('')

  useEffect(() => {
    if (props.file) {
      if ('id' in props.file && props.file.metadata?.url) {
        // File type is a IFile and we already have a url for it
        // so we'll simply return that
        setContent(props.file.metadata.url)
      } else if ('id' in props.file) {
        // File type is IFile but we don't yet have a metadata so we'll
        // grab that here
        setContent(metadata?.url ?? '')
      } else {
        // File type is File - read the file content
        const reader = new FileReader()
        reader.onloadend = () => {
          setContent(reader.result?.toString() ?? '')
        }
        reader.readAsDataURL(props.file)
      }
    }
  }, [props.file, metadata])

  const onClick = () => {
    if (props.file) {
      props.onClick && props.onClick(props.file)
    }
  }

  const onRemoveClick = () => {
    if (props.file) {
      props.onRemoveClick && props.onRemoveClick(props.file)
    }
  }

  return (
    <div
      className={twMerge(
        'flex min-w-[105px] max-w-[105px] min-h-[82px] max-h-[82px]',
        'relative justify-center items-center',
        'rounded-[6px] overflow-hidden',
        props.isPreviewing && 'border-solid border-[2px] border-black',

        props.size === 'large' &&
          'w-full max-w-none h-[50vw] min-h-[240px] max-h-[420px]',
        props.size === 'large' && 'rounded-[12px] bg-gray-100'
      )}
      onClick={onClick}
    >
      {props.size === 'large' ? (
        <Image
          src={_content}
          alt={`image for file ${props.file?.name ?? 'unknown'}`}
          className={twMerge('absolute w-full h-full object-contain')}
        />
      ) : (
        <div
          className={twMerge(
            'w-full h-full overflow-hidden',
            'absolute  rounded-[3px] bg-gray-100',
            props.isPreviewing && 'w-[calc(100%-4px)] h-[calc(100%-4px)]'
          )}
        >
          <Image
            src={_content}
            alt={`thumbnail for file ${props.file?.name ?? 'unknown'}`}
            className={twMerge('absolute w-full h-full object-contain')}
          />
          {props.size === 'small' && props.onRemoveClick && (
            <Button.Shape
              layout={'icon'}
              hierarchy={'primary'}
              size={'small'}
              icon={{
                name: 'close',
                family: 'sharp',
                variant: 'solid',
              }}
              className={
                'absolute top-[0.5rem] right-[0.5rem] bg-[#21242780] border-[#E1E1E2]'
              }
              withAttributes={{
                type: 'button',
              }}
              onClick={onRemoveClick}
            />
          )}
        </div>
      )}
    </div>
  )
}
