import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Button from '@/components/button'
import { Chip } from '@/components/chip'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { IHealth, IHealthDataProps } from '@/types/health'

export const HealthDetail: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const [health] = useState<IHealth>(location.state.health)
  const data: IHealthDataProps | null = health.data

  const rpcLinkInUse = (key: string) => (
    <div className="pt-[2rem]" key={key}>
      <Input.Text
        className="max-w-[42rem]"
        state={'readonly'}
        label={t('rpc_link')}
        value={data?.rpc_link_in_use ?? '-'}
        trailingIcon={
          <Icon
            name={'copy'}
            family={'sharp'}
            variant={'solid'}
            onClick={() =>
              copyToClipboard(data?.rpc_link_in_use ?? '', t('rpc_link'))
            }
          />
        }
      />
    </div>
  )
  const streamingLinkInUse = (key: string) => (
    <div className="pt-[2rem]" key={key}>
      <Input.Text
        className="max-w-[42rem]"
        state={'readonly'}
        label={t('streaming_link')}
        value={data?.streaming_link_in_use ?? '-'}
        trailingIcon={
          <Icon
            name={'copy'}
            family={'sharp'}
            variant={'solid'}
            onClick={() =>
              copyToClipboard(
                data?.streaming_link_in_use ?? '',
                t('streaming_link')
              )
            }
          />
        }
      />
    </div>
  )
  const availableRPCLinks = () => {
    if (!data?.available_rpc_links || data?.available_rpc_links?.length < 1)
      return <></>
    return data.available_rpc_links.map((available_link: string, i: number) => (
      <div className="pt-[2rem]" key={`${available_link} + ${i}`}>
        <Input.Text
          className="max-w-[42rem]"
          state={'readonly'}
          label={t('available_rpc_link')}
          value={available_link ?? '-'}
          trailingIcon={
            <Icon
              name={'copy'}
              family={'sharp'}
              variant={'solid'}
              onClick={() =>
                copyToClipboard(available_link ?? '', t('available_rpc_link'))
              }
            />
          }
        />
      </div>
    ))
  }
  const availableStreamingLinks = () => {
    if (
      !data?.available_streaming_links ||
      data?.available_streaming_links?.length < 1
    )
      return <></>
    return data.available_streaming_links.map(
      (available_link: string, i: number) => (
        <div className="pt-[2rem]" key={`${available_link} + ${i}`}>
          <Input.Text
            key={i}
            className="max-w-[42rem]"
            state={'readonly'}
            label={t('available_streaming_link')}
            value={available_link ?? '-'}
            trailingIcon={
              <Icon
                name={'copy'}
                family={'sharp'}
                variant={'solid'}
                onClick={() =>
                  copyToClipboard(
                    available_link ?? '',
                    t('available_streaming_link')
                  )
                }
              />
            }
          />
        </div>
      )
    )
  }
  const lastBlockNumber = (key: string) => (
    <div className="pt-[2rem]" key={key}>
      <Input.Text
        className="max-w-[16rem]"
        state={'readonly'}
        label={t('last_block_number')}
        value={data?.last_processed_block_number ?? '-'}
        trailingIcon={
          <Icon
            name={'copy'}
            family={'sharp'}
            variant={'solid'}
            onClick={() =>
              copyToClipboard(
                data?.last_processed_block_number ?? '',
                t('last_block_number')
              )
            }
          />
        }
      />
    </div>
  )
  const lastBlocktimestamp = (key: string) => (
    <div className="pt-[2rem]" key={key}>
      <Input.Text
        className="max-w-[16rem]"
        state={'readonly'}
        label={t('last_block_hash')}
        value={data?.last_processed_block_timestamp ?? '-'}
        trailingIcon={
          <Icon
            name={'copy'}
            family={'sharp'}
            variant={'solid'}
            onClick={() =>
              copyToClipboard(
                data?.last_processed_block_timestamp ?? '',
                t('last_block_hash')
              )
            }
          />
        }
      />
    </div>
  )

  const healthDetail = () => {
    if (!data) return <></>

    return Object.keys(data).map((key: string) => {
      if (key === 'rpc_link_in_use') return rpcLinkInUse(key)
      if (key === 'streaming_link_in_use') return streamingLinkInUse(key)
      if (key === 'available_rpc_links') return availableRPCLinks()
      if (key === 'available_streaming_links') return availableStreamingLinks()
      if (key === 'last_processed_block_number') return lastBlockNumber(key)
      if (key === 'last_processed_block_timestamp')
        return lastBlocktimestamp(key)
      return
    })
  }

  useEffect(() => {
    if (health.error) {
      BannerManager.showBanner({
        variant: 'page',
        type: 'error',
        title: `Account suspended`,
        description: `You need to add more funds to this account in order to resume service`,
      })
    }
  }, [])

  return (
    <div className="w-full flex justify-center">
      <div
        className="flex flex-col flex-grow gap-4 px-8"
        data-testid="health-detail"
      >
        <div className={'pt-[1rem]'}>
          <Button.Back />
        </div>

        <div className="mb-[3rem]">
          <Paragraph
            title={`${health.service} ${t('monitor')}`}
            subTitle="Monday, Jan 15, 2024, 12:13:06"
          />
          <div className="my-[1rem]">
            <Chip
              label={health.error ? 'Interruped' : 'Operational'}
              colour={health?.error ? 'yellow' : 'green'}
            />
          </div>

          {healthDetail()}
        </div>
      </div>
    </div>
  )
}
