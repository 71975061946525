import { t } from 'i18next'
import { ReactElement, useEffect, useState } from 'react'

import { Avatar } from '@/components/avatar'
import { RowTypes } from '@/components/dropdown/components/list.dropdown'
import { Icon, Props as IconProps } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { useDefendants } from '@/hooks/queries/useDefendants'
import { IDefendant } from '@/types/defendants'

import { Props as DropdownViewProps } from '../../dropdown/components/view.dropdown'
import { Props as InputFieldProps } from '../components/field.input'
import { Props as InputLabelProps } from '../components/label.input'
import { InputSuggestion } from './suggestion.input'

interface Props<T> {
  name?: string
  defaultValue?: string
  label?: string | ReactElement<InputLabelProps>
  icon?: ReactElement<IconProps>
  size?: InputFieldProps['size']
  placeholder?: string
  listGap?: DropdownViewProps<T>['gap']
  state?: 'default' | 'disabled'
  className?: string
  testId?: string
  onSelectedIdChange?: (id: string) => void
}

export const InputDefendant = (props: Props<IDefendant>) => {
  const { defendants } = useDefendants()
  const [_items, setItems] = useState<DropdownViewProps<IDefendant>['items']>(
    []
  )

  useEffect(() => {
    setItems(
      defendants
        ? defendants
            .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
            .map((defendant, index) =>
              renderDefendantListItem(defendant, index)
            )
        : []
    )
  }, [defendants])

  const renderDefendantListItem = (d: IDefendant, index: number) => {
    return (
      <ListItem
        key={`option_${index}`}
        title={d.name}
        description={d.platform_id}
        value={d.id}
        leading={<Avatar type={'monogram'} value={d.name} size={'small'} />}
        style={'bold'}
        className={'pl-2 pr-2'}
      />
    )
  }

  const onItemSelectionChanged = (item: RowTypes<string> | null) => {
    if (item !== null && 'value' in item && item.value) {
      props.onSelectedIdChange && props.onSelectedIdChange(item.value)
    } else {
      props.onSelectedIdChange && props.onSelectedIdChange('')
    }
  }

  return (
    <InputSuggestion
      name={props.name}
      label={props.label}
      defaultValue={props.defaultValue}
      addValueAsEntry={false}
      placeholder={t('search_for_subject')}
      onItemSelectionChange={onItemSelectionChanged}
      icon={
        <Icon
          name={'user-tie-hair'}
          variant={'solid'}
          family={'sharp'}
          size={'medium'}
        />
      }
      items={_items}
      className={props.className}
      testId={props.testId}
    />
  )
}
