import { t } from 'i18next'
import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { findLastStatus } from '@/actions/create-asset/assetForm'
import { postRequestDigitalAssetBalanceUpdate, updateAsset } from '@/api'
import AssetAmountAndIconDigital from '@/components/asset/detail/components/asset-amount-and-icon-digital.tsx'
import Button from '@/components/button'
import Chip from '@/components/chip'
import { EventLogs } from '@/components/event-logs/event-logs'
import File from '@/components/file'
import BottomPadding from '@/components/footer/BottomPadding.tsx'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { Typography } from '@/components/typography'
import { queryClient } from '@/contexts'
import { BannerManager } from '@/contexts/banner'
import { useDrawer } from '@/contexts/interface'
import { ToastManager } from '@/contexts/toast'
import { getAssetHasImages } from '@/helpers/assetFiles.ts'
import { AssetRules, RuleMode } from '@/helpers/assetRules.ts'
import { getAssetLocationDescription } from '@/helpers/assetTitleAndIcon.ts'
import { getAssetTypeDescription } from '@/helpers/assetTypeDescription.ts'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { dateToString } from '@/helpers/dateToString.ts'
import { isDefined } from '@/helpers/isDefined.ts'
import { key as QueryAssetKey, useAsset } from '@/hooks/queries/useAsset'
import useHubspot from '@/hooks/useHubspot'
import {
  AssetCategory,
  DigitalAssetType,
  DigitalWalletSource,
  IAsset,
  IDigitalAsset,
  IFiatCashAsset,
  IHighValuePersonalPropertyAsset,
  IRealEstateAsset,
  IVehicleAsset,
} from '@/types/asset'
import { IFile } from '@/types/file.ts'

import { AssetTimeline } from '../../asset-timeline'
import { AssetDescription } from './asset-description'
interface Props {
  asset: IAsset

  // Flags for turning features on/off - by default
  // if values are not supplied we'll asume we want to show
  readOnly?: boolean
  showValue?: boolean
  showImage?: boolean
  showId?: boolean
  showClassification?: boolean
  showDescription?: boolean
  showTags?: boolean
  showDefendant?: boolean
  showCustodian?: boolean
  showContract?: boolean
  showTimeline?: boolean
  showFiles?: boolean
  showStatus?: boolean

  // Used for sharing/transfering
  sessionToken?: string
}

export const AssetDetail = (props: Props) => {
  const { setDrawer } = useDrawer()
  const { openConversation } = useHubspot()
  const { refetchAsset } = useAsset(props.asset.id)
  const [lastRefetch, setLastRefetch] = useState<number>(Date.now)
  const [isUpdatingDigitalBalance, setIsUpdatingDigitalBalance] =
    useState<boolean>(false)
  const asset = props.asset
  const digitalAsset: IDigitalAsset | undefined =
    asset.category === AssetCategory.DIGITAL ? asset : undefined
  const [addressOriginallyGenerated] = useState<boolean>(
    digitalAsset?.wallet?.source === DigitalWalletSource.GENERATED &&
      isDefined(digitalAsset?.wallet.address)
  )
  const lastStatus = findLastStatus(props.asset.statuses)
  const digitalWalletSource = digitalAsset?.wallet?.source
  const digitalCanUpdateBalanceOnDemand =
    digitalWalletSource === DigitalWalletSource.GENERATED ||
    digitalWalletSource === DigitalWalletSource.EXISTING
  const assetRules = new AssetRules(
    RuleMode.DISPLAY,
    asset.category,
    (
      asset as
        | IHighValuePersonalPropertyAsset
        | IRealEstateAsset
        | IVehicleAsset
    )?.type,
    (asset as IFiatCashAsset)?.currency
  )

  const EMPTY_VALUE = '-'

  useEffect(() => {
    if (digitalAsset?.wallet?.source === DigitalWalletSource.GENERATED) {
      if (!digitalAsset.wallet.address) {
        // First show the banner
        BannerManager.showBanner({
          variant: 'page',
          type: 'information',
          title: `${capitalizeFirstLetter(digitalAsset.network)} ${t('address')}`,
          description: t('address_being_generated_description'),
        })

        // Set a timeout to refetch the asset details
        setTimeout(() => {
          refetchAsset().finally(() => {
            setLastRefetch(Date.now())
          })
        }, 5000)
      } else if (!addressOriginallyGenerated) {
        // Display a new banner informing of the
        BannerManager.showBanner({
          variant: 'page',
          type: 'success',
          title: `${capitalizeFirstLetter(digitalAsset.network)} ${t('address')}`,
          description: t('address_now_generated_description'),
          duration: 20000,
        })
      } else {
        BannerManager.hideBanners('page')
      }
    }
  }, [lastRefetch])

  const openHubspot = async () => {
    openConversation('service_request')
  }

  const onDeleteFile = async (file: IFile) => {
    const files = asset.files?.filter((e) => e.id !== file.id)
    const newAsset = { ...asset, files }
    const res = await updateAsset(newAsset)
    if (res.error) {
      throw new Error(res.message)
    }
    ToastManager.showToast({
      text: t('file_successfully_deleted'),
      type: 'information',
    })
    await queryClient.invalidateQueries({
      queryKey: [QueryAssetKey, newAsset.id],
    })
  }

  const onDigitalUpdateBalance = async (): Promise<void> => {
    if (isUpdatingDigitalBalance) {
      return
    }
    setIsUpdatingDigitalBalance(true)
    const digitalAssetId = digitalAsset?.id
    if (!isDefined(digitalAssetId)) {
      throw new Error('Asked to update balance of non digital asset')
    }

    const updateSucceeded = await postRequestDigitalAssetBalanceUpdate({
      assetId: digitalAssetId,
      assetSource: 'CUSTODY',
    })
      .then(() => true)
      .catch((error) => {
        console.error('Error updating balance:', error)
        return false
      })

    if (updateSucceeded) {
      ToastManager.showToast({
        text: t('balance_update_completed'),
        type: 'information',
      })
    }
    await refetchAsset()
    setIsUpdatingDigitalBalance(false)
  }

  return (
    <div className={'flex flex-col w-full gap-[36px]'}>
      {props.showValue !== false && (
        <div
          className={twMerge(
            'flex flex-col self-stretch items-start w-full gap-4 flex-wrap',
            'tablet:flex-row tablet:items-end',
            'bg-gray-100 rounded-[12px] p-[16px]'
          )}
        >
          <AssetAmountAndIconDigital asset={asset} />

          {!props.readOnly && (
            <div className={'flex flex-col tablet:flex-row self-stretch gap-4'}>
              <Button.Basic
                hierarchy="secondary"
                size={'small'}
                label={t('service_request')}
                icon={{
                  name: 'comment-exclamation',
                }}
                onClick={openHubspot}
                className={twMerge('self-end tablet:flex')}
              />
              <Button.Basic
                testId={'btn_add_document'}
                hierarchy={'secondary'}
                size={'small'}
                icon={{
                  name: 'plus',
                  family: 'sharp',
                  variant: 'solid',
                }}
                label={t('add_document')}
                onClick={() => {
                  setDrawer({
                    id: 'NEW_ASSET_DOCUMENT',
                    asset: asset,
                  })
                }}
                className={twMerge('self-end tablet:flex')}
              />
              {digitalCanUpdateBalanceOnDemand && (
                <Button.Basic
                  hierarchy={'secondary'}
                  size={'small'}
                  icon={{
                    name: 'arrows-rotate',
                    family: 'sharp',
                    variant: 'solid',
                  }}
                  label={t('update_balance')}
                  testId={'btn_update_balance'}
                  state={isUpdatingDigitalBalance ? 'loading' : 'default'}
                  onClick={() => onDigitalUpdateBalance()}
                  className={twMerge('self-end ')}
                />
              )}
              <Button.Basic
                hierarchy={'secondary'}
                size={'small'}
                icon={{
                  name: 'edit',
                  family: 'sharp',
                  variant: 'solid',
                }}
                label={t('edit')}
                testId={'btn_edit_asset'}
                onClick={() => {
                  setDrawer({ id: 'EDIT_ASSET', asset: asset })
                }}
                className={twMerge('self-end ')}
              />
            </div>
          )}
        </div>
      )}

      {props.showImage !== false && getAssetHasImages(asset) && (
        <File.Gallery
          files={asset.files ?? []}
          showThumbnails={true}
          sessionToken={props.sessionToken}
        />
      )}

      <div className={'flex flex-row w-full gap-[24px] flex-wrap'}>
        {props.showId !== false && (
          <Group
            label={t('ar_reference')}
            inline={true}
            className={'justify-center'}
          >
            <Input.IDNumber
              id={props.asset.platform_id ?? ''}
              hideLabel={true}
            />
          </Group>
        )}

        {props.showStatus !== false && lastStatus && (
          <Group label={t('status')} inline={true} className={'justify-center'}>
            <div className="flex items-center">
              <Chip.Status
                status={lastStatus}
                context={'ASSET'}
                size={'large'}
              />
              <Typography variant={'paragraph-large'} className="ml-2">
                {isDefined(lastStatus?.happened_at)
                  ? dateToString(lastStatus?.happened_at)
                  : ''}
              </Typography>
            </div>
          </Group>
        )}

        {props.showDefendant !== false && asset.defendant && (
          <Group
            label={t('subject')}
            inline={true}
            className={'justify-center flex gap-4'}
          >
            <div
              className={'flex self-stretch items-center w-full gap-4 min-w-32'}
            >
              <div className="flex-shrink-0">
                <Icon name={'user-tie-hair'} variant={'solid'} size={'large'} />
              </div>
              <Input.Auction value={asset.defendant.name} />
            </div>
          </Group>
        )}
      </div>

      {props.showClassification !== false && (
        <Group label={t('asset_details')}>
          <div className={'flex flex-col pt-2'}>
            <div className={'flex self-stretch items-center w-full gap-4 pl-2'}>
              <Icon
                name={getAssetTypeDescription(asset).icon}
                size={'large'}
                variant="solid"
                className={'min-w-8'}
              />
              <Typography variant={'label-medium'} className={'pl-2'}>
                {getAssetTypeDescription(asset).title}
              </Typography>
            </div>

            {assetRules.showCountryAndAddress() &&
              asset.category === AssetCategory.REAL_ESTATE && (
                <div className={'pt-4'}>
                  <Input.Auction
                    icon={
                      <Icon
                        name={'location-pin'}
                        variant={'solid'}
                        size={'large'}
                        className={' mr-2'}
                      />
                    }
                    text={getAssetLocationDescription(asset.location)}
                    className={'min-w-0'}
                  />
                </div>
              )}
          </div>

          <div
            className={
              'flex flex-row justify-start items-start flex-wrap gap-8'
            }
          >
            <div className="flex-shrink-0">
              <Input.Auction
                label={t('identifier')}
                value={asset.external_ref ?? EMPTY_VALUE}
              />
            </div>

            {assetRules.showManufacturer() &&
              asset.category === AssetCategory.VEHICLE && (
                <div className="flex-shrink-0">
                  <Input.Auction
                    label={t('manufacturer')}
                    value={asset.make ?? EMPTY_VALUE}
                  />
                </div>
              )}

            {assetRules.showModel() &&
              asset.category === AssetCategory.VEHICLE && (
                <div className="flex-shrink-0">
                  <Input.Auction
                    label={t('model')}
                    value={asset.model ?? EMPTY_VALUE}
                  />
                </div>
              )}

            {assetRules.showSerial() &&
              asset.category === AssetCategory.VEHICLE && (
                <div className="flex-shrink-0">
                  <Input.Auction
                    label={t('vin_number')}
                    value={asset.vin ?? EMPTY_VALUE}
                  />
                </div>
              )}

            {assetRules.showSerial() &&
              asset.category === AssetCategory.PERSONAL_PROPERTY && (
                <div className="flex-shrink-0">
                  <Input.Auction
                    label={t('serial_number')}
                    value={asset.serial_number ?? EMPTY_VALUE}
                  />
                </div>
              )}

            {assetRules.showSerial() &&
              asset.category === AssetCategory.REAL_ESTATE && (
                <div className="flex-shrink-0">
                  <Input.Auction
                    label={t('plot_number')}
                    value={asset.plot_number ?? EMPTY_VALUE}
                  />
                </div>
              )}

            {asset.category === AssetCategory.DIGITAL &&
              asset.type === DigitalAssetType.NFT && (
                <div className="flex-shrink-0">
                  <Input.Auction
                    label={t('token_number')}
                    value={asset.id ?? 0}
                    className="w-auto"
                  />
                </div>
              )}
          </div>
        </Group>
      )}

      {props.showDescription !== false && (
        <Group label={t('description')}>
          <AssetDescription
            assetId={asset.id}
            notes={asset.notes}
            readOnly={props.readOnly}
          />
        </Group>
      )}

      {props.showTags === true && (
        <Group label={t('search_tags')}>
          {asset.search_tags && asset.search_tags.length > 0 && (
            <div
              className={'flex items-start content-start gap-[8px] flex-wrap'}
            >
              {asset.search_tags.map((tag, index) => (
                <Chip.Basic
                  key={`search_tag_${index}`}
                  label={tag}
                  type={'filled'}
                  size={'medium'}
                  colour={'blue'}
                />
              ))}
            </div>
          )}
        </Group>
      )}

      {(props.showCustodian !== false || props.showContract !== false) &&
        digitalAsset?.wallet && (
          <>
            <Group label={t('address')} className={'flex tablet:hidden'}>
              <Input.Auction
                testId={'input_wallet_address'}
                value={digitalAsset.wallet.address ?? t('pending')}
                multiline={true}
                trailingIcon={
                  digitalAsset.wallet.address ? (
                    <Icon
                      name={'copy'}
                      family={'sharp'}
                      variant={'solid'}
                      size={'medium'}
                      onClick={() =>
                        copyToClipboard(
                          digitalAsset.wallet?.address ?? '',
                          t('wallet_address')
                        )
                      }
                    />
                  ) : (
                    <Icon type={'loader'} color={'black'} size={'small'} />
                  )
                }
                hint={
                  digitalAsset.wallet?.address ? undefined : (
                    <Input.Component.Hint
                      style={'message'}
                      label={t('blockchain_address_generated_message')}
                    />
                  )
                }
                className={'w-auto'}
              />
              {digitalAsset.wallet?.address && (
                <QRCodeCanvas
                  value={digitalAsset.wallet.address}
                  size={1000}
                  style={{
                    // We need to account for padding + scrollbar too
                    width: 'calc(100vw - 60px)',
                    height: 'auto',
                    maxWidth: '100%',
                  }}
                  className={'self-center'}
                />
              )}
              <Input.Auction
                label={t('custodian')}
                icon={
                  <Icon
                    name={'shield-check'}
                    variant={'solid'}
                    size={'medium'}
                  />
                }
                text={digitalAsset?.custodian?.provider ?? EMPTY_VALUE}
                value={digitalAsset?.custodian?.provider ?? ''}
              />
              <Input.Auction
                label={t('contact')}
                text={digitalAsset?.custodian?.contact ?? EMPTY_VALUE}
                value={digitalAsset?.custodian?.contact ?? ''}
              />
            </Group>

            <Group
              label={t('address')}
              id={'qr_section'}
              className={'hidden tablet:flex'}
            >
              <div
                className={
                  'flex flex-row gap-[24px] justify-end items-end self-stretch'
                }
              >
                <div
                  className={twMerge(
                    'flex flex-col items-start gap-[16px]',
                    'flex-[1_0_0%] overflow-hidden'
                  )}
                >
                  <Input.Auction
                    testId={'input_wallet_address'}
                    value={digitalAsset.wallet?.address ?? t('pending')}
                    multiline={true}
                    trailingIcon={
                      digitalAsset.wallet?.address ? (
                        <Icon
                          name={'copy'}
                          family={'sharp'}
                          variant={'solid'}
                          size={'medium'}
                          onClick={() =>
                            copyToClipboard(
                              digitalAsset.wallet?.address ?? '',
                              t('wallet_address')
                            )
                          }
                        />
                      ) : (
                        <Icon type={'loader'} color={'black'} size={'small'} />
                      )
                    }
                    hint={
                      digitalAsset.wallet?.address ? undefined : (
                        <Input.Component.Hint
                          style={'message'}
                          label={t('blockchain_address_generated_message')}
                        />
                      )
                    }
                  />
                  <Input.Auction
                    label={t('custodian')}
                    icon={
                      <Icon
                        name={'shield-check'}
                        variant={'solid'}
                        size={'medium'}
                      />
                    }
                    text={digitalAsset?.custodian?.provider ?? EMPTY_VALUE}
                    value={digitalAsset?.custodian?.provider ?? ''}
                    className={'w-auto'}
                  />
                  <Input.Auction
                    label={t('contact')}
                    text={digitalAsset?.custodian?.contact ?? EMPTY_VALUE}
                    value={digitalAsset?.custodian?.contact ?? ''}
                    className={'w-auto'}
                  />
                  {digitalAsset?.contract_address?.length ? (
                    <Input.Auction
                      label={t('contract_address')}
                      value={digitalAsset?.contract_address}
                      className="w-auto"
                    />
                  ) : null}
                </div>
                <div className={'flex w-[212px] h-[212px]'}>
                  {digitalAsset.wallet?.address ? (
                    <QRCodeCanvas
                      value={digitalAsset.wallet.address}
                      size={424}
                      style={{ width: '212px', height: '212px' }}
                    />
                  ) : (
                    <div
                      className={
                        'flex flex-1 flex-col items-center justify-center bg-[#F5F5F6] rounded-[6px]'
                      }
                    >
                      <Icon
                        name={'qrcode'}
                        size={'xlarge'}
                        variant={'solid'}
                        family={'sharp'}
                      />
                      <Paragraph subTitle={t('qr_code')} />
                    </div>
                  )}
                </div>
              </div>
            </Group>
          </>
        )}

      {props.showTimeline !== false && (
        <div
          className={twMerge(
            'flex flex-col w-full self-stretch gap-[24px]',
            'tablet:flex-row'
          )}
        >
          <Group label={t('timeline')}>
            <AssetTimeline asset={asset} />
          </Group>
        </div>
      )}

      {props.showFiles !== false && (
        <Group label={t('documents')}>
          {asset.files && asset.files.length > 0 ? (
            <Table.Files
              testId={'table_documents'}
              showLeftController={false}
              showSort={false}
              files={asset.files}
              onDeleteFile={onDeleteFile}
            />
          ) : (
            <Paragraph>{}</Paragraph>
          )}
        </Group>
      )}

      {!['main', 'demo'].includes(import.meta.env.MODE) ? (
        <Group label={t('event_logs')}>
          <EventLogs assetId={asset.id} />
        </Group>
      ) : null}
      <BottomPadding />
    </div>
  )
}
