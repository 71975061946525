import { createContext } from 'react'

import { ToastProps } from './types'

type IToastContext = {
  currentToast: ToastProps | null
}

export const ToastContext = createContext<IToastContext>({
  currentToast: null,
})
ToastContext.displayName = 'ToastContext'
