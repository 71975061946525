import { useQuery } from '@tanstack/react-query'

import { getCustodians } from '@/api/org'

export const useCustodians = (orgId: string) => {
  const { isLoading, data } = useQuery({
    queryKey: ['custodians', orgId],
    queryFn: () => getCustodians(orgId),
    enabled: !!orgId,
  })

  return { custodians: data?.data, isLoading }
}
