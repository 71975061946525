import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useRouteError } from 'react-router-dom'

import Card from '@/components/card'
import { ROUTES } from '@/constants/routes'

interface Props {
  statusCode?: number
  statusText?: string
}
export const ErrorPage = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const response = useRouteError() as Response

  useEffect(() => {
    if (response && response.status === 401) {
      navigate(ROUTES.AUTH.INDEX)
    }
  }, [response])

  return (
    <main className="flex min-h-[max(25rem,calc(100dvh-5rem))] min-w-full place-items-center bg-white overflow-auto">
      <Card.PageState
        type={'warning'}
        title={
          props.statusCode
            ? props.statusCode.toString()
            : response && response.status
              ? response.status.toString()
              : '500'
        }
        description={
          props.statusText ?? response.statusText ?? t('something_went_wrong')
        }
        button={{ label: t('go_back'), onClick: () => navigate(-1) }}
        className={'animate-fade-in-up'}
      />
    </main>
  )
}
