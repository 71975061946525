import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import {
  IEscrow,
  IEscrowAsset,
  IEscrowFeeCharge,
  IEscrowOrganizationId,
} from '@/types/escrows'

export type IEscrowStatus = 'PENDING' | 'TRANSFERRED' | 'FAILED'

export const getEscrows = async (): Promise<BaseResponse<IEscrow[]>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client.get<BaseResponse<IEscrow[]>>(`/escrows`).then((response) => {
    return response.data
  })
}

export const getEscrow = async (
  escrow_id: string
): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .get<BaseResponse<IEscrow>>(`/escrows/${escrow_id}`)
    .then((response) => {
      return response.data
    })
}

export const createEscrow = async (
  name: string,
  organizations: [IEscrowOrganizationId, IEscrowOrganizationId],
  digital_assets: Omit<
    IEscrowAsset,
    'id' | 'wallet' | 'destination_wallet' | 'balance'
  >[]
): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows`, {
      name,
      organizations,
      digital_assets,
    })
    .then((res) => res.data)
}

export const updateEscrow = async ({
  escrow,
  destination_addresses,
  required_organization_approvals,
  append_files,
}: {
  escrow: IEscrow
  destination_addresses?: {
    address: string
    asset_id: string
  }[]
  required_organization_approvals?: {
    id: string
    number_of_approvals: number
    require_approval: boolean
  }[]
  append_files?: {
    key: string
    name: string
    type: string
  }[]
}): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  console.log(escrow.organizations)
  return client
    .patch<BaseResponse<IEscrow>>(`/escrows/${escrow.id}`, {
      destination_addresses:
        destination_addresses ??
        escrow.digital_assets
          .filter((a) => a.destination_wallet?.address)
          .map((a) => {
            return {
              address: a.destination_wallet?.address,
              asset_id: a.id,
            }
          }),
      required_organization_approvals:
        required_organization_approvals ??
        escrow.organizations.map((o) => {
          return {
            id: o.id,
            number_of_approvals: o.number_of_approvals ?? 0,
            require_approval: o.require_approval,
          }
        }),
      ...(append_files && { append_files }),
    })
    .then((response) => {
      return response.data
    })
}

export const progressEscrow = async (escrowId: string) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/progress`, {})
    .then((response) => response.data)
}

export const rejectEscrow = async (
  escrowId: string,
  { reason }: { reason: string }
) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/reject`, { reason })
    .then((response) => response.data)
}

export const progressEscrowFee = async (escrowId: string, feeId: string) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<
      BaseResponse<IEscrow>
    >(`/escrows/${escrowId}/fees/${feeId}/progress`, {})
    .then((response) => response.data)
}

export const rejectEscrowFee = async (
  escrowId: string,
  feeId: string,
  reason: string
) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/fees/${feeId}/reject`, {
      reason,
    })
    .then((response) => response.data)
}

export const createEscrowFee = async ({
  escrowId,
  files,
  charge,
}: {
  escrowId: string
  files: { key: string; name: string; type: string }[]
  charge: IEscrowFeeCharge
}) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .post<BaseResponse<IEscrow>>(`/escrows/${escrowId}/fees`, {
      files: files,
      charge,
    })
    .then((response) => response.data)
}

export const updateEscrowFee = async ({
  escrowId,
  feeId,
  files,
  charge,
}: {
  escrowId: string
  feeId: string
  files: { key: string; name: string; type: string }[]
  charge: IEscrowFeeCharge
}) => {
  const client = await getClient(getConfig().BROKER_API_URL_V2)
  return client
    .patch<BaseResponse<IEscrow>>(`/escrows/${escrowId}/fees/${feeId}`, {
      append_files: files,
      charge,
    })
    .then((response) => response.data)
}

export const submitEscrowDocuments = async (
  escrow_id: string,
  files: { key: string; name: string; type: string }[]
): Promise<BaseResponse<IEscrow>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .patch(`/escrows/${escrow_id}`, { append_files: files })
    .then((response) => {
      return response.data
    })
}
