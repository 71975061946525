import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Props as ButtonShapeProps } from '@/components/button/variants/shape.button'
import { IconProps } from '@/components/icon'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame, Props as FrameProps } from '../components/frame.input'
import { InputHint, Props as HintProps } from '../components/hint.input'
import { InputLabel, Props as LabelProps } from '../components/label.input'

export type Props = {
  label?: string | ReactElement<LabelProps>
  hint?: string | ReactElement<HintProps>
  prefix?: string
  suffix?: string
  icon?: ReactElement<IconProps>
  multiline?: boolean
  trailingIcon?: ReactElement<IconProps>
  trailingButton?: ReactElement<ButtonShapeProps>
  className?: string
  testId?: string
} & Omit<FrameProps, 'state'>
export const InputAuction = (props: Props) => {
  return (
    <InputField
      testId={props.testId}
      className={twMerge('max-w-none gap-0', props.className)}
      label={
        typeof props.label === 'string' ? (
          <InputLabel state={'default'} text={props.label} size={'large'} />
        ) : props.label !== undefined ? (
          props.label
        ) : undefined
      }
      hint={
        typeof props.hint === 'string' ? (
          <InputHint state={'default'} label={props.hint} style={'hint'} />
        ) : props.hint !== undefined ? (
          props.hint
        ) : undefined
      }
      element={
        <InputElement
          variant={'auction'}
          icon={props.icon}
          state={'readonly'}
          trailing={
            props.trailingIcon
              ? {
                  icon: props.trailingIcon,
                }
              : props.trailingButton
                ? {
                    button: props.trailingButton,
                  }
                : undefined
          }
          input={
            <InputFrame
              {...props}
              className={twMerge(
                props.multiline && 'overflow-auto h-auto break-words',
                props.className
              )}
              state={'default'}
            />
          }
          prefix={props.prefix}
          suffix={props.suffix}
          className={props.multiline ? 'items-start' : ''}
        />
      }
    />
  )
}
