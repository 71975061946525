import Flags from 'country-flag-icons/react/3x2'

type Props = {
  countryCode: string
  className?: string
}

export const Flag: React.FC<Props> = ({
  countryCode,
  className,
}): JSX.Element => {
  const FlagComponent = Flags[countryCode.toUpperCase() as keyof typeof Flags]
  return (
    <div className={className}>
      <FlagComponent />
    </div>
  )
}
