import { t } from 'i18next'
import { ChangeEvent, useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  user: IUser
  onCancel?: () => void
  onSuccess: () => void
}
export const DeleteUserModal = (props: Props) => {
  const { refetch } = useWorkspaceMembers()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_isValidConfirmation, setIsValidConfirmation] =
    useState<boolean>(false)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (response: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: response.message ?? t('action_successful'),
    })
    onRefetchMembers()
    props.onSuccess()
    props.onClose()
  }

  const onRefetchMembers = async () => {
    return await refetch()
  }

  const onCancel = () => {
    props.onCancel && props.onCancel()
    props.onClose()
  }

  const onConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.toLowerCase() === props.user.name.toLowerCase()) {
      setIsValidConfirmation(true)
    } else {
      setIsValidConfirmation(false)
    }
  }

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[624px] bg-black'}
    >
      <Form
        buttons={[
          <Button.Basic
            key={'btn_delete'}
            label={t('delete_user')}
            hierarchy={'destructive-inverted'}
            icon={{
              name: 'exclamation-triangle',
              family: 'sharp',
              variant: 'solid',
            }}
            state={
              _submitting === INTENTS.DELETE_USER
                ? 'loading'
                : _isValidConfirmation
                  ? 'default'
                  : 'disabled'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.DELETE_USER,
            }}
          />,
          <Button.Basic
            key={'btn_cancel'}
            hierarchy={'tertiary-inverted'}
            size={'medium'}
            label={t('cancel')}
            onClick={onCancel}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={'flex p-6 flex-col items-start gap-6 w-full'}
      >
        <input
          type={'hidden'}
          name={'user_obj'}
          value={JSON.stringify(props.user)}
        />

        <Paragraph
          title={t('delete_user_dialog_title')}
          description={t('delete_user_dialog_description', {
            USER_NAME: props.user.name,
          })}
          spacerOverrides={{
            title: ['bottom'],
            description: ['top'],
          }}
          classOverrides={{
            title: 'text-white',
            description: 'text-white',
          }}
        />

        <div className={'w-full'}>
          <Input.Text
            label={
              <Input.Component.Label
                state={'default'}
                text={t('delete_user_confirmation')}
                size={'medium'}
                classOverrides={{
                  text: 'text-white',
                }}
              />
            }
            name={'confirmation_value'}
            onChange={onConfirmationChange}
          />
        </div>
      </Form>
    </Modal>
  )
}
