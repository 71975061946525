import { Theme } from './types'

interface IThemeManager {
  setThemeHook: React.Dispatch<React.SetStateAction<Theme>> | null

  setThemeFunction: (fn: React.Dispatch<React.SetStateAction<Theme>>) => void

  setTheme: (user: Theme) => void
}

export const ThemeManager: IThemeManager = {
  setThemeHook: null,

  setThemeFunction(fn) {
    this.setThemeHook = fn
  },

  setTheme(theme) {
    if (this.setThemeHook) {
      this.setThemeHook(theme)
    }
  },
}
