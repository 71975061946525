import { twMerge } from 'tailwind-merge'

import { LogoWordMark } from '../word-mark'

interface Props {
  color: 'BLACK' | 'WHITE'
}
export const LogoWordMarkPill = (props: Props) => {
  return (
    <div
      className={twMerge(
        'flex w-[20.875rem] h-[9.0625rem] items-center justify-center',
        'rounded-[2rem]',
        props.color === 'BLACK' ? 'bg-white' : 'bg-black'
      )}
    >
      <LogoWordMark style={'COLOR'} color={props.color} />
    </div>
  )
}
