import { t } from 'i18next'

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  JMD = 'JMD',
  SCR = 'SCR',
  CAD = 'CAD',
}

export const getCurrencyTitle = (currency: Currency) => {
  return t(('' + currency).toLowerCase())
}
export const getCurrencyIcon = (currency: Currency) => {
  switch (currency) {
    case Currency.USD:
    case Currency.JMD:
    case Currency.CAD:
      return 'dollar-sign'
    case Currency.EUR:
      return 'euro-sign'
    case Currency.GBP:
      return 'sterling-sign'
    case Currency.SCR:
      return 'rupee-sign'
  }
}

export const getCurrencySymbol = (currency: Currency | undefined) => {
  switch (currency) {
    case Currency.USD:
      return '$'
    case Currency.JMD:
      return 'J$'
    case Currency.CAD:
      return 'C$'
    case Currency.EUR:
      return '€'
    case Currency.GBP:
      return '£'
    case Currency.SCR:
      return 'Rs'
    default:
      return ''
  }
}
