import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { getHealthChain } from '@/api/health'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IHealth } from '@/types/health'

export const loader: LoaderFunction = async () => {
  try {
    const { data } = await getHealthChain()
    const healthData = data ?? []
    return { data: healthData as IHealth[] }
  } catch (error) {
    const message = ExtractErrorFrom(error) ?? t('something_went_wrong')

    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
  return null
}
