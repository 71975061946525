import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import { Divider } from '@/components/divider'
import Dropdown from '@/components/dropdown'
import { ListItem } from '@/components/list-item'
import { useAuth } from '@/contexts/auth'
import { useDropdown } from '@/contexts/interface'
import { useWorkspace } from '@/contexts/workspace'
import { useOrg } from '@/hooks/queries/useOrg'
import { useWindowSize } from '@/hooks/useWindowSize'

import Searchbar from './searchbar'

export const Header = () => {
  const { user } = useAuth()
  const { org } = useOrg()
  const { isTablet } = useWindowSize()
  const { selectedWorkspace } = useWorkspace()
  const { setDropdown } = useDropdown()
  const [_navMenuOpen, setNavMenuOpen] = useState<boolean>(false)
  const [_mobileSearch, setMobileSearch] = useState<boolean>(false)

  const onNavDropdownToggled = (open: boolean) => {
    setNavMenuOpen(open)
  }

  useEffect(() => {
    if (_mobileSearch) {
      setMobileSearch(false)
    }
  }, [isTablet])

  return (
    <div
      className={twMerge(
        'max-h-[4.5] min-h-[4.5rem]',
        'sticky top-0 z-40 self-center',
        'px-4 2xl:px-8',
        'w-full max-w-[2160px]',
        'flex flex-col items-center bg-white'
      )}
      data-testid="sticky-header"
    >
      <div
        className={'flex flex-1 relative flex-row items-center w-full gap-4 '}
      >
        <div
          className={twMerge(
            'tablet:hidden',
            'flex flex-1 items-center justify-start',
            'tablet:flex-none'
          )}
        >
          <Avatar
            id={'btn_navigation'}
            type={'icon'}
            size={'medium'}
            name={_navMenuOpen ? 'close' : 'bars'}
            family={'sharp'}
            iconVariant={'solid'}
            shape={'round'}
            onClick={() => {
              setDropdown({
                target: 'btn_navigation',
                controller: <Dropdown.Controllers.Navigation />,
                onDropdownToggled: onNavDropdownToggled,
              })
            }}
            className={'flex 2xl:hidden bg-red-500'}
          />
        </div>
        <div className={twMerge('hidden tablet:flex', 'flex-1')}>
          <Searchbar />
        </div>
        <div className="flex items-center gap-x-4">
          <ListItem
            id={'btn_profile_menu'}
            testId="user-menu"
            title={user?.name}
            description={selectedWorkspace?.name ?? org?.name ?? ''}
            style={'bold'}
            textAlignment={'right'}
            trailing={
              <Avatar
                type={'monogram'}
                shape={'round'}
                size={'medium'}
                value={user?.name ?? ''}
              />
            }
            onClick={() => {
              setDropdown({
                target: 'btn_profile_menu',
                controller: <Dropdown.Controllers.Profile />,
                maxHeightPx: 800,
                offsetYPx: 24,
              })
            }}
            className={
              'hover:cursor-pointer hover:bg-transparent active:bg-transparent max-w-[Min(60vw,15rem)]'
            }
          />
        </div>
        <Button.Shape
          layout={'icon'}
          shape={'round'}
          icon={{ name: 'search' }}
          hierarchy={'secondary'}
          onClick={() => setMobileSearch(true)}
          className={'tablet:hidden'}
        />
        <div
          className={twMerge(
            'absolute hidden inset-0 bg-white',
            'items-center gap-4',
            'tablet:hidden',
            _mobileSearch && 'flex'
          )}
        >
          <Searchbar onResultsHidden={() => setMobileSearch(false)} />
          <Button.Shape
            layout={'icon'}
            shape={'round'}
            icon={{ name: 'close' }}
            hierarchy={'secondary'}
            onClick={() => setMobileSearch(false)}
          />
        </div>
      </div>
      <Divider />
    </div>
  )
}

export default Header
