import { STORAGE_KEYS } from '@/helpers/localStorage.ts'

export const shouldShowBanner = (key: string, timeoutMinutes: number = 5) => {
  const localStorageKey = STORAGE_KEYS.BANNERS_FIRST_DISPLAY_PREFIX + key
  const now = Date.now()
  const firstDisplayed = localStorage.getItem(localStorageKey)
  const timeout = timeoutMinutes * 60 * 1000
  if (!firstDisplayed) {
    localStorage.setItem(localStorageKey, now.toString())
  }

  return !firstDisplayed || now - parseInt(firstDisplayed, 10) < timeout
}
