import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'

import { SearchOptionsInputProps } from '../types'
import { InputDropdown } from './dropdown.input'

export const SearchOptionsInput = ({
  containerClassName,
  ...props
}: SearchOptionsInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFocus = () => {
    inputRef.current?.focus()
  }

  const [search, setSearch] = useState('')

  const [isFocused, setIsFocused] = useState(false)
  const [blurListener, setBlurListener] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const [addedOptions, setAddedOptions] = useState(
    [] as {
      label: string
      value: string
    }[]
  )

  const filteredSuggestions = props.suggestions?.filter(
    (option) =>
      option.label.toLowerCase().includes(search.toLowerCase()) &&
      !addedOptions.find((addedOption) => addedOption.value === option.value)
  )

  const handleOptionClick = (option: { label: string; value: string }) => {
    setAddedOptions([...addedOptions, option])
    setSearch('')
    setBlurListener(true)
    setIsFocused(false)
  }

  return (
    <div
      className={twMerge(
        'flex flex-col space-y-2 relative',
        containerClassName,
        props.disabled ? 'cursor-disabled' : ''
      )}
    >
      {props.label && (
        <label htmlFor={props.id}>
          <Typography
            variant="label-small"
            className={twMerge(props.labelClassName)}
          >
            {props.label}
          </Typography>
        </label>
      )}

      <div className="flex tablet:flex-row flex-col-reverse items-start tablet:gap-5">
        <div
          className={twMerge(
            'w-full tabet:w-[25rem] flex flex-row gap-2 rounded-[0.25rem] border-0 p-3 text-gray-900 ring-1 ring-inset ring-[#E1E1E2] placeholder:text-gray-400 focus:ring-inset focus:ring-black min-h-[6.25rem] justify-start items-start flex-wrap cursor-text',
            props.className
          )}
          onClick={handleFocus}
        >
          {
            addedOptions?.map((option, index) => (
              <div
                key={option.value}
                className="flex items-center justify-center gap-2 bg-green-100 rounded-md px-2 py-1"
              >
                <input
                  type="hidden"
                  name={props.name + '_' + index}
                  value={option.value}
                />

                <div className="bg-blue rounded-full p-1 w-6 h-6 flex items-center justify-center">
                  <Typography
                    variant="paragraph-extrasmall"
                    className="text-white"
                  >
                    {option.value[0].toUpperCase()}
                    {option.value[1].toUpperCase()}
                  </Typography>
                </div>

                <Typography variant="label-extrasmall">
                  {option.label}
                </Typography>

                <Icon
                  name="close"
                  variant="solid"
                  className="cursor-pointer"
                  onClick={() => {
                    setAddedOptions(
                      addedOptions.filter(
                        (addedOption) => addedOption.label !== option.label
                      )
                    )
                  }}
                />
              </div>
            )) as unknown as React.ReactNode
          }

          <input
            {...props}
            ref={inputRef}
            onChange={handleChange}
            value={search}
            onFocus={
              props.disabled
                ? undefined
                : () => {
                    setBlurListener(true)
                    setIsFocused(true)
                  }
            }
            onBlur={
              props.disabled || !blurListener
                ? undefined
                : () => {
                    setIsFocused(false)
                  }
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault()
              if (e.key === 'Enter' && !filteredSuggestions?.length) {
                handleOptionClick({
                  label: search,
                  value: search,
                })
              }
            }}
            className="w-[300px] ring-0 focus:ring-0 border-none bg-transparent p-0 outline-none flex-wrap"
          />
        </div>

        {props.options && (
          <InputDropdown
            name="search-options-dropdown"
            placeholder={props.options[0].label}
            items={props.options.map((item, index) => (
              <ListItem
                key={`option_${index}`}
                title={item.label}
                value={item.value}
                style={'normal'}
                onClick={() => {}}
                className={'pl-4 pr-4'}
              />
            ))}
          />
        )}
      </div>

      <div className="">
        {search?.length && filteredSuggestions?.length && isFocused ? (
          <ul className="absolute z-10 w-full overflow-y-scroll bg-white rounded-md ring-1 ring-inset ring-[#E1E1E2] py-3 max-h-[12.5rem]">
            {filteredSuggestions?.map((option) => (
              <li
                key={option.value}
                className="px-5 py-2 hover:bg-gray-100 cursor-pointer"
                onMouseEnter={() => {
                  setBlurListener(false)
                }}
                onClick={() => {
                  handleOptionClick(option)
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        ) : null}
      </div>

      <Typography variant="paragraph-small">{props?.bottomText}</Typography>
    </div>
  )
}
