import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon.ts'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { formatNumber, formatValue } from '@/helpers/formatValue'
import { useOrg } from '@/hooks/queries/useOrg'
import { IAddressAsset } from '@/types/evm'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

export interface Props extends BaseTableProps {
  tokens: IAddressAsset[]
  onItemSelected: (balance: IAddressAsset) => void
}

export const TokensTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { org } = useOrg()
  const [_tokens, setTokens] = useState<IAddressAsset[]>([])

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setTokens([...sort(props.tokens)])
  }, [props.tokens])

  // Sorts the provided data
  const sort = (tokens: IAddressAsset[]): IAddressAsset[] => {
    return tokens.sort((a, b) => b.value - a.value)
  }

  const renderRow = (row: IAddressAsset) => {
    return (
      <Row
        key={`${row.ticker}`}
        onClick={() => {
          props.onItemSelected(row)
        }}
        className={'max-h-[3rem]'}
      >
        <Cell.Icon slim={true}>
          {/* <Avatar variant={'avatar'} type={'monogram'} value={row.ticker} /> */}
          <Avatar
            type={'user'}
            image={getCryptoImageUrl(row.ticker)}
            fallbackImage={getCryptoImageUrl()}
          />
        </Cell.Icon>
        <Cell.Text slim={true}>{row.ticker}</Cell.Text>
        <Cell.Text slim={true}>
          {capitalizeFirstLetter(row.networks[0]?.name ?? '')}
        </Cell.Text>
        <Cell.Number slim={true} align="left">
          {formatNumber(row.balance, {
            notation: 'standard',
            minimumFractionDigits: 2,
            //fewer digits for now until we can fully test our UI
            maximumFractionDigits: 12,
          })}
        </Cell.Number>
        <Cell.Number slim={true} align="right">
          {formatValue(row.value, org?.preferred_currency)}
        </Cell.Number>
      </Row>
    )
  }

  return (
    <BaseTable<IAddressAsset, null, null, null>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      headers={[
        {
          label: t('token'),
          colSpan: 2,
        },
        {
          label: t('network'),
          alignment: 'left',
        },
        {
          label: t('balance'),
          alignment: 'left',
        },
        {
          label: t('value'),
          alignment: 'right',
        },
      ]}
      data={_tokens}
      onRenderRow={renderRow}
    />
  )
}
