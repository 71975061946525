export const AssetRealityText = (): JSX.Element => {
  return (
    <svg
      width="144"
      height="24"
      viewBox="0 0 144 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.651367 18.3209L6.5068 0.779012H11.2934L17.1488 18.3209H13.222L12.0602 14.7418H5.71678L4.55499 18.3209H0.651367ZM6.71592 11.5631H11.061L8.90009 4.8054L6.71592 11.5631Z"
        fill="currentColor"
      />
      <path
        d="M23.6646 18.6034C22.2705 18.6034 21.0545 18.2738 20.0166 17.6145C18.9942 16.9395 18.2972 16.0369 17.9254 14.9067L20.4813 13.6823C20.8066 14.373 21.2481 14.9145 21.8058 15.307C22.3634 15.6994 22.9831 15.8956 23.6646 15.8956C24.687 15.8956 25.1982 15.511 25.1982 14.7418C25.1982 14.2238 24.78 13.8471 23.9435 13.6116L21.7825 12.9994C20.7447 12.7012 19.9469 12.2224 19.3892 11.5631C18.8316 10.8881 18.5528 10.0954 18.5528 9.18497C18.5528 8.3844 18.7541 7.68587 19.1569 7.08937C19.5596 6.49287 20.125 6.02979 20.8531 5.70015C21.5812 5.3548 22.4177 5.18213 23.3626 5.18213C24.6018 5.18213 25.6862 5.48038 26.6156 6.07689C27.5605 6.65769 28.2266 7.4818 28.6139 8.54923L26.0347 9.77362C25.8488 9.23991 25.508 8.81608 25.0123 8.50213C24.5321 8.17249 23.9822 8.00767 23.3626 8.00767C22.9133 8.00767 22.5571 8.10185 22.2937 8.29022C22.0459 8.47859 21.922 8.73759 21.922 9.06724C21.922 9.56956 22.3789 9.95414 23.2929 10.221L25.3144 10.8332C26.3677 11.1471 27.1733 11.6259 27.7309 12.2695C28.2886 12.8974 28.5674 13.6744 28.5674 14.6006C28.5674 15.7936 28.1182 16.759 27.2197 17.4967C26.3213 18.2345 25.1362 18.6034 23.6646 18.6034Z"
        fill="currentColor"
      />
      <path
        d="M35.6229 18.6034C34.2288 18.6034 33.0128 18.2738 31.9749 17.6145C30.9525 16.9395 30.2554 16.0369 29.8837 14.9067L32.4396 13.6823C32.7649 14.373 33.2064 14.9145 33.7641 15.307C34.3217 15.6994 34.9413 15.8956 35.6229 15.8956C36.6453 15.8956 37.1565 15.511 37.1565 14.7418C37.1565 14.2238 36.7382 13.8471 35.9018 13.6116L33.7408 12.9994C32.703 12.7012 31.9052 12.2224 31.3475 11.5631C30.7899 10.8881 30.511 10.0954 30.511 9.18497C30.511 8.3844 30.7124 7.68587 31.1152 7.08937C31.5179 6.49287 32.0833 6.02979 32.8114 5.70015C33.5394 5.3548 34.3759 5.18213 35.3209 5.18213C36.5601 5.18213 37.6444 5.48038 38.5739 6.07689C39.5188 6.65769 40.1849 7.4818 40.5722 8.54923L37.993 9.77362C37.8071 9.23991 37.4663 8.81608 36.9706 8.50213C36.4904 8.17249 35.9405 8.00767 35.3209 8.00767C34.8716 8.00767 34.5154 8.10185 34.252 8.29022C34.0042 8.47859 33.8802 8.73759 33.8802 9.06724C33.8802 9.56956 34.3372 9.95414 35.2512 10.221L37.2727 10.8332C38.326 11.1471 39.1315 11.6259 39.6892 12.2695C40.2469 12.8974 40.5257 13.6744 40.5257 14.6006C40.5257 15.7936 40.0765 16.759 39.178 17.4967C38.2796 18.2345 37.0945 18.6034 35.6229 18.6034Z"
        fill="currentColor"
      />
      <path
        d="M48.7198 18.6034C47.3721 18.6034 46.2025 18.3052 45.2112 17.7087C44.2198 17.0965 43.453 16.2802 42.9108 15.2599C42.3686 14.2395 42.0976 13.1093 42.0976 11.8692C42.0976 10.582 42.3764 9.43613 42.934 8.4315C43.5072 7.42686 44.274 6.63414 45.2344 6.05334C46.1948 5.47254 47.2791 5.18213 48.4874 5.18213C49.8351 5.18213 50.9736 5.46469 51.9031 6.02979C52.8325 6.5949 53.5373 7.35622 54.0175 8.31377C54.5132 9.25561 54.7611 10.323 54.7611 11.516C54.7611 11.7672 54.7456 12.0184 54.7146 12.2695C54.6991 12.505 54.6604 12.709 54.5984 12.8817H45.7223C45.8153 13.6823 46.1328 14.3259 46.675 14.8125C47.2172 15.2991 47.9143 15.5424 48.7662 15.5424C49.3859 15.5424 49.9125 15.4168 50.3463 15.1657C50.7955 14.9145 51.144 14.5692 51.3919 14.1297L54.1802 15.4718C53.7929 16.4136 53.1113 17.1749 52.1354 17.7558C51.1595 18.3209 50.021 18.6034 48.7198 18.6034ZM48.4874 8.00767C47.8058 8.00767 47.2249 8.20388 46.7447 8.59632C46.28 8.98875 45.9624 9.55386 45.792 10.2916H51.0666C50.9891 9.61665 50.718 9.06724 50.2533 8.64341C49.7886 8.21958 49.2 8.00767 48.4874 8.00767Z"
        fill="currentColor"
      />
      <path
        d="M62.9245 18.4621C61.3909 18.4621 60.1981 18.0462 59.3462 17.2142C58.5097 16.3665 58.0914 15.1892 58.0914 13.6823V8.52568H55.9537V5.46469H56.0699C57.4176 5.46469 58.0914 4.8054 58.0914 3.48681V2.54497H61.5768V5.46469H64.551V8.52568H61.5768V13.4468C61.5768 14.7497 62.2971 15.4011 63.7377 15.4011C63.9546 15.4011 64.2257 15.3776 64.551 15.3305V18.3209C64.3186 18.3523 64.0553 18.3836 63.761 18.415C63.4666 18.4464 63.1878 18.4621 62.9245 18.4621Z"
        fill="currentColor"
      />
      <path
        d="M66.849 18.3209V0.779012H73.4712C74.664 0.779012 75.7173 0.990927 76.6313 1.41476C77.5607 1.83859 78.2887 2.46648 78.8154 3.29845C79.3421 4.13041 79.6054 5.15859 79.6054 6.38299C79.6054 7.57599 79.3344 8.59632 78.7922 9.44398C78.25 10.2759 77.522 10.9038 76.608 11.3277L80.5581 18.3209H76.5151L73.0065 11.9634H70.4505V18.3209H66.849ZM70.4505 8.78469H73.4944C74.2535 8.78469 74.8576 8.56492 75.3068 8.1254C75.7715 7.68587 76.0039 7.10507 76.0039 6.38299C76.0039 5.64521 75.7715 5.05655 75.3068 4.61703C74.8576 4.1775 74.2535 3.95774 73.4944 3.95774H70.4505V8.78469Z"
        fill="currentColor"
      />
      <path
        d="M87.4991 18.6034C86.1514 18.6034 84.9819 18.3052 83.9905 17.7087C82.9991 17.0965 82.2323 16.2802 81.6901 15.2599C81.148 14.2395 80.8769 13.1093 80.8769 11.8692C80.8769 10.582 81.1557 9.43613 81.7134 8.4315C82.2865 7.42686 83.0533 6.63414 84.0137 6.05334C84.9741 5.47254 86.0585 5.18213 87.2667 5.18213C88.6144 5.18213 89.753 5.46469 90.6824 6.02979C91.6118 6.5949 92.3167 7.35622 92.7969 8.31377C93.2926 9.25561 93.5404 10.323 93.5404 11.516C93.5404 11.7672 93.5249 12.0184 93.4939 12.2695C93.4784 12.505 93.4397 12.709 93.3778 12.8817H84.5017C84.5946 13.6823 84.9122 14.3259 85.4543 14.8125C85.9965 15.2991 86.6936 15.5424 87.5456 15.5424C88.1652 15.5424 88.6919 15.4168 89.1256 15.1657C89.5748 14.9145 89.9234 14.5692 90.1712 14.1297L92.9595 15.4718C92.5722 16.4136 91.8907 17.1749 90.9148 17.7558C89.9389 18.3209 88.8003 18.6034 87.4991 18.6034ZM87.2667 8.00767C86.5852 8.00767 86.0042 8.20388 85.524 8.59632C85.0593 8.98875 84.7418 9.55386 84.5714 10.2916H89.8459C89.7685 9.61665 89.4974 9.06724 89.0327 8.64341C88.5679 8.21958 87.9793 8.00767 87.2667 8.00767Z"
        fill="currentColor"
      />
      <path
        d="M99.5196 18.6034C98.1565 18.6034 97.0721 18.2738 96.2666 17.6145C95.4766 16.9552 95.0816 16.0447 95.0816 14.8831C95.0816 13.8 95.4534 12.9053 96.1969 12.1989C96.9405 11.4925 98.0713 11.0137 99.5893 10.7626L103.098 10.1975V9.84426C103.098 9.35764 102.904 8.94951 102.517 8.61986C102.145 8.29022 101.634 8.1254 100.983 8.1254C100.364 8.1254 99.814 8.29807 99.3338 8.64341C98.869 8.98875 98.5282 9.44398 98.3114 10.0091L95.5231 8.66696C95.8949 7.59953 96.5842 6.75187 97.5911 6.12398C98.6134 5.49608 99.7985 5.18213 101.146 5.18213C102.215 5.18213 103.16 5.37835 103.981 5.77079C104.802 6.16322 105.437 6.71263 105.886 7.41901C106.351 8.1097 106.583 8.91811 106.583 9.84426V18.3209H103.33V17.12C102.37 18.1089 101.1 18.6034 99.5196 18.6034ZM98.6832 14.7654C98.6832 15.1578 98.8303 15.4639 99.1246 15.6837C99.419 15.9035 99.783 16.0133 100.217 16.0133C101.084 16.0133 101.781 15.7386 102.308 15.1892C102.835 14.6398 103.098 13.9727 103.098 13.1878V12.7875L100.217 13.3055C99.721 13.3997 99.3415 13.5645 99.0782 13.8C98.8148 14.0198 98.6832 14.3416 98.6832 14.7654Z"
        fill="currentColor"
      />
      <path
        d="M109.139 18.3209V0.49646H112.625V18.3209H109.139Z"
        fill="currentColor"
      />
      <path
        d="M115.175 4.31093V0.779012H118.661V4.31093H115.175ZM115.175 18.3209V5.46469H118.661V18.3209H115.175Z"
        fill="currentColor"
      />
      <path
        d="M127.322 18.4621C125.789 18.4621 124.596 18.0462 123.744 17.2142C122.907 16.3665 122.489 15.1892 122.489 13.6823V8.52568H120.351V5.46469H120.468C121.815 5.46469 122.489 4.8054 122.489 3.48681V2.54497H125.975V5.46469H128.949V8.52568H125.975V13.4468C125.975 14.7497 126.695 15.4011 128.135 15.4011C128.352 15.4011 128.623 15.3776 128.949 15.3305V18.3209C128.716 18.3523 128.453 18.3836 128.159 18.415C127.864 18.4464 127.586 18.4621 127.322 18.4621Z"
        fill="currentColor"
      />
      <path
        d="M132.966 23.501C132.594 23.501 132.23 23.4696 131.874 23.4068C131.533 23.344 131.231 23.242 130.968 23.1007V20.2045C131.464 20.3615 131.99 20.44 132.548 20.44C133.23 20.44 133.717 20.2752 134.012 19.9455C134.306 19.6316 134.546 19.2706 134.732 18.8624L134.964 18.3209L129.969 5.46469H133.71L136.707 13.7294L139.705 5.46469H143.446L138.008 19.5217C136.97 22.1746 135.29 23.501 132.966 23.501Z"
        fill="currentColor"
      />
    </svg>
  )
}
