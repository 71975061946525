import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IFile } from '@/types/file'

import FilesActionsView from './views/files-actions.view'

interface Props extends ControllerProps<unknown> {
  file: IFile
  onDeleteFile: (file: IFile) => Promise<void>
}
const FileActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'DEFENDANT_ACTIONS'}
      closeOnItemClick={true}
    >
      <FilesActionsView file={props.file} onDeleteFile={props.onDeleteFile} />
    </DropdownController>
  )
}
export default FileActionsController
