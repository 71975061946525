import { useTranslation } from 'react-i18next'

import Button from '@/components/button'
import Dropdown from '@/components/dropdown'
import BottomPadding from '@/components/footer/BottomPadding.tsx'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { Table } from '@/components/table'
import { useDrawer, useDropdown, useModal } from '@/contexts/interface'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'

export const TeamManagement: React.FC = (): JSX.Element => {
  const { members } = useWorkspaceMembers()
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const { setModal } = useModal()
  const { setDropdown } = useDropdown()

  const btnCreateNewIcon = 'plus'
  const btnCreateNewTitle = t('create-new-user')
  const btnCreateNewHandler = () => {
    setDrawer({
      id: 'NEW_TEAM_USER',
    })
  }
  const btnAddExistingIcon = 'user'
  const btnAddExistingTitle = t('add_existing_user')
  const btnAddExistingOnClick = () => {
    setModal({
      id: 'WORKSPACE_INVITE',
    })
  }
  const showMobileAddActions = () => {
    setDropdown({
      target: `fab-button`,
      maxWidthPx: 300,
      controller: (
        <Dropdown.Controllers.BasicList
          closeOnItemClick={true}
          items={[
            <ListItem
              key={'btn_create_new'}
              title={btnCreateNewTitle}
              leading={
                <Icon
                  name={btnCreateNewIcon}
                  family={'sharp'}
                  variant={'solid'}
                />
              }
              onClick={btnCreateNewHandler}
              className={'pl-2 pr-2'}
            />,
            <ListItem
              key={'btn_add_existing'}
              title={btnAddExistingTitle}
              leading={
                <Icon
                  name={btnAddExistingIcon}
                  family={'sharp'}
                  variant={'solid'}
                />
              }
              onClick={btnAddExistingOnClick}
              className={'pl-2 pr-2'}
            />,
          ]}
        />
      ),
    })
  }

  return (
    <>
      <div className="flex flex-col p-8 pt-4 desktop:px-8 desktop:py-4">
        <Paragraph
          title={t('team_management')}
          description={t('team_management_description')}
          spacerOverrides={{
            description: [],
          }}
        />

        <div className="flex gap-5 py-8 hidden tablet:flex">
          <Button.Basic
            label={btnCreateNewTitle}
            testId={'btn_show_new_user_drawer'}
            icon={{
              name: btnCreateNewIcon,
            }}
            onClick={btnCreateNewHandler}
          />

          <Button.Basic
            testId={'btn_show_existing_user_drawer'}
            hierarchy="secondary"
            label={btnAddExistingTitle}
            icon={{
              name: btnAddExistingIcon,
            }}
            onClick={btnAddExistingOnClick}
          />
        </div>

        <Paragraph
          classOverrides={{
            title: 'mb-8 tablet:mb-0',
          }}
          title={t('people')}
          spacerOverrides={{
            title: ['top'],
          }}
        />

        <Table workspaceMembers={members} />

        <BottomPadding />
        <Button.Fab
          label={t('add_user')}
          icon={{
            name: 'plus',
          }}
          onClick={showMobileAddActions}
        />
      </div>
    </>
  )
}
