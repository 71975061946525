import { MouseEvent, ReactElement, ReactNode, useEffect, useRef } from 'react'
import { Form as RRDomForm, useFetcher } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useFormErrors } from '@/contexts/formErrors/useFormErrors'
import { ActionResponse, IsActionResponse } from '@/types/actions'

import Button from '../button'
import { Props as ButtonBasicProps } from '../button/variants/basic.button'
import { Paragraph } from '../paragraph'
import { ErrorBottomBanner } from './error-section'

export interface Props<T, I> {
  title?: string
  subTitle?: string
  description?: string
  type?:
    | 'billing'
    | 'change_password'
    | 'contact'
    | 'forgot_password'
    | 'identity'
    | 'login'
    | 'newsletter'
    | 'other'
    | 'payment'
    | 'register'
    | 'search'
    | 'shopping_basket'
    | 'shipping'
  children: ReactNode
  buttons: ReactElement<ButtonBasicProps>[]
  buttonClassName?: string
  testId?: string
  hideErrorSummary?: boolean
  onSubmit?: (intent: I) => void
  onSuccess?: (response: ActionResponse<T>) => void
  onFailed?: (response: ActionResponse<T>) => void
  onException?: (response: ActionResponse<T>) => void
  onComplete?: () => void
  onUnknownResponse?: (response: ActionResponse<T>) => void
  onFetchDataChange?: (data: unknown) => void
  className?: string
  containerClassName?: string
  footer?: ReactNode
}
export const Form = <T, I>(props: Props<T, I>) => {
  const fetcher = useFetcher()
  const { errors } = useFormErrors()
  const _form = useRef<HTMLFormElement>(null)
  const _formValueContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Fire off the data change if we need to
    props.onFetchDataChange && props.onFetchDataChange(fetcher.data)

    // Ignore anything where the data is undefined
    if (fetcher.data) {
      if (IsActionResponse<T>(fetcher.data)) {
        // Otherwise we have an action response base which will hold some error data
        if (fetcher.data.exception === true) {
          // The call has triggered an exception - we'll forward this response
          // back to the form owner but if we're in a modal this should be closed
          props.onException && props.onException(fetcher.data)
        } else if (fetcher.data.error === true) {
          // The call has errored but its not a big issue - this is something we
          // simply need to display to the user; something like a form error
          // *******
          // FORM ERRORS SHOULD BE AUTO DISPLAYED THROUGH THE USERFORMERRORS
          // HOOK SO NOTHING REALLY NEEDS TO BE DONE FROM THIS CALLBACK :)
          props.onFailed && props.onFailed(fetcher.data)
        } else {
          // Otherwist the call must have succeeded! but without an api response
          // thats OK - not an issue.
          props.onSuccess && props.onSuccess(fetcher.data)
        }
      } else {
        // This is a response we're not handling but we may want to listen for
        // this as it indicates a response that ISNT success or failure
        props.onUnknownResponse && props.onUnknownResponse(fetcher.data)
      }

      // Throw the oncomplete callback - this will signify that
      // that submit has finished
      props.onComplete && props.onComplete()
    }
  }, [fetcher.data])

  const scrollToInput = (inputName: string) => {
    const inputElement = document.querySelector(
      `input[name="${inputName}"]`
    ) as HTMLInputElement | null
    if (inputElement) {
      if (isElementHidden(inputElement)) {
        // If the input is hidden then lets find its parent and attempt to scroll
        // to that instead - if we can't then we'll just fail out
        const parentElement = inputElement.parentElement
        if (parentElement && !isElementHidden(parentElement)) {
          parentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          })
        }
      } else {
        // Scroll to the input
        inputElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
      }
    }
  }

  // Checks to see if a element is hidden
  const isElementHidden = (element: HTMLElement) => {
    return (
      element.offsetParent === null ||
      getComputedStyle(element).display === 'none'
    )
  }

  // We override the button click action - if we can find an intent on the button
  // then we'll use that, add it to the form and then submit. If we can't find
  // an intent then we'll assume the button is there for some other reason and
  // simply pass the click event through to it.
  //
  // In order to submit the form the button MUST have an intent in order for us to establish
  // what action it is intended to carry out.
  //
  // Buttons with onclick AND intent will have its onclick ignored.
  const onButtonClick = (
    e: MouseEvent<HTMLButtonElement>,
    btnOnClick?: (p: MouseEvent<HTMLButtonElement>) => void
  ) => {
    // Attempt to find the intent details
    const { name, value } = e.currentTarget

    // Check to see if we've found an intent
    if (name === 'intent') {
      // First lets throw the onSubmit callback if the name/value is
      // an intent
      props.onSubmit && props.onSubmit(value as I)

      // // Get form values based on formName
      if (_form.current) {
        // Clone the current form
        const fd = new FormData(_form.current)

        // Add in our intent
        fd.set('intent', value)

        // Submit the form
        fetcher.submit(fd, {
          method: 'POST',
          encType: 'multipart/form-data',
        })
      }
    } else if (btnOnClick) {
      btnOnClick(e)
    }
  }

  return (
    <RRDomForm
      ref={_form}
      className={twMerge('flex flex-col w-full gap-[24px]', props.className)}
      data-testid={props.testId}
      data-form-type={props.type ?? 'other'}
      autoComplete={'false'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      }}
      onSubmit={(e) => {
        // We manually submit the form so we need to prevent the
        // default submission of the form
        e.preventDefault()
      }}
    >
      {(props.title || props.description) && (
        <Paragraph
          title={props.title}
          subTitle={props.subTitle}
          description={props.description}
          spacerOverrides={{
            description: [],
          }}
        />
      )}

      <div
        ref={_formValueContainer}
        className={twMerge(
          'flex flex-col w-full gap-[24px]',
          props.containerClassName
        )}
      >
        {props.children}
      </div>
      <div className={'flex flex-col w-full gap-[16px]'}>
        {!props.hideErrorSummary !== false && errors && errors.length > 0 && (
          <ErrorBottomBanner errors={errors} scrollToInput={scrollToInput} />
        )}
        <Button.Dock className={props.buttonClassName}>
          {props.buttons.map((b, i) => (
            <Button.Basic
              key={`submit_btn_${i}`}
              {...b.props}
              onClick={(e) => {
                onButtonClick(e, b.props.onClick)
              }}
            />
          ))}
        </Button.Dock>
      </div>
      {props.footer && props.footer}
    </RRDomForm>
  )
}
