import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export const loader: LoaderFunction = async () => {
  try {
    return {}
  } catch (error) {
    const message = ExtractErrorFrom(error) ?? t('something_went_wrong')

    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
