import { twMerge } from 'tailwind-merge'

import { Typography } from '@/components/typography'

export type Size = 'large' | 'medium' | 'small'
export interface Props {
  title?: string
  description?: string
  trailingLabel?: string
  size?: Size
  style?: 'bold' | 'normal'
  state?: 'default' | 'disabled'
  textAlignment?: 'left' | 'right'
  withClass?: string
}
export const ListItemLabel = (props: Props) => {
  props = {
    size: 'medium',
    style: 'normal',
    ...props,
  }

  return (
    <div
      className={twMerge(
        'flex flex-1 flex-row items-center gap-4 overflow-hidden',
        props.size === 'large'
          ? 'h-16'
          : props.size === 'medium'
            ? 'h-12'
            : 'h-10',
        props.withClass
      )}
    >
      <div
        className={twMerge(
          'flex flex-1 flex-col justify-center items-start self-stretch overflow-hidden',
          props.withClass
        )}
      >
        {props.title !== undefined && (
          <Typography
            variant={
              props.size === 'large'
                ? // Large
                  props.style === 'bold'
                  ? 'label-large'
                  : 'paragraph-large'
                : // Medium
                  props.size === 'medium'
                  ? props.style === 'bold'
                    ? 'label-medium'
                    : 'paragraph-medium'
                  : // Small
                    props.style === 'bold'
                    ? 'label-small'
                    : 'paragraph-small'
            }
            className={twMerge(
              'whitespace-nowrap text-ellipsis overflow-hidden w-full text-left',
              'text-[#212427]',
              props.state === 'disabled' && 'text-[#909293]',
              props.textAlignment === 'right' && 'text-right',
              props.description !== undefined && 'mb-[-0.125rem]'
            )}
          >
            {props.title}
          </Typography>
        )}
        {props.description !== undefined && (
          <Typography
            variant={
              props.size === 'large'
                ? 'paragraph-small'
                : 'paragraph-extrasmall'
            }
            className={twMerge(
              'whitespace-nowrap text-ellipsis overflow-hidden w-full text-left',
              'text-[#646668]',
              props.state === 'disabled' && 'text-[#909293]',
              props.textAlignment === 'right' && 'text-right',
              props.withClass
            )}
          >
            {props.description}
          </Typography>
        )}
      </div>
      {props.trailingLabel !== undefined && (
        <div>
          <Typography
            variant={
              props.size === 'large'
                ? 'paragraph-large'
                : props.size === 'medium'
                  ? 'paragraph-medium'
                  : 'paragraph-small'
            }
            className={twMerge(
              'whitespace-nowrap text-ellipsis overflow-hidden w-full text-left'
            )}
          >
            {props.trailingLabel}
          </Typography>
        </div>
      )}
    </div>
  )
}
