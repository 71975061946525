import { t } from 'i18next'

import { ActionResponse } from '@/types/actions'

import { FormError } from './types'

interface IFormErrorsManager {
  addErrorHook: React.Dispatch<FormError[]> | null
  clearErrorsHook: React.Dispatch<void> | null
  setAddErrorsFunction: (fn: React.Dispatch<FormError[]>) => void
  setClearErrorsFunction: (fn: React.Dispatch<void>) => void

  addErrors: <T>(errors: FormError[]) => ActionResponse<T>
  clearErrors: () => void
}

export const FormErrorsManager: IFormErrorsManager = {
  addErrorHook: null,
  clearErrorsHook: null,

  setAddErrorsFunction(fn) {
    this.addErrorHook = fn
  },
  setClearErrorsFunction(fn) {
    this.clearErrorsHook = fn
  },

  addErrors(errors: FormError[]) {
    if (this.addErrorHook) {
      this.addErrorHook(errors)
    }

    // throw back an error response
    return {
      completion: 'NOT_COMPLETE',
      error: true,
      exception: false,
      message:
        !Array.isArray(errors) || errors.length <= 0
          ? t('something_went_wrong')
          : errors.length === 1
            ? errors[0].error
            : `${errors.length} fields have errored`,
    }
  },
  clearErrors() {
    if (this.clearErrorsHook) {
      this.clearErrorsHook()
    }
  },
}
