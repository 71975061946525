import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { Props as ButtonProps } from '@/components/button'

export interface Props {
  children: ReactElement<ButtonProps> | ReactElement<ButtonProps>[]
  className?: string
}
export const ButtonDock = (props: Props) => {
  return (
    <div className={'flex flex-col w-full'}>
      {/*
                    Todo - add in list item checkbox
                */}
      <div
        className={twMerge(
          'flex flex-row gap-[24px] self-stretch flex-wrap',
          props.className
        )}
      >
        {props.children}
      </div>
    </div>
  )
}
