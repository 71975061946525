import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  items: JSX.Element[]
  bufferCount: number // Optional buffer to preload more items
  className?: string
}

/**
 *
 * List component for displaying a large quantity of data but having it load
 * in pages rather than all at once.
 *
 */
const List = (props: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [_visibleCount, setVisibleCount] = useState(props.bufferCount)
  const [_renderedItems, setRenderedItems] = useState<JSX.Element[]>([])
  const [_isAtBottom, setIsAtBottom] = useState(false)
  const [_isAtTop, setIsAtTop] = useState(true)

  const loadMoreItems = useCallback(() => {
    setVisibleCount((prevCount) =>
      Math.min(prevCount + props.bufferCount, props.items.length)
    )
  }, [props.bufferCount, props.items.length])

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      const bottomOffset = scrollHeight - (scrollTop + clientHeight)

      // Load more items when within 500px of the bottom
      if (bottomOffset < 500) {
        loadMoreItems()
      }

      // Show/Hide the top/bottom gradients depending on where the scroll is.
      setIsAtBottom(bottomOffset < 20)
      setIsAtTop(scrollTop < 20)
    }
  }, [loadMoreItems])

  useEffect(() => {
    setRenderedItems(props.items.slice(0, _visibleCount))
  }, [_visibleCount, props.items])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <div className={'h-full relative'}>
      <div
        className={twMerge(
          'bg-gradient-to-b from-[#FFFFFFDD] to-transparent',
          'absolute top-0 h-[30px] pointer-events-none',
          _isAtTop ? 'animate-fade-out-up' : 'animate-fade-in-down'
        )}
        style={{ width: containerRef.current?.clientWidth ?? '100%' }}
      />
      <div
        ref={containerRef}
        className={twMerge('overflow-y-auto h-full', props.className)}
      >
        {_renderedItems.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
      <div
        className={twMerge(
          'bg-gradient-to-t from-[#FFFFFFDD] to-transparent',
          'absolute bottom-0 h-[30px] pointer-events-none',
          _isAtBottom ? 'animate-fade-out-down' : 'animate-fade-in-up'
        )}
        style={{ width: containerRef.current?.clientWidth ?? '100%' }}
      />
    </div>
  )
}

export default List
