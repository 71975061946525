import { useEffect } from 'react'

import useStorage from '@/hooks/useStorage'

import { ThemeContext } from './context'
import { ThemeManager } from './manager'
import { IThemeContext } from './types'

export type ThemeProviderProps = {
  children: JSX.Element[] | JSX.Element
}

export const ThemeProvider: React.FC<ThemeProviderProps> = (
  props
): JSX.Element => {
  const [theme, setTheme] = useStorage('@theme', 'light') as IThemeContext

  useEffect(() => {
    document.body.classList.remove('light', 'dark')
    document.body.classList.add(theme)
  }, [theme])

  useEffect(() => {
    ThemeManager.setThemeFunction(setTheme)
  }, [setTheme])

  return (
    <ThemeContext.Provider value={[theme, setTheme]} {...props}>
      {props.children}
    </ThemeContext.Provider>
  )
}
