import { t } from 'i18next'
import {
  memo,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'

import { NavigationAction, ViewId } from '../types'
import {
  DropdownList,
  Props as DropdownListProps,
  RowTypes,
} from './list.dropdown'

export interface Props<T> {
  id?: ViewId
  items?: ReactElement<RowTypes<T>>[]
  element?: ReactElement<unknown>
  gap?: DropdownListProps<T>['gap']
  onItemClicked?: (props: RowTypes<T>) => void
  heightClassName?: string
  fixedHeader?: ReactNode
  fixedFooter?: ReactNode

  // These properties are overwritten when the controller
  // renders the view - they are here to allow the view to
  // navigate around within the controller - note that this will
  // only work if the controller has been provided the correct
  // child views
  testId?: string
  dropdownId?: string
  goBack?: () => void
  goToView?: (id: ViewId) => void
  close?: () => void

  viewSizeChanged?: (height: number) => void

  action?: NavigationAction
}

export const DropdownView = memo(<T,>(props: Props<T>) => {
  const [_id] = useState<string>(
    `${Math.floor(new Date().getTime() * Math.random())}`
  )
  const [_transitioning, setTransitioning] = useState<boolean>(false)
  const [_lastAction, setLastAction] = useState<NavigationAction | undefined>()

  /*
    We want to listen for action changes but we only really care
    about actions that are associated to this view so if we get
    anything that isn't todo with us then we'll keep the lastaction
    as undefined
  */
  useEffect(() => {
    if (props.action?.from === props.id || props.action?.to === props.id) {
      setTransitioning(true)
      setLastAction(props.action)
      setTimeout(() => {
        setTransitioning(false)
      }, 800)
    } else {
      setLastAction(undefined)
    }
  }, [props.action, props.id])

  const onHeightChanged = (height: number) => {
    if (props.action?.to === props.id) {
      props.viewSizeChanged?.(height > 0 ? height + 2 : 0)
    }
  }

  const className = useMemo(() => {
    return twMerge(
      'absolute shadow-none bg-white',
      'opacity-0',
      _lastAction?.to === props.id ? 'z-[1]' : 'z-[0]',
      _lastAction === undefined
        ? 'opacity-0'
        : _lastAction?.to === props.id &&
            _lastAction.from === undefined &&
            _lastAction.direction === 'FORWARD'
          ? 'opacity-100'
          : _lastAction?.to === props.id && _lastAction?.direction === 'FORWARD'
            ? 'animate-fade-in-left'
            : _lastAction?.to === props.id && _lastAction?.direction === 'BACK'
              ? 'animate-fade-in-right'
              : _lastAction?.from === props.id &&
                  _lastAction?.direction === 'FORWARD'
                ? 'animate-fade-out-left'
                : 'animate-fade-out-right'
    )
  }, [_lastAction, props.id])

  return (
    <DropdownList
      testId={props.testId}
      dropdownId={props.dropdownId}
      key={`dropdown_view_${_id}_${props.id}`}
      innerId={`dropdown_view_${_id}_${props.id}`}
      gap={props.gap}
      onItemClicked={props.onItemClicked}
      fixedHeader={props.fixedHeader}
      fixedFooter={props.fixedFooter}
      hideOverflow={_transitioning}
      onHeightChanged={onHeightChanged}
      className={className}
    >
      {[
        ...(props.action?.from !== undefined &&
        ((props.action?.to === props.id &&
          props.action?.direction === 'FORWARD') ||
          (props.action?.from === props.id &&
            props.action.direction === 'BACK'))
          ? [
              <ListItem
                key={'li_goback'}
                title={t('back')}
                style={'bold'}
                leading={
                  <Icon
                    name={'arrow-left'}
                    size={'medium'}
                    family={'sharp'}
                    variant={'solid'}
                  />
                }
                onClick={() => {
                  props.goBack?.()
                }}
                className={'pl-2 pr-2'}
              />,
            ]
          : []),
        ...(props.items ?? []),
      ]}
    </DropdownList>
  )
})
DropdownView.displayName = 'DropdownView'
