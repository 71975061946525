import { t } from 'i18next'
import { ChangeEvent, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { createDefendant } from '@/api/broker/v2/defendants'
import { useDefendants } from '@/hooks/queries/useDefendants'

import Button from '../button'
import { Icon } from '../icon'
import Input from '../input'
import { Tabs } from '../tabs'

type mode = 'ADD_EXISTING' | 'CREATE_NEW'

interface Props {
  name: string
  label?: string
  defaultMode?: mode
  defaultDefendantId?: string
  onSelectedIdChange?: (id: string) => void
  readonly?: boolean
}

export const AssetDefendants = (props: Props) => {
  const [_creatingDefendant, setCreatingDefendant] = useState<boolean>(false)
  const [_selectedDefendantId, setSelectedDefendantId] = useState<string>(
    props.defaultDefendantId ?? ''
  )
  const { refetchDefendants } = useDefendants()
  const [_mode, setMode] = useState<mode>(
    (props.defaultMode ?? props.defaultDefendantId)
      ? 'ADD_EXISTING'
      : 'CREATE_NEW'
  )
  const newDefendantName = useRef<string>('')
  const [_createDefendantError, setCreateDefendantError] = useState<string>('')

  const modeChange = (id: mode) => {
    setMode(id)
  }

  const onNewDefendantValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    newDefendantName.current = e.target.value
  }

  const onCreateNew = async () => {
    setCreatingDefendant(true)
    const defendantName = newDefendantName.current

    // Check the name is OK
    if (!defendantName || defendantName.length <= 0) {
      setCreateDefendantError(t('invalid_name_provided'))
    }

    // Create the new Defendant
    const res = await createDefendant({
      name: defendantName?.trim(),
    })

    // Check if the subject was created or not
    if (res.error ?? !res.data) {
      // Set the error
      setCreateDefendantError(t('failed_to_create_subject'))

      // Clear the creating flag
      setCreatingDefendant(false)
    } else {
      // Trigger a refetch of the subject
      await refetchDefendants()

      // Grab out the new subject id
      const newDefendantId = res.data
      setSelectedDefendantId(newDefendantId)

      // Wait a delay to ensure the new subjects make it into
      // the other components
      setTimeout(() => {
        // Move over to 'existing'
        setMode('ADD_EXISTING')

        // Clear the creating flag
        setCreatingDefendant(false)
      }, 2000)
    }
  }

  return (
    <div
      className={'flex flex-col w-full items-start gap-2'}
      data-testid="defendantWrapper"
    >
      {props.label && <Input.Component.Label text={props.label} />}
      <div
        className={twMerge(
          'flex flex-col w-full max-w-[23.4375rem]',
          'items-start gap-4 rounded-[0.75rem]'
        )}
      >
        <input type={'hidden'} name={props.name} value={_selectedDefendantId} />
        <Tabs<mode>
          type={'button'}
          size={'small'}
          values={[
            {
              id: 'ADD_EXISTING',
              text: t('existing'),
            },
            {
              id: 'CREATE_NEW',
              text: t('create_new'),
            },
          ]}
          onSelectionChanged={modeChange}
          selectedId={_mode}
          className={'w-full tablet:w-full'}
        />

        <Input.Defendant
          testId={'asset_defendants'}
          defaultValue={_selectedDefendantId}
          className={_mode === 'CREATE_NEW' ? 'hidden' : ''}
          onSelectedIdChange={(id) => {
            setSelectedDefendantId(id)
            if (props.onSelectedIdChange) {
              props.onSelectedIdChange(id)
            }
          }}
        />

        {_mode === 'CREATE_NEW' && (
          <>
            <Input.Text
              icon={
                <Icon
                  name={'user-tie-hair'}
                  variant={'solid'}
                  family={'sharp'}
                  size={'medium'}
                />
              }
              placeholder={'e.g. Kevin McCallister'}
              hint={
                _createDefendantError ? (
                  <Input.Component.Hint
                    style={'negative'}
                    label={_createDefendantError}
                  />
                ) : (
                  <Input.Component.Hint
                    style={'hint'}
                    label={t('subject_hint')}
                  />
                )
              }
              onChange={onNewDefendantValueChanged}
            />
            <Button.Basic
              size={'medium'}
              hierarchy={'primary'}
              state={_creatingDefendant ? 'loading' : 'default'}
              label={t('save')}
              onClick={onCreateNew}
            />
          </>
        )}
      </div>
    </div>
  )
}
