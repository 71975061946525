import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { getStorage, updateStorage } from '@/api/broker/v2/storage'
import { useAuth } from '@/contexts/auth'

export const useAgreed = () => {
  const { user } = useAuth()
  const userId = user?.id
  const { data } = useQuery({
    queryKey: [userId, 'agreed'],
    queryFn: () =>
      getStorage(userId!, 'agreed')
        .then((response) => response.data?.Value.agreed)
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            return null
          }
          throw error
        }),
    enabled: !!userId,
  })
  return data
}

export const useUpdateAgreed = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const userId = user?.id
  const navigate = useNavigate()
  const { mutate } = useMutation({
    mutationFn: userId
      ? (agreed: number) => updateStorage(userId, 'agreed', { agreed })
      : undefined,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [userId, 'agreed'] })
      navigate('/')
    },
  })
  return mutate
}
