import { useQuery } from '@tanstack/react-query'

import { getEscrows } from '@/api/escrows/v1'

export const key = 'escrows'
export const useEscrows = () => {
  const { isLoading, data, isRefetching, refetch } = useQuery({
    queryKey: [key],
    queryFn: async () => (await getEscrows()).data ?? [],
  })

  return { escrows: data, isLoading, isRefetching, refetch }
}
