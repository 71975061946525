import { ReactElement, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useFormErrors } from '@/contexts/formErrors/useFormErrors'

import { InputElement, Props as InputElementProps } from './element.input'
import { InputHint, Props as InputHintProps } from './hint.input'
import { InputLabel, Props as InputLabelProps } from './label.input'

export interface Props {
  size?: 'large' | 'medium'
  label?: ReactElement<InputLabelProps>
  element: ReactElement<InputElementProps> | ReactElement<InputElementProps>[]
  hint?: ReactElement<InputHintProps>
  inputName?: string
  className?: string
  elementContainerClassName?: string
  testId?: string
  dynamicInput?: boolean
}

export const InputField = (props: Props) => {
  const { errors, clearError } = useFormErrors()
  const [_error, setError] = useState<string>('')
  const [_inputName] = useState<string>(
    props.inputName
      ? props.inputName
      : Array.isArray(props.element)
        ? (props.element[0].props?.input?.props?.name ?? '')
        : (props.element?.props?.input?.props?.name ?? '')
  )

  /*
    The input will automatically listen for any form errors that
    associate with itself - if it finds one then it'll display
    an error and stick itself into an error state
  */
  useEffect(() => {
    const foundError = errors.find(
      (e) =>
        e.fieldName &&
        e.fieldName.trim() !== '' &&
        _inputName.trim() !== '' &&
        e.fieldName === _inputName
    )
    if (foundError) {
      setError(foundError.error)
    } else {
      setError('')
    }
  }, [errors])

  const onValueChanged = () => {
    clearError(_inputName)
  }

  return (
    <div
      className={twMerge(
        'flex flex-col w-full gap-2',
        props.dynamicInput ? 'max-w-full' : 'max-w-[23.4375rem]',
        props.className
      )}
      data-testid={props.testId}
    >
      {props.label && <InputLabel size={props.size} {...props.label.props} />}
      <div
        className={twMerge(
          'flex flex-col',
          props.dynamicInput
            ? 'w-fit max-w-full xs:min-w-[23.4375rem]'
            : 'w-full',
          props.elementContainerClassName
        )}
      >
        {Array.isArray(props.element) ? (
          props.element.map((e, i) => (
            <InputElement
              key={`input_el_${i}`}
              size={props.size}
              {...e.props}
              onFocus={onValueChanged}
              onValueChanged={onValueChanged}
              state={_error ? 'error' : (e.props.state ?? 'default')}
            />
          ))
        ) : (
          <InputElement
            size={props.size}
            {...props.element.props}
            onFocus={onValueChanged}
            onValueChanged={onValueChanged}
            state={_error ? 'error' : (props.element.props.state ?? 'default')}
          />
        )}
      </div>
      {_error ? (
        <InputHint style={'negative'} label={_error} />
      ) : (
        props.hint && <InputHint {...props.hint.props} />
      )}
    </div>
  )
}
