import { t } from 'i18next'

import {
  AssetType,
  EstimatedAssetBalanceType,
  NumberOfLicensesType,
  RequirementsType,
  SectorType,
} from './types'

export const AssetTypes: readonly AssetType[] = [
  {
    label: t('physical'),
    value: 'physical_item',
  },
  {
    label: t('digital'),
    value: 'digital_item',
  },
]

export const Sectors: readonly SectorType[] = [
  {
    label: 'Government',
    id: 'government',
  },
  {
    label: 'Financial Services',
    id: 'financial_services',
  },
  {
    label: 'Healthcare',
    id: 'healthcare',
  },
  {
    label: 'Technology',
    id: 'technology',
  },
  {
    label: 'Education',
    id: 'education',
  },
  {
    label: 'Manufacturing',
    id: 'manufacturing',
  },
  {
    label: 'Retail',
    id: 'retail',
  },
  {
    label: 'Media',
    id: 'media',
  },
  {
    label: 'Energy',
    id: 'energy',
  },
  {
    label: 'Other',
    id: 'other',
  },
]

export const NumberOfLicenses: readonly NumberOfLicensesType[] = [
  {
    label: '1-5 users',
    id: '1-5',
  },
  {
    label: '6-10 users',
    id: '6-10',
  },
  {
    label: '11-20 users',
    id: '11-20',
  },
  {
    label: '21-50 users',
    id: '21-50',
  },
  {
    label: '51-100 users',
    id: '51-100',
  },
  {
    label: '101-250 users',
    id: '101-250',
  },
  {
    label: '251-500 users',
    id: '251-500',
  },
  {
    label: '501-1000 users',
    id: '501-1000',
  },
  {
    label: '1000+ users',
    id: '1000+',
  },
]

export const EstimatedAssetBalance: readonly EstimatedAssetBalanceType[] = [
  {
    label: 'Less than £100k',
    id: '>100k',
  },
  {
    label: '£100k - £500k',
    id: '100k-500k',
  },
  {
    label: '£500k - £1m',
    id: '500k-1m',
  },
  {
    label: '£1m - £5m',
    id: '1m-5m',
  },
  {
    label: '£5m - £10m',
    id: '5m-10m',
  },
  {
    label: '£10m - £50m',
    id: '10m-50m',
  },
  {
    label: '£50m - £100m',
    id: '50m-100m',
  },
  {
    label: '£100m - £500m',
    id: '100m-500m',
  },
  {
    label: '£500m - £1bn',
    id: '500m-1bn',
  },
  {
    label: '£1bn+',
    id: '1bn+',
  },
]

export const Requirements: readonly RequirementsType[] = [
  {
    label: 'Seize',
    value: 'seize',
  },
  {
    label: 'Manage',
    value: 'manage',
  },
  {
    label: 'Liquidate',
    value: 'liquidate',
  },
]
