import { t } from 'i18next'
import { useState } from 'react'

import { downloadFile } from '@/api/files'
import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { IFile } from '@/types/file'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  file: IFile
  onDeleteFile: (file: IFile) => Promise<void>
}
const FilesActionsView = (props: Props) => {
  const [_downloadingFileIds, setDownloadingFileIds] = useState<string[]>([])
  const [_deletingFileIds, setDeletingFileIds] = useState<string[]>([])

  const onDownloadFile = async () => {
    if (!_downloadingFileIds.includes(props.file?.id as string)) {
      try {
        // Set the downloading flag
        setDownloadingFileIds([
          ..._downloadingFileIds,
          props.file?.id as string,
        ])

        // Download the file
        setTimeout(async () => {
          await downloadFile(props.file)
        }, 4000)
      } catch (e) {
        console.error(e)
      } finally {
        setDownloadingFileIds(
          _downloadingFileIds.filter((i) => i !== props.file.id)
        )
      }
    }
  }

  const onDeleteFile = async () => {
    if (!_deletingFileIds.includes(props.file?.id as string)) {
      setDeletingFileIds([..._deletingFileIds, props.file?.id as string])
      await props.onDeleteFile(props.file)
      setDeletingFileIds(_deletingFileIds.filter((i) => i !== props.file.id))
    }
  }

  return (
    <DropdownView
      {...props}
      id={'FILE_ACTIONS'}
      items={[
        {
          title: t('download_file'),
          icon: 'edit',
          onClick: onDownloadFile,
        },
        {
          title: t('delete_file'),
          icon: 'trash',
          onClick: onDeleteFile,
        },
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default FilesActionsView
