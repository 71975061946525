import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export interface LoaderData {
  escrowId: string
}
export const loader: LoaderFunction<LoaderData> = async ({ params }) => {
  try {
    if (!params.escrow_id) {
      return ErrorHandler(404, t('missing_params'))
    }

    return { escrowId: params.escrow_id }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error) ?? t('something_went_wrong')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
