import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Typography } from '../typography'

interface Props {
  label?: string
  id?: string
  className?: string
  inline?: boolean
  children?: ReactNode
  shadow?: boolean
  borderStyle?: 'solid' | 'dashed' | 'dotted'
  testId?: string
}
export const Group = (props: Props) => {
  return (
    <div
      id={props.id}
      data-testid={props.testId}
      className={twMerge(
        'flex flex-col w-full relative',
        'border-[1px] border-[#2124271A] rounded-[0.75rem]',
        'bg-white gap-[24px]',
        'p-[24px] pt-[32px]',
        props.borderStyle === 'dotted'
          ? 'border-dotted'
          : props.borderStyle === 'dashed'
            ? 'border-dashed'
            : 'border-solid',
        props.shadow && 'shadow-[0_4px_6px_0_rgba(40,38,35,0.05)]',
        props.inline && 'inline-flex w-auto',
        props.className
      )}
    >
      {props.label && (
        <Typography
          variant={'label-large'}
          className={twMerge(
            'absolute top-0 left-[16px] px-2 bg-white transform -translate-y-1/2',
            'overflow-hidden text-ellipsis whitespace-nowrap max-w-[70%]'
          )}
        >
          {props.label}
        </Typography>
      )}
      {props.children}
    </div>
  )
}
