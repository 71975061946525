import { t } from 'i18next'
import { redirect } from 'react-router-dom'

import { getUser, updateOrgUser } from '@/api'
import { AuthManager } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

export const updateUserAction = async (
  formData: FormData
): Promise<ActionResponse<string> | Response> => {
  const { data: user } = await getUser()
  if (user === null) {
    throw new Response('', {
      status: 404,
      statusText: t('user_not_found'),
    })
  }

  const name = formData.get('name') as string
  const language = formData.get('language') as string

  // Create a shallow copy of the user object
  const updatedUser: IUser = {
    ...user,
    name: name || user.name,
    language: language || user.language,
  }

  const { data, error } = await updateOrgUser(updatedUser)
  if (error) {
    return {
      error: true,
      message: '',
      completion: 'NOT_COMPLETE',
      data: null,
    }
  } else {
    await AuthManager.setUser(data)
    BannerManager.showBanner({
      variant: 'page',
      type: 'success',
      title: t('user_updated_successfully'),
      duration: 5000,
    })

    const from = new URLSearchParams(window.location.search).get('from')
    if (from) return redirect(from)
  }

  return {
    error: false,
    message: t('user_updated_successfully'),
    completion: 'COMPLETE',
    data: null,
  }
}
