import { t } from 'i18next'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { findLastValuation } from '@/actions/create-asset/assetForm.ts'
import Button from '@/components/button'
import Input from '@/components/input'
import { Currency, getCurrencySymbol } from '@/constants/currencies'
import { useOrg } from '@/hooks/queries/useOrg'
import { IAssetCost, IAssetValuation } from '@/types/asset'

interface Props {
  costs?: IAssetCost[]
  valuations?: IAssetValuation[]
  valuationCurrency?: Currency
}

export const AssetCalculator = (props: Props) => {
  const { org } = useOrg()
  const [_costs, setCosts] = useState<IAssetCost[]>(
    props.costs ?? [{ currency: org?.preferred_currency, value: undefined }]
  )
  const [_latestValuation] = useState<IAssetValuation | null>(
    findLastValuation(props.valuations)
  )

  const addCost = () => {
    setCosts((prevCosts) => {
      return [
        ...prevCosts,
        {
          currency: org?.preferred_currency,
          value: undefined,
        },
      ]
    })
  }

  const removeCost = () => {
    setCosts((prevCosts) => {
      return prevCosts.slice(0, -1)
    })
  }

  const _currency =
    (_latestValuation ? _latestValuation.currency : props.valuationCurrency) ??
    org?.preferred_currency

  return (
    <div
      className={twMerge(
        'flex flex-col p-6 gap-6 self-stretch',
        'bg-gray-100 rounded-[0.75rem]'
      )}
    >
      <input
        type={'hidden'}
        name={'asset_total_value_currency'}
        value={
          _latestValuation ? _latestValuation.currency : org?.preferred_currency
        }
      />
      <Input.Number
        label={t('total_value')}
        name={'asset_total_value'}
        testId={'asset_total_value'}
        placeholder={'0.0'}
        defaultValue={_latestValuation?.value}
        prefix={getCurrencySymbol(_currency)}
        suffix={_currency?.toString()}
      />
      <div className={'flex flex-col gap-4 self-stretch'}>
        <div className={'flex flex-col gap-2 self-stretch'}>
          <Input.Component.Label text={t('new_costs')} />
          {_costs.map((c, i) => (
            <div
              key={`cost_${i}`}
              className={twMerge(
                'flex flex-col mb-4 self-stretch',
                'tablet:mb-0'
              )}
              data-testid="new-costs-wrapper"
            >
              <div
                className={twMerge(
                  'flex flex-col gap-2 self-stretch flex-wrap',
                  'tablet:flex-row'
                )}
              >
                <input
                  type={'hidden'}
                  name={`asset_cost_currency_${i}`}
                  value={c.currency}
                />
                <Input.Number
                  name={`asset_cost_value_${i}`}
                  testId={`asset_cost_value_${i}`}
                  value={c.value}
                  placeholder={'0.0'}
                  prefix={getCurrencySymbol(c.currency)}
                  suffix={c.currency ?? ''}
                  className={'flex-1 text-red-500'}
                />
                <Input.Text
                  name={`asset_cost_name_${i}`}
                  testId={`asset_cost_name_${i}`}
                  placeholder={t('name')}
                  value={c.title ?? ''}
                  className={'flex-1'}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={'flex flex-row gap-4'}>
          <Button.Shape
            layout={'icon'}
            icon={{
              name: 'plus',
              family: 'sharp',
              variant: 'solid',
            }}
            hierarchy={'secondary'}
            size={'small'}
            onClick={addCost}
            withAttributes={{ type: 'button' }}
          />
          <Button.Shape
            layout={'icon'}
            icon={{
              name: 'minus',
              family: 'sharp',
              variant: 'solid',
            }}
            hierarchy={'secondary'}
            state={_costs.length > 1 ? 'default' : 'disabled'}
            size={'small'}
            onClick={removeCost}
            withAttributes={{ type: 'button' }}
          />
        </div>
      </div>
    </div>
  )
}
