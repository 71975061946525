import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { IFile } from '@/types/file'

import { GalleryThumbnail } from './components/thumbnail.gallery'

interface Props {
  files: (File | IFile)[]
  showThumbnails?: boolean
  onRemoveClick?: (file: IFile | File) => void

  // Used for sharing/transfering
  sessionToken?: string
}
export const FileGallery = (props: Props) => {
  const [_files, setFiles] = useState<(File | IFile)[]>([])
  const [_previewFile, setPreviewFile] = useState<File | IFile | undefined>(
    props.files.length > 0 ? props.files[0] : undefined
  )

  useEffect(() => {
    const imageFiles = props.files.filter((f) => {
      if (
        ['IMG', 'image', 'png', 'gif', 'jpg', 'jpeg'].some((t) =>
          f.type.includes(t)
        )
      ) {
        return true
      }
      return false
    })
    setFiles(imageFiles)
    setPreviewFile(imageFiles.length > 0 ? imageFiles[0] : undefined)
  }, [props.files])

  // Set preview function
  const onThumbnailClicked = (file: File | IFile) => {
    setPreviewFile(file)
  }

  // Dont render anything if we have no files to show
  if (_files.length <= 0) {
    return null
  }

  return (
    <div className={'flex flex-col w-full pb-4'}>
      <div
        className={twMerge(
          'w-full h-[50vw] min-h-[240px] max-h-[420px]',
          'overflow-hidden rounded-[12px] items-center justify-center',
          'relative bg-gray-100'
        )}
      >
        {_previewFile && (
          <GalleryThumbnail
            file={_previewFile}
            size={'large'}
            onClick={() => {}}
            onRemoveClick={props.onRemoveClick}
            sessionToken={props.sessionToken}
          />
        )}
      </div>
      {props.showThumbnails && (
        <div
          className={twMerge(
            'flex flex-row pt-4 gap-2 flex-wrap',
            'items-start content-start self-stretch'
          )}
        >
          {_files.map((file, index) => (
            <GalleryThumbnail
              key={`thumnail_${index}`}
              file={file}
              size={'small'}
              onClick={onThumbnailClicked}
              onRemoveClick={props.onRemoveClick}
              isPreviewing={!!_previewFile && _previewFile.name === file.name}
              sessionToken={props.sessionToken}
            />
          ))}
        </div>
      )}
    </div>
  )
}
