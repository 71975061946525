import { t } from 'i18next'
import { useState } from 'react'

import { Flag } from '@/components/flag'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { CountryOption, useCountries } from '@/constants/countries'

interface InputCountryProps {
  selectedValue?: string
  onCountrySelected?: (country: CountryOption) => void
  name: string
  testId?: string
}

export const InputCountry = (props: InputCountryProps) => {
  const countries = useCountries()

  const [inputValue, setInputValue] = useState<string | undefined>(
    props.selectedValue
      ? countries.find((c) => c.value === props.selectedValue)?.title
      : undefined
  )

  const [_selectedCountry, setSelectedCountry] = useState<
    CountryOption | undefined
  >(
    props.selectedValue
      ? countries.find((c) => c.value === props.selectedValue)
      : undefined
  )

  const handleCountrySelected = (c: CountryOption) => {
    setSelectedCountry(c)
    if (props.onCountrySelected) {
      props.onCountrySelected(c)
    }
  }

  return (
    <Input.Suggest<CountryOption>
      name={props.name}
      testId={props.testId}
      label={t('country')}
      placeholder={t('select_country')}
      defaultValue={_selectedCountry?.value}
      addValueAsEntry={false}
      onValueChanged={(v) => {
        setInputValue(v)
      }}
      icon={
        countries.find((c) => c.title === inputValue) &&
        countries.find((c) => c.title === inputValue)?.value ===
          _selectedCountry?.value &&
        _selectedCountry?.value ? (
          <Icon
            component={
              <Flag
                className="w-8 h-[100%]"
                countryCode={_selectedCountry?.value}
              />
            }
          />
        ) : (
          <Icon name={'earth-americas'} />
        )
      }
      items={countries.map((v, i) => (
        <ListItem
          key={`country_${i}`}
          title={v.title}
          value={v.value}
          onClick={() => {
            handleCountrySelected(v)
            setInputValue(v.title)
          }}
          className="pl-3 pr-2"
          leading={<Flag className="w-8 h-[100%]" countryCode={v.value} />}
        />
      ))}
    />
  )
}
