import { twMerge } from 'tailwind-merge'

import {
  getCryptoImageUrl,
  getCurrencyImageUrl,
} from '@/helpers/assetTitleAndIcon.ts'

import { Image } from '../image'

export type Size =
  | 'xs'
  | 'xsmall'
  | 'sm'
  | 'small'
  | 'md'
  | 'medium'
  | 'lg'
  | 'large'
  | 'xl'
  | 'xlarge'
  | 'xxl'
  | 'xxlarge'
export type Variant = 'regular' | 'solid' | 'light' | 'brands'
export type Family = 'sharp'

interface BaseProps {
  className?: string
  size?: Size
  state?: 'default' | 'disabled'
  alt?: string
  onClick?: () => void
  component?: JSX.Element
}

export interface IconProps extends BaseProps {
  name: string
  family?: Family
  variant?: Variant
}

export interface FlagProps extends BaseProps {
  flag: string
}

export interface CryptoProps extends BaseProps {
  crypto?: string
}

export interface FiatProps extends BaseProps {
  fiat: string
}

export interface ImageProps extends BaseProps {
  image: string
}

export interface LoaderProps extends BaseProps {
  type: 'loader'
  color: 'white' | 'black' | 'grey'
  // weight: 'bold' | 'regular' // << Cant see the difference in the Figma
}

export type Props =
  | IconProps
  | FlagProps
  | CryptoProps
  | FiatProps
  | LoaderProps
  | ImageProps
export const Icon = (props: Props): JSX.Element => {
  let iconSize: string = ''
  let svgSize: number = 0

  // Work out the icon + svg size
  switch (props.size ?? 'medium') {
    case 'xs':
    case 'xsmall':
      iconSize =
        'min-w-[1.5rem] min-h-[1.5rem] max-w-[1.5rem] max-h-[1.5rem] text-[0.75rem] leading-normal'
      svgSize = 18
      break
    case 'sm':
    case 'small':
      iconSize =
        'min-w-[1.5rem] min-h-[1.5rem] max-w-[1.5rem] max-h-[1.5rem] text-[1rem] leading-normal'
      svgSize = 24
      break
    case 'md':
    case 'medium':
      iconSize =
        'min-w-[2rem] min-h-[2rem] max-w-[2rem] max-h-[2rem] text-[1.25rem] leading-normal'
      svgSize = 32
      break
    case 'lg':
    case 'large':
      iconSize =
        'min-w-[2.5rem] min-h-[2.5rem] max-w-[2.5rem] max-h-[2.5rem] text-[1.625rem] leading-normal'
      svgSize = 50
      break
    case 'xl':
    case 'xlarge':
      iconSize =
        'min-w-[3.5rem] min-h-[3.5rem] max-w-[3.5rem] max-h-[3.5rem] text-[2.25rem] leading-normal'
      svgSize = 56
      break
    case 'xxl':
    case 'xxlarge':
      iconSize =
        'min-w-[4rem] min-h-[4rem] max-w-[4rem] max-h-[4rem] text-[3.5rem] leading-normal'
      svgSize = 64
      break
  }

  return (
    <span
      className={twMerge(
        'text-xl flex items-center justify-center leading-normal',
        'transition-[opacity] duration-100',
        iconSize,
        props.onClick && 'cursor-pointer active:opacity-50',
        props.className,
        props.state === 'disabled' && 'text-[#909293]',
        'type' in props && props.type === 'loader' && 'animate-spin',
        'flag' in props && ' items-stretch'
      )}
      onClick={props.onClick}
    >
      {'component' in props ? (
        props.component
      ) : 'name' in props ? (
        <i
          className={`fa-${props.variant ?? 'regular'} fa-${props.name} ${
            props.family && `fa-${props.family}`
          }`}
        ></i>
      ) : 'flag' in props ? (
        <Image
          src={props.flag}
          alt={props.alt ?? `flag image for ${props.flag}`}
          className={twMerge(
            'min-w-full min-h-full rounded-full overflow-hidden object-cover',
            'border-solid border-[1px] border-[#F5F5F6]'
          )}
        />
      ) : 'crypto' in props ? (
        <Image
          src={getCryptoImageUrl(props.crypto)}
          fallbackSrc={getCryptoImageUrl()}
          alt={props.alt ?? `crypto image for ${props.crypto}`}
          className={
            'min-w-full min-h-full rounded-full overflow-hidden object-cover'
          }
        />
      ) : 'fiat' in props ? (
        <Image
          src={getCurrencyImageUrl(props.fiat)}
          fallbackSrc={getCurrencyImageUrl()}
          alt={props.alt ?? `fiat image for ${props.fiat}`}
          className={'w-full h-full  rounded-full overflow-hidden'}
        />
      ) : 'image' in props ? (
        <Image
          src={props.image}
          alt={props.alt ?? `image`}
          className={
            'min-w-full min-h-full rounded-full overflow-hidden object-cover'
          }
        />
      ) : 'type' in props && props.type === 'loader' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={`${svgSize}`}
          height={`${svgSize}`}
          viewBox={`0 0 56 56`}
          fill="none"
        >
          <path
            d="M47.799 47.799C51.7149 43.8831 54.3816 38.894 55.462 33.4625C56.5424 28.0311 55.9879 22.4012 53.8686 17.2849C51.7494 12.1685 48.1605 7.79553 43.556 4.71885C38.9514 1.64217 33.5379 4.18098e-07 28 0V7C32.1534 7 36.2135 8.23163 39.667 10.5391C43.1204 12.8466 45.812 16.1264 47.4015 19.9636C48.9909 23.8009 49.4068 28.0233 48.5965 32.0969C47.7862 36.1705 45.7861 39.9123 42.8492 42.8492L47.799 47.799Z"
            fill={
              props.color === 'white'
                ? 'white'
                : props.color === 'black'
                  ? '#212427'
                  : '#909293'
            }
          />
          <path
            d="M28 0C23.3954 -5.49096e-08 18.8618 1.1356 14.8009 3.3062C10.74 5.47681 7.27705 8.61543 4.71885 12.444C2.16066 16.2726 0.58616 20.6731 0.134828 25.2555C-0.316505 29.838 0.369258 34.461 2.13137 38.7151C3.89349 42.9693 6.67756 46.7231 10.237 49.6443C13.7964 52.5654 18.0213 54.5637 22.5375 55.462C27.0536 56.3603 31.7216 56.131 36.128 54.7943C40.5343 53.4577 44.543 51.055 47.799 47.799L42.8492 42.8492C40.4073 45.2912 37.4007 47.0933 34.096 48.0957C30.7912 49.0982 27.2902 49.2702 23.9031 48.5965C20.516 47.9228 17.3473 46.4241 14.6777 44.2332C12.0082 42.0424 9.92012 39.2269 8.59853 36.0364C7.27694 32.8458 6.76262 29.3785 7.10112 25.9416C7.43962 22.5048 8.62049 19.2045 10.5391 16.333C12.4578 13.4616 15.055 11.1076 18.1007 9.47965C21.1464 7.8517 24.5465 7 28 7V0Z"
            fill={props.color === 'white' ? 'white' : '#212427'}
            fillOpacity="0.25"
          />
        </svg>
      ) : null}
    </span>
  )
}
