import { t } from 'i18next'
import { useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import { RowTypes } from '@/components/dropdown/components/list.dropdown'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { DEFAULT_USER_ROLE } from '@/constants/auth.ts'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { useWorkspace } from '@/contexts/workspace'
import { roleTitleTranslated } from '@/helpers/roles.ts'
import { useOrgUsers } from '@/hooks/queries/useOrgUsers'
import { useRoles } from '@/hooks/queries/useRoles'
import { ActionResponse } from '@/types/actions'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  onInvite?: () => void
  onCancel?: () => void
}

export const WorkspaceInviteModal = (props: Props) => {
  const { selectedWorkspace } = useWorkspace()
  const { user } = useAuth()
  const { users } = useOrgUsers(user?.organization_id ?? '')
  const { roles } = useRoles()
  const { revalidate } = useRevalidator()
  const [_selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const [_selectedRoleId, setSelectedRoleId] = useState<string | null>(null)
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  const validRoles = roles.filter((role) => role.name !== 'Owner') ?? []
  const defaultRoleId =
    validRoles.find((role) => role.name.toLowerCase() === DEFAULT_USER_ROLE)
      ?.id ??
    validRoles[0]?.id ??
    ''

  const usersToDisplay = (users ?? [])
    .filter((u) => !user || u.id !== user.id)
    .sort((a, b) => a.name.localeCompare(b.name))

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  const onSuccess = () => {
    revalidate()
    ToastManager.showToast({
      type: 'success',
      text: t('user_added_successfully'),
    })

    // Allow some time for the revalidation to occur - it'll be cleaner
    // for the user
    setTimeout(() => {
      props.onClose()
    }, 500)
  }

  const onCancel = () => {
    props.onCancel && props.onCancel()
    props.onClose()
  }

  const onUserSelected = (item: RowTypes<unknown> | null) => {
    if (item && 'value' in item && item.value) {
      setSelectedUserId(item.value)
    }
  }

  const onRoleSelected = (roleId: string | null) => {
    setSelectedRoleId(roleId)
  }

  return (
    <Modal
      testId={'modal_workspace_invite'}
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[504px]'}
    >
      <Form
        buttons={[
          <Button.Basic
            key={'btn_add'}
            label={t('add_user')}
            state={
              _submitting === INTENTS.ADD_WORKSPACE_MEMBER
                ? 'loading'
                : _selectedRoleId && _selectedUserId
                  ? 'default'
                  : 'disabled'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.ADD_WORKSPACE_MEMBER,
            }}
          />,
          <Button.Basic
            key={'btn_cancel'}
            hierarchy={'tertiary'}
            size={'medium'}
            label={t('cancel')}
            onClick={onCancel}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={'flex p-6 flex-col items-start gap-6 w-full'}
      >
        <input
          type={'hidden'}
          name={'workspace_id'}
          value={selectedWorkspace?.id}
        />
        <ListItem
          leading={
            <Icon
              name={'sitemap'}
              family={'sharp'}
              variant={'solid'}
              size={'medium'}
            />
          }
          title={selectedWorkspace?.name}
          description={t('workspace_invite_header_desc')}
        />
        <Paragraph
          subTitle={t('workspace_invite_sub_header')}
          description={t('workspace_invite_description')}
          spacerOverrides={{
            subTitle: ['top', 'bottom'],
            description: [],
          }}
        />

        <div className={'flex flex-col items-end tablet:flex-row gap-2 w-full'}>
          <Input.Suggest
            label={t('user')}
            name={'user_id'}
            addValueAsEntry={false}
            onItemSelectionChange={onUserSelected}
            onValueChanged={() => setSelectedUserId(null)}
            items={usersToDisplay.map((u) => (
              <ListItem
                key={`user_${u.id}`}
                leading={
                  <Avatar type={'monogram'} value={u.name} size={'small'} />
                }
                title={u.name}
                value={u.id}
                data={u}
                className={'pl-0 pr-0 tablet:pl-2 tablet:pr-2'}
              />
            ))}
            className={'max-w-none'}
          />
          <div className={'max-w-none w-full tablet:max-w-[10rem]'}>
            <Input.Dropdown
              name={'role_id'}
              defaultValue={defaultRoleId}
              placeholder={'Role'}
              onItemSelectionChange={onRoleSelected}
              items={validRoles.map((r) => (
                <ListItem
                  key={`role_${r.id}`}
                  title={roleTitleTranslated(r)}
                  value={r.id}
                  data={r}
                  className={'pl-2 pr-2'}
                />
              ))}
              className={'max-w-none'}
            />
          </div>
        </div>
      </Form>
    </Modal>
  )
}
