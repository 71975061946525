import { twMerge } from 'tailwind-merge'

import { LogoIcon } from '../icon'
import { LogoWord } from '../word'

interface Props {
  style: 'COLOR' | 'MONO'
  color: 'BLACK' | 'WHITE'
  heightRem?: number
  className?: string
  onClick?: () => void
}
export const LogoWordMark = (props: Props) => {
  // Set some defaults
  props = {
    heightRem: 5.5,
    ...props,
  }

  return (
    <div
      className={twMerge(
        'flex w-auto pr-[0.01469rem] justify-center items-center',
        props.onClick && 'hover:cursor-pointer',
        props.className
      )}
      style={{
        gap: `${(props.heightRem ?? 1) * 0.157503237}rem`,
      }}
      onClick={props.onClick}
    >
      <LogoIcon
        color={props.style === 'COLOR' ? 'WHITE' : props.color}
        background={props.style === 'COLOR' ? 'RED' : 'TRANSPARENT'}
        shape={'ROUND'}
        sizeInRem={props.heightRem ?? 1}
      />
      <LogoWord
        color={props.color === 'BLACK' ? 'BLACK' : 'WHITE'}
        heightInRem={(props.heightRem ?? 1) * 0.8689833763}
      />
    </div>
  )
}
