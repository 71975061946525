import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import { useDropdown } from '@/contexts/interface'
import pxToRem from '@/helpers/pxToRem'
import { useWindowSize } from '@/hooks/useWindowSize'

import { Props as DropdownControllerProps } from './controller.dropdown'
import { RowTypes } from './list.dropdown'

/*
    Built by N.Palethorpe
    Wed 20th Dec

    This component is used to wrap another component with drop down
    functionality. This is the same wrapper component that the actual
    dropdown is using to keep components common.

    Note - If the trigger component has an onclick itself this will
    overwrite the dropdown click capture (unless propogation is allowed)
    this will cause the dropdown to fail to open. Its not a bug.
*/
export interface Props<T> {
  dropdownId?: string // Populated by the context on show
  target: string | React.MouseEvent<HTMLElement> // Element Id of a target or the onClick event
  controller: ReactElement<DropdownControllerProps<T>>
  autoClose?: boolean
  autoCloseDelay?: number
  autoSize?: ('mobile' | 'desktop' | 'tablet')[]
  maxHeightPx?: number
  maxWidthPx?: number
  offsetYPx?: number

  type?: 'default' | 'input'
  allowMobileView?: boolean
  onDropdownToggled?: (open: boolean, alignment?: DropdownAlignment) => void
  onItemClicked?: (item: RowTypes<T>) => void
  closeOnItemClick?: boolean
  state?: 'default' | 'disabled'
  className?: string
}

export type DropdownAlignment = 'ABOVE' | 'BELOW'
export interface DropdownHandle {
  open: (viewId?: string) => void
  close: () => void
}

declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null
}

const DropdownContext = <T,>(
  props: Props<T>,
  ref: React.ForwardedRef<DropdownHandle>
) => {
  const { isMobile, isTablet, isDesktop, windowSize } = useWindowSize()
  const { setCurrentDropdownId, currentDropdownId } = useDropdown()
  const [_openTimestamp, setOpenTimestamp] = useState<number | null>(null)
  const [_showing, setShowing] = useState<boolean>(false)
  const [_visible, setVisible] = useState<boolean>(false)
  const [_hiding, setHiding] = useState<boolean>(false)
  const [_alignment, setAlignment] = useState<DropdownAlignment>('ABOVE')
  const [_styles, setStyles] = useState<CSSProperties>()

  const [_currentViewHeightVal, setCurrentViewHeightVal] = useState<number>(0)
  const [_currentViewHeight, setCurrentViewHeight] = useState<string>('auto')
  const timeoutRef = useRef<NodeJS.Timeout>()
  const targetRef = useRef<HTMLElement | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // Expose a few functions
  useImperativeHandle(ref, () => {
    return {
      open: showDropdown,
      close: hideDropdown,
    }
  }, [isMobile, props.target, props.controller, currentDropdownId])

  useEffect(() => {
    if (props.target && props.controller) {
      // Figure out if the target is a string Id or a mouse event
      if (typeof props.target === 'string') {
        targetRef.current = document.getElementById(props.target)
      } else {
        targetRef.current = props.target.target as HTMLElement
      }
      // Check we do have a target - otherwise we won't be able to show the menu
      if (targetRef.current) {
        // Attempt to calculate the position
        calcPositionStyle()

        // Show the dropdown
        showDropdown()
      }
    }
  }, [props.target, props.controller])

  // Monitor the screensize changing - if it doesn lets simply
  // close the drop down.
  useEffect(() => {
    if (_showing || _visible) {
      hideDropdown()
    }
  }, [windowSize])

  // Listen for any external click events whilst we're visible
  useEffect(() => {
    if (_showing || _visible) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [_visible])

  // Check if the click event occured outside this component
  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      _visible
    ) {
      hideDropdown()
    }
  }

  // Users mouse has left the overall container of the
  // dropdown
  const onMouseLeaveContainer = () => {
    if ((_showing || _visible) && !isMobile && props.autoClose) {
      timeoutRef.current = setTimeout(() => {
        hideDropdown()
      }, props.autoCloseDelay ?? 500)
    }
  }

  const showDropdown = () => {
    if (props.state !== 'disabled' && _visible !== true) {
      // Set the time the open was triggered - we also use this value
      // to indicate to the controllers that the menu is about to be
      // displayed
      setOpenTimestamp(new Date().getTime())

      // Calculate the positioning
      const alignment = calcPositionStyle()
      setAlignment(alignment)

      // Start the process by marking the dropdown as being 'shown'
      // this will keep the dropdown offscreen but rendered whilst
      // we work out its potential size - we'll then begin displaying
      // after a short delay
      setShowing(true)

      // We need the _showing state value to be updated initially to trigger
      // an offscreen render before we show the component - otherwise we'll
      // have potentially 1000s of dropdowns being painted but never used.
      setTimeout(() => {
        props.onDropdownToggled && props.onDropdownToggled(true, alignment)

        // Begin showing the dropdown
        setHiding(false)
        setVisible(true)
      }, 50)
    }
  }

  const hideDropdown = (immediateHide?: boolean) => {
    // First lets clear the currently open dropdown id - this will ensure that
    // no updates are pushed through whilst we're closing which could potentially
    // trigger the dropdown to re-open
    setCurrentDropdownId(null)

    // Throw the callback if required
    props.onDropdownToggled && props.onDropdownToggled(false)

    // Start the hide animation - we have an event that is triggered when
    // the animation ends to then set the component as not visible
    if (immediateHide === true) {
      setHiding(false)
      setVisible(false)
      setShowing(false)
    } else {
      // Doing this route will start a hide animation - we'll then land on the
      // onAnimationEnd callback below which will hide the dropdown
      setHiding(true)
    }
  }

  const onAnimationEnd = () => {
    if (_showing || _visible) {
      if (_hiding) {
        setShowing(false)
        setVisible(false)
        setHiding(false)
      }
    }
  }

  const onBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    hideDropdown()
  }

  const onListHeightChanged = (heightPx: number) => {
    setCurrentViewHeightVal(heightPx)

    // Check to see if we want to autosize the dropdown - we may want a static
    // size depending on the current screen mode (mobile/tablet/desktop)
    if (props.autoSize === undefined) {
      setCurrentViewHeight(`${heightPx}px`)
    } else if (isMobile) {
      if (props.autoSize.includes('mobile')) {
        setCurrentViewHeight(`${heightPx}px`)
      } else {
        setCurrentViewHeight(`70dvh`)
      }
    } else if (isTablet) {
      if (props.autoSize.includes('tablet')) {
        setCurrentViewHeight(`${heightPx}px`)
      } else {
        setCurrentViewHeight(`70dvh`)
      }
    } else if (isDesktop) {
      if (props.autoSize.includes('desktop')) {
        setCurrentViewHeight(`${heightPx}px`)
      } else {
        setCurrentViewHeight(`50dvh`)
      }
    }
  }

  const calcPositionStyle = (): DropdownAlignment => {
    const triggerPos = targetRef.current?.getBoundingClientRect()
    const screenHeight = window.innerHeight
    const screenWidth = window.innerWidth
    const screenPadding = 16 // In px
    const defaultHeight = props.maxHeightPx ?? 400 // In px
    const defaultWidth = props.maxWidthPx ?? 200 // In px
    const minHeight = 200 // In px
    const offsetY =
      props.offsetYPx !== undefined
        ? props.offsetYPx
        : props.type === 'input'
          ? 8
          : 10 // In px
    let top: number | undefined = 0 // In px
    let bottom: number | undefined = 0
    let left: number | undefined = 0 // In px
    let right: number | undefined = 0 // In px
    let width: number = defaultWidth
    let maxHeight: number = defaultHeight
    let alignment: DropdownAlignment = 'BELOW'
    let borderTLRadius: number = 12
    let borderTRRadius: number = 12
    let borderBLRadius: number = 12
    let borderBRRadius: number = 12

    // If we're mobile then we can always say it'll be
    // pinned to the bottom unless we don't want mobile
    // view for this dropdown
    if (isMobile && props.allowMobileView !== false) {
      setStyles({
        position: 'fixed',
        top: `auto`,
        bottom: `0dvh`,
        left: `0`,
        width: `100vw`,
        maxHeight: `70dvh`,

        // Border radius
        borderTopLeftRadius: `0.75rem`,
        borderTopRightRadius: `0.75rem`,
        borderBottomLeftRadius: `0`,
        borderBottomRightRadius: `0`,
      })
      return 'ABOVE'
    }

    if (triggerPos) {
      // Set the width of the dropdown - we should be aiming to be
      // the same width as the trigger component but with constraints.
      // In the case of an input dropdown we should match the width.
      // If we've received a maxWidth then we should use that.
      if (props.maxWidthPx) {
        width = props.maxWidthPx
      } else if (props.type === 'input') {
        width = triggerPos.width
      } else {
        width = Math.max(320, Math.min(480, triggerPos.width))
      }

      // Work out the X positioning of the dropdown in relation to
      // its trigger component - we want to try to always stay inside the screen
      right = undefined
      left = Math.max(
        0,
        triggerPos.left +
          Math.min(0, screenWidth - (triggerPos.left + width + screenPadding))
      )

      // If the left position is more than 50 from the left side then we should use right
      // alignment instead in order to pin it to the right side
      if (left > screenWidth * 0.5) {
        right = screenWidth - triggerPos.right //screenWidth - (left + width)
        left = undefined
      }

      // Work out the height of the dropdown if we were to place it directly
      // below the trigger component
      maxHeight = Math.floor(
        Math.min(
          defaultHeight,
          Math.max(
            minHeight,
            screenHeight - triggerPos.bottom - screenPadding - offsetY
          )
        )
      )

      // If the height is too small then we should aim to place it above the trigger
      // instead (unless the space above the trigger is less!)
      if (
        triggerPos.bottom + maxHeight > screenHeight - screenPadding &&
        triggerPos.top - screenPadding > maxHeight
      ) {
        // Lets attempt to place it above the trigger - first lets recalculate
        // the max height
        maxHeight = Math.max(
          minHeight,
          Math.min(defaultHeight, triggerPos.top - screenPadding)
        )

        // If we're aligning above the trigger then we need to alight
        // using the bottom flag rather than fix it based on the height
        // as the height is maxHeight so it may only have a single item
        top = undefined
        bottom = screenHeight - triggerPos.top

        // Set a flag to indicate we're placing the dropdown above
        alignment = 'ABOVE'
      } else {
        // We should be fine to place the dropdown below the
        // trigger component
        bottom = undefined
        top = triggerPos.bottom
      }

      if (props.type === 'input') {
        borderTLRadius = 6
        borderTRRadius = 6
        borderBLRadius = 6
        borderBRRadius = 6
      }
    }

    // Set the styles
    setStyles({
      position: 'fixed',
      top: top !== undefined ? `${pxToRem(offsetY + top)}rem` : 'auto',
      bottom: bottom !== undefined ? `${pxToRem(offsetY + bottom)}rem` : 'auto',
      left: left !== undefined ? `${pxToRem(left)}rem` : 'auto',
      right: right !== undefined ? `${pxToRem(right)}rem` : 'auto',
      width: `${pxToRem(width)}rem`,
      maxHeight: `${pxToRem(maxHeight)}rem`,

      // Border radius
      borderTopLeftRadius: `${borderTLRadius}px`,
      borderTopRightRadius: `${borderTRRadius}px`,
      borderBottomLeftRadius: `${borderBLRadius}px`,
      borderBottomRightRadius: `${borderBRRadius}px`,
    })

    return alignment
  }

  return (
    <div
      ref={containerRef}
      className={twMerge('relative w-auto h-auto', props.className)}
    >
      <div
        data-testid={'dropdown_backdrop'}
        onClick={onBackdropClick}
        className={twMerge(
          'fixed top-0 left-[150vw] w-0 h-0',
          'overflow-auto overscroll-contain',
          'cursor-default',
          props.allowMobileView === false
            ? 'bg-transparent'
            : 'bg-[#21242759] tablet:bg-transparent',
          _visible && 'w-[100vw] h-[100dvh]',
          _visible && 'z-[80] left-0 animate-fade-in',
          _hiding && 'animate-fade-out'
        )}
      />
      <div
        className={twMerge(
          'absolute hidden left-[150vw] max-w-[100vw]',
          '-z-20 overflow-hidden opacity-0 overscroll-none',
          'transition-[height] duration-[300ms] ease-in-out',
          'border-solid',
          props.type === 'input' ? 'border-[#2124271A]' : 'border-[#2124271A]',
          props.allowMobileView === false
            ? 'border-[1px]'
            : 'tablet:border-[1px]',
          _currentViewHeightVal <= 0 && 'tablet:border-0',
          'bg-white',
          'w-fit h-fit max-h-[50vh]',
          _alignment === 'ABOVE'
            ? 'shadow-[0_-8px_16px_1px_rgba(40,38,35,0.15)]'
            : 'shadow-[0_8px_16px_1px_rgba(40,38,35,0.15)]',
          _showing && 'flex left-0',
          _visible && 'z-[100] top-0 left-0',
          _visible && 'opacity-100 tablet:opacity-0',
          props.type === 'input'
            ? twMerge(
                props.allowMobileView === false ? 'shadow-none' : '',
                _visible &&
                  (props.allowMobileView === false
                    ? 'animate-fade-in'
                    : 'animate-in-up tablet:animate-fade-in'),
                _hiding &&
                  (props.allowMobileView === false
                    ? 'animate-fade-out'
                    : 'animate-out-down tablet:animate-fade-out')
              )
            : twMerge(
                _visible &&
                  _alignment === 'ABOVE' &&
                  'animate-in-up tablet:animate-fade-in-up',
                _visible &&
                  _alignment === 'BELOW' &&
                  'animate-in-down tablet:animate-fade-in-down',
                _hiding &&
                  _alignment === 'ABOVE' &&
                  'animate-out-down tablet:animate-fade-out-down',
                _hiding &&
                  _alignment === 'BELOW' &&
                  'animate-out-up tablet:animate-fade-out-up'
              )
        )}
        onMouseLeave={onMouseLeaveContainer}
        onAnimationEnd={onAnimationEnd}
        style={
          _visible || _hiding
            ? {
                ..._styles,
                height: `${_currentViewHeight}`,
              }
            : {}
        }
      >
        <props.controller.type
          {...props.controller.props}
          dropdownId={props.dropdownId}
          close={hideDropdown}
          isOpen={_showing || _visible}
          openTimestamp={_openTimestamp}
          requestHeight={onListHeightChanged}
        />
      </div>
    </div>
  )
}
DropdownContext.displayName = 'DropdownContext'
export default React.memo(React.forwardRef(DropdownContext))
