import axios, { AxiosError } from 'axios'
import { t } from 'i18next'

import { API_ERRORS } from '@/constants/errors'
import { BaseResponse, isBaseResponse } from '@/types/api'

/*

    This function was built to help extract an error message from a BE response
    by either providing the baseResponse object OR by passing through a collection
    of promise results.

    The function will run through the results and extract the first error it comes
    across, otherwise it'll return a null value to indicate no error was found

*/
type AcceptedTypes =
  | PromiseSettledResult<BaseResponse<unknown>>
  | PromiseSettledResult<BaseResponse<unknown>>[]
  | BaseResponse<unknown>
  | BaseResponse<unknown>[]
  | Response
  | AxiosError
  | Error
  | unknown // << Including this messes our TS up a little but we need it to handle Errors

export const ExtractErrorFrom = (res: AcceptedTypes): string | null => {
  if (res) {
    if (Array.isArray(res)) {
      // Is a collection of items
      if (res.length > 0) {
        // We have items in the collection
        if ('status' in res[0]) {
          const psRes = res as PromiseSettledResult<BaseResponse<unknown>>[]

          // Type is a promise settled response []
          for (const r of psRes) {
            const err = getErrorFromPromiseSettle(r)
            if (err) return err
          }
        } else {
          const brRes = res as BaseResponse<unknown>[]

          // Type is a base response []
          for (const r of brRes) {
            const err = getErrorFromBaseResponse(r)
            if (err) return err
          }
        }
      }
    } else if (typeof res === 'object') {
      // Check if the item is an object
      if (
        'status' in res &&
        (res.status === 'fulfilled' || res.status === 'rejected')
      ) {
        // Type is a promise settled response
        return getErrorFromPromiseSettle(
          res as PromiseSettledResult<BaseResponse<unknown>>
        )
      } else if (isBaseResponse(res)) {
        // Type is a base response
        return getErrorFromBaseResponse(res)
      } else if (axios.isAxiosError(res)) {
        // Type is an Axios Error
        const serverErr = getErrorFromBaseResponse(res.response?.data)
        if (serverErr) return serverErr
        return getErrorFromString(res.message)
      } else if ('statusText' in res) {
        // Type is a Response
        return getErrorFromString(res.statusText)
      } else if ('message' in res) {
        // Possibly an Error

        const message = (
          res as unknown as {
            response: {
              data: {
                message: string
              }
            }
          }
        )?.response?.data?.message

        if (
          message?.length &&
          message?.includes(API_ERRORS.UNSUFFICIENT_POLICIES)
        ) {
          console.log(message)
          return t('unsufficient_policies')
        }
        return getErrorFromString(res.message)
      }
    } else if (typeof res === 'string') {
      // String value
      return getErrorFromString(res)
    }
  }

  // Otherwise return null (no error found)
  return null
}

// Grab the error from a promise settle - this will call the base response
// function if the promise itself hasnt failed
const getErrorFromPromiseSettle = (
  psRes: PromiseSettledResult<BaseResponse<unknown>>
): string | null => {
  if (psRes.status === 'rejected') {
    return psRes.reason
  } else {
    return getErrorFromBaseResponse(psRes.value)
  }
}

// Grab the error from a base response object
const getErrorFromBaseResponse = (
  brRes: BaseResponse<unknown>
): string | null => {
  return brRes.error ? brRes.message : null
}

// Important note here - empty strings will give us the result of null
// this is deliberate so we can understand we've received an actual error
// message
const getErrorFromString = (sRes?: string | unknown) => {
  return sRes && typeof sRes === 'string' && sRes.length > 0 ? sRes : null
}
