import { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { IconProps } from '@/components/icon'

import { ButtonShape } from './shape.button'

export interface Props {
  id?: string
  label: string
  icon: IconProps
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}
export const FabButton = (props: Props) => {
  return (
    <ButtonShape
      id={props.id ?? 'fab-button'}
      layout={'icon'}
      size={'large'}
      icon={props.icon}
      hierarchy={'primary'}
      onClick={props.onClick}
      className={twMerge(
        'fixed tablet:hidden',
        'right-4 bottom-20 shadow-[0_0.5rem_1rem_0_rgba(40,38,35,0.15)]'
      )}
    />
  )
}
