import { useEffect, useState } from 'react'

import { ConfigContext } from './context'
import { Config } from './types'

export type ConfigProviderProps = {
  children: JSX.Element[] | JSX.Element
}

export const ConfigProvider: React.FC<ConfigProviderProps> = (
  props
): JSX.Element => {
  const [config, setConfig] = useState<Config>({} as Config)

  useEffect(() => {
    ;(async () => {
      let loadedConfig: Config

      switch (import.meta.env.MODE) {
        case 'staging':
          loadedConfig = (await import('@/config/staging')).default
          break
        case 'main':
          loadedConfig = (await import('@/config/main')).default
          break
        case 'localdev':
          loadedConfig = (await import('@/config/localdev')).default
          break
        default:
          loadedConfig = (await import('@/config/development')).default
          break
      }

      setConfig(loadedConfig)
    })()
  }, [])

  return (
    <ConfigContext.Provider value={config} {...props}>
      {props.children}
    </ConfigContext.Provider>
  )
}
