import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import File from '@/components/file'
import { Form } from '@/components/form'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { useWorkspace } from '@/contexts/workspace'
import { useOrg } from '@/hooks/queries/useOrg'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}
export const ImportAssetsDrawer = (props: Props) => {
  const { selectedWorkspace } = useWorkspace()
  const { org } = useOrg()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })

    // Close the modal
    props.onClose()
  }

  return (
    <Drawer
      title={t('import_assets')}
      description={t('import_assets_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'new-workspace-form'}
        buttons={[
          <Button.Basic
            key={'btn_import'}
            label={t('import')}
            state={
              _submitting === INTENTS.IMPORT_ASSETS
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.IMPORT_ASSETS,
            }}
            testId={'btn_import'}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <input
          type={'hidden'}
          name={'workspace_id'}
          value={selectedWorkspace?.id}
        />
        <input type={'hidden'} name={'org_id'} value={org?.id} />

        <File.Upload
          id="import_files"
          name="import_files"
          testId={'import_files'}
          innerText={t('add_your_files_here')}
          allowedFileTypes={['XLS']}
          maxFilesAllowed={5}
          showFileList={true}
          containerClassName="mt-[16px]"
        />
      </Form>
    </Drawer>
  )
}
