import '../animations.css'

import { useEffect, useState } from 'react'

import { useBanner } from '@/contexts/banner'

import DefaultPageBanner from '../components/default.page.banner'

export const PageBanner = () => {
  const { currentPageBanner: _banner } = useBanner()
  const [_hide, setHide] = useState<boolean>(false)

  useEffect(() => {
    if (_banner) {
      let autoCloseTimer: NodeJS.Timeout

      // Ensure the hide is not true
      setHide(false)

      // Check if we want to auto close the banner
      if (_banner.duration && _banner.duration > 0) {
        autoCloseTimer = setTimeout(() => {
          // Instruct the component to hide
          setHide(true)

          // Wait a delay and then hide
          setTimeout(() => {
            _banner.close?.()
          }, 1)
        }, _banner.duration)
      }

      return () => clearTimeout(autoCloseTimer)
    }

    return () => {}
  }, [_banner])

  // If banner is null then return null
  if (!_banner) {
    return null
  }

  // Otherwise lets show the banner :)
  return (
    <DefaultPageBanner
      {..._banner}
      className={_hide ? 'animate-fade-out' : ''}
    />
  )
}
