import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { IDefendant } from '@/types/defendants'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  defendant: IDefendant
}
const DefendantActionsView = (props: Props) => {
  const { setDrawer } = useDrawer()
  const navigate = useNavigate()

  const { policies } = useAuth()
  const canManageDefendant = policies?.includes('CUSTODY.MANAGE_DEFENDANT')

  const handleNavigateToDetails = () => {
    if (!canManageDefendant) return
    navigate(ROUTES.SUBJECT.DETAIL.replace(':subject_id', props.defendant.id), {
      state: {
        row: props.defendant,
      },
    })
  }

  const editDefendant = () => {
    if (!canManageDefendant) return
    setDrawer({
      id: 'EDIT_DEFENDANT',
      defendant: props.defendant,
    })
  }

  return (
    <DropdownView
      {...props}
      id={'DEFENDANT_ACTIONS'}
      items={[
        ...(canManageDefendant
          ? [
              {
                title: t('view_details'),
                icon: 'eye',
                onClick: handleNavigateToDetails,
              },
            ]
          : []),
        ...(canManageDefendant
          ? [
              {
                title: t('edit_subject'),
                icon: 'edit',
                onClick: editDefendant,
              },
            ]
          : []),
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default DefendantActionsView
