import { twMerge } from 'tailwind-merge'

import Banner from '@/components/banner'

// import Footer from '@/components/footer'
// import { BannerManager } from '@/contexts/banner'
// import { FormErrorsManager } from '@/contexts/formErrors'
import Header from './components/header'
import Sidebar from './components/sidebar'

type NavbarProps = {
  children: JSX.Element
}

export const Navbar: React.FC<NavbarProps> = ({ children }) => {
  return (
    <div className="flex flex-col h-[100dvh] min-h-[100dvh] max-h-[100dvh] w-full overflow-hidden">
      <Banner.Site />

      <div className={'flex flex-1 flex-row w-full overflow-hidden'}>
        <Sidebar />

        <div className="relative flex flex-1 flex-col w-full overflow-hidden">
          <Header />
          <Banner.Toast />

          {/* Outlet Content */}
          <main
            className={twMerge(
              'overflow-y-auto min-h-[calc(100dvh-5rem)] w-full pb-6 tablet:pb-8 desktop:pb-16',
              'flex justify-center '
            )}
          >
            <div className={'w-full min-h-full max-w-[1440px]'}>
              <Banner.Welcome />
              <Banner.Page />
              {children}
              {/* <Footer /> */}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
