export const REGEX = {
  // General Regex
  IS_ANY: /^.*$/,
  IS_EMPTY: /^$/,
  IS_STRING: /^[a-zA-Z]+$/,
  IS_ANY_STARTS_WITH_LETTER: /^[a-zA-Z]+.*$/,
  IS_STRING_WITH_NUMBERS: /^[a-zA-Z0-9]+$/,
  IS_STRING_WITH_SPACES: /^[a-zA-Z\s]+$/,
  IS_STRING_WITH_SPACES_AND_SPECIAL:
    /^[a-zA-Z\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/,
  IS_STRING_WITH_SPACES_AND_NUMBERS: /^[a-zA-Z\s\d]+$/,
  IS_UUID: /^[a-zA-Z0-9-]+$/,
  IS_NUMBER: /^\d+$/,
  IS_EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  IS_PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{12,}$/,
  IS_URL:
    /^(http|https):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/,
  IS_DATE_UK: /^\d{2}-\d{2}-\d{4}$/,
  IS_DATE_US: /^\d{4}-\d{2}-\d{2}$/,
  IS_PHONE_NUMBER: /^\+?[\d()-]+$/,
  IS_POSTCODE: /^[a-zA-Z0-9\s]*$/,
  IS_HUMAN_NAME: /^.*$/,

  // Specific Regex
  IS_CASE_NAME: /^.*$/,
  IS_ESCROW_NAME: /^.*$/,
  IS_ESCROW_GROUP_NAME: /^.*$/,
  IS_DEFENDANT_NAME: /^.*$/,
  IS_ASSET_NAME: /^.*$/,
  IS_WORKSPACE_NAME: /^.+$/,
}
