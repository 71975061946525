import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import { AssetSelector } from '@/components/asset/asset-selector'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { IAsset } from '@/types/asset'
import { IOperation } from '@/types/operations'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  case: IOperation
  selectedAssets?: IAsset[]
  onUpdated?: () => void
}

export const CaseAddExistingAssetsDrawer = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    props.onUpdated && props.onUpdated()
    ToastManager.showToast({
      type: 'success',
      text: t('case_assets_updated_successfully'),
    })
    props.onClose()
  }

  return (
    <Drawer
      title={t('add_asset')}
      visible={props.visible}
      onClose={props.onClose}
      className={'gap-0 max-w-[90rem]'}
      drawerClassName={
        'desktop:w-[calc(100vw-64px)] desktop:max-h-[calc(100dvh-64px)] desktop:max-w-[1080px] ' +
        'tablet:w-[calc(100vw-64px)] tablet:max-h-[calc(100dvh-64px)] tablet:max-w-[1080px]'
      }
    >
      <Form<string, INTENTS>
        type={'other'}
        buttons={[
          <Button.Basic
            key={'btn_save_changes'}
            label={t('add_selected')}
            state={
              _submitting === INTENTS.CASE_ADD_EXISTING_ASSETS
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CASE_ADD_EXISTING_ASSETS,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <input
          type={'hidden'}
          name={'case_original'}
          value={JSON.stringify(props.case)}
        />
        <input type={'hidden'} name={'intent'} value={_submitting ?? ''} />

        <AssetSelector name={'case_asset'} />
      </Form>
    </Drawer>
  )
}
