import { t } from 'i18next'
import { useState } from 'react'

import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { isDefined } from '@/helpers/isDefined.ts'
import { IOperation } from '@/types/operations'

interface Props {
  operations: IOperation[]
  preSelected?: IOperation
}

export const AssetCaseSelector = (props: Props) => {
  const [_selection, setSelection] = useState<IOperation | undefined>(
    props.preSelected
  )
  const operationIds = new Set(props.operations?.map((it) => it.id))
  const onValueChanged = (v: string) => {
    if (!operationIds.has(v) && isDefined(_selection)) {
      // clear selection when user starts typing
      setSelection(undefined)
    }
  }

  return (
    <div>
      <input
        type={'hidden'}
        name={'asset_operation'}
        value={_selection?.id ?? ''}
      />
      <Input.Suggest<string>
        testId={'asset_operation'}
        addValueAsEntry={false}
        placeholder={t('select_a_case')}
        state={isDefined(props.preSelected) ? 'disabled' : 'default'}
        icon={<Icon name={'folders'} variant={'solid'} family={'sharp'} />}
        label={t('case')}
        defaultValue={props?.preSelected?.id}
        onValueChanged={onValueChanged}
        items={props.operations.map((_case, index) => (
          <ListItem
            key={`option_${index}`}
            title={_case.name}
            value={_case.id}
            style={'normal'}
            {...(_selection &&
              _selection.id === _case.id && {
                trailing: (
                  <Icon
                    name={'circle-check'}
                    family={'sharp'}
                    size={'medium'}
                    variant={'solid'}
                  />
                ),
              })}
            onClick={() => {
              setSelection(_case)
            }}
            className={'pl-2 pr-2'}
          />
        ))}
      />
    </div>
  )
}
