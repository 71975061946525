import { getEnumValueFromString } from '@/helpers/enums.ts'

export enum STORAGE_KEYS {
  WORKSPACE_ID = 'workspace_id_key',
  LAST_USED_WORKSPACE_ID = 'last_used_workspace_id',
  SESSION_EXPIRATION_KEY = 'session_expiration_key',
  LAST_DROP_DOWN_VALUE_PREFIX = 'last_drop_down_value_',
  BANNERS_FIRST_DISPLAY_PREFIX = 'banner_first_displayed_',
}

export const getSavedSelectionValue = (
  identifier: string
): string | undefined => {
  const value = localStorage.getItem(
    STORAGE_KEYS.LAST_DROP_DOWN_VALUE_PREFIX + identifier
  )
  return value === null ? undefined : value
}

export function getSavedSelectionEnum<T>(
  enumObj: T,
  identifier: string | undefined
): T[keyof T] | undefined {
  if (identifier === undefined) {
    return undefined
  }
  const value = getSavedSelectionValue(identifier)
  if (value) {
    return getEnumValueFromString(enumObj, value)
  }
  return undefined
}

export const saveDropDownSelection = (
  identifier: string | undefined,
  value: string | undefined
): void => {
  if (identifier) {
    localStorage.setItem(
      STORAGE_KEYS.LAST_DROP_DOWN_VALUE_PREFIX + identifier,
      value ? value : ''
    )
  }
}
