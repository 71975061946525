import { useEffect, useState } from 'react'

import { ToastContext } from './context'
import { ToastManager } from './manager'
import { ToastProps } from './types'

type ToastProviderProps = {
  children: JSX.Element[] | JSX.Element
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toastQueue, setToastQueue] = useState<ToastProps[]>([])

  const showToast = (props: ToastProps) => {
    const animation_duration = 500 // Time it takes for the animation to complete

    // Set some defaults if not supplied
    props = {
      type: 'information',
      duration: 3000,
      id: Math.floor(new Date().getTime() * Math.random()).toString(),
      ...props,
    }

    // Add the new toast to the queue
    setToastQueue((prevQueue) => [...prevQueue, props])

    // Set a timer to remove the toast when the duration complete
    setTimeout(
      () => {
        setToastQueue((prevQueue) => prevQueue.filter((b) => b.id !== props.id))
      },
      (props.duration ?? 3000) + animation_duration
    )
  }

  useEffect(() => {
    ToastManager.setShowToastFunction(showToast)
  }, [])

  const currentToast = toastQueue.length > 0 ? toastQueue[0] : null
  return (
    <ToastContext.Provider value={{ currentToast }}>
      {children}
    </ToastContext.Provider>
  )
}
