import { t } from 'i18next'
import { useState } from 'react'

import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'
import {
  getTangibleSubcategoryTitle,
  getTangibleTypeConfig,
} from '@/helpers/assetTitleAndIcon.ts'
import {
  AssetTangibleCategory,
  AssetTangibleType,
  tangibleCategoryAndTypes,
} from '@/types/asset.ts'

export type TangibleSelectionType =
  | [AssetTangibleCategory, AssetTangibleType]
  | undefined
const getKeyFor = (cat: AssetTangibleCategory, type: AssetTangibleType) => {
  return cat + '---' + type
}

interface Props {
  onSelectionChanged: (selection: TangibleSelectionType) => void
}

export const AssetTangibleTypeSelector = (props: Props) => {
  const [_selection, setSelection] = useState<TangibleSelectionType>(undefined)

  const onSelectionChanged = (value: TangibleSelectionType) => {
    setSelection(value)
    props.onSelectionChanged(value)
  }

  const typeConfig = _selection
    ? getTangibleTypeConfig(_selection[0], _selection[1])
    : undefined
  const selectedTitle = typeConfig?.selectedTitle ?? typeConfig?.title ?? ''
  const selectedIcon = typeConfig?.icon ?? ''

  return (
    <div className={'w-full'}>
      <input
        type={'hidden'}
        name={'asset_category'}
        value={_selection ? _selection[0] : ''}
      />
      <input
        type={'hidden'}
        name={'asset_category_type'}
        value={_selection ? _selection[1] : ''}
      />
      <Input.Dropdown
        name="asset_tangible_category"
        testId="asset_tangible_category"
        hint={t('choose_tangible_asset_type')}
        icon={
          selectedIcon ? (
            <Icon
              name={selectedIcon}
              variant="solid"
              family="sharp"
              size="medium"
            />
          ) : undefined
        }
        placeholder={t('select_category')}
        text={selectedTitle}
        items={tangibleCategoryAndTypes
          .flatMap((categoryAndType, index) => {
            const [subcategory, tangibleTypeEnum] = categoryAndType
            const dropdownItems: JSX.Element[] = []
            dropdownItems.push(
              <Typography
                key={`option_header_${index}`}
                variant={'label-medium'}
                spacers={['top', 'bottom']}
              >
                {getTangibleSubcategoryTitle(categoryAndType) ?? ''}
              </Typography>
            )

            Object.values(tangibleTypeEnum).map((_type) => {
              const config = getTangibleTypeConfig(subcategory, _type)
              if (config) {
                dropdownItems.push(
                  <ListItem
                    key={getKeyFor(subcategory, _type)}
                    title={config.title}
                    leading={
                      <Icon
                        name={config.icon}
                        family={'sharp'}
                        variant={'solid'}
                        size={'medium'}
                      />
                    }
                    onClick={() => onSelectionChanged([subcategory, _type])}
                    className={'pl-2 pr-2'}
                  />
                )
              }
            })

            return dropdownItems
          })
          .filter((item) => item !== null)}
      />
    </div>
  )
}
