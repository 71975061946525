import { t } from 'i18next'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Banner from '@/components/banner'
import Logo from '@/components/logo'
import { Typography } from '@/components/typography'

interface Props {
  children: ReactNode
}
export const Template = (props: Props) => {
  const navigate = useNavigate()

  return (
    <div
      className={twMerge(
        'flex flex-col',
        'w-full h-full min-h-dvh overflow-auto'
      )}
    >
      <div
        className={twMerge(
          'flex flex-col p-[48px] pb-[20px]',
          'justify-center items-start self-stretch',
          'p-[24px]',
          'tablet:p-[48px] tablet:pb-0',
          'desktop:p-[48px] desktop:pb-[20px]'
        )}
      >
        <Logo.WordMark
          style={'COLOR'}
          heightRem={3.5}
          color={'BLACK'}
          onClick={() => navigate('/')}
        />
      </div>
      <div className={'flex w-full justify-center'}>
        <div className={'w-full max-w-[90rem]'}>
          <Banner.Page />
        </div>
      </div>
      <main className={'flex flex-1 flex-col items-center justify-center p-6'}>
        {props.children}
      </main>
      <footer
        className={twMerge(
          'flex flex-row p-6 content-end items-start gap-[1.5rem] self-stretch',
          'tablet:p-12'
        )}
      >
        <Typography variant={'label-small'} className={'text-gray-500'}>
          <a href="https://www.assetreality.com/privacy-policy" target="blank">
            {t('privacy_policy')}
          </a>
        </Typography>
        <Typography variant={'label-small'} className={'text-gray-500'}>
          <a href="https://www.assetreality.com/cookie-policy" target="blank">
            {t('cookie_policy')}
          </a>
        </Typography>
      </footer>
    </div>
  )
}
