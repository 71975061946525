import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { NoData } from '@/components/no-data'
import Page from '@/components/page'
import { Paragraph } from '@/components/paragraph'
import { Table } from '@/components/table'
import { GroupBy as SupportedAssetGroupBy } from '@/components/table/variants/custodian-supported-assets.table'
import { useDrawer } from '@/contexts/interface'
import { useOperations } from '@/hooks/queries/useOperations'
import { ICustodian } from '@/types/custodian'

export const CustodianDetail: React.FC = (): JSX.Element => {
  const { custodian: _custodian } = useLoaderData() as { custodian: ICustodian }
  const [_urlParams] = useSearchParams()
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const { operations } = useOperations()
  const [_tableGroup, setTableGroup] = useState<SupportedAssetGroupBy>()

  useEffect(() => {
    if (_urlParams.has('filter')) {
      const filter = _urlParams.get('filter')
      if (filter && ['all', 'active', 'disabled'].includes(filter)) {
        setTableGroup(filter as SupportedAssetGroupBy)
      }
    }
  }, [_urlParams])

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col min-h-[calc(100dvh-80px)]',
          'tablet:pt-0'
        )}
      >
        <Page.Navigation className={'px-6'} />
        <div className={twMerge('flex flex-col p-8 pt-4', 'tablet:gap-8')}>
          <Paragraph
            title={_custodian?.name ?? 'No custodian name.'}
            description={t('case_description')}
            spacerOverrides={{ description: [] }}
          />
          <div className={'flex flex-row gap-2'}>
            <Button.Basic
              icon={{
                name: 'plus',
              }}
              label={t('add_asset')}
              onClick={() => {
                setDrawer({
                  id: 'NEW_SUPPORTED_ASSET',
                  custodian_id: _custodian?.id,
                })
              }}
              className="hidden tablet:flex"
            />
          </div>
        </div>
        {operations && operations.length > 0 ? (
          <div
            className={'flex flex-col px-8 pb-8'}
            data-testid="case-table-wrapper"
          >
            <Table
              custodianDetailAssets={_custodian?.supported_assets ?? []}
              defaultGroupBy={_tableGroup}
            />
          </div>
        ) : (
          <NoData description={t('no_cases_description')} />
        )}

        <Button.Fab
          label={t('create_new_case')}
          icon={{
            name: 'plus',
          }}
          onClick={() => {
            setDrawer({ id: 'NEW_CASE' })
          }}
        />
      </div>
    </>
  )
}
