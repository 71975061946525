// AssetDetailsGroup.tsx

import { t } from 'i18next'

import File from '@/components/file'
import { Currency } from '@/constants/currencies.ts'
import { IAsset, IAssetCost, IAssetValuation } from '@/types/asset'

export interface AssetDetailsValues {
  make?: string
  model?: string
  serialNumber?: string
  country?: string
  address?: string
  value?: number
  costs?: IAssetCost[]
  valuations?: IAssetValuation[]
  valuationCurrency?: Currency
}

interface Props {
  asset?: IAsset
}

export const AssetFormPhotos = ({ asset }: Props) => {
  const images = asset?.files?.filter((f) => f.type === 'IMG')
  return (
    <File.Upload
      id="asset_photos"
      name="asset_photos"
      testId={'asset_photos'}
      label={t('photos')}
      files={images}
      innerText={t('asset_accepts_photo_types')}
      allowedFileTypes={['JPG', 'PNG', 'GIF']}
      maxFilesAllowed={10}
      showGallery={true}
      showQuantitySelected={true}
      // showImagePreview={true}
    />
  )
}
