import {
  ParagraphBasic,
  Props as ParagraphBasicProps,
} from './variants/basic.paragraph'
import {
  ParagraphHeading,
  Props as ParagraphHeadingProps,
} from './variants/heading.paragraph'
import {
  ParagraphSubtext,
  Props as ParagraphSubtextProps,
} from './variants/subtext.paragraph'

type BasicParagraphProps = {
  variant?: 'basic'
} & ParagraphBasicProps

type HeadingParagraphProps = {
  variant: 'heading'
} & ParagraphHeadingProps

type SubtextParagraphProps = {
  variant: 'subtext'
} & ParagraphSubtextProps

type Props = BasicParagraphProps | HeadingParagraphProps | SubtextParagraphProps
export const Paragraph = (props: Props) => {
  switch (props.variant) {
    case 'heading':
      return <ParagraphHeading {...props} />

    case 'subtext':
      return <ParagraphSubtext {...props} />

    default:
      return <ParagraphBasic {...props} />
  }
}
