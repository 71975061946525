import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Dropdown from '@/components/dropdown'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import Table from '@/components/table'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { useDropdown } from '@/contexts/interface'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { useAdminCustodians } from '@/hooks/queries/useAdminCustodians'
import { useSupportedAssets } from '@/hooks/queries/useSupportedAssets'
import { ISupportedAsset } from '@/types/custodian'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  defaultAssetIds?: string[]
  maxSelection?: number
  requireSelection?: number
  selected?: ISupportedAsset[]
  exclude?: ISupportedAsset[]
  onComplete: (assets: ISupportedAsset[]) => void
}
export const AssetSelectorModal = (props: Props) => {
  const { user } = useAuth()
  const { custodians, isLoading: isLoadingAdminCustodians } =
    useAdminCustodians(user?.role)
  const { supportedAssets, isLoading: isLoadingSupportedAssets } =
    useSupportedAssets()
  const [_selectableAssets, setSelectableAssets] = useState<ISupportedAsset[]>(
    []
  )
  const { setDropdown } = useDropdown()
  const [_selectedAssets, setSelectedAssets] = useState<ISupportedAsset[]>(
    props.selected ?? []
  )
  const [_quickFilterText, setQuickFilterText] = useState<string>('')
  const [_networkFilter, setNetworkFilter] = useState<string>('')
  const [_networks, setNetworks] = useState<string[]>([])
  const [_maxSelection] = useState<number>(props.maxSelection ?? 1)

  useEffect(() => {
    let selectableAssets: ISupportedAsset[] = []
    const availableNetworks: Set<string> = new Set()

    if (user?.role === 'super') {
      // Admin role we'll need to convert the custodians object into a useable list of
      // assets...
      selectableAssets = (custodians ?? [])
        .flatMap((custodian) =>
          custodian.supported_assets.map((asset) => {
            // Add the network to the Set
            availableNetworks.add(asset.network.toLowerCase())

            return {
              name: asset.name,
              ticker: asset.ticker,
              network: asset.network,
              contract_address: asset.contract_address,
              native_asset_ticker: asset.native_asset,
              native_asset_name: asset.network,
              custodians: [
                {
                  id: custodian.id,
                  name: custodian.name,
                  supported_asset_key: asset.key,
                },
              ],
            }
          })
        )
        .filter(
          (a) =>
            !(props.exclude || []).find(
              (e) =>
                a.name === e.name &&
                a.network === e.network &&
                a.ticker === e.ticker &&
                a.contract_address === e.contract_address &&
                a.native_asset_name === e.native_asset_name &&
                a.native_asset_ticker === e.native_asset_ticker
            )
        )
    } else {
      selectableAssets = (supportedAssets ?? []).filter(
        (a) =>
          !(props.exclude || []).find(
            (e) =>
              a.name === e.name &&
              a.network === e.network &&
              a.ticker === e.ticker &&
              a.contract_address === e.contract_address &&
              a.native_asset_name === e.native_asset_name &&
              a.native_asset_ticker === e.native_asset_ticker
          )
      )

      // Add networks from `supportedAssets` for non-super users
      supportedAssets?.forEach((asset) =>
        availableNetworks.add(asset.native_asset_name.toLowerCase())
      )
    }

    // Run through and grab all available networks
    setNetworks(
      Array.from(availableNetworks)
        .filter((n) => n.trim().length > 0)
        .sort()
    )

    // Set the selectable assets
    setSelectableAssets(selectableAssets)
  }, [custodians, supportedAssets, props.exclude])

  const handleInput = (value: string) => {
    setQuickFilterText(value?.trim())
  }

  const renderFilterBar = useMemo(() => {
    return (
      <div className="flex gap-5 flex-col tablet:flex-row">
        <Input.Search
          icon={<Icon name="search" variant="solid" size="medium" />}
          size={'medium'}
          placeholder={t('search_for_tokens')}
          onChange={handleInput}
          className={'max-w-none flex-1'}
          testId={'input_token_filter'}
        />
        <Button.Basic
          id="btn_filter_by_network"
          hierarchy="secondary"
          label={
            _networkFilter
              ? capitalizeFirstLetter(_networkFilter)
              : t('filter_by_network')
          }
          trailingIcon={{
            name: 'chevron-down',
          }}
          className={'w-full tablet:w-auto'}
          onClick={() =>
            setDropdown({
              target: 'btn_filter_by_network',
              controller: (
                <Dropdown.Controllers.BasicList
                  closeOnItemClick
                  items={[
                    <ListItem
                      key={'all_networks'}
                      title={t('all_networks')}
                      className={'px-2'}
                      onClick={() => {
                        setNetworkFilter('')
                      }}
                    />,
                    ..._networks
                      .sort((a, b) => a.localeCompare(b))
                      .map((n) => (
                        <ListItem
                          key={n}
                          title={capitalizeFirstLetter(n)}
                          className={'px-2'}
                          onClick={() => {
                            setNetworkFilter(n)
                          }}
                        />
                      )),
                  ]}
                />
              ),
            })
          }
        />
      </div>
    )
  }, [_networks, _networkFilter])

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      className={'w-[624px]'}
      closeOnBackgroundClick={false}
    >
      <div className="flex p-[24px] flex-col w-full gap-[24px] h-[750px] max-h-[90vh]">
        <div className={'flex flex-row w-full gap-[16px]'}>
          <Paragraph
            subTitle={t('select_token')}
            spacerOverrides={{ subTitle: [] }}
            classOverrides={{
              base: 'flex-1',
            }}
          />
        </div>

        <div className="flex flex-1">
          {isLoadingSupportedAssets || isLoadingAdminCustodians ? (
            <div
              className={twMerge(
                'flex flex-col items-center justify-center',
                'gap-[16px] p-[32px]'
              )}
            >
              <Paragraph description={'Assets loading...'} />
            </div>
          ) : _selectableAssets.length <= 0 ? (
            <div
              className={twMerge(
                'flex flex-col items-center justify-center',
                'gap-[16px] p-[32px]'
              )}
            >
              <Paragraph description={'No assets found.'} />
            </div>
          ) : (
            <div className={'flex flex-1 flex-col gap-[24px]'}>
              {renderFilterBar}
              <div className={'flex flex-1 flex-col'}>
                <Table.AgGrid.Wrapper>
                  <Table.SupportedAssets
                    data={_selectableAssets}
                    filterText={`${_quickFilterText}${_networkFilter ? ` network:${_networkFilter}` : ''}`}
                    maxSelection={_maxSelection}
                    selected={_selectedAssets}
                    allowSelectAll={false}
                    onSelectionChanged={(selected) => {
                      setSelectedAssets(selected)
                    }}
                  />
                </Table.AgGrid.Wrapper>
              </div>
            </div>
          )}
        </div>

        {_selectedAssets.length > 0 && (
          <Group shadow={true} className="p-[12px] flex-col gap-[16px]">
            <div className={'flex flex-row items-center gap-[16px]'}>
              <Avatar
                variant={'group'}
                size={'medium'}
                shape={'round'}
                outline={true}
                avatars={_selectedAssets.map((a) => {
                  return {
                    type: 'user',
                    image: getCryptoImageUrl(a.ticker),
                    fallbackImage: getCryptoImageUrl(),
                    onClick: () => {},
                  }
                })}
              />
              <div className={'flex flex-col'}>
                <Typography variant={'label-medium'}>
                  {_selectedAssets.length === 1
                    ? `${_selectedAssets[0].name}`
                    : `${_selectedAssets.length} asset${_selectedAssets.length > 1 ? 's' : ''} selected`}
                </Typography>
                {_selectedAssets.length >= _maxSelection &&
                  _maxSelection > 1 && (
                    <Typography variant={'paragraph-extrasmall'}>
                      {'No further assets can be selected.'}
                    </Typography>
                  )}
              </div>
            </div>
            <Typography
              variant={'label-small'}
              onClick={() => setSelectedAssets([])}
              className={twMerge(
                'w-auto hover:underline hover:cursor-pointer self-start'
              )}
            >
              {t('clear_selected')}
            </Typography>
          </Group>
        )}

        <Button.Dock>
          <Button.Basic
            testId={'btn_select'}
            hierarchy={'primary'}
            size={'medium'}
            label={t('select')}
            state={
              props.requireSelection &&
              _selectedAssets.length < props.requireSelection
                ? 'disabled'
                : 'default'
            }
            onClick={() => {
              props.onComplete(_selectedAssets)
              props.onClose()
            }}
          />
          <Button.Basic
            testId={'btn_close'}
            hierarchy={'tertiary'}
            size={'medium'}
            label={t('cancel')}
            onClick={props.onClose}
          />
        </Button.Dock>
      </div>
    </Modal>
  )
}
