import { useQuery } from '@tanstack/react-query'

import { getDefendants } from '@/api/broker/v2/defendants'

export const key = 'defendants'
export const useDefendants = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key],
    queryFn: () => getDefendants(),
  })

  return { defendants: data?.data, isLoading, refetchDefendants: refetch }
}
