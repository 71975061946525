import { t } from 'i18next'
import moment from 'moment'

export const TimeAgo = (timeA: string, timeB: string) => {
  const min = 60000
  const hour = min * 60
  const day = hour * 24
  const year = day * 365

  const tA = moment(timeA)
  const tB = moment(timeB)
  const msDiff = tB.diff(tA)

  if (msDiff < 1 * min) {
    // Less than 1 minute ago
    return t('just_now')
  } else if (msDiff < 60 * min) {
    // Less than 1 hour ago
    const minutesAgo = Math.floor(msDiff / min)
    return `${minutesAgo}m`
  } else if (msDiff < 24 * hour) {
    // Less than 1 day ago
    const hoursAgo = Math.floor(msDiff / hour)
    return `${hoursAgo}h`
  } else if (msDiff < 365 * day) {
    // Less than 1 year ago
    const daysAgo = Math.floor(msDiff / day)
    return `${daysAgo}d`
  } else {
    // More than 1 year ago
    const yearsAgo = Math.floor(msDiff / year)
    return `${yearsAgo}y`
  }
}
