import { ReactElement } from 'react'

import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'

import { RowTypes } from '../components/list.dropdown'
import BasicListView from './views/basic-list.view'

interface Props<T> extends ControllerProps<T> {
  items: ReactElement<RowTypes<T>>[]
}
const BasicListController = <T,>(props: Props<T>) => {
  return (
    <DropdownController {...props} defaultViewId={'BASIC_LIST'}>
      <BasicListView items={props.items} />
    </DropdownController>
  )
}
export default BasicListController
