import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from '@/components/avatar'
import { Button } from '@/components/button'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon.ts'
import { ICustodianSupportedAsset } from '@/types/custodian'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

export type SortBy = 'az' | 'latest' | 'value'
export type GroupBy = 'all' | 'active' | 'disabled'

export interface Props extends BaseTableProps {
  custodianDetailAssets: ICustodianSupportedAsset[]
  defaultGroupBy?: GroupBy
  defaultSortBy?: SortBy
}

export const SupportedAssetTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = 'az'
  const defaultGroupBy: GroupBy = 'all'
  const { t } = useTranslation()
  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const [_groupBy, setGroupBy] = useState<GroupBy>(defaultGroupBy)
  const [_assets, setAssets] = useState<ICustodianSupportedAsset[]>([])

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setAssets([...sort(group(props.custodianDetailAssets))])
  }, [props.custodianDetailAssets])

  // Monitor for sortBy changes + update
  useEffect(() => {
    setAssets([...sort(_assets)])
  }, [_sortBy])

  // Monitor for groupBy + filterBy changes + update
  useEffect(() => {
    setAssets([...sort(group(props.custodianDetailAssets))])
  }, [_groupBy])

  // Update our visibility and sort
  const onGroupChanged = (id: GroupBy) => setGroupBy(id)
  const onSortChanged = (id: SortBy) => setSortBy(id)

  // Sorts the provided data
  const sort = (
    assets: ICustodianSupportedAsset[]
  ): ICustodianSupportedAsset[] => {
    switch (_sortBy) {
      // case 'latest':
      //   return assets.sort(
      //     (a, b) =>
      //       new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      //   )
      case 'az':
        return assets.sort((a, b) =>
          // getAssetName(a).localeCompare(getAssetName(b))
          a?.ticker.localeCompare(b?.ticker)
        )
      // case 'value':
      //   return assets.sort(
      //     (a, b) => (b.price?.value ?? 0) - (a.price?.value ?? 0)
      //   )
      default:
        return assets
    }
  }

  // Group the provided data
  const group = (
    assets: ICustodianSupportedAsset[]
  ): ICustodianSupportedAsset[] => {
    // return assets.filter((a) =>
    //   _groupBy === 'all'
    //     ? a.category === 'DIGITAL'
    //     : a.category !== 'DIGITAL'
    // )
    return assets
  }

  // eslint-disable-next-line react/display-name
  const renderRows = (asset: ICustodianSupportedAsset, index: number) => {
    return (
      <Row key={index} onClick={() => {}}>
        <Cell.Icon>
          <Avatar
            type={'user'}
            image={getCryptoImageUrl(asset.ticker)}
            fallbackImage={getCryptoImageUrl()}
          />
        </Cell.Icon>
        <Cell.Text title={asset.ticker} className={'max-w-[20rem]'}>
          {asset.ticker}
        </Cell.Text>
        <Cell.Text>{asset.network}</Cell.Text>
        {/* <Cell.Text>{asset.seizure?.location?.country}</Cell.Text> */}
        <Cell.Text>{asset.ticker}</Cell.Text>
        <Cell.Text>{asset.contract_address || 'No data'}</Cell.Text>

        {/* <Cell.Checkbox>
          <Checkbox name={asset.id + 'checkbox'} id={asset?.id} />
        </Cell.Checkbox> */}
        <Cell.Button>
          <Button
            id={`btn_asset_ellip_drop_${asset.contract_address}`}
            layout={'icon'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'ellipsis',
            }}
            onClick={() => {
              // setDropdown({
              //   target: `btn_asset_ellip_drop_${asset.contract_address}`,
              //   controller: <Dropdown.Controllers.AssetActions asset={asset} />,
              // })
            }}
          />
        </Cell.Button>
      </Row>
    )
  }

  return (
    <BaseTable<ICustodianSupportedAsset, SortBy, null, GroupBy>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      pageData={false}
      leftController={{
        tabs: {
          type: 'button',
          size: 'small',
          onSelectionChanged: onGroupChanged,
          selectedId: _groupBy,
          values: [
            {
              id: 'all',
              text: t('all'),
            },
            {
              id: 'active',
              text: t('active'),
            },
            {
              id: 'disabled',
              text: t('disabled'),
            },
          ],
        },
      }}
      sort={{
        options: [
          {
            id: 'az',
            value: 'az',
            label: t('a-z'),
            icon: 'user-circle',
          },
          {
            id: 'latest',
            value: 'latest',
            label: t('latest'),
            icon: 'calendar',
            iconVariant: 'regular',
          },
          {
            id: 'value',
            value: 'value',
            label: t('value'),
            icon: 'coins',
            iconVariant: 'regular',
          },
        ],
        onSortByChanged: onSortChanged,
        defaultSelectedId: defaultSortBy,
      }}
      headers={[
        {
          label: t('asset_name'),
          colSpan: 2,
        },
        {
          label: t('network'),
        },
        {
          label: t('ticker'),
        },
        {
          label: t('contract_address'),
        },
        {
          label: '',
        },
      ]}
      data={_assets}
      onRenderRow={renderRows}
    />
  )
}
