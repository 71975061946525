import { twMerge } from 'tailwind-merge'

import { Group } from '@/components/group'
import { Typography } from '@/components/typography'

export interface Props {
  className?: string
  value: string | number // eg: 5
  valueDescriptor: string // eg: 'Files'
  description?: string // eg: '2 court documents'
  testId?: string
}

export const CardOverview = (props: Props) => {
  const valueLength = props.value.toString().length
  let groupWidth
  if (valueLength >= 12) {
    groupWidth = 'min-w-80'
  } else if (valueLength >= 8) {
    groupWidth = 'min-w-60'
  } else {
    groupWidth = 'min-w-44'
  }

  return (
    <Group
      className={twMerge(
        'flex flex-1 flex-grow-0 flex-col p-6 px-8 gap-2 items-center bg-white min-w-44',
        groupWidth,
        props.className
      )}
      shadow={true}
      testId={props.testId}
    >
      <div className={'flex items-center flex-col'}>
        <Typography variant="heading-small">{props.value}</Typography>
        <Typography
          variant="paragraph-medium"
          className={
            'text-center whitespace-nowrap overflow-hidden text-ellipsis'
          }
        >
          {props.valueDescriptor}
        </Typography>
      </div>
      {props.description && (
        <div className={'items-center'}>
          <Typography
            variant="paragraph-small"
            className={'text-gray-400 text-center'}
          >
            {props.description}
          </Typography>
        </div>
      )}
    </Group>
  )
}
