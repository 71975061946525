import { t } from 'i18next'

import Button from '@/components/button'
import { Divider } from '@/components/divider'
import { Typography } from '@/components/typography'

interface Props {
  title: string
  onClose: () => void
}
export const Header = (props: Props): JSX.Element => {
  return (
    <div className="flex flex-col w-full h-auto px-[24px] sticky top-0 bg-white z-10">
      <div className="flex justify-between items-center h-[72px] relative">
        <div className={'flex flex-1 items-center'}>
          <Typography variant="label-large">{props.title}</Typography>
        </div>

        <Button.Basic
          hierarchy={'secondary'}
          size={'small'}
          label={t('close')}
          onClick={props.onClose}
          className={'self-auto'}
        />
      </div>
      <Divider className="h-[0.0625rem]" />
    </div>
  )
}
