import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Navbar } from '@/components/navbar'

export interface Props {
  children: JSX.Element
  authenticated: boolean
  showNavigation: boolean
}
const GuardContent = (props: Props) => {
  const [_isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return props.authenticated && props.showNavigation ? (
    <Navbar>
      <div
        className={twMerge(
          'flex flex-col w-full',
          _isReady && 'guard_content_ready',
          _isReady ? 'opacity-100' : 'opacity-0'
        )}
      >
        {props.children}
      </div>
    </Navbar>
  ) : (
    <div
      className={twMerge(
        'flex flex-col w-full',
        _isReady && 'guard_content_ready',
        _isReady ? 'opacity-100' : 'opacity-0'
      )}
    >
      {props.children}
    </div>
  )
}
export default GuardContent
