import { useQuery } from '@tanstack/react-query'

import { getAllGroups } from '@/api'

export const key = 'groups'
export const useGroups = () => {
  const { isLoading, data } = useQuery({
    queryKey: [key],
    queryFn: () => getAllGroups(),
  })

  return { groups: data?.data, isLoading }
}
