import { t } from 'i18next'

import { getTotalCostFor } from '@/actions/create-asset/assetForm.ts'
import { Typography } from '@/components/typography'
import { formatValue } from '@/helpers/formatValue.ts'
import { useOrg } from '@/hooks/queries/useOrg.ts'
import { IAsset } from '@/types/asset'

interface Props {
  asset: IAsset
}

export const AssetTotalCost = ({ asset }: Props) => {
  const { org } = useOrg()
  const totalCost = getTotalCostFor(asset)
  const totalCostFormatted = formatValue(
    totalCost,
    asset.costs?.[0]?.currency ?? org?.preferred_currency,
    {
      notation: 'standard',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      compactDisplay: 'long',
      currencyDisplay: 'symbol',
    }
  )
  const costTextColour = totalCost > 0 ? 'text-red' : 'text-black'

  return (
    <div className={'flex items-center gap-2 self-stretch'}>
      <Typography variant="label-medium">{t('total_costs')}</Typography>
      <Typography variant="paragraph-medium" className={costTextColour}>
        {totalCostFormatted}
      </Typography>
    </div>
  )
}
