import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Divider } from '@/components/divider'
import { Typography } from '@/components/typography'

export interface Props {
  title?: ReactNode
  subtitle?: string
  description?: ReactNode
  dividerBelow?: boolean
  tooltips?: {
    title?: string
    subtitle?: string
    description?: string
  }
  classOverrides?: {
    base?: string
    title?: string
    subtitle?: string
    description?: string
  }
}

export const ParagraphSubtext = (props: Props) => {
  return (
    <span
      className={twMerge(
        'flex flex-col justify-center items-start max-w-[50rem]',
        props.classOverrides && props.classOverrides.base
      )}
    >
      {props.title && (
        <Typography
          variant={'label-large'}
          tooltip={props.tooltips?.title ?? undefined}
          spacers={['top', 'bottom']}
          className={props.classOverrides && props.classOverrides.title}
        >
          {props.title}
        </Typography>
      )}
      {props.subtitle && (
        <Typography
          variant={'label-small'}
          tooltip={props.tooltips?.subtitle ?? undefined}
          spacers={['bottom']}
          className={props.classOverrides && props.classOverrides.subtitle}
        >
          {props.subtitle}
        </Typography>
      )}
      {props.description && (
        <Typography
          variant={'paragraph-small'}
          tooltip={props.tooltips?.description ?? undefined}
          spacers={['bottom']}
          className={props.classOverrides && props.classOverrides.description}
        >
          {props.description}
        </Typography>
      )}
      {props.dividerBelow === true && (
        <Divider size="small" orientation="horizontal" />
      )}
    </span>
  )
}
