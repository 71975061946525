import { useQuery } from '@tanstack/react-query'

import { getSupportedAssets } from '@/api/org'

export const useSupportedAssets = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['supported-assets'],
    queryFn: () => getSupportedAssets(),
  })

  return { supportedAssets: data?.data, isLoading }
}
