import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { Table } from '@/components/table'
import { useDrawer } from '@/contexts/interface'
import { IUser } from '@/types/user'

export const SuperAdminTeamManagement: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const { data } = useLoaderData() as { data: IUser[] }
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    const _super_users = data?.filter((_u) => _u?.role === 'super') ?? []
    setUsers(_super_users)
  }, [data])

  const handleCreateNewUser = () => {
    setDrawer({
      id: 'NEW_SUPER_ADMIN_USER',
    })
  }

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col min-h-[calc(100vh-80px)]',
          'tablet:pt-0'
        )}
      >
        <div className={twMerge('flex flex-col p-8 pt-4', 'tablet:gap-8')}>
          <Paragraph
            title={t('team_management')}
            description={t('team_management_description')}
            spacerOverrides={{
              description: [],
            }}
          />
          <div className={'flex flex-row gap-2'}>
            <Button.Basic
              label={t('create-new-user')}
              icon={{
                name: 'plus',
              }}
              onClick={handleCreateNewUser}
              className="hidden tablet:flex"
            />
          </div>
        </div>

        {users && users.length > 0 ? (
          <div
            className={'flex flex-col px-8 pb-8'}
            data-testid="case-table-wrapper"
          >
            <Table team={users} />

            <Button.Fab
              label={t('create-new-user')}
              icon={{
                name: 'plus',
              }}
              onClick={handleCreateNewUser}
            />
          </div>
        ) : (
          <NoData description={t('no_organization_description')} />
        )}

        <Button.Fab
          label={t('create_new_user')}
          icon={{
            name: 'plus',
          }}
          onClick={() => {
            setDrawer({ id: 'NEW_SUPER_ADMIN_USER' })
          }}
        />
      </div>
    </>
  )
}
