import { Divider } from '@/components/divider'
import { Paragraph } from '@/components/paragraph'

import { StepMarker } from './step-marker'

interface Props {
  title: string
  description?: string
  titleTooltip?: string
  descriptionTooltip?: string
  iconName: string
  className?: string
}
export const Step = (props: Props) => {
  return (
    <div className={'flex flex-row w-full gap-[12px]'}>
      <div className={'flex flex-col items-center self-stretch'}>
        <StepMarker
          type={'icon'}
          name={props.iconName}
          className={props.className}
        />
        <Divider
          orientation={'vertical'}
          size={'medium'}
          className={'bg-black'}
        />
      </div>
      <div className={'flex flex-1 flex-col items-start justify-start pb-6'}>
        <Paragraph
          variant={'subtext'}
          subtitle={props.title}
          description={props.description}
          tooltips={{
            title: props.titleTooltip,
            description: props.descriptionTooltip,
          }}
          classOverrides={{
            subtitle: 'p-0',
            description: 'p-0',
          }}
        />
      </div>
    </div>
  )
}
