import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import List from '@/components/list'
import { ListItem } from '@/components/list-item'
import { useDropdown } from '@/contexts/interface'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { isDefined } from '@/helpers/isDefined.ts'
import { useSupportedAssets } from '@/hooks/queries/useSupportedAssets'
import { ISupportedAssetCustodian } from '@/types/custodian'

// import { ICustodian } from '@/types/custodian'
import { Avatar } from '../avatar'
import { Chip } from '../chip'
import { Paragraph } from '../paragraph'
import { Typography } from '../typography'

interface Props {
  onSelectionChanged: (isSelectionValid: boolean) => void
}
interface SupportedAsset {
  name: string
  ticker: string
  key: string
  contract_address: string
  native_asset_ticker: string
  native_asset_name: string
  network: string
  custodian: ISupportedAssetCustodian
}

export const AssetGenerateAddress = (props: Props) => {
  const { supportedAssets } = useSupportedAssets()
  const [_tokens, setTokens] = useState<SupportedAsset[]>([])
  const [_networkFilter, setNetworkFilter] = useState<string>()
  const [_networks, setNetworks] = useState<string[]>([])
  const [_selectedToken, setSelectedToken] = useState<
    SupportedAsset | undefined
  >()
  const [_selectedCustodian, setSelectedCustodian] =
    useState<ISupportedAssetCustodian>()
  const [_quickFilterText, setQuickFilterText] = useState<string>()
  const [_editSelection, setEditSelection] = useState<boolean>(true)
  const { setDropdown } = useDropdown()

  useEffect(() => {
    props.onSelectionChanged(
      isDefined(_selectedToken) && isDefined(_selectedCustodian)
    )
  }, [_selectedToken, _selectedCustodian])

  useEffect(() => {
    const networks: string[] = []
    const tokens: SupportedAsset[] = []

    if (Array.isArray(supportedAssets)) {
      for (const asset of supportedAssets) {
        if (asset.name) {
          // Add the network into our list
          if (asset.native_asset_name) {
            networks.push(asset.native_asset_name)
          }

          // Run through each custodian this asset supports
          for (const custodian of asset.custodians) {
            tokens.push({
              ...asset,
              key: custodian.supported_asset_key,
              custodian: custodian,
            })
          }
        }
      }
    }

    //
    setNetworks([...new Set(networks)])
    setTokens(tokens)
  }, [supportedAssets])

  const handleInput = (value: string) => {
    setQuickFilterText(value?.trim())
  }

  const renderRow = (
    data: SupportedAsset,
    size?: 'normal' | 'large',
    selected?: boolean,
    showDeselect?: boolean
  ) => {
    return (
      <div
        key={data.key}
        className={'flex flex-col w-full'}
        data-testid={'supported_asset_row'}
      >
        <div
          className={twMerge(
            'flex flex-row items-center gap-[16px] min-w-full',
            'mb-[8px] rounded-[6px] p-[8px]',
            'overflow-hidden',
            'hover:bg-gray-100 cursor-pointer',
            'transition-[background] duration-300',
            _selectedToken?.key === data.key &&
              'bg-[#2124271A] hover:bg-[#2124271A]',
            size === 'large' && 'py-[16px] pl-[16px] pr-[24px] gap-[24px]',
            selected &&
              'bg-[#212427] shadow-[0_16px_32px_0_rgba(40,38,35,0.15)] hover:bg-[#212427] cursor-default'
          )}
          onClick={() => {
            setSelectedToken(data)
            setSelectedCustodian(data.custodian)
            setEditSelection(false)
          }}
        >
          <div className={twMerge('overflow-hidden', 'pl-[8px]')}>
            <Avatar
              type={'user'}
              size={size === 'large' ? 'large' : 'medium'}
              image={getCryptoImageUrl(data?.ticker)}
              fallbackImage={getCryptoImageUrl()}
            />
          </div>
          <div
            className={twMerge(
              'flex flex-1 flex-col justify-center items-start whitespace-nowrap',
              'overflow-hidden'
            )}
          >
            <div
              className={
                'flex flex-col whitespace-nowrap mb-[8px] gap-[2px] overflow-hidden w-full'
              }
            >
              <Typography
                variant={'label-small'}
                className={twMerge(
                  'w-full whitespace-nowrap overflow-hidden text-ellipsis',
                  selected && 'text-[#F5F5F6]'
                )}
              >
                {data.name}
              </Typography>
              {data?.contract_address && (
                <Typography
                  variant={'paragraph-extrasmall'}
                  className={twMerge(
                    'text-[#585B5F] w-full whitespace-nowrap overflow-hidden text-ellipsis',
                    selected && 'text-[#BCBDBE]'
                  )}
                >
                  {data.contract_address}
                </Typography>
              )}
            </div>
            <div className={'flex flex-row gap-2 flex-wrap'}>
              <Chip
                label={data?.ticker ?? ''}
                type={'outline'}
                size={'small'}
                colour={'grey'}
                className={twMerge(
                  'min-w-0',
                  selected && 'border-[#45484B] bg-[#FFFFFF26]'
                )}
                textClassName={selected ? 'text-[#F5F5F6]' : ''}
              />
              {data?.native_asset_name && (
                <Chip
                  label={`${capitalizeFirstLetter(data.native_asset_name)} network`}
                  type={'outline'}
                  size={'small'}
                  colour={'grey'}
                  className={twMerge(
                    'min-w-0',
                    selected && 'border-[#45484B] bg-[#FFFFFF26]'
                  )}
                  textClassName={selected ? 'text-[#F5F5F6]' : ''}
                />
              )}
            </div>
          </div>
          {showDeselect && (
            <div>
              <Button.Shape
                layout={'icon'}
                // hierarchy={selected ? 'secondary-inverted' : 'secondary'}
                icon={{ name: 'close' }}
                size={'small'}
                onClick={() => setSelectedToken(undefined)}
                className={'self-auto hidden tablet:block'}
                withAttributes={{
                  type: 'button',
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderRows = useMemo(
    () =>
      _tokens
        .filter((token) =>
          _networkFilter ? token.native_asset_name === _networkFilter : true
        )
        .filter((token) => {
          if (_quickFilterText) {
            const lowerFilter = _quickFilterText.toLowerCase()
            if (_quickFilterText.length < 3) {
              return [
                token.name.toLowerCase(),
                token.ticker.toLowerCase(),
              ].some((val) => val.includes(lowerFilter))
            } else {
              return [
                token.name.toLowerCase(),
                token.ticker.toLowerCase(),
                token.contract_address?.toLowerCase(),
              ].some((val) => val.includes(lowerFilter))
            }
          }
          return true
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t) => renderRow(t)),
    [_tokens, _selectedToken, _networkFilter, _quickFilterText]
  )

  return (
    <div className={'flex flex-col gap-4 self-stretch'}>
      <input
        type={'hidden'}
        name={'token_contract_address'}
        value={_selectedToken?.contract_address}
      />
      <input
        type={'hidden'}
        name={'token_network'}
        value={_selectedToken?.native_asset_name}
      />
      <input
        type={'hidden'}
        name={'token_ticker'}
        value={_selectedToken?.ticker}
      />
      <input
        type={'hidden'}
        name={'supported_asset_key'}
        value={_selectedToken?.key}
      />
      <input
        type={'hidden'}
        name={'token_custodian_id'}
        value={_selectedCustodian?.id}
      />
      <input
        type={'hidden'}
        name={'token_custodian_name'}
        value={_selectedCustodian?.name}
      />

      {_selectedToken && !_editSelection ? (
        <div className={'flex flex-col gap-[8px]'}>
          {renderRow(_selectedToken, 'large', true, false)}
          <Typography
            variant={'label-small'}
            onClick={() => {
              setEditSelection(true)
              setQuickFilterText(undefined)
              setNetworkFilter(undefined)
            }}
            className={'cursor-pointer hover:underline self-start'}
          >
            {'Edit your token selection'}
          </Typography>
        </div>
      ) : (
        <div
          className={twMerge(
            'flex flex-col gap-[8px]',
            'h-[600px] max-h-[50vh]'
          )}
        >
          <div className={'w-full'}>
            <div className="flex grow gap-5 flex-col tablet:flex-row">
              <Input.Search
                icon={<Icon name="search" variant="solid" size="medium" />}
                size={'medium'}
                placeholder={t('search_for_tokens')}
                onChange={handleInput}
                className={'max-w-none flex-1'}
                testId={'input_token_filter'}
              />
              <Button.Basic
                id="btn_filter_by_network"
                hierarchy="secondary"
                label={
                  _networkFilter
                    ? capitalizeFirstLetter(_networkFilter)
                    : t('filter_by_network')
                }
                trailingIcon={{
                  name: 'chevron-down',
                }}
                className={'w-full tablet:w-auto'}
                onClick={() =>
                  setDropdown({
                    target: 'btn_filter_by_network',
                    controller: (
                      <Dropdown.Controllers.BasicList
                        closeOnItemClick
                        items={[
                          <ListItem
                            key={'all_networks'}
                            title={t('all_networks')}
                            className={'px-2'}
                            onClick={() => {
                              setNetworkFilter(undefined)
                            }}
                          />,
                          ..._networks
                            .sort((a, b) => a.localeCompare(b))
                            .map((n) => (
                              <ListItem
                                key={n}
                                title={capitalizeFirstLetter(n)}
                                className={'px-2'}
                                onClick={() => {
                                  setNetworkFilter(n)
                                }}
                              />
                            )),
                        ]}
                      />
                    ),
                  })
                }
              />
            </div>
          </div>
          <div className={twMerge('flex flex-1 flex-col overflow-hidden')}>
            {!_quickFilterText && !_networkFilter ? (
              <div
                className={twMerge(
                  'flex flex-1 justify-center animate-fade-in-up'
                )}
              >
                <Paragraph
                  variant={'subtext'}
                  title={'Search for tokens'}
                  description={'Enter a search term or filter by network.'}
                  classOverrides={{
                    title: twMerge('self-center items-center text-center'),
                    subtitle: twMerge('self-center items-center text-center'),
                    description: twMerge(
                      'self-center items-center text-center'
                    ),
                  }}
                />
              </div>
            ) : (
              <div
                className={twMerge(
                  'flex flex-col overflow-hidden h-full w-full',
                  'pb-[0px] pl-[0px] tablet:pl-[8px]'
                )}
              >
                <List
                  items={renderRows}
                  bufferCount={20}
                  className={'pr-[8px]'}
                />
              </div>
            )}
          </div>
          {_selectedToken && (
            <div className={'w-full max-w-full flex-col'}>
              {renderRow(_selectedToken, 'normal', true, true)}
              <Typography
                variant={'label-small'}
                onClick={() => {
                  setEditSelection(false)
                }}
                className={'cursor-pointer hover:underline self-start'}
              >
                {'Keep selection'}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
