import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IReportTemplate } from '@/types/reporting'

export interface IGenerateReportRequest {
  template: string
  operation_ids?: string[]
  defendant_ids?: string[]
  categories?: string[]
  tags?: string[]
  statuses?: string[]
  seizured_at?: {
    from: string // RFC3339 format
    to: string // RFC3339 format
  }
}

export const getReportTemplates = async (
  lang?: string
): Promise<BaseResponse<IReportTemplate[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get(`reports-templates${lang ? '?lang=' + lang : ''}`)
    .then((response) => {
      return response.data
    })
}

export const generateReport = async (
  data: IGenerateReportRequest
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client.post(`reports`, data).then((response) => {
    console.log('generateReport', response.data)
    return response.data
  })
}
