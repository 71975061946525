import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { IDefendant } from '@/types/defendants'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  defendant: IDefendant
}

export const EditDefendantDrawer: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_defendant] = useState<IDefendant>(props.defendant)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })

    // If we've just deleted the subject then we need to navigate to the root
    // subjects list instead
    if (_submitting === INTENTS.DELETE_DEFENDANT) {
      navigate(ROUTES.SUBJECT.INDEX)
    }
    props.onClose()
  }

  return (
    <Drawer
      title={t('subject_details')}
      subTitle={t('information')}
      description={t('new_subject_drawer_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'defendant-form'}
        buttons={[
          <Button.Basic
            key={'edit_defendant'}
            label={t('save_changes')}
            testId={'btn_edit_defendant'}
            state={
              _submitting === INTENTS.EDIT_DEFENDANT
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.EDIT_DEFENDANT,
            }}
          />,
          <Button.Basic
            key={'delete_defendant'}
            hierarchy={'tertiary'}
            label={t('delete')}
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.DELETE_DEFENDANT,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <Input.Text
          id="full_name"
          testId={'defendant_name'}
          name="name"
          label={t('full_name')}
          placeholder={t('full_name_hint')}
          icon={
            <Icon name={'user-tie-hair'} variant={'solid'} family={'sharp'} />
          }
          defaultValue={_defendant.name}
          hint={t('subject_name_hint')}
        />

        <Paragraph subTitle={t('contact_details')} />

        <Input.Email
          id="user_email"
          testId={'defendant_email'}
          name="email"
          label={t('email')}
          dynamicInput
          placeholder={t('email_address_placeholder')}
          icon={<Icon name={'envelope'} family={'sharp'} variant={'solid'} />}
          defaultValue={_defendant?.contact_details?.email}
        />

        <Input.Tel
          id="phone"
          testId={'defendant_phone'}
          name="phone"
          placeholder={t('mobile_number_placeholder')}
          icon={<Icon name={'phone'} family={'sharp'} variant={'solid'} />}
          label={t('mobile')}
          defaultValue={_defendant?.contact_details?.phone}
        />

        <input
          type="hidden"
          name="original_defendant"
          value={JSON.stringify(_defendant)}
        />
      </Form>
    </Drawer>
  )
}
