import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import { getAllCustodians } from '@/api/org'
import Button from '@/components/button'
import Card from '@/components/card'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { Switch } from '@/components/switch'
import { Table } from '@/components/table'
import {
  Currency,
  getCurrencyIcon,
  getCurrencyTitle,
} from '@/constants/currencies.ts'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { ICustodian } from '@/types/custodian'
import { IOrganisation } from '@/types/organisation'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  org?: IOrganisation
  onUpdated?: () => void
}

export const NewOrganizationDrawer = (props: Props) => {
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  const [_selectedCurrency, setSelectedCurrency] = useState<Currency>(
    Currency.USD
  )
  const [enable2FA, setEnable2FA] = useState<boolean>(true)
  const [_loading, setLoading] = useState<boolean>(true)
  const [_custodians, setCustodians] = useState<ICustodian[]>([])
  const [existingAddress, setExistingAddress] = useState(false)
  const [customTracker, setCustomTracker] = useState(false)
  const [escrowEnabled, setEscrowEnabled] = useState(false)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    // const organizationUrl = `/cases/${actionRes.data}`
    const organizationUrl = `/organization`
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(organizationUrl)
    props.onClose()
  }

  useEffect(() => {
    const asyncFunc = async () => {
      const { data } = await getAllCustodians()
      setCustodians(data ?? [])
      setLoading(false)
    }
    asyncFunc()
  }, [])

  return (
    <Drawer
      title={t('new_organization')}
      description={t('organization_detail_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      {_loading ? (
        <Card.PageState
          type={'loading'}
          title={t('fetching_data')}
          description={t('please_wait')}
        />
      ) : (
        <Form<string, INTENTS>
          type={'other'}
          testId={'create-organization-form'}
          buttons={[
            <Button.Basic
              key={'btn_create_organization'}
              label={t('create_organization')}
              state={
                _submitting === INTENTS.CREATE_ORGANIZATION
                  ? 'loading'
                  : _submitting !== null
                    ? 'disabled'
                    : 'default'
              }
              withAttributes={{
                type: 'submit',
                name: 'intent',
                value: INTENTS.CREATE_ORGANIZATION,
              }}
            />,
          ]}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          onException={onException}
          onFailed={clearSubmitting}
          onUnknownResponse={clearSubmitting}
          containerClassName="gap-[48px]"
        >
          <input
            type="hidden"
            name="custodian_obj"
            value={JSON.stringify(_custodians ?? [])}
          />

          <Group label={t('details')}>
            <Input.Text
              id="org_name"
              name="org_name"
              label={t('name')}
              autoComplete={'off'}
              placeholder={t('organization_name_placeholder')}
            />

            <Input.Country name={'country'} />

            <Input.Dropdown
              label={t('currency')}
              name={'currency'}
              {...{
                icon: (
                  <Icon
                    name={getCurrencyIcon(_selectedCurrency)}
                    variant={'solid'}
                    family={'sharp'}
                    size={'small'}
                  />
                ),
              }}
              value={_selectedCurrency.toString()}
              text={getCurrencyTitle(_selectedCurrency)}
              items={Object.values(Currency).map((item, index) => (
                <ListItem
                  key={`currency_${index}`}
                  title={getCurrencyTitle(item)}
                  value={item}
                  style={'normal'}
                  onClick={() => {
                    setSelectedCurrency(item)
                  }}
                  {...{
                    leading: (
                      <Icon
                        size={'small'}
                        family={'sharp'}
                        name={getCurrencyIcon(item)}
                        variant={'solid'}
                      />
                    ),
                  }}
                  trailing={
                    _selectedCurrency === item ? (
                      <Icon
                        size={'small'}
                        family={'sharp'}
                        name={'circle-check'}
                        variant={'solid'}
                      />
                    ) : undefined
                  }
                  className={'pl-2 pr-2'}
                />
              ))}
            />
          </Group>

          <Group label={t('owner')}>
            <Paragraph description={t('owner_description')} />

            <Input.Text
              id="name"
              name="_name"
              label={t('name')}
              autoComplete={'off'}
              hint={t('subject_hint')}
              placeholder="e.g. Emmett Brown"
              icon={<Icon name={'user'} family={'sharp'} variant={'solid'} />}
            />

            <Input.Email
              id="email"
              name="email"
              dynamicInput
              label={t('email_address')}
              placeholder={t('email_address_placeholder')}
              autoComplete="email"
              icon={
                <Icon name={'envelope'} family={'sharp'} variant={'solid'} />
              }
            />

            <div className="max-w-[23.4rem]">
              <ListItem
                title={t('2fa')}
                description={t('two_factor_authentication_description')}
                style={'bold'}
                trailing={
                  <Switch
                    name={'two_factor_enabled'}
                    enabled={enable2FA}
                    onChanged={setEnable2FA}
                    size={'large'}
                    testId="2fa"
                  />
                }
              />
            </div>

            <Input.Text
              id="security_code"
              name="security_code"
              label={t('secret_code')}
              autoComplete={'off'}
              placeholder={''}
              text=""
            />
          </Group>

          <Group label={'Configuration'} className="p-[24px] gap-[24px]">
            <ListItem
              title="Enable 'Existing Address' assets."
              description="Enables the creation of 'Existing Address' assets."
              style="bold"
              trailing={
                <Switch
                  name="existing_address"
                  size="large"
                  enabled={existingAddress}
                  testId="existing_address"
                  onChanged={setExistingAddress}
                />
              }
              className="max-w-[23.4rem]"
            />

            <ListItem
              title="Enable 'Custom Tracker' assets."
              description="Enables the creation of 'Custom Tracker' assets."
              style="bold"
              trailing={
                <Switch
                  name="custom_tracker"
                  size="large"
                  enabled={customTracker}
                  testId="custom_tracker"
                  onChanged={setCustomTracker}
                />
              }
              className="max-w-[23.4rem]"
            />

            <ListItem
              title="Enable Escrow."
              description="Enables Escrow for this organisation."
              style="bold"
              trailing={
                <Switch
                  name="escrow_enabled"
                  size="large"
                  enabled={escrowEnabled}
                  testId="escrow_enabled"
                  onChanged={setEscrowEnabled}
                />
              }
              className="max-w-[23.4rem]"
            />
          </Group>

          <Group label={t('custodians')}>
            <Paragraph
              description={t('custodians_description')}
              classOverrides={{ description: 'max-w-[40rem]' }}
            />
            <div className="border-black border">
              {_custodians && _custodians.length > 0 ? (
                <Table
                  className="max-h-[20rem] min-h-[20rem]"
                  assetCustodians={_custodians}
                  setCustodian={setCustodians}
                  name={'custodians'}
                />
              ) : (
                <NoData description={t('no_custodian_description')} />
              )}
            </div>
          </Group>
        </Form>
      )}
    </Drawer>
  )
}
