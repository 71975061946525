import { Button } from '@/components/button'
import { Icon } from '@/components/icon'
import { dateToString } from '@/helpers/dateToString.ts'
import { formatBytes } from '@/helpers/formatValue'
import { getFileIcon } from '@/helpers/getFileIcon.ts'
import { useFileMetadata } from '@/hooks/queries/useFileMetadata'
import { useWorkspaceMembers } from '@/hooks/queries/useWorkspaceMembers'
import { IFile } from '@/types/file'

import Cell from '../components/cell.table'
import Row from '../components/row.table'

interface FileRowProps {
  file: IFile
  index: number
  onDownloadFile: (file: IFile) => void
  onDeleteFile?: (file: IFile, targetId: string) => void
  isDeletingFile: boolean
  isDownloadingFile: boolean
}

export const FileRow: React.FC<FileRowProps> = ({
  file,
  index,
  onDownloadFile,
  onDeleteFile,
  isDeletingFile,
  isDownloadingFile,
}) => {
  const { metadata, isLoading } = useFileMetadata(file.key)
  const { members, isLoading: isMembersLoading } = useWorkspaceMembers()

  const uploadedBy =
    members.find((member) => member.user_id === file.created_by)?.user?.name ??
    '-'

  const deleteBtnId = 'file_row_delete_btn_' + index

  return (
    <Row key={`${file.name}_${index}`}>
      <Cell.Icon>
        <Icon name={getFileIcon(file)} variant={'solid'} size={'md'} />
      </Cell.Icon>
      <Cell.Text>{file.name}</Cell.Text>
      <Cell.Text align="center">
        {isLoading
          ? 'Loading...'
          : metadata?.metadata?.size
            ? formatBytes(metadata.metadata.size)
            : '-'}
      </Cell.Text>
      <Cell.Text align={'center'}>{dateToString(file.created_at)}</Cell.Text>
      <Cell.Text align="center">
        {' '}
        {isMembersLoading ? 'Loading...' : uploadedBy}
      </Cell.Text>
      <Cell.Button>
        <Button
          layout={'icon'}
          variant={'shape'}
          shape={'square'}
          size={'small'}
          hierarchy={'tertiary'}
          icon={{
            name: 'arrow-down-to-bracket',
          }}
          state={isDownloadingFile ? 'loading' : 'default'}
          onClick={() => {
            onDownloadFile(file)
          }}
        />
      </Cell.Button>
      <Cell.Button>
        {onDeleteFile && (
          <Button
            id={deleteBtnId}
            layout={'icon'}
            testId={'btn_file_row_delete'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'trash',
            }}
            state={isDeletingFile ? 'loading' : 'default'}
            onClick={() => {
              onDeleteFile(file, deleteBtnId)
            }}
          />
        )}
      </Cell.Button>
    </Row>
  )
}

export default FileRow
