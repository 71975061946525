import { twMerge } from 'tailwind-merge'

import { InputHint } from '../components/hint.input'
import { InputLabel } from '../components/label.input'

type Props = {
  state?: 'default' | 'disabled'
  label?: string
  hint?: string
  className?: string
  testId?: string
  textAreaClassName?: string
} & Omit<JSX.IntrinsicElements['textarea'], 'ref' | 'accept'>

export const TextareaInput = (props: Props) => {
  return (
    <div
      className={twMerge(
        'flex flex-col w-full max-w-[23.4375rem] gap-2',
        props.className
      )}
      data-testid={props.testId}
    >
      {props.label && <InputLabel size={'medium'} text={props.label} />}
      <textarea
        data-testid={'input_frame'}
        {...Object.fromEntries(
          Object.entries(props).filter(
            ([key]) =>
              !['state', 'label', 'hint', 'className', 'testId'].includes(key)
          )
        )}
        className={twMerge(
          'flex border-[1px] border-solid border-[#E1E1E2]',
          'min-h-[6rem]',
          'rounded-[6px] px-4 py-3 self-stretch ring-0',
          'transition-[border-color,background-color] ease-in-out duration-300',
          'text-[0.9375rem] font-medium text-[#212427] placeholder:text-[#909293]',
          'hover:bg-white hover:border-[#212427]',
          'focus:ring-0 focus:border-[#212427]',
          props.className,
          props.textAreaClassName
        )}
      />
      {props.hint && <InputHint style={'hint'} label={props.hint} />}
    </div>
  )
}
