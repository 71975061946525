import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import AssetIcon from '@/components/table/rows/assetIcon.tsx'
import { useAuth } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { useModal } from '@/contexts/interface'
import { useWorkspace } from '@/contexts/workspace'
import { getAssetTypeDescriptionForColumn } from '@/helpers/assetTypeDescription.ts'
import { useOrgUsers } from '@/hooks/queries/useOrgUsers'
import { ActionResponse } from '@/types/actions'
import { IAsset } from '@/types/asset'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  asset: IAsset
  onCancel?: () => void
}

export const ShareAssetModal = (props: Props) => {
  const { selectedWorkspace } = useWorkspace()
  const { user } = useAuth()
  const { users } = useOrgUsers(user?.organization_id ?? '')
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const { setModal } = useModal()

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    setModal({
      id: 'SHARE_ASSET_REQUESTED',
      invitedMembersCount: 1,
      invitedGuestsCount: 0,
      workspaceName: selectedWorkspace?.name ?? '',
    })
  }

  const onCancel = () => {
    props.onCancel && props.onCancel()
    props.onClose()
  }

  return (
    <Modal visible={props.visible} onClose={props.onClose}>
      <Form
        buttons={[
          <Button.Basic
            key={'btn_share'}
            label={t('share')}
            state={_submitting === INTENTS.SHARE_ASSET ? 'loading' : 'default'}
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.SHARE_ASSET,
            }}
          />,
          <Button.Basic
            key={'btn_cancel'}
            hierarchy={'tertiary'}
            size={'medium'}
            label={t('cancel')}
            onClick={onCancel}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={'flex p-6 flex-col items-start gap-6 w-full'}
      >
        <input type={'hidden'} name={'asset_id'} value={props.asset.id} />
        <div className={'flex items-center gap-6 self-stretch'}>
          <AssetIcon asset={props.asset} />
          <Paragraph
            title={
              props.asset?.external_ref ??
              getAssetTypeDescriptionForColumn(props.asset)
            }
            subTitle={props.asset.platform_id ?? '-'}
            spacerOverrides={{
              title: [],
              subTitle: [],
            }}
          />
        </div>
        <Input.Suggest
          label={t('email')}
          name={'email'}
          addValueAsEntry={true}
          // onItemSelectionChange={onUserSelected}
          items={
            users
              ? users
                  .filter((u) => !user || u.id !== user.id)
                  .map((u) => (
                    <ListItem
                      key={`user_${u.id}`}
                      leading={
                        <Avatar
                          type={'monogram'}
                          value={u.name}
                          size={'small'}
                        />
                      }
                      title={u.name}
                      value={u.email}
                      data={u}
                      className={'pl-0 pr-0 tablet:pl-2 tablet:pr-2'}
                    />
                  ))
              : []
          }
          className={'max-w-none'}
        />
        <Input.TextArea
          label={t('message')}
          name={'message'}
          placeholder={t('add_note_for_recipient')}
          className={'max-w-none'}
        />
      </Form>
    </Modal>
  )
}
