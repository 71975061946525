import { useParams } from 'react-router-dom'

import { EscrowDetail } from '@/components/escrow/EscrowDetail'

export const EscrowDetailPage = () => {
  const params = useParams()

  if (!params.escrow_id) {
    return null
  }

  return <EscrowDetail escrowId={params.escrow_id} />
}
