import { t } from 'i18next'

import { createSuperAdminUser } from '@/api'
import { REGEX } from '@/constants/regex'
import { SECURITY_CODE } from '@/constants/securit-code'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'

export const CreateSuperAdminUser = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('name')?.toString()
  const email = formData.get('email')?.toString()
  const phone = formData.get('phone')?.toString()
  // const twoFactorEnabled = formData.get('two_factor_enabled')?.toString()

  // Check basic details
  if (IsNot(name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_name_provided'),
      },
    ])
  } else if (IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('invalid_email_provided'),
      },
    ])
  }

  const user = {
    name,
    email,
    phone,
    language: 'en',
  }

  try {
    await createSuperAdminUser(user, SECURITY_CODE)
  } catch (error) {
    const message = ExtractErrorFrom(error) ?? t('failed_to_load_organization')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }

  return {
    error: false,
    message: t('new_user_added_successfully'),
    completion: 'COMPLETE',
    data: null,
  }
}
