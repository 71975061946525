import { useQuery } from '@tanstack/react-query'

import { getAllCustodians } from '@/api/org'
import { IUserRole } from '@/types/user'

export const useAdminCustodians = (role?: IUserRole) => {
  const { isLoading, data } = useQuery({
    queryKey: ['admin-custodians'],
    queryFn: () => getAllCustodians(),
    enabled: role === 'super',
  })

  return { custodians: data?.data, isLoading }
}
