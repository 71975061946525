import {
  ChangeEvent,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import Dropdown from '@/components/dropdown'
import { DropdownAlignment } from '@/components/dropdown/components/context.dropdown'
import { RowTypes } from '@/components/dropdown/components/list.dropdown'
import { Icon, IconProps } from '@/components/icon'
import { ListItem, Props as ListItemProps } from '@/components/list-item'
import { useDropdown } from '@/contexts/interface'
import { useId } from '@/hooks/useId'
import { useWindowSize } from '@/hooks/useWindowSize'

import { Props as InputHintProps } from '../components/hint.input'
import { Props as InputLabelProps } from '../components/label.input'
import { DefaultInput } from './default.input'

interface Props<T> {
  type?: 'text' | 'email' | 'number'
  label?: string | ReactElement<InputLabelProps>
  hint?: string | ReactElement<InputHintProps>
  name?: string
  // value?: string
  defaultValue?: string
  forceDefaultValue?: boolean
  icon?: ReactElement<IconProps>
  state?: 'default' | 'disabled'
  suffix?: string
  items?: ReactElement<RowTypes<T>>[]
  placeholder?: string
  addValueAsEntry?: boolean
  className?: string
  fixedHeader?: ReactNode
  fixedFooter?: ReactNode
  onFilteredItemsChanged?: (values: string[]) => void
  onValueChanged?: (value: string) => void
  onItemSelectionChange?: (item: RowTypes<T> | null) => void
  testId?: string
}

export const InputSuggestion = <T,>(props: Props<T>) => {
  const [_value, setValue] = useState<string>(props.defaultValue ?? '')
  const [_text, setText] = useState<string>('')
  const [_hasItemSelected, setHasItemSelected] = useState<boolean>(false)
  const [_dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [_dropdownAlignment, setDropdownAlignment] =
    useState<DropdownAlignment>()
  const { setDropdown, updateDropdownController } = useDropdown()
  const { id } = useId()
  const { isMobile } = useWindowSize()
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownId = useRef<string>()
  const hasSetDefaultValue = useRef<boolean>(false)

  useEffect(() => {
    setDefaultValue()
  }, [props.defaultValue])

  useEffect(() => {
    if (!hasSetDefaultValue.current && props.items) {
      setDefaultValue()
    }
  }, [props.items])

  const setDefaultValue = () => {
    if (props.defaultValue) {
      if (props.forceDefaultValue === true) {
        setValue(props.defaultValue)
        setText(props.defaultValue)
        setHasItemSelected(true)
        hasSetDefaultValue.current = true
        return
      }

      for (const v of props.items ?? []) {
        if (
          v.type === ListItem &&
          'title' in v.props &&
          'value' in v.props &&
          v.props.value === props.defaultValue
        ) {
          setValue(v.props.value ?? '')
          setText(v.props.title ?? '')
          setHasItemSelected(true)
          props.onItemSelectionChange &&
            props.onItemSelectionChange(v.props ?? '')
          hasSetDefaultValue.current = true
          return
        }
      }
    }
  }

  const areHintsEqual = useMemo(() => {
    return (
      prevHint: Props<T>['hint'],
      currHint: Props<T>['hint']
    ): boolean => {
      if (typeof prevHint === 'string' && typeof currHint === 'string') {
        return prevHint === currHint
      } else if (isValidElement(prevHint) && isValidElement(currHint)) {
        const prevHintProps = prevHint.props || {}
        const currentHintProps = currHint.props || {}
        return (
          // prevHint === currentHint &&
          prevHintProps.label === currentHintProps.label &&
          prevHintProps.style === currentHintProps.style
        )
      }
      return false
    }
  }, [])

  const areItemsEqual = useMemo(() => {
    return (
      prevItems: Props<T>['items'],
      currItems: Props<T>['items']
    ): boolean => {
      if (prevItems === undefined && currItems === undefined) {
        return true
      }

      if (prevItems === undefined || currItems === undefined) {
        return false
      }

      if (prevItems.length !== currItems.length) {
        return false
      }

      for (let i = 0; i < prevItems.length; i++) {
        if (
          prevItems[i].type === 'ListItem' &&
          currItems[i].type === 'ListItem'
        ) {
          const pItem = prevItems[i] as ReactElement<ListItemProps<T>>
          const cItem = currItems[i] as ReactElement<ListItemProps<T>>
          if (
            pItem.props.title !== cItem.props.title ||
            pItem.props.description !== cItem.props.description
          ) {
            return false
          }
        }
      }

      return true
    }
  }, [])

  useEffect(() => {
    if (dropdownId.current) {
      updateDropdownController(
        dropdownId.current,
        renderController(_value, _text)
      )
    }
  }, [areItemsEqual, areHintsEqual])

  const onInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setText(e.target.value)
    setHasItemSelected(false)
    props.onItemSelectionChange && props.onItemSelectionChange(null)
    props.onValueChanged && props.onValueChanged(e.target.value)
    if (dropdownId.current) {
      updateDropdownController(
        dropdownId.current,
        renderController(e.target.value, e.target.value)
      )
    }
  }

  const onMobileValueChange = (value: string) => {
    setValue(value)
    setText(value)
    setHasItemSelected(false)
    props.onItemSelectionChange && props.onItemSelectionChange(null)
    props.onValueChanged && props.onValueChanged(value)
  }

  const renderController = (value: string, text: string) => {
    return (
      <Dropdown.Controllers.Suggestion
        items={props.items} // This is the full list of filter'able items
        onItemClicked={onItemClicked}
        closeOnItemClick={true} // Only applies to non-mobile
        inputValue={value} // Value will not auto-update as per usual as we run through a state
        inputText={text}
        hint={props.hint}
        label={props.label}
        fixedHeader={props.fixedHeader}
        fixedFooter={props.fixedFooter}
        addValueAsEntry={props.addValueAsEntry}
        onFilteredItemsChanged={props.onFilteredItemsChanged}
        onValueChanged={onMobileValueChange}
      />
    )
  }

  const showDropdown = () => {
    if (props.state === 'disabled' || props.state?.toString() === 'readonly') {
      return
    }

    dropdownId.current = setDropdown({
      target: id('input'),
      type: 'input',
      autoSize: ['tablet', 'desktop'],
      onDropdownToggled: (open, alignment) => {
        setDropdownOpen(open)
        setDropdownAlignment(alignment)
      },
      controller: renderController(_value, _text),
    })
  }

  const onItemClicked = (item: RowTypes<T>) => {
    if ('title' in item && item.title && 'value' in item && item.value) {
      setValue(item.value)
      setText(item.title)
      setHasItemSelected(true)
      props.onItemSelectionChange && props.onItemSelectionChange(item)
    }
  }

  const onInputFocus = () => {
    if (isMobile && inputRef.current) {
      inputRef.current.blur()
    }
  }

  return (
    <DefaultInput
      setRef={inputRef}
      id={id('input')}
      testId={props.testId}
      name={props.name}
      type={props.type ?? 'text'}
      hint={props.hint}
      label={props.label}
      icon={props.icon}
      suffix={props.suffix}
      state={props.state}
      onClick={showDropdown}
      text={_text}
      value={_value}
      onChange={onInputValueChange}
      onFocus={onInputFocus}
      className={props.className}
      placeholder={props.placeholder}
      elementClassName={
        _dropdownOpen &&
        !isMobile &&
        ((props.items ?? []).length > 0 || props.addValueAsEntry)
          ? _dropdownAlignment === 'ABOVE'
            ? 'border-[#212427] border-[0.125rem]'
            : 'border-[#212427] border-[0.125rem]'
          : ''
      }
      trailingIcon={
        <Icon
          name={_hasItemSelected ? 'circle-check' : 'search'}
          variant={'solid'}
          family={'sharp'}
        />
      }
    />
  )
}
