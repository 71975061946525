import { useQuery } from '@tanstack/react-query'

import { getOrganisation } from '@/api/org'

export const useOrg = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['org'],
    queryFn: () => getOrganisation(),
  })

  return { org: data?.data, isLoading }
}
