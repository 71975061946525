import { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/button'
import Input from '@/components/input'
import { InputHint } from '@/components/input/components/hint.input'
import { PageBack } from '@/components/page-back'
import { Paragraph } from '@/components/paragraph'

import { INTENTS } from '../index/intents'

interface State {
  old_password: string
  new_password: string
  confirm_password: string
  isPasswordStrong: boolean
  isPasswordMatch: boolean
}

type Action =
  | { type: 'SET_FIELD'; field: keyof State; value: string }
  | { type: 'VALIDATE_PASSWORD'; isStrong: boolean }
  | { type: 'CHECK_PASSWORD_MATCH' }

const initialState = {
  old_password: '',
  new_password: '',
  confirm_password: '',
  isPasswordStrong: false,
  isPasswordMatch: false,
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value }
    case 'VALIDATE_PASSWORD':
      return { ...state, isPasswordStrong: action.isStrong }
    case 'CHECK_PASSWORD_MATCH':
      return {
        ...state,
        isPasswordMatch: state.new_password === state.confirm_password,
      }
    default:
      return state
  }
}

// Do not modify this code without get in touch with the backend team first.
function isPasswordValid(pass: string) {
  let upp = false,
    low = false,
    num = false,
    sym = false
  let tot = 0

  for (const char of pass) {
    if (/[A-Z]/.test(char)) {
      upp = true
      tot++
    } else if (/[a-z]/.test(char)) {
      low = true
      tot++
    } else if (/[0-9]/.test(char)) {
      num = true
      tot++
    } else if (/[\p{P}\p{S}]/u.test(char)) {
      sym = true
      tot++
    } else {
      return false
    }
  }

  if (!upp || !low || !num || !sym || tot < 12) {
    return false
  }

  return true
}

export const SetNewPassword: React.FC = () => {
  const { t } = useTranslation()
  const fetcher = useFetcher()

  const [state, dispatch] = useReducer(reducer, initialState)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    dispatch({ type: 'SET_FIELD', field: name as keyof State, value })

    if (name === 'new_password') {
      // Check if password contains 12 characters with at least 1 upper case letter, 1 lower case letter, 1 symbol character and 1 number
      dispatch({ type: 'CHECK_PASSWORD_MATCH' })
      dispatch({
        type: 'VALIDATE_PASSWORD',
        isStrong: isPasswordValid(value),
      })
    }

    if (name === 'confirm_password') dispatch({ type: 'CHECK_PASSWORD_MATCH' })
  }

  const from = new URLSearchParams(window.location.search).get('from')

  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-[45rem] overflow-x-hidden flex flex-col">
        <PageBack url={`/profile?from=${from}`} />
        <div className={'flex flex-col p-6 gap-8'}>
          <Paragraph
            title={t('set_new_password')}
            description={t('set_new_password_description')}
            spacerOverrides={{
              description: [],
            }}
          />
          <InputHint style={'message'} label={t('set_new_password_hint')} />
          <fetcher.Form method="POST" className="flex flex-col gap-8">
            <Input.Password
              label={t('enter_old_password')}
              id="old_password"
              testId="old_password"
              name="old_password"
              onChange={handleInputChange}
            />
            <div>
              <Input.Password
                label={t('enter_new_password')}
                id="new_password"
                testId="new_password"
                name="new_password"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Input.Password
                label={t('re_enter_new_password')}
                id="confirm_password"
                testId="confirm_password"
                name="confirm_password"
                onChange={handleInputChange}
                hint={
                  state.new_password.length > 0 &&
                  state.confirm_password.length > 0 ? (
                    !state.isPasswordMatch ? (
                      <InputHint
                        testId={'hint_password_mismatch'}
                        style={'negative'}
                        label={t('password_not_match')}
                      />
                    ) : !state.isPasswordStrong ? (
                      <InputHint
                        testId={'hint_password_invalid'}
                        style={'negative'}
                        label={t('set_new_password_hint')}
                      />
                    ) : undefined
                  ) : undefined
                }
              />
            </div>
            <div>
              <Button
                testId="update-password-button"
                className={twMerge(
                  !state.isPasswordStrong || !state.isPasswordMatch
                    ? 'opacity-75 pointer-events-none'
                    : ''
                )}
                label={t('set_new_password')}
                state={fetcher.state === 'submitting' ? 'loading' : 'default'}
                withAttributes={{
                  name: 'intent',
                  type: 'submit',
                  value: INTENTS.SET_NEW_PASSWORD,
                }}
              />
            </div>
          </fetcher.Form>
        </div>
      </div>
    </div>
  )
}
