import { useTranslation } from 'react-i18next'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { Avatar } from '@/components/avatar'
import { Chip } from '@/components/chip'
import { Icon } from '@/components/icon'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon.ts'
import { IHealth } from '@/types/health'

export const Health: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: healthDataArray } = useLoaderData() as { data: IHealth[] }
  // const [assets] = useState<IAsset[]>(data)

  const handleNavigateToMonitorDetails = (health: IHealth) =>
    navigate(ROUTES.HEALTH.DETAIL.replace(':health_id', health?.id), {
      state: { health },
    })

  if (healthDataArray.length < 1)
    return <NoData description="no_health_description" />

  return (
    <>
      <div className="flex flex-col p-8 pt-4 gap-16 desktop:px-8 desktop:py-4">
        <Paragraph
          title={t('monitor')}
          subTitle="Monday, Jan 15, 2024, 12:13:06"
          description={t('monitor_description')}
          spacerOverrides={{ description: [] }}
        />
      </div>

      <div data-testid="health-list">
        {healthDataArray.map((item: IHealth, i: number) => (
          <div
            className="flex items-center w-full tablet:w-[42rem] my-[2rem] cursor-pointer"
            key={i}
            onClick={() => handleNavigateToMonitorDetails(item)}
            data-testid="health-item"
          >
            <div className="flex flex-[6] justify-cener items-center gap-[1rem] px-8">
              <Avatar
                type={'user'}
                size="small"
                image={getCryptoImageUrl(item.service)}
                fallbackImage={getCryptoImageUrl()}
              />
              <Typography variant="paragraph-medium">{item.service}</Typography>
            </div>
            <div className="flex flex-[2]">
              <Chip
                label={item.error ? 'Interruped' : 'Operational'}
                colour={item?.error ? 'yellow' : 'green'}
              />
            </div>
            <div className="flex flex-1 px-[1rem] tablet:px-0">
              <Icon name={'arrow-right'} size={'sm'} />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
