import { t } from 'i18next'
import { useEffect, useState } from 'react'

import Button from '@/components/button'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { IAssetNote } from '@/types/asset'

import { AssetNote } from './asset-note'

interface Props {
  assetId: string
  notes?: IAssetNote[]
  readOnly?: boolean
}

const extractDescription = (notes?: IAssetNote[]) => {
  return notes?.[0] ?? null
}

const extractNotes = (notes?: IAssetNote[]) => {
  return (notes ?? [])
    .slice(1)
    .sort(
      (a, b) =>
        new Date(b.created_at ?? '0').getTime() -
        new Date(a.created_at ?? '0').getTime()
    )
}

export const AssetDescription = (props: Props) => {
  const { hasPolicy } = useAuth()
  const [_description, setDescription] = useState<IAssetNote | null>(
    extractDescription(props.notes)
  )
  const [_notes, setNotes] = useState<IAssetNote[]>(extractNotes(props.notes))
  const [_isCreatingNote, setIsCreatingNote] = useState<boolean>(false)

  useEffect(() => {
    setDescription(extractDescription(props.notes))
    setNotes(extractNotes(props.notes))
  }, [props.notes])

  const createNewNote = () => {
    setIsCreatingNote(true)
  }

  const cancelNewNote = () => {
    setIsCreatingNote(false)
  }

  const descriptionContent = (_description?.content ?? '').trim()

  return (
    <div className={'flex flex-col w-full '}>
      <div className={'flex flex-col w-full '}>
        {_description && descriptionContent.length > 0 && (
          <Typography variant="paragraph-medium" className={'pt-[10px]'}>
            {descriptionContent}
          </Typography>
        )}

        {_isCreatingNote ? (
          <AssetNote assetId={props.assetId} onCompleteEdit={cancelNewNote} />
        ) : (
          hasPolicy('CUSTODY.MANAGE_NOTE') &&
          props.readOnly !== true && (
            <Button.Basic
              hierarchy={'secondary'}
              size={'small'}
              label={t('add_note')}
              icon={{
                name: 'notes',
                variant: 'solid',
                family: 'sharp',
              }}
              onClick={createNewNote}
              className={descriptionContent.length > 0 ? 'mt-6' : ''}
            />
          )
        )}
      </div>

      {_notes.length > 0 && (
        <div className={'flex flex-col w-full pt-6'}>
          {_notes.map((note, index, arr) => (
            <AssetNote
              key={`asset_note_${index}`}
              assetId={props.assetId}
              note={note}
              readonly={props.readOnly}
              className={index < arr.length - 1 ? 'pb-[24px]' : ''}
            />
          ))}
        </div>
      )}
    </div>
  )
}
