import { t } from 'i18next'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import Page from '@/components/page'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { AuthManager } from '@/contexts/auth'
import { BannerManager } from '@/contexts/banner'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

import { Template } from '../_components/template'

export const TwoFactor = () => {
  const params = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const autoSubmit = useRef<boolean>(true)
  const { state } = useLocation()

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => {
    BannerManager.hideBanners('all')
    setSubmitting(intent)
  }

  // On form exception
  const onException = (actionRes: ActionResponse<IUser>) => {
    clearSubmitting()
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: '2FA error',
      description: actionRes.message ?? t('something_went_wrong'),
    })
  }

  const onFailed = () => {
    // If we error at all then we need to stop auto submit
    // so we rely on the user typing in their code
    autoSubmit.current = false
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<IUser>) => {
    if (actionRes.data?.role === 'super') {
      navigate(ROUTES.ADMIN.ORGANIZATION.INDEX)
    } else if (
      (actionRes.data?.escrow_ids ?? []).length > 0 &&
      !actionRes.data?.organization_id
    ) {
      navigate(ROUTES.ESCROW.INDEX)
    } else {
      navigate(`${ROUTES.AUTH.WORKSPACE}?${params.toString()}`)
    }
  }

  const onAutoSubmit = () => {
    if (autoSubmit.current === true) {
      setTimeout(() => {
        document.getElementById('btn_continue')?.click()
      }, 100)
    }
  }

  const logout = () => {
    AuthManager.logout()
  }

  return (
    <Template>
      <Form<IUser, INTENTS>
        type={'other'}
        buttons={[
          <Button.Basic
            key={`btn_continue`}
            id={'btn_continue'}
            hierarchy={'primary'}
            width={'flex'}
            label={t('continue')}
            state={_submitting !== null ? 'loading' : 'default'}
            trailingIcon={{
              name: 'arrow-right',
              family: 'sharp',
              variant: 'solid',
            }}
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.VERIFY_PASSCODE,
            }}
          />,
        ]}
        hideErrorSummary={true}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onFailed={onFailed}
        onException={onException}
        onUnknownResponse={clearSubmitting}
        onComplete={clearSubmitting}
        className={
          'flex w-full max-w-[23.4375rem] flex-col items-start gap-[24px] p-0'
        }
      >
        <input type="hidden" value={state.token} name="token" id="token" />
        <Page.Navigation className={'p-0 tablet:p-0'} backOnClick={logout} />
        <Typography variant={'heading-small'} spacers={[]}>
          {t('sign_in')}
        </Typography>

        <Input.Pin
          length={6}
          label={t('check_google_authenticator')}
          name={'passcode'}
          autoComplete={'off'}
          onComplete={onAutoSubmit}
        />
      </Form>
    </Template>
  )
}
