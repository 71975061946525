import { t } from 'i18next'
import moment from 'moment'

import {
  findLastStatus,
  findLastValuation,
  getCostsFromForm,
} from '@/actions/create-asset/assetForm.ts'
import { updateAsset } from '@/api'
import { Currency } from '@/constants/currencies.ts'
import { REGEX } from '@/constants/regex.ts'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import {
  assetRulesCanEditDefendant,
  getSerialMissingErrorMsg,
  isSerialRequired,
} from '@/helpers/assetRules.ts'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test.ts'
import { key as QueryAssetKey } from '@/hooks/queries/useAsset'
import { ActionResponse } from '@/types/actions'
import {
  AssetCategory,
  IAsset,
  IAssetStatus,
  IAssetStatusName,
  IAssetValuation,
} from '@/types/asset'

export const EditAsset = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const asset_original = formData.get('asset_original')?.toString()
  const asset_manufacturer = formData.get('asset_manufacturer')?.toString()
  const asset_model = formData.get('asset_model')?.toString()
  const asset_country = formData.get('asset_country')?.toString()
  const asset_address = formData.get('asset_address')?.toString()
  const asset_defendant = formData.get('asset_defendant')?.toString()
  const asset_status = formData.get('asset_status')?.toString()
  const asset_status_at = formData.get('asset_status_at')?.toString()
  const asset_notes = formData.get('asset_notes')?.toString()
  const asset_total_value_currency = formData
    .get('asset_total_value_currency')
    ?.toString()
  const asset_total_value = formData.get('asset_total_value')?.toString()
  const asset_identifier = formData.get('asset_identifier')?.toString()?.trim()

  const asset_balance = formData.get('asset_balance')?.toString()

  const external_ref = formData.get('external_ref')?.toString()

  // If we have no original asset object then lets fail out
  if (!asset_original) {
    throw new Error(t('invalid_asset_object'))
  }

  // Parse out the original asset object as we'll use that as our base
  const originalAsset: IAsset = JSON.parse(asset_original)
  const newAsset: IAsset = JSON.parse(asset_original)

  // Add in the specific values
  if (newAsset.category === 'DIGITAL') {
    // Digital Asset
    if (newAsset.is_self_managed) {
      // Validate the asset balance value
      if (Number(asset_balance ?? 0) < 0) {
        return FormErrorsManager.addErrors([
          {
            fieldName: 'asset_balance',
            error: t('balance_less_than_0'),
          },
        ])
      }

      newAsset.amount = Number(asset_balance ?? 0)
    }
  } else if (newAsset.category === 'FIAT_CASH') {
    // Fiat Cash
    newAsset.amount = Number(asset_total_value ?? 0)
  } else if (newAsset.category === AssetCategory.PERSONAL_PROPERTY) {
    // High value property
    newAsset.serial_number = asset_identifier
  } else if (newAsset.category === 'REAL_ESTATE') {
    // Real estate
    newAsset.plot_number = asset_identifier
    newAsset.location = {
      address: asset_address,
      country: asset_country,
    }
  } else if (newAsset.category === 'VEHICLE') {
    // Vehicle
    newAsset.vin = asset_identifier
    newAsset.make = asset_manufacturer
    newAsset.model = asset_model
  }

  // Handle the status and log if its changed
  const lastStatus: IAssetStatus | null = findLastStatus(newAsset.statuses)
  if (!lastStatus || lastStatus.name !== asset_status) {
    // The asset either doesn't have a status at the moment (unlikely)
    // or the latest status we currently have for the asset is different
    // to the status the user has chosen on the form
    newAsset.statuses.push({
      id: '',
      name: (asset_status as IAssetStatusName) ?? 'PENDING',
      created_at: new Date().toISOString(),
      happened_at: asset_status_at
        ? new Date(asset_status_at).toISOString()
        : lastStatus?.happened_at,
    })
  } else if (asset_status_at) {
    if (!moment(lastStatus.happened_at).isSame(asset_status_at, 'D')) {
      const formattedAssetStatusAt = new Date(asset_status_at).toISOString()
      newAsset.statuses.push({
        id: '',
        name: (asset_status as IAssetStatusName) ?? 'PENDING',
        last_updated_at: new Date().toISOString(),
        created_at: lastStatus.created_at,
        happened_at: formattedAssetStatusAt,
      })
    }
  }

  // Handle valuations
  if ('valuations' in newAsset) {
    const latestValuation: IAssetValuation | null = findLastValuation(
      newAsset.valuations
    )

    if (
      !latestValuation ||
      (asset_total_value && latestValuation.value !== Number(asset_total_value))
    ) {
      newAsset.valuations?.push({
        currency: asset_total_value_currency as Currency,
        value: Number(asset_total_value) ?? 0,
      })
    }
  }

  // Handle notes
  const enteredNotes =
    asset_notes && asset_notes.trim().length > 0 ? asset_notes : ' '
  if (newAsset.notes && newAsset.notes.length > 0) {
    // If we already have notes then we want to make sure we're
    // only ever editing the first note [0] as we use this one as the
    // asset description.
    newAsset.notes[0] = {
      ...newAsset.notes[0],
      content: enteredNotes,
    }
  } else {
    // Otherwise we don't have any notes so we can simply add the new
    // note in as the sole note.
    newAsset.notes = [
      {
        content: enteredNotes,
      },
    ]
  }

  if (IsNot(external_ref, REGEX.IS_ASSET_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'external_ref',
        error: t('missing_identifier'),
      },
    ])
  }

  if (isSerialRequired(originalAsset.category) && !asset_identifier) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'asset_identifier',
        error: getSerialMissingErrorMsg(originalAsset.category),
      },
    ])
  }

  // Note: External ref is named 'Asset Name' in the UI
  newAsset.external_ref = external_ref
  newAsset.costs = getCostsFromForm(formData)

  if (assetRulesCanEditDefendant(originalAsset)) {
    if (IsNot(asset_defendant, REGEX.IS_CASE_NAME)) {
      return FormErrorsManager.addErrors([
        {
          fieldName: 'asset_defendant',
          error: t('subject_must_be_provided'),
        },
      ])
    }
    newAsset.defendant_id = asset_defendant
  }

  if ((Number(asset_total_value) ?? 0) < 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'asset_total_value',
        error: t('total_value_value_must_be_positive'),
      },
    ])
  }

  const res = await updateAsset(newAsset)

  // Check response
  if (res.error) {
    throw new Error(ExtractErrorFrom(res) ?? t('something_went_wrong'))
  }

  // Invalidate asset cache
  await queryClient.invalidateQueries({
    queryKey: [QueryAssetKey, newAsset.id],
  })

  return {
    error: false,
    message: t('asset_updated_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
