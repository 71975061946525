import { useCallback, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import DropdownContext, {
  Props as DropdownProps,
} from '@/components/dropdown/components/context.dropdown'

import { DrawerContent } from './components/drawer'
import { ModalContent } from './components/modal'
import { InterfaceContext } from './context'
import { DrawerProps } from './drawer.types'
import { ModalProps } from './modal.types'

type Props = {
  children: JSX.Element[] | JSX.Element
}

export const InterfaceProvider = <T,>(props: Props) => {
  const [_currentDropdownId, setCurrentDropdownId] = useState<string | null>(
    null
  )
  const [_dropdownContent, setDropdownContent] =
    useState<DropdownProps<T> | null>(null)
  const [_drawerContent, setDrawerContent] = useState<DrawerProps | null>(null)
  const [_modalContent, setModalContent] = useState<ModalProps | null>(null)

  const setDropdown = useCallback((props: DropdownProps<T> | null) => {
    const id = uuidv4()
    setCurrentDropdownId(id)
    setDropdownContent(props ? { ...props, dropdownId: id } : null)
    return id
  }, [])

  const updateDropdownController = useCallback(
    (id: string, controller: DropdownProps<T>['controller']) => {
      if (
        _dropdownContent &&
        id === _currentDropdownId &&
        _currentDropdownId !== null
      ) {
        setDropdownContent({
          ..._dropdownContent,
          controller: {
            ..._dropdownContent.controller,
            ...controller,
          },
        })
      }
    },
    [_currentDropdownId]
  )

  const setDrawer = useCallback((props: DrawerProps | null) => {
    if (_drawerContent !== null && props !== null) {
      setDrawerContent(null)
    }

    setTimeout(() => {
      setDrawerContent(props)
    }, 100)
  }, [])

  const setModal = useCallback((props: ModalProps | null) => {
    if (_modalContent !== null && props !== null) {
      setModalContent(null)
    }

    setTimeout(() => {
      setModalContent(props)
    }, 100)
  }, [])

  const providerValue = useMemo(
    () => ({
      currentDropdownId: _currentDropdownId,
      setCurrentDropdownId,
      setDropdown,
      updateDropdownController,
      setDrawer,
      setModal,
    }),
    [
      _currentDropdownId,
      setCurrentDropdownId,
      setDropdown,
      updateDropdownController,
      setDrawer,
      setModal,
    ]
  )

  return (
    <InterfaceContext.Provider value={providerValue}>
      <DrawerContent content={_drawerContent} />
      <ModalContent content={_modalContent} />
      {_dropdownContent !== null && <DropdownContext {..._dropdownContent} />}
      {props.children}
    </InterfaceContext.Provider>
  )
}
