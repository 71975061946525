import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IIamUser } from '@/types/group'
import { ILoginSuccess, IUserSession } from '@/types/session'
import { IUser, IUserBase } from '@/types/user'

export const login = async (
  email?: string,
  password?: string,
  workspaceId?: string
): Promise<BaseResponse<ILoginSuccess | IUserSession>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client
    .post<BaseResponse<ILoginSuccess | IUserSession>>(`user/authenticate`, {
      email,
      password,
      ...(workspaceId && {
        workspace_id: workspaceId,
      }),
    })
    .then((response) => {
      return response.data
    })
}

export const getQRCode = async (token: string): Promise<string> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  const response = await client.get(`user/${token}/2fa`).then((response) => {
    return response.data
  })
  return client.defaults.baseURL + '/' + response.data.qr_code
}

export const verify2FA = async (
  token: string,
  code: string
): Promise<BaseResponse<IUser>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client
    .post<BaseResponse<IUser>>(`user/${token}/2fa`, {
      token: code,
    })
    .then((response) => {
      return response.data
    })
}

export const getUser = async (): Promise<BaseResponse<IUser>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client.get<BaseResponse<IUser>>(`user/me`).then((response) => {
    return response.data
  })
}

export const getEscrowUser = async (): Promise<BaseResponse<IUser>> => {
  const client = await getClient((await getConfig()).GOVERNANCE_API_URL)
  return client.get<BaseResponse<IUser>>(`escrow-user/me`).then((response) => {
    return response.data
  })
}

export const recoverPasswordForEmail = async (
  email: string
): Promise<BaseResponse<null>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client.get(`user/${email}/forgotpassword`).then((response) => {
    return response.data
  })
}

export const resetPassword = async (
  password: string,
  token: string
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client
    .post(`user/${token}/newpassword`, { new_password: password })
    .then((response) => {
      return response.data
    })
}

export const updateOrgUser = async (
  user: IUser
): Promise<BaseResponse<IUser>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.put(`/user/${user.id}/modify`, user).then((response) => {
    return response.data
  })
}

export const deleteOrgUser = async (
  userId: string
): Promise<BaseResponse<IUser[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.delete(`/user/${userId}`).then((response) => {
    console.log('deleteOrUser res', response)
    return response.data
  })
}

export const changePassword = async (data: {
  confirm_password: string
  new_password: string
  old_password: string
}): Promise<BaseResponse<unknown>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.post(`/user/changepassword`, data).then((res) => res.data)
}

export const createNewUser = async (
  user: IUserBase[]
): Promise<
  BaseResponse<{
    users: IUser[]
    errors: string[]
  }>
> => {
  const data = { payload: user }
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.post(`/user/new`, data).then((res) => res.data)
}

export const getIamUser = async (
  userId: string
): Promise<BaseResponse<IIamUser>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.get(`/user/iam?user_id=${userId}`).then((res) => res.data)
}
