import { t } from 'i18next'

export enum AuditOperation {
  GetFileInfo = 'GetFileInfo',
  UploadFile = 'UploadFile',
  GetMultipleFileInfos = 'GetMultipleFileInfos',
  StartBalanceCheckWorker = 'StartBalanceCheckWorker',
  StartExchangeRateUpdateWorker = 'StartExchangeRateUpdateWorker',
  StartValuationWorker = 'StartValuationWorker',
  StartWalletBalanceWorker = 'StartWalletBalanceWorker',
  FindAllAudits = 'FindAllAudits',
  FindAllAuditsV2 = 'FindAllAuditsV2',
  GetExchangeRates = 'GetExchangeRates',
  ImportCustodian = 'ImportCustodian',
  CreateCustodian = 'CreateCustodian',
  GetCustodians = 'GetCustodians',
  CreateCustodianAsset = 'CreateCustodianAsset',
  CreateCustodianConfig = 'CreateCustodianConfig',
  GetOrgCustodians = 'GetOrgCustodians',
  CreateCustody = 'CreateCustody',
  RefreshCustodianSupportedAssets = 'RefreshCustodianSupportedAssets',
  GetAddressNFTs = 'GetAddressNFTs',
  RefreshAddressAssetsBalances = 'RefreshAddressAssetsBalances',
  GetTokenInfo = 'GetTokenInfo',
  GetAllNetworks = 'GetAllNetworks',
  ValidateAddress = 'ValidateAddress',
  GetAddressTokens = 'GetAddressTokens',
  GetAddressAssets = 'GetAddressAssets',
  GetAddressBalances = 'GetAddressBalances',
  AddAlert = 'AddAlert',
  SearchAlerts = 'SearchAlerts',
  GetAlertByID = 'GetAlertByID',
  GetAlertsByOrgID = 'GetAlertsByOrgID',
  UpdateAlert = 'UpdateAlert',
  DeleteAlertByID = 'DeleteAlertByID',
  GetInvestigations = 'GetInvestigations',
  CreateInvestigation = 'CreateInvestigation',
  GetInvestigationByID = 'GetInvestigationByID',
  UpdateInvestigation = 'UpdateInvestigation',
  HandleClientDecision = 'HandleClientDecision',
  AddFiles = 'AddFiles',
  UpdateTransactionHistoryFees = 'UpdateTransactionHistoryFees',
  GetTransactionHistoryByAddressAndNetwork = 'GetTransactionHistoryByAddressAndNetwork',
  GetSharedAssets = 'GetSharedAssets',
  ImportDigitalAssets = 'ImportDigitalAssets',
  ImportVehicle = 'ImportVehicle',
  ImportRealEstateAssets = 'ImportRealEstateAssets',
  ImportHighValuePersonalPropertyAssets = 'ImportHighValuePersonalPropertyAssets',
  ImportFiatCashAssets = 'ImportFiatCashAssets',
  CreateAsset = 'CreateAsset',
  GetAsset = 'GetAsset',
  UpdateAsset = 'UpdateAsset',
  DeleteAsset = 'DeleteAsset',
  ArchiveAsset = 'ArchiveAsset',
  ShareAssets = 'ShareAssets',
  AddAssetStatus = 'AddAssetStatus',
  UpdateAssetStatus = 'UpdateAssetStatus',
  DeleteAsseStatust = 'DeleteAsseStatust',
  AddAssetNote = 'AddAssetNote',
  UpdateAssetNote = 'UpdateAssetNote',
  DeleteAssetNote = 'DeleteAssetNote',
  AddAssetCost = 'AddAssetCost',
  UpdateAssetCost = 'UpdateAssetCost',
  DeleteAssetCost = 'DeleteAssetCost',
  FindDigitalAssets = 'FindDigitalAssets',
  FindPhysicalAssets = 'FindPhysicalAssets',
  FindVehicleAssets = 'FindVehicleAssets',
  FindRealEstateAssets = 'FindRealEstateAssets',
  FindHighValuePersonalPropertyAssets = 'FindHighValuePersonalPropertyAssets',
  FindFiatCashAssets = 'FindFiatCashAssets',
  CreateOperation = 'CreateOperation',
  FindOperations = 'FindOperations',
  UpdateOperation = 'UpdateOperation',
  UpdateOperationFiles = 'UpdateOperationFiles',
  FindOperation = 'FindOperation',
  ArchiveOperation = 'ArchiveOperation',
  FindOperationsByIDs = 'FindOperationsByIDs',
  FindOperationDigitalAssets = 'FindOperationDigitalAssets',
  FindOperationPhysicalAssets = 'FindOperationPhysicalAssets',
  GetOperationAssetsStats = 'GetOperationAssetsStats',
  GetAllOperationsAssetsStats = 'GetAllOperationsAssetsStats',
  CreateSeizure = 'CreateSeizure',
  UpdateSeizure = 'UpdateSeizure',
  FindSeizures = 'FindSeizures',
  FindSeizureDigitalAssets = 'FindSeizureDigitalAssets',
  FindSeizurePhysicalAssets = 'FindSeizurePhysicalAssets',
  CreateDefendant = 'CreateDefendant',
  UpdateDefendant = 'UpdateDefendant',
  FindDefendant = 'FindDefendant',
  DeleteDefendant = 'DeleteDefendant',
  FindDefendants = 'FindDefendants',
  FindDefendantDigitalAssets = 'FindDefendantDigitalAssets',
  FindDefendantPhysicalAssets = 'FindDefendantPhysicalAssets',
  FindDefendantOperations = 'FindDefendantOperations',
  GetReportTemplates = 'GetReportTemplates',
  GenerateReport = 'GenerateReport',
  DownloadReport = 'DownloadReport',
  Search = 'Search',
  StartTransferRequest = 'StartTransferRequest',
  GetTransferRequest = 'GetTransferRequest',
  FindTransferRequestDigitalAssets = 'FindTransferRequestDigitalAssets',
  FindTransferRequestPhysicalAssets = 'FindTransferRequestPhysicalAssets',
  ApproveTransferRequest = 'ApproveTransferRequest',
  DeclineTransferRequest = 'DeclineTransferRequest',
  AuthenticateUser = 'AuthenticateUser',
  VerifyCode = 'VerifyCode',
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
  SaveUserDevicePublicKey = 'SaveUserDevicePublicKey',
  DeleteUserDevicePublicKey = 'DeleteUserDevicePublicKey',
  StartUserDeviceChallenge = 'StartUserDeviceChallenge',
  VerifyUserDeviceChallenge = 'VerifyUserDeviceChallenge',
  CreateUser = 'CreateUser',
  CreateEscrowUser = 'CreateEscrowUser',
  ChangePassword = 'ChangePassword',
  ModifyUser = 'ModifyUser',
  DeleteUser = 'DeleteUser',
  GetUserMe = 'GetUserMe',
  ToggleDisable = 'ToggleDisable',
  GetOrgByUser = 'GetOrgByUser',
  GetAllOrganizations = 'GetAllOrganizations',
  UpdateOrganization = 'UpdateOrganization',
  DeleteOrganization = 'DeleteOrganization',
  GetOrgUsers = 'GetOrgUsers',
  DeleteSession = 'DeleteSession',
  GetSession = 'GetSession',
  RefreshSession = 'RefreshSession',
  GetAllPolicies = 'GetAllPolicies',
  CreateRole = 'CreateRole',
  FindAllRoles = 'FindAllRoles',
  UpdateRole = 'UpdateRole',
  DeleteRoleByID = 'DeleteRoleByID',
  GetPoliciesNotInRole = 'GetPoliciesNotInRole',
  CreateGroup = 'CreateGroup',
  FindAllGroups = 'FindAllGroups',
  UpdateGroup = 'UpdateGroup',
  FindGroupByID = 'FindGroupByID',
  DeleteGroupByID = 'DeleteGroupByID',
  GetOrgRolesNotInGroup = 'GetOrgRolesNotInGroup',
  GetUserIAMDetails = 'GetUserIAMDetails',
  NewDirectPolicy = 'NewDirectPolicy',
  DeleteDirectPolicy = 'DeleteDirectPolicy',
  UpdateDirectPolicy = 'UpdateDirectPolicy',
  GetPoliciesNotInDirectPolicy = 'GetPoliciesNotInDirectPolicy',
  CreateWorkspace = 'CreateWorkspace',
  GetAllWorkspaces = 'GetAllWorkspaces',
  GetOneWorkspaceByID = 'GetOneWorkspaceByID',
  UpdateWorkspace = 'UpdateWorkspace',
  DeleteWorkspace = 'DeleteWorkspace',
  GetAllWorkspaceMembers = 'GetAllWorkspaceMembers',
  AddWorkspaceMember = 'AddWorkspaceMember',
  UpdateWorkspaceMember = 'UpdateWorkspaceMember',
  RemoveWorkspaceMember = 'RemoveWorkspaceMember',
}

export type AuditLog = {
  id: string
  organization_id: string
  user: {
    id: string
    name: string
    email: string
    phone: string
    organization_id: string
    role: string
    two_factor_enabled: boolean
    deleted: boolean
    created_at: string
    created_by: string
    disabled: boolean
    language: string
  }
  operation: AuditOperation
  occurred_at: string
  ip: string
  country: string
  city: string
  asset_id: string
  defendant_id: string
  operation_id: string
  defendant_name: string
  operation_name: string
  body?: string
}

export const assetOperations: Record<
  AuditOperation,
  { label: string | null; data?: (body: string) => string }
> = {
  [AuditOperation.GetAsset]: { label: t('get_asset') },
  [AuditOperation.AddAssetCost]: { label: t('added_asset_cost') },
  [AuditOperation.AddAssetNote]: {
    label: t('added_asset_note'),
    data: (body: string) => `"${JSON.parse(body).content}"`,
  },
  [AuditOperation.AddAssetStatus]: { label: t('added_asset_status') },
  [AuditOperation.CreateAsset]: {
    label: t('created_new_asset'),
    data: (body) => `${t('data')}: ${body}`,
  },
  [AuditOperation.DeleteAsset]: { label: t('deleted_asset') },
  [AuditOperation.DeleteAssetCost]: { label: t('deleted_asset_cost') },
  [AuditOperation.DeleteAssetNote]: {
    label: t('deleted_asset_note'),
  },
  [AuditOperation.UpdateAsset]: {
    label: t('updated_asset'),
    data: (body) => `${t('updated_to')}: ${body}`,
  },
  [AuditOperation.UpdateAssetCost]: { label: t('updated_asset_cost') },
  [AuditOperation.UpdateAssetNote]: { label: t('updated_asset_note') },
  [AuditOperation.UpdateAssetStatus]: { label: t('updated_asset_status') },
  [AuditOperation.ArchiveAsset]: { label: t('archived_asset') },
  [AuditOperation.DeleteAsseStatust]: { label: t('deleted_asset_status') },
  [AuditOperation.CreateCustodianAsset]: {
    label: t('created_custodian_asset'),
  },
  [AuditOperation.GetFileInfo]: { label: t('get_file_info') },
  [AuditOperation.UploadFile]: { label: t('upload_file') },
  [AuditOperation.GetMultipleFileInfos]: {
    label: t('get_multiple_file_infos'),
  },
  [AuditOperation.StartBalanceCheckWorker]: {
    label: t('start_balance_check_worker'),
  },
  [AuditOperation.StartExchangeRateUpdateWorker]: {
    label: t('start_exchange_rate_update_worker'),
  },
  [AuditOperation.StartValuationWorker]: { label: t('start_valuation_worker') },
  [AuditOperation.StartWalletBalanceWorker]: {
    label: t('start_wallet_balance_worker'),
  },
  [AuditOperation.FindAllAudits]: { label: t('find_all_audits') },
  [AuditOperation.FindAllAuditsV2]: { label: t('find_all_audits_v2') },
  [AuditOperation.GetExchangeRates]: { label: t('get_exchange_rates') },
  [AuditOperation.ImportCustodian]: { label: t('import_custodian') },
  [AuditOperation.CreateCustodian]: { label: t('create_custodian') },
  [AuditOperation.GetCustodians]: { label: t('get_custodians') },
  [AuditOperation.CreateCustodianConfig]: {
    label: t('create_custodian_config'),
  },
  [AuditOperation.GetOrgCustodians]: { label: t('get_org_custodians') },
  [AuditOperation.CreateCustody]: { label: t('create_custody') },
  [AuditOperation.RefreshCustodianSupportedAssets]: {
    label: t('refresh_custodian_supported_assets'),
  },
  [AuditOperation.GetAddressNFTs]: { label: t('get_address_nfts') },
  [AuditOperation.RefreshAddressAssetsBalances]: {
    label: t('refresh_address_assets_balances'),
  },
  [AuditOperation.GetTokenInfo]: { label: t('get_token_info') },
  [AuditOperation.GetAllNetworks]: { label: t('get_all_networks') },
  [AuditOperation.ValidateAddress]: { label: t('validate_address') },
  [AuditOperation.GetAddressTokens]: { label: t('get_address_tokens') },
  [AuditOperation.GetAddressAssets]: { label: t('get_address_assets') },
  [AuditOperation.GetAddressBalances]: { label: t('get_address_balances') },
  [AuditOperation.AddAlert]: { label: t('add_alert') },
  [AuditOperation.SearchAlerts]: { label: t('search_alerts') },
  [AuditOperation.GetAlertByID]: { label: t('get_alert_by_id') },
  [AuditOperation.GetAlertsByOrgID]: { label: t('get_alerts_by_org_id') },
  [AuditOperation.UpdateAlert]: { label: t('update_alert') },
  [AuditOperation.DeleteAlertByID]: { label: t('delete_alert_by_id') },
  [AuditOperation.GetInvestigations]: { label: t('get_investigations') },
  [AuditOperation.CreateInvestigation]: { label: t('create_investigation') },
  [AuditOperation.GetInvestigationByID]: {
    label: t('get_investigation_by_id'),
  },
  [AuditOperation.UpdateInvestigation]: { label: t('update_investigation') },
  [AuditOperation.HandleClientDecision]: { label: t('handle_client_decision') },
  [AuditOperation.AddFiles]: { label: t('add_files') },
  [AuditOperation.UpdateTransactionHistoryFees]: {
    label: t('update_transaction_history_fees'),
  },
  [AuditOperation.GetTransactionHistoryByAddressAndNetwork]: {
    label: t('get_transaction_history_by_address_and_network'),
  },
  [AuditOperation.GetSharedAssets]: { label: t('get_shared_assets') },
  [AuditOperation.ImportDigitalAssets]: { label: t('import_digital_assets') },
  [AuditOperation.ImportVehicle]: { label: t('import_vehicle') },
  [AuditOperation.ImportRealEstateAssets]: {
    label: t('import_real_estate_assets'),
  },
  [AuditOperation.ImportHighValuePersonalPropertyAssets]: {
    label: t('import_high_value_personal_property_assets'),
  },
  [AuditOperation.ImportFiatCashAssets]: {
    label: t('import_fiat_cash_assets'),
  },
  [AuditOperation.ShareAssets]: { label: t('share_assets') },
  [AuditOperation.FindDigitalAssets]: { label: t('find_digital_assets') },
  [AuditOperation.FindPhysicalAssets]: { label: t('find_physical_assets') },
  [AuditOperation.FindVehicleAssets]: { label: t('find_vehicle_assets') },
  [AuditOperation.FindRealEstateAssets]: {
    label: t('find_real_estate_assets'),
  },
  [AuditOperation.FindHighValuePersonalPropertyAssets]: {
    label: t('find_high_value_personal_property_assets'),
  },
  [AuditOperation.FindFiatCashAssets]: { label: t('find_fiat_cash_assets') },
  [AuditOperation.CreateOperation]: { label: t('create_operation') },
  [AuditOperation.FindOperations]: { label: t('find_operations') },
  [AuditOperation.UpdateOperation]: { label: t('update_operation') },
  [AuditOperation.UpdateOperationFiles]: { label: t('update_operation_files') },
  [AuditOperation.FindOperation]: { label: t('find_operation') },
  [AuditOperation.ArchiveOperation]: { label: t('archive_operation') },
  [AuditOperation.FindOperationsByIDs]: { label: t('find_operations_by_ids') },
  [AuditOperation.FindOperationDigitalAssets]: {
    label: t('find_operation_digital_assets'),
  },
  [AuditOperation.FindOperationPhysicalAssets]: {
    label: t('find_operation_physical_assets'),
  },
  [AuditOperation.GetOperationAssetsStats]: {
    label: t('get_operation_assets_stats'),
  },
  [AuditOperation.GetAllOperationsAssetsStats]: {
    label: t('get_all_operations_assets_stats'),
  },
  [AuditOperation.CreateSeizure]: { label: t('create_seizure') },
  [AuditOperation.UpdateSeizure]: { label: t('update_seizure') },
  [AuditOperation.FindSeizures]: { label: t('find_seizures') },
  [AuditOperation.FindSeizureDigitalAssets]: {
    label: t('find_seizure_digital_assets'),
  },
  [AuditOperation.FindSeizurePhysicalAssets]: {
    label: t('find_seizure_physical_assets'),
  },
  [AuditOperation.CreateDefendant]: { label: t('created_subject') },
  [AuditOperation.UpdateDefendant]: { label: t('update_subject') },
  [AuditOperation.FindDefendant]: { label: t('find_subject') },
  [AuditOperation.DeleteDefendant]: { label: t('deleted_subject') },
  [AuditOperation.FindDefendants]: { label: t('find_subjects') },
  [AuditOperation.FindDefendantDigitalAssets]: {
    label: t('find_subject_digital_assets'),
  },
  [AuditOperation.FindDefendantPhysicalAssets]: {
    label: t('find_subject_physical_assets'),
  },
  [AuditOperation.FindDefendantOperations]: {
    label: t('find_subject_operations'),
  },
  [AuditOperation.GetReportTemplates]: { label: t('get_report_templates') },
  [AuditOperation.GenerateReport]: { label: t('generated_report') },
  [AuditOperation.DownloadReport]: { label: t('download_report') },
  [AuditOperation.Search]: { label: t('search') },
  [AuditOperation.StartTransferRequest]: { label: t('start_transfer_request') },
  [AuditOperation.GetTransferRequest]: { label: t('get_transfer_request') },
  [AuditOperation.FindTransferRequestDigitalAssets]: {
    label: t('find_transfer_request_digital_assets'),
  },
  [AuditOperation.FindTransferRequestPhysicalAssets]: {
    label: t('find_transfer_request_physical_assets'),
  },
  [AuditOperation.ApproveTransferRequest]: {
    label: t('approve_transfer_request'),
  },
  [AuditOperation.DeclineTransferRequest]: {
    label: t('decline_transfer_request'),
  },
  [AuditOperation.AuthenticateUser]: { label: t('authenticate_user') },
  [AuditOperation.VerifyCode]: { label: t('verify_code') },
  [AuditOperation.ForgotPassword]: { label: t('forgot_password_log') },
  [AuditOperation.ResetPassword]: { label: t('reset_password_log') },
  [AuditOperation.SaveUserDevicePublicKey]: {
    label: t('save_user_device_public_key'),
  },
  [AuditOperation.DeleteUserDevicePublicKey]: {
    label: t('delete_user_device_public_key'),
  },
  [AuditOperation.StartUserDeviceChallenge]: {
    label: t('start_user_device_challenge'),
  },
  [AuditOperation.VerifyUserDeviceChallenge]: {
    label: t('verify_user_device_challenge'),
  },
  [AuditOperation.CreateUser]: { label: t('created_user') },
  [AuditOperation.CreateEscrowUser]: { label: t('create_escrow_user') },
  [AuditOperation.ChangePassword]: { label: t('change_password') },
  [AuditOperation.ModifyUser]: { label: t('modify_user') },
  [AuditOperation.DeleteUser]: { label: t('deleted_user') },
  [AuditOperation.GetUserMe]: { label: t('get_user_me') },
  [AuditOperation.ToggleDisable]: { label: t('toggle_disable') },
  [AuditOperation.GetOrgByUser]: { label: t('get_org_by_user') },
  [AuditOperation.GetAllOrganizations]: { label: t('get_all_organizations') },
  [AuditOperation.UpdateOrganization]: { label: t('update_organization') },
  [AuditOperation.DeleteOrganization]: { label: t('delete_organization') },
  [AuditOperation.GetOrgUsers]: { label: t('get_org_users') },
  [AuditOperation.DeleteSession]: { label: t('delete_session') },
  [AuditOperation.GetSession]: { label: t('get_session') },
  [AuditOperation.RefreshSession]: { label: t('refresh_session') },
  [AuditOperation.GetAllPolicies]: { label: t('get_all_policies') },
  [AuditOperation.CreateRole]: { label: t('create_role') },
  [AuditOperation.FindAllRoles]: { label: t('find_all_roles') },
  [AuditOperation.UpdateRole]: { label: t('update_role') },
  [AuditOperation.DeleteRoleByID]: { label: t('delete_role_by_id') },
  [AuditOperation.GetPoliciesNotInRole]: {
    label: t('get_policies_not_in_role'),
  },
  [AuditOperation.CreateGroup]: { label: t('create_group') },
  [AuditOperation.FindAllGroups]: { label: t('find_all_groups') },
  [AuditOperation.UpdateGroup]: { label: t('update_group') },
  [AuditOperation.FindGroupByID]: { label: t('find_group_by_id') },
  [AuditOperation.DeleteGroupByID]: { label: t('delete_group_by_id') },
  [AuditOperation.GetOrgRolesNotInGroup]: {
    label: t('get_org_roles_not_in_group'),
  },
  [AuditOperation.GetUserIAMDetails]: { label: t('get_user_iam_details') },
  [AuditOperation.NewDirectPolicy]: { label: t('new_direct_policy') },
  [AuditOperation.DeleteDirectPolicy]: { label: t('delete_direct_policy') },
  [AuditOperation.UpdateDirectPolicy]: { label: t('update_direct_policy') },
  [AuditOperation.GetPoliciesNotInDirectPolicy]: {
    label: t('get_policies_not_in_direct_policy'),
  },
  [AuditOperation.CreateWorkspace]: { label: t('created_workspace') },
  [AuditOperation.GetAllWorkspaces]: { label: t('get_all_workspaces') },
  [AuditOperation.GetOneWorkspaceByID]: { label: t('get_one_workspace_by_id') },
  [AuditOperation.UpdateWorkspace]: { label: t('update_workspace') },
  [AuditOperation.DeleteWorkspace]: { label: t('delete_workspace') },
  [AuditOperation.GetAllWorkspaceMembers]: {
    label: t('get_all_workspace_members'),
  },
  [AuditOperation.AddWorkspaceMember]: { label: t('add_workspace_member') },
  [AuditOperation.UpdateWorkspaceMember]: {
    label: t('update_workspace_member'),
  },
  [AuditOperation.RemoveWorkspaceMember]: {
    label: t('remove_workspace_member'),
  },
}
