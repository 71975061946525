import { GridOptions } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/button'
import { useAuditLogs } from '@/components/event-logs/use-audit-logs'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { dateToString } from '@/helpers/dateToString'
import { assetOperations } from '@/types/audit-log'

type Props =
  | {
      assetId: string
      defendantId?: undefined
      caseId?: undefined
    }
  | {
      assetId?: undefined
      defendantId: string
      caseId?: undefined
    }
  | {
      assetId?: undefined
      defendantId?: undefined
      caseId: string
    }

export const EventLogs = (props: Props) => {
  const { t } = useTranslation()
  const { data, isLoading, refetch, isRefetching } = useAuditLogs(props)

  const [quickFilterText, setQuickFilterText] = useState('')

  const rowData = useMemo(
    () =>
      data?.map((item) => ({
        ...item,
        user: {
          ...item.user,
        },
        operation: {
          id: item.operation,
          label: assetOperations[item.operation]?.label ?? item.operation,
        },
      })),
    [data]
  )

  const ref = useRef<AgGridReact>(null)
  const gridOptions = useMemo<
    GridOptions<NonNullable<typeof rowData>[0]>
  >(() => {
    return {
      defaultColDef: {
        suppressHeaderMenuButton: true,
        suppressHeaderContextMenu: true,
      },
      columnDefs: [
        {
          field: 'occurred_at',
          headerName: t('event_time'),
          valueFormatter: (params) =>
            params.value
              ? `${dateToString(params.value)} ${moment(params.value).format('h:mm:ss a')}`
              : '',
          filter: 'agDateColumnFilter',
          filterParams: {
            maxNumConditions: 1,
            filterOptions: [
              'equals',
              'lessThan',
              'greaterThan',
              'inRange',
              'blank',
            ],
            comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
              const cellDate = moment(cellValue).startOf('day').toDate()
              if (cellDate.getTime() === filterLocalDateAtMidnight.getTime()) {
                return 0
              }
              return cellDate < filterLocalDateAtMidnight ? -1 : 1
            },
          },
        },
        {
          field: 'user.name',
          filter: 'agSetColumnFilter',
          headerName: t('user'),
        },
        {
          field: 'user.email',
          filter: 'agSetColumnFilter',
          headerName: t('email'),
        },
        {
          field: 'operation.label',
          filter: 'agSetColumnFilter',
          headerName: t('action'),
        },
        {
          field: 'ip',
          filter: 'agSetColumnFilter',
          headerName: t('ip_address'),
        },
        {
          field: 'body',
          headerName: t('change'),
          valueFormatter: (params) =>
            (params.data &&
              assetOperations[params.data.operation.id]?.data?.(
                params.value
              )) ??
            '',
          getQuickFilterText: () => '',
          sortable: false,
        },
        {
          field: 'city',
          headerName: t('city'),
        },
        {
          field: 'country',
          headerName: t('country'),
        },
      ],
      rowData,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivotMode: true,
              suppressRowGroups: true,
              suppressValues: true,
            },
          },
        ],
      },
      suppressContextMenu: true,
    }
  }, [rowData, t])

  return (
    <div className="flex flex-col gap-8 py-2">
      <div className="flex flex-wrap gap-3 justify-between">
        <div className="flex items-center gap-3 grow flex-wrap">
          <Button.Basic
            hierarchy="secondary"
            icon={{ name: 'bars-filter' }}
            label={t('clear_all_filters')}
            onClick={() => {
              ref.current?.api.setFilterModel(null)
            }}
          />

          <Button.Basic
            hierarchy="secondary"
            icon={{
              name: 'refresh',
            }}
            state={isRefetching ? 'loading' : undefined}
            label={t('refresh')}
            onClick={() => refetch()}
            className="place-self-end"
          />
        </div>

        <div className="flex flex-grow-[2] md:justify-end">
          <Input.Text
            icon={<Icon name="search" variant="solid" size="medium" />}
            placeholder={
              // TODO: translate this
              'Search'
            }
            onChange={(event) => {
              setQuickFilterText(event.target.value)
            }}
          />
        </div>
      </div>
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          {...gridOptions}
          ref={ref}
          quickFilterText={quickFilterText}
          loading={isLoading}
        />
      </div>
    </div>
  )
}
