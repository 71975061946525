import { t } from 'i18next'

import { getOrganisation } from '@/api/org'

//when a user shares an asset and leaves the message box empty, this message should be automatically generated
export const GenerateAutomatedMessage = async (message: string) => {
  const org = await getOrganisation()
  const orgname = org.data?.name ?? ''
  message = t('share_asset_message', { orgname })

  return message
}
