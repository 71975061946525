import { twMerge } from 'tailwind-merge'

import { Icon } from '../../icon'
import { Typography } from '../../typography'

export interface Props {
  label: string
  style?: 'hint' | 'positive' | 'negative' | 'message'
  width?: 'flex' | 'intrinsic'
  state?: 'default' | 'disabled'
  className?: string
  testId?: string
}

export const InputHint = (props: Props) => {
  return (
    <div
      className={twMerge(
        'inline-flex relative gap-[0.125rem] rounded-[6px] py-[0.25rem] pl-[2rem] pr-[0.5rem]',
        props.width === 'flex' ? 'flex-1' : 'self-start',
        props.style === 'hint'
          ? 'pl-[0.1875rem] bg-transparent'
          : props.state === 'disabled'
            ? 'bg-[#E1E1E2]'
            : props.style === 'positive'
              ? 'bg-green-500'
              : props.style === 'negative'
                ? 'bg-yellow-500'
                : 'bg-black',
        props.className
      )}
      data-testid={props.testId}
    >
      {props.style !== 'hint' && (
        <Icon
          size={'small'}
          variant={'solid'}
          name={
            props.style === 'message'
              ? 'circle-info'
              : props.style === 'negative'
                ? 'circle-exclamation'
                : 'circle-check'
          }
          className={twMerge(
            'absolute left-[0.125rem] top-[0.125rem]',
            props.state === 'disabled'
              ? 'text-[#909293]'
              : props.style === 'negative'
                ? 'text-black'
                : 'text-white'
          )}
        />
      )}
      <Typography
        variant="paragraph-small"
        className={
          props.state === 'disabled'
            ? 'text-[#909293]'
            : props.style === 'negative' || props.style === 'hint'
              ? 'text-[#45484B]'
              : 'text-white'
        }
      >
        {props.label}
      </Typography>
    </div>
  )
}
