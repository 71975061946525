import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { getOrgUsers } from '@/api/auth/v1/org'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export const loader: LoaderFunction = async ({ params }) => {
  try {
    if (!params.org_id) {
      return ErrorHandler(400, t('missing_params'))
    } else if (typeof params.org_id !== 'string') {
      return ErrorHandler(422, t('invalid_params'))
    } else if (params.org_id === ':org_id') {
      return ErrorHandler(422, t('invalid_params'))
    }

    const res = await getOrgUsers(params.org_id)

    // Check data is ok
    if (res.error) {
      return ErrorHandler(500, ExtractErrorFrom(res) ?? '')
    }

    return { data: res.data }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error) ?? t('something_went_wrong')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
