import { ReactNode, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { AddBlockchainAddressModal } from './variants/add-blockchain-address'
import { AssetSelectorModal } from './variants/asset-selector'
import { DeleteUserModal } from './variants/delete-user'
import { EscrowApproversModal } from './variants/escrow-approvers'
import { EscrowFeeModal } from './variants/escrow-fee'
import { ForgottenPasswordModal } from './variants/forgotten-password'
import { QRWalletAddressModal } from './variants/qr-wallet-address'
import { ShareAssetModal } from './variants/share-asset'
import { ShareAssetRequestedModal } from './variants/share-asset-requested'
import { TransferAssetModal } from './variants/transfer-asset'
import { TransferAssetRequestedModal } from './variants/transfer-asset-requested'
import { TwoFactorSetupModal } from './variants/two-factor-setup'
import { WorkspaceInviteModal } from './variants/workspace-invite'
import { WorkspaceInviteSentModal } from './variants/workspace-invite-sent'

interface Props {
  title?: string
  children?: ReactNode
  visible: boolean
  onClose?: () => void
  className?: string
  closeOnBackgroundClick?: boolean
  testId?: string
}
export const Modal = (props: Props): JSX.Element => {
  const [_showing, setShowing] = useState<boolean>(false)
  const [_visible, setVisible] = useState<boolean>(false)
  const [_hiding, setHiding] = useState<boolean>(false)

  /*
    I'm sure you're wondering why we're using async functions here... whilst in
    dev mode useEffects can be ran twice as a strict testing process built in
    by React. Unfortunately this means that our drawer can stutter on opening
    because it gets 2 open commands - so we have the async flow in order to
    avoid this - I believe it works but I could be wrong!
  */
  useEffect(() => {
    const asyncFunc = async () => {
      if (props.visible) {
        if (!_showing && !_visible) {
          await onOpen()
        }
      } else {
        if (_showing || _visible) {
          await onClose?.()
        }
      }
    }
    asyncFunc()
  }, [props.visible])

  const onOpen = async () => {
    return new Promise<void>((resolve) => {
      setHiding(false)
      setShowing(true)
      setTimeout(() => {
        setVisible(true)
        resolve()
      })
    })
  }

  const onClose = props.onClose
    ? async () => {
        return new Promise<void>((resolve) => {
          setHiding(true)
          setTimeout(() => {
            props.onClose?.()
            setShowing(false)
            setVisible(false)
            setHiding(false)
            resolve()
          }, 300)
        })
      }
    : undefined

  const onOutsideClick = onClose
    ? () => {
        if (props.closeOnBackgroundClick === true) {
          onClose?.()
        }
      }
    : undefined

  return (
    <div
      className={twMerge(
        'fixed hidden top-0 left-[150vw] w-0 h-0',
        'pointer-events-auto overflow-hidden overscroll-contain',
        'items-center justify-center',
        _showing &&
          'flex left-0 w-[100vw] min-h-[100dvh] max-h-[100dvh] z-[60] opacity-0',
        _visible && 'opacity-100'
      )}
    >
      <div
        onClick={onOutsideClick}
        className={twMerge(
          'absolute flex inset-0',
          'overflow-hidden overscroll-contain',
          'cursor-default',
          'bg-[#21242759]',
          _visible && 'w-[100vw] min-h-[100dvh] max-h-[100dvh]',
          _visible && 'animate-fade-in',
          _hiding && 'animate-fade-out'
        )}
      />
      <div
        data-testid={props.testId}
        className={twMerge(
          'pointer-events-auto bg-white',
          'w-[600px] max-w-[calc(100vw-2rem)] h-auto max-h-[calc(100vh-2rem)]',
          'tablet:h-auto tablet:bottom-auto tablet:left-auto',
          'z-[61] overflow-hidden overflow-y-auto overscroll-contain',
          'rounded-[0.75rem]',
          'shadow-[0_0.5rem_1rem_0.0625rem_rgba(40,38,35,0.10)]',
          _visible && 'animate-fade-in-up',
          _hiding && 'animate-fade-out-down',
          props.className
        )}
      >
        <div
          className={twMerge('flex flex-col', 'h-full w-full')}
          data-testid="modal-container"
        >
          <div
            className={twMerge(
              'flex flex-1 items-start justify-center',
              'overflow-y-auto overscroll-contain'
            )}
          >
            <div className={'w-full max-w-[100vw] tablet:pb-0'}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default {
  TransferAsset: TransferAssetModal,
  TransferAssetRequested: TransferAssetRequestedModal,
  ShareAsset: ShareAssetModal,
  ShareAssetRequested: ShareAssetRequestedModal,
  WorkspaceInvite: WorkspaceInviteModal,
  WorkspaceInviteSent: WorkspaceInviteSentModal,
  DeleteUser: DeleteUserModal,
  TwoFactorSetup: TwoFactorSetupModal,
  ForgottenPassword: ForgottenPasswordModal,
  QrWalletAddress: QRWalletAddressModal,
  AddBlockchainAddress: AddBlockchainAddressModal,
  EscrowApprovers: EscrowApproversModal,
  EscrowFee: EscrowFeeModal,
  AssetSelector: AssetSelectorModal,
}
