import { t } from 'i18next'

import { updateAssetNote } from '@/api'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { IsNot } from '@/helpers/test'
import { key as QueryAssetKey } from '@/hooks/queries/useAsset'
import { ActionResponse } from '@/types/actions'
import { IAssetNote } from '@/types/asset'

export const EditAssetNote = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const assetId = formData.get('asset_id')?.toString()
  const note = formData.get('note_text')?.toString()
  const origNote = formData.get('note_obj')?.toString()

  // Check basic details
  if (IsNot(note?.trim(), REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_note_provided'),
      },
    ])
  } else if (IsNot(assetId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('missing_asset_id'),
      },
    ])
  } else if (IsNot(origNote, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_original_note_object'),
      },
    ])
  }

  // Attempt to parse the original note object as we'll need this
  // If parse fails it'll throw an error which'll get caught in the
  // action index. (...hopefully)
  const parsedOrigNote = JSON.parse(origNote) as IAssetNote

  // Add in the note
  const res = await updateAssetNote(assetId, {
    ...parsedOrigNote,
    content: note,
    last_updated_at: new Date().toISOString(),
  })

  // Check if the call failed
  if (res.error) {
    throw new Error(res.message)
  }

  // Invalidate asset cache
  await queryClient.invalidateQueries({
    queryKey: [QueryAssetKey, assetId],
  })

  return {
    error: false,
    message: t('note_updated_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
