import { t } from 'i18next'

import { createEscrow } from '@/api/escrows/v1'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { DigitalAssetType } from '@/types/asset'
import { ISupportedAsset } from '@/types/custodian'
import { IEscrowAsset } from '@/types/escrows'

export const CreateEscrow = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('escrow_name')?.toString()
  const orgIdA = formData.get('org_id_a')?.toString()
  const orgIdB = formData.get('org_id_b')?.toString()
  const assets = formData.get('selected_assets')?.toString()

  // Validate the fields
  if (IsNot(assets, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'selected_assets',
        error: t('assets_not_found'),
      },
    ])
  } else if (IsNot(name, REGEX.IS_ESCROW_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow_name',
        error: t('invalid_escrow_name_provided'),
      },
    ])
  } else if (IsNot(orgIdA, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'org_id_a',
        error: t('invalid_party_name_provided'),
      },
    ])
  } else if (IsNot(orgIdB, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'org_id_b',
        error: t('invalid party_b_name_provided'),
      },
    ])
  }

  // Attempt to parse the assets
  const parsedAssets = JSON.parse(assets) as ISupportedAsset[]

  // Check we have some assets to add
  if (parsedAssets.length <= 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'selected_assets',
        error: t('no_valid_assets_provided'),
      },
    ])
  }

  // Make the call to create the escrow
  let create_escrow_res
  try {
    create_escrow_res = await createEscrow(
      name,
      [
        {
          id: orgIdA,
        },
        {
          id: orgIdB,
        },
      ],
      parsedAssets.map((a) => {
        return {
          type: a.contract_address
            ? DigitalAssetType.ERC20
            : DigitalAssetType.NATIVE,
          contract_address: a.contract_address,
          ticker: a.ticker,
          network: a.network,
          custodian: {
            id: a.custodians[0].id,
            name: a.custodians[0].name,
            supported_asset_key: a.custodians[0].supported_asset_key,
          },
        }
      }) satisfies Omit<
        IEscrowAsset,
        'id' | 'wallet' | 'destination_wallet' | 'balance'
      >[]
    )
  } catch (e) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: ExtractErrorFrom(e) ?? t('something_went_wrong'),
      },
    ])
  }

  // Check the escrow was created OK
  if (create_escrow_res.error || !create_escrow_res.data) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: create_escrow_res.message,
      },
    ])
  }

  return {
    error: false,
    message: t('new_escrow_added_successfully'),
    completion: 'COMPLETE',
    data: create_escrow_res.data.id,
  }
}
