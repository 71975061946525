import { t } from 'i18next'
import { useState } from 'react'
import { useLoaderData } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { ActionResponse } from '@/types/actions'

import { Template } from '../_components/template'
import { LoaderData } from './loader'

export const ResetPassword = () => {
  const loader = useLoaderData() as LoaderData
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => {
    BannerManager.hideBanners('all')
    setSubmitting(intent)
  }

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    clearSubmitting()
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: 'Password reset error',
      description: actionRes.message ?? t('something_went_wrong'),
    })
  }

  // On form submit successfully
  const onSuccess = () => {
    window.location.href = ROUTES.AUTH.INDEX
  }

  return (
    <Template>
      <Form
        type={'change_password'}
        buttons={[
          <Button.Basic
            key={`btn_confirm_password`}
            hierarchy={'primary'}
            width={'flex'}
            label={t('continue')}
            state={_submitting ? 'loading' : 'default'}
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.RESET_PASSWORD,
            }}
          />,
        ]}
        hideErrorSummary={true}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className={
          'flex w-full max-w-[23.4375rem] flex-col items-start gap-[24px] p-0'
        }
      >
        <input type="hidden" value={loader.token} name="token" id="token" />

        <Typography variant={'heading-small'} spacers={[]}>
          {t('new_password')}
        </Typography>

        <Input.Password
          name="new_password"
          label={t('choose_new_password')}
          hint={t('new_password_hint')}
          icon={<Icon name={'lock'} family={'sharp'} variant={'solid'} />}
        />
      </Form>
    </Template>
  )
}
