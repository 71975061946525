import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '../icon'

export interface Props {
  id?: string
  name?: string
  enabled?: boolean
  size?: 'large' | 'small'
  state?: 'default' | 'disabled'
  onChanged?: (enabled: boolean, id?: string) => void
  testId?: string
}

export const Switch = (props: Props) => {
  const [_enabled, setEnabled] = useState<boolean>(props.enabled === true)

  useEffect(() => {
    setEnabled(props.enabled === true)
  }, [props.enabled])

  const toggle = () => {
    if (props.state !== 'disabled') {
      const newEnabled = !_enabled
      setEnabled(newEnabled)
      props.onChanged && props.onChanged(newEnabled, props.id && props.id)
    }
  }

  return (
    <div
      className={twMerge(
        'flex relative justify-center items-center h-[3rem] w-[3.25rem]',
        props.state !== 'disabled' && 'cursor-pointer'
      )}
      onClick={toggle}
      data-testid={props.testId}
    >
      <input
        id={props.id}
        name={props.name}
        value={_enabled ? 'true' : 'false'}
        onChange={() => {}}
        className={'absolute opacity-0 w-0 h-0'}
      />
      <div
        className={twMerge(
          'flex relative justify-center items-center',
          'rounded-[1rem] border-solid border-[1px]',
          'transition-[background-color,border-color] duration-[300ms]',
          props.size === 'small'
            ? 'w-[2.125rem] h-[0.875rem]'
            : 'w-[3.25rem] h-[2rem]',
          props.state === 'disabled'
            ? 'bg-[#E1E1E2] border-[#E1E1E2]'
            : !_enabled
              ? 'bg-transparent border-black'
              : 'bg-green-500 border-green-500'
        )}
      >
        <div
          className={twMerge(
            'absolute rounded-full',
            'transition-[background-color,transform] duration-[300ms]',
            'flex items-center justify-center',
            props.size === 'small'
              ? 'left-[-0.19rem] h-[1.25rem] w-[1.25rem] shadow-[0_0.125rem_0.375rem_0_rgba(40,38,35,0.15)]'
              : 'left-[0.1875rem] h-[1.5rem] w-[1.5rem]',
            _enabled
              ? props.size === 'small'
                ? 'translate-x-[calc(calc(2.125rem-1.25rem)+0.2525rem)]'
                : 'translate-x-[1.26rem]'
              : 'translate-x-0',
            props.state === 'disabled'
              ? 'bg-[#909293]'
              : !_enabled
                ? 'bg-black'
                : 'bg-white'
          )}
        >
          {props.size !== 'small' && (
            <Icon
              name={'xmark'}
              size={'small'}
              family={'sharp'}
              variant={'solid'}
              className={twMerge(
                'opacity-0 transition-opacity duration-[300ms]',
                'text-white',
                !_enabled && 'opacity-100'
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}
