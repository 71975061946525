import { useEffect, useState } from 'react'

import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { getGroupedCategoryConfig } from '@/helpers/assetTitleAndIcon.ts'
import {
  getSavedSelectionEnum,
  saveDropDownSelection,
} from '@/helpers/localStorage.ts'
import { AssetGroupedCategory } from '@/types/asset.ts'

import { Radio } from '../radio'

interface Props {
  onGroupedCategoryChanged: (groupedCategory: AssetGroupedCategory) => void
}

const lastSelectionKey = 'asset-create-grouped-category-selector'

export const AssetGroupedCategorySelector = (props: Props) => {
  const [_selection, setSelection] = useState<AssetGroupedCategory>(
    getSavedSelectionEnum(AssetGroupedCategory, lastSelectionKey) ??
      AssetGroupedCategory.DIGITAL
  )

  useEffect(() => {
    props.onGroupedCategoryChanged(_selection)
  }, [])

  const onCheckChanged = (value: AssetGroupedCategory) => {
    setSelection(value)
    props.onGroupedCategoryChanged(value)
    saveDropDownSelection(lastSelectionKey, value)
  }

  return (
    <div
      className="flex flex-col w-full max-w-[24rem] gap-[16px]"
      data-testid="asset_type_category"
    >
      {Object.values(AssetGroupedCategory).map((gc, index) => (
        <ListItem
          key={`option_${index}`}
          title={getGroupedCategoryConfig(gc)?.title}
          description={getGroupedCategoryConfig(gc)?.description}
          style={'normal'}
          leading={
            <Icon
              name={getGroupedCategoryConfig(gc)?.icon}
              family={'sharp'}
              variant={'solid'}
              size={'medium'}
            />
          }
          trailing={
            <Radio<AssetGroupedCategory>
              id={'contract_token'}
              groupId={'asset_grouped_category'}
              value={gc}
              defaultChecked={gc === _selection}
              onCheckChanged={() => onCheckChanged(gc)}
              size={'medium'}
            />
          }
          onClick={() => onCheckChanged(gc)}
          className={'pl-2 pr-2'}
        />
      ))}
    </div>
  )
}
