import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}

export const NewCustodianDrawer = (props: Props) => {
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    const newCustodianUrl = `/custodian/${actionRes.data}`
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(newCustodianUrl)
    props.onClose()
  }

  return (
    <Drawer
      title={t('add_custodian')}
      description={t('add_custodian_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'create-custodian-form'}
        buttons={[
          <Button.Basic
            key={'btn_add_custodian'}
            label={t('add_custodian')}
            state={
              _submitting === INTENTS.ADD_CUSTODIAN
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.ADD_CUSTODIAN,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <Input.Text
          id="custodian_name"
          name="custodian_name"
          label={t('custodian_name')}
          autoComplete={'off'}
          hint={t('custodian_name_bottom_text')}
          placeholder="e.d. Fireblocks"
        />
      </Form>
    </Drawer>
  )
}
