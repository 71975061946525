import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'

interface BaseProps {
  state?: 'default' | 'disabled'
  type: 'icon' | 'number'
  className?: string
}
interface IconProps extends BaseProps {
  type: 'icon'
  name: string
}
interface NumberProps extends BaseProps {
  type: 'number'
  value: number
}
type Props = IconProps | NumberProps
export const StepMarker = (props: Props) => {
  return (
    <div
      className={twMerge(
        'min-h-6 max-h-6 min-w-6 max-w-6',
        'bg-black text-white',
        'rounded-sm',
        props.state === 'disabled' && 'bg-disabled text-black',
        props.className
      )}
    >
      {props.type === 'icon' ? (
        <Icon
          name={props.name}
          variant={'solid'}
          family={'sharp'}
          size={'xsmall'}
        />
      ) : (
        <Typography variant={'label-extrasmall'} spacers={[]}>
          {props.value}
        </Typography>
      )}
    </div>
  )
}
