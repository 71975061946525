import { t } from 'i18next'
import { ReactElement, ReactNode, useEffect, useState } from 'react'

import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { Props as InputHintProps } from '@/components/input/components/hint.input'
import { Props as InputLabelProps } from '@/components/input/components/label.input'
import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'

import { RowTypes } from '../components/list.dropdown'
import SuggestionView from './views/suggestion.view'

/*

  We have to be smart here - the Suggestion input/dropdown has multiple entries of text;
  either the text is coming from the desktop suggestion.input.tsx component, or its coming
  from the mobile input on the suggestion.view.tsx - in either case as one is updated we
  need to alter the other without falling into a recursive loop - so we must pay attention
  as to where the update is coming from...

  This is our base of filtering though so regardless of where the value has been updated we should
  receive the value here, filter and then update what items should be displayed.
  
*/
interface Props<T> extends ControllerProps<T> {
  items?: ReactElement<RowTypes<T>>[]
  inputValue: string
  inputText: string
  addValueAsEntry?: boolean
  label?: string | ReactElement<InputLabelProps>
  hint?: string | ReactElement<InputHintProps>
  fixedHeader?: ReactNode
  fixedFooter?: ReactNode
  onFilteredItemsChanged?: (values: string[]) => void
  onValueChanged?: (value: string) => void
}

const SuggestionController = <T,>(props: Props<T>) => {
  const [_filteredItems, setFilteredItems] = useState<Props<T>['items']>([
    ...(props.items ?? []),
  ])
  const [_inputValue, setInputValue] = useState<string>(props.inputValue ?? '')
  const [_inputText, setInputText] = useState<string>(props.inputText ?? '')

  useEffect(() => {
    filterListItems(props.inputValue ?? '')
  }, [props.items])

  /*
    This monitors a change from the inputValue prop which should only
    ever (at time of writing this) be updated via the desktop input

    Mobile changes will come from the suggestion.value.tsx onInputChanged()
    callback function
  */
  useEffect(() => {
    setInputValue(props.inputValue ?? '')
    filterListItems(props.inputValue ?? '')
  }, [props.inputValue])
  useEffect(() => {
    setInputText(props.inputText ?? '')
  }, [props.inputText])

  const onViewInputChanged = (value: string) => {
    setInputValue(value)
    filterListItems(value)
    props.onValueChanged && props.onValueChanged(value)
  }

  // This function will filter the props.items collection to only
  // include items that contain the filter string in 1 of their
  // notable props (title, description... etc)
  const filterListItems = (filter: string) => {
    const filteredValues: string[] = []
    const loweredFilter = filter.toLowerCase()
    const filteredItems = (props.items ?? []).filter((i) => {
      if (i.type === ListItem) {
        if (
          ('title' in i.props &&
            i.props.title?.toLowerCase().includes(loweredFilter)) ||
          ('description' in i.props &&
            i.props.description?.toLowerCase().includes(loweredFilter)) ||
          ('trailingLabel' in i.props &&
            i.props.trailingLabel?.toLowerCase().includes(loweredFilter)) ||
          ('value' in i.props &&
            i.props.value?.toLowerCase().includes(loweredFilter))
        ) {
          // ListItems must have a value when pushed into here as we'll return out a
          // list of values
          if ('value' in i.props && i.props.value) {
            filteredValues.push(i.props.value)
          }

          return true
        } else {
          return false
        }
      }
      return true
    })

    // If we have no items remaining lets add in a custom
    // item for the entry the user is typing in
    if (filter.length > 0 && filteredItems.length <= 0) {
      if (props.addValueAsEntry !== false) {
        filteredValues.push(filter)
        filteredItems.push(
          <ListItem
            key={`custom_1`}
            title={filter}
            value={filter}
            className={'pl-2 pr-2'}
          />
        )
      } else {
        filteredItems.push(
          <Typography variant={'label-medium'} spacers={[]}>
            {t('no_matches_found')}
          </Typography>
        )
        filteredItems.push(
          <Typography variant={'paragraph-small'} spacers={[]}>
            {t('please_select_item_from_list')}
          </Typography>
        )
      }
    } else if (
      filteredItems.length === 1 &&
      filteredItems[0].type === ListItem &&
      (('value' in filteredItems[0].props &&
        filteredItems[0].props.value?.toLowerCase() === loweredFilter) ||
        ('title' in filteredItems[0].props &&
          filteredItems[0].props.title?.toLowerCase() === loweredFilter) ||
        ('description' in filteredItems[0].props &&
          filteredItems[0].props.description?.toLowerCase() === loweredFilter))
    ) {
      props.onItemClicked?.(filteredItems[0].props)
    }

    // Throw the filtered items changed props
    props.onFilteredItemsChanged && props.onFilteredItemsChanged(filteredValues)

    // Set the filtered list items
    setFilteredItems(filteredItems)
  }

  return (
    <DropdownController {...props} defaultViewId={'SUGGESTION_INPUT'}>
      <SuggestionView
        items={_filteredItems}
        value={_inputValue}
        text={_inputText}
        label={props.label}
        hint={props.hint}
        fixedFooter={props.fixedFooter}
        fixedHeader={props.fixedHeader}
        onInputChanged={onViewInputChanged}
      />
    </DropdownController>
  )
}
export default SuggestionController
