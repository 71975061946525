import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}
export const NewWorkspaceDrawer = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })

    // Close the modal
    props.onClose()
  }

  return (
    <Drawer
      title={t('create_new_workspace')}
      description={t('create_new_workspace_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'new-workspace-form'}
        buttons={[
          <Button.Basic
            key={'btn_create_workspace'}
            label={t('create_workspace')}
            state={
              _submitting === INTENTS.CREATE_WORKSPACE
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_WORKSPACE,
            }}
            testId={'btn_create_workspace'}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <Input.Text
          state={'default'}
          name={'name'}
          value={''}
          autoComplete={'off'}
          placeholder={t('workspace_name_placeholder')}
          label={t('workspace_name')}
          hint={t('workspace_name_bottom_text')}
          className={'max-w-none'}
          testId="workspace_name"
        />
      </Form>
    </Drawer>
  )
}
