import { createContext } from 'react'

import { IPolicyKey } from '@/types/policies'
import { IUser } from '@/types/user'

type IAuthContext = {
  user: IUser | null
  policies: IPolicyKey[]
  hasPolicy: (
    policy: IPolicyKey | IPolicyKey[],
    requireAll?: boolean
  ) => boolean
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  policies: [],
  hasPolicy: () => false,
} as IAuthContext)

AuthContext.displayName = 'AuthContext'
