import { createContext } from 'react'

import { IWorkspace } from '@/types/workspace'

type IWorkspaceContext = {
  workspaces: IWorkspace[]
  selectedWorkspace: IWorkspace | null
  lastUsedWorkspace: IWorkspace | null
  setWorkspace: (workspace: IWorkspace | string | null) => void
}

export const WorkspaceContext = createContext<IWorkspaceContext>({
  workspaces: [],
  selectedWorkspace: null,
  lastUsedWorkspace: null,
  setWorkspace: () => {},
})
WorkspaceContext.displayName = 'WorkspaceContext'
