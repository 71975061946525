import { ToastProps } from './types'

interface IToastManager {
  showToastHook: React.Dispatch<ToastProps> | null
  setShowToastFunction: (fn: React.Dispatch<ToastProps>) => void
  showToast: (toast: ToastProps) => void
}

export const ToastManager: IToastManager = {
  showToastHook: null,

  setShowToastFunction(fn) {
    this.showToastHook = fn
  },

  showToast(props: ToastProps) {
    if (this.showToastHook) this.showToastHook(props)
  },
}
