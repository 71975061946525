import { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon, IconProps as IconCompProps } from '@/components/icon'
import { Typography } from '@/components/typography'

export type Hierarchy =
  | 'special'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'destructive'
export type Size = 'large' | 'medium' | 'small' | 'xsmall'
export type Shape = 'square' | 'round'
export type Type = 'button' | 'icon' | 'text'
export type Layout = 'icon' | 'label'
export type ButtonState = 'default' | 'disabled' | 'loading'

interface BaseProps {
  id?: string
  hierarchy?: Hierarchy
  shape?: Shape
  size?: Size
  layout?: Layout
  state?: ButtonState
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void

  // Additional props + overwrites
  testId?: string
  className?: string
  withAttributes?: JSX.IntrinsicElements['button']
}

interface IconProps extends BaseProps {
  layout: 'icon'
  icon: IconCompProps
}
interface LabelProps extends BaseProps {
  layout: 'label'
  label: string
}

export type Props = IconProps | LabelProps
export const ButtonShape = (props: Props) => {
  // Set some defaults
  props = {
    hierarchy: 'primary',
    size: 'medium',
    state: 'default',
    ...props,
  }

  // Background Styles
  const backgroundStyle = twMerge(
    props.state === 'disabled'
      ? props.hierarchy === 'special'
        ? 'bg-[#E1E1E2]'
        : props.hierarchy === 'primary'
          ? 'bg-[#E1E1E2]'
          : props.hierarchy === 'secondary'
            ? 'bg-transparent border-[1px] border-solid border-[#E1E1E2]'
            : props.hierarchy === 'tertiary'
              ? 'bg-transparent'
              : 'bg-transparent border-[0.125rem] border-solid border-[#E1E1E2]'
      : props.state === 'loading'
        ? props.hierarchy === 'special'
          ? 'bg-red-500'
          : props.hierarchy === 'primary'
            ? 'bg-black'
            : props.hierarchy === 'secondary'
              ? 'bg-transparent border-[1px] border-solid border-[#E1E1E2]'
              : props.hierarchy === 'tertiary'
                ? 'bg-transparent'
                : 'bg-transparent border-[0.125rem] border-solid border-[#E1E1E2]'
        : props.hierarchy === 'special'
          ? 'bg-red-500 active:bg-[#E1E1E2]'
          : props.hierarchy === 'primary'
            ? 'bg-black active:bg-[#E1E1E2]'
            : props.hierarchy === 'secondary'
              ? 'bg-white border-[1px] border-solid border-black hover:border-[#E1E1E2] active:bg-gray-100 active:border-black'
              : props.hierarchy === 'tertiary'
                ? 'bg-transparent active:bg-[#F5F5F6] hover:bg-[#F5F5F6]'
                : 'bg-transparent border-[0.125rem] border-solid border-red-500 active:bg-red-100'
  )

  // Foreground/text styles
  const textStyle = twMerge(
    props.state === 'disabled'
      ? props.hierarchy === 'special'
        ? 'text-gray-400'
        : props.hierarchy === 'primary'
          ? 'text-gray-400'
          : props.hierarchy === 'secondary'
            ? 'text-gray-400'
            : props.hierarchy === 'tertiary'
              ? 'text-gray-400'
              : 'text-gray-400'
      : props.state === 'loading'
        ? props.hierarchy === 'special'
          ? 'text-white'
          : props.hierarchy === 'primary'
            ? 'text-white'
            : props.hierarchy === 'secondary'
              ? 'text-black'
              : props.hierarchy === 'tertiary'
                ? 'text-black'
                : 'text-black'
        : props.hierarchy === 'special'
          ? 'text-white group-active:text-black'
          : props.hierarchy === 'primary'
            ? 'text-white group-active:text-black'
            : props.hierarchy === 'secondary'
              ? 'text-black'
              : props.hierarchy === 'tertiary'
                ? 'text-black'
                : 'text-black'
  )

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      e.stopPropagation()
      props.onClick(e)
    }
  }

  return (
    <button
      id={props.id}
      aria-label={'label' in props ? props.label : props.icon.name}
      className={twMerge(
        'group flex flex-row content-center items-center',
        'transition-[opacity,background-color,text-color] duration-300',
        props.shape === 'round' ? 'rounded-full' : 'rounded-md',
        props.state === 'disabled' ? 'cursor-default' : 'cursor-pointer',
        backgroundStyle,
        props.size === 'large'
          ? 'min-h-[3.5rem] min-w-[3.5rem] max-h-[3.5rem] max-w-[3.5rem]'
          : props.size === 'medium'
            ? 'min-h-[2.75rem] min-w-[2.75rem] max-h-[2.75rem] max-w-[2.75rem]'
            : props.size === 'small'
              ? 'min-h-[2.25rem] min-w-[2.25rem] max-h-[2.25rem] max-w-[2.25rem]'
              : 'min-h-[1.5rem] min-w-[1.5rem] max-h-[1.5rem] max-w-[1.5rem]',
        props.className
      )}
      disabled={props.state === 'disabled' || props.state === 'loading'}
      onClick={onButtonClick}
      {...(props.testId && { 'data-cy': props.testId })}
      {...props.withAttributes}
    >
      {props.state === 'loading' ? (
        <span className={'flex flex-1 justify-center items-center'}>
          <Icon
            type={'loader'}
            color={
              props.hierarchy === 'special' || props.hierarchy === 'primary'
                ? 'white'
                : 'black'
            }
            size={
              props.size === 'large'
                ? 'medium'
                : props.size === 'medium'
                  ? 'small'
                  : 'xsmall'
            }
          />
        </span>
      ) : (
        <span
          className={
            'flex flex-1 flex-row justify-center items-center overflow-hidden'
          }
        >
          {props.layout === 'icon' ? (
            <Icon
              name={props.icon.name}
              variant={props.icon.variant ?? 'solid'}
              family={
                props.icon.variant === 'brands'
                  ? undefined
                  : (props.icon.family ?? 'sharp')
              }
              size={props.size}
              className={twMerge(
                textStyle,
                props.hierarchy === 'destructive' &&
                  props.state === 'default' &&
                  'text-red-500'
              )}
            />
          ) : (
            <Typography
              variant={
                props.size === 'xsmall'
                  ? 'label-extrasmall'
                  : props.size === 'small'
                    ? 'label-small'
                    : props.size === 'medium'
                      ? 'label-medium'
                      : 'label-large'
              }
              className={textStyle}
            >
              {props.label.substring(0, 2)}
            </Typography>
          )}
        </span>
      )}
    </button>
  )
}
