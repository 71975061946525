import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IOrganisation, IOrganisationBase } from '@/types/organisation'

export const getAllOrgs = async (): Promise<BaseResponse<IOrganisation[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client
    .get<BaseResponse<IOrganisation[]>>(`/org/all`)
    .then((response) => {
      return response.data
    })
}

export const updateOrg = async (
  orgData: IOrganisationBase,
  id: string
): Promise<BaseResponse<IOrganisation>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client
    .put<BaseResponse<IOrganisation>>(`/org/${id}`, orgData)
    .then((response) => {
      return response.data
    })
}
