import { useContext } from 'react'

import { SelectedLanguageContext } from './context'

export const useSelectedLanguage = () => {
  const context = useContext(SelectedLanguageContext)

  if (!context) {
    throw new Error('Did you forget to setup <SelectedLanguageContextProvider>')
  }

  return context
}
