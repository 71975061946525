import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'

import { IncreaseDecreaseInputProps } from '../types'

export const IncreaseDecreaseInput = ({
  containerClassName,
  ...props
}: IncreaseDecreaseInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFocus = () => {
    inputRef.current?.focus()
  }

  const [value, setValue] = useState(
    Number(props.defaultValue || 0)?.toFixed(1)
  )

  const handleDecrease = () => {
    if (props.disabled) return

    const newValue = Number(value) - 0.1
    setValue(newValue.toFixed(1))

    // props.onChange(Number(value) - 0.1)
  }

  const handleIncrease = () => {
    if (props.disabled) return

    const newValue = Number(value) + 0.1
    setValue(newValue.toFixed(1))
  }

  return (
    <div
      className={twMerge(
        'flex flex-col space-y-2',
        containerClassName,
        props.disabled ? 'cursor-disabled' : ''
      )}
    >
      {props.label && (
        <label htmlFor={props.id}>
          <Typography
            variant="label-small"
            className={twMerge(props.labelClassName)}
          >
            {props.label}
          </Typography>
        </label>
      )}

      <div
        className={twMerge(
          'flex flex-row w-full gap-x-3 text-gray-900 placeholder:text-gray-400 ring-0',
          props.className
        )}
        onClick={handleFocus}
      >
        <Icon
          name="minus"
          variant="solid"
          size="sm"
          className="bg-white border-[1px] border-black rounded-md px-4 cursor-pointer hover:bg-[#F2F2F2] transition-all duration-200 ease-in-out"
          onClick={handleDecrease}
        />

        <div className="ring-inset rounded-[0.25rem] border-0 p-3 ring-1 ring-[#E1E1E2] focus:ring-inset focus:ring-black w-[7.5rem]">
          <input
            {...props}
            ref={inputRef}
            className="ring-0 focus:ring-0 border-none w-full bg-transparent p-0 outline-none"
            value={value}
          />
        </div>

        <Icon
          name="plus"
          variant="solid"
          size="sm"
          className="bg-white border-[1px] border-black rounded-md px-4 cursor-pointer hover:bg-[#F2F2F2] transition-all duration-200 ease-in-out"
          onClick={handleIncrease}
        />
      </div>
      <Typography variant="paragraph-small">{props.bottomText}</Typography>
    </div>
  )
}
