import { Currency } from '@/constants/currencies.ts'

export interface BaseProps {
  data: GraphData[]
  format: 'CURRENCY' | 'NUMBER'
  currency?: Currency
  zeroLabel?: string
  showTotal?: boolean
  showLegend?: boolean
  className?: string
}
export interface GraphData {
  label: string
  value: number
  htmlColor?: string
  hideIfZero?: boolean
}

export const canHide = (data: GraphData): boolean => {
  return data.hideIfZero === true && data.value === 0
}
