import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const HealthDetailSkeleton: React.FC = (): JSX.Element => (
  <>
    <Skeleton className="h-10 mb-6" width="100px" />
    <Skeleton className="h-6 mb-2" width="60%" />
    <Skeleton className="h-5 mb-[60px]" width="60%" />

    <Skeleton className="h-10" width="40%" />
  </>
)
export default HealthDetailSkeleton
