import { t } from 'i18next'
import { twMerge } from 'tailwind-merge'

import Card from '@/components/card'

import { Modal } from '..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  inviteCount: number
  workspaceName: string
}
export const WorkspaceInviteSentModal = (props: Props) => {
  return (
    <Modal visible={props.visible} onClose={props.onClose}>
      <div
        className={twMerge(
          'flex p-[2rem] flex-col w-full tablet:p-[4rem]',
          'items-center justify-center relative',
          'bg-[url("/bg_2.jpg")] bg-cover bg-left-center'
        )}
      >
        <div className={'z-[1] flex flex-1 w-full h-full bg-white rounded-sm'}>
          <Card.PageState
            type={'success'}
            title={t('invitation_sent')}
            description={
              <span>
                <span>
                  {t('invitation_sent_description', {
                    inviteCount: props.inviteCount ?? 1,
                    workspaceName: props.workspaceName,
                  })}
                </span>
                <br />
                <br />
                <span>{t('invitation_sent_description_2')}</span>
              </span>
            }
            button={{ label: t('done'), onClick: props.onClose }}
            className={'max-w-none'}
          />
        </div>
      </div>
    </Modal>
  )
}
