import { useEffect, useRef, useState } from 'react'

import { Icon } from '../icon'

export type CheckedState = 'checked' | 'indeterminate' | 'unchecked'
export interface Props {
  id: string
  name?: string
  checked?: CheckedState
  size?: 'large' | 'medium'
  state?: 'default' | 'disabled'
  testId?: string

  /*
    The onCheckChanged callback requires the function to return back
    a 'confirmed' value. EG; if the callback says "checked" the callback
    can return back "actually its unchecked" and we'll change to 'unchecked'.
    Usually you can simply return the value passed through but this gives us
    more flexibility on value checking.
  */
  onCheckChanged?: (id: string, state: CheckedState) => Promise<CheckedState>
}

export const Checkbox = (props: Props) => {
  props = {
    size: 'medium',
    state: 'default',
    ...props,
  }

  const [_checked, _setChecked] = useState<CheckedState>(
    props.checked ?? 'unchecked'
  )
  const checkboxRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  const setChecked = (state?: CheckedState) => {
    _setChecked(state ?? 'unchecked')
    if (checkboxRef?.current) {
      checkboxRef.current.indeterminate = state === 'indeterminate'
    }
  }

  const onCheckChange = async () => {
    const newCheck: CheckedState =
      _checked === 'indeterminate'
        ? 'checked'
        : _checked === 'checked'
          ? 'unchecked'
          : 'checked'

    // Update the check state
    setChecked(newCheck)

    // Throw the check change event
    if (props.onCheckChanged) {
      const confirmedState = await props.onCheckChanged(props.id, newCheck)
      if (confirmedState !== newCheck) {
        setChecked(confirmedState)
      }
    }
  }

  return (
    <div
      className={
        'flex w-12 h-12 max-w-[3rem] max-h-[3rem] justify-center items-center'
      }
    >
      <label
        htmlFor={props.id ?? props.name}
        className={
          'flex flex-1 justify-center items-center relative w-full h-full cursor-pointer'
        }
      >
        {_checked === 'indeterminate' ? (
          <Icon
            name="square-minus"
            size={props.size}
            family="sharp"
            variant={'solid'}
            className={
              props.state === 'disabled' ? 'text-[#E1E1E2]' : 'text-[#212427]'
            }
          />
        ) : _checked === 'checked' ? (
          <Icon
            name="square-check"
            size={props.size}
            family="sharp"
            variant={'solid'}
            className={
              props.state === 'disabled' ? 'text-[#E1E1E2]' : 'text-[#212427]'
            }
          />
        ) : (
          <Icon
            name="square"
            size={props.size}
            family="sharp"
            className={
              props.state === 'disabled' ? 'text-[#E1E1E2]' : 'text-[#212427]'
            }
          />
        )}
        <input
          ref={checkboxRef}
          id={props.id ?? props.name}
          aria-describedby={props.name && `${props.name}-description`}
          name={props.name}
          type="checkbox"
          disabled={props.state === 'disabled'}
          onChange={onCheckChange}
          value={_checked === 'checked' ? 'true' : 'false'}
          checked={_checked === 'checked'}
          className={'absolute opacity-0 w-full h-full cursor-pointer'}
          data-testid={props.testId}
        />
      </label>
    </div>
  )
}
