import { InputFrame } from './components/frame.input'
import { InputHint } from './components/hint.input'
import { InputLabel } from './components/label.input'
import { InputAddress } from './variants/address.input'
import { InputAuction } from './variants/auction.input'
import { InputCountry } from './variants/country.input.tsx'
import { CurrencyInput } from './variants/currency'
import { DateInput } from './variants/date.input'
import {
  DefaultInput,
  Props as DefaultInputProps,
} from './variants/default.input'
import { InputDefendant } from './variants/defendants.input'
import { InputDropdown } from './variants/dropdown.input'
import { InputIDNumber } from './variants/idnumber.input'
import { IncreaseDecreaseInput } from './variants/increase-decrease'
import { InputNumber } from './variants/number.input'
import { PasswordInput } from './variants/password.input'
import { PinInput } from './variants/pin.input'
import { InputSearch } from './variants/search.input'
import { SearchOptionsInput } from './variants/search-options'
import { InputSuggestion } from './variants/suggestion.input'
import { TextareaInput } from './variants/textarea'

const _text = (props: DefaultInputProps) => (
  <DefaultInput type={'text'} {...props} />
)
const _email = (props: DefaultInputProps) => (
  <DefaultInput {...props} type={'email'} />
)

const _tel = (props: DefaultInputProps) => (
  <DefaultInput {...props} type={'tel'} />
)

export default {
  Text: _text,
  Email: _email,
  Number: InputNumber,
  Password: PasswordInput,
  TextArea: TextareaInput,
  Currency: CurrencyInput,
  Date: DateInput,
  Defendant: InputDefendant,
  IncreaseDecrease: IncreaseDecreaseInput,
  Dropdown: InputDropdown,
  Suggest: InputSuggestion,
  Search: InputSearch,
  SearchOptions: SearchOptionsInput,
  Address: InputAddress,
  Country: InputCountry,
  IDNumber: InputIDNumber,
  Auction: InputAuction,
  Pin: PinInput,
  Tel: _tel,

  Component: {
    Label: InputLabel,
    Hint: InputHint,
    Frame: InputFrame,
  },
}
