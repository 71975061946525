import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'

import LanguageView from './views/language.view'
import ProfileView from './views/profile.view'
import WorkspaceView from './views/workspace.view'

interface Props extends ControllerProps<unknown> {}
const ProfileController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      testId={'dropdown_profile'}
      defaultViewId={'PROFILE'}
      closeOnItemClick={false}
    >
      <ProfileView />
      <LanguageView />
      <WorkspaceView />
    </DropdownController>
  )
}
export default ProfileController
