import { MouseEvent, ReactElement } from 'react'

import { Props as ButtonShapeProps } from '@/components/button/variants/shape.button'
import { IconProps } from '@/components/icon'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame, Props as FrameProps } from '../components/frame.input'
import { InputHint, Props as HintProps } from '../components/hint.input'
import { InputLabel, Props as LabelProps } from '../components/label.input'

export type Props = {
  state?: 'default' | 'disabled' | 'error' | 'readonly'
  id?: string
  label?: string | ReactElement<LabelProps>
  hint?: string | ReactElement<HintProps>
  prefix?: string
  suffix?: string
  icon?: ReactElement<IconProps>
  trailingIcon?: ReactElement<IconProps>
  trailingButton?: ReactElement<ButtonShapeProps>
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  elementClassName?: string
  className?: string
  testId?: string
  min?: number
  dynamicInput?: boolean
} & Omit<FrameProps, 'state'>
export const DefaultInput = (props: Props) => {
  return (
    <InputField
      className={props.className}
      testId={props.testId}
      dynamicInput={props.dynamicInput}
      label={
        typeof props.label === 'string' ? (
          <InputLabel
            state={props.state === 'disabled' ? 'disabled' : 'default'}
            text={props.label}
            size={'medium'}
          />
        ) : props.label !== undefined ? (
          props.label
        ) : undefined
      }
      hint={
        typeof props.hint === 'string' ? (
          <InputHint
            state={props.state === 'disabled' ? 'disabled' : 'default'}
            label={props.hint}
            style={'hint'}
          />
        ) : props.hint !== undefined ? (
          props.hint
        ) : undefined
      }
      element={
        <InputElement
          id={props.id}
          variant={props.variant ?? 'default'}
          icon={props.icon}
          state={props.state ?? 'default'}
          onClick={props.onClick}
          trailing={
            props.trailingIcon
              ? {
                  icon: props.trailingIcon,
                }
              : props.trailingButton
                ? {
                    button: props.trailingButton,
                  }
                : undefined
          }
          input={<InputFrame {...props} state={'default'} />}
          prefix={props.prefix}
          suffix={props.suffix}
          className={props.elementClassName}
          min={props.min}
          testId={'input_wrapper'}
        />
      }
    />
  )
}
