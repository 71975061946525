export const ExtractStatusFrom = (
  res: unknown,
  defaultStatusCode?: number
): number => {
  try {
    return res && typeof res === 'object' && 'status' in res && res.status
      ? Number(res.status)
      : (defaultStatusCode ?? 500)
  } catch (e) {
    return defaultStatusCode ?? 500
  }
}
