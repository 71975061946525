import StaticPageBanner from './static/page.static.banner'
import { PageBanner } from './variants/page.banner'
import { SiteBanner } from './variants/site.banner'
import { ToastBanner } from './variants/toast.banner'
import { WelcomeBanner } from './variants/welcome.banner'

export default {
  Page: PageBanner,
  Site: SiteBanner,
  Toast: ToastBanner,
  Welcome: WelcomeBanner,

  StaticPage: StaticPageBanner,
}
