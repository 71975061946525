/*
    Used to test a provided value to check if it compares against
    a provided regex string. Allows for undefined/null values to be passed
    through to avoid issue - will return false if these values are
    provided

    Can be used in conjuction with the pre-build REGEX test
    found at @/constants/regex.ts
*/

// Checks if a value IS a regex match
export const Is = (
  value: string | undefined | null,
  regex?: RegExp
): value is string => {
  return !!value && (regex ? regex.test(value) : true)
}

// Checks if a value IS NOT a regex match
export const IsNot = (
  value: string | undefined | null,
  regex?: RegExp
): value is undefined | null => {
  return !Is(value, regex)
}
