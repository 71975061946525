import '@/locale'

import Hotjar from '@hotjar/browser'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'

import ContextsProviders from '@/contexts'

// @Hotjar
let siteId = 0
const hotjarVersion = 6

switch (import.meta.env.MODE) {
  case 'main': // production - even though branch main is sandbox
    siteId = 5102244
    break
  case 'demo':
    siteId = 5102250
    break
  case 'staging':
    siteId = 5102253
    break
  case 'localdev':
    siteId = 5102253
    break
  case 'development':
    siteId = 5102254
    break
  default:
    break
}
if (siteId !== 0) Hotjar.init(siteId, hotjarVersion)
// @Hotjar

type ProvidersProps = {
  children: JSX.Element | JSX.Element[]
}

const Providers: React.FC<ProvidersProps> = ({ children }): JSX.Element => {
  return (
    <React.StrictMode>
      <ContextsProviders type={'GLOBAL_PROVIDERS'}>
        {children}
      </ContextsProviders>
    </React.StrictMode>
  )
}

const LDProvider = withLDProvider({
  clientSideID: '66d6822ba7c64b100585ef87',
  context: {
    kind: 'client',
    key: 'arc-web',
  },
})(Providers as React.ComponentType) as React.FC<ProvidersProps>

export default LDProvider
