import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { getWalletSourceConfig } from '@/helpers/assetTitleAndIcon.ts'
import {
  getSavedSelectionEnum,
  saveDropDownSelection,
} from '@/helpers/localStorage.ts'
import { useOrg } from '@/hooks/queries/useOrg'
import { DigitalWalletSource } from '@/types/asset.ts'

interface Props {
  onWalletSourceChanged: (walletSource: DigitalWalletSource) => void
}

const lastSelectionKey = 'asset-create-wallet-source'

export const AssetDigitalWalletSourceSelector = (props: Props) => {
  const { org } = useOrg()

  const initialSelection = getSavedSelectionEnum(
    DigitalWalletSource,
    lastSelectionKey
  )

  const defaultSelection =
    initialSelection === DigitalWalletSource.CUSTOM_TRACKER
      ? DigitalWalletSource.GENERATED
      : (initialSelection ?? DigitalWalletSource.GENERATED)

  const [_selection, setSelection] =
    useState<DigitalWalletSource>(defaultSelection)

  useEffect(() => {
    props.onWalletSourceChanged(_selection)
  }, [])

  const onWalletSourceChanged = (ws: DigitalWalletSource): void => {
    setSelection(ws)
    saveDropDownSelection(lastSelectionKey, ws)
    props.onWalletSourceChanged(ws)
  }

  return (
    <div className={'w-full'}>
      <Input.Dropdown
        name="asset_digital_wallet_source_selector"
        testId={'asset_digital_wallet_source_selector'}
        hint={t('choose_digital_asset_type')}
        icon={
          <Icon
            name={getWalletSourceConfig(_selection)?.icon}
            variant={'solid'}
            family={'sharp'}
            size={'medium'}
          />
        }
        text={getWalletSourceConfig(_selection)?.title}
        items={Object.values(DigitalWalletSource)
          .filter(
            (source) =>
              source === DigitalWalletSource.GENERATED ||
              (source === DigitalWalletSource.CUSTOM_TRACKER &&
                org?.self_managed_digital_assets_enabled) ||
              (source === DigitalWalletSource.EXISTING &&
                org?.existing_wallet_digital_assets_enabled)
          )
          .map((ws, index) => (
            <ListItem
              key={`option_${index}`}
              title={getWalletSourceConfig(ws)?.title}
              description={getWalletSourceConfig(ws)?.description}
              style={'normal'}
              leading={
                <Icon
                  name={getWalletSourceConfig(ws)?.icon}
                  family={'sharp'}
                  variant={'solid'}
                  size={'medium'}
                />
              }
              {...(_selection &&
                _selection === ws && {
                  trailing: (
                    <Icon
                      name={'circle-check'}
                      family={'sharp'}
                      variant={'solid'}
                      size={'medium'}
                    />
                  ),
                })}
              onClick={() => onWalletSourceChanged(ws)}
              className={'pl-2 pr-2'}
            />
          ))}
      />
    </div>
  )
}
