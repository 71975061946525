import { useQuery } from '@tanstack/react-query'

import { getWorkspaceMembers } from '@/api/workspaces/v1'
import { useWorkspace } from '@/contexts/workspace'

export const key = 'workspace-members'
export const useWorkspaceMembers = () => {
  const { selectedWorkspace } = useWorkspace()
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key, selectedWorkspace?.id],
    queryFn: () => getWorkspaceMembers(selectedWorkspace?.id ?? ''),
    enabled: !!selectedWorkspace,
  })

  return { members: data?.data ?? [], isLoading, refetch }
}
