/* eslint-disable no-case-declarations */

import { t } from 'i18next'

import { getCostsFromForm } from '@/actions/create-asset/assetForm.ts'
import { createAsset } from '@/api'
import { uploadFiles } from '@/api/files/'
import { Currency } from '@/constants/currencies.ts'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import {
  getSerialMissingErrorMsg,
  isSerialRequired,
} from '@/helpers/assetRules.ts'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import {
  AssetCategory,
  AssetGroupedCategory,
  DigitalWalletSource,
  IAssetCost,
  IAssetStatusName,
  ICreateAsset,
  ICreateDigitalAsset,
  ICreateFiatCashAsset,
  ICreateHighValuePersonalPropertyAsset,
  ICreateRealEstateAsset,
  TangibleTypeRealEstate,
  TangibleTypeVehicle,
} from '@/types/asset'

export const CreateAsset = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const asset_grouped_category = formData
    .get('asset_grouped_category')
    ?.toString() as AssetGroupedCategory
  const asset_category = formData
    .get('asset_category')
    ?.toString() as AssetCategory
  const asset_category_type = formData.get('asset_category_type')?.toString()
  const asset_digital_wallet_source = formData
    .get('asset_digital_wallet_source')
    ?.toString() as DigitalWalletSource
  const asset_currency = formData.get('asset_currency')?.toString() as Currency
  const asset_manufacturer = formData
    .get('asset_manufacturer')
    ?.toString()
    .trim()
  const asset_model = formData.get('asset_model')?.toString().trim()
  const asset_country = formData.get('asset_country')?.toString()
  const asset_address = formData.get('asset_address')?.toString().trim()
  const asset_defendant = formData.get('asset_defendant')?.toString()
  const asset_operation = formData.get('asset_operation')?.toString()
  const asset_status = formData.get('asset_status')?.toString()
  const asset_status_at = formData.get('asset_status_at')?.toString()

  const asset_notes = formData.get('asset_notes')?.toString()
  const asset_total_value_currency = formData
    .get('asset_total_value_currency')
    ?.toString()
  const asset_total_value = formData.get('asset_total_value')?.toString()
  const asset_identifier = formData.get('asset_identifier')?.toString().trim()

  const token_network = formData.get('token_network')?.toString()
  const token_ticker = formData.get('token_ticker')?.toString()
  const token_custodian_id = formData.get('token_custodian_id')?.toString()
  const token_custodian_name = formData.get('token_custodian_name')?.toString()
  const token_blockchain_address = formData
    .get('token_blockchain_address')
    ?.toString()

  const token_contract_address = formData
    .get('token_contract_address')
    ?.toString()
  const token_balance = formData.get('token_balance')?.toString()
  const supported_asset_key = formData.get('supported_asset_key')?.toString()
  const external_ref = formData.get('external_ref')?.toString().trim()

  // CURRENTLY NOT USED
  // const asset_name = formData.get('asset_name')?.toString()
  // const asset_tag = formData.getAll('asset_tag')?.toString()
  // const token_total = formData.get('token_total')?.toString()

  // Check basic details
  if (IsNot(asset_defendant, REGEX.IS_CASE_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'asset_defendant',
        error: t('subject_must_be_provided'),
      },
    ])
  } else if (IsNot(external_ref, REGEX.IS_ASSET_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'external_ref',
        error: t('missing_identifier'),
      },
    ])
  }

  // Handle files
  const files = [
    ...formData.getAll('asset_photos'),
    ...formData.getAll('asset_files'),
  ]?.filter((file) => file && file instanceof File && file.size !== 0) as File[]

  // Upload files
  const filesKeys = await uploadFiles(files)

  // Build up a list of costs
  const costs: IAssetCost[] = getCostsFromForm(formData)

  // Depending on the category/type we need to create
  // our asset object to submit
  let new_asset: ICreateAsset | null = null

  // Run through and create the relevant object
  switch (asset_grouped_category) {
    case AssetGroupedCategory.DIGITAL:
      switch (asset_digital_wallet_source) {
        case DigitalWalletSource.EXISTING:
          new_asset = {
            category: 'DIGITAL',
            type: token_contract_address ? 'ERC20' : 'NATIVE',
            wallet: {
              type: 'WARM',
              source: DigitalWalletSource.EXISTING,
              address: token_blockchain_address ?? '',
            },
            contract_address: token_contract_address ?? '',
            network: token_network ?? '',
            ticker: token_ticker ?? '',
            amount: Number(token_balance),
          } as ICreateDigitalAsset

          if (!token_ticker) {
            return FormErrorsManager.addErrors([
              {
                fieldName: 'ticker',
                error: t('ticker_must_be_selected22'),
              },
            ])
          }

          break
        case DigitalWalletSource.GENERATED:
          new_asset = {
            category: 'DIGITAL',
            type: token_contract_address ? 'ERC20' : 'NATIVE',
            wallet: {
              type: 'WARM',
              source: DigitalWalletSource.GENERATED,
            },
            contract_address: token_contract_address ?? '',
            network: token_network ?? '',
            ticker: token_ticker ?? '',
            custodian: {
              provider: token_custodian_name,
              id: token_custodian_id,
              supported_asset_key,
            },
          } as ICreateDigitalAsset

          if (!token_ticker || !token_custodian_id || !token_custodian_name) {
            return FormErrorsManager.addErrors([
              ...(!token_ticker
                ? [
                    {
                      fieldName: 'token_ticker',
                      error: t('ticker_must_be_provided'),
                    },
                  ]
                : []),
              ...(!token_custodian_id || !token_custodian_name
                ? [
                    {
                      fieldName: 'token_custodian',
                      error: t('custodian_must_be_provided'),
                    },
                  ]
                : []),
            ])
          }

          break
        case DigitalWalletSource.CUSTOM_TRACKER:
          new_asset = {
            category: 'DIGITAL',
            type: token_contract_address ? 'ERC20' : 'NATIVE',
            contract_address: token_contract_address ?? '',
            ticker: token_ticker ?? '',
            network: token_network ?? '',
            amount: Number(token_balance),
            is_self_managed: true,
          } as ICreateDigitalAsset

          if (!token_network || !token_ticker) {
            return FormErrorsManager.addErrors([
              ...(!token_network
                ? [
                    {
                      fieldName: 'token_network',
                      error: t('network must be provided'),
                    },
                  ]
                : []),
              ...(!token_ticker
                ? [
                    {
                      fieldName: 'token_ticker',
                      error: t('ticker_must_be_provided'),
                    },
                  ]
                : []),
            ])
          }

          break
        default:
          // Unsupported asset category
          break
      }
      break
    case AssetGroupedCategory.TANGIBLE:
      switch (asset_category as AssetCategory) {
        case AssetCategory.FIAT_CASH:
          new_asset = {
            category: AssetCategory.FIAT_CASH,
            amount: Number(asset_total_value),
            currency: asset_currency,
          } as ICreateFiatCashAsset
          break
        case AssetCategory.VEHICLE:
          new_asset = {
            category: AssetCategory.VEHICLE,
            type: asset_category_type as TangibleTypeVehicle,
            make: asset_manufacturer,
            model: asset_model,
            vin: asset_identifier ?? '',
            valuations: [
              {
                currency: asset_total_value_currency as Currency,
                value: Number(asset_total_value) ?? 0,
              },
            ],
          }
          break
        case AssetCategory.REAL_ESTATE:
          new_asset = {
            category: AssetCategory.REAL_ESTATE,
            type: asset_category_type as TangibleTypeRealEstate,
            location: {
              address: asset_address,
              country: asset_country,
            },
            plot_number: asset_identifier,
            valuations: [
              {
                currency: asset_total_value_currency as Currency,
                value: Number(asset_total_value) ?? 0,
              },
            ],
          } as ICreateRealEstateAsset
          break
        case AssetCategory.PERSONAL_PROPERTY:
          new_asset = {
            category: AssetCategory.PERSONAL_PROPERTY,
            type: asset_category_type ?? '',
            serial_number: asset_identifier ?? '',
            valuations: [
              {
                currency: asset_total_value_currency as Currency,
                value: Number(asset_total_value) ?? 0,
              },
            ],
          } as ICreateHighValuePersonalPropertyAsset
          break
      }
      break
    default:
    // Unsupported or not set type
  }

  if (asset_category && isSerialRequired(asset_category) && !asset_identifier) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'asset_identifier',
        error: getSerialMissingErrorMsg(asset_category),
      },
    ])
  }

  if ((Number(asset_total_value) ?? 0) < 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'asset_total_value',
        error: t('total_value_value_must_be_positive'),
      },
    ])
  }

  // Check we have a new asset value
  if (new_asset === null) {
    console.error(
      'invalid asset request ' +
        asset_grouped_category?.toString() +
        ', ' +
        asset_category?.toString() +
        ', ' +
        asset_category_type?.toString()
    )
    throw new Error(t('invalid_asset_information_provided'))
  }

  // Add in additional values that apply to all assets
  const enteredNotes = (asset_notes ?? '').trim()
  let newNote = undefined
  if (enteredNotes.length > 0) {
    newNote = [
      {
        content: enteredNotes,
      },
    ]
  }
  new_asset = {
    ...new_asset,
    files: filesKeys,
    operation_id: asset_operation,
    defendant_id: asset_defendant,
    costs: costs,
    statuses: [
      {
        name: (asset_status as IAssetStatusName) ?? 'PENDING',
        happened_at: asset_status_at
          ? new Date(asset_status_at).toISOString()
          : undefined,
      },
    ],
    notes: newNote,
    external_ref,
  }

  const res = await createAsset(new_asset)

  // Check response
  if (res.error) {
    throw new Error(res.message)
  }

  return {
    error: false,
    message: t('asset_created'),
    completion: 'COMPLETE',
    data: res.data,
  }
}
