import { t } from 'i18next'

import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { Radio } from '@/components/radio'
import { SupportedAssetType } from '@/types/asset'

interface Props {
  defaultType: SupportedAssetType
  onTypeChanged: (type: SupportedAssetType) => void
}

export const SupportedAssetTypeSelector = (props: Props) => {
  const onCheckChanged = (
    _id: string,
    _groupId: string,
    value: SupportedAssetType
  ) => {
    props.onTypeChanged(value)
  }

  return (
    <div
      className="flex flex-col w-full max-w-[24rem] gap-6"
      data-testid="asset_type_category"
    >
      <ListItem
        title={t('contract_token')}
        style={'bold'}
        description={t('contract_token_description')}
        leading={
          <Icon
            name={'file'}
            family={'sharp'}
            variant={'solid'}
            size={'medium'}
          />
        }
        trailing={
          <Radio<SupportedAssetType>
            id={'contract_token'}
            groupId={'asset_grouped_category'}
            value={'CONTRACT TOKEN'}
            defaultChecked={props.defaultType === 'CONTRACT TOKEN'}
            onCheckChanged={onCheckChanged}
            size={'medium'}
          />
        }
      />
      <ListItem
        title={t('native_token')}
        style={'bold'}
        description={t('native_token_description')}
        leading={
          <Icon
            name={'coin'}
            family={'sharp'}
            variant={'solid'}
            size={'medium'}
          />
        }
        trailing={
          <Radio<SupportedAssetType>
            id={'native_token'}
            groupId={'asset_grouped_category'}
            value={'NATIVE TOKEN'}
            defaultChecked={props.defaultType === 'NATIVE TOKEN'}
            onCheckChanged={onCheckChanged}
            size={'medium'}
          />
        }
      />
    </div>
  )
}
