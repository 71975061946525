import { ReactNode, useEffect, useState } from 'react'

import { FormErrorsContext } from './context'
import { FormErrorsManager } from './manager'
import { FormError } from './types'

interface Props {
  children: ReactNode
}
export const FormErrorsProvider = (props: Props) => {
  const [_errors, setErrors] = useState<FormError[]>([])

  useEffect(() => {
    FormErrorsManager.setAddErrorsFunction((errors: FormError[]) => {
      setErrors((currErrors) => [
        ...currErrors.filter(
          (e) => !errors.find((ea) => ea.fieldName === e.fieldName)
        ),
        ...errors,
      ])
    })
    FormErrorsManager.setClearErrorsFunction(() => {
      setErrors([])
    })
  }, [])

  const clearError = (fieldName: string) => {
    setErrors((currErrors) => {
      if (currErrors.find((e) => e.fieldName === fieldName)) {
        // Found an error in our collection for this fieldname
        // so we'll clear it out
        return [...currErrors.filter((e) => e.fieldName !== fieldName)]
      }
      return currErrors
    })
  }

  return (
    <FormErrorsContext.Provider
      value={{
        errors: _errors,
        clearError: clearError,
      }}
    >
      {props.children}
    </FormErrorsContext.Provider>
  )
}
