import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { INTENTS } from '@/actions/intents'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Chip from '@/components/chip'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Typography } from '@/components/typography'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { useModal } from '@/contexts/interface'
import { ToastManager } from '@/contexts/toast'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon'
import { useOrgs } from '@/hooks/queries/useOrgs'
import { ActionResponse } from '@/types/actions'
import { ISupportedAsset } from '@/types/custodian'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}
export const NewEscrowDrawer = (props: Props) => {
  const navigate = useNavigate()
  const { setModal } = useModal()
  const { orgs } = useOrgs()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_selectedPartyAOrgId, setSelectedPartyAOrgId] = useState<
    string | null
  >(null)
  const [_selectedPartyBOrgId, setSelectedPartyBOrgId] = useState<
    string | null
  >(null)
  const [_selectedAssets, setSelectedAssets] = useState<ISupportedAsset[]>([])

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(
      ROUTES.ADMIN.ESCROW.DETAIL.replace(':escrow_id', actionRes.data ?? '')
    )
    props.onClose()
  }

  const renderTokenRow = useCallback(
    (asset: ISupportedAsset) => {
      return (
        <div
          className={twMerge(
            'flex flex-row gap-[16px] w-full items-center',
            'p-[8px] py-[12px] border-b-[1px] border-dotted border-b-transparent',
            'hover:border-b-gray-200 transition-[border-color] duration-500 ease-out'
          )}
        >
          <Avatar
            type={'user'}
            size={'medium'}
            image={getCryptoImageUrl(asset.ticker)}
            fallbackImage={getCryptoImageUrl()}
          />
          <div
            className={twMerge(
              'flex flex-1 flex-col gap-[8px] justify-center',
              'overflow-hidden'
            )}
          >
            {asset.name && (
              <Typography variant={'label-small'} className={'truncate'}>
                {asset.name}
              </Typography>
            )}
            <div className={'flex flex-row gap-[8px]'}>
              <Chip.Basic
                type={'outline'}
                colour={'grey'}
                label={asset.ticker ?? '-'}
                size={'small'}
                className="self-start"
              />
              {asset.custodians.length > 0 && (
                <Chip.Basic
                  type={'outline'}
                  colour={'grey'}
                  label={asset.custodians[0].name}
                  size={'small'}
                  className="self-start"
                />
              )}
            </div>
          </div>
          <div className={'flex items-center justify-center'}>
            <Button.Shape
              layout={'icon'}
              size={'small'}
              hierarchy={'secondary'}
              icon={{ name: 'minus' }}
              onClick={() => {
                setSelectedAssets([
                  ..._selectedAssets.filter(
                    (a) =>
                      !(
                        a.name === asset.name &&
                        a.network === asset.network &&
                        a.ticker === asset.ticker &&
                        a.contract_address === asset.contract_address &&
                        a.native_asset_name === asset.native_asset_name &&
                        a.native_asset_ticker === asset.native_asset_ticker
                      )
                  ),
                ])
              }}
            />
          </div>
        </div>
      )
    },
    [_selectedAssets]
  )

  return (
    <Drawer
      title={t('new_escrow_drawer_title')}
      description={t('new_escrow_drawer_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'create-escrow-form'}
        buttons={[
          <Button.Basic
            testId={'btn_create_escrow'}
            key={'btn_create_escrow'}
            label={t('create_escrow')}
            state={
              _submitting === INTENTS.CREATE_ESCROW
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_ESCROW,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        containerClassName={'pt-[32px] gap-[32px]'}
      >
        <Group label={t('details')}>
          <Input.Text
            id="escrow_name"
            name="escrow_name"
            label={t('escrow_name')}
            hint={t('create_escrow_name_bottom_text')}
            placeholder={t('create_escrow_name_placeholder')}
            maxLength={35}
          />

          <Input.Dropdown
            name="org_id_a"
            label={t('create_escrow_party_a_label')}
            testId={'party_a_selector'}
            hint={
              <Input.Component.Hint
                style={'message'}
                label={
                  'Only organisations who have Escrow enabled will be displayed for selection.'
                }
              />
            }
            icon={<Icon name="buildings" family={'sharp'} variant={'solid'} />}
            placeholder="Choose organisation"
            items={orgs
              .filter(
                (org) => org.escrow_enabled && org.id !== _selectedPartyBOrgId
              )
              .map((org) => (
                <ListItem
                  key={org.id}
                  value={org.id}
                  title={org.name}
                  className={'pl-2 pr-2'}
                />
              ))}
            onItemSelectionChange={(orgId: string | null) =>
              setSelectedPartyAOrgId(orgId)
            }
          />

          <Input.Dropdown
            name="org_id_b"
            label={t('create_escrow_party_b_label')}
            testId={'party_b_selector'}
            icon={<Icon name="buildings" family={'sharp'} variant={'solid'} />}
            placeholder="Choose organisation"
            items={orgs
              .filter(
                (org) => org.escrow_enabled && org.id !== _selectedPartyAOrgId
              )
              .map((org) => (
                <ListItem
                  key={org.id}
                  value={org.id}
                  title={org.name}
                  className={'pl-2 pr-2'}
                />
              ))}
            onItemSelectionChange={(orgId: string | null) =>
              setSelectedPartyBOrgId(orgId)
            }
          />
        </Group>

        <Group label={t('tokens')}>
          <input
            type={'hidden'}
            name={'selected_assets'}
            value={JSON.stringify(_selectedAssets)}
          />

          {_selectedAssets.length > 0 && (
            <div data-testid={'selected_tokens'} className={'flex flex-col'}>
              {_selectedAssets.map((a) => renderTokenRow(a))}
            </div>
          )}

          <Button.Basic
            label={t('add_token')}
            hierarchy={'secondary'}
            icon={{
              name: 'plus',
            }}
            onClick={() => {
              setModal({
                id: 'ASSET_SELECTOR',
                exclude: _selectedAssets,
                maxSelection: 50,
                onComplete: (newSelection: ISupportedAsset[]) => {
                  console.log(newSelection)
                  setSelectedAssets([..._selectedAssets, ...newSelection])
                },
              })
            }}
          />
        </Group>
      </Form>
    </Drawer>
  )
}
