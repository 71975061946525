// import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { ReactElement } from 'react'

import { IconProps } from '@/components/icon'
import { Props as InputHintProps } from '@/components/input/components/hint.input'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame, Props as InputProps } from '../components/frame.input'
import { InputHint } from '../components/hint.input'
import { InputLabel } from '../components/label.input'

export type Props = {
  state?: 'default' | 'disabled'
  label?: string
  hint?: string | ReactElement<InputHintProps>
  prefix?: string
  suffix?: string
  icon?: ReactElement<IconProps>
  testId?: string
} & InputProps
export const InputNumber = (props: Props) => {
  const preventMinus = (e: { code: string; preventDefault: () => void }) => {
    if (e.code === 'Minus' && props.min === 0) e.preventDefault()
  }

  const preventPasteNegative = (e: {
    clipboardData: {
      getData(arg0: string): string
    }
    preventDefault: () => void
  }) => {
    const clipboardData = e.clipboardData
    const pastedData = parseFloat(clipboardData.getData('text'))
    if (pastedData < 0 && props.min === 0) e.preventDefault()
  }

  return (
    <InputField
      testId={props.testId}
      className={props.className}
      label={
        props.label ? (
          <InputLabel state={props.state} size={'medium'} text={props.label} />
        ) : undefined
      }
      hint={
        typeof props.hint === 'string' ? (
          <InputHint state={props.state} label={props.hint} style={'hint'} />
        ) : props.hint !== undefined ? (
          props.hint
        ) : undefined
      }
      element={
        <InputElement
          variant={'default'}
          icon={props.icon}
          state={props.state ?? 'default'}
          input={
            <InputFrame
              placeholder={props.placeholder}
              {...props}
              onPaste={preventPasteNegative}
              onKeyPress={preventMinus}
              name={props.name}
              type={'number'}
            />
          }
          prefix={props.prefix}
          suffix={props.suffix}
        />
      }
    />
  )
}
