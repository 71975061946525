import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'

export const getStorage = async (userId: string, key: string) => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<
      BaseResponse<{ Value: Record<string, unknown> }>
    >(`storage/user/${userId}/${key}`)
    .then((response) => {
      return response.data
    })
}

export const updateStorage = async (
  userId: string,
  key: string,
  value: object
) => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .put(`storage/user/${userId}/${key}`, { value })
    .then((response) => {
      return response.data
    })
}
