import { IEscrowFee } from '@/types/escrows'
import { IUser } from '@/types/user'

/*
    Fee approval requires 1 approval from each organisation involved
    in the escrow - once all approvals are made the fee is then approved.
    Can pass through a single Fee or array of Fees in order to see if A
    fee exists that the user can approve.
*/
export const userCanApproveFees = (
  user: IUser | null,
  fee?: IEscrowFee | IEscrowFee[]
): IEscrowFee[] => {
  // Exit out if fee or user is undefined
  if (!fee || !user) {
    return []
  }

  // Bring all fees together as an array so we're dealing with
  // a common type
  const feeArray = Array.isArray(fee) ? fee : [fee]

  return feeArray.filter(
    (f) =>
      (f.approvals ?? []).find(
        (a) => a.by.organization_id === user.organization_id
      ) === undefined
  )
}

/*
    Checks if an org has already approved a given fee. Fee can either be
    a single fee or an array of fees.
*/
export const hasOrgAlreadyApprovedFee = (
  orgId: string,
  fee?: IEscrowFee | IEscrowFee[]
) => {
  const feeArray = Array.isArray(fee) ? fee : [fee]

  // Exit out if fee is undefined
  if (!fee) {
    return false
  }

  // Attempt to find any fee in our fee array which hasnt been approved by
  // the provided organisation
  return feeArray.find(
    (f) =>
      (f?.approvals ?? []).find((a) => a.by.organization_id === orgId) !==
      undefined
  )
}
