import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { IUser } from '@/types/user'

import TeamActionsView from './views/team-actions.view'

interface Props extends ControllerProps<unknown> {
  member: IUser
}
const TeamActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'TEAM_ACTIONS'}
      closeOnItemClick={true}
    >
      <TeamActionsView member={props.member} />
    </DropdownController>
  )
}
export default TeamActionsController
