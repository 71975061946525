import { t } from 'i18next'
import { twMerge } from 'tailwind-merge'

import { FormError } from '@/contexts/formErrors/types'

import { Paragraph } from '../paragraph'
import { Typography } from '../typography'

interface Props {
  errors: FormError[]
  scrollToInput: (inputName: string) => void
}

export const ErrorBottomBanner = (props: Props) => {
  const { errors, scrollToInput } = props
  return (
    <div
      className={twMerge(
        'flex flex-col p-4 pt-2 border-[3px] border-yellow-500 rounded-[6px] animate-fade-in',
        'bg-[#FFF8E5]',
        'self-stretch'
      )}
    >
      <Paragraph subTitle={t('there_is_a_problem')} />
      {errors.map((e, i) =>
        e.fieldName ? (
          <Typography key={`error_field_${i}`} variant={'paragraph-medium'}>
            <a
              key={`error_field_${i}`}
              onClick={() => {
                scrollToInput(e.fieldName ?? '')
              }}
            >
              {e.error}
            </a>
          </Typography>
        ) : (
          <Typography key={`error_field_${i}`} variant={'paragraph-medium'}>
            {e.error}
          </Typography>
        )
      )}
    </div>
  )
}
