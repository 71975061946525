import { JSX, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  children: ReactNode
  className?: string
} & JSX.IntrinsicElements['tr']

export const Row = (props: Props) => {
  return (
    <tr
      {...props}
      className={twMerge(
        'border-b transition-colors duration-100 border-b-gray-100 h-[4.5rem]',
        'transform-[background-color] duration-100 w-full',
        'onClick' in props &&
          props.onClick &&
          'cursor-pointer hover:bg-gray-100',
        props.className
      )}
    >
      {props.children}
    </tr>
  )
}
export default Row
