import { t } from 'i18next'
import { ChangeEvent, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { getAddressAssets, validateAddress } from '@/api/evm/v1'
import Button from '@/components/button'
import Card from '@/components/card'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Table } from '@/components/table'
import { FormErrorsManager, useFormErrors } from '@/contexts/formErrors'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { formatNumber, formatValue } from '@/helpers/formatValue'
import { useOrg } from '@/hooks/queries/useOrg'
import { IAddressAsset } from '@/types/evm'

interface Props {
  isDetected: boolean
  onDetect: (isDetected: boolean) => void
  onTokenSelected: (token: IAddressAsset | undefined) => void
}

export const AssetExistingChainSelector = (props: Props) => {
  const { errors } = useFormErrors()
  const { org } = useOrg()
  const [_isValidAddress, setIsValidAddress] = useState<boolean | null>(null)
  const [_blockchainAddress, setBlockchainAddress] = useState<string>('')
  const [_assets, setAssets] = useState<IAddressAsset[] | null>(null)
  const [_isLoading, setIsLoading] = useState<boolean>(false)
  const [_selectedToken, setSelectedToken] = useState<IAddressAsset | null>(
    null
  )
  const [_error, setError] = useState<string>(errors[0]?.error ?? '')

  const onAddressChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim()
    props.onDetect(false)
    setIsValidAddress(false)
    setAssets(null)
    props?.onTokenSelected(undefined)
    setSelectedToken(null)
    setBlockchainAddress(trimmedValue)
    FormErrorsManager.clearErrors()
  }

  const detectTokens = async () => {
    try {
      const address = _blockchainAddress
      if (!_isLoading) {
        // Reset a bunch of flags
        setIsLoading(true)
        setAssets(null)
        setSelectedToken(null)
        setIsValidAddress(false)
        setError('')

        // First lets verify the address
        // Make the call to verify the address
        const validateRes = await validateAddress(address)

        // Check the address is valid
        if (validateRes.error || !validateRes.data?.isValid) {
          setIsLoading(false)
          return setError(t('not_valid_blockchain_address'))
        }

        // Otherwise address is valid so lets indicate that in the UI
        setIsValidAddress(true)

        // Get all asset information for the address
        const assetRes = await getAddressAssets(address)

        // Check if we've errored
        if (assetRes.error) {
          setIsLoading(false)
          return setError(t('error'))
        }

        // Otherwise lets push the values into our collection
        props.onDetect(true)
        setAssets(assetRes.data?.assets ?? [])

        // Clear the loading flag
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      setError(ExtractErrorFrom(e) ?? t('something_went_wrong'))
    }
  }

  const onItemSelected = (item: IAddressAsset) => {
    setSelectedToken(item)
    props?.onTokenSelected(item)
    FormErrorsManager.clearErrors()
  }
  return (
    <div className={'flex w-full gap-6 self-stretch max-w-[42rem]'}>
      <div
        className={
          'flex flex-1 flex-row items-center content-center gap-4 self-stretch flex-wrap'
        }
      >
        <Input.Text
          name={'token_blockchain_address'}
          testId={'token_blockchain_address'}
          label={t('blockchain_address')}
          hint={
            _error ? (
              <Input.Component.Hint label={_error} style={'negative'} />
            ) : (
              t('paste_the_wallet_address_here')
            )
          }
          state={_error ? 'error' : _isLoading ? 'disabled' : 'default'}
          onChange={onAddressChange}
          {...(_isValidAddress === true && {
            trailingIcon: (
              <Icon name={'circle-check'} variant={'solid'} family={'sharp'} />
            ),
          })}
        />

        <Button.Basic
          hierarchy={'secondary'}
          testId={'btn_detect_tokens'}
          label={t('detect_tokens')}
          onClick={detectTokens}
          state={_isLoading ? 'loading' : 'default'}
          className={'self-auto mt-[-0.1875rem]'}
        />

        {!_isLoading && Array.isArray(_assets) && (
          <div
            className={twMerge(
              'flex flex-col w-full max-h-[21.5rem] px-4 py-2 items-start gap-4',
              'border-solid border-[#212427] border-[1px] rounded-md',
              'items-stretch self-stretch max-w-[41rem]'
            )}
          >
            {_assets.length > 0 ? (
              <div
                className={
                  'flex flex-col overflow-auto self-stretch items-stretch pr-4'
                }
                data-testid="token-list-wrapper"
              >
                <Table
                  testId={'table_token_list'}
                  tokens={_assets}
                  onItemSelected={onItemSelected}
                />
              </div>
            ) : (
              <Card.PageState
                type={'no_tokens'}
                title={t('no_tokens')}
                description={t('there_are_no_tokens_detected')}
              />
            )}
          </div>
        )}

        {errors && errors.length > 0 && _selectedToken === null && (
          <Input.Component.Hint
            label={t('existing_chain_selector_error_hint')}
            style="negative"
          />
        )}

        <div className={'flex w-full flex-col self-stretch gap-6'}>
          <input
            type={'hidden'}
            name={'token_ticker'}
            value={_selectedToken?.ticker}
          />
          <input
            type={'hidden'}
            name={'token_contract_address'}
            value={_selectedToken?.networks[0].contract_address ?? ''}
          />
          <Input.Text
            name={'token_network'}
            label={t('network')}
            state={'readonly'}
            value={_selectedToken?.networks[0].name ?? ''}
            text={capitalizeFirstLetter(_selectedToken?.networks[0].name ?? '')}
          />
          <Input.Text
            name={'token_balance'}
            label={t('balance')}
            state={'readonly'}
            value={_selectedToken?.balance ?? 0}
            text={formatNumber(_selectedToken?.balance ?? 0, {
              notation: 'standard',
              minimumFractionDigits: 2,
              maximumFractionDigits: 18,
            })}
            suffix={_selectedToken?.ticker}
            hint={t('number_of_tokens')}
          />
          <Input.Text
            name={'token_total'}
            label={t('total_value')}
            state={'readonly'}
            value={_selectedToken?.value ?? 0}
            text={formatValue(
              _selectedToken?.value ?? 0,
              org?.preferred_currency
            )}
            suffix={org?.preferred_currency}
            className="max-w-[15rem]"
          />
        </div>
      </div>
    </div>
  )
}
