import { getClient, getConfig } from '@/api/common'
import { STORAGE_KEYS } from '@/helpers/localStorage.ts'
import { BaseResponse } from '@/types/api'
import { ISession } from '@/types/session'

export const invalidateToken = async (): Promise<BaseResponse<ISession>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return client.delete(`session`).then((response) => {
    return response.data
  })
}

export const refreshSession = async (): Promise<BaseResponse<ISession>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  const selectedWorkspaceId = localStorage.getItem(STORAGE_KEYS.WORKSPACE_ID)
  return client
    .get(
      `session/refresh${selectedWorkspaceId ? '?workspace_id=' + selectedWorkspaceId : ''}`
    )
    .then((response) => {
      return response.data
    })
}
