import { t } from 'i18next'
import { useLoaderData, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { AssetDetails } from '@/components/asset/detail'
import Card from '@/components/card'
import Page from '@/components/page'
import { useAsset } from '@/hooks/queries/useAsset'

import { LoaderData } from './loader'
import AssetSkeleton from './skeleton'

export const AssetDetail: React.FC = (): JSX.Element => {
  const { state } = useLocation()
  const { assetId } = useLoaderData() as LoaderData
  const { asset, isLoading } = useAsset(assetId)

  return (
    <div className={twMerge('flex flex-col items-left')}>
      <div className={'w-full max-w-[1050px]'}>
        <Page.Navigation className={'px-6'} />
        {isLoading ? (
          <AssetSkeleton />
        ) : !asset ? (
          <div className={'flex h-[80vh] w-full items-center justify-center'}>
            <Card.PageState
              type={'warning'}
              title={t('404')}
              description={t('page_not_found')}
            />
          </div>
        ) : (
          <AssetDetails asset={asset} readOnly={state?.readOnly === true} />
        )}
      </div>
    </div>
  )
}
