import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const HealthSkeleton: React.FC = (): JSX.Element => (
  <>
    <Skeleton className="h-10 mb-6" width="100px" />
    <Skeleton className="h-6 mb-2" />
    <Skeleton className="h-32 mb-[60px]" />

    <Skeleton className="h-10" count={7} />
  </>
)
export default HealthSkeleton
