import { twMerge } from 'tailwind-merge'

import { Icon, IconProps } from '@/components/icon'
import { Typography } from '@/components/typography'

export interface Props {
  text: string
  label?: string
  icon?: IconProps
  size?: 'small' | 'medium' | 'large'
  state?: 'default' | 'disabled'
  classOverrides?: {
    base?: string
    text?: string
    label?: string
    icon?: string
  }
}
export const InputLabel = (props: Props) => {
  props = {
    size: 'medium',
    state: 'default',
    ...props,
  }

  return (
    <div
      className={twMerge(
        'flex flex-row items-center w-full max-w-[23.4375rem] gap-4',
        props.classOverrides?.base
      )}
    >
      <Typography
        variant={
          props.size === 'small'
            ? 'label-extrasmall'
            : props.size === 'medium'
              ? 'label-small'
              : 'label-medium'
        }
        className={twMerge(
          'flex flex-1',
          props.state === 'disabled' ? 'text-[#909293]' : 'text-[#212427]',
          props.classOverrides?.text
        )}
      >
        {props.text}
      </Typography>
      {props.icon && (
        <Icon
          {...props.icon}
          size={props.size}
          state={props.state}
          className={twMerge(
            props.state === 'disabled' ? 'text-[#909293]' : 'text-[#212427]',
            props.classOverrides?.icon
          )}
        />
      )}
      {props.label && (
        <Typography
          variant={
            props.size === 'small'
              ? 'label-extrasmall'
              : props.size === 'medium'
                ? 'label-small'
                : 'label-medium'
          }
          className={twMerge(
            props.state === 'disabled' ? 'text-[#909293]' : 'text-[#45484B]',
            props.classOverrides?.label
          )}
        >
          {props.label}
        </Typography>
      )}
    </div>
  )
}
