import { t } from 'i18next'
import { useState } from 'react'

import Input from '@/components/input'
import { CountryOption } from '@/constants/countries'
import { fixCountryValue } from '@/helpers/fixCountryValue.ts'

interface Props {
  selectedCountry?: string
  selectedAddress?: string
}

export const AssetAddressSelector = (props: Props) => {
  const [_selectedCountry, setSelectedCountry] = useState<
    CountryOption | undefined
  >(undefined)

  return (
    <div className={'flex flex-col gap-6 w-full'}>
      <Input.Country
        name={'asset_country'}
        testId={'asset_country'}
        selectedValue={fixCountryValue(props.selectedCountry)}
        onCountrySelected={setSelectedCountry}
      />
      <Input.Address
        name={'asset_address'}
        testId={'asset_address'}
        label={t('address')}
        defaultValue={props.selectedAddress ?? ''}
        placeholder={t('start_typing_address')}
        country={_selectedCountry?.value ?? props?.selectedCountry ?? ''}
      />
    </div>
  )
}
