import { Currency } from '@/constants/currencies.ts'

import { ILocation, IPrice } from './common'
import { IDefendant } from './defendants'
import { IFile } from './file'
import { ISeizure } from './seizure'
import { IUser } from './user'

export enum DigitalAssetType {
  NATIVE = 'NATIVE',
  ERC20 = 'ERC20',
  NFT = 'NFT',
}

export type SupportedAssetType = 'CONTRACT TOKEN' | 'NATIVE TOKEN'

export enum AssetWalletType {
  WARM = 'WARM',
  COLD = 'COLD',
}

// subcategories for digital as used in the asset creation flow
export enum DigitalWalletSource {
  EXISTING = 'EXISTING',
  GENERATED = 'GENERATED',
  CUSTOM_TRACKER = 'CUSTOM_TRACKER',
}

// simplified categories used in the UI but not in our backend
// we group the physical categories into one, called TANGIBLE
export enum AssetGroupedCategory {
  DIGITAL = 'DIGITAL',
  TANGIBLE = 'TANGIBLE',
}

// categories as used in the backend
export enum AssetCategory {
  DIGITAL = 'DIGITAL',
  FIAT_CASH = 'FIAT_CASH',
  PERSONAL_PROPERTY = 'HIGH_VALUE_PERSONAL_PROPERTY', // different value in backend
  REAL_ESTATE = 'REAL_ESTATE',
  VEHICLE = 'VEHICLE',
}

export type AssetTangibleCategory =
  | AssetCategory.FIAT_CASH
  | AssetCategory.VEHICLE
  | AssetCategory.REAL_ESTATE
  | AssetCategory.PERSONAL_PROPERTY

export type AssetTangibleType =
  | TangibleTypeCash
  | TangibleTypeVehicle
  | TangibleTypeJewellery
  | TangibleTypeElectronics
  | TangibleTypeRealEstate
  | TangibleTypeCollectibles

export type AssetTangiblePersonaPropertyType =
  | TangibleTypeJewellery
  | TangibleTypeElectronics
  | TangibleTypeCollectibles

export type AssetTangibleEnum =
  | typeof TangibleTypeCash
  | typeof TangibleTypeVehicle
  | typeof TangibleTypeJewellery
  | typeof TangibleTypeElectronics
  | typeof TangibleTypeRealEstate
  | typeof TangibleTypeCollectibles

export enum TangibleTypeCash {
  CASH = 'CASH',
}

export enum TangibleTypeVehicle {
  CAR = 'CAR',
  MOTORCYCLE = 'MOTORCYCLE',
  SCOOTER = 'SCOOTER',
  VAN = 'VAN',
  TRUCK = 'TRUCK',
  SUV = 'SUV',
  SEDAN = 'SEDAN',
  BUS = 'BUS',
  CARAVAN = 'CARAVAN',
  BIKE = 'BIKE',
  AIRPLANE = 'AIRPLANE',
  HELICOPTER = 'HELICOPTER',
  BOAT = 'BOAT',
  YACHT = 'YACHT',
  OTHER = 'OTHER',
}

export enum TangibleTypeRealEstate {
  HOUSE = 'HOUSE',
  LAND = 'LAND',
  VILLA = 'VILLA',
  APARTMENT_FLAT = 'APARTMENT/FLAT',
  SHED = 'SHED',
  FARM = 'FARM',
  OTHER = 'OTHER',
}

///////////////////////////////////////////
///////////////// HVPP ////////////////////
export enum TangibleTypeJewellery {
  WATCH = 'WATCH',
  RING = 'RING',
  NECKLACE = 'NECKLACE',
  BRACELET = 'BRACELET',
  GEM = 'GEM',
  JEWELLERY_OTHER = 'JEWELLERY_OTHER',
}

export enum TangibleTypeElectronics {
  DESKTOP_COMPUTER = 'DESKTOP_COMPUTER',
  LAPTOP_COMPUTER = 'LAPTOP_COMPUTER',
  TABLET = 'TABLET',
  MOBILE_PHONE = 'MOBILE_PHONE',
  HARD_DISK_DRIVE = 'HARD_DISK_DRIVE',
  USB_DRIVE = 'USB_DRIVE',
  ELECTRONICS_OTHER = 'ELECTRONICS_OTHER',
}

export enum TangibleTypeCollectibles {
  PAINTING = 'PAINTING',
  SCULPTURE = 'SCULPTURE',
  ANTIQUES = 'ANTIQUES',
  ALCOHOL = 'ALCOHOL',
  OTHER = 'OTHER',
}

// Allowed combinations. Should match what's in our backend. Order as they appear in selector.
export const tangibleCategoryAndTypes: [
  AssetTangibleCategory,
  AssetTangibleEnum,
][] = [
  [AssetCategory.FIAT_CASH, TangibleTypeCash],
  [AssetCategory.VEHICLE, TangibleTypeVehicle],
  [AssetCategory.PERSONAL_PROPERTY, TangibleTypeJewellery],
  [AssetCategory.PERSONAL_PROPERTY, TangibleTypeElectronics],
  [AssetCategory.PERSONAL_PROPERTY, TangibleTypeCollectibles],
  [AssetCategory.REAL_ESTATE, TangibleTypeRealEstate],
]
// don't allow duplicates, for example OTHER in electronics and jewellery
errorIfDuplicateCombinations(tangibleCategoryAndTypes)

function errorIfDuplicateCombinations(
  array: [AssetTangibleCategory, AssetTangibleEnum][]
): void {
  const seenCombinations = new Set<string>()
  array.forEach(([category, typeEnum]) => {
    const enumValues = Object.values(typeEnum)
    enumValues.forEach((type) => {
      const combination = `${category}-${type}`
      if (seenCombinations.has(combination)) {
        throw new Error(`Duplicate combination found: ${combination}`)
      }
      seenCombinations.add(combination)
    })
  })
}

///////////////// HVPP ////////////////////
///////////////////////////////////////////

////////////////////////////////
/////////// Statuses ///////////
export type IAssetStatusName =
  | 'WITHOUT_OPERATION'
  | 'PENDING'
  | 'READY'
  | 'SEIZED'
  | 'ADOPTED'
  | 'AWARDED'
  | 'FORFEITED'
  | 'TRANSFERRED'
  | 'RETURNED'
  | 'LIQUIDATED'
  | 'DELETED'
  | 'CLOSED'
export type IServiceRequestType = 'WITHDRAW' | 'TRANSFER'
export type IAsset = IDigitalAsset | IPhysicalAsset
export type ICreateAsset = ICreateDigitalAsset | ICreatePhysicalAsset

export type IDigitalAsset = IDigitalAssetContract | IDigitalAssetNative
export type ICreateDigitalAsset =
  | ICreateDigitalAssetContract
  | ICreateDigitalAssetNative
export type IPhysicalAsset =
  | IFiatCashAsset
  | IHighValuePersonalPropertyAsset
  | IRealEstateAsset
  | IVehicleAsset

export type ICreatePhysicalAsset =
  | ICreateFiatCashAsset
  | ICreateHighValuePersonalPropertyAsset
  | ICreateRealEstateAsset
  | ICreateVehicleAsset

interface IAssetBase {
  id: string
  platform_id?: string

  created_at: string
  last_updated_at: string
  created_by: string
  organization_id?: string
  operation_id?: string
  seizure_id?: string
  defendant_id?: string
  category: AssetCategory

  external_ref?: string
  statuses: IAssetStatus[]
  files?: IFile[]
  costs?: IAssetCost[]
  notes?: IAssetNote[]
  search_tags?: string[] //<< NOT IMPLEMENTED ON BE - NAME LIKELY TO CHANGE
  service_request?: IAssetServiceRequest

  price?: IPrice
  seizure?: ISeizure
  defendant?: IDefendant
}

interface ICreateAssetBase {
  organization_id?: string
  operation_id?: string
  seizure_id?: string
  defendant_id?: string
  category: AssetCategory

  statuses?: IAssetStatusBase[]
  files?: IFile[]
  costs?: IAssetCost[]
  notes?: IAssetNote[]

  price?: IPrice
  external_ref?: string
}

interface IDigitalAssetBase extends IAssetBase {
  category: AssetCategory.DIGITAL
  is_self_managed?: boolean

  type: DigitalAssetType
  contract_address?: string
  ticker: string
  network: string
  amount?: number
  wallet?: IAssetWallet
  custodian?: IAssetCustodian
  airtable_record_id?: string

  is_supported?: boolean
  has_alert?: boolean
}

interface ICreateDigitalAssetBase extends ICreateAssetBase {
  category: AssetCategory.DIGITAL
  type: DigitalAssetType

  ticker: string
  network: string
  contract_address?: string
  amount?: number
  wallet?: IAssetWallet
  custodian?: IAssetCustodian
  external_ref?: string
}

export interface IDigitalAssetContract extends IDigitalAssetBase {
  type: DigitalAssetType.ERC20 | DigitalAssetType.NFT
  contract_address: string
}

export interface ICreateDigitalAssetContract extends ICreateDigitalAssetBase {
  type: DigitalAssetType.ERC20 | DigitalAssetType.NFT
  contract_address: string
}

export interface IDigitalAssetNative extends IDigitalAssetBase {
  type: DigitalAssetType.NATIVE
}

export interface ICreateDigitalAssetNative extends ICreateDigitalAssetBase {
  type: DigitalAssetType.NATIVE
}

export interface IFiatCashAsset extends IAssetBase {
  category: AssetCategory.FIAT_CASH
  amount?: number
  currency?: Currency
  condition?: string
}

export interface ICreateFiatCashAsset extends ICreateAssetBase {
  category: AssetCategory.FIAT_CASH
  amount: number
  currency: Currency
}

export interface IHighValuePersonalPropertyAsset extends IAssetBase {
  category: AssetCategory.PERSONAL_PROPERTY
  valuations?: IAssetValuation[]

  quantity?: number
  type?: AssetTangiblePersonaPropertyType
  serial_number?: string
  description?: string
}

export interface ICreateHighValuePersonalPropertyAsset
  extends ICreateAssetBase {
  category: AssetCategory.PERSONAL_PROPERTY
  valuations?: IAssetValuation[]

  quantity?: number
  type?: string
  serial_number?: string
  description?: string
}

export interface IRealEstateAsset extends IAssetBase {
  category: AssetCategory.REAL_ESTATE
  valuations?: IAssetValuation[]

  area: number
  owner_name?: string
  type?: TangibleTypeRealEstate
  plot_number?: string
  location?: ILocation
}

export interface ICreateRealEstateAsset extends ICreateAssetBase {
  category: AssetCategory.REAL_ESTATE
  valuations?: IAssetValuation[]

  area?: number
  owner_name?: string
  type?: TangibleTypeRealEstate
  plot_number?: string
  location?: ILocation
}

export interface IVehicleAsset extends IAssetBase {
  category: AssetCategory.VEHICLE
  valuations?: IAssetValuation[]

  type?: TangibleTypeVehicle
  make?: string
  model?: string
  year?: number
  plate?: string
  color?: string
  owner_name?: string
  chassis?: string
  engine?: string
  engine_id?: string
  vin?: string
  mileage?: number
  description?: string
}

export interface ICreateVehicleAsset extends ICreateAssetBase {
  category: AssetCategory.VEHICLE
  valuations?: IAssetValuation[]

  type?: TangibleTypeVehicle
  make?: string
  model?: string
  year?: number
  plate?: string
  color?: string
  owner_name?: string
  chassis?: string
  engine?: string
  engine_id?: string
  vin: string
  mileage?: number
  description?: string
}

export interface IAssetTotalPrice {
  currency: Currency
  value: number
}

interface IAssetStatusBase {
  name: IAssetStatusName
  happened_at?: string
}

export interface IAssetStatus extends IAssetStatusBase {
  id: string
  last_updated_at?: string
  created_at: string
  created_by?: string
  files?: IFile[]
}

export interface IAssetValuation {
  id?: string
  value?: number
  currency?: Currency
  created_by?: string
  last_updated_at?: string
  created_at?: string
  verified?: boolean
}

export interface IAssetCost {
  id?: string
  title?: string
  description?: string
  last_updated_at?: string
  created_at?: string
  created_by?: string
  occurred_at?: string
  value?: number
  currency?: Currency
}

export interface IAssetNote {
  id?: string
  content?: string
  created_by?: string
  last_updated_at?: string
  created_at?: string
  user?: IUser
}

export interface IAssetServiceRequest {
  type?: IServiceRequestType
  details?: string
  closed: boolean
  status?: IAssetServiceRequestStatus
  created_by?: string
  created_at?: string
  last_updated_at?: string
  user: IUser
}

export interface IAssetServiceRequestStatus {
  id?: string
  message?: string
  created_by?: string
  created_at?: string
  last_updated_at?: string
  user: IUser
}

export type IAssetWallet = IAssetWalletExisting | IAssetWalletGenerated

interface IAssetWalletBase {
  address?: string
  type?: AssetWalletType
  source: DigitalWalletSource
}

export interface IAssetWalletExisting extends IAssetWalletBase {
  source: DigitalWalletSource.EXISTING
  address: string
}

export interface IAssetWalletGenerated extends IAssetWalletBase {
  source: DigitalWalletSource.GENERATED
  type: AssetWalletType
}

export interface IAssetCustodian {
  provider?: string
  care_of?: string
  contact?: string
  supported_asset_key?: string
}
