// import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher, useLoaderData, useNavigate } from 'react-router-dom'

import { Button } from '@/components/button'
import Input from '@/components/input'
import { PageBack } from '@/components/page-back'
import { Paragraph } from '@/components/paragraph'
import { ROUTES } from '@/constants/routes'
import { useOrg } from '@/hooks/queries/useOrg'
import { IUser } from '@/types/user'

export const Profile: React.FC = (): JSX.Element => {
  const { org } = useOrg()
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const { isMobile } = useWindowSize()
  const fetcher = useFetcher()

  const { user } = useLoaderData() as { user: IUser }

  if (!user) {
    throw new Response('', {
      status: 404,
      statusText: t('user_not_found'),
    })
  }

  const handleSetNewPassword = () => {
    const from = new URLSearchParams(window.location.search).get('from')
    navigate(`${ROUTES.PROFILE.SET_NEW_PASSWORD}?from=${from}`)
  }

  const urlFrom = new URLSearchParams(window.location.search).get('from')

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="max-w-full grow overflow-x-hidden tablet:max-w-[45rem] flex flex-col">
          <PageBack url={urlFrom ?? ''} />
          <div className={'flex flex-col p-6 gap-8'}>
            <Paragraph title={t('user_settings')} />

            <Input.Text
              label={t('organisation')}
              id="organisation"
              name="organisation"
              value={org?.name ?? ''}
              state={'readonly'}
            />

            <fetcher.Form method="POST" className="flex flex-col gap-8">
              <Input.Text
                label={t('full_name')}
                id="name"
                name="name"
                value={user.name ?? ''}
                testId="profile-name-input"
                state={'readonly'}
                className="whitespace-nowrap"
                dynamicInput
                elementClassName="whitespace-nowrap"
              />

              <Input.Email
                label={t('email')}
                id="email"
                name="email"
                value={user.email ?? ''}
                state={'readonly'}
                className="whitespace-nowrap"
                dynamicInput
                elementClassName="whitespace-nowrap"
              />

              <Paragraph
                subTitle={t('account_password')}
                description={t('account_password_description')}
                dividerAbove={true}
                spacerOverrides={{
                  description: [],
                }}
              />
              <Button
                hierarchy="secondary"
                label={t('set_new_password')}
                onClick={handleSetNewPassword}
                testId="set-new-password-button"
              />

              {/* <Paragraph
                subTitle={t('two_factor_authentication')}
                description={t('two_factor_authentication_description_2')}
                spacerOverrides={{
                  description: [],
                }}
              />
              <Button
                hierarchy="secondary"
                label={t('reset_two_factor_authentication')}
                onClick={handleToggleReset2FADialog}
              /> */}

              {/* <Paragraph
                subTitle={t('site_settings')}
                description={t('site_settings_description')}
                dividerAbove={true}
                spacerOverrides={{
                  description: [],
                }}
              /> */}

              {/* <Input.Dropdown
                label={t('currency')}
                name={'currency'}
                {...(_selectedCurrency.icon && {
                  icon: (
                    <Icon
                      name={_selectedCurrency.icon}
                      variant={'solid'}
                      family={'sharp'}
                      size={'small'}
                    />
                  ),
                })}
                value={_selectedCurrency.id}
                text={_selectedCurrency.label}
                items={CURRENCY.map((item, index) => (
                  <ListItem
                    key={`currency_${index}`}
                    title={item.label}
                    value={item.id}
                    style={'normal'}
                    onClick={() => {
                      setSelectedCurrency(item)
                    }}
                    {...(item.icon && {
                      leading: (
                        <Icon
                          size={'small'}
                          family={'sharp'}
                          name={item.icon}
                          variant={'solid'}
                        />
                      ),
                    })}
                    trailing={
                      _selectedCurrency.id === item.id ? (
                        <Icon
                          size={'small'}
                          family={'sharp'}
                          name={'circle-check'}
                          variant={'solid'}
                        />
                      ) : undefined
                    }
                    className={'pl-2 pr-2'}
                  />
                ))}
              /> */}

              {/* <Input.Dropdown
                label={t('auto_logout')}
                name={'autologout'}
                value={_selectedAutoLogout.id}
                text={_selectedAutoLogout.label}
                items={AUTOLOGOUT.map((item, index) => (
                  <ListItem
                    key={`autologout_${index}`}
                    title={item.label}
                    value={item.id}
                    style={'normal'}
                    onClick={() => {
                      setAutoLogout(item)
                    }}
                    trailing={
                      _selectedAutoLogout.id === item.id ? (
                        <Icon
                          size={'small'}
                          family={'sharp'}
                          name={'circle-check'}
                          variant={'solid'}
                        />
                      ) : undefined
                    }
                    className={'pl-4 pr-4'}
                  />
                ))}
              /> */}
              <input type="hidden" name="userId" value={user.id ?? ''} />
            </fetcher.Form>
          </div>
        </div>
      </div>
    </>
  )
}
