import { useQuery } from '@tanstack/react-query'

import { getEscrow } from '@/api/escrows/v1'

export const key = 'escrow'
export const useEscrow = (escrowId: string) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key, escrowId],
    queryFn: () => getEscrow(escrowId),
  })

  return { escrow: data?.data, isLoading, refetchEscrow: refetch }
}
