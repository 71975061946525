import { t } from 'i18next'

import { addSupportedAsset } from '@/api/org'
import { REGEX } from '@/constants/regex'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { IsNot } from '@/helpers/test'
import { ActionResponse } from '@/types/actions'
import { ICustodianSupportedAsset } from '@/types/custodian'

export const CreateSupportedAsset = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const custodian_id = formData.get('custodian_id')?.toString()
  const asset_type = formData.get('asset_grouped_category')?.toString()
  const name = formData.get('asset_name')?.toString()
  const contract_address = formData.get('contract_address')?.toString()
  const network = formData.get('network_name')?.toString()
  const ticker = formData.get('ticker')?.toString()

  // const notes = formData.get('case_notes')?.toString() // << Not currently implemented in BE

  // Check basic details
  if (!ticker || IsNot(ticker, REGEX.IS_ASSET_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'custodian_name',
        error: t('invalid_custodian_name_provided'),
      },
    ])
  }

  try {
    const data = {
      custodian_id,
      ticker,
      name,
      key: '',
      network,
      contract_address,
      native_asset: asset_type === 'NATIVE TOKEN' ? 'NATIVE' : 'ERC20',
    } as ICustodianSupportedAsset

    await addSupportedAsset(data)
    // Run through each asset
    // await addCustodian(data)
    // await
  } catch (e) {
    // At this point we should throw an error but not anything that could
    // cause a re-create as the operation has been created - we can use a banner
    // instead
    console.error(e)
    return {
      error: false,
      message: `${t('successfully_added_custodian')} '${name}' ${t('but_failed_to_add_assets')}`,
      completion: 'COMPLETE_WITH_ERROR',
      data: null, // New operation Id
    }
  }

  return {
    error: false,
    message: `${t('new_custodian')} '${name}' ${t('added_successfully')}`,
    completion: 'COMPLETE',
    data: null, // New operation Id
  }
}
