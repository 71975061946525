import { t } from 'i18next'

import { createAssetNote } from '@/api'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { IsNot } from '@/helpers/test'
import { key as QueryAssetKey } from '@/hooks/queries/useAsset'
import { ActionResponse } from '@/types/actions'

export const CreateAssetNote = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const assetId = formData.get('asset_id')?.toString()
  const note = formData.get('note_text')?.toString()

  // Check basic details
  if (IsNot(note?.trim(), REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_note_provided'),
      },
    ])
  } else if (IsNot(assetId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('missing_asset_id'),
      },
    ])
  }

  // Add in the note
  const res = await createAssetNote(assetId, {
    content: note,
  })

  // Check if the call failed
  if (res.error) {
    throw new Error(res.message)
  }

  // Invalidate asset cache
  await queryClient.invalidateQueries({
    queryKey: [QueryAssetKey, assetId],
  })

  return {
    error: false,
    message: t('note_added_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
