import { useQuery } from '@tanstack/react-query'
import moment from 'moment'

import {
  getAuditLogsForAsset,
  getAuditLogsForCase,
  getAuditLogsForDefendant,
} from '@/api'
import { key as QueryAssetKey } from '@/hooks/queries/useAsset'
import { key as QueryDefendantsKey } from '@/hooks/queries/useDefendants'
import { key as QueryOperationsKey } from '@/hooks/queries/useOperations'
import { BaseResponse } from '@/types/api'
import { AuditLog, AuditOperation } from '@/types/audit-log'

const AuditLogsKey = 'audit-logs'

const mapAuditLogsResponse = (data: BaseResponse<AuditLog[]>) =>
  data?.data
    ?.filter(
      ({ operation }) =>
        ![
          AuditOperation.FindOperation,
          AuditOperation.FindOperationDigitalAssets,
          AuditOperation.FindOperationPhysicalAssets,
          AuditOperation.FindDefendant,
          AuditOperation.FindDefendantDigitalAssets,
          AuditOperation.FindDefendantPhysicalAssets,
          AuditOperation.FindDefendantOperations,
        ].includes(operation)
    )
    ?.map((log) => ({
      ...log,
      occurred_at: moment(log.occurred_at).toDate(),
    })) ?? []

const useAuditLogsForAsset = (assetId: string | undefined) => {
  const result = useQuery({
    queryKey: [QueryAssetKey, assetId, AuditLogsKey],
    queryFn: async () =>
      mapAuditLogsResponse(await getAuditLogsForAsset({ assetId: assetId! })),
    enabled: Boolean(assetId),
  })

  return result
}

const useAuditLogsForDefendant = (defendantId: string | undefined) => {
  const result = useQuery({
    queryKey: [QueryDefendantsKey, defendantId, AuditLogsKey],
    queryFn: async () =>
      mapAuditLogsResponse(
        await getAuditLogsForDefendant({ defendantId: defendantId! })
      ),
    enabled: Boolean(defendantId),
  })

  return result
}

const useAuditLogsForCase = (caseId: string | undefined) => {
  const result = useQuery({
    queryKey: [QueryOperationsKey, caseId, AuditLogsKey],
    queryFn: async () =>
      mapAuditLogsResponse(await getAuditLogsForCase({ caseId: caseId! })),
    enabled: Boolean(caseId),
  })

  return result
}

type Params =
  | {
      assetId: string
      defendantId?: undefined
      caseId?: undefined
    }
  | {
      assetId?: undefined
      defendantId: string
      caseId?: undefined
    }
  | {
      assetId?: undefined
      defendantId?: undefined
      caseId: string
    }

const determineAuditLogsVariant = (props: Params) => {
  if ('assetId' in props) return 'ASSET'
  if ('defendantId' in props) return 'DEFENDANT'
  if ('caseId' in props) return 'CASE'

  throw new Error('Invalid variant for useAuditLogs')
}

export const useAuditLogs = (params: Params) => {
  const { assetId, caseId, defendantId } = params

  const assetResult = useAuditLogsForAsset(assetId)
  const defendantResult = useAuditLogsForDefendant(defendantId)
  const caseResult = useAuditLogsForCase(caseId)

  const variant = determineAuditLogsVariant(params)

  if (variant === 'ASSET') {
    return assetResult
  }

  if (variant === 'DEFENDANT') {
    return defendantResult
  }

  if (variant === 'CASE') {
    return caseResult
  }

  throw new Error('Invalid variant for useAuditLogs')
}
