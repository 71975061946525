import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import Card from '@/components/card'
import Dropdown from '@/components/dropdown'
import { ListItem } from '@/components/list-item'
import { useDropdown } from '@/contexts/interface'

import Button from '../../button'
import { Props as ButtonProps } from '../../button/variants/basic.button'
import { Icon, Variant as IconVariant } from '../../icon'
import { Props as TabsProps, Tabs } from '../../tabs'
import { BaseTableProps } from '../types'

export interface SortOption<S> {
  id: S
  label: string
  value: string
  icon: string
  iconVariant?: IconVariant
}

export interface FilterOption<F> {
  id: F
  label: string
  value: string
}

export interface Header {
  label: string
  alignment?: 'left' | 'center' | 'right'
  colSpan?: number
}

interface SortProps<S> {
  options: SortOption<S>[]
  onSortByChanged: (id: S, value: string) => void
  defaultSelectedId?: S
}

interface FilterProps<F> {
  options: FilterOption<F>[]
  onFilterChanged: (id: F, value?: string) => void
  defaultSelectedId?: F
}

interface TabController<TabIdType> {
  tabs: TabsProps<TabIdType>
}

interface ButtonController {
  buttons: ButtonProps[]
}

export interface Props<DataType, SortIdType, FilterIdType, TabIdType>
  extends BaseTableProps {
  leftController?: TabController<TabIdType> | ButtonController // Displayed on the left side
  sort?: SortProps<SortIdType> // Displayed as a dropdown on the right side
  filter?: FilterProps<FilterIdType> // Displayed as a dropdown on the right side
  headers: Header[]
  data: DataType[]
  className?: string
  pageData?: boolean
  scrollParentId?: string // We'll use <main> otherwise
  onRenderRow: (item: DataType, index: number) => JSX.Element
}

export const BaseTable = <
  DataType,
  SortIdType = string,
  FilterIdType = string,
  TabIdType = string,
>(
  props: Props<DataType, SortIdType, FilterIdType, TabIdType>
) => {
  const PAGE_SIZE = 20
  const { t } = useTranslation()
  const { setDropdown } = useDropdown()
  const [selectedSortOption, setSelectedSortOption] =
    useState<SortOption<SortIdType>>()
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<FilterOption<FilterIdType>>()
  const [_pagingCap, _setPagingCap] = useState<number>(
    props.pageData === false ? props.data.length : PAGE_SIZE
  )
  const dataLength = useRef<number>(props.data.length)
  const pagingCap = useRef<number>(_pagingCap)
  const scrollableElement = useRef<HTMLElement>()

  useEffect(() => {
    dataLength.current = props.data.length
    if (props.pageData === false) {
      setPagingCap(props.data.length)
    }
  }, [props.data])

  // On load we'll figure out if we want to set a sort/filter option
  useEffect(() => {
    // Manage the default sort id
    if (
      props.sort &&
      props.sort.options.length > 0 &&
      props.sort.defaultSelectedId
    ) {
      const selectedSort = props.sort.options.find(
        (o) => o.id === props.sort?.defaultSelectedId
      )
      if (selectedSort) {
        setSelectedSortOption(selectedSort)
      }
    }

    // Manage the default filter id
    if (
      props.filter &&
      props.filter.options.length > 0 &&
      props.filter.defaultSelectedId
    ) {
      const selectedFilter = props.filter.options.find(
        (o) => o.id === props.filter?.defaultSelectedId
      )
      if (selectedFilter) {
        setSelectedFilterOption(selectedFilter)
      }
    }
  }, [props.sort?.defaultSelectedId, props.filter?.defaultSelectedId, t])

  useEffect(() => {
    // Find the scrollable element
    const el = props.scrollParentId
      ? document.getElementById(props.scrollParentId)
      : document.querySelector('main')

    // Check we found an element
    if (el) {
      // Set our scrollable parent
      scrollableElement.current = el

      // Listen to the scroll
      scrollableElement.current.addEventListener('scroll', onParentScrolled)
    } else {
      // If we didn't find an element so we need to render all rows at once
      setPagingCap(props.data.length)
    }

    return () => {
      if (scrollableElement.current) {
        scrollableElement.current.removeEventListener(
          'scroll',
          onParentScrolled
        )
      }
    }
  }, [])

  // Used to set the paging cap - sets the useRef value first so we're not using out of date
  // state values - this will also avoid attempting to update the state value multiple times!
  const setPagingCap = (cap: number) => {
    if (cap !== pagingCap.current) {
      pagingCap.current = cap
      _setPagingCap(cap)
    }
  }

  // On scroll of whatever the parent is (by default it'll be the <main> component)
  const onParentScrolled = () => {
    if (scrollableElement.current) {
      // Remove the scroll listener if we've loaded all items
      if (pagingCap.current >= dataLength.current) {
        scrollableElement.current.removeEventListener(
          'scroll',
          onParentScrolled
        )
      }

      const scrollPosition =
        scrollableElement.current.scrollTop +
        scrollableElement.current.clientHeight
      const totalHeight = scrollableElement.current.scrollHeight

      // Define a threshold to trigger the action before reaching the bottom
      const threshold = 100

      if (totalHeight - scrollPosition < threshold) {
        setPagingCap(pagingCap.current + PAGE_SIZE)
      }
    }
  }

  const renderSortItem = (item: SortOption<SortIdType>, index: number) => {
    return (
      <ListItem
        key={`sortoptions_${index}`}
        title={item.label}
        style={'bold'}
        onClick={() => {
          setSelectedSortOption(item)
          props.sort?.onSortByChanged(item.id, item.value)
        }}
        leading={
          <Icon
            name={item.icon}
            size={'medium'}
            family={'sharp'}
            variant={item.iconVariant ?? 'solid'}
          />
        }
        trailing={
          selectedSortOption?.id === item.id ? (
            <Icon
              size={'medium'}
              family={'sharp'}
              name={'circle-check'}
              variant={'solid'}
            />
          ) : undefined
        }
        className={'px-2 max-w-full'}
      />
    )
  }

  const renderFilterItem = (item: FilterOption<FilterIdType>) => {
    return (
      <ListItem
        key={`filteroption_${item.id}`}
        title={item.label}
        style={'bold'}
        onClick={() => {
          setSelectedFilterOption(item)
          props.filter?.onFilterChanged(item.id, item.value)
        }}
        trailing={
          selectedFilterOption === item.id ? (
            <Icon
              size={'medium'}
              family={'sharp'}
              name={'circle-check'}
              variant={'solid'}
            />
          ) : undefined
        }
        className={'px-4 max-w-full'}
      />
    )
  }

  const pagedData = useMemo(() => {
    return props.data.slice(0, _pagingCap)
  }, [props.data, _pagingCap])

  return (
    <div>
      {/* Button sections */}
      {((props.showLeftController !== false && props.leftController) ||
        (props.showFilters !== false && props.filter) ||
        (props.showSort !== false && props.sort)) && (
        <div
          className={twMerge(
            'flex self-stretch items-end flex-col h-auto gap-y-4 z-50 pb-4',
            'tablet:content-between tablet:items-start tablet:flex-row tablet:pt-4'
          )}
          data-testid="category-tab"
        >
          {/* Left buttons */}
          {props.showLeftController !== false &&
            props.leftController &&
            ('tabs' in props.leftController &&
            props.leftController.tabs.values.length > 0 ? (
              <Tabs {...props.leftController.tabs} />
            ) : (
              'buttons' in props.leftController && (
                <div className="flex space-x-2">
                  {props.leftController.buttons.map((button, index) => {
                    const { hierarchy = 'secondary', ...restButtonProps } =
                      button
                    return (
                      <Button.Basic
                        key={index}
                        {...restButtonProps}
                        hierarchy={hierarchy}
                        size={'small'}
                        className={'self-start tablet:self-center'}
                      />
                    )
                  })}
                </div>
              )
            ))}
          {/* Right buttons */}
          <div className="flex flex-row gap-x-2 ml-auto max-w-full items-center">
            {props.showFilters !== false &&
              props.filter?.options &&
              props.filter.options.length > 0 && (
                <Button.Basic
                  id={'base_table_filters_btn'}
                  hierarchy={'secondary'}
                  size={'small'}
                  label={
                    !selectedFilterOption
                      ? t('filters')
                      : `${t('filters')}: ${selectedFilterOption.label}`
                  }
                  icon={{
                    name: 'bars-filter',
                  }}
                  className="self-center"
                  onClick={() => {
                    setDropdown({
                      target: 'base_table_filters_btn',
                      controller: (
                        <Dropdown.Controllers.BasicList
                          closeOnItemClick={true}
                          items={
                            props.filter?.options.map(renderFilterItem) ?? []
                          }
                        />
                      ),
                    })
                  }}
                />
              )}

            {props.showSort !== false &&
              props.sort?.options &&
              props.sort.options.length > 0 && (
                <Button.Basic
                  id={'base_table_sort_btn'}
                  hierarchy={'secondary'}
                  size={'small'}
                  label={
                    !selectedSortOption
                      ? t('sort')
                      : `${t('sort')}: ${selectedSortOption.label}`
                  }
                  icon={{
                    name: 'sort',
                  }}
                  className="self-center"
                  onClick={() => {
                    setDropdown({
                      target: 'base_table_sort_btn',
                      controller: (
                        <Dropdown.Controllers.BasicList
                          closeOnItemClick={true}
                          items={props.sort?.options.map(renderSortItem) ?? []}
                        />
                      ),
                    })
                  }}
                />
              )}
          </div>
        </div>
      )}

      {/* Table section */}
      {props.data.length > 0 ? (
        <div className="z-10">
          <div className="overflow-x-auto overscroll-x-contain">
            <div
              className={twMerge(
                'inline-block min-w-full align-middle',
                props.className
              )}
            >
              <table data-testid={props.testId} className="min-w-full">
                <thead className="border-b border-b-gray-100">
                  <tr>
                    {props.headers &&
                      props.headers.map((header, index) => (
                        <th
                          key={index}
                          scope="col"
                          colSpan={header.colSpan ?? 1}
                          className={twMerge(
                            'px-4 text-sm font-semibold text-gray-900 mr-8 h-[4.5rem] whitespace-nowrap',
                            header.alignment === 'right'
                              ? 'text-right'
                              : header.alignment === 'center'
                                ? 'text-center'
                                : 'text-left'
                          )}
                        >
                          {header.label}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {pagedData.map(props.onRenderRow)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        props.hideNoDataState === undefined ||
        (!props.hideNoDataState && (
          <div className="pt-20 justify-center" data-testid={'no_data_found'}>
            <Card.PageState
              type={'no_table_data'}
              title={t('no_data')}
              description={t('no_data_for_selection')}
            />
          </div>
        ))
      )}
    </div>
  )
}
