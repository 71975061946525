import { t } from 'i18next'

import { getAllRoles, getUser, updateOrgUser } from '@/api'
import { editWorkspaceMember } from '@/api/workspaces/v1'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { AuthManager } from '@/contexts/auth'
import { FormErrorsManager } from '@/contexts/formErrors'
import {
  getAccountOwnerGroupOrError,
  updateOwnerGroupToAddUserOrError,
  updateOwnerGroupToRemoveUserOrError,
} from '@/helpers/accountOwnerGroupHelper.ts'
import { IsNot } from '@/helpers/test'
import { key as QueryGroupsKey } from '@/hooks/queries/useGroups.ts'
import { key as QueryOrgUsersKey } from '@/hooks/queries/useOrgUsers'
import { key as QueryWorkspaceMembersKey } from '@/hooks/queries/useWorkspaceMembers'
import { ActionResponse } from '@/types/actions'
import { IUser } from '@/types/user'

export const EditUser = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  const name = formData.get('name')?.toString()?.trim() ?? ''
  const email = formData.get('email')?.toString()
  const workspaceId = formData.get('workspace_id')?.toString()
  const twoFactorEnabled = formData.get('two_factor_enabled')?.toString()
  const status = formData.get('status')?.toString()
  const roleId = formData.get('role_id') as string
  const isNewOwner = (formData.get('is_owner') as string) === 'true'
  const oldUser = JSON.parse(
    (formData.get('user_obj') as string) || '{}'
  ) as IUser

  // Check basic details
  if (IsNot(name, REGEX.IS_HUMAN_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('invalid_name_provided'),
      },
    ])
  } else if (IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('invalid_email_provided'),
      },
    ])
  } else if (IsNot(roleId, REGEX.IS_STRING_WITH_NUMBERS) && !isNewOwner) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_role_selection'),
      },
    ])
  } else if (IsNot(workspaceId, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('missing_workspace_id'),
      },
    ])
  }

  // Create a user object
  const newUser: IUser = {
    ...(oldUser || {}),
    name,
    email,
    two_factor_enabled: twoFactorEnabled === 'true',
    disabled: status === 'false',
  }

  // Attempt the update the user
  const updateRes = await updateOrgUser(newUser)

  // Check for errors
  if (updateRes.error) {
    throw new Error(updateRes.message)
  }

  // Check if we've just updated our own user account
  if (newUser.id === AuthManager.user?.id) {
    // Re-get our information
    const ourUserRes = await getUser()

    // Check for error
    if (ourUserRes.error || !ourUserRes.data) {
      return {
        error: true,
        message: t('user_updated_successfully'),
        completion: 'COMPLETE_WITH_ERROR',
        data: true,
      }
    }

    // Otherwise we got our user info so lets update the user
    await AuthManager.setUser(ourUserRes.data)
  }

  // Invalidate org user cache
  await queryClient.invalidateQueries({
    queryKey: [QueryOrgUsersKey, newUser.organization_id],
  })

  // User is created - we now need to grab some roles
  const roles = await getAllRoles()

  // Check we found the roles ok
  if (roles.error || !roles.data || roles.data.length <= 0) {
    console.error('failed to get roles')
    throw new Error(t('something_went_wrong'))
  }

  if (!isNewOwner) {
    // Find some roles
    // The 'fallbackRoleId' is the role with the lowest quantity of policies
    // we do this for safety; we can always increase the role.
    const requestedRoleId = roles.data.find((r) => r.id === roleId)?.id
    const fallbackRoleId = roles.data.sort(
      (a, b) => (a.policy_ids ?? []).length - (b.policy_ids ?? []).length
    )[0].id

    const workspaceRes = await editWorkspaceMember(
      workspaceId,
      newUser.id,
      requestedRoleId ?? fallbackRoleId
    )

    // Check for errors
    if (workspaceRes.error) {
      throw new Error(workspaceRes.message)
    }
  }

  // Invalidate team members cache
  await queryClient.invalidateQueries({
    queryKey: [QueryWorkspaceMembersKey, workspaceId],
  })

  const ownerGroup = await getAccountOwnerGroupOrError()
  const isAlreadyInOwnerGroup =
    ownerGroup.user_ids?.includes(newUser.id) === true

  const invalidateOwnerGroup = async () => {
    await queryClient.invalidateQueries({
      queryKey: [QueryGroupsKey],
    })
  }
  if (isNewOwner && !isAlreadyInOwnerGroup) {
    await updateOwnerGroupToAddUserOrError(ownerGroup, newUser.id)
    await invalidateOwnerGroup()
  } else if (!isNewOwner && isAlreadyInOwnerGroup) {
    await updateOwnerGroupToRemoveUserOrError(ownerGroup, newUser.id)
    await invalidateOwnerGroup()
  }

  return {
    error: false,
    message: t('user_updated_successfully'),
    completion: 'COMPLETE',
    data: true,
  }
}
