import { twMerge } from 'tailwind-merge'

import { Typography } from '@/components/typography'

import { Icon } from '../icon'

export type Size = 'large' | 'medium' | 'small'
export type Type = 'dot' | 'digit' | 'icon' | 'label'
export type Colors = 'red' | 'green' | 'blue' | 'yellow' | 'white'

interface BaseProps {
  type: Type
  color?: Colors
  size?: Size
  outline?: boolean
  className?: string
}
interface DotProps extends BaseProps {
  type: 'dot'
}
interface DigitProps extends BaseProps {
  type: 'digit'
  value: number
}
interface IconProps extends BaseProps {
  type: 'icon'
  icon: string
}
interface LabelProps extends BaseProps {
  type: 'label'
  value: string
}
export type Props = DotProps | DigitProps | IconProps | LabelProps
export const Badge = (props: Props) => {
  props = {
    size: 'medium',
    color: 'green',
    outline: false,
    ...props,
  }

  const widthAndHeight =
    props.type === 'dot'
      ? props.size === 'small'
        ? 'w-[0.5rem] h-[0.5rem]'
        : props.size === 'medium'
          ? 'w-[0.75rem] h-[0.75rem]'
          : 'w-[1rem] h-[1rem]'
      : props.type === 'label'
        ? props.size === 'small'
          ? 'w-auto h-[1.375rem] inline-flex px-[0.38rem]'
          : props.size === 'medium'
            ? 'w-auto h-[1.75rem] inline-flex px-[0.38rem]'
            : 'w-auto h-[2.25rem] inline-flex px-[0.62rem]'
        : props.size === 'small'
          ? 'w-[1.125rem] h-[1.125rem]'
          : props.size === 'medium'
            ? 'w-[1.5rem] h-[1.5rem]'
            : 'w-[2rem] h-[2rem]'

  return (
    <div
      className={twMerge(
        'flex items-center justify-center overflow-hidden',
        'border-solid border-transparent rounded-[6.25rem]',
        props.color === 'red'
          ? 'bg-red-500'
          : props.color === 'green'
            ? 'bg-green-500'
            : props.color === 'blue'
              ? 'bg-blue-500'
              : props.color === 'yellow'
                ? 'bg-yellow-500'
                : 'bg-gray-200',
        widthAndHeight,
        props.outline ? 'border-[0.125rem] border-white' : 'border-0',
        props.className
      )}
    >
      {props.type === 'digit' ? (
        <Typography
          variant={
            props.size === 'small'
              ? 'label-extrasmall'
              : props.size === 'medium'
                ? 'label-small'
                : 'label-medium'
          }
          className={
            props.color === 'yellow' || props.color === 'white'
              ? 'text-black'
              : 'text-white'
          }
        >
          {props.value.toString().substring(0, 1)}
        </Typography>
      ) : props.type === 'label' ? (
        <Typography
          variant={
            props.size === 'small'
              ? 'label-extrasmall'
              : props.size === 'medium'
                ? 'label-small'
                : 'label-medium'
          }
          className={
            props.color === 'yellow' || props.color === 'white'
              ? 'text-black'
              : 'text-white'
          }
        >
          {props.value}
        </Typography>
      ) : props.type === 'icon' ? (
        <Icon
          name={props.icon}
          variant={'solid'}
          size={
            props.size === 'small'
              ? 'xsmall'
              : props.size === 'medium'
                ? 'small'
                : 'medium'
          }
          className={
            props.color === 'yellow' || props.color === 'white'
              ? 'text-black'
              : 'text-white'
          }
        />
      ) : null}
    </div>
  )
}
