import { ReactNode, useEffect, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import de from '../../locale/locales/de.json'
import es from '../../locale/locales/es.json'
import fr from '../../locale/locales/fr.json'
import mk from '../../locale/locales/mk.json'
import nl from '../../locale/locales/nl.json'
import sq from '../../locale/locales/sq.json'
import sr from '../../locale/locales/sr.json'
import { SelectedLanguageContext } from './context'

const KEY = 'i18nextLng'

export type Props = {
  children: ReactNode
}

export const SelectedLanguageContextProvider = ({ children }: Props) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem(KEY) || 'en'
  )

  useEffect(() => {
    if (selectedLanguage) {
      localStorage.setItem(KEY, selectedLanguage)
    }
  }, [selectedLanguage])

  return (
    <ReactIntlProvider
      locale={selectedLanguage}
      defaultLocale={selectedLanguage}
      messages={
        {
          de,
          es,
          fr,
          mk,
          nl,
          sr,
          sq,
        }[selectedLanguage]
      }
    >
      <SelectedLanguageContext.Provider
        value={{ selectedLanguage, setSelectedLanguage }}
      >
        {children}
      </SelectedLanguageContext.Provider>
    </ReactIntlProvider>
  )
}
