import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@/components/icon'
import { Switch } from '@/components/switch'
import { ICustodian } from '@/types/custodian'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

export interface Props extends BaseTableProps {
  name?: string
  className?: string
  assetCustodians: ICustodian[]
  setCustodian: (custodian: ICustodian[]) => void
}

export const AssetCustodianTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const renderRow = (row: ICustodian, index: number) => {
    const [enable2FA, setEnable2FA] = useState<boolean>(
      row.status === 'ACTIVE' ? true : false
    )
    const networks =
      row.supported_assets && row.supported_assets.length > 0
        ? [
            ...new Set(
              row.supported_assets.map((_assets) => _assets['network'])
            ),
          ]
        : []

    const onUpdate2Fa = (custodian: ICustodian, isEnabled: boolean) => {
      const status = isEnabled ? 'ACTIVE' : 'DEACTIVE'
      const _custodian = { ...custodian, status }
      const updatedCustodians = props.assetCustodians.map((_c: ICustodian) => {
        if (_c.id === _custodian.id) return _custodian
        return { ..._c, status: _c.status ? _c.status : 'DEACTIVE' }
      })
      props.setCustodian(updatedCustodians)
    }

    return (
      <Row key={`${row.id}_${index}`}>
        <Cell.Icon>
          <Icon
            name="buildings"
            variant={'solid'}
            family={'sharp'}
            size={'md'}
          />
        </Cell.Icon>
        <Cell.Text title={row.name} className={'max-w-[20rem]'}>
          {row.name}
        </Cell.Text>
        <Cell.Number>{networks.length}</Cell.Number>
        <Cell.Number>{row?.supported_assets?.length ?? 0}</Cell.Number>
        <Cell.Chip>
          <Switch
            testId={`switch_${row.name.toLowerCase().replace(/ /, '_')}`}
            name={'two_factor_enabled'}
            enabled={enable2FA}
            onChanged={() => {
              setEnable2FA(!enable2FA)
              onUpdate2Fa(row, !enable2FA)
            }}
            size={'large'}
          />
        </Cell.Chip>
      </Row>
    )
  }

  return (
    <BaseTable<ICustodian, null, null, null>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      headers={[
        {
          label: t('name'),
          colSpan: 2,
        },
        {
          label: t('networks'),
          alignment: 'center',
        },
        {
          label: t('assets'),
          alignment: 'center',
        },
        {
          label: t('enable_disable'),
          alignment: 'center',
        },
      ]}
      className={props?.className}
      data={props.assetCustodians}
      onRenderRow={renderRow}
    />
  )
}
