import { twMerge } from 'tailwind-merge'

import remToPx from '@/helpers/remToPx'

interface Props {
  color: 'WHITE' | 'RED' | 'BLACK'
  background: 'RED' | 'WHITE' | 'BLACK' | 'TRANSPARENT'
  shape?: 'ROUND' | 'SQUARE'
  sizeInRem?: number
}

export const LogoIcon = (props: Props) => {
  // Set some defaults
  props = {
    shape: 'ROUND',
    sizeInRem: 8.75,
    ...props,
  }

  const textColor: { [color in Props['color']]: string } = {
    RED: 'text-[#E01F3D]',
    WHITE: 'text-[white]',
    BLACK: 'text-[#212427]',
  }

  const backgroundColor: { [color in Props['background']]: string } = {
    RED: 'bg-[#E01F3D]',
    WHITE: 'bg-white',
    BLACK: 'bg-[#212427]',
    TRANSPARENT: 'bg-transparent',
  }

  return (
    <div
      className={twMerge(
        'flex items-center justify-center',
        props.shape === 'ROUND' && 'rounded-full',
        props.shape === 'SQUARE' && 'rounded-none',
        backgroundColor[props.background]
      )}
      style={{
        width: `${props.sizeInRem ?? 0}rem`,
        height: `${props.sizeInRem ?? 0}rem`,
      }}
    >
      <div className={twMerge(textColor[props.color])}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={`${remToPx((props.sizeInRem ?? 0) * 1)}`}
          viewBox="0 0 140 140"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.985 83.7162L71.7682 26.9676C70.9659 25.6749 69.0282 25.6749 68.226 26.9676L33.0095 83.7162C27.5046 92.5868 33.9596 103.992 44.4848 103.992H53.8575C47.2257 103.973 43.1643 96.7814 46.6365 91.1863L68.8785 55.3451C69.3851 54.5287 70.609 54.5287 71.1156 55.3451L93.3577 91.1864C96.8298 96.7814 92.7684 103.973 86.1366 103.992H95.5094C106.035 103.992 112.49 92.5868 106.985 83.7162ZM69.4378 115.692L58.3167 97.7713C56.5784 94.9701 58.6168 91.3683 61.9405 91.3683H78.0536C81.3773 91.3683 83.4157 94.9701 81.6774 97.7713L70.5563 115.692C70.303 116.1 69.6911 116.1 69.4378 115.692Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  )
}
