import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { findLastStatus } from '@/actions/create-asset/assetForm'
import { Button } from '@/components/button'
import Chip from '@/components/chip'
import Dropdown from '@/components/dropdown'
import AssetIcon from '@/components/table/rows/assetIcon.tsx'
import { ROUTES } from '@/constants/routes'
import { useDropdown } from '@/contexts/interface'
import { getAssetTypeDescriptionForColumn } from '@/helpers/assetTypeDescription.ts'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { dateToString } from '@/helpers/dateToString.ts'
import { formatAssetPrice } from '@/helpers/formatValue'
import { IAsset, IAssetStatusName } from '@/types/asset'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

type SortBy = 'az' | 'latest' | 'value'
type FilterBy = 'all' | IAssetStatusName
type GroupBy = 'digital' | 'physical'

export interface Props extends BaseTableProps {
  assets: IAsset[]
  onRemoveFromCase?: (a: IAsset) => void
}

export const AssetsTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = 'latest'
  const defaultFilterBy: FilterBy = 'all'
  const { t } = useTranslation()
  const { setDropdown } = useDropdown()
  const navigate = useNavigate()

  const [_sortBy, setSortBy] = useState<SortBy>(defaultSortBy)
  const [_filterBy, setFilterBy] = useState<FilterBy>(defaultFilterBy)
  const [_assets, setAssets] = useState<IAsset[]>([])

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setAssets([...sort(filter(group(props.assets)))])
  }, [props.assets])

  // Monitor for sortBy changes + update
  useEffect(() => {
    setAssets([...sort(_assets)])
  }, [_sortBy])

  // Monitor for groupBy + filterBy changes + update
  useEffect(() => {
    setAssets([...sort(filter(group(props.assets)))])
  }, [_filterBy])

  // Update our visibility and sort
  const onFilterChanged = (id: FilterBy) => setFilterBy(id)
  const onSortChanged = (id: SortBy) => setSortBy(id)

  // Sorts the provided data
  const sort = (assets: IAsset[]): IAsset[] => {
    switch (_sortBy) {
      case 'latest':
        return assets.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      case 'az':
        return assets.sort((a, b) =>
          (a.external_ref ?? '').localeCompare(b.external_ref ?? '')
        )
      case 'value':
        return assets.sort(
          (a, b) => (b.price?.value ?? 0) - (a.price?.value ?? 0)
        )
      default:
        return assets
    }
  }

  // Group the provided data
  const group = (assets: IAsset[]): IAsset[] => assets

  // Filter the data
  const filter = (assets: IAsset[]): IAsset[] => {
    let filteredAssets = assets

    // Only filter if we've not selected 'all'
    if (_filterBy === 'all') return filteredAssets
    else if (_filterBy === 'WITHOUT_OPERATION') {
      filteredAssets = assets
        .sort(
          (a, b) =>
            new Date(a.created_at ?? 0).getTime() -
            new Date(b.created_at ?? 0).getTime()
        )
        .filter((a) => {
          return a.operation_id === '000000000000000000000000'
        })
    } else {
      filteredAssets = assets.filter((a) => {
        const lastStatus = a.statuses.sort(
          (a, b) =>
            new Date(a.created_at ?? 0).getTime() -
            new Date(b.created_at ?? 0).getTime()
        )[0]
        return lastStatus.name === _filterBy
      })
    }

    return filteredAssets
  }

  // eslint-disable-next-line react/display-name
  const renderRows = (asset: IAsset, index: number) => {
    const handleNavigateToAssetDetails = () => {
      navigate(
        ROUTES.ASSETS.DETAIL.replace(':asset_id', asset?.id).replace(
          ':asset_id',
          asset?.id
        ),
        {
          state: {
            asset,
            category: asset.category,
          },
        }
      )
    }
    const lastStatus = findLastStatus(asset.statuses)
    return (
      <Row key={index} onClick={handleNavigateToAssetDetails}>
        <Cell.Icon>
          <AssetIcon asset={asset} />
        </Cell.Icon>
        <Cell.Text>{getAssetTypeDescriptionForColumn(asset)}</Cell.Text>
        <Cell.Text>{asset.external_ref ?? ''}</Cell.Text>
        <Cell.Number align="right">{formatAssetPrice(asset.price)}</Cell.Number>
        <Cell.Text align={'center'}>{dateToString(asset.created_at)}</Cell.Text>
        {lastStatus && (
          <Cell.Chip>
            <Chip.Status
              size={'medium'}
              context={'ASSET'}
              status={lastStatus}
            />
          </Cell.Chip>
        )}
        <Cell.Button>
          <Button
            id={`btn_asset_ellip_drop_${asset.id}`}
            layout={'icon'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'ellipsis',
            }}
            onClick={() => {
              setDropdown({
                target: `btn_asset_ellip_drop_${asset.id}`,
                controller: (
                  <Dropdown.Controllers.AssetActions
                    asset={asset}
                    onRemoveFromCase={props.onRemoveFromCase}
                  />
                ),
                maxWidthPx: 270,
              })
            }}
          />
        </Cell.Button>
      </Row>
    )
  }

  return (
    <BaseTable<IAsset, SortBy, FilterBy, GroupBy>
      testId={props.testId}
      showLeftController={props.showLeftController}
      leftController={{
        buttons: props.leftButtons ?? [],
      }}
      showFilters={props.showFilters}
      showSort={props.showSort}
      hideNoDataState={props.hideNoDataState}
      pageData={false}
      filter={{
        options: (
          [
            'all',
            'WITHOUT_OPERATION',
            'PENDING',
            'READY',
            'SEIZED',
            'ADOPTED',
            'AWARDED',
            'FORFEITED',
            'TRANSFERRED',
            'RETURNED',
            'LIQUIDATED',
            'DELETED',
            'CLOSED',
          ] as FilterBy[]
        ).map((o) => {
          return {
            id: o,
            label: capitalizeFirstLetter(t(o.toLowerCase())),
            value: o,
          }
        }),
        onFilterChanged: onFilterChanged,
        defaultSelectedId: defaultFilterBy,
      }}
      sort={{
        options: [
          {
            id: 'az',
            value: 'az',
            label: t('a-z'),
            icon: 'user-circle',
          },
          {
            id: 'latest',
            value: 'latest',
            label: t('latest'),
            icon: 'calendar',
            iconVariant: 'regular',
          },
          {
            id: 'value',
            value: 'value',
            label: t('value'),
            icon: 'coins',
            iconVariant: 'regular',
          },
        ],
        onSortByChanged: onSortChanged,
        defaultSelectedId: defaultSortBy,
      }}
      headers={[
        {
          label: t('asset_type'),
          colSpan: 2,
        },
        {
          label: t('identifier'),
        },
        {
          label: t('value'),
          alignment: 'right',
        },
        {
          label: t('created'),
          alignment: 'center',
        },
        {
          label: t('status'),
          alignment: 'center',
        },
        {
          label: '',
        },
      ]}
      data={_assets}
      onRenderRow={renderRows}
    />
  )
}
