import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Divider } from '@/components/divider'
import { Typography } from '@/components/typography'

import { Icon } from '../../icon'

export interface Props {
  title?: ReactNode
  subTitle?: string
  description?: ReactNode
  icon?: string
  dividerAbove?: boolean
  dividerBelow?: boolean
  classOverrides?: {
    base?: string
    title?: string
    subTitle?: string
    description?: string
    icon?: string
  }
}

export const ParagraphHeading = (props: Props) => {
  return (
    <span
      className={twMerge(
        'flex flex-col justify-center items-center gap-6 max-w-[50rem]',
        props.classOverrides && props.classOverrides.base
      )}
    >
      {props.dividerAbove === true && (
        <Divider size="small" orientation="horizontal" />
      )}
      <span
        className={
          'flex flex-row justify-center items-center gap-12 self-stretch'
        }
      >
        <span className={'flex flex-1 flex-col justify-center items-start'}>
          {props.title && (
            <Typography
              variant={'heading-medium'}
              spacers={['bottom']}
              className={props.classOverrides && props.classOverrides.title}
            >
              {props.title}
            </Typography>
          )}
          {props.subTitle && (
            <Typography
              variant={'heading-small'}
              spacers={['bottom']}
              className={props.classOverrides && props.classOverrides.subTitle}
            >
              {props.subTitle}
            </Typography>
          )}
          {props.description && (
            <Typography
              variant={'paragraph-large'}
              spacers={['bottom']}
              className={
                props.classOverrides && props.classOverrides.description
              }
            >
              {props.description}
            </Typography>
          )}
        </span>
        {props.icon && (
          <Icon
            name={props.icon}
            family="sharp"
            size={'xxlarge'}
            className={props.classOverrides && props.classOverrides.icon}
          />
        )}
      </span>
      {props.dividerBelow === true && (
        <Divider size="small" orientation="horizontal" />
      )}
    </span>
  )
}
