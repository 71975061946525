import React, {
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { Props as DropdownViewProps } from '@/components/dropdown/components/view.dropdown'

import { NavigationAction, ViewId } from '../types'
import { RowTypes } from './list.dropdown'

export interface Props<T> {
  testId?: string
  defaultViewId?: ViewId
  children?:
    | ReactElement<DropdownViewProps<T>>
    | ReactElement<DropdownViewProps<T>>[]
  closeOnItemClick?: boolean
  onItemClicked?: (item: RowTypes<T>) => void

  // These properties are overwritten by the dropdown when it
  // renders this controller - this should not directly
  // be asigned to
  dropdownId?: string
  openTimestamp?: number
  isOpen?: boolean
  close?: () => void
  requestHeight?: (height: number) => void
}

export interface ControllerActions {
  goToView: (viewId: ViewId) => void
  goBack: () => void
}

declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null
}

const DropdownController = <T,>(
  props: Props<T>,
  ref: React.ForwardedRef<ControllerActions>
) => {
  const [_viewIdHistory, setViewIdHistory] = useState<ViewId[]>([])
  const [_lastAction, setLastAction] = useState<NavigationAction | undefined>()

  // Expose a few functions
  useImperativeHandle(ref, () => {
    return {
      goToView,
      goBack,
    }
  }, [props.close, props.requestHeight, props.children])

  useEffect(() => {
    let viewId = null

    if (props.isOpen === false) {
      setLastAction(() => {
        return undefined
      })
      setViewIdHistory(() => {
        return []
      })
    } else {
      if (props.defaultViewId) {
        viewId = props.defaultViewId
      } else if (Array.isArray(props.children) && props.children.length > 0) {
        viewId = props.children[0].props.id
      } else if (!Array.isArray(props.children) && props.children) {
        viewId = props.children.props.id
      }

      // If we have a valid view Id to start us off then
      // we'll transition that view into ...view
      if (viewId) {
        goToView(viewId)
      }
    }
  }, [props.isOpen])

  const goToView = useCallback(
    (id: ViewId) => {
      const latestViewId = _viewIdHistory[_viewIdHistory.length - 1]
      if (latestViewId !== id) {
        setLastAction(() => {
          return {
            from: _viewIdHistory[_viewIdHistory.length - 1],
            to: id,
            direction: 'FORWARD',
          }
        })
        setViewIdHistory((currHistory) => {
          return [...currHistory, id]
        })
      }
    },
    [_lastAction, _viewIdHistory]
  )

  const goBack = useCallback(() => {
    if (_viewIdHistory.length <= 1) {
      props.close?.()
    } else {
      setLastAction({
        from: _viewIdHistory[_viewIdHistory.length - 1],
        to: _viewIdHistory[_viewIdHistory.length - 2],
        direction: 'BACK',
      })
      setViewIdHistory((currHistory) => {
        return currHistory.slice(0, currHistory.length - 1)
      })
    }
  }, [_lastAction, _viewIdHistory])

  const close = useCallback(() => {
    props.close?.()
  }, [])

  const onItemClicked = (item: RowTypes<unknown>) => {
    if (props.closeOnItemClick === true) {
      props.close?.()
    }
    props.onItemClicked && props.onItemClicked(item)
  }

  const onViewSizeChanged = (height: number) => {
    props.requestHeight?.(height)
  }

  const mappedChildren = React.useMemo(() => {
    return React.Children.map(props.children, (child) => {
      if (child) {
        return React.cloneElement(
          child,
          {
            testId: props.testId,
            dropdownId: props.dropdownId,
            close,
            goToView,
            goBack,
            onItemClicked,
            viewSizeChanged: onViewSizeChanged,
            action: _lastAction,
          },
          null
        )
      }
      return null
    })
  }, [
    props.children,
    props.testId,
    props.dropdownId,
    close,
    goToView,
    goBack,
    onItemClicked,
    onViewSizeChanged,
    _lastAction,
  ])

  return <div className={'w-auto h-fit'}>{mappedChildren}</div>
}
DropdownController.displayName = 'DropdownController'
export default React.memo(React.forwardRef(DropdownController))
