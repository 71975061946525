import { SyntheticEvent, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  src: string
  fallbackSrc?: string
  testId?: string
} & JSX.IntrinsicElements['img']

export const Image = (props: Props): JSX.Element => {
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [_fadeIn, setFadeIn] = useState<null | boolean>(null)
  const startTime = performance.now()

  const handleLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const duration = performance.now() - startTime
    setFadeIn(!props.src ? null : duration > 200)
    props.onLoad && props.onLoad(e)
  }

  const handleError = () => {
    if (imgRef.current) {
      if (!props.fallbackSrc || props.fallbackSrc === imgRef.current.src) {
        imgRef.current.onerror = null // prevents looping
        imgRef.current.src =
          'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
      } else {
        imgRef.current.src = props.fallbackSrc
      }
    }
  }
  return (
    <img
      {...Object.fromEntries(
        Object.entries(props).filter(
          ([key]) => !['fallbackSrc', 'testId'].includes(key)
        )
      )}
      className={twMerge(
        _fadeIn === null || !props.src
          ? 'opacity-0'
          : _fadeIn
            ? 'animate-fade-in'
            : 'opacity-1',
        props.className
      )}
      onLoad={handleLoad}
      onError={handleError}
      ref={imgRef}
      data-testid={props.testId}
    />
  )
}
