import Cookies, { CookieAttributes } from 'js-cookie'
import { useState } from 'react'

export default function useCookies() {
  const [cookies, setCookies] = useState(() => Cookies.get() || {})

  const get = (name: string) => {
    return cookies[name]
  }

  const set = (name: string, value: string, options?: CookieAttributes) => {
    setCookies({ ...cookies, [name]: value })
    Cookies.set(name, value, options)
  }

  const remove = (name: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: _, ...otherCookies } = cookies
    setCookies(otherCookies)
    Cookies.remove(name)
  }

  return { get, set, remove, cookies }
}
