import { t } from 'i18next'
import { ReactElement, useEffect, useRef, useState } from 'react'

import { Props as IconProps } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { AutoCompleteService } from '@/types/google'

import { Props as DropdownViewProps } from '../../dropdown/components/view.dropdown'
import { Props as InputFieldProps } from '../components/field.input'
import { InputHint, Props as InputHintProps } from '../components/hint.input'
import { Props as InputLabelProps } from '../components/label.input'
import { InputSuggestion } from './suggestion.input'

interface Props<T> {
  country: string
  name?: string
  defaultValue?: string
  label?: string | ReactElement<InputLabelProps>
  hint?: string | ReactElement<InputHintProps>
  icon?: ReactElement<IconProps>
  size?: InputFieldProps['size']
  placeholder?: string
  listGap?: DropdownViewProps<T>['gap']
  state?: 'default' | 'disabled'
  className?: string
  testId?: string
}

export const InputAddress = <T,>(props: Props<T>) => {
  const [_value, setValue] = useState<string | undefined>(
    props.defaultValue ?? undefined
  )
  const [_items, setItems] = useState<DropdownViewProps<T>['items']>([])
  const [_error, setError] = useState<string>('')
  const autocompleteRef = useRef<AutoCompleteService>()

  useEffect(() => {
    const asyncFunc = async () => {
      const { AutocompleteService } =
        await window.google.maps.importLibrary('places')
      autocompleteRef.current = new AutocompleteService()
    }
    asyncFunc()
  }, [])

  useEffect(() => {
    if (!_error) {
      getAddress()
    }
  }, [_value])

  const onInputValueChanged = (val: string) => {
    setValue(val)
  }

  const getAddress = async () => {
    const _currentValue = _value
    if (!_currentValue) {
      setItems([])
    } else {
      try {
        const res = await autocompleteRef.current?.getPlacePredictions({
          input: _currentValue ?? '',
          componentRestrictions: {
            country: props.country,
          },
        })

        if (res && 'predictions' in res) {
          setItems(
            res.predictions.map((p, i) => (
              <ListItem
                key={`prediction_${i}`}
                title={p.description}
                value={p.description}
                data={p}
                className={'pl-2 pr-2'}
              />
            ))
          )
        }
      } catch (e) {
        console.error('err', e)
        setError('Unable to search for address. Please type in manually.')
      }
    }
  }

  return (
    <InputSuggestion
      name={props.name}
      testId={props.testId}
      label={t('address')}
      defaultValue={props.defaultValue}
      forceDefaultValue={true}
      hint={
        _error ? (
          <InputHint label={_error} style={'negative'} />
        ) : (
          <InputHint label={t('start_typing_address')} style={'hint'} />
        )
      }
      addValueAsEntry={true}
      onValueChanged={onInputValueChanged}
      items={_items}
    />
  )
}
