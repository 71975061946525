import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'

import NavigationView from './views/navigation.view'

interface Props extends ControllerProps<unknown> {}
const NavigationController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'NAVIGATION'}
      closeOnItemClick={true}
    >
      <NavigationView />
    </DropdownController>
  )
}
export default NavigationController
