import { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'

export type Size = 'xlarge' | 'large' | 'medium'

export interface Props {
  icon: string
  label: string
  size?: Size
  selected?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void

  // Additional props + overwrites
  testId?: string
  className?: string
  withAttributes?: JSX.IntrinsicElements['button']
}

export const ButtonLabelled = (props: Props) => {
  // Set some defaults
  props = {
    size: 'medium',
    selected: false,
    ...props,
  }

  return (
    <button
      aria-label={props.label}
      className={twMerge(
        'flex flex-col justify-center items-center shrink-0 bg-gray-100 rounded-md border border-transparent overflow-hidden',
        'transition-[border-color,background-color]',
        'hover:border-gray-400 active:bg-gray-200',
        props.selected && 'bg-green-100',
        props.size === 'xlarge'
          ? 'h-24 w-24 gap-[0.5625rem] px-3'
          : props.size === 'large'
            ? 'h-[4.75rem] w-[4.75rem] gap-1 px-2'
            : 'h-16 w-16 gap-[0.125rem]',
        props.className
      )}
      onClick={props.onClick}
      {...(props.testId && { 'data-cy': props.testId })}
      {...props.withAttributes}
    >
      <span
        className={twMerge(
          'flex items-center justify-center',
          props.size === 'xlarge' ? 'h-10 w-10' : 'h-8 w-8'
        )}
      >
        <Icon
          name={props.icon}
          variant="solid"
          family="sharp"
          size={props.size === 'xlarge' ? 'large' : 'medium'}
        />
      </span>
      <Typography
        variant={props.size === 'xlarge' ? 'label-medium' : 'label-small'}
        className={'w-full whitespace-nowrap overflow-hidden text-ellipsis'}
      >
        {props.label}
      </Typography>
    </button>
  )
}
