import { BannerProps, BannerVariant } from './types'

interface IBannerManager {
  showBannerHook: React.Dispatch<BannerProps> | null
  hideBannersHook: React.Dispatch<BannerVariant | 'all'> | null
  setShowBannerFunction: (fn: React.Dispatch<BannerProps>) => void
  setHideBannersFunction: (fn: React.Dispatch<BannerVariant | 'all'>) => void
  showBanner: (banner: BannerProps) => void
  hideBanners: (variant: BannerVariant | 'all') => void
}

export const BannerManager: IBannerManager = {
  showBannerHook: null,
  hideBannersHook: null,

  setShowBannerFunction(fn) {
    this.showBannerHook = fn
  },
  setHideBannersFunction(fn) {
    this.hideBannersHook = fn
  },

  showBanner(props: BannerProps) {
    if (this.showBannerHook) this.showBannerHook(props)
  },

  hideBanners(variant: BannerVariant | 'all') {
    if (this.hideBannersHook) this.hideBannersHook(variant)
  },
}
