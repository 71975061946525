import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { ICreateFile, IFile } from '@/types/file'
import { IOperation, IOperationStatus, IStats } from '@/types/operations'

export interface IUpdateOperationRequest {
  id?: string
  platform_id?: string
  created_at?: string
  last_updated_at?: string
  created_by?: string
  organization_id?: string
  external_ref?: string
  status: IOperationStatus
  name: string
}

export interface ICreateOperationRequest {
  files?: ICreateFile[]
  external_ref?: string
  status?: IOperationStatus
  name: string
}

/**
 * Get all operations
 * @returns IOperation[] -> operation array
 */
export const getOperations = async (): Promise<BaseResponse<IOperation[]>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<BaseResponse<IOperation[]>>(`/operations`)
    .then((response) => {
      return response.data
    })
}

/**
 * Get single operation
 * @returns IOperation -> operation array
 */
export const getOperation = async (
  operationId: string
): Promise<BaseResponse<IOperation>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<BaseResponse<IOperation>>(`/operations/${operationId}`)
    .then((response) => {
      return response.data
    })
}

/**
 * Creates a new operation
 * @returns string - New Operation Id
 */
export const createOperation = async (
  data: ICreateOperationRequest
): Promise<BaseResponse<string>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .post<BaseResponse<string>>(`/operations`, data)
    .then((response) => {
      return response.data
    })
}

/**
 * Get top assets and total price, total value
 * @returns <IStats> -> Get top assets
 */
export const getStats = async (): Promise<BaseResponse<IStats>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .get<BaseResponse<IStats>>(`/operations/stats`)
    .then((response) => {
      return response.data
    })
}

export const updateOperation = async (
  operationId: string,
  data: IOperation
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .put<BaseResponse<boolean>>(`operations/${operationId}`, data)
    .then((response) => {
      return response.data
    })
}

/*
  Provide the full list of files - this will overwrite the existing collection
  so we can use this call to remove as well as add
*/
export const updateOperationFiles = async (
  operationId: string,
  files: (IFile | ICreateFile)[]
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .put<BaseResponse<boolean>>(`operations/${operationId}/files`, files)
    .then((response) => {
      return response.data
    })
}

export const deleteArchivedOperation = async (
  operationId: string
): Promise<BaseResponse<boolean>> => {
  const client = await getClient((await getConfig()).BROKER_API_URL_V2)
  return client
    .delete<BaseResponse<boolean>>(`operations/${operationId}/archive`)
    .then((response) => {
      console.log('deleteArchivedOperation', response)
      return response.data
    })
}
