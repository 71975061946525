import {
  ChangeEvent,
  FocusEvent,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ButtonShape,
  Props as ButtonShapeProps,
} from '@/components/button/variants/shape.button'
import { useId } from '@/hooks/useId'

import { Icon, Props as IconProps } from '../../icon'
import { Typography } from '../../typography'
import { InputFrame, Props as InputFrameProps } from './frame.input'

type Variants =
  | 'default'
  | 'dropdown'
  | 'search-dropdown'
  | 'auction'
  | 'id-number'
  | 'textfield'
  | 'pin'
type ElementStates = 'default' | 'readonly' | 'disabled' | 'error'

export interface BaseProps {
  id?: string
  input: ReactElement<InputFrameProps>
  prefix?: string
  suffix?: string
  icon?: ReactElement<IconProps>
  trailing?: TrailingOptions
  size?: 'medium' | 'large'
  variant?: Variants
  state?: ElementStates
  className?: string
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onClick?: MouseEventHandler
  onValueChanged?: (e: ChangeEvent<HTMLInputElement>) => void
  testId?: string
}

interface TrailingIcon {
  icon: ReactElement<IconProps>
}
interface TrailingButton {
  button: ReactElement<ButtonShapeProps>
}
type TrailingOptions = TrailingIcon | TrailingButton

export interface DefaultProps extends BaseProps {
  variant: 'default' | 'auction' | 'id-number' | 'pin' | 'dropdown'
  min?: number
}
export type Props = DefaultProps
export const InputElement = (props: Props) => {
  const { id } = useId()
  const [_isFocused, setIsFocused] = useState(false)

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onValueChanged && props.onValueChanged(e)
    props.input.props.onChange && props.input.props.onChange(e)
  }

  const onInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    props.onFocus && props.onFocus(e)
    props.input.props.onFocus && props.input.props.onFocus(e)
  }

  const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    props.onBlur && props.onBlur(e)
    props.input.props.onBlur && props.input.props.onBlur(e)
  }

  const WrapperComponent = ['dropdown', 'search-dropdown'].includes(
    props.variant
  )
    ? 'button'
    : 'div'

  return (
    <WrapperComponent
      data-testid={props.testId}
      id={props.id ?? id('input_el_id')}
      onClick={props.onClick}
      className={twMerge(
        'flex flex-1 flex-row items-center gap-2',
        'w-full overflow-hidden text-left',
        'group border-solid border-[1px] rounded-[6px]',
        'transition-[border-color,background-color,box-shadow] ease duration-300',
        props.size === 'large'
          ? 'h-[3.5rem] min-h-[3.5rem] px-[1rem]'
          : 'h-[2.75rem] min-h-[2.75rem] px-[0.75rem]',
        props.variant === 'id-number'
          ? 'bg-[#D6E6F7] border-[#D6E6F7]'
          : props.variant === 'auction'
            ? 'bg-transparent border-transparent px-0'
            : 'bg-white border-[#21242740]',
        props.state === 'disabled'
          ? 'bg-[#E1E1E2] border-[#E1E1E2]'
          : props.state === 'error'
            ? 'bg-white border-[0.1875rem] border-[#FFC42D]'
            : props.state === 'readonly' && props.variant !== 'auction'
              ? 'bg-[#F5F5F6] border-[#F5F5F6]'
              : props.variant === 'default' || props.variant === 'dropdown'
                ? 'hover:bg-white hover:border-[#212427]'
                : '',
        _isFocused &&
          props.variant === 'default' &&
          props.state === 'default' &&
          'border-[#212427]',
        props.className
      )}
    >
      {props.icon && (
        <Icon
          size={props.size === 'large' ? 'medium' : 'small'}
          {...props.icon.props}
          state={props.state === 'disabled' ? 'disabled' : 'default'}
        />
      )}
      {props.prefix && (
        <Typography
          variant={props.size === 'large' ? 'label-large' : 'label-medium'}
          className={props.state === 'disabled' ? 'text-[#909293]' : ''}
        >
          {props.prefix}
        </Typography>
      )}
      <InputFrame
        variant={
          props.variant === 'dropdown'
            ? 'dropdown'
            : props.variant === 'pin'
              ? 'pin'
              : 'default'
        }
        {...props.input.props}
        onChange={onValueChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        state={
          props.state === 'disabled' ||
          props.state === 'readonly' ||
          props.variant === 'id-number'
            ? 'disabled'
            : 'default'
        }
        min={props.min}
      />
      {props.suffix && (
        <Typography
          variant={props.size === 'large' ? 'label-large' : 'label-medium'}
          className={props.state === 'disabled' ? 'text-[#909293]' : ''}
        >
          {props.suffix}
        </Typography>
      )}
      {props.trailing && 'icon' in props.trailing && (
        <Icon
          {...props.trailing.icon.props}
          state={props.state === 'disabled' ? 'disabled' : 'default'}
        />
      )}
      {props.trailing && 'button' in props.trailing && (
        <ButtonShape
          {...props.trailing.button.props}
          size={props.size === 'large' ? 'small' : 'xsmall'}
          state={props.state === 'disabled' ? 'disabled' : 'default'}
        />
      )}
    </WrapperComponent>
  )
}
