import ReactHtmlParser from 'react-html-parser'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { Icon } from '@/components/icon'
import { Paragraph } from '@/components/paragraph'
import { __PageBannerProps } from '@/contexts/banner/types'

const DefaultPageBanner = (props: __PageBannerProps) => {
  return (
    <div className={twMerge('animate-fade-in w-full', props.className)}>
      <div className={twMerge('p-4 pb-0 tablet:pt-8 tablet:p-6 tablet:pb-0')}>
        <div
          className={twMerge(
            'flex flex-col w-full justify-center',
            'rounded-[12px]',
            props.description
              ? 'pt-[8px] pr-[8px] pb-[16px]'
              : 'py-[4px] pr-[4px]',
            props.onClick && 'cursor-pointer',
            props.type === 'error'
              ? 'bg-yellow-500'
              : props.type === 'success'
                ? 'bg-green-100'
                : 'bg-blue-100',
            'tablet:flex-row'
          )}
          onClick={props.onClick}
        >
          <div
            className={twMerge(
              'flex flex-1 flex-row min-h-[3rem] items-center',
              'overflow-hidden',
              'gap-[0.125rem] justify-between',
              props.description && 'flex-none tablet:items-start'
            )}
          >
            <div
              className={twMerge(
                'px-[16px]',
                props.description && 'tablet:pt-[6px]'
              )}
            >
              <Icon
                size={'medium'}
                family={'sharp'}
                variant={'solid'}
                name={
                  props.type === 'error'
                    ? 'triangle-exclamation'
                    : props.type === 'success'
                      ? 'circle-check'
                      : 'circle-info'
                }
                className={'text-black'}
              />
            </div>
            {!props.description && (
              <div className={'flex flex-1 overflow-hidden'}>
                <Paragraph
                  subTitle={props.title}
                  spacerOverrides={{
                    subTitle: ['top', 'bottom'],
                  }}
                />
              </div>
            )}

            {props.close && (
              <Button.Shape
                layout={'icon'}
                hierarchy={'tertiary'}
                icon={{ name: 'close', family: 'sharp', variant: 'solid' }}
                onClick={props.close}
                className="tablet:hidden"
              />
            )}
          </div>

          {props.description && (
            <div className={'flex flex-1 overflow-hidden px-6 tablet:px-0'}>
              <Paragraph
                subTitle={props.title}
                description={ReactHtmlParser(props.description)}
                spacerOverrides={{
                  subTitle: [],
                  description: [],
                }}
                classOverrides={{
                  subTitle: 'tablet:pt-[8px]',
                }}
              />
            </div>
          )}

          {props.cta && (
            <div
              className={twMerge(
                'flex px-6 pt-[16px]',
                !props.description && 'pt-[8px] pb-[16px]',
                'tablet:self-center tablet:pt-0 tablet:pb-0 tablet:pr-[16px]'
              )}
            >
              {props.cta}
            </div>
          )}
          {props.close && (
            <div className={twMerge('p-1 hidden tablet:flex')}>
              <Button.Shape
                layout={'icon'}
                hierarchy={'tertiary'}
                icon={{ name: 'close', family: 'sharp', variant: 'solid' }}
                onClick={props.close}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default DefaultPageBanner
