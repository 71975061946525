import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import Logo from '@/components/logo'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { useOrg } from '@/hooks/queries/useOrg'

import { NavItem } from './nav-item'

const Sidebar = () => {
  const { t } = useTranslation()
  const { user, hasPolicy } = useAuth()
  const { org } = useOrg()
  const [_showMenuLabels, setShowMenuLabels] = useState<boolean>(false)
  useEffect(() => {
    const onBackgroundClick = () => {
      setShowMenuLabels(false)
    }
    document.addEventListener('click', onBackgroundClick)
    return () => {
      document.removeEventListener('click', onBackgroundClick)
    }
  }, [])

  const onToggleLabels = () => {
    setTimeout(() => {
      setShowMenuLabels(!_showMenuLabels)
    }, 100)
  }

  const renderLiAssets = () => {
    if (hasPolicy('CUSTODY.VIEW_ASSET_DETAIL')) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.ASSETS.INDEX}
            testId={'btn_side_menu_assets'}
            icon={'objects-column'}
            label={t('assets')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiOperations = () => {
    if (hasPolicy('CUSTODY.VIEW_OPERATION')) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.CASES.INDEX}
            testId={'btn_side_menu_operations'}
            icon={'folder'}
            label={t('cases')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiDefendants = () => {
    if (hasPolicy('CUSTODY.MANAGE_DEFENDANT')) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.SUBJECT.INDEX}
            testId={'btn_side_menu_defendants'}
            icon={'user-tie-hair'}
            label={t('subjects')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiTeamManagement = () => {
    if (hasPolicy('TEAM.MANAGE_ORG')) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.TEAM_MANAGEMENT}
            testId={'btn_side_menu_team_management'}
            icon={'users'}
            label={t('team')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiEscrow = () => {
    if (
      org?.escrow_enabled &&
      hasPolicy(['ESCROW.APPROVER', 'ESCROW.INITIATOR'], false)
    ) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.ESCROW.INDEX}
            testId={'btn_side_menu_escrow'}
            icon={'hands-holding-dollar'}
            label={t('escrow')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiReports = () => {
    if (hasPolicy('REPORTING.GENERATE_REPORT')) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.REPORTS}
            testId={'btn_side_menu_reports'}
            icon={'file-chart-pie'}
            label={t('reports')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiAdminEscrow = () => {
    if (['super'].includes(user?.role ?? '')) {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.ADMIN.ESCROW.INDEX}
            testId={'btn_side_menu_admin_escrow'}
            icon={'hands-holding-dollar'}
            label={t('escrow')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiAdminOrganisation = () => {
    if (user?.role === 'super') {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.ADMIN.ORGANIZATION.INDEX}
            testId={'btn_side_menu_admin_organisation'}
            icon={'buildings'}
            label={t('organization')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiAdminCustodian = () => {
    if (user?.role === 'super') {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.ADMIN.CUSTODIAN.INDEX}
            testId={'btn_side_menu_admin_custodian'}
            icon={'shield-check'}
            label={t('custodian')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  const renderLiAdminTeamManagement = () => {
    if (user?.role === 'super') {
      return (
        <li className={'w-full overflow-hidden'}>
          <NavItem
            to={ROUTES.ADMIN.TEAM_MANAGEMENT.ORG.replace(
              ':org_id',
              user?.organization_id as string
            )}
            testId={'btn_side_menu_admin_team_management'}
            icon={'users'}
            label={t('ar_team')}
            overrideLabelVisibility={_showMenuLabels}
            darkMode={user?.role === 'super'}
          />
        </li>
      )
    }
    return null
  }

  return (
    <div
      className={twMerge(
        'hidden flex-col relative',
        'tablet:flex tablet:min-w-[4.25rem] tablet:max-w-[4.25rem]',
        'items-center',
        'bg-white',
        user?.role === 'super' && 'bg-black',
        '2xl:min-w-[20vw] 2xl:max-w-none 2xl:px-6'
      )}
      data-testid="desktop-sidebar"
    >
      {_showMenuLabels && (
        <div
          className={
            'absolute top-0 left-0 w-[100vw] h-full bg-transparent z-[49]'
          }
          onWheel={onToggleLabels}
          onClick={onToggleLabels}
        />
      )}
      <div
        className={twMerge(
          'flex flex-1 flex-col gap-8',
          ' border-r border-r-gray-100',
          'pt-4 pb-4 w-full',
          'absolute top-0 left-0 h-full z-50',
          'transition-[width,background-color] duration-100 ease-in',
          '2xl:max-w-[250px] 2xl:border-r-0 2xl:relative',
          _showMenuLabels && 'w-[12.75rem] max-w-[12.75rem]',
          _showMenuLabels && 'bg-[#F5F5F6]',
          _showMenuLabels && user?.role === 'super' && 'bg-black border-r-black'
        )}
      >
        <div className="flex items-center justify-center w-[4.25rem]">
          <Link to="/" className={'hidden 2xl:flex'}>
            <Logo.Icon
              background={'RED'}
              color={'WHITE'}
              shape={'ROUND'}
              sizeInRem={2.75}
            />
          </Link>
          <Avatar
            type={'icon'}
            size={'medium'}
            name={_showMenuLabels ? 'close' : 'bars'}
            family={'sharp'}
            iconVariant={'solid'}
            shape={'round'}
            onClick={onToggleLabels}
            className={'flex 2xl:hidden bg-red-500'}
          />
        </div>
        <nav className="flex flex-1 flex-col px-3">
          <ul
            role="list"
            className="flex flex-1 flex-col gap-8 overflow-hidden"
          >
            <li>
              <ul
                role="list"
                className={twMerge('flex flex-1 flex-col gap-2 items-center')}
              >
                {user?.role === 'super' ? (
                  <>
                    {renderLiAdminOrganisation()}
                    {renderLiAdminTeamManagement()}
                    {renderLiAdminCustodian()}
                    {renderLiAdminEscrow()}
                    {renderLiReports()}
                  </>
                ) : (
                  <>
                    {renderLiAssets()}
                    {renderLiOperations()}
                    {renderLiDefendants()}
                    {renderLiTeamManagement()}
                    {renderLiEscrow()}
                    {renderLiReports()}
                  </>
                )}
              </ul>
            </li>
            <li className="mt-auto">
              <ul
                role="list"
                className={twMerge('flex flex-1 flex-col gap-4 items-center')}
              >
                <li className={'w-full overflow-hidden'}>
                  <NavItem
                    to={ROUTES.HELP}
                    testId={'btn_side_menu_help'}
                    icon={'circle-question'}
                    label={t('help')}
                    overrideLabelVisibility={_showMenuLabels}
                    darkMode={user?.role === 'super'}
                  />
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
