import { ReactElement } from 'react'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'

import { RowTypes } from '../../components/list.dropdown'
import { Props as ViewProps } from '../../components/view.dropdown'

interface Props<T> extends ViewProps<T> {
  items: ReactElement<RowTypes<T>>[]
}
const BasicListView = <T,>(props: Props<T>) => {
  return <DropdownView {...props} id={'BASIC_LIST'} items={props.items} />
}
export default BasicListView
