import { t } from 'i18next'
import { ChangeEvent, useEffect, useState } from 'react'

import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { useAuth } from '@/contexts/auth'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
import { useCustodians } from '@/hooks/queries/useCustodians'

export const AssetCustomAddress = () => {
  const { user } = useAuth()
  const { custodians } = useCustodians(user?.organization_id ?? '')
  const [_suggestableNetworks, setSuggestableNetworks] = useState<string[]>([])
  const [_suggestableTokens, setSuggestableTokens] = useState<string[]>([])

  useEffect(() => {
    // Run through and use these to populate our suggestable values
    const _snetworks = []
    const _stokens = []
    for (const custodian of custodians ?? []) {
      for (const asset of custodian.supported_assets ?? []) {
        if (asset.network) _snetworks.push(asset.network)
        if (asset.ticker) _stokens.push(asset.ticker)
      }
    }

    // Push into the state and make sure we don't have duplicates
    setSuggestableNetworks(
      Array.from(new Set([..._snetworks])).sort((a, b) => a.localeCompare(b))
    )
    setSuggestableTokens(
      Array.from(new Set([..._stokens])).sort((a, b) => a.localeCompare(b))
    )
  }, [custodians])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
  }

  return (
    <div className={'flex flex-col gap-6 self-stretch'}>
      <Input.Suggest
        name={'token_network'}
        testId={'token_network'}
        label={t('network')}
        hint={t('search_for_a_network')}
        placeholder={'Select a token network'}
        items={_suggestableNetworks.map((n, i) => (
          <ListItem
            key={`network_${i}`}
            value={n}
            title={capitalizeFirstLetter(n)}
            className={'pl-2 pr-2'}
          />
        ))}
      />

      <Input.Suggest
        name={'token_ticker'}
        testId={'token_ticker'}
        label={t('token')}
        hint={t('search_for_a_coin_or_token')}
        placeholder={'Select a token'}
        items={_suggestableTokens.map((n, i) => (
          <ListItem
            key={`token_${i}`}
            value={n}
            title={n.toUpperCase()}
            className={'pl-2 pr-2'}
          />
        ))}
      />

      <Input.Number
        name={'token_balance'}
        testId={'token_balance'}
        label={t('balance')}
        hint={t('enter_balance_of_tokens')}
        placeholder={'0.0'}
        onChange={onChange}
        min={0}
      />
    </div>
  )
}
