import { t } from 'i18next'
import React from 'react'

import { AssetTotalCost } from '@/components/asset/detail/components/asset-total-cost.tsx'
import { Avatar } from '@/components/avatar'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { Typography } from '@/components/typography'
import { Currency, getCurrencyIcon } from '@/constants/currencies.ts'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon.ts'
import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter.ts'
import {
  formatAssetPrice,
  formatNumber,
  formatValue,
} from '@/helpers/formatValue.ts'
import { AssetCategory, IAsset } from '@/types/asset.ts'

interface Props {
  asset: IAsset
}

const AssetAmountAndIconDigital: React.FC<Props> = ({ asset }) => {
  return (
    <div className={'flex items-start flex-col flex-1 gap-4'}>
      <Paragraph
        title={formatAssetPrice(asset.price)}
        spacerOverrides={{
          title: [],
        }}
      />
      {asset.price?.is_untradeable === true && (
        <Input.Component.Hint
          style={'message'}
          label={t('asset_value_currently_unavailable')}
        />
      )}
      <div className={'flex items-center gap-4 self-stretch'}>
        {asset.category === AssetCategory.DIGITAL && (
          <Avatar
            type={'user'}
            size={'medium'}
            shape={'round'}
            outline={false}
            image={getCryptoImageUrl(asset)}
            fallbackImage={getCryptoImageUrl()}
          />
        )}
        {asset.category === AssetCategory.FIAT_CASH && (
          <Icon
            name={getCurrencyIcon(asset.currency ?? Currency.USD)}
            size={'xl'}
            variant="solid"
            className={'min-w-0'}
          />
        )}

        <div
          className={
            'flex flex-1 flex-col justify-center items-start self-stretch'
          }
        >
          {asset.category === AssetCategory.FIAT_CASH && (
            <div>
              <Paragraph
                subTitle={formatValue(asset.amount ?? 0, asset.currency)}
                spacerOverrides={{ subTitle: [], description: [] }}
              />
              <AssetTotalCost asset={asset} />
            </div>
          )}

          {asset.category === AssetCategory.DIGITAL && (
            <>
              <Typography variant="label-large">
                {formatNumber(asset.amount ?? 0, {
                  notation: 'standard',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 18,
                })}{' '}
                {asset.ticker.toUpperCase().replace('$', '')}
              </Typography>
              <Typography
                variant={'paragraph-medium'}
                className={'mt-[-0.25rem]'}
              >
                {capitalizeFirstLetter(asset.network || '')}
              </Typography>
            </>
          )}
          {(asset.category === AssetCategory.REAL_ESTATE ||
            asset.category === AssetCategory.PERSONAL_PROPERTY ||
            asset.category === AssetCategory.VEHICLE) && (
            <AssetTotalCost asset={asset} />
          )}
        </div>
      </div>
    </div>
  )
}

export default AssetAmountAndIconDigital
