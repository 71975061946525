import { t } from 'i18next'

import Input from '@/components/input'
import { AssetCategory } from '@/types/asset'

interface Props {
  category: AssetCategory
  value?: string
}

const labels: {
  [key in
    | AssetCategory.VEHICLE
    | AssetCategory.REAL_ESTATE
    | AssetCategory.PERSONAL_PROPERTY]: { label: string; placeholder: string }
} = {
  [AssetCategory.REAL_ESTATE]: {
    label: t('plot_number'),
    placeholder: t('enter_plot_number'),
  },
  [AssetCategory.VEHICLE]: {
    label: t('vin_number'),
    placeholder: t('enter_vin'),
  },
  [AssetCategory.PERSONAL_PROPERTY]: {
    label: t('serial_number'),
    placeholder: t('enter_serial_number'),
  },
}

export const AssetSerialNumberSelector = (props: Props) => {
  const tangibleCategory = props.category as
    | AssetCategory.VEHICLE
    | AssetCategory.REAL_ESTATE
    | AssetCategory.PERSONAL_PROPERTY
  const config = tangibleCategory ? labels[tangibleCategory] : undefined
  return (
    <Input.Text
      name={'asset_identifier'}
      testId={'asset_identifier'}
      label={config?.label ?? ''}
      placeholder={config?.placeholder ?? ''}
      value={props.value}
    />
  )
}
