import { t } from 'i18next'
import { useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Switch } from '@/components/switch'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}
export const NewTeamSuperUserDrawer = (props: Props) => {
  const { revalidate } = useRevalidator()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = () => {
    revalidate()
    ToastManager.showToast({
      type: 'success',
      text: `User created successfully`,
    })

    // Allow some time for the revalidation to occur - it'll be cleaner
    // for the user
    setTimeout(() => {
      props.onClose()
    }, 500)
  }

  return (
    <Drawer
      title={t('add_user')}
      subTitle={t('identification')}
      description={t('add_user_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        buttons={[
          <Button.Basic
            key={`team_add_user_btn`}
            label={t('add_user')}
            state={_submitting ? 'loading' : 'default'}
            withAttributes={{
              name: 'intent',
              type: 'submit',
              value: INTENTS.CREATE_SUPER_ADMIN_USER,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <div className={'flex flex-col gap-8'}>
          <div className={'flex flex-col gap-6'}>
            <Input.Text label={t('full_name')} id="name" name="name" />
            <Input.Email
              label={t('email')}
              id="email"
              name="email"
              dynamicInput
            />
            <Input.Text id="phone" name="phone" label={t('mobile_number')} />
          </div>

          <div className={'flex flex-col gap-6 max-w-[23.4375rem]'}>
            <ListItem
              title={t('2fa')}
              description={t('two_factor_authentication_description')}
              style={'bold'}
              trailing={
                <Switch
                  name={'two_factor_enabled'}
                  enabled={false}
                  size={'large'}
                />
              }
            />
          </div>
        </div>
      </Form>
    </Drawer>
  )
}
