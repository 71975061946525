import { twMerge } from 'tailwind-merge'

import { Button } from '../button'

export interface Props {
  backLabel?: string
  url?: string
}
export const PageBack = (props: Props) => {
  return (
    <div
      className={twMerge(
        'flex px-4 flex-col items-start self-stretch',
        'tablet:pt-4'
      )}
    >
      <Button
        variant={'back'}
        label={props.backLabel}
        url={props.url}
        testId="back-button"
      />
    </div>
  )
}
