import { useQuery } from '@tanstack/react-query'

import { getAssetById } from '@/api'

export const key = 'asset'
export const useAsset = (id: string) => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key, id],
    queryFn: () => getAssetById(id),
  })

  return { asset: data?.data, isLoading, refetchAsset: refetch }
}
