import { useEffect, useState } from 'react'

import { getIamUser } from '@/api'
import { STORAGE_KEYS } from '@/helpers/localStorage.ts'
import { IWorkspace } from '@/types/workspace'

import { useAuth } from '../auth'
import { WorkspaceContext } from './context'
import { WorkspaceManager } from './manager'

type Props = {
  children: JSX.Element[] | JSX.Element
}

export const WorkspaceProvider: React.FC<Props> = ({ children }) => {
  const { user } = useAuth()
  const [_workspaces, _setWorkspaces] = useState<IWorkspace[]>([])
  const [_selectedWorkspace, _setSelectedWorkspace] =
    useState<IWorkspace | null>()
  const [_lastUsedWorkspace, _setLastUsedWorkspace] =
    useState<IWorkspace | null>(null)
  const LAST_USED_KEY = `${STORAGE_KEYS.LAST_USED_WORKSPACE_ID}_${user?.organization_id ?? ''}`

  useEffect(() => {
    // Attach the manager functions
    WorkspaceManager.setUpdateWorkspacesFunction(updateWorkspaces)
    WorkspaceManager.setSelectWorkspaceFunction(setWorkspace)
  }, [user])

  useEffect(() => {
    if (user) {
      const asyncFunc = async () => {
        // Update the workspaces
        const workspaces = await updateWorkspaces()

        // Attempt to find the last used workspace
        const _lastWorkspace =
          workspaces.find(
            (w) => w.id === localStorage.getItem(LAST_USED_KEY)
          ) ?? null

        // Attempt to set the last used workspace
        setLastUsedWorkspace(_lastWorkspace)
        // setSelectedWorkspace(_lastWorkspace)
      }
      asyncFunc()
    }
  }, [user])

  useEffect(() => {
    WorkspaceManager.lastUsedWorkspace = _lastUsedWorkspace
  }, [_lastUsedWorkspace])

  const setWorkspaces = (workspaces: IWorkspace[]) => {
    _setWorkspaces(workspaces)
    WorkspaceManager.workspaces = _workspaces
  }

  const setSelectedWorkspace = (workspace: IWorkspace | null) => {
    _setSelectedWorkspace(workspace)
    WorkspaceManager.selectedWorkspace = workspace
  }

  const setLastUsedWorkspace = (workspace: IWorkspace | null) => {
    _setLastUsedWorkspace(workspace)
    WorkspaceManager.lastUsedWorkspace = _lastUsedWorkspace
  }

  const updateWorkspaces = async (): Promise<IWorkspace[]> => {
    if (user && user.id) {
      // Get workspace details - we should be able to get everything we need
      // from the iam user object which also contains the role. Getting directly
      // from the workspaces (getWorkspace()) doesn't include roles
      const res = await getIamUser(user.id)

      // Set the workspaces
      setWorkspaces(res.data?.workspaces ?? [])

      // Attempt to set the currently chosen workspace if we have one.
      setWorkspace(
        localStorage.getItem(STORAGE_KEYS.WORKSPACE_ID),
        res.data?.workspaces ?? []
      )

      return res.data?.workspaces ?? []
    }
    return []
  }

  /*
    Function accepts either:
    - a string (workspace id)
    - an IWorkspace object
    - null (in order to clear the selection)

    If the workspace isn't found that we want to set it to then we'll
    instead set to null
  */
  const setWorkspace = (
    workspaceVal: IWorkspace | string | null,
    workspaces?: IWorkspace[]
  ) => {
    const availableWorkspaces = workspaces ?? _workspaces

    // If the id is null then we want to clear the workspace selection
    if (!workspaceVal) {
      localStorage.removeItem(STORAGE_KEYS.WORKSPACE_ID)
      setSelectedWorkspace(null)
      return
    }

    // Grab the workspace
    const newWorkspace = availableWorkspaces.find(
      (w) =>
        w.id ===
        (typeof workspaceVal === 'string' ? workspaceVal : workspaceVal.id)
    )

    // Set the new workspace Id
    if (newWorkspace) {
      localStorage.setItem(STORAGE_KEYS.WORKSPACE_ID, newWorkspace.id)
    } else {
      localStorage.removeItem(STORAGE_KEYS.WORKSPACE_ID)
    }

    // Set the values in the state
    setSelectedWorkspace(newWorkspace ?? null)

    // If the new workspace isn't null then lets update the last used workspace
    if (newWorkspace) {
      localStorage.setItem(LAST_USED_KEY, newWorkspace.id)
      setLastUsedWorkspace(newWorkspace)
    }
  }

  return (
    <WorkspaceContext.Provider
      value={{
        workspaces: _workspaces,
        selectedWorkspace: _selectedWorkspace ?? null,
        lastUsedWorkspace: _lastUsedWorkspace,
        setWorkspace: setWorkspace,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}
