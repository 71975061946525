import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import { AssetCaseSelector } from '@/components/asset/asset-case-selector.tsx'
import { AssetCurrencySelector } from '@/components/asset/asset-currency-selector'
import { AssetCustomAddress } from '@/components/asset/asset-custom-address.tsx'
import { AssetDefendants } from '@/components/asset/asset-defendants'
import { AssetDigitalWalletSourceSelector } from '@/components/asset/asset-digital-wallet-source-selector.tsx'
import { AssetExistingChainSelector } from '@/components/asset/asset-existing-chain-selector.tsx'
import { AssetGenerateAddress } from '@/components/asset/asset-generate-address.tsx'
import { AssetGroupedCategorySelector } from '@/components/asset/asset-grouped-category-selector.tsx'
import { AssetStatusSelector } from '@/components/asset/asset-status-selector'
import {
  AssetTangibleTypeSelector,
  TangibleSelectionType,
} from '@/components/asset/asset-tangible-type-selector.tsx'
import Button from '@/components/button'
import { AssetFormDetailsGroup } from '@/components/drawer/variants/new-asset/asset-form-details-group.tsx'
import { AssetFormPhotos } from '@/components/drawer/variants/new-asset/asset-form-photos.tsx'
import File from '@/components/file'
import { allAllowedFileTypes } from '@/components/file/upload'
import { Form } from '@/components/form'
import { Group } from '@/components/group'
import Input from '@/components/input'
import { Currency } from '@/constants/currencies.ts'
import { BannerManager } from '@/contexts/banner'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { ToastManager } from '@/contexts/toast'
import { AssetRules, RuleMode } from '@/helpers/assetRules.ts'
import { useOperations } from '@/hooks/queries/useOperations'
import { useOrg } from '@/hooks/queries/useOrg.ts'
import { ActionResponse } from '@/types/actions'
import {
  AssetCategory,
  AssetGroupedCategory,
  AssetTangibleType,
  DigitalWalletSource,
  IAssetStatusName,
} from '@/types/asset'
import { IAddressAsset } from '@/types/evm.ts'
import { IOperation } from '@/types/operations.ts'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  preChosenOperation?: IOperation
  onUpdated?: (id: string) => void
}

export const NewAssetDrawer = (props: Props) => {
  const navigate = useNavigate()
  const { operations } = useOperations()
  const activeCases = (operations ?? []).filter((it) => it.status === 'ACTIVE')
  const { org } = useOrg()
  const [_detectAddress, setDetectAddress] = useState<boolean>(false)
  const [_asset_defendantId, setAssetDefendantId] = useState<string | null>(
    null
  )
  const [_groupedCategory, setGroupedCategory] = useState<
    AssetGroupedCategory | undefined
  >()
  const [_digitalWalletSource, setDigitalWalletSource] = useState<
    DigitalWalletSource | undefined
  >()

  const [_assetCategory, setAssetCategory] = useState<AssetCategory>()
  const [_assetTangibleType, setAssetTangibleType] =
    useState<AssetTangibleType>()

  const [_assetFiatCurrency, setAssetFiatCurrency] = useState<
    Currency | undefined
  >(undefined)
  const [_digitalExistingSelectedToken, setDigitalExistingSelectedToken] =
    useState<IAddressAsset | undefined>(undefined)
  const [_digitalGeneratedSelectedToken, setDigitalGeneratedSelectedToken] =
    useState<boolean>(false)

  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_status, setStatus] = useState<IAssetStatusName | null>(null)
  const [_statusAt, setStatusAt] = useState(moment().format('YYYY-MM-DD'))

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    // Check if we've received an onUpdated callback - if we have
    // don't navigate away
    if (props.onUpdated) {
      props.onUpdated(actionRes.data ?? '')
    } else {
      const goToUrl = `/assets/${actionRes.data}`
      navigate(goToUrl)
    }

    // Show the success toast
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })

    // Close the drawer
    props.onClose()
  }

  useEffect(() => {
    setDigitalExistingSelectedToken(undefined)
    setDigitalGeneratedSelectedToken(false)
    setAssetFiatCurrency(undefined)
  }, [_groupedCategory, _digitalWalletSource])

  const onGroupedCategoryChanged = (groupedCategory: AssetGroupedCategory) => {
    setGroupedCategory(groupedCategory)
    setAssetTangibleType(undefined)
    switch (groupedCategory) {
      case AssetGroupedCategory.DIGITAL:
        setAssetCategory(AssetCategory.DIGITAL)
        break
      default:
        setAssetCategory(undefined)
        break
    }
    FormErrorsManager.clearErrors()
  }

  const onDigitalWalletSourceChanged = (ws: DigitalWalletSource) => {
    setDigitalWalletSource(ws)
    FormErrorsManager.clearErrors()
  }

  const onTangibleTypeChanged = (selection: TangibleSelectionType) => {
    let category, type
    if (selection) {
      category = selection[0]
      type = selection[1]
    }
    setAssetCategory(category)
    setAssetTangibleType(type)
    FormErrorsManager.clearErrors()
  }

  const onFiatCurrencySelectionChanged = (currency: Currency) => {
    setAssetFiatCurrency(currency)
  }

  useEffect(() => {
    if (_asset_defendantId) {
      FormErrorsManager.clearErrors()
    }
  }, [_asset_defendantId])

  const isDigital = _groupedCategory === AssetGroupedCategory.DIGITAL
  const isDigitalExisting =
    isDigital && _digitalWalletSource === DigitalWalletSource.EXISTING
  const isDigitalGenerated =
    isDigital && _digitalWalletSource === DigitalWalletSource.GENERATED
  const isDigitalCustomTicker =
    isDigital && _digitalWalletSource === DigitalWalletSource.CUSTOM_TRACKER
  const isTangible = _groupedCategory === AssetGroupedCategory.TANGIBLE
  const isTangibleWithType = isTangible && _assetCategory && _assetTangibleType
  const isFiatCash = isTangible && _assetCategory == AssetCategory.FIAT_CASH

  const assetRules = new AssetRules(
    RuleMode.EDIT,
    _assetCategory,
    _assetTangibleType,
    _assetFiatCurrency
  )

  const shouldShowPhotos = assetRules.canAddPhotos()
  //////////////////////// group (type) ////////////////////////////////
  const isReadyForStatusAndCase =
    isFiatCash ||
    isTangibleWithType ||
    (isDigitalExisting && _digitalExistingSelectedToken) ||
    (isDigitalGenerated && _digitalGeneratedSelectedToken) ||
    isDigitalCustomTicker

  //////////////////////// group (status) ////////////////////////////////
  const group4ShowGroup = isReadyForStatusAndCase
  //////////////////////// group (case)  /////////////////////////////////
  const group5ShowGroup = isReadyForStatusAndCase

  function getSubmitButtonState() {
    const isCreatingAsset = _submitting === INTENTS.CREATE_ASSET
    if (isCreatingAsset) {
      return 'loading'
    } else if (isReadyForStatusAndCase) {
      return 'default'
    } else {
      return 'disabled'
    }
  }

  useEffect(() => {
    if (_status !== 'SEIZED' && _status !== 'ADOPTED') {
      setStatusAt(moment().format('YYYY-MM-DD'))
    }
  }, [_status])

  return (
    <Drawer
      title={t('new_asset_drawer_title')}
      description={t('new_asset_drawer_subtitle')}
      visible={props.visible}
      onClose={props.onClose}
      className={'gap-[48px]'}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'asset-form'}
        buttons={[
          <Button.Basic
            key={'btn_create_asset'}
            testId={'btn_create_asset'}
            hierarchy={'primary'}
            width={'intrinsic'}
            label={t('create_asset')}
            size={'medium'}
            state={getSubmitButtonState()}
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_ASSET,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        containerClassName={'gap-[48px]'}
      >
        <input
          type={'hidden'}
          name={'asset_grouped_category'}
          value={_groupedCategory}
        />
        <input
          type={'hidden'}
          name={'asset_digital_wallet_source'}
          value={_digitalWalletSource}
        />
        <input
          type={'hidden'}
          name={'asset_org_currency'}
          value={org?.preferred_currency}
        />
        <input
          type={'hidden'}
          name={'asset_currency'}
          value={_assetFiatCurrency}
        />
        <Group label={t('asset_type')} className="pt-[32px]">
          <AssetGroupedCategorySelector
            onGroupedCategoryChanged={onGroupedCategoryChanged}
          />

          {isDigital && (
            <AssetDigitalWalletSourceSelector
              onWalletSourceChanged={onDigitalWalletSourceChanged}
            />
          )}

          {isTangible && (
            <AssetTangibleTypeSelector
              onSelectionChanged={onTangibleTypeChanged}
            />
          )}

          {isFiatCash && (
            <AssetCurrencySelector
              onSelectionChanged={onFiatCurrencySelectionChanged}
              savedSelectionIdentifier={'new-asset-fiat-currency'}
            />
          )}
        </Group>

        {shouldShowPhotos && <AssetFormPhotos />}

        <AssetFormDetailsGroup
          assetCategory={_assetCategory}
          assetTangibleType={_assetTangibleType}
          assetFiatCurrency={_assetFiatCurrency}
        />

        {isDigital && _digitalWalletSource && (
          <Group
            label={
              _digitalWalletSource == DigitalWalletSource.GENERATED
                ? t('select_token')
                : t('asset_details')
            }
            className="pt-[32px]"
          >
            {_digitalWalletSource == DigitalWalletSource.EXISTING && (
              <AssetExistingChainSelector
                isDetected={_detectAddress}
                onDetect={setDetectAddress}
                onTokenSelected={setDigitalExistingSelectedToken}
              />
            )}
            {_digitalWalletSource == DigitalWalletSource.GENERATED && (
              <AssetGenerateAddress
                onSelectionChanged={setDigitalGeneratedSelectedToken}
              />
            )}
            {_digitalWalletSource == DigitalWalletSource.CUSTOM_TRACKER && (
              <AssetCustomAddress />
            )}
          </Group>
        )}

        {group4ShowGroup && (
          <Group
            label={
              _digitalWalletSource == DigitalWalletSource.GENERATED
                ? t('asset_details')
                : t('asset_details')
            }
          >
            <div className="flex gap-6">
              <AssetStatusSelector
                onChangeStatus={(value) => {
                  setStatus(value as IAssetStatusName)
                }}
              />
              <Input.Date
                max={moment().format('YYYY-MM-DD')}
                type="date"
                id="date"
                name={'asset_status_at'}
                value={_statusAt}
                onChange={(e) => setStatusAt(e.target.value)}
                placeholder={t('eg_ledger_usb')}
                className="max-w-40"
                disabled={_status !== 'SEIZED' && _status !== 'ADOPTED'}
              />
            </div>

            <Input.Text
              name={'external_ref'}
              testId={'asset_name'}
              label={t('identifier')}
              hint={t('create_a_memorable_name')}
              placeholder={t('eg_ledger_usb')}
            />

            <Input.TextArea
              name={'asset_notes'}
              testId={'asset_description'}
              label={t('description')}
              placeholder={t('identification_notes')}
              className={'max-w-none'}
            />
          </Group>
        )}

        {group5ShowGroup && (
          <Group label={t('add_to')}>
            <AssetDefendants
              name={'asset_defendant'}
              label={t('subject')}
              defaultMode={'ADD_EXISTING'}
              onSelectedIdChange={(id) => {
                setAssetDefendantId(id)
              }}
            />

            <AssetCaseSelector
              operations={activeCases ?? []}
              preSelected={props.preChosenOperation}
            />
          </Group>
        )}

        {group5ShowGroup && (
          <File.Upload
            id="asset_files"
            name="asset_files"
            testId={'asset_files'}
            innerText={t('add_your_files_here')}
            allowedFileTypes={allAllowedFileTypes}
            maxFilesAllowed={100}
            showFileList={true}
          />
        )}
      </Form>
    </Drawer>
  )
}
