import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { getEscrowUser, getUser } from '@/api/auth'
import { AuthManager } from '@/contexts/auth'
import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export const loader: LoaderFunction = async () => {
  try {
    const res = await Promise.allSettled([getUser(), getEscrowUser()])

    // Check for errors
    if (res?.[0]?.status === 'rejected' || !res?.[0]?.value?.data) {
      return ErrorHandler(404, ExtractErrorFrom(res[1]) ?? t('user_not_found'))
    } else if (res?.[1]?.status === 'rejected' || !res?.[1]?.value?.data) {
      return ErrorHandler(500, ExtractErrorFrom(res[1]) ?? '')
    }

    // Set auth user
    await AuthManager.setUser({
      ...res?.[0]?.value?.data,
      escrow_ids: res?.[1]?.value?.data?.escrow_ids ?? [],
    })

    return { user: res?.[0]?.value?.data }
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error) ?? t('something_went_wrong')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
