import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Typography } from '@/components/typography'

import { Icon } from '../../icon'

export interface Props {
  label?: string
  className?: string
  url?: string
  onClick?: () => void
  testId?: string
}

export const ButtonBack = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onClick = () => {
    if (props.onClick) {
      // This will override the navigation
      props.onClick()
    } else {
      props.url ? navigate(props.url) : navigate(-1)
    }
  }
  return (
    <button
      type={'button'}
      {...props}
      className={twMerge(
        'group flex flex-row items-center justify-center gap-x-2 font-extrabold whitespace-nowrap h-11',
        props.className
      )}
      onClick={onClick}
      data-testid={props.testId}
    >
      <span
        className={twMerge(
          'flex justify-center items-center min-w-[2rem] min-h-[2rem] max-w-[2rem] max-h-[2rem]',
          'transition-[transform]',
          'group-hover:translate-x-[-0.25rem]'
        )}
      >
        <Icon
          name={'arrow-left'}
          size={'medium'}
          variant={'solid'}
          family={'sharp'}
        />
      </span>
      <Typography variant="label-medium">{props.label ?? t('back')}</Typography>
    </button>
  )
}
