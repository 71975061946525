import React, { useEffect, useState } from 'react'
import { matchPath, Outlet, useNavigation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { ROUTES } from '@/constants/routes'
import useDebounce from '@/hooks/useDebounce'
import AssetSkeleton from '@/screens/assets/detail/skeleton'
import AssetsSkeleton from '@/screens/assets/index/skeleton'
import CaseSkeleton from '@/screens/cases/detail/skeleton'
import CasesSkeleton from '@/screens/cases/index/skeleton'
import HealthDetailSkeleton from '@/screens/health/details/skeleton'
import HealthSkeleton from '@/screens/health/index/skeleton'

let timeout: NodeJS.Timeout

const Screen: React.FC = (): JSX.Element => {
  const { state, location } = useNavigation()
  const [loading, setLoading] = useState(true)
  const [opacityAnim, setOpacityAnim] = useState([true, false])
  const debouncedLocation = useDebounce({
    value: location?.pathname ?? '',
    delay: 300,
    enabled: true,
  })

  const handleStateChange = async () => {
    clearTimeout(timeout)

    if (state === 'loading') {
      setOpacityAnim([true, false])
      await new Promise((resolve) => {
        timeout = setTimeout(resolve, 300)
      })
      setLoading(true)
    } else {
      setOpacityAnim([false, true])
      await new Promise((resolve) => {
        timeout = setTimeout(resolve, 300)
      })
      setLoading(false)
      document.querySelector('main')?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    handleStateChange()
  }, [state])

  const getSkeleton = () => {
    if (matchPath({ path: ROUTES.CASES.INDEX }, debouncedLocation)) {
      return <CasesSkeleton />
    } else if (matchPath({ path: ROUTES.CASES.DETAIL }, debouncedLocation)) {
      return <CaseSkeleton />
    } else if (matchPath({ path: ROUTES.ASSETS.INDEX }, debouncedLocation)) {
      return <AssetsSkeleton />
    } else if (matchPath({ path: ROUTES.ASSETS.DETAIL }, debouncedLocation)) {
      return <AssetSkeleton />
    } else if (matchPath({ path: ROUTES.HEALTH.INDEX }, debouncedLocation)) {
      return <HealthSkeleton />
    } else if (matchPath({ path: ROUTES.HEALTH.DETAIL }, debouncedLocation)) {
      return <HealthDetailSkeleton />
    }
    return <AssetsSkeleton />
  }

  if (loading) {
    return (
      <div
        className={twMerge(
          'flex flex-col p-8 pt-4 desktop:px-8 desktop:py-8 transition-opacity duration-300 ease-in-out',
          opacityAnim[0] ? 'opacity-100' : 'opacity-0'
        )}
      >
        {getSkeleton()}
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        'transition-opacity duration-300 ease-in-out',
        opacityAnim[1] ? 'opacity-100' : 'opacity-0'
      )}
      data-testid="main-container"
    >
      <Outlet />
    </div>
  )
}

export default Screen
