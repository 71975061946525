import { CardBadge } from './components/badge.card'
import { CardOverview } from './variants/overview.card'
import { CardPageState } from './variants/page-state.card'

export default {
  // Variants
  Overview: CardOverview,
  PageState: CardPageState,

  // Components
  Badge: CardBadge,
}
