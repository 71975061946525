import { twMerge } from 'tailwind-merge'

import { Icon, IconProps as IconCompProps } from '@/components/icon'
import { Typography } from '@/components/typography'

export type Size = 'medium' | 'small'
export type Type = 'button' | 'icon' | 'text'

interface Base {
  type: Type
  active: boolean
  className?: string
  onClick: () => void
  testId?: string
}
interface ButtonProps extends Base {
  type: 'button'
  text: string
  icon?: IconCompProps
}
interface IconProps extends Base {
  type: 'icon'
  icon: IconCompProps
}
interface TextProps extends Base {
  type: 'text'
  text: string
}

export type Props = ButtonProps | IconProps | TextProps
export const ButtonTab = (props: Props) => {
  const iconStyles = twMerge(
    'flex items-center justify-center gap-x-2 font-extrabold overflow-hidden',
    'transition-[border-color] duration-[300ms]',
    'border-solid border-[1px] border-transparent rounded-md',
    'h-11 w-11 tablet:h-9 tablet:w-9',
    props.active ? 'border-black scale-[0.0625rem]' : '',
    props.className
  )

  const buttonStyles = twMerge(
    'flex items-center justify-center gap-x-2 font-extrabold px-4 ',
    'transition-[border-color] duration-[300ms]',
    'border-solid border-[1px] border-transparent rounded-md',
    'h-9 tablet:h-11',
    props.active ? 'border-black scale-[0.0625rem]' : '',
    props.className
  )

  const textStyles = twMerge(
    'flex items-center justify-center gap-x-2 font-extrabold px-4 overflow-hidden',
    'transition-[border-color] duration-[300ms]',
    'border-solid border-b-[0.25rem] border-[#E1E1E2]',
    'h-11 tablet:h-9',
    props.active ? 'border-black scale-[0.0625rem]' : '',
    props.className
  )
  return (
    <button
      className={
        props.type === 'icon'
          ? iconStyles
          : props.type === 'text'
            ? textStyles
            : buttonStyles
      }
      data-testid={props.testId}
      aria-label={'text' in props ? props.text : props.icon.name}
      type={'button'}
      onClick={props.onClick}
    >
      {'icon' in props && props.icon && (
        <>
          <Icon
            {...props.icon}
            size={'small'}
            className={'hidden tablet:flex'}
          />
          <Icon
            {...props.icon}
            size={'medium'}
            className={'flex tablet:hidden'}
          />
        </>
      )}
      {'text' in props && props.text && (
        <>
          <Typography
            variant={'label-medium'}
            className={twMerge(
              'hidden tablet:flex',
              'whitespace-nowrap max-w-[9rem] text-ellipsis overflow-hidden'
            )}
          >
            {props.text}
          </Typography>
          <Typography
            variant={'label-medium'}
            className={twMerge(
              'flex tablet:hidden',
              'whitespace-nowrap max-w-[9rem] text-ellipsis overflow-hidden'
            )}
          >
            {props.text}
          </Typography>
        </>
      )}
    </button>
  )
}
