import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar } from '@/components/avatar'
import Chip from '@/components/chip'
import Table from '@/components/table'
import { Typography } from '@/components/typography'
import { IEscrow, IEscrowStatusName } from '@/types/escrows'

import styles from '../aggrid.module.css'

export interface Props {
  data: IEscrow[]
}

export const AdminEscrowsTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IEscrow>[]>(
    () => [
      {
        field: 'name',
        headerName: t('identifier'),
        cellRenderer: (props: CustomCellRendererProps<IEscrow>) => {
          return (
            <div className={'flex flex-row gap-[16px]'}>
              <Avatar
                type={'icon'}
                size={'medium'}
                shape={'square'}
                iconClassName={'text-black'}
                name={'hands-holding-dollar'}
                iconVariant={'solid'}
                family={'sharp'}
              />
              <div className={'flex flex-col gap-[4px] justify-center'}>
                <Typography variant={'label-small'}>{props.value}</Typography>
              </div>
            </div>
          )
        },
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'platform_id',
        headerName: t('ar_reference'),
        cellRenderer: Table.AgGrid.Cell,
        width: 190,
        minWidth: 190,
      },
      {
        field: 'created_at',
        headerName: t('created'),
        filter: 'agDateColumnFilter',
        cellRenderer: Table.AgGrid.Cell,
        cellRendererParams: {
          type: 'date-short',
        },
        width: 190,
        minWidth: 190,
        sort: 'desc',
      },
      {
        headerName: t('fees'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrow>) => {
          const feeStatus = cellProps.data?.fees?.at(-1)?.status?.at(-1)?.name
          if (!feeStatus) {
            return Table.AgGrid.Cell({
              ...cellProps,
              value: '',
              type: 'text',
            })
          } else {
            return (
              <Chip.Status
                size={'medium'}
                context={'ESCROW_FEE'}
                status={feeStatus}
              />
            )
          }
        },
        width: 190,
        minWidth: 190,
      },
      {
        headerName: t('escrow_status'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrow>) => {
          return (
            <Chip.Status
              size={'medium'}
              context={'ESCROW'}
              status={
                cellProps.data?.status?.at(-1)?.name ??
                ('PENDING' as IEscrowStatusName)
              }
            />
          )
        },
        width: 190,
        minWidth: 190,
      },
    ],
    [t]
  )

  return (
    <div
      className={`ag-theme-quartz ${styles.grid} ${styles.no_cell_selection} h-full`}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          rowHeight: 72,
        }}
        rowData={props.data}
        onRowClicked={(e: RowClickedEvent<IEscrow>) =>
          navigate(`/admin/escrow/${e.data?.id}`)
        }
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
