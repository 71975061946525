import {
  DropdownList,
  // Props as DropdownListProps,
} from './components/list.dropdown'
import AssetActionsController from './controllers/asset-actions.controller'
import BasicListController from './controllers/basic-list.controller'
import CaseActionsController from './controllers/case-actions.controller'
import CustodianActionsController from './controllers/custodian-actions.controller'
import DefendantActionsController from './controllers/defendant-actions.controller'
import FileActionsController from './controllers/file-actions.controller'
import NavigationController from './controllers/navigation.controller'
import ProfileController from './controllers/profile.controller'
import SuggestionController from './controllers/suggestion.controller'
import TeamActionsController from './controllers/team-actions.controller'

export default {
  List: DropdownList,
  Controllers: {
    Profile: ProfileController,
    AssetActions: AssetActionsController,
    CaseActions: CaseActionsController,
    FileActions: FileActionsController,
    DefendantActions: DefendantActionsController,
    TeamActions: TeamActionsController,
    BasicList: BasicListController,
    Navigation: NavigationController,
    Suggestion: SuggestionController,
    CustodianAction: CustodianActionsController,
  },
}
