import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CaseSkeleton: React.FC = (): JSX.Element => (
  <>
    <Skeleton className="h-4 mb-10" width="5%" />

    <Skeleton className="h-8 mb-6" width="200px" />
    <Skeleton className="h-5 mb-[35px]" width="210px" />

    <div className="flex flex-row items-center gap-5 mb-[25px]">
      <Skeleton className="h-12" width="180px" />
      <Skeleton className="h-12" width="180px" />
    </div>

    <div className="flex flex-row items-center gap-3 mb-[40px]">
      <Skeleton className="" width="190px" height="150px" />
      <Skeleton className="" width="190px" height="150px" />
      <Skeleton className="" width="190px" height="150px" />
      <Skeleton className="" width="190px" height="150px" />
    </div>

    <Skeleton className="h-8 mb-8" width="100px" />
    <Skeleton className="h-6" width="300px" />

    <Skeleton className="h-8 mt-10 mb-4" width="100px" />

    {/* table */}
    <div className="flex flex-row items-center justify-between mb-[40px]">
      <div className="flex flex-row items-center">
        <Skeleton className="h-9" width="85px" />
        <Skeleton className="h-9" width="85px" />
      </div>

      <div className="flex flex-row items-center gap-2">
        <Skeleton className="h-9" width="130px" />
        <Skeleton className="h-9" width="130px" />
      </div>
    </div>

    <div className="flex flex-row items-center justify-between p-4">
      <Skeleton className="h-4" width="85px" />
      <Skeleton className="h-4" width="45px" />
      <Skeleton className="h-4" width="65px" />
      <Skeleton className="h-4" width="55px" />
      <Skeleton className="h-4" width="55px" />
    </div>
    <Skeleton className="h-10" count={5} />
  </>
)
export default CaseSkeleton
