import { t } from 'i18next'

import { updateAsset } from '@/api'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { IsNot } from '@/helpers/test'
import { key as QueryCaseKey } from '@/hooks/queries/useOperations.ts'
import { ActionResponse } from '@/types/actions'
import { IAsset } from '@/types/asset'
import { IOperation } from '@/types/operations'

export const CaseAddExistingAssets = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  // Grab data from the form
  const case_original = formData.get('case_original')?.toString()
  const assets = formData.getAll('case_asset')

  // Validate the form
  if (IsNot(case_original, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: '',
        error: t('invalid_case_provided'),
      },
    ])
  }

  // Parse out the original case object as we'll use that as our base
  const existing_case: IOperation = JSON.parse(case_original)

  // Create an array of our selected Assets
  const selected_assets = assets.map((a) => JSON.parse(a.toString()) as IAsset)

  // Assign anything that isn't already assigned
  await Promise.allSettled(
    selected_assets
      // Then update those to add an association with this case
      .map((a) => {
        return updateAsset({
          ...a,
          operation_id: existing_case.id,
        })
      })
  )

  await queryClient.invalidateQueries({
    queryKey: [QueryCaseKey, existing_case.id],
  })

  return {
    error: false,
    message: t('case_assets_updated_successfully'),
    completion: 'COMPLETE',
    data: existing_case.id,
  }
}
