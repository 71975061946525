import RNCurrencyInput from 'react-currency-input-field'
import { twMerge } from 'tailwind-merge'

import { Typography } from '@/components/typography'

import { CurrencyInputProps } from '../types'

export const CurrencyInput = ({
  labelClassName,
  containerClassName,
  currencySymbol,
  currencyCode,
  bottomText,
  ...props
}: CurrencyInputProps) => {
  // const { user } = useAuth()
  // const placeholder= props.placeholder || `${user.currency}0.00`
  // const currencySymbol = props.currencySymbol || user.currency
  // const currencyCode = props.currencyCode || user.currency

  return (
    <div
      className={twMerge(
        'flex flex-col space-y-2',
        containerClassName,
        props.disabled ? 'cursor-disabled' : ''
      )}
    >
      {props.label && (
        <label htmlFor={props.id}>
          <Typography variant="label-small" className={twMerge(labelClassName)}>
            {props.label}
          </Typography>
        </label>
      )}
      <div className="relative flex items-center w-[60%]">
        <RNCurrencyInput
          className={twMerge(
            'block w-full rounded-[0.25rem] border-0 p-3 pr-[3.75rem] pl-[1.375rem] text-gray-900 ring-1 ring-inset ring-[#E1E1E2] placeholder:text-gray-400 focus:ring-inset focus:ring-black',
            props.className,
            props.disabled ? 'bg-gray-100 border-gray-100 text-gray-500' : ''
          )}
          onValueChange={(value: string | undefined) =>
            props.onChange ? props.onChange(value as string) : undefined
          }
          prefix={currencySymbol}
          {...props}
        />

        {currencyCode && (
          <Typography variant="label-medium" className="absolute right-3">
            {currencyCode}
          </Typography>
        )}
      </div>
      <Typography variant="paragraph-small">{bottomText}</Typography>
    </div>
  )
}
