import { t } from 'i18next'

import { createDefendant } from '@/api/broker/v2/defendants'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors/manager'
import { IsNot } from '@/helpers/test'
import { key as QueryDefendantsKey } from '@/hooks/queries/useDefendants'
import { ActionResponse } from '@/types/actions'

export const CreateDefendant = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const name = formData.get('name')?.toString()?.trim() ?? ''
  const email = formData.get('email')?.toString()
  const phone = formData.get('phone')?.toString()

  // Validate fields
  if (IsNot(name, REGEX.IS_DEFENDANT_NAME)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'name',
        error: t('subject_name_must_be_provided'),
      },
    ])
  } else if (email && IsNot(email, REGEX.IS_EMAIL)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'email',
        error: t('email_address_is_invalid'),
      },
    ])
  } else if (phone && IsNot(phone, REGEX.IS_PHONE_NUMBER)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'phone',
        error: t('phone_number_is_invalid'),
      },
    ])
  }

  // Create the new subject
  const res = await createDefendant({
    name,
    contact_details: {
      email,
      phone,
    },
  })

  // Check if the call failed
  if (res.error) {
    throw new Error(res.message)
  }

  // Invalidate the cache
  await queryClient.invalidateQueries({ queryKey: [QueryDefendantsKey] })

  return {
    error: false,
    message: `${t('new_subject')} ${t('added_successfully')}`,
    completion: 'COMPLETE',
    data: res.data,
  }
}
