import { ChangeEvent, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ButtonShape } from '@/components/button/variants/shape.button'
import { Icon } from '@/components/icon'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame, Props as InputProps } from '../components/frame.input'
import { InputHint } from '../components/hint.input'
import { InputLabel } from '../components/label.input'

type Props = Omit<InputProps, 'onChange'> & {
  defaultValue?: string
  placeholder?: string
  label?: string
  hint?: string
  state?: 'default' | 'disabled'
  size?: 'medium' | 'large'
  hideClearButton?: boolean
  fillWidth?: boolean
  className?: string
  elementClassName?: string
  onChange: (value: string) => void
  testId?: string
}

export const InputSearch = (props: Props) => {
  const [_value, setValue] = useState<string>(props.defaultValue ?? '')

  const onValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    // Update our input value
    setValue(newValue)

    // Inform any external props of the change
    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  const onClearClicked = () => {
    setValue('')
    if (props.onChange) {
      props.onChange('')
    }
  }

  return (
    <InputField
      testId={props.testId}
      label={
        props.label ? (
          <InputLabel state={props.state} text={props.label} />
        ) : undefined
      }
      hint={
        props.hint ? (
          <InputHint state={props.state} label={props.hint} style={'hint'} />
        ) : undefined
      }
      size={props.size}
      className={props.className}
      element={
        <InputElement
          variant={'default'}
          icon={
            <Icon
              name={'search'}
              family={'sharp'}
              variant={'solid'}
              size={'medium'}
            />
          }
          state={props.state ?? 'default'}
          trailing={
            props.hideClearButton !== true && _value.length > 0
              ? {
                  button: (
                    <ButtonShape
                      layout={'icon'}
                      hierarchy={'tertiary'}
                      shape={'round'}
                      icon={{
                        name: 'close',
                      }}
                      onClick={onClearClicked}
                      withAttributes={{
                        type: 'button',
                      }}
                    />
                  ),
                }
              : undefined
          }
          input={
            <InputFrame
              {...props}
              value={_value}
              onChange={onValueChanged}
              className={'max-w-none border-none bg-transparent focus:ring-0'}
            />
          }
          className={twMerge(
            'rounded-[2.5rem] w-full max-w-none',
            props.elementClassName
          )}
        />
      }
    />
  )
}
