import { ColDef, IRowNode, RowSelectedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Chip from '@/components/chip'
import { Typography } from '@/components/typography'
import { getCryptoImageUrl } from '@/helpers/assetTitleAndIcon'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { ISupportedAsset } from '@/types/custodian'

import styles from '../aggrid.module.css'

export interface Props {
  data: ISupportedAsset[]
  maxSelection?: number
  filterText?: string
  allowSelectAll?: boolean
  selected?: ISupportedAsset[]
  onSelectionChanged?: (selected: ISupportedAsset[]) => void
}

export const SupportedAssetsTable: React.FC<Props> = (props: Props) => {
  const DEFAULT_MAX_SELECTION: number = 1
  const { t } = useTranslation()
  const gridRef = useRef<AgGridReact<ISupportedAsset>>(null)
  const [_usedCustodianIds, setUsedCustodianIds] = useState<string[]>([])

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current?.api?.forEachNode((node) => {
        node.setSelected(
          props.selected?.find(
            (a) =>
              a.name === node.data?.name &&
              a.network === node.data?.network &&
              a.ticker === node.data?.ticker &&
              a.contract_address === node.data?.contract_address &&
              a.native_asset_name === node.data?.native_asset_name &&
              a.native_asset_ticker === node.data?.native_asset_ticker
          ) !== undefined
        )
      })
    }
  }, [props.selected, gridRef.current])

  useEffect(() => {
    const availableCustodians: Set<string> = new Set()
    for (const a of props.data) {
      if (a.custodians.length > 0) {
        availableCustodians.add(a.custodians[0].id)
      }
    }
    console.log('found custodians', availableCustodians)
    setUsedCustodianIds(Array.from(availableCustodians))
  }, [props.data])

  const colDefs = useMemo<ColDef<ISupportedAsset>[]>(
    () => [
      ...((props.maxSelection ?? DEFAULT_MAX_SELECTION) > 0
        ? [
            {
              checkboxSelection: true,
              headerCheckboxSelection: props.allowSelectAll === true,
              width: 50,
              minWidth: 50,
            },
          ]
        : []),
      {
        headerName: t('token'),
        cellRenderer: (props: CustomCellRendererProps<ISupportedAsset>) => {
          return (
            <div className={'flex flex-row gap-[16px] w-full'}>
              <Avatar
                type={'user'}
                size={'medium'}
                image={getCryptoImageUrl(props.data?.ticker)}
                fallbackImage={getCryptoImageUrl()}
              />
              <div
                className={twMerge(
                  'flex flex-1 flex-col gap-[4px] justify-center',
                  'overflow-hidden'
                )}
              >
                {props.data?.name && (
                  <Typography variant={'label-small'} className={'truncate'}>
                    {props.data.name}
                  </Typography>
                )}
                <Chip.Basic
                  type={'outline'}
                  colour={'grey'}
                  label={props.data?.ticker ?? '-'}
                  size={'small'}
                  className="self-start"
                />
              </div>
            </div>
          )
        },
        getQuickFilterText: (params) => {
          return `${params.data.name} ${params.data.ticker} network:${params.data.native_asset_name}`
        },
        comparator: (
          _a,
          _b,
          nodeA: IRowNode<ISupportedAsset>,
          nodeB: IRowNode<ISupportedAsset>
        ) => {
          const prep = (d: ISupportedAsset | undefined) =>
            d?.name.trim() ?? d?.ticker.trim() ?? ''
          const valA = prep(nodeA.data)
          const valB = prep(nodeB.data)
          return valA.localeCompare(valB)
        },
        width: 320,
        minWidth: 200,
      },
      {
        hide: _usedCustodianIds.length <= 1,
        field: 'custodians',
        headerName: t('custodian'),
        cellRenderer: (cellProps: CustomCellRendererProps<ISupportedAsset>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <Typography
                variant={'paragraph-small'}
                className={'text-right truncate'}
              >
                {cellProps.data?.custodians.at(0)?.name ?? '-'}
              </Typography>
            </div>
          )
        },
        getQuickFilterText: (params) => {
          return `${params.data.custodians.at(0)?.name}`
        },
        comparator: (
          _a,
          _b,
          nodeA: IRowNode<ISupportedAsset>,
          nodeB: IRowNode<ISupportedAsset>
        ) => {
          const valA = nodeA.data?.custodians.at(0)?.name ?? '-'
          const valB = nodeB.data?.custodians.at(0)?.name ?? '-'
          return valA.localeCompare(valB)
        },
        width: 160,
        minWidth: 160,
      },
      {
        field: 'contract_address',
        headerName: t('contract_address'),
        cellRenderer: (cellProps: CustomCellRendererProps<ISupportedAsset>) => {
          return (
            <div className={'flex flex-row gap-[16px] items-center w-full'}>
              <Typography
                variant={'paragraph-small'}
                className={'text-right truncate'}
              >
                {cellProps.value ?? '-'}
              </Typography>
              {cellProps.value && (
                <Button.Shape
                  layout={'icon'}
                  size={'xsmall'}
                  shape={'square'}
                  hierarchy={'tertiary'}
                  icon={{ name: 'copy' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    copyToClipboard(cellProps.value, t('contract_address'))
                  }}
                />
              )}
            </div>
          )
        },
        flex: 1,
        minWidth: 400,
      },
    ],
    [t, props.maxSelection, _usedCustodianIds]
  )

  // Handle row selection event
  const onRowSelected = useCallback(
    (event: RowSelectedEvent<ISupportedAsset>) => {
      const selectedNodes = gridRef.current?.api.getSelectedNodes()
      const maxSelection = props.maxSelection ?? DEFAULT_MAX_SELECTION

      // Check if we've reached a max selection limit
      if (selectedNodes && selectedNodes.length > maxSelection) {
        // Deselect the newly selected row if the max is exceeded
        event.node.setSelected(false)
      }
    },
    [props.maxSelection]
  )

  return (
    <div
      className={`ag-theme-quartz ${styles.grid} ${styles.no_cell_selection} h-full`}
    >
      <AgGridReact
        ref={gridRef}
        suppressContextMenu
        suppressRowClickSelection
        isRowSelectable={() =>
          (props.maxSelection ?? DEFAULT_MAX_SELECTION) > 0 ? true : false
        }
        quickFilterText={props.filterText}
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          rowHeight: 72,
          rowSelection:
            (props.maxSelection ?? DEFAULT_MAX_SELECTION) > 1
              ? 'multiple'
              : (props.maxSelection ?? DEFAULT_MAX_SELECTION) === 1
                ? 'single'
                : undefined,
        }}
        rowData={props.data}
        defaultColDef={{
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true,
          filter: true,
        }}
        columnDefs={colDefs}
        onSelectionChanged={(e) => {
          props.onSelectionChanged?.(e.api.getSelectedRows())
        }}
        onRowSelected={onRowSelected}
      />
    </div>
  )
}
