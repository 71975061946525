import { t } from 'i18next'
import { redirect } from 'react-router-dom'

import { changePassword } from '@/api'
import { ROUTES } from '@/constants/routes'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'

export const setNewPasswordAction = async (
  formData: FormData
): Promise<ActionResponse<string> | Response> => {
  const old_password = formData
    .get('old_password')
    ?.toString()
    ?.trim() as string
  const new_password = formData
    .get('new_password')
    ?.toString()
    ?.trim() as string
  const confirm_password = formData
    .get('confirm_password')
    ?.toString()
    ?.trim() as string

  if (old_password === new_password)
    throw new Error('The new password must be different from the old one.')

  try {
    await changePassword({
      old_password,
      new_password,
      confirm_password,
    })

    BannerManager.showBanner({
      variant: 'page',
      type: 'success',
      title: t('password_updated_successfully'),
      duration: 5000,
    })

    const from = new URLSearchParams(window.location.search).get('from')
    if (from) return redirect(`${ROUTES.PROFILE.INDEX}?from=${from}`)
    redirect(ROUTES.PROFILE.INDEX)

    return {
      error: false,
      message: t('password_updated_successfully'),
      completion: 'COMPLETE',
    }
  } catch (error) {
    ToastManager.showToast({
      type: 'critical',
      text: t('password_update_failed'),
    })

    return {
      error: true,
      message: t('password_update_failed'),
      completion: 'NOT_COMPLETE',
    }
  }
}
