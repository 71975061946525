import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IGroup, IRole } from '@/types/group'
import { ISuperAdminOrg, ISuperAdminProps } from '@/types/organisation'
import { IPolicy } from '@/types/policies'
import { IUser } from '@/types/user'

export const createOrgUser = async (
  user: ISuperAdminOrg,
  adminSecret?: string
): Promise<BaseResponse<IUser>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL, adminSecret)
  return await client
    // .post('/admin/init', { ...user, root: true })
    .post('/admin/init', user)
    .then((res) => res.data)
}

export const createSuperAdminUser = async (
  user: ISuperAdminProps,
  adminSecret?: string
): Promise<BaseResponse<IUser>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL, adminSecret)
  return await client
    .post('/admin/init', { ...user, root: true })
    .then((res) => res.data)
}

export const getAllPolicies = async (): Promise<BaseResponse<IPolicy[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.get('/policy/all').then((res) => res.data)
}

export const getAllRoles = async (): Promise<BaseResponse<IRole[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.get('/role').then((res) => {
    return res.data
  })
}

export const getGroupById = async (
  groupId: string
): Promise<BaseResponse<IGroup>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.get(`/group/${groupId}`).then((res) => res.data)
}

export const getAllGroups = async (): Promise<BaseResponse<IGroup[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.get('/group').then((res) => res.data)
}

export const updateGroup = async (
  groupId: string,
  data: {
    name: string
    user_ids: string[]
    role_ids: string[]
  }
): Promise<BaseResponse<unknown>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client.put(`/group/${groupId}`, data).then((res) => res.data)
}
