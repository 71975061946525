import { Currency } from '@/constants/currencies.ts'
import { isDefined } from '@/helpers/isDefined.ts'
import {
  IAsset,
  IAssetCost,
  IAssetStatus,
  IAssetValuation,
} from '@/types/asset.ts'

export const getCostsFromForm = (formData: FormData): IAssetCost[] => {
  const costs: IAssetCost[] = []
  let index: number = 0
  let hasCosts: boolean = true
  const orgCurrency = formData.get('asset_org_currency') as Currency
  while (hasCosts) {
    const name = formData.get(`asset_cost_name_${index}`)
    const value = formData.get(`asset_cost_value_${index}`)

    // Check if we found a value - name can be blank
    if (value) {
      // Add in our found cost
      costs.push({
        currency: orgCurrency,
        title: name?.toString() ?? '',
        value: Number(value?.toString() ?? 0),
      })

      // Increase our index count
      index += 1
    } else {
      hasCosts = false
      break
    }
  }
  return costs
}

export const findLastValuation = (
  valuations: IAssetValuation[] | undefined
): IAssetValuation | null => {
  if (!isDefined(valuations) || valuations.length == 0) {
    return null
  }

  return valuations.sort(
    (a, b) =>
      new Date(b.created_at ?? 0).getTime() -
      new Date(a.created_at ?? 0).getTime()
  )[0]
}

export const getTotalCostFor = (asset: IAsset) => {
  return (
    asset.costs?.reduce((acc, cost) => {
      return acc + (cost.value ?? 0)
    }, 0) ?? 0
  )
}

export const findLastStatus = (
  statuses: IAssetStatus[] | undefined
): IAssetStatus | null => {
  if (!isDefined(statuses) || statuses.length == 0) {
    return null
  }
  return statuses.sort(
    (a, b) =>
      new Date(b.created_at ?? 0).getTime() -
      new Date(a.created_at ?? 0).getTime()
  )[0]
}
