import { exists, t } from 'i18next'

import { USER_ROLES } from '@/constants/auth'
import { IRole } from '@/types/group.ts'

export const roleTitleTranslated = (role: IRole) => {
  let key = `role_title_${role.name.toLowerCase()}`

  // We're re-purposing certain old roles for Escrow temporarily
  // so we'll run a check here to use the correct role
  if (role.name.toLowerCase() === USER_ROLES.SIGNER) {
    key = 'role_title_escrow_initiator'
  } else if (role.name.toLowerCase() === USER_ROLES.APPROVER) {
    key = 'role_title_escrow_approver'
  }

  if (exists(key)) {
    return t(key)
  } else {
    return role.name
  }
}

export const roleDescriptionTranslated = (role: IRole) => {
  let key = `role_description_${role.name.toLowerCase()}`

  // We're re-purposing certain old roles for Escrow temporarily
  // so we'll run a check here to use the correct role
  if (role.name.toLowerCase() === USER_ROLES.SIGNER) {
    key = 'role_description_escrow_initiator'
  } else if (role.name.toLowerCase() === USER_ROLES.APPROVER) {
    key = 'role_description_escrow_approver'
  }

  if (exists(key)) {
    return t(key)
  } else {
    return role.name
  }
}
