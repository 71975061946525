import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import {
  Currency,
  getCurrencyIcon,
  getCurrencyTitle,
} from '@/constants/currencies.ts'
import {
  getSavedSelectionEnum,
  saveDropDownSelection,
} from '@/helpers/localStorage.ts'

interface Props {
  savedSelectionIdentifier?: string
  onSelectionChanged: (selection: Currency) => void
}

export const AssetCurrencySelector = (props: Props) => {
  const [_selection, setSelection] = useState<Currency>(
    getSavedSelectionEnum(Currency, props.savedSelectionIdentifier) ??
      Currency.USD
  )

  useEffect(() => {
    props.onSelectionChanged(_selection)
  }, [])

  const onSelectedChange = (value: Currency) => {
    setSelection(value)
    props.onSelectionChanged(value)
    saveDropDownSelection(props.savedSelectionIdentifier, value)
  }
  return (
    <div className={'w-full'}>
      <Input.Dropdown
        testId={'asset_currency'}
        placeholder={t('choose_financial_asset_type')}
        icon={
          <Icon
            name={getCurrencyIcon(_selection)}
            variant={'solid'}
            family={'sharp'}
            size={'medium'}
          />
        }
        text={getCurrencyTitle(_selection)}
        hint={t('choose_financial_asset_type')}
        items={Object.values(Currency).map((currency, index) => (
          <ListItem
            key={`option_${index}`}
            title={getCurrencyTitle(currency)}
            style={'normal'}
            leading={
              <Icon
                name={getCurrencyIcon(currency)}
                family={'sharp'}
                variant={'solid'}
                size={'medium'}
              />
            }
            {...(_selection &&
              _selection === currency && {
                trailing: (
                  <Icon
                    name={'circle-check'}
                    family={'sharp'}
                    variant={'solid'}
                    size={'medium'}
                  />
                ),
              })}
            onClick={() => onSelectedChange(currency)}
            className={'pl-2 pr-2'}
          />
        ))}
      />
    </div>
  )
}
