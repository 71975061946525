import { t } from 'i18next'
import { ReactElement } from 'react'

import { Icon } from '@/components/icon'
import { copyToClipboard } from '@/helpers/copyToClipboard'

import { InputElement } from '../components/element.input'
import { InputField } from '../components/field.input'
import { InputFrame } from '../components/frame.input'
import { InputHint, Props as HintProps } from '../components/hint.input'
import { InputLabel } from '../components/label.input'

interface Props {
  id: string
  includeCopy?: boolean
  hideLabel?: boolean
  hint?: string | ReactElement<HintProps>
  testId?: string
}
export const InputIDNumber = (props: Props) => {
  return (
    <InputField
      label={
        props.hideLabel === true ? undefined : (
          <InputLabel
            state={'default'}
            text={t('ar_reference')}
            size={'medium'}
          />
        )
      }
      hint={
        typeof props.hint === 'string' ? (
          <InputHint state={'default'} label={props.hint} style={'hint'} />
        ) : props.hint !== undefined ? (
          props.hint
        ) : undefined
      }
      element={
        <InputElement
          variant={'id-number'}
          state={'default'}
          testId={props.testId}
          trailing={
            props.includeCopy !== false
              ? {
                  icon: (
                    <Icon
                      name={'copy'}
                      family={'sharp'}
                      variant={'solid'}
                      onClick={() =>
                        copyToClipboard(props.id, t('ar_reference'))
                      }
                    />
                  ),
                }
              : undefined
          }
          input={
            <InputFrame
              state={'default'}
              variant={'id-number'}
              title={props.id}
              value={props.id}
              text={props.id}
            />
          }
        />
      }
      className={'max-w-none w-auto self-start'}
    />
  )
}
