import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import { SupportedAssetTypeSelector } from '@/components/asset/supported-asset-type-selector'
import Button from '@/components/button'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { FormErrorsManager } from '@/contexts/formErrors'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { SupportedAssetType } from '@/types/asset'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  custodian_id?: string
}

export const NewSupportedAssetDrawer = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)
  const [_assetType, setAssetType] =
    useState<SupportedAssetType>('CONTRACT TOKEN')

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  const onAssetTypeChanged = (type: SupportedAssetType) => {
    setAssetType(type)
    FormErrorsManager.clearErrors()
  }

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    props.onClose()
  }

  return (
    <Drawer
      title={t('add_supported_asset')}
      description={t('add_custodian_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'create-supported-asset-form'}
        buttons={[
          <Button.Basic
            key={'btn_add_asset'}
            label={t('add_asset')}
            state={
              _submitting === INTENTS.ADD_SUPPORTED_ASSET
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.ADD_SUPPORTED_ASSET,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <Paragraph subTitle={t('asset_type')} />

        <input
          type={'hidden'}
          name={'custodian_id'}
          value={props.custodian_id}
        />

        <SupportedAssetTypeSelector
          defaultType={_assetType}
          onTypeChanged={onAssetTypeChanged}
        />

        <Input.Text
          id="contract_address"
          name="contract_address"
          label={t('contract_address')}
          autoComplete={'off'}
          placeholder="e.d. OxEdODBOE979120aa8EFBA3314Ca8bD1592bb7923"
        />

        <Input.Text
          id="network_name"
          name="network_name"
          label={t('network_name')}
          autoComplete={'off'}
          placeholder="e.d. Bitcoin, Ethereum etc..."
        />

        <Input.Text
          id="asset_name"
          name="asset_name"
          label={t('asset_name')}
          autoComplete={'off'}
          placeholder="e.d. Bitcoin, Ethereum Coin etc..."
        />

        <Input.Text
          id="ticker"
          name="ticker"
          label={t('ticker')}
          autoComplete={'off'}
          placeholder="e.d. BTC, ETH, BNB etc..."
        />
      </Form>
    </Drawer>
  )
}
