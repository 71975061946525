import DropdownController, {
  Props as ControllerProps,
} from '@/components/dropdown/components/controller.dropdown'
import { ICustodian } from '@/types/custodian'

import CustodianActionsView from './views/custodian-actions.view'

interface Props extends ControllerProps<unknown> {
  custodian: ICustodian
}
const CustodianActionsController = (props: Props) => {
  return (
    <DropdownController
      {...props}
      defaultViewId={'CUSTODIAN_ACTIONS'}
      closeOnItemClick={true}
    >
      <CustodianActionsView custodian={props.custodian} />
    </DropdownController>
  )
}
export default CustodianActionsController
