import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { formatValue } from '@/helpers/formatValue'

import { Typography } from '../../typography'
import Colors from '../constants/colors'
import { BaseProps, canHide, GraphData } from '../types'
import { LegendKey } from './legendKey'

interface Props extends BaseProps {
  zeroLabel?: string
}

export const BarGraph = (props: Props) => {
  const [_data, setData] = useState<GraphData[]>(
    props.data
      .map((d, i) => {
        return { ...d, htmlColor: d.htmlColor ?? Colors[i] }
      })
      .sort((a, b) => b.value - a.value)
  )
  const [_totalValue, setTotalValue] = useState<number>(
    props.data.reduce((prev, curr) => prev + curr.value, 0)
  )

  useEffect(() => {
    setData(
      props.data
        .map((d, i) => {
          return { ...d, htmlColor: d.htmlColor ?? Colors[i] }
        })
        .sort((a, b) => b.value - a.value)
    )
    setTotalValue(props.data.reduce((prev, curr) => prev + curr.value, 0))
  }, [props.data])

  return (
    <div
      className={twMerge('flex w-full flex-col gap-[10px]', props.className)}
    >
      {props.showTotal && (
        <>
          <Typography
            variant={'heading-small'}
            spacers={[]}
            tooltip={
              props.format === 'CURRENCY'
                ? formatValue(_totalValue, props.currency, {
                    notation: 'standard',
                    minimumFractionDigits: 2,
                  })
                : _totalValue.toString()
            }
            className={'hidden tablet:flex'}
          >
            {props.format === 'CURRENCY'
              ? formatValue(_totalValue, props.currency, {
                  notation: 'standard',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              : _totalValue}
          </Typography>
          <Typography
            variant={'heading-small'}
            spacers={[]}
            tooltip={
              props.format === 'CURRENCY'
                ? formatValue(_totalValue, props.currency, {
                    notation: 'standard',
                    minimumFractionDigits: 2,
                  })
                : _totalValue.toString()
            }
            className={'flex tablet:hidden'}
          >
            {props.format === 'CURRENCY'
              ? formatValue(_totalValue, props.currency, {
                  notation: 'compact',
                  minimumFractionDigits: 2,
                })
              : _totalValue}
          </Typography>
        </>
      )}
      <div className="flex flex-col w-full overflow-hidden gap-[8px]">
        <div
          className={twMerge(
            'flex flex-row w-full gap-[2px]',
            'rounded-[2px] overflow-hidden'
          )}
        >
          {_totalValue <= 0 || _data.length <= 0 ? (
            <div
              className={twMerge('flex-1', 'min-h-[16px] bg-[#E1E1E2]')}
            ></div>
          ) : (
            _data.map((d, i) =>
              d.value <= 0 ? null : (
                <div
                  key={`d_${i}`}
                  className={twMerge(
                    'flex-1',
                    'h-[16px] bg-black',
                    'min-w-[4px]'
                  )}
                  style={{
                    backgroundColor: d.htmlColor,
                    maxWidth: `${(d.value * 100) / _totalValue}%`,
                  }}
                ></div>
              )
            )
          )}
        </div>
        {props.showLegend === true && (
          <div className={'flex flex-row w-full gap-[16px] flex-wrap'}>
            {_totalValue <= 0 || _data.length <= 0 ? (
              <LegendKey
                label={props.zeroLabel ?? t('no_data')}
                htmlColor={'#E1E1E2'}
                textClassName={'text-[#909293]'}
              />
            ) : (
              _data
                .filter((d) => !canHide(d))
                .sort((a, b) => b.value - a.value)
                .map((d, i) => (
                  <LegendKey
                    label={d.label}
                    htmlColor={d.htmlColor}
                    key={`d_${i}`}
                  />
                ))
            )}
          </div>
        )}
      </div>
    </div>
  )
}
