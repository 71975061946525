import { t } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunction, useFetcher } from 'react-router-dom'

import { AssetRealityIcon } from '@/assets/asset-reality-icon'
import { AssetRealityIconTransparent } from '@/assets/asset-reality-icon-transparent'
import { AssetRealityText } from '@/assets/asset-reality-text'
import Button from '@/components/button'
import { Checkbox, CheckedState } from '@/components/checkbox'
import File from '@/components/file'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { ListItem } from '@/components/list-item'
import { Paragraph } from '@/components/paragraph'
import { Radio } from '@/components/radio'
import { Typography } from '@/components/typography'
import { ToastManager } from '@/contexts/toast'

import {
  AssetTypes,
  EstimatedAssetBalance,
  NumberOfLicenses,
  Requirements,
  Sectors,
} from './constants'
import {
  EstimatedAssetBalanceType,
  NumberOfLicensesType,
  SectorType,
} from './types'

export const action: LoaderFunction = async ({ request }) => {
  try {
    const formData = await request.formData()

    const registrationInputs = [
      'email',
      'sector',
      'number_of_licenses',
      'estimated_asset_balance',
      'number_of_assets',
      'asset_list',
    ]

    const newRegistration = registrationInputs.reduce((acc, key) => {
      const value = formData.get(key)
      return value ? { ...acc, [key]: value } : acc
    }, {}) as {
      currency: string
      quantity: string
      total_value: string
      owners_name: string
      asset_subtype: string
    }
    console.log(newRegistration)
    return {
      newRegistration,
    }
  } catch (error: unknown) {
    const _error = error as { response: { data: { message: string } } }
    const errorMessage = _error?.response?.data?.message

    if (errorMessage) {
      return ToastManager.showToast({
        text: errorMessage,
        type: 'information',
      })
    }

    return ToastManager.showToast({
      text: t('login_failed'),
      type: 'information',
    })
  }
}

export const Register: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const fetcher = useFetcher()
  const [_selectedSector, setSelectedSector] = useState<SectorType>(Sectors[0])
  const [_workspaceCount, setWorkspaceCount] = useState<NumberOfLicensesType>(
    NumberOfLicenses[0]
  )
  const [_estimatedAssetBalance, setEstimatedAssetBalance] =
    useState<EstimatedAssetBalanceType>(EstimatedAssetBalance[0])

  const isLoading = fetcher.state === 'submitting'

  const [_assetType, setAssetType] = useState<string>(AssetTypes[0].value)
  const [_requirements, setRequirements] = useState<string[]>([])

  return (
    <div className="flex min-h-[100dvh] justify-center desktop:justify-between flex-1">
      <div className="relative hidden desktop:block w-[50%] ">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/register-bg.svg"
          alt=""
        />

        <div className="absolute top-10 left-12 text-white flex gap-2 items-center">
          <AssetRealityIconTransparent />
          <div className="mt-1">
            <AssetRealityText />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between flex-1 mx-auto w-full max-w-sm tablet:max-w-[425px] desktop:pb-12 desktop:pt-9">
        <header className="flex flex-row gap-x-2 py-6 items-center desktop:hidden text-red">
          <AssetRealityIcon />
          <AssetRealityText />
        </header>
        <div className="desktop:visible" />
        <div className="my-6 desktop:mb-[4rem] desktop:mt-0">
          <Paragraph
            title={t('register_title')}
            description={t('register_subtitle')}
          />
        </div>
        <div>
          <fetcher.Form method="POST" className="flex flex-col gap-6">
            <Input.Email
              id="email"
              name="email"
              label={t('email_address')}
              placeholder={t('email_address_placeholder')}
              icon={
                <Icon name={'envelope'} family={'sharp'} variant={'solid'} />
              }
            />

            <Input.Dropdown
              label={t('sector')}
              name={'sector'}
              items={Sectors.map((item, index) => (
                <ListItem
                  key={`sector_${index}`}
                  title={item.label}
                  value={item.id}
                  style={'normal'}
                  onClick={() => {
                    setSelectedSector(item)
                  }}
                  trailing={
                    _selectedSector.id === item.id ? (
                      <Icon
                        size={'medium'}
                        family={'sharp'}
                        name={'circle-check'}
                        variant={'solid'}
                      />
                    ) : undefined
                  }
                  className={'pl-4 pr-4'}
                />
              ))}
            />

            <div className={'flex flex-row gap-6'}>
              <Input.Dropdown
                label={t('workspaces')}
                name={'workspaces'}
                items={NumberOfLicenses.map((item, index) => (
                  <ListItem
                    key={`numoflicenses_${index}`}
                    title={item.label}
                    value={item.id}
                    style={'normal'}
                    onClick={() => {
                      setWorkspaceCount(item)
                    }}
                    trailing={
                      _workspaceCount.id === item.id ? (
                        <Icon
                          size={'medium'}
                          family={'sharp'}
                          name={'circle-check'}
                          variant={'solid'}
                        />
                      ) : undefined
                    }
                    className={'pl-4 pr-4'}
                  />
                ))}
              />
            </div>

            <Typography variant="label-large">Assets</Typography>

            <Input.Dropdown
              label={t('estimated_asset_balance')}
              name={'licenses'}
              items={EstimatedAssetBalance.map((item, index) => (
                <ListItem
                  key={`numoflicenses_${index}`}
                  title={item.label}
                  value={item.id}
                  style={'normal'}
                  onClick={() => {
                    setEstimatedAssetBalance(item)
                  }}
                  trailing={
                    _estimatedAssetBalance.id === item.id ? (
                      <Icon
                        size={'medium'}
                        family={'sharp'}
                        name={'circle-check'}
                        variant={'solid'}
                      />
                    ) : undefined
                  }
                />
              ))}
            />

            <Input.Number
              id="number_of_assets"
              name="number_of_assets"
              label="Number of assets"
              placeholder="e.g. 5,000"
            />

            <div className={'flex flex-col gap-2'}>
              <Typography variant="label-large">{t('asset_type')}</Typography>

              <div className={'flex flex-col'}>
                {AssetTypes.map((asset) => (
                  <ListItem
                    key={asset.value}
                    title={asset.label}
                    trailing={
                      <Radio
                        id={asset.value}
                        value={asset.value}
                        groupId={'asset_type_group'}
                        defaultChecked={_assetType === asset.value}
                        onCheckChanged={() => {
                          setAssetType(asset.value)
                        }}
                        size={'medium'}
                      />
                    }
                  />
                ))}
              </div>
            </div>

            <div className={'flex flex-col gap-2'}>
              <Typography variant="label-large">{t('requirements')}</Typography>

              <div className={'flex flex-col'}>
                {Requirements.map((requirement) => (
                  <ListItem
                    key={requirement.value}
                    title={requirement.value}
                    trailing={
                      <Checkbox
                        id={requirement.value}
                        size={'medium'}
                        checked={
                          _requirements.includes(requirement.value)
                            ? 'checked'
                            : 'unchecked'
                        }
                        onCheckChanged={async (
                          _id: string,
                          state: CheckedState
                        ) => {
                          setRequirements((prev) =>
                            prev.includes(requirement.value)
                              ? prev.filter((r) => r !== requirement.value)
                              : [...prev, requirement.value]
                          )
                          return state
                        }}
                      />
                    }
                  />
                ))}
              </div>
            </div>

            <Typography variant="label-large" className="col-span-full py-2">
              {t('send_us_your_asset_list')}
            </Typography>
            <Button.Basic
              hierarchy={'secondary'}
              size={'small'}
              label={t('download_an_example_spreadsheet')}
              trailingIcon={{
                name: 'download',
              }}
            />

            <File.Upload
              id="asset_list"
              innerText="Accepts .xls, .xlsx, .csv"
              name="asset_list"
              label="Upload your complete spreadsheet"
              allowedFileTypes={['XLS', 'CSV']}
              showFileList={true}
            />

            <Button.Basic
              label={t('submit_your_application')}
              icon={{
                name: 'arrow-right',
              }}
              state={isLoading ? 'loading' : 'default'}
              withAttributes={{
                name: 'intent',
                type: 'submit',
                value: 'register',
              }}
              className={'mt-10 mb-§'}
            />
          </fetcher.Form>
        </div>
        <footer className="flex flex-row gap-x-2 py-6 tablet:py-12 desktop:p-0 items-center justify-between text-gray-500 text-sm font-extrabold underline mt-10">
          <a href="https://www.assetreality.com/privacy-policy" target="blank">
            Privacy policy
          </a>
          <a href="https://www.assetreality.com/cookie-policy" target="blank">
            Cookie policy
          </a>
        </footer>
      </div>
    </div>
  )
}
