import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from '../button'
import { Typography } from '../typography'

export interface Props {
  name: string
  testId?: string
  value?: number
  minValue?: number
  maxValue?: number
  size?: 'medium' | 'small'
  state?: 'default' | 'disabled'
  onValueChanged?: (name: string, value: number) => void
}

export const Stepper = (props: Props) => {
  const [_value, setValue] = useState<number>(props.value ?? 0)
  const [_minValue, setMinValue] = useState<number>(props.minValue ?? 0)
  const [_maxValue, setMaxValue] = useState<number>(props.maxValue ?? 10)

  useEffect(() => {
    setMinValue(props.minValue ?? 0)
  }, [props.minValue])

  useEffect(() => {
    setMaxValue(props.maxValue ?? 0)
  }, [props.maxValue])

  useEffect(() => {
    changeValue(props.value)
  }, [props.value])

  // Handles when the value is changed
  useEffect(() => {
    if (props.onValueChanged) {
      props.onValueChanged(props.name, _value)
    }
  }, [_value])

  const changeValue = (newValue?: number) => {
    setValue(Math.min(_maxValue, Math.max(_minValue, newValue ?? 0)))
  }

  return (
    <div
      className={twMerge(
        'flex flex-row items-center',
        'rounded-[6px] border-[1px] border-gray-200',
        props.size === 'small'
          ? 'h-[36px] max-h-[36px]'
          : 'h-[44px] max-h-[44px]'
      )}
    >
      <input name={props.name} type={'hidden'} value={_value} />
      <div
        className={twMerge(
          'flex items-center justify-center',
          props.size === 'small' ? 'w-[36px] h-[36px]' : 'w-[44px] h-[44px]'
        )}
      >
        <Button.Shape
          shape={'square'}
          size={props.size === 'small' ? 'xsmall' : 'small'}
          hierarchy={'tertiary'}
          layout={'icon'}
          icon={{ name: 'minus' }}
          state={(_value ?? 0) <= _minValue ? 'disabled' : 'default'}
          onClick={() => changeValue(_value - 1)}
        />
      </div>
      <div className={twMerge('flex min-w-[24px] items-center justify-center')}>
        <Typography variant={'label-medium'} className={'select-none'}>
          {_value}
        </Typography>
      </div>
      <div
        className={twMerge(
          'flex items-center justify-center',
          props.size === 'small' ? 'w-[36px] h-[36px]' : 'w-[44px] h-[44px]'
        )}
      >
        <Button.Shape
          shape={'square'}
          size={props.size === 'small' ? 'xsmall' : 'small'}
          hierarchy={'tertiary'}
          layout={'icon'}
          icon={{ name: 'plus' }}
          state={(_value ?? 0) >= _maxValue ? 'disabled' : 'default'}
          onClick={() => changeValue(_value + 1)}
        />
      </div>
    </div>
  )
}
