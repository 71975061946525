import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'

export interface Props {
  type:
    | 'help'
    | 'success'
    | 'error'
    | 'warning'
    | 'message'
    | 'offer'
    | 'loading'
    | 'table'
    | 'token'
    | 'declined'
  size?: 'large' | 'medium' | 'small'
  paddingTop?: boolean
  paddingBottom?: boolean
}
export const CardBadge = (props: Props) => {
  const iconNames: { [type in Props['type']]: string } = {
    help: 'question-circle',
    success: 'circle-check',
    error: 'triangle-exclamation',
    warning: 'exclamation-circle',
    message: 'circle-info',
    offer: 'star',
    table: 'table',
    token: 'wallet',
    declined: 'circle-xmark',
    loading: 'spinner', // not used
  }

  return (
    <div
      className={twMerge(
        'flex flex-col items-center w-[4rem]',
        props.paddingTop ? 'pt-[1rem]' : '',
        props.paddingBottom ? 'pb-[1rem]' : ''
      )}
    >
      {props.type === 'loading' ? (
        <Icon type="loader" size={props.size} color={'black'} />
      ) : (
        <Icon
          size={
            props.size === 'small'
              ? 'large'
              : props.size === 'medium'
                ? 'xlarge'
                : 'xxlarge'
          }
          name={iconNames[props.type]}
          family={'sharp'}
          variant={'solid'}
        />
      )}
    </div>
  )
}
