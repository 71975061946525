import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from '@/components/button'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { Table } from '@/components/table'
import { GroupBy as CaseTableGroupBy } from '@/components/table/variants/cases.table'
import { useAuth } from '@/contexts/auth'
import { useDrawer } from '@/contexts/interface'
import { useOperations } from '@/hooks/queries/useOperations'

export const Cases: React.FC = (): JSX.Element => {
  const [_urlParams] = useSearchParams()
  const { t } = useTranslation()
  const { setDrawer } = useDrawer()
  const { operations } = useOperations()
  const [_tableGroup, setTableGroup] = useState<CaseTableGroupBy>()

  useEffect(() => {
    if (_urlParams.has('filter')) {
      const filter = _urlParams.get('filter')
      if (filter && ['all', 'active', 'archived'].includes(filter)) {
        setTableGroup(filter as CaseTableGroupBy)
      }
    }
  }, [_urlParams])

  const { policies } = useAuth()
  const canCreateCase = policies?.includes('CUSTODY.MANAGE_OPERATION')

  return (
    <>
      <div
        className={twMerge(
          'flex flex-col min-h-[calc(100dvh-80px)]',
          'tablet:pt-0'
        )}
      >
        <div className={twMerge('flex flex-col p-8 pt-4 pb-0 gap-8')}>
          <Paragraph
            title={t('cases')}
            description={t('case_description')}
            spacerOverrides={{ description: [] }}
          />
          {canCreateCase ? (
            <div className={'flex flex-row gap-2'}>
              <Button.Basic
                icon={{
                  name: 'plus',
                }}
                testId={'btn_add_new_operation'}
                label={t('create_new_case')}
                onClick={() => {
                  setDrawer({ id: 'NEW_CASE' })
                }}
                className="hidden tablet:flex"
              />
            </div>
          ) : null}
        </div>
        {operations && operations.length > 0 ? (
          <div
            className={'flex flex-col px-8 pb-8'}
            data-testid="case-table-wrapper"
          >
            <Table
              testId={'table_operations'}
              cases={operations ?? []}
              defaultGroupBy={_tableGroup}
            />
          </div>
        ) : (
          <NoData description={t('no_cases_description')} />
        )}

        <Button.Fab
          label={t('create_new_case')}
          icon={{
            name: 'plus',
          }}
          onClick={() => {
            setDrawer({ id: 'NEW_CASE' })
          }}
        />
      </div>
    </>
  )
}
