import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import { Form } from '@/components/form'
import { Icon } from '@/components/icon'
import Input from '@/components/input'
import { Paragraph } from '@/components/paragraph'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}

export const NewDefendantDrawer = (props: Props): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    const newCaseUrl = `/subjects/${actionRes.data}`
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(newCaseUrl)
    props.onClose()
  }

  return (
    <Drawer
      title={t('add_new_subject')}
      subTitle={t('information')}
      description={t('new_subject_drawer_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'defendant-form'}
        buttons={[
          <Button.Basic
            key={'add_new_defendant'}
            testId={'btn_create_defendant'}
            label={t('add_new_subject')}
            state={
              _submitting === INTENTS.CREATE_DEFENDANT
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_DEFENDANT,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
      >
        <Input.Text
          id="full_name"
          testId={'defendant_name'}
          name="name"
          label={t('full_name')}
          autoComplete={'off'}
          icon={<Icon name={'user-tie-hair'} variant={'solid'} />}
          hint={t('subject_name_hint')}
          placeholder="e.g. James Parker"
        />

        <Paragraph subTitle={t('contact_details')} />

        <Input.Email
          id="user_email"
          testId={'defendant_email'}
          name="email"
          label={t('email')}
          dynamicInput
          placeholder={t('email_address_placeholder')}
          icon={<Icon name={'envelope'} variant={'solid'} />}
        />

        <Input.Tel
          id="phone"
          testId={'defendant_phone'}
          name="phone"
          placeholder={t('mobile_number_placeholder')}
          icon={<Icon name={'phone'} family={'sharp'} variant={'solid'} />}
          label={t('mobile')}
        />
      </Form>
    </Drawer>
  )
}
