import { t } from 'i18next'
import { useState } from 'react'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import File from '@/components/file'
import { allAllowedFileTypes } from '@/components/file/upload'
import { Form } from '@/components/form'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'
import { IFile } from '@/types/file'
import { IOperation } from '@/types/operations'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {
  operation: IOperation
  onFilesChanged: (files: IFile[]) => void
}

export const NewOperationDocumentDrawer = (props: Props) => {
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<IFile[]>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<IFile[]>) => {
    if (actionRes.data) {
      props.onFilesChanged(actionRes.data)
    }

    // Show a success
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })

    // Close the modal
    props.onClose()
  }

  return (
    <Drawer
      title={t('upload_new_document')}
      description={t('new_case_document_description')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<IFile[], INTENTS>
        type={'other'}
        testId={'new-document-form'}
        buttons={[
          <Button.Basic
            key={'btn_add_case_doc'}
            label={t('upload_document')}
            state={
              _submitting === INTENTS.ADD_OPERATION_DOCUMENT
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.ADD_OPERATION_DOCUMENT,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        className="pt-[24px] gap-[48px]"
      >
        <input
          type={'hidden'}
          name={'existing_operation'}
          value={JSON.stringify(props.operation)}
        />

        <File.Upload
          id="file-upload"
          innerText={t('add_your_files_here')}
          name="new_files"
          maxFilesAllowed={10}
          allowedFileTypes={allAllowedFileTypes}
          showPreview={false}
          showFileList={true}
          showQuantitySelected={false}
        />
      </Form>
    </Drawer>
  )
}
