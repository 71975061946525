import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import './index.css'
import 'ag-grid-enterprise'
import './instrument.ts'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LicenseManager } from 'ag-grid-enterprise'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import Providers from './providers/index.tsx'
import router from './routes/index.tsx'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-065987}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Asset_Reality_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Asset_Reality_Web}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Asset_Reality_Web}_need_to_be_licensed___{Asset_Reality_Web}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_August_2025}____[v3]_[01]_MTc1NjMzNTYwMDAwMA==dec7672a73ac0ead1b0daee07906ef13'
)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Providers>
    <ReactQueryDevtools initialIsOpen={false} />
    <RouterProvider router={router} />
  </Providers>
)
