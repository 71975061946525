import { useQuery } from '@tanstack/react-query'

import { getReportTemplates } from '@/api/broker/v2/reports'

export const key = 'report_templates'
export const useReportTemplates = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key],
    queryFn: () => getReportTemplates(),
  })

  return { templates: data?.data, isLoading, refetchReportTemplates: refetch }
}
