export const AssetRealityIconTransparent = (): JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0003 40.0003C31.0461 40.0003 40.0005 31.0458 40.0005 20C40.0005 8.95415 31.0461 -0.000274658 20.0003 -0.000274658C8.95443 -0.000274658 0 8.95415 0 20C0 31.0458 8.95443 40.0003 20.0003 40.0003ZM24.6578 14.9492L20.223 7.55463C20.122 7.38619 19.878 7.38619 19.777 7.55463L15.3422 14.9492C14.649 16.1051 15.4619 17.5912 16.7873 17.5912H23.2127C24.5381 17.5912 25.351 16.1051 24.6578 14.9492ZM12.3126 20.0007C12.9743 18.8975 14.5411 18.8975 15.2028 20.0007L18.4155 25.3577C19.1087 26.5135 18.2958 27.9997 16.9704 27.9997H7.97478C7.77261 27.9997 7.64774 27.7791 7.75175 27.6056L12.3126 20.0007ZM27.6874 20.0007C27.0257 18.8975 25.4589 18.8975 24.7972 20.0007L21.5845 25.3577C20.8913 26.5135 21.7042 27.9997 23.0296 27.9997H32.0252C32.2274 27.9997 32.3523 27.7791 32.2482 27.6056L27.6874 20.0007Z"
        fill="currentColor"
      />
    </svg>
  )
}
