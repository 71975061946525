import { useContext } from 'react'

import { InterfaceContext } from './context'

export const useDropdown = () => {
  const context = useContext(InterfaceContext)
  if (!context) {
    throw new Error('useDropdown must be used within a DropdownProvider')
  }
  return context
}
