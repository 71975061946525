import { JSX } from 'react'

import { ButtonBack, Props as ButtonBackProps } from './variants/back.button'
import { ButtonBasic, Props as ButtonBasicProps } from './variants/basic.button'
import { ButtonDock } from './variants/dock.button'
import { FabButton } from './variants/fab.button'
import {
  ButtonLabelled,
  Props as ButtonLabelledProps,
} from './variants/labelled.button'
import { ButtonShape, Props as ButtonShapeProps } from './variants/shape.button'
import { ButtonTab, Props as ButtonTabProps } from './variants/tab.button'

type BasicButtonProps = {
  variant?: 'basic'
} & ButtonBasicProps

type TabButtonProps = {
  variant: 'tab'
} & ButtonTabProps

type BackButtonProps = {
  variant: 'back'
} & ButtonBackProps

type ShapeButtonProps = {
  variant: 'shape'
} & ButtonShapeProps

type LabelledButtonProps = {
  variant: 'labelled'
} & ButtonLabelledProps

type CoreButton = {
  variant: 'core'
  children: JSX.Element | JSX.Element[]
} & JSX.IntrinsicElements['button']

export type Props =
  | TabButtonProps
  | BasicButtonProps
  | BackButtonProps
  | ShapeButtonProps
  | LabelledButtonProps
  | CoreButton

export const Button = (props: Props) => {
  switch (props.variant) {
    case 'tab':
      return <ButtonTab {...props} />

    case 'back':
      return <ButtonBack {...props} />

    case 'shape':
      return <ButtonShape {...props} />

    case 'labelled':
      return <ButtonLabelled {...props} />

    case 'core':
      // This is not one that should be used but on the chance
      // the other button doesn't satisfy an edge-case we have this
      // available
      return <button {...props}>{props.children}</button>

    default:
      return <ButtonBasic {...props} />
  }
}

export default {
  Tab: ButtonTab,
  Back: ButtonBack,
  Shape: ButtonShape,
  Labelled: ButtonLabelled,
  Basic: ButtonBasic,
  Fab: FabButton,

  Dock: ButtonDock,
}
