import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// import { useSearchParams } from 'react-router-dom'
// import { Button } from '@/components/button'
// import { Chip } from '@/components/chip'
// import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { IOrganisation } from '@/types/organisation'

// import { useDropdown } from '@/contexts/interface'
// import { capitalizeFirstLetter } from '@/helpers/capitalizeFirstLetter'
// import { formatValue } from '@/helpers/formatValue'
import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

export type SortBy = 'az' | 'latest' | 'value'
export type GroupBy = 'all' | 'active' | 'archived'

export interface Props extends BaseTableProps {
  organizations: IOrganisation[]
  defaultGroupBy?: GroupBy
  defaultSortBy?: SortBy
  onEditOrganization: (organization: IOrganisation) => void
}

export const OrganizationTable: React.FC<Props> = (props: Props) => {
  const defaultSortBy: SortBy = 'latest'
  const defaultGroupBy: GroupBy = 'all'
  const { t } = useTranslation()
  // const { setDropdown } = useDropdown()
  const [_sortBy] = useState<SortBy>(defaultSortBy)
  const [_groupBy, setGroupBy] = useState<GroupBy>(defaultGroupBy)
  const [_orgs, setOrgs] = useState<IOrganisation[]>([])

  // Monitor for incoming changes to the raw cases data
  useEffect(() => {
    setOrgs([...sort(filter(group(props.organizations)))])
  }, [props.organizations])

  useEffect(() => {
    if (props.defaultGroupBy) {
      setGroupBy(props.defaultGroupBy)
    }
  }, [props.defaultGroupBy])

  // Monitor for sortBy changes + update
  useEffect(() => {
    setOrgs([...sort(_orgs)])
  }, [_sortBy])

  // Monitor for groupBy changes + update
  useEffect(() => {
    setOrgs([...sort(filter(group(props.organizations)))])
  }, [_groupBy])

  // Update our visibility and sort
  // const onGroupChanged = (id: GroupBy) => setGroupBy(id)
  // const onSortChanged = (id: SortBy) => setSortBy(id)

  // Sorts the provided data
  const sort = (orgs: IOrganisation[]): IOrganisation[] => {
    switch (_sortBy) {
      case 'latest':
        return orgs.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      case 'az':
        return orgs.sort((a, b) => a.name.localeCompare(b.name))
      // case 'value':
      //   return cases.sort(
      //     (a, b) =>
      //       b.total_price_of_digital_assets.value +
      //       b.total_price_of_physical_assets.value -
      //       (a.total_price_of_digital_assets.value +
      //         a.total_price_of_physical_assets.value)
      //   )
      default:
        return orgs
    }
  }

  // Group the provided data
  const group = (orgs: IOrganisation[]): IOrganisation[] => {
    // const groupStatuses = {
    //   all: ['PENDING', 'ACTIVE', 'ARCHIVED'],
    //   active: ['PENDING', 'ACTIVE'],
    //   archived: ['ARCHIVED'],
    // } as { [k in GroupBy]: Array<IOrganisationStatus> }
    // return orgs.filter((c) => groupStatuses[_groupBy].includes(c?.deleted))
    return orgs
  }

  const filter = (orgs: IOrganisation[]): IOrganisation[] => {
    return orgs
  }

  const renderRow = (row: IOrganisation, index: number) => {
    // Handle navigation
    // const handleNavigateToDetails = () => {
    //   navigate(
    //     ROUTES.ORGNIZATIION.DETAIL.replace(':org_id', row.id).replace(
    //       ':case_id',
    //       row.id
    //     )
    //   )
    // }

    // const handleShareCase = () =>
    //   setUrlParams({
    //     'share-case-modal': row?.id,
    //   })

    return (
      <Row
        key={`${row.id}_${index}`}
        // onClick={row.status === 'ACTIVE' ? handleNavigateToDetails : undefined}
        onClick={() => props.onEditOrganization(row)}
      >
        <Cell.Icon>
          <Icon
            name="buildings"
            variant={'solid'}
            family={'sharp'}
            size={'md'}
          />
        </Cell.Icon>
        <Cell.Text title={row.name} className={'max-w-[20rem]'}>
          {row.name}
        </Cell.Text>
        <Cell.Text>{row.client_number}</Cell.Text>
        <Cell.Text align="center">{row.country}</Cell.Text>
        {/* <Cell.Number>0</Cell.Number>
        <Cell.Number>0</Cell.Number>
        <Cell.Number>0</Cell.Number>
        <Cell.Number>
          0
        </Cell.Number>
        <Cell.Chip>
          <Chip
            // label={capitalizeFirstLetter(row.status)}
            // colour={
            //   row.status === 'ACTIVE'
            //     ? 'green'
            //     : row.status === 'ARCHIVED'
            //       ? 'red'
            //       : row.status === 'PENDING'
            //         ? 'yellow'
            //         : 'white'
            // }
            label="Active"
            colour="green"
          />
        </Cell.Chip> */}
        {/* <Cell.Button>
          {row?.shared && (
            <Button
              layout={'icon'}
              variant={'shape'}
              shape={'square'}
              size={'small'}
              hierarchy={'tertiary'}
              icon={{
                name: 'users',
              }}
            />
          )}
        </Cell.Button> */}
        {/* <Cell.Button>
          <Button
            id={`btn_case_ellip_drop_${row.id}`}
            layout={'icon'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'ellipsis',
            }}
            onClick={() => {
              setDropdown({
                target: `btn_case_ellip_drop_${row.id}`,
                controller: <Dropdown.Controllers.CaseActions case={row} />,
              })
            }}
          />
        </Cell.Button> */}
      </Row>
    )
  }

  return (
    <BaseTable<IOrganisation, null, null, null>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      // <BaseTable<IOrganisation, SortBy, null, GroupBy>
      // leftController={{
      //   tabs: {
      //     type: 'button',
      //     size: 'small',
      //     onSelectionChanged: onGroupChanged,
      //     selectedId: _groupBy,
      //     values: [
      //       {
      //         id: 'all',
      //         text: 'All',
      //       },
      //       {
      //         id: 'active',
      //         text: 'Active',
      //       },
      //       {
      //         id: 'archived',
      //         text: 'Archived',
      //       },
      //     ],
      //   },
      // }}
      // sort={{
      //   options: [
      //     {
      //       id: 'az',
      //       value: 'az',
      //       label: 'A-Z',
      //       icon: 'user-circle',
      //     },
      //     {
      //       id: 'latest',
      //       value: 'latest',
      //       label: t('latest'),
      //       icon: 'calendar',
      //       iconVariant: 'regular',
      //     },
      //     {
      //       id: 'value',
      //       value: 'value',
      //       label: t('value'),
      //       icon: 'coins',
      //       iconVariant: 'regular',
      //     },
      //   ],
      //   onSortByChanged: onSortChanged,
      //   defaultSelectedId: defaultSortBy,
      // }}
      headers={[
        {
          label: t('organization_name'),
          colSpan: 2,
        },
        {
          label: t('id'),
        },
        {
          label: t('location'),
          alignment: 'center',
        },
        // {
        //   label: t('users'),
        //   alignment: 'center',
        // },
        // {
        //   label: t('assets'),
        //   alignment: 'center',
        // },
        // {
        //   label: t('cases'),
        //   alignment: 'center',
        // },
        // {
        //   label: t('parties'),
        //   alignment: 'center',
        // },
        // {
        //   label: t('status'),
        //   alignment: 'center',
        // },
        // {
        //   label: '',
        // },
      ]}
      data={_orgs}
      onRenderRow={renderRow}
    />
  )
}
