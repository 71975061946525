export interface ActionResponse<T> {
  error?: boolean // Something not terrible has occured - form input error for example
  exception?: boolean // Something bad has happened - close the form if we're in one
  completion: 'COMPLETE' | 'COMPLETE_WITH_ERROR' | 'NOT_COMPLETE'
  message?: string // Possible message to deliver back to the user
  data?: T | null
}

export function IsActionResponse<T>(obj: unknown): obj is ActionResponse<T> {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    'completion' in obj &&
    ('error' in obj || 'exception' in obj || 'message' in obj || 'data' in obj)
  )
}
