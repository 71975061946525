import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  children: JSX.Element | null
}
const DrawerContent = (props: Props) => {
  const [_isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true)
    }, 1)
  }, [])

  return (
    <div
      //   ref={containerRef}
      className={twMerge(
        'flex flex-col w-full opacity-0',
        'transition-[opacity] duration-[200ms] ease-in-out',
        _isReady && 'opacity-100'
      )}
    >
      {props.children}
    </div>
  )
}
export default DrawerContent
