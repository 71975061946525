import { useTranslation } from 'react-i18next'

import Button from '@/components/button'
import BottomPadding from '@/components/footer/BottomPadding.tsx'
import { NoData } from '@/components/no-data'
import { Paragraph } from '@/components/paragraph'
import { Table } from '@/components/table'
import { useDrawer } from '@/contexts/interface'
import { useDefendants } from '@/hooks/queries/useDefendants'
import { IDefendant } from '@/types/defendants'

export const Subjects: React.FC = (): JSX.Element => {
  const { setDrawer } = useDrawer()
  const { defendants } = useDefendants()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col p-8 pt-4 desktop:px-8 desktop:py-4">
      <Paragraph
        title={t('subjects')}
        description={t('subject_description')}
        spacerOverrides={{ description: [] }}
      />

      <div className="flex gap-5 pt-8" data-testid="create-defendant">
        <Button.Basic
          label={t('add_new_subject')}
          testId={'btn_show_new_subject_drawer'}
          icon={{
            name: 'plus',
          }}
          onClick={() => {
            setDrawer({
              id: 'NEW_DEFENDANT',
            })
          }}
          className="hidden tablet:flex"
        />
      </div>

      {defendants?.length ? (
        <Table
          defendants={defendants}
          testId={'table_defendants'}
          onEditDefendant={(d: IDefendant) => {
            setDrawer({
              id: 'EDIT_DEFENDANT',
              defendant: d,
            })
          }}
        />
      ) : (
        <div className="flex min-h-[50vh]">
          <NoData description="no_subjects_description" />
        </div>
      )}

      <BottomPadding />

      <Button.Fab
        label={t('add_new_subject')}
        icon={{
          name: 'plus',
        }}
        onClick={() => {
          setDrawer({
            id: 'NEW_DEFENDANT',
          })
        }}
      />
    </div>
  )
}
