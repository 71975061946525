import { useQuery } from '@tanstack/react-query'

import { getAllRoles } from '@/api'
import { USER_ROLES } from '@/constants/auth.ts'
import { useOrg } from '@/hooks/queries/useOrg'

export const useRoles = () => {
  const { org } = useOrg()

  const { isLoading, data } = useQuery({
    queryKey: ['org-roles'],
    queryFn: () => getAllRoles(),
  })

  return {
    roles: (data?.data ?? [])
      .filter((r) => r.is_built_in)
      .filter((r) => {
        switch (r.name.toLowerCase()) {
          case USER_ROLES.SIGNER:
            return Boolean(org?.escrow_enabled)
          case USER_ROLES.APPROVER:
            return Boolean(org?.escrow_enabled)
          default:
            return true
        }
      })
      // the roles with more policies first
      .sort(
        (a, b) => (b.policy_ids ?? []).length - (a.policy_ids ?? []).length
      ),
    isLoading,
  }
}
