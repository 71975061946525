import axios, { RawAxiosRequestHeaders } from 'axios'
import Cookies from 'js-cookie'

import config_demo from '../config/demo'
import config_development from '../config/development'
import config_localdev from '../config/localdev'
import config_main from '../config/main'
import config_staging from '../config/staging'
import { Config } from '../contexts/config/types'

export const getConfig = (configName?: string): Config => {
  const value = configName || process.env.MODE || import.meta.env.MODE

  switch (value) {
    case 'main':
      return config_main
    case 'demo':
      return config_demo
    case 'staging':
      return config_staging
    case 'development':
      return config_development
    case 'localdev':
      return config_localdev
    default:
      return config_main
  }
}

export const getClient = async (
  baseURL: string,
  customToken?: string,
  headers?: RawAxiosRequestHeaders
) => {
  const token = Cookies.get('token')

  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'AR-Token': customToken || token,
      ...headers,
    },
  })
}
