import { t } from 'i18next'
import * as XLSX from 'xlsx'

import { importAssets } from '@/api'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryDigitalAssetsKey } from '@/hooks/queries/useDigitalAssets'
import { key as QueryPhysicalAssetsKey } from '@/hooks/queries/usePhysicalAssets'
import { ActionResponse } from '@/types/actions'

type AssetType =
  | 'digital'
  | 'vehicle'
  | 'real-estate'
  | 'high-value-personal-property'
  | 'fiat-cash'

export const ImportAssets = async (
  formData: FormData
): Promise<ActionResponse<boolean>> => {
  // Grab data from the form
  const workspace_id = formData.get('workspace_id')?.toString()
  const org_id = formData.get('org_id')?.toString()

  // Confirm we have the required workspace + org ids
  if (IsNot(workspace_id, REGEX.IS_UUID)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'workspace_id',
        error: t('something_went_wrong'),
      },
    ])
  } else if (IsNot(org_id, REGEX.IS_UUID)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'org_id',
        error: t('something_went_wrong'),
      },
    ])
  }

  // Grab the new files from the form and ignore any
  // files that are not valid
  const formFiles = [...formData.getAll('import_files')]?.filter((file) => {
    return file && file instanceof File && file.size !== 0
  }) as File[]

  // Check if we have any files to upload
  if (formFiles.length <= 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'import_files',
        error: t('no_new_files_attached'),
      },
    ])
  }

  // Run through each and check what type of files they are. They should be one of:
  // + Vehicle
  // + Digital
  // + High Value Property
  // + Real Estate
  // + Fiat Cash
  // Each type needs to be imported seperately using the appropriate /import endpoint.
  // Note that there could be multiple files of the same type.
  const validateFile = async (file: File): Promise<AssetType> => {
    const columns_digital = [
      'operation name',
      'defendant name',
      'external reference',
      'ticker',
      'network',
      'token type',
      'amount',
      'wallet address',
      'contract address',
      'custodian',
    ]
    const columns_vehicle = [
      'operation name',
      'defendant name',
      'external reference',
      'valuation',
      'valuation currency',
      'type',
      'make',
      'model',
      'year',
      'plate number',
      'color',
      'owner name',
      'chassis',
      'engine',
      'engine id',
      'vin',
      'mileage',
      'last status happened at',
      'last status',
    ]
    const columns_realestate = [
      'operation name',
      'defendant name',
      'external reference',
      'valuation',
      'valuation currency',
      'type',
      'owner name',
      'plot number',
      'area',
      'address',
      'last status happened at',
      'last status',
    ]
    const columns_highvaluepersonalproperty = [
      'operation name',
      'defendant name',
      'external reference',
      'valuation',
      'valuation currency',
      'type',
      'serial number',
      'quantity',
      'description',
      'last status happened at',
      'last status',
    ]
    const columns_fiatcash = [
      'operation name',
      'defendant name',
      'external reference',
      'amount',
      'currency',
      'condition',
      'last status happened at',
      'last status',
    ]
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0] // Get the first sheet
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) // Convert to 2D array

        const headers = (jsonData[0] ?? []) as string[] // Get the first row (headers)

        // Check for relevant column headers
        if (
          columns_digital.every((c) =>
            headers.map((h) => h.toLowerCase()).includes(c)
          )
        ) {
          resolve('digital')
        } else if (
          columns_vehicle.every((c) =>
            headers.map((h) => h.toLowerCase()).includes(c)
          )
        ) {
          resolve('vehicle')
        } else if (
          columns_fiatcash.every((c) =>
            headers.map((h) => h.toLowerCase()).includes(c)
          )
        ) {
          resolve('fiat-cash')
        } else if (
          columns_highvaluepersonalproperty.every((c) =>
            headers.map((h) => h.toLowerCase()).includes(c)
          )
        ) {
          resolve('high-value-personal-property')
        } else if (
          columns_realestate.every((c) =>
            headers.map((h) => h.toLowerCase()).includes(c)
          )
        ) {
          resolve('real-estate')
        } else {
          reject()
        }
      }

      reader.onerror = (error) => {
        console.error(`Error reading file ${file.name}:`, error)
        reject(error)
      }

      reader.readAsArrayBuffer(file)
    })
  }

  // First lets validate the files
  const filesWithType: { file: File; type: AssetType }[] = []
  const invalid_filenames: string[] = []
  for (const file of formFiles) {
    try {
      const type = await validateFile(file)
      filesWithType.push({
        file,
        type,
      })
    } catch {
      invalid_filenames.push(file.name)
    }
  }

  // Check if we have any files that are invalid
  if (invalid_filenames.length > 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'import_files',
        error: `The following files are invalid: ${invalid_filenames.join(', ')}`,
      },
    ])
  }

  // If we land here then all files have been validated and are OK for upload
  // (They'll get fully validated on the BE)
  const succeeded: { file: File; type: AssetType }[] = []
  const failed: { file: File; type: AssetType }[] = []
  for (const obj of filesWithType) {
    try {
      const import_res = await importAssets(
        obj.type,
        obj.file,
        org_id,
        workspace_id
      )
      if (import_res.error) {
        failed.push(obj)
      } else {
        succeeded.push(obj)
      }
    } catch {
      failed.push(obj)
    }
  }

  // Update cache
  await queryClient.invalidateQueries({ queryKey: [QueryPhysicalAssetsKey] })
  await queryClient.invalidateQueries({ queryKey: [QueryDigitalAssetsKey] })

  // Check if we have any failed
  if (failed.length > 0 && succeeded.length > 0) {
    return {
      error: false,
      message: `Some of your imports failed, ${succeeded.length} succeeded. Failed imports: ${failed.map((f) => f.file.name).join(', ')}.`,
      completion: 'COMPLETE_WITH_ERROR',
      exception: true,
    }
  } else if (failed.length > 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'import_files',
        error: `All imports failed. Please confirm the structure of your files.`,
      },
    ])
  } else {
    return {
      error: false,
      message: `Assets imported successfully.`,
      completion: 'COMPLETE',
      data: true,
    }
  }
}
