import { CustomCellRendererProps } from 'ag-grid-react'

import { Typography } from '@/components/typography'
import { dateToString } from '@/helpers/dateToString'

interface Props extends CustomCellRendererProps {
  type: 'header' | 'text' | 'date-short'
}

export const Cell = (props: Props) => {
  return (
    <Typography
      variant={props.type === 'header' ? 'label-small' : 'paragraph-small'}
      tooltip={props.type === 'date-short' ? props.value : undefined}
    >
      {props.type === 'date-short' ? dateToString(props.value) : props.value}
    </Typography>
  )
}
export default Cell
