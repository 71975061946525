import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { INTENTS } from '@/actions/intents'
import Button from '@/components/button'
import File from '@/components/file'
import { allAllowedFileTypes } from '@/components/file/upload'
import { Form } from '@/components/form'
import Input from '@/components/input'
import { BannerManager } from '@/contexts/banner'
import { ToastManager } from '@/contexts/toast'
import { ActionResponse } from '@/types/actions'

import { Drawer } from '../..'
import { BaseProps } from '../types'

export interface Props extends BaseProps {}
export const NewCaseDrawer = (props: Props) => {
  const navigate = useNavigate()
  const [_submitting, setSubmitting] = useState<INTENTS | null>(null)

  // Clear the submitting flag
  const clearSubmitting = () => setSubmitting(null)

  // On form submission
  const onSubmit = (intent: INTENTS) => setSubmitting(intent)

  // On form exception
  const onException = (actionRes: ActionResponse<string>) => {
    BannerManager.showBanner({
      variant: 'page',
      type: 'error',
      title: actionRes.message ?? t('something_went_wrong'),
    })
    props.onClose()
  }

  // On form submit successfully
  const onSuccess = (actionRes: ActionResponse<string>) => {
    const newCaseUrl = `/cases/${actionRes.data}`
    ToastManager.showToast({
      type: 'success',
      text: actionRes.message ?? t('action_successful'),
    })
    navigate(newCaseUrl)
    props.onClose()
  }

  return (
    <Drawer
      title={t('new_case_drawer_title')}
      description={t('new_case_drawer_subtitle')}
      visible={props.visible}
      onClose={props.onClose}
    >
      <Form<string, INTENTS>
        type={'other'}
        testId={'create-case-form'}
        buttons={[
          <Button.Basic
            key={'btn_create_case'}
            testId={'btn_create_case'}
            label={t('create_case')}
            state={
              _submitting === INTENTS.CREATE_CASE
                ? 'loading'
                : _submitting !== null
                  ? 'disabled'
                  : 'default'
            }
            withAttributes={{
              type: 'submit',
              name: 'intent',
              value: INTENTS.CREATE_CASE,
            }}
          />,
        ]}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onException={onException}
        onFailed={clearSubmitting}
        onUnknownResponse={clearSubmitting}
        containerClassName={'gap-[48px]'}
      >
        <Input.Text
          id="case_name"
          testId="txt_case_name"
          name="case_name"
          label={t('case_name')}
          autoComplete={'off'}
          hint={t('case_name_bottom_text')}
          placeholder={t('case_name_placeholder')}
        />
        <File.Upload
          id="file-upload"
          innerText={t('add_your_files_here')}
          name="case_files"
          maxFilesAllowed={10}
          allowedFileTypes={allAllowedFileTypes}
          showPreview={false}
          showFileList={true}
          showQuantitySelected={false}
        />
      </Form>
    </Drawer>
  )
}
