import { t } from 'i18next'
import { LoaderFunction } from 'react-router-dom'

import { ErrorHandler } from '@/errors/error.handler'
import { ExtractErrorFrom } from '@/helpers/extractErrorFrom'
import { ExtractStatusFrom } from '@/helpers/extractStatusFrom'

export interface LoaderData {
  token: string
}
export const loader: LoaderFunction = async ({ request }) => {
  try {
    const token = new URL(request.url).searchParams.get('token')
    if (!token) return ErrorHandler(404, t('missing_params'))

    return {
      token,
    } satisfies LoaderData
  } catch (error: unknown) {
    const message = ExtractErrorFrom(error) ?? t('something_went_wrong')
    return ErrorHandler(ExtractStatusFrom(error, 500), message)
  }
}
