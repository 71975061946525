import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/button'
import { Paragraph } from '@/components/paragraph'
import { useWindowSize } from '@/hooks/useWindowSize'

import { CardBadge, Props as CardBadgeProps } from '../components/badge.card'

export interface Props {
  type:
    | 'question'
    | 'success'
    | 'information'
    | 'warning'
    | 'error'
    | 'loading'
    | 'declined'
    | 'no_table_data'
    | 'no_tokens'
  title?: string
  color?: 'black' | 'white'
  subTitle?: string
  description?: string | ReactNode
  button?: {
    label: string
    onClick: () => void
  }
  className?: string
}
export const CardPageState = (props: Props) => {
  const { isMobile } = useWindowSize()
  const CardBadgeTypes: { [type in Props['type']]: CardBadgeProps['type'] } = {
    question: 'help',
    success: 'success',
    information: 'message',
    warning: 'warning',
    error: 'error',
    loading: 'loading',
    declined: 'declined',
    no_table_data: 'table',
    no_tokens: 'token',
  }

  return (
    <div
      className={twMerge('flex flex-col w-full items-center', props.className)}
      data-testid={props.type === 'loading' ? 'loader' : ''}
    >
      <div
        className={twMerge(
          'flex flex-col items-center w-full max-w-[23.4375rem] p-[2.25rem]',
          props.color === 'white' ? 'text-white' : 'text-black'
        )}
      >
        <CardBadge
          type={CardBadgeTypes[props.type]}
          size={isMobile ? 'medium' : 'large'}
          paddingBottom={!isMobile}
        />
        <Paragraph
          title={props.title}
          subTitle={props.subTitle}
          description={props.description}
          classOverrides={{
            title: twMerge(
              'self-center items-center text-center',
              props.color === 'white' ? 'text-white' : 'text-black'
            ),
            subTitle: twMerge(
              'self-center items-center text-center',
              props.color === 'white' ? 'text-white' : 'text-black'
            ),
            description: twMerge(
              'self-center items-center text-center',
              props.color === 'white' ? 'text-white' : 'text-black'
            ),
          }}
        />
        {props.button && (
          <Button
            hierarchy={'secondary'}
            size={'medium'}
            width={'intrinsic'}
            label={props.button.label}
            onClick={props.button.onClick}
            className={'self-center'}
          />
        )}
      </div>
    </div>
  )
}
