import * as Sentry from '@sentry/react'
import { t } from 'i18next'
import { createBrowserRouter } from 'react-router-dom'

import { Action } from '@/actions'
import { ROUTES } from '@/constants/routes'
import { CustodianDetail } from '@/screens/admin/custodians/detail'
import { loader as custodianDetailLoader } from '@/screens/admin/custodians/detail/loaders'
import { Custodian } from '@/screens/admin/custodians/index'
import { loader as custodianLoader } from '@/screens/admin/custodians/index/loader'
import { AdminEscrowDetail } from '@/screens/admin/escrows/detail'
import { loader as AdminEscrowDetailLoader } from '@/screens/admin/escrows/detail/loader'
import { AdminEscrows } from '@/screens/admin/escrows/index'
import { loader as AdminEscrowsLoader } from '@/screens/admin/escrows/index/loader'
import { Organization } from '@/screens/admin/organization/index'
import { loader as organizationLoader } from '@/screens/admin/organization/index/loader'
import { SuperAdminTeamManagement } from '@/screens/admin/team-management/index'
import { loader as superAdminTeamManagementLoader } from '@/screens/admin/team-management/index/loader'
import { Agreement } from '@/screens/agreement/agreement'
import { AssetDetail } from '@/screens/assets/detail'
import { loader as assetDetailLoader } from '@/screens/assets/detail/loader'
import { Assets } from '@/screens/assets/index'
import { loader as assetsLoader } from '@/screens/assets/index/loader'
import { Auth } from '@/screens/auth'
import { loader as authLoader } from '@/screens/auth/loader'
import { ResetPassword } from '@/screens/auth/reset-password'
import { loader as resetPasswordLoader } from '@/screens/auth/reset-password/loader'
import { TwoFactor } from '@/screens/auth/two-factor'
import { Workspace } from '@/screens/auth/workspace'
import { CaseDetail } from '@/screens/cases/detail/index'
import { loader as caseDetailLoader } from '@/screens/cases/detail/loader'
import { Cases } from '@/screens/cases/index'
import { loader as casesLoader } from '@/screens/cases/index/loader'
import { ErrorPage } from '@/screens/error-page'
import { EscrowDetailPage } from '@/screens/escrows/detail'
import { loader as escrowDetailLoader } from '@/screens/escrows/detail/loader'
import { Escrows } from '@/screens/escrows/index'
import { loader as escrowsLoader } from '@/screens/escrows/index/loader'
import { HealthDetail } from '@/screens/health/details'
import { loader as healthDetailLoader } from '@/screens/health/details/loader'
import { Health } from '@/screens/health/index/index'
import { loader as healthLoader } from '@/screens/health/index/loader'
import { Profile } from '@/screens/profile/index'
import { loader as profileLoader } from '@/screens/profile/index/loader'
import { SetNewPassword } from '@/screens/profile/set-new-password'
import { action as registerAction, Register } from '@/screens/register'
import { Reports } from '@/screens/reports/reports'
import { Shares } from '@/screens/shares'
import { loader as SharesLoader } from '@/screens/shares/loader'
import { SubjectDetail } from '@/screens/subjects/detail'
import { loader as subjectDetailLoader } from '@/screens/subjects/detail/loader'
import { Subjects } from '@/screens/subjects/index'
import { loader as subjectLoader } from '@/screens/subjects/index/loader'
import { TeamManagement } from '@/screens/team-management'
import { loader as teamManagementLoader } from '@/screens/team-management/loader'
import { Transfers } from '@/screens/transfers'
import { loader as TransfersLoader } from '@/screens/transfers/loader'

import Guard from './components/guard'
import Screen from './components/screen'

const errorUnauthenticatedPage = (
  <Guard access={'BOTH'} showNavigation={true}>
    <ErrorPage />
  </Guard>
)

const sentryCreateBrowserRouter =
  process.env.NODE_ENV !== 'development'
    ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
    : createBrowserRouter

const router = sentryCreateBrowserRouter([
  {
    path: ROUTES.AUTH.INDEX,
    loader: authLoader,
    action: Action,
    element: (
      <Guard access={'UNAUTHENTICATED_ONLY'}>
        <Auth />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.REGISTER,
    action: registerAction,
    element: (
      <Guard access={'UNAUTHENTICATED_ONLY'}>
        <Register />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.AUTH.RESET_PASSWORD,
    loader: resetPasswordLoader,
    action: Action,
    element: (
      <Guard access={'UNAUTHENTICATED_ONLY'}>
        <ResetPassword />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.AUTH.TWO_FACTOR,
    action: Action,
    element: (
      <Guard access={'UNAUTHENTICATED_ONLY'}>
        <TwoFactor />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.AUTH.WORKSPACE,
    action: Action,
    element: (
      <Guard access={'AUTHENTICATED_ONLY'}>
        <Workspace />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.SHARES,
    loader: SharesLoader,
    element: (
      <Guard access={'BOTH'}>
        <Shares />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.TRANSFERS,
    loader: TransfersLoader,
    element: (
      <Guard access={'BOTH'}>
        <Transfers />
      </Guard>
    ),
    errorElement: errorUnauthenticatedPage,
  },
  {
    path: ROUTES.AGREEMENT,
    element: <Agreement />,
  },
  {
    path: '/',
    action: Action,
    element: (
      <Guard access={'AUTHENTICATED_ONLY'} showNavigation={true}>
        <Screen />
      </Guard>
    ),
    errorElement: (
      <Guard access={'AUTHENTICATED_ONLY'} showNavigation={true}>
        <ErrorPage />
      </Guard>
    ),
    children: [
      {
        path: ROUTES.TEAM_MANAGEMENT,
        children: [
          {
            index: true,
            loader: teamManagementLoader,
            element: <TeamManagement />,
          },
        ],
      },
      {
        path: ROUTES.ASSETS.INDEX,
        children: [
          {
            index: true,
            loader: assetsLoader,
            action: Action,
            element: <Assets />,
          },
          {
            path: ROUTES.ASSETS.DETAIL,
            loader: assetDetailLoader,
            action: Action,
            element: <AssetDetail />,
          },
        ],
      },
      {
        path: ROUTES.CASES.INDEX,
        children: [
          {
            index: true,
            loader: casesLoader,
            action: Action,
            element: <Cases />,
          },
          {
            path: ROUTES.CASES.DETAIL,
            loader: caseDetailLoader,
            action: Action,
            element: <CaseDetail />,
          },
        ],
      },
      {
        path: ROUTES.SUBJECT.INDEX,
        children: [
          {
            index: true,
            loader: subjectLoader,
            action: Action,
            element: <Subjects />,
          },
          {
            path: ROUTES.SUBJECT.DETAIL,
            loader: subjectDetailLoader,
            action: Action,
            element: <SubjectDetail />,
          },
        ],
      },
      {
        path: ROUTES.PROFILE.INDEX,
        children: [
          {
            index: true,
            loader: profileLoader,
            action: Action,
            element: <Profile />,
          },
          {
            path: ROUTES.PROFILE.SET_NEW_PASSWORD,
            loader: profileLoader,
            action: Action,
            element: <SetNewPassword />,
          },
        ],
      },
      {
        path: ROUTES.ESCROW.INDEX,
        children: [
          {
            index: true,
            loader: escrowsLoader,
            element: <Escrows />,
          },
          {
            path: ROUTES.ESCROW.DETAIL,
            loader: escrowDetailLoader,
            element: <EscrowDetailPage />,
          },
        ],
      },
      // Health page
      {
        path: ROUTES.HEALTH.INDEX,
        children: [
          {
            index: true,
            loader: healthLoader,
            action: Action,
            element: <Health />,
          },
          {
            path: ROUTES.HEALTH.DETAIL,
            loader: healthDetailLoader,
            action: Action,
            element: <HealthDetail />,
          },
        ],
      },
      {
        path: ROUTES.REPORTS,
        element: <Reports />,
      },
      //////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////// super admin //////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////
      {
        path: ROUTES.ADMIN.ORGANIZATION.INDEX,
        children: [
          {
            index: true,
            loader: organizationLoader,
            action: Action,
            element: <Organization />,
          },
          {
            path: ROUTES.ADMIN.ORGANIZATION.DETAIL,
            // loader: organizationDetailLoader,
            action: Action,
            // element: <OrganizationDetail />,
          },
        ],
      },
      {
        path: ROUTES.ADMIN.CUSTODIAN.INDEX,
        children: [
          {
            index: true,
            loader: custodianLoader,
            action: Action,
            element: <Custodian />,
          },
          {
            path: ROUTES.ADMIN.CUSTODIAN.DETAIL,
            loader: custodianDetailLoader,
            action: Action,
            element: <CustodianDetail />,
          },
        ],
      },
      {
        path: ROUTES.ADMIN.ESCROW.INDEX,
        children: [
          {
            index: true,
            loader: AdminEscrowsLoader,
            action: Action,
            element: <AdminEscrows />,
          },
          {
            path: ROUTES.ADMIN.ESCROW.DETAIL,
            loader: AdminEscrowDetailLoader,
            action: Action,
            element: <AdminEscrowDetail />,
          },
        ],
      },
      {
        path: ROUTES.ADMIN.TEAM_MANAGEMENT.ORG,
        children: [
          {
            index: true,
            loader: superAdminTeamManagementLoader,
            element: <SuperAdminTeamManagement />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    // loader: authLoader,
    element: (
      <Guard access={'AUTHENTICATED_ONLY'} showNavigation={true}>
        <ErrorPage statusCode={404} statusText={t('page_not_found')} />
      </Guard>
    ),
  },
])

export default router
