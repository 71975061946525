import { twMerge } from 'tailwind-merge'

import { Paragraph } from '@/components/paragraph'
import { Currency } from '@/constants/currencies.ts'
import { formatValue } from '@/helpers/formatValue'

interface Props {
  label: string
  currency?: Currency
  htmlColor?: string
  textClassName?: string
  value?: number
}
export const LegendKey = (props: Props) => {
  return (
    <div className={'flex flex-row items-start'}>
      <div className={'flex items-start pt-[3px] pr-[8px]'}>
        <div
          className={twMerge('w-[16px] h-[16px] rounded-[2px] bg-black')}
          style={{
            backgroundColor: props.htmlColor,
          }}
        ></div>
      </div>
      <Paragraph
        variant="subtext"
        subtitle={props.label}
        description={
          props.value !== undefined
            ? formatValue(props.value, props.currency)
            : undefined
        }
        classOverrides={{
          subtitle: twMerge('p-0', props.textClassName),
        }}
      />
    </div>
  )
}
