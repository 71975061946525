import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/button'
import Chip from '@/components/chip'
import Dropdown from '@/components/dropdown'
import { Icon } from '@/components/icon'
import { ROUTES } from '@/constants/routes'
import { useDropdown } from '@/contexts/interface'
import { ICustodian } from '@/types/custodian'

import Cell from '../components/cell.table'
import Row from '../components/row.table'
import { BaseTableProps } from '../types'
import { BaseTable } from './base.table'

export type SortBy = 'az' | 'latest' | 'value'
export type GroupBy = 'all' | 'active' | 'archived'

export interface Props extends BaseTableProps {
  custodians: ICustodian[]
  defaultGroupBy?: GroupBy
  defaultSortBy?: SortBy
}

export const CustodianTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setDropdown } = useDropdown()

  const renderRow = (row: ICustodian, index: number) => {
    const networks =
      row.supported_assets && row.supported_assets.length > 0
        ? [
            ...new Set(
              row.supported_assets.map((_assets) => _assets['network'])
            ),
          ]
        : []

    // Handle navigation
    const handleNavigateToDetails = () => {
      navigate(
        ROUTES.ADMIN.CUSTODIAN.DETAIL.replace(':custodian_id', row.id).replace(
          ':custodian_id',
          row.id
        )
      )
    }

    return (
      <Row key={`${row.id}_${index}`} onClick={handleNavigateToDetails}>
        <Cell.Icon>
          <Icon name="shield" variant={'solid'} family={'sharp'} size={'md'} />
        </Cell.Icon>
        <Cell.Text title={row.name} className={'max-w-[20rem]'}>
          {row.name}
        </Cell.Text>
        <Cell.Number>{row?.supported_assets?.length ?? 0}</Cell.Number>
        <Cell.Number>{networks.length}</Cell.Number>
        <Cell.Number>{0}</Cell.Number>
        <Cell.Chip>
          <Chip.Status status={row.status || 'active'} size={'medium'} />
        </Cell.Chip>
        <Cell.Button>
          <Button
            id={`btn_custodian_ellip_drop_${row.id}`}
            layout={'icon'}
            variant={'shape'}
            shape={'square'}
            size={'small'}
            hierarchy={'tertiary'}
            icon={{
              name: 'ellipsis',
            }}
            onClick={() => {
              setDropdown({
                target: `btn_custodian_ellip_drop_${row.id}`,
                controller: (
                  <Dropdown.Controllers.CustodianAction custodian={row} />
                ),
                maxWidthPx: 270,
              })
            }}
          />
        </Cell.Button>
      </Row>
    )
  }

  return (
    <BaseTable<ICustodian, null, null, null>
      testId={props.testId}
      showLeftController={props.showLeftController}
      showFilters={props.showFilters}
      showSort={props.showSort}
      headers={[
        {
          label: t('name'),
          colSpan: 2,
        },
        {
          label: t('assets'),
          alignment: 'center',
        },
        {
          label: t('networks'),
          alignment: 'center',
        },
        {
          label: t('address'),
          alignment: 'center',
        },
        {
          label: t('status'),
          alignment: 'center',
        },
        {
          label: '',
        },
      ]}
      data={props.custodians}
      onRenderRow={renderRow}
    />
  )
}
