import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import { MouseEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { downloadFile, getFileMetadata } from '@/api/files'
import { Avatar } from '@/components/avatar'
import Button from '@/components/button'
import Chip from '@/components/chip'
import { Typography } from '@/components/typography'
import { useAuth } from '@/contexts/auth'
import { useModal } from '@/contexts/interface'
import { getFileIcon } from '@/helpers/getFileIcon'
import { useOrgUsers } from '@/hooks/queries/useOrgUsers'
import { IEscrow, IEscrowFee, IEscrowFile } from '@/types/escrows'
import { IFile } from '@/types/file'

import Table from '..'
import styles from '../aggrid.module.css'

export interface Props {
  data: IEscrow
  mode: 'setup' | 'approval'
  testId?: string
}

export const EscrowFeesTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { users: orgUsers } = useOrgUsers(user?.organization_id ?? '')
  const { setModal } = useModal()
  const [_downloadingFileIds, setDownloadingFileIds] = useState<string[]>([])

  const onDownloadFile = async (file?: IFile | IEscrowFile) => {
    if (file && !_downloadingFileIds.includes(file.key)) {
      try {
        // Set the downloading flag
        setDownloadingFileIds([..._downloadingFileIds, file.key])

        // Fetch the file metadata if not available
        if (!file.metadata || !file.metadata.url) {
          const metadata = await getFileMetadata(file.key)
          file.metadata = metadata[0]
        }

        // Download the file
        setTimeout(async () => {
          await downloadFile(file)
        }, 200)
      } catch (e) {
        console.error(e)
      } finally {
        setDownloadingFileIds(_downloadingFileIds.filter((i) => i !== file.key))
      }
    }
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    }),
    []
  )

  const colDefs = useMemo<ColDef<IEscrowFee>[]>(
    () => [
      {
        headerName: t('invoice'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrowFee>) => {
          if (cellProps.data && cellProps.data.files.length > 0) {
            return (
              <div className={'flex flex-row gap-[16px]'}>
                <Avatar
                  type={'icon'}
                  shape={'square'}
                  size={'medium'}
                  name={
                    (cellProps.data?.files ?? []).length > 0
                      ? getFileIcon(cellProps.data!.files[0])
                      : ''
                  }
                  family={'sharp'}
                  iconVariant={'solid'}
                  iconClassName="text-black"
                />
                <div className={'flex flex-col gap-[4px] justify-center'}>
                  <Typography variant={'label-small'}>
                    {cellProps.data.files[0].name}
                  </Typography>
                </div>
              </div>
            )
          } else {
            return Table.AgGrid.Cell({
              ...cellProps,
              value: '-',
              type: 'header',
            })
          }
        },
        cellRendererParams: {
          type: 'header',
        },
        flex: 1,
        minWidth: 250,
      },
      // {
      //   field: 'platform_id',
      //   headerName: t('ar_reference'),
      //   cellRenderer: Table.AgGrid.Cell,
      //   cellRendererParams: {
      //     type: 'text',
      //   },
      //   width: 180,
      //   minWidth: 180,
      // },
      {
        field: 'created_at',
        headerName: t('created'),
        cellRenderer: Table.AgGrid.Cell,
        cellRendererParams: {
          type: 'date-short',
        },
        width: 180,
        minWidth: 180,
      },
      ...(props.mode === 'setup'
        ? [
            {
              headerName: t('uploaded_by'),
              cellRenderer: (
                cellProps: CustomCellRendererProps<IEscrowFee>
              ) => {
                const username = orgUsers?.find(
                  (u) => u.id === cellProps.data?.created_by.user_id
                )?.name
                if (username) {
                  return (
                    <div className={'flex flex-row gap-[16px]'}>
                      <Avatar
                        type={'monogram'}
                        shape={'round'}
                        size={'small'}
                        value={username}
                      />
                      <div className={'flex flex-col gap-[4px] justify-center'}>
                        <Typography variant={'label-small'}>
                          {username}
                        </Typography>
                      </div>
                    </div>
                  )
                } else {
                  return Table.AgGrid.Cell({
                    ...cellProps,
                    value: '-',
                    type: 'text',
                  })
                }
              },
              width: 180,
              minWidth: 180,
            },
          ]
        : []),
      {
        headerName: t('status'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrowFee>) => {
          const feeStatus = cellProps.data?.status?.at(-1)?.name
          if (!feeStatus) {
            return Table.AgGrid.Cell({
              ...cellProps,
              value: '',
              type: 'text',
            })
          } else {
            return (
              <Chip.Status
                size={'medium'}
                context={'ESCROW_FEE'}
                status={feeStatus}
              />
            )
          }
        },
        width: 200,
        minWidth: 200,
      },
      {
        headerName: t('file'),
        cellRenderer: (cellProps: CustomCellRendererProps<IEscrowFee>) => {
          if (
            cellProps.data?.files === undefined ||
            cellProps.data?.files.length <= 0
          ) {
            return Table.AgGrid.Cell({ ...cellProps, value: '-', type: 'text' })
          } else {
            return (
              <Button.Shape
                layout={'icon'}
                hierarchy={'tertiary'}
                shape={'square'}
                size={'small'}
                icon={{ name: 'arrow-down-to-bracket' }}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  onDownloadFile(cellProps.data?.files[0])
                }}
              />
            )
          }
        },

        width: 100,
        minWidth: 100,
      },
    ],
    [t, orgUsers]
  )

  return (
    <div
      className={`ag-theme-quartz ${styles.grid} ${styles.no_cell_selection}`}
      style={{ position: 'relative' }}
      data-testid={props.testId}
    >
      <AgGridReact
        suppressContextMenu
        autoSizeStrategy={{
          type: 'fitGridWidth',
        }}
        gridOptions={{
          domLayout: 'autoHeight',
          rowHeight: 72,
        }}
        rowData={props.data.fees}
        onRowClicked={(e: RowClickedEvent<IEscrowFee>) => {
          if (!e.event?.defaultPrevented) {
            setModal({
              id: 'ESCROW_FEE',
              mode:
                props.mode === 'setup' &&
                e.data?.status.at(-1)?.name === 'PENDING'
                  ? 'setup'
                  : props.mode === 'approval' &&
                      e.data?.status.at(-1)?.name === 'AWAITING_APPROVAL'
                    ? 'approval'
                    : 'readonly',
              escrow: props.data,
              fee: e.data,
            })
          }
        }}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
      />
    </div>
  )
}
