import { MouseEvent, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon, IconProps } from '@/components/icon'
import { Typography } from '@/components/typography'

export type Hierarchy =
  | 'special'
  | 'primary'
  | 'primary-inverted'
  | 'secondary'
  | 'secondary-inverted'
  | 'tertiary'
  | 'tertiary-inverted'
  | 'destructive'
  | 'destructive-inverted'
export type Size = 'large' | 'medium' | 'small'
export type Width = 'intrinsic' | 'flex' | number // << in REM
export type ButtonState = 'default' | 'disabled' | 'loading'

export interface Props {
  id?: string
  label: string
  hierarchy?: Hierarchy
  size?: Size
  width?: Width
  state?: ButtonState
  icon?: IconProps
  trailingIcon?: IconProps
  includePadding?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void

  // Additional props + overwrites
  testId?: string
  className?: string
  withAttributes?: JSX.IntrinsicElements['button']
}

export const ButtonBasic = (props: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const buttonWidth = useRef<number>()
  const previousState = useRef<Props['state']>(props.state)
  const [_minWidth, setMinWidth] = useState<number | undefined>(undefined)

  // Set some defaults
  props = {
    hierarchy: 'primary',
    size: 'medium',
    width: 'intrinsic',
    state: 'default',
    includePadding: true,
    ...props,
  }

  // We need to grab the button width from the component
  // when we have the reference available
  useEffect(() => {
    if (buttonRef) {
      buttonWidth.current = buttonRef.current?.getBoundingClientRect().width
    }
  }, [buttonRef])

  useEffect(() => {
    /*
      If our previous state was not loading and we are now
      loading then we need to match a min width of what
      the button width was previously. Otherwise the button
      will shrink each time we swap to the loader state!
    */
    if (
      buttonWidth &&
      props.width !== 'flex' &&
      previousState.current !== 'loading' &&
      props.state === 'loading'
    ) {
      setMinWidth(buttonWidth.current)
    }
    previousState.current = props.state
  }, [props.state])

  // Background Styles
  const backgroundStyle = twMerge(
    props.state === 'disabled'
      ? props.hierarchy === 'special'
        ? 'bg-[#E1E1E2]'
        : props.hierarchy === 'primary'
          ? 'bg-[#E1E1E2]'
          : props.hierarchy === 'primary-inverted'
            ? 'bg-[#E1E1E2]'
            : props.hierarchy === 'secondary'
              ? 'bg-transparent border-[1px] border-solid border-[#E1E1E2]'
              : props.hierarchy === 'secondary-inverted'
                ? 'bg-transparent border-[1px] border-solid border-[#E1E1E2]'
                : props.hierarchy === 'tertiary' ||
                    props.hierarchy === 'tertiary-inverted'
                  ? 'bg-transparent'
                  : 'bg-transparent border-[0.125rem] border-solid border-[#E1E1E2]'
      : props.state === 'loading'
        ? props.hierarchy === 'special'
          ? 'bg-red-500'
          : props.hierarchy === 'primary'
            ? 'bg-black'
            : props.hierarchy === 'primary-inverted'
              ? 'bg-white'
              : props.hierarchy === 'secondary'
                ? 'bg-transparent border-[1px] border-solid border-[#E1E1E2]'
                : props.hierarchy === 'secondary-inverted'
                  ? 'bg-transparent border-[1px] border-solid border-white'
                  : props.hierarchy === 'tertiary' ||
                      props.hierarchy === 'tertiary-inverted'
                    ? 'bg-transparent'
                    : 'bg-transparent border-[0.125rem] border-solid border-[#E1E1E2]'
        : props.hierarchy === 'special'
          ? 'bg-red-500 active:bg-[#E1E1E2] [&[data-dropdownopen=true]]:bg-[#E1E1E2]'
          : props.hierarchy === 'primary'
            ? 'bg-black active:bg-[#E1E1E2] [&[data-dropdownopen=true]]:bg-[#E1E1E2]'
            : props.hierarchy === 'primary-inverted'
              ? 'bg-white active:bg-[#E1E1E2] [&[data-dropdownopen=true]]:bg-[#E1E1E2]'
              : props.hierarchy === 'secondary'
                ? 'bg-transparent border-[1px] border-solid border-black hover:border-[#E1E1E2] active:bg-gray-100 active:border-black [&[data-dropdownopen=true]]:bg-gray-100 [&[data-dropdownopen=true]]:border-black'
                : props.hierarchy === 'secondary-inverted'
                  ? 'bg-transparent border-[1px] border-solid border-[#585B5F] hover:border-[#E1E1E2] active:bg-gray-100 active:border-white [&[data-dropdownopen=true]]:bg-gray-100 [&[data-dropdownopen=true]]:border-white'
                  : props.hierarchy === 'tertiary'
                    ? 'bg-transparent active:bg-[#E1E1E2] [&[data-dropdownopen=true]]:bg-[#E1E1E2] hover:bg-gray-100'
                    : props.hierarchy === 'tertiary-inverted'
                      ? 'bg-transparent border-[1px] border-solid border-transparent hover:border-white'
                      : 'bg-transparent border-[0.125rem] border-solid border-red-500 active:bg-red-100 [&[data-dropdownopen=true]]:bg-red-100'
  )

  // Foreground/text styles
  const textStyle = twMerge(
    props.state === 'disabled'
      ? 'text-gray-400'
      : props.state === 'loading'
        ? props.hierarchy === 'special'
          ? 'text-white'
          : props.hierarchy === 'primary'
            ? 'text-white'
            : props.hierarchy === 'primary-inverted'
              ? 'text-black'
              : props.hierarchy === 'secondary'
                ? 'text-black'
                : props.hierarchy === 'secondary-inverted'
                  ? 'text-white'
                  : props.hierarchy === 'tertiary'
                    ? 'text-black'
                    : props.hierarchy === 'tertiary-inverted'
                      ? 'text-white'
                      : 'text-black'
        : props.hierarchy === 'special'
          ? 'text-white group-active/button:text-black [&[data-dropdownopen=true]]:text-black'
          : props.hierarchy === 'primary'
            ? 'text-white group-active/button:text-black [&[data-dropdownopen=true]]:text-black'
            : props.hierarchy === 'primary-inverted'
              ? 'text-black'
              : props.hierarchy === 'secondary'
                ? 'text-black'
                : props.hierarchy === 'secondary-inverted'
                  ? 'text-white group-active/button:text-black [&[data-dropdownopen=true]]:text-black'
                  : props.hierarchy === 'tertiary'
                    ? 'text-black'
                    : props.hierarchy === 'tertiary-inverted'
                      ? 'text-white'
                      : props.hierarchy === 'destructive-inverted'
                        ? 'text-white'
                        : 'text-black'
  )

  const renderIcon = (icon?: IconProps) => {
    if (icon === undefined) {
      return null
    } else {
      return (
        <span
          className={twMerge(
            'flex justify-center items-center',
            props.size === 'small'
              ? 'min-w-[1.5rem] max-w-[1.5rem] min-h-[1.5rem] max-h-[1.5rem]'
              : 'min-w-[2rem] max-w-[2rem] min-h-[2rem] max-h-[2rem]'
          )}
        >
          <Icon
            name={icon.name}
            variant={icon.variant ?? 'solid'}
            family={icon.family ?? 'sharp'}
            size={props.size === 'small' ? 'small' : 'medium'}
            className={twMerge(
              textStyle,
              (props.hierarchy === 'destructive' ||
                props.hierarchy === 'destructive-inverted') &&
                props.state === 'default' &&
                'text-red-500'
            )}
          />
        </span>
      )
    }
  }

  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      e.stopPropagation()
      props.onClick(e)
    }
  }

  return (
    <button
      ref={buttonRef}
      id={props.id}
      aria-label={props.label}
      className={twMerge(
        'relative tablet:right-auto tablet:bottom-auto select-none',
        'group/button flex flex-row rounded-[6px] content-center items-center min-w-[3rem]',
        'transition-[background-color,border-color,color]',
        props.state === 'disabled' || props.state === 'loading'
          ? 'cursor-default'
          : 'cursor-pointer',
        backgroundStyle,
        props.size === 'large'
          ? 'h-14 min-h-14 gap-4 px-6'
          : props.size === 'medium'
            ? 'h-11 min-h-11 gap-3 px-4'
            : 'h-9 min-h-9 gap-2 px-3',
        props.width === 'intrinsic'
          ? 'w-auto self-start'
          : typeof props.width === 'number'
            ? `w-[${props.width}rem] min-w-[${props.width}rem] max-w-[${props.width}rem]`
            : 'flex-1 w-full max-w-sm',
        props.includePadding === false ? 'px-0' : '',
        props.className
      )}
      style={
        _minWidth !== undefined
          ? {
              minWidth: `${_minWidth}px`,
            }
          : {}
      }
      disabled={props.state === 'disabled' || props.state === 'loading'}
      onClick={onButtonClick}
      type={'button'} // This can be overwritten using the withAttributes
      data-testid={props.testId}
      {...props.withAttributes}
    >
      {props.state === 'loading' ? (
        <span className={'flex flex-1 flex-row justify-center items-center'}>
          <span className={'animate-spin'}>
            {props.size === 'small' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15.364 15.364C16.6226 14.1053 17.4798 12.5016 17.8271 10.7558C18.1743 9.00998 17.9961 7.20038 17.3149 5.55585C16.6337 3.91131 15.4802 2.50571 14.0001 1.51677C12.5201 0.527841 10.78 1.34389e-07 9 0V2.25C10.335 2.25 11.6401 2.64588 12.7501 3.38758C13.8601 4.12928 14.7253 5.18349 15.2362 6.41689C15.7471 7.65029 15.8808 9.00749 15.6203 10.3169C15.3598 11.6262 14.717 12.829 13.773 13.773L15.364 15.364Z"
                  fill={
                    props.hierarchy === 'special' ||
                    props.hierarchy === 'primary' ||
                    props.hierarchy === 'secondary-inverted'
                      ? 'white'
                      : '#212427'
                  }
                />
                <path
                  d="M9 0C7.51994 -1.76495e-08 6.06273 0.365014 4.75743 1.06271C3.45213 1.7604 2.33905 2.76924 1.51677 3.99987C0.694497 5.23049 0.188409 6.64491 0.0433375 8.11785C-0.101734 9.59078 0.11869 11.0768 0.685084 12.4442C1.25148 13.8115 2.14636 15.0182 3.29046 15.9571C4.43456 16.896 5.79257 17.5383 7.24419 17.8271C8.69581 18.1158 10.1962 18.0421 11.6126 17.6125C13.0289 17.1828 14.3174 16.4105 15.364 15.364L13.773 13.773C12.9881 14.5579 12.0217 15.1371 10.9594 15.4593C9.89717 15.7816 8.77185 15.8369 7.68314 15.6203C6.59443 15.4037 5.57592 14.922 4.71784 14.2178C3.85977 13.5136 3.18861 12.6087 2.76381 11.5831C2.33902 10.5576 2.1737 9.44308 2.2825 8.33838C2.39131 7.23369 2.77087 6.17287 3.38758 5.2499C4.00429 4.32693 4.8391 3.5703 5.81807 3.04703C6.79704 2.52376 7.88996 2.25 9 2.25V0Z"
                  fill={
                    props.hierarchy === 'special' ||
                    props.hierarchy === 'primary' ||
                    props.hierarchy === 'secondary-inverted'
                      ? 'white'
                      : '#212427'
                  }
                  fillOpacity="0.35"
                />
              </svg>
            ) : props.size === 'medium' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.4853 20.4853C22.1635 18.8071 23.3064 16.6689 23.7694 14.3411C24.2324 12.0133 23.9948 9.60051 23.0866 7.4078C22.1783 5.21509 20.6402 3.34094 18.6668 2.02237C16.6935 0.703788 14.3734 1.79185e-07 12 0V3C13.78 3 15.5201 3.52784 17.0001 4.51677C18.4802 5.50571 19.6337 6.91131 20.3149 8.55585C20.9961 10.2004 21.1743 12.01 20.8271 13.7558C20.4798 15.5016 19.6226 17.1053 18.364 18.364L20.4853 20.4853Z"
                  fill={
                    props.hierarchy === 'special' ||
                    props.hierarchy === 'primary' ||
                    props.hierarchy === 'secondary-inverted'
                      ? 'white'
                      : '#212427'
                  }
                />
                <path
                  d="M12 0C10.0266 -2.35327e-08 8.08363 0.486685 6.34324 1.41694C4.60285 2.3472 3.11873 3.69233 2.02236 5.33316C0.925996 6.97399 0.251212 8.85988 0.0577833 10.8238C-0.135645 12.7877 0.158254 14.769 0.913445 16.5922C1.66864 18.4154 2.86181 20.0242 4.38728 21.2761C5.91275 22.528 7.72342 23.3844 9.65892 23.7694C11.5944 24.1544 13.595 24.0561 15.4834 23.4833C17.3719 22.9104 19.0899 21.8807 20.4853 20.4853L18.364 18.364C17.3174 19.4105 16.0289 20.1828 14.6126 20.6125C13.1962 21.0421 11.6958 21.1158 10.2442 20.8271C8.79257 20.5383 7.43456 19.896 6.29046 18.9571C5.14636 18.0182 4.25148 16.8115 3.68508 15.4442C3.11869 14.0768 2.89827 12.5908 3.04334 11.1178C3.18841 9.64491 3.6945 8.23049 4.51677 6.99987C5.33905 5.76924 6.45213 4.7604 7.75743 4.06271C9.06273 3.36501 10.5199 3 12 3V0Z"
                  fill={
                    props.hierarchy === 'special' ||
                    props.hierarchy === 'primary' ||
                    props.hierarchy === 'secondary-inverted'
                      ? 'white'
                      : '#212427'
                  }
                  fillOpacity="0.35"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M27.3137 27.3137C29.5513 25.0761 31.0752 22.2251 31.6926 19.1214C32.3099 16.0177 31.9931 12.8007 30.7821 9.87707C29.5711 6.95345 27.5203 4.45459 24.8891 2.69649C22.2579 0.938384 19.1645 2.38913e-07 16 0L16 4C18.3734 4 20.6935 4.70379 22.6668 6.02237C24.6402 7.34094 26.1783 9.21509 27.0866 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853L27.3137 27.3137Z"
                  fill={
                    props.hierarchy === 'special' ||
                    props.hierarchy === 'primary' ||
                    props.hierarchy === 'secondary-inverted'
                      ? 'white'
                      : '#212427'
                  }
                />
                <path
                  d="M16 0C13.3688 -3.13769e-08 10.7782 0.648913 8.45765 1.88926C6.13713 3.12961 4.15831 4.9231 2.69649 7.11088C1.23466 9.29865 0.334949 11.8132 0.0770444 14.4317C-0.18086 17.0503 0.211005 19.692 1.21793 22.1229C2.22485 24.5539 3.81575 26.6989 5.84971 28.3682C7.88366 30.0374 10.2979 31.1792 12.8786 31.6926C15.4592 32.2059 18.1266 32.0748 20.6446 31.311C23.1625 30.5472 25.4532 29.1743 27.3137 27.3137L24.4853 24.4853C23.0899 25.8807 21.3719 26.9104 19.4834 27.4833C17.595 28.0561 15.5944 28.1544 13.6589 27.7694C11.7234 27.3844 9.91275 26.528 8.38728 25.2761C6.86181 24.0242 5.66864 22.4154 4.91345 20.5922C4.15825 18.769 3.86436 16.7877 4.05778 14.8238C4.25121 12.8599 4.926 10.974 6.02236 9.33316C7.11873 7.69233 8.60285 6.3472 10.3432 5.41694C12.0836 4.48668 14.0266 4 16 4V0Z"
                  fill={
                    props.hierarchy === 'special' ||
                    props.hierarchy === 'primary' ||
                    props.hierarchy === 'secondary-inverted'
                      ? 'white'
                      : '#212427'
                  }
                  fillOpacity="0.35"
                />
              </svg>
            )}
          </span>
        </span>
      ) : (
        <span
          className={
            'flex flex-1 flex-row justify-center items-center gap-2 overflow-hidden'
          }
        >
          {/* {props.trailingIcon !== undefined && (
            <span
              className={twMerge(
                'w-6 h-6 min-w-[1.5rem] min-h-[1.5rem] items-center justify-center',
                'tablet:flex'
              )}
            >
              {
                // Not currently used but needed
                // to keep the balance
              }
            </span>
          )} */}
          <span
            className={
              'flex flex-1 items-center justify-center gap-2 overflow-hidden'
            }
          >
            {renderIcon(props.icon)}
            <Typography
              variant={
                props.size === 'large'
                  ? 'label-large'
                  : props.size === 'medium'
                    ? 'label-medium'
                    : 'label-small'
              }
              className={twMerge(
                'whitespace-nowrap overflow-hidden text-ellipsis',
                'tablet:block',
                textStyle
              )}
            >
              {props.label}
            </Typography>
          </span>
          {props.trailingIcon !== undefined && (
            <span
              className={twMerge(
                'flex w-6 h-6 min-w-[1.5rem] min-h-[1.5rem] items-center justify-center',
                'tablet:flex'
              )}
            >
              {renderIcon(props.trailingIcon)}
            </span>
          )}
        </span>
      )}
    </button>
  )
}
