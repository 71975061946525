import { getClient, getConfig } from '@/api/common'
import { BaseResponse } from '@/types/api'
import { IUser } from '@/types/user'

export const getOrgUsers = async (
  orgId: string
): Promise<BaseResponse<IUser[]>> => {
  const client = await getClient((await getConfig()).AUTH_API_URL)
  return await client
    .get<BaseResponse<IUser[]>>(`org/${orgId}/users`)
    .then((res) => {
      return res.data
    })
}
