import { twMerge } from 'tailwind-merge'

interface Props {
  color: 'BLACK' | 'WHITE'
  heightInRem?: number
}
export const LogoWord = (props: Props) => {
  // Set some defaults
  props = {
    heightInRem: 5.0625,
    ...props,
  }

  const textColorsForTheme: { [color in Props['color']]: string } = {
    BLACK: 'text-[#212427]',
    WHITE: 'text-white',
  }

  return (
    <div className={twMerge(textColorsForTheme[props.color])}>
      <p
        className={twMerge(
          'font-["Plus Jakarta Sans"]',
          'font-[600]',
          'leading-[90%]',
          'select-none',
          props.color === 'WHITE' ? 'text-[#F5F5F6]' : 'text-[#212427]'
        )}
        style={{
          fontSize: `${(props.heightInRem ?? 1) * 0.5570075}rem`,
        }}
      >
        asset
        <br />
        reality
      </p>
    </div>
  )
}
