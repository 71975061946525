import '../styles/date.input.css'

import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Icon } from '@/components/icon'
import { Typography } from '@/components/typography'
import { dateToString } from '@/helpers/dateToString'

import { DateInputProps } from '../types'

export const DateInput = (props: DateInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [displayValue, setDisplayValue] = useState<string>(
    dateToString(String(props.value ?? ''))
  )
  const [actualValue, setActualValue] = useState<string>(
    String(props.value ?? '')
  )

  const handleFocus = () => {
    inputRef.current?.focus()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setActualValue(newValue)
    setDisplayValue(dateToString(newValue))
  }

  return (
    <div
      className={twMerge(
        'flex flex-col space-y-2 max-w-[23.4375rem] w-full',
        props.disabled ? 'cursor-disabled' : ''
      )}
    >
      {props.label && (
        <label htmlFor={props.id}>
          <Typography
            variant="label-small"
            className={twMerge(props.labelClassName)}
          >
            {props.label}
          </Typography>
        </label>
      )}
      <div
        className={twMerge(
          'group flex flex-row w-full gap-x-3 rounded-[0.25rem] border-0 px-3 text-gray-900 h-[2.75rem] min-h-[2.75rem] relative',
          'border-[1px] bg-white border-[#21242740] placeholder:text-gray-400',
          'hover:border-[#212427]',
          'focus-within:border-[2px] focus-within:border-[#212427]',
          'transition-[border-color,background-color,box-shadow] ease duration-300',
          props.disabled
            ? 'bg-gray-100 border-gray-100 text-gray-500 hover:border-gray-100'
            : '',
          props.className
        )}
        onClick={handleFocus}
      >
        <div
          className="inset-y-0 flex items-center text-sm"
          onClick={() => {
            if (!props.disabled) {
              inputRef.current?.showPicker()
            }
          }}
        >
          <Icon
            name={'calendar-alt'}
            family={'sharp'}
            variant={'solid'}
            size={'small'}
          />
        </div>
        <input
          type="text"
          className={twMerge(
            'ring-0 focus:ring-0 border-none w-full bg-transparent p-0 outline-none date-input pointer-events-none',
            'focus:group:text-red',
            '-webkit-calendar-picker-indicator:opacity-0'
          )}
          value={displayValue}
          onChange={(e) => {
            e.preventDefault()
          }}
        />
        <input
          {...props}
          ref={inputRef}
          type="date"
          className={twMerge(
            'ring-0 focus:ring-0 border-none w-full bg-transparent p-0 outline-none date-input invisible absolute inset-0 -z-[1]',
            'focus:group:text-red',
            '-webkit-calendar-picker-indicator:opacity-0'
          )}
          value={actualValue}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
