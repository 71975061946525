import { useQuery } from '@tanstack/react-query'

import { getWorkspaces } from '@/api/workspaces/v1'

export const key = 'org-workspaces'
export const useOrgWorkspaces = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: [key],
    queryFn: () => getWorkspaces(),
  })

  return { orgWorkspaces: data?.data ?? [], isLoading, refetch }
}
