import { useContext } from 'react'

import { FormErrorsContext } from './context'

export const useFormErrors = () => {
  const context = useContext(FormErrorsContext)
  if (!context) {
    throw new Error('useFormErrors must be used within a FormErrorsProvider')
  }
  return context
}
