import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { ICustodian } from '@/types/custodian'

// import { useModal } from '@/contexts/interface'
import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  custodian: ICustodian
}
const CustodianActionsView = (props: Props) => {
  // const { setModal } = useModal()
  const navigate = useNavigate()

  return (
    <DropdownView
      {...props}
      id={'CUSTODIAN_ACTIONS'}
      items={[
        {
          title: t('view_details'),
          icon: 'eye',
          onClick: () => {
            navigate(
              ROUTES.ADMIN.CUSTODIAN.DETAIL.replace(
                ':custodian_id',
                props.custodian?.id
              ).replace(':custodian_id', props.custodian?.id),
              {
                state: {
                  custodian: props.custodian,
                },
              }
            )
          },
        },
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default CustodianActionsView
