import { ReactElement, useEffect, useRef, useState } from 'react'

import Dropdown from '@/components/dropdown'
import { DropdownAlignment } from '@/components/dropdown/components/context.dropdown'
import { RowTypes } from '@/components/dropdown/components/list.dropdown'
import { Props as DropdownViewProps } from '@/components/dropdown/components/view.dropdown'
import { Icon, IconProps } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { useDropdown } from '@/contexts/interface'
import { useId } from '@/hooks/useId'
import { useWindowSize } from '@/hooks/useWindowSize'

import { Props as InputFieldProps } from '../components/field.input'
import { Props as HintProps } from '../components/hint.input'
import { Props as LabelProps } from '../components/label.input'
import { DefaultInput } from './default.input'

interface Props<T> {
  items: DropdownViewProps<T>['items']
  text?: string
  value?: string
  defaultValue?: string
  suffix?: string
  name?: string
  label?: string | ReactElement<LabelProps>
  hint?: string | ReactElement<HintProps>
  icon?: ReactElement<IconProps>
  size?: InputFieldProps['size']
  placeholder?: string
  listGap?: DropdownViewProps<T>['gap']
  fixedHeader?: DropdownViewProps<T>['fixedHeader']
  fixedFooter?: DropdownViewProps<T>['fixedFooter']
  state?: 'readonly' | 'default' | 'disabled' | 'error'
  onItemClicked?: (item: RowTypes<T>) => void
  onItemSelectionChange?: (value: string | null) => void
  className?: string
  testId?: string
}

export const InputDropdown = <T,>(props: Props<T>) => {
  const [_value, setValue] = useState<string>(props.value ?? '')
  const [_text, setText] = useState<string>('')
  const [_dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [_dropdownAlignment, setDropdownAlignment] =
    useState<DropdownAlignment>()
  const { setDropdown } = useDropdown()
  const { id } = useId()
  const { isMobile } = useWindowSize()
  const inputRef = useRef<HTMLInputElement>(null)

  // const [_open, setOpen] = useState<boolean>(false)
  // const [_value, setValue] = useState<string>()
  // const [_text, setText] = useState<string>()

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (props.text !== undefined) {
      setText(props.text)
    }
  }, [props.text])

  useEffect(() => {
    for (const v of props.items ?? []) {
      if (
        v.type === ListItem &&
        'title' in v.props &&
        'value' in v.props &&
        v.props.value === props.defaultValue
      ) {
        setValue(v.props.value ?? '')
        setText(v.props.title ?? '')
        props.onItemSelectionChange &&
          props.onItemSelectionChange(v.props.value ?? '')
        break
      }
    }
  }, [props.defaultValue])

  const onItemClicked = (item: RowTypes<T>) => {
    if ('title' in item && 'value' in item) {
      setValue(item.value ?? '')
      setText(item.title ?? '')
      props.onItemClicked && props.onItemClicked(item)
      props.onItemSelectionChange &&
        props.onItemSelectionChange(item.value ?? '')
    }
  }

  const showDropdown = () => {
    if (props.state !== 'readonly' && props.state !== 'disabled') {
      setDropdown({
        target: id('input'),
        type: 'input',
        autoSize: ['mobile', 'tablet', 'desktop'],
        onDropdownToggled: (open, alignment) => {
          setDropdownOpen(open)
          setDropdownAlignment(alignment)
        },
        controller: (
          <Dropdown.Controllers.BasicList
            items={props.items ?? []}
            onItemClicked={onItemClicked}
            closeOnItemClick={true}
          />
        ),
      })
    }
  }

  return (
    <DefaultInput
      setRef={inputRef}
      id={id('input')}
      name={props.name}
      type={'text'}
      variant={'dropdown'}
      hint={props.hint}
      label={props.label}
      icon={props.icon}
      state={props.state}
      onClick={showDropdown}
      text={_text}
      value={_value}
      suffix={props.suffix}
      className={props.className}
      placeholder={props.placeholder}
      testId={props.testId}
      elementClassName={
        _dropdownOpen && !isMobile && (props.items ?? []).length > 0
          ? _dropdownAlignment === 'ABOVE'
            ? 'border-[#212427] border-[0.125rem]'
            : 'border-[#212427] border-[0.125rem]'
          : ''
      }
      {...(props.state !== 'readonly' &&
        props.state !== 'disabled' && {
          trailingIcon: (
            <Icon
              name={_dropdownOpen ? 'close' : 'chevron-down'}
              variant={'solid'}
              family={'sharp'}
            />
          ),
        })}
    />
  )
}
