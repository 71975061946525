import { useEffect, useState } from 'react'

import { BannerContext } from './context'
import { BannerManager } from './manager'
import {
  __BannerProps,
  __PageBannerProps,
  __SiteBannerProps,
  __WelcomeBannerProps,
  BannerProps,
  BannerVariant,
} from './types'

type BannerProviderProps = {
  children: JSX.Element[] | JSX.Element
}

export const BannerProvider: React.FC<BannerProviderProps> = ({ children }) => {
  const [bannerQueue, setBannerQueue] = useState<__BannerProps[]>([])

  // Show the banner
  const showBanner = (props: BannerProps) => {
    // Generate an id
    const _id = Math.floor(new Date().getTime() * Math.random()).toString()

    // Clone + add in any missing attributes
    const __props: __BannerProps =
      props.variant === 'welcome'
        ? {
            id: _id,
            queue: false,
            clearOnPageNavigate: true,
            duration: props.clearOnPageNavigate === false ? 10000 : 0,
            close: () => closeBannerByVariant('welcome'),
            ...props,
          }
        : {
            type: 'information',
            id: _id,
            queue: false,
            clearOnPageNavigate: true,
            duration: props.clearOnPageNavigate === false ? 10000 : 0,
            close: () => closeBannerById(_id),
            ...props,
          }

    // Add the new banner to the queue
    setBannerQueue((prevQueue) => {
      if (__props.queue === false) {
        return [
          ...prevQueue.filter((b) => b.variant !== __props.variant),
          __props,
        ]
      } else {
        return [...prevQueue, __props]
      }
    })
  }

  // Remove all banners of a particular type from the queue
  const closeBannerByVariant = (variant: BannerVariant | 'all') => {
    setBannerQueue((prevQueue) => {
      return prevQueue.filter(
        (b) =>
          (variant === 'all' ? false : b.variant !== variant) ||
          !b.clearOnPageNavigate
      )
    })
  }

  // Close a particular banner based on its id
  const closeBannerById = (id: string) => {
    setBannerQueue((prevQueue) => prevQueue.filter((b) => b.id !== id))
  }

  useEffect(() => {
    BannerManager.setShowBannerFunction(showBanner)
    BannerManager.setHideBannersFunction(closeBannerByVariant)
  }, [])

  return (
    <BannerContext.Provider
      value={{
        currentSiteBanner:
          (bannerQueue.find(
            (b) => b.variant === 'site'
          ) as __SiteBannerProps) ?? null,
        currentPageBanner:
          (bannerQueue.find(
            (b) => b.variant === 'page'
          ) as __PageBannerProps) ?? null,
        currentWelcomeBanner:
          (bannerQueue.find(
            (b) => b.variant === 'welcome'
          ) as __WelcomeBannerProps) ?? null,
      }}
    >
      {children}
    </BannerContext.Provider>
  )
}
