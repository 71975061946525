import { t } from 'i18next'

import { updateEscrowFee } from '@/api/escrows/v1'
import { uploadFiles } from '@/api/files'
import { REGEX } from '@/constants/regex'
import { queryClient } from '@/contexts'
import { FormErrorsManager } from '@/contexts/formErrors'
import { IsNot } from '@/helpers/test'
import { key as QueryEscrowKey } from '@/hooks/queries/useEscrow'
import { ActionResponse } from '@/types/actions'
import { IEscrow } from '@/types/escrows'

export const EditEscrowFee = async (
  formData: FormData
): Promise<ActionResponse<string>> => {
  const escrow = formData.get('escrow')?.toString()
  const fee_id = formData.get('fee_id')?.toString()
  const fee_value = formData.get('fee_value')?.toString()
  const fee_asset = formData.get('fee_asset')?.toString()
  const invoice_file = formData.get('invoice_file')?.toString()

  // Validate the fields
  if (IsNot(escrow, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'escrow',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(fee_id, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'fee_id',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(fee_value, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'fee_value',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(fee_asset, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'fee_asset',
        error: t('try_again_later'),
      },
    ])
  } else if (IsNot(invoice_file, REGEX.IS_ANY)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'invoice_file',
        error: 'Please attach a Fee file.',
      },
    ])
  }

  // Attempt to parse the original escrow object
  const parsedEscrow = JSON.parse(escrow) as IEscrow

  // Confirm that the fee asset does indeed belong to the escrow
  if (!parsedEscrow.digital_assets.find((a) => a.id === fee_asset)) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'fee_asset',
        error: 'Chosen asset does not exist in Escrow.',
      },
    ])
  }

  // Handle files
  const files = [...formData.getAll('invoice_file')]?.filter(
    (file) => file && file instanceof File && file.size !== 0
  ) as File[]

  // Upload files
  const filesKeys = await uploadFiles(files)

  // Check we have a file attached
  if (filesKeys.length <= 0) {
    return FormErrorsManager.addErrors([
      {
        fieldName: 'invoice_file',
        error: 'Please attach a Fee file.',
      },
    ])
  }

  // Attempt to patch the fee
  const res = await updateEscrowFee({
    escrowId: parsedEscrow.id,
    feeId: fee_id,
    files: filesKeys.map((f) => {
      return {
        key: f.key,
        name: f.name,
        type: f.type,
      }
    }),
    charge: {
      asset_id: fee_asset,
      amount: fee_value,
    },
  })

  // Check it was created ok
  if (res.error || !res.data) {
    throw new Error(res.message)
  }

  // Invalidate the escrow cache
  await queryClient.invalidateQueries({
    queryKey: [QueryEscrowKey, parsedEscrow.id],
  })

  return {
    error: false,
    message: t('escrow_updated_successfully'),
    completion: 'COMPLETE',
    data: parsedEscrow.id,
  }
}
