const config = {
  AUTH_URL: '//127.0.0.1:5000',
  PORTAL_URL: '//127.0.0.1:5001',
  CUSTODY_URL: '//127.0.0.1:5002',
  ADMIN_URL: '//127.0.0.1:5003',
  ACTIVITY_URL: '//127.0.0.1:5004',
  INVESTIGATION_URL: '//127.0.0.1:5005',
  ESCROW_URL: 'https://escrow.demo.assetreality.org/',
  AUTH_API_URL: 'https://auth.demo.assetreality.org/api/v1',
  BROKER_API_URL: 'https://broker.demo.assetreality.org/api/v1',
  BROKER_API_URL_V2: 'https://broker.demo.assetreality.org/api/v2',
  GOVERNANCE_API_URL: 'https://governance.demo.assetreality.org/api/v1',
  CHAIN_API_URL: 'https://chain.demo.assetreality.org/',
}

export default config
