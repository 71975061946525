import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import { DropdownView } from '@/components/dropdown/components/view.dropdown'
import { Icon } from '@/components/icon'
import { ListItem } from '@/components/list-item'
import { ROUTES } from '@/constants/routes'
import { useAuth } from '@/contexts/auth'
import { useModal } from '@/contexts/interface'
import { isDefined } from '@/helpers/isDefined.ts'
import { useOrgWorkspaces } from '@/hooks/queries/useOrgWorkspaces'
import { IAsset } from '@/types/asset'

import { Props as ViewProps } from '../../components/view.dropdown'

interface Props extends ViewProps<unknown> {
  asset: IAsset
  onRemoveFromCase?: (a: IAsset) => void
}

const AssetActionsView = (props: Props) => {
  const { setModal } = useModal()
  const { orgWorkspaces } = useOrgWorkspaces()
  const navigate = useNavigate()

  const viewAsset = () => {
    navigate(
      ROUTES.ASSETS.DETAIL.replace(':asset_id', props.asset?.id).replace(
        ':asset_id',
        props.asset?.id
      ),
      {
        state: {
          asset: props.asset,
          category: props.asset.category,
        },
      }
    )
  }

  const { policies } = useAuth()
  const canShareAsset = policies?.includes('CUSTODY.SHARE_ASSET')
  const canTransferAsset = policies?.includes('CUSTODY.MANAGE_TRANSFER_REQUEST')
  const canRemoveAssetFromCase =
    policies?.includes('CUSTODY.MANAGE_OPERATION') &&
    isDefined(props.onRemoveFromCase)

  const shareAsset = () => {
    if (!canShareAsset) return
    setModal({ id: 'SHARE_ASSET', asset: props.asset })
  }

  const transferAsset = () => {
    if (!canTransferAsset) return
    setModal({ id: 'TRANSFER_ASSET', asset: props.asset })
  }

  const onRemoveAsset = () => {
    if (props.onRemoveFromCase) {
      props.onRemoveFromCase(props.asset)
    }
  }

  return (
    <DropdownView
      {...props}
      id={'ASSET_ACTIONS'}
      items={[
        {
          title: t('view_details'),
          icon: 'eye',
          onClick: viewAsset,
        },
        ...(orgWorkspaces.length > 1 && canTransferAsset
          ? [
              {
                title: t('transfer'),
                icon: 'upload',
                onClick: transferAsset,
              },
            ]
          : []),
        ...(canShareAsset
          ? [
              {
                title: t('share_item'),
                icon: 'share',
                onClick: shareAsset,
              },
            ]
          : []),
        ...(canRemoveAssetFromCase
          ? [
              {
                title: t('remove_from_case'),
                icon: 'trash',
                onClick: onRemoveAsset,
              },
            ]
          : []),
      ].map((v, i) => (
        <ListItem
          key={`item_${i}`}
          title={v.title}
          leading={
            <Icon
              size={'small'}
              family={'sharp'}
              name={v.icon}
              variant={'solid'}
            />
          }
          className="pl-2 pr-2"
          onClick={v.onClick}
        />
      ))}
    />
  )
}
export default AssetActionsView
